import { LayoutRouteUsage } from "../../../redux/types";
import DistrictContainer from "../../../containers/companies/districts/DistrictContainer";
import DistrictEditFormContainer from "../../../containers/companies/districts/DistrictEditFormContainer";
import DistrictNewFormContainer from "../../../containers/companies/districts/DistrictNewFormContainer";
import DistrictsContainer from "../../../containers/companies/districts/DistrictsContainer";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import PlaceIcon from "@material-ui/icons/Place";

const DistrictsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["companies/districts"],
    requireSuperuser: true,
    routes: [
      {
        path: "/companies/districts",
        label: t("companyNavbarDistrictsLabel"),
        component: DistrictsContainer,
        icon: PlaceIcon,
        highlightClass: NavItemClassEnum.DISTRICTS,
        navId: NavItemClassEnum.DISTRICTS,
      },
      {
        path: "/companies/districts/new",
        parent: "/companies/districts",
        label: t("newDistrictLabel"),
        component: DistrictNewFormContainer,
        highlightClass: NavItemClassEnum.DISTRICTS,
      },
      {
        path: "/companies/districts/:districtId",
        parent: "/companies/districts",
        label: t("districtLabel"),
        component: DistrictContainer,
        highlightClass: NavItemClassEnum.DISTRICTS,
      },
      {
        path: "/companies/districts/:districtId/edit",
        parent: "/companies/districts/:districtId",
        label: t("editDistrictLabel"),
        component: DistrictEditFormContainer,
        highlightClass: NavItemClassEnum.DISTRICTS,
      },
    ],
  },
];

export default DistrictsRouter;
