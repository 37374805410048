import React, { useEffect, useState } from "react";
import { SelectFieldOption } from "../../../redux/types";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { Formik, Field } from "formik";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import { getContractorNamesAPI } from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import { history } from "../../../redux/store";

interface AfterregistrationsProps {}

const useStyles = makeStyles((theme) => ({
  paper: { margin: `${theme.spacing(2)}px 0`, padding: 20 },
  form: {
    width: "100%",
    padding: ` ${theme.spacing(1)}px ${theme.spacing(2.5)}px `
  }
}));

const Afterregistrations: React.FC<AfterregistrationsProps> = (props) => {
  const classes = useStyles();
  const t = useTranslate("AfterregistrationPage");
  const [loading, setLoading] = useState<boolean>(false);
  const [contractorOptions, setContractorOptions] = useState<
    SelectFieldOption[]
  >([]);

  useEffect(() => {
    let isAlive = true;

    (async () => {
      setLoading(true);
      const response = await getContractorNamesAPI();
      if (isAlive) {
        setContractorOptions(
          response.results.map((company) => ({
            label: company.name,
            value: company.id
          }))
        );
      }
      setLoading(false);
    })();

    return () => {
      isAlive = false;
    };
  }, []);

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography variant="h6">{t("afterregistrationsTitle")}</Typography>
        <Formik initialValues={{ contractor: null }} onSubmit={() => {}}>
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                isLoading={loading}
                id="afterregistration-new-contractor"
                data-cy="afterregistration-new-contractor"
                type="text"
                name="contractor"
                label={t("contractorFieldLabel")}
                placeholder={t("contractorFieldLabel")}
                value={
                  contractorOptions.find(
                    (contractorOption) =>
                      props.values.contractor === contractorOption.value
                  )
                    ? contractorOptions.find(
                        (contractorOption) =>
                          props.values.contractor === contractorOption.value
                      )
                    : null
                }
                options={contractorOptions}
                component={AutoCompleteSelect}
                margin="normal"
                customHandleChange={(value: number) => {
                  if (value >= 0) {
                    history.replace(
                      `/after-registrations/add-new/${value}?same_tab=true`
                    );
                  }
                }}
              />
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default Afterregistrations;
