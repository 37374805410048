import { createSuccessActionType } from "../actionTypes";
import { TasksState, Action, Task } from "../../types";
import { RestStrings, ReduxActionStrings } from "../../strings";

const initialState: TasksState = {
  tasks: [],
  contractorTasks: [],
  count: 0,
  next: null,
  previous: null,
  task: {
    task: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      customer_contracts: [],
      contractor_cooperations: [],
      geo_polygons: null,
      services: [],
      notes: null,
      work_description: null,
      risk_description: null,
      businessarea: -1,
      customerobject: -1,
      task_options: [],
      geo_polygons_image: "",
      complexity: "",
      vulnerability: "",
      report_interval: "",
      status: "",
      product_package: false,
      material_depot: false,
      default_transport: 0,
      spoilage_coefficient: 0,
      accounting_reference: null,
      area_square_meters: null,
      dumpsite_square_meters: null,
      priority_square_meters: null,
      manual_square_meters: null
    },
    taskContractorCooperations: [],
    taskCustomerobject: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      status: "ACTIVE",
      customer: -1,
      contact_person: -1,
      customer_object_type: "PROPERTY_USER",
      passcard_type: "PASSCARD",
      district_name: null,
      notes: "",
      allow_photos: true,
      invoice_name: "",
      weekday_opening: "",
      weekday_closing: "",
      saturday_opening: "",
      saturday_closing: "",
      sunday_opening: "",
      sunday_closing: "",
      location: null,
      passcards: [],
      persons: [],
      tasks: [],
      company_logo: null,
      invoice_location: null,
      invoice_address_enabled: false,
      accounting_reference: null
    },
    taskBusinessArea: {
      id: -1,
      created_at: "",
      updated_at: "",
      invoicingprofile: -1,
      contractingprofile: null,
      name: "",
      notes: null
    }
  },
  businessareas: [],
  businessarea: {
    id: -1,
    created_at: "",
    updated_at: "",
    invoicingprofile: -1,
    contractingprofile: null,
    name: "",
    notes: null
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.TASKS):
      return {
        ...state,
        tasks: [...action.payload.results].filter(
          (value, index, self) =>
            self.map((x) => x.id).indexOf(value.id) === index
        ),
        count: action.payload.count
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTOR_TASKS
    ):
      return {
        ...state,
        contractorTasks: [...action.payload.results].filter(
          (value, index, self) =>
            self.map((x) => x.id).indexOf(value.id) === index
        ),
        count: action.payload.count
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.TASKS):
      return {
        ...state,
        tasks: [...state.tasks, action.payload]
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.TASK):
      return {
        ...state,
        task: {
          ...state.task,
          task: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TASK_CUSTOMEROBJECT
    ):
      return {
        ...state,
        task: {
          ...state.task,
          taskCustomerobject: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TASK_BUSINESSAREA
    ):
      return {
        ...state,
        task: {
          ...state.task,
          taskBusinessArea: action.payload
        }
      };
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.TASK):
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task.id === action.payload.id ? action.payload : task
        ),
        task: { ...state.task, task: action.payload }
      };

    case createSuccessActionType(RestStrings.DELETE, ReduxActionStrings.TASK):
      return {
        ...state,
        tasks: state.tasks.filter(
          (tasks: Task) => tasks.id !== parseInt(action.payload.id)
        )
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TASK_CONTRACTOR_COOPERATIONS
    ):
      return {
        ...state,
        task: {
          ...state.task,
          taskContractorCooperations: action.payload.results
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TASK_CATEGORIES
    ):
      return {
        ...state,
        businessareas: [
          ...state.businessareas,
          ...action.payload.results
        ].filter(
          (value, index, self) =>
            self.map((x) => x.id).indexOf(value.id) === index
        )
      };
    default:
      return state;
  }
}

export default reducer;
