import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles, Paper, Typography, Button } from "@material-ui/core";
import {
  CompanyForm,
  Company,
  TabComponentProps,
  LocationForm,
  SelectFieldOption
} from "../../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useTranslate } from "../../../services/appLanguageService";
import {
  getCompanyByIdAPI,
  getDistrictsAPI,
  updateCompanyAPI
} from "../../../services/api-declaration";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import { history } from "../../../redux/store";
import InvoiceLocationForm from "../../InvoiceLocationForm";

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      marginTop: 20
    }
  });

interface CompanyInvoiceLocationFormProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"companyId"> {}

const CompanyInvoiceLocationForm: React.FC<CompanyInvoiceLocationFormProps> = (
  props
) => {
  const {
    classes,
    routeParams: { companyId }
  } = props;
  const t = useTranslate("InvoiceLocationFormPage");
  const [company, setCompany] = useState<Company | CompanyForm>();
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [districtOptions, setDistrictOptions] = useState<SelectFieldOption[]>(
    []
  );

  useEffect(() => {
    let isAlive = true;

    setLoadingCompany(true);
    void getCompanyByIdAPI(companyId).then((companyResponse) => {
      if (isAlive) {
        setCompany(companyResponse);
        setLoadingCompany(false);
      }
    });

    return () => {
      isAlive = false;
    };
  }, [companyId]);

  useEffect(() => {
    let isAlive = true;

    void getDistrictsAPI().then(
      (districtsResponse) =>
        isAlive &&
        setDistrictOptions(
          districtsResponse.results.map((district) => ({
            label: district.name,
            value: district.id
          }))
        )
    );

    return () => {
      isAlive = false;
    };
  }, []);

  const handleSubmit = (values: LocationForm) => {
    void updateCompanyAPI(companyId, { invoice_location: values }).then(() =>
      history.replace(`/companies/companies/${companyId}?same_tab=true`)
    );
  };

  const initialValues: LocationForm =
    company && company.invoice_location !== null
      ? company.invoice_location
      : {
          notes: "",
          address: "",
          addressee: "",
          zip_code: "",
          country: "",
          extra_address: "",
          district: null,
          city: "",
          full_address: "-",
          latitude: 0,
          longitude: 0
        };

  return loadingCompany ? (
    <LoadingSpinnerPaper />
  ) : (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/companies/companies${
                company ? `/${company.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {t("editInvoiceLocation")}
        </Typography>
        <InvoiceLocationForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          districtOptions={districtOptions}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(CompanyInvoiceLocationForm);
