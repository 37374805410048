import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, ServiceCategory } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { useTranslate } from "../../../services/appLanguageService";
import * as Yup from "yup";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface ServiceCategoriesContainerProps {
  serviceCategories: ServiceCategory[];
  count: number;
  loadingServiceCategories: boolean;
  resetServiceCategories: () => void;
  loadServiceCategories: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  addServiceCategory: (newData: any, businessAreaId?: number) => void;
  updateServiceCategory: (serviceCategoryId: number, newData: any) => void;
  deleteServiceCategory: (serviceCategoryId: number) => void;
  businessAreaId?: number;
  serviceCategoryIds?: number[];
  customHeader?: string;
  editable?: boolean;
}

const ServiceCategoriesContainer: React.FC<ServiceCategoriesContainerProps> = (
  props
) => {
  const {
    loadServiceCategories,
    resetServiceCategories,
    loadingServiceCategories,
    serviceCategories,
    businessAreaId,
    serviceCategoryIds,
    editable
  } = props;

  const page = 1;
  const pageSize = 20;
  const businessAreasFilter = businessAreaId
    ? `&filter__businessarea=${businessAreaId}`
    : "";
  const serviceCategoriesFilter = serviceCategoryIds
    ? `&filter__id__in=[${serviceCategoryIds.toString()}]`
    : "";
  const filter = businessAreasFilter + serviceCategoriesFilter;
  const t3 = useTranslate("ValidationErrorMessages");

  useEffect(
    () => loadServiceCategories(page, pageSize, undefined, undefined, filter),
    [filter, loadServiceCategories]
  );
  useEffect(() => () => resetServiceCategories(), [resetServiceCategories]);

  const columnDefinitions = [
    {
      name: "name",
      type: "text",
      editableForm: "text",
      validation: Yup.string()
        .min(1, t3("minLengthError"))
        .max(255, t3("max255LengthError"))
        .required(t3("requiredError"))
    },
    {
      name: "notes",
      type: "text",
      editableForm: "text",
      validation: Yup.string().nullable().notRequired()
    }
  ];

  return (
    <ResourcesTableContainer
      resources={serviceCategories}
      resourceName={"ServiceCategory"}
      count={props.count}
      routeUrl={"contracts/servicecategories"}
      loadingResources={loadingServiceCategories}
      columnDefinitions={columnDefinitions}
      showNewButton={false}
      filterDefinitions={[]}
      customFilter={filter}
      resetResources={resetServiceCategories}
      loadResources={loadServiceCategories}
      defaultPageSize={pageSize}
      customHeader={props.customHeader}
      owningId={businessAreaId}
      addResource={editable ? props.addServiceCategory : undefined}
      updateResource={editable ? props.updateServiceCategory : undefined}
      deleteResource={editable ? props.deleteServiceCategory : undefined}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadServiceCategories: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "SERVICECATEGORIES",
        `contracts/servicecategories/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  resetServiceCategories: () =>
    dispatch(LoadingBooleansActions.ResetServiceCategories(ownProps.tabId)),
  addServiceCategory: (newData: any, businessAreaId?: number) => {
    if (businessAreaId) {
      dispatch(
        RestActions.restRequestWithData(
          ownProps.tabId,
          RestStrings.POST,
          ReduxActionStrings.SERVICECATEGORIES,
          "contracts/servicecategories/",
          {
            businessarea: businessAreaId,
            name: newData.name,
            notes: newData.notes || null
          }
        )
      );
    }
  },
  updateServiceCategory: (serviceCategoryId: number, newData: any) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.SERVICECATEGORY,
        `contracts/servicecategories/${serviceCategoryId}/`,
        {
          name: newData.name,
          notes: newData.notes || null
        }
      )
    );
  },
  deleteServiceCategory: (serviceCategoryId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.SERVICECATEGORY,
        `contracts/servicecategories/${serviceCategoryId}/`,
        undefined,
        undefined,
        serviceCategoryId
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  serviceCategories:
    state.tabStates[ownProps.tabId].serviceCategories.serviceCategories
      .serviceCategories,
  count:
    state.tabStates[ownProps.tabId].serviceCategories.serviceCategories.count,
  loadingServiceCategories:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingServiceCategories
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCategoriesContainer);
