import {
  TimeSheet,
  RetrospectRoutePlanInstance,
  RouteInstance,
  TimesheetQuery
} from "../redux/types";
import {
  approveUnplannedRouteInstance,
  getTimesheetsAPI
} from "./api-declaration";

export const approveUnplannedTimesheets = async (
  retrospectInstance: RetrospectRoutePlanInstance
): Promise<RouteInstance> => {
  try {
    const routeInstance: RouteInstance = await approveUnplannedRouteInstance(
      retrospectInstance
    );
    return Promise.resolve(routeInstance);
  } catch (e) {
    return Promise.reject(e);
  }
};

export async function getNonApprovableTimesheetRows(
  filter: TimesheetQuery = {}
): Promise<TimeSheet[]> {
  try {
    const response = await getTimesheetsAPI({
      ...filter,
      filter__submitted: "True",
      filter__is_approvable: "False"
    });
    return Promise.resolve(response.results);
  } catch (error) {
    return Promise.reject(error);
  }
}
