import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import { TextField, Select } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  TermForm,
  Term,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import { useTranslate } from "../../../services/appLanguageService";
import { EnumStrings } from "../../../redux/strings";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";

const statusOptions = [
  EnumStrings.ACTIVE,
  EnumStrings.LOCKED,
  EnumStrings.ARCHIVED,
  EnumStrings.DRAFT
];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    description: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    terms_text: Yup.string().nullable().notRequired(),
    notes: Yup.string().nullable().notRequired(),
    owner: Yup.number().nullable().notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    }
  });

interface NewTermProps
  extends FormikSubmitDispatchProps<TermForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  term?: Term;
  ownerOptions: SelectFieldOption[];
  loadCompanies: (searchTerm?: string) => void;
}

const NewEditTerm: React.FC<NewTermProps> = (props) => {
  const { classes, term, ownerOptions, loadCompanies, tabId } = props;
  const t = useTranslate("TermPage");
  const t2 = useTranslate("Terms");
  const t3 = useTranslate("ValidationErrorMessages");
  let initialValues: Term | TermForm;
  if (term && (term as Term).id) {
    initialValues = {
      ...term
    };
  } else {
    initialValues = {
      id: -1,
      name: "",
      status: "ACTIVE",
      description: "",
      terms_text: "",
      notes: "",
      owner: -1
    };
  }
  useEffect(() => {
    if (term) {
      store.dispatch(setTitle(term.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/contracts/terms${term ? `/${term.id}` : ""}?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {term ? t("editTermHeading") : t("newTermHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={props.handleSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="term-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              {/* Status type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="term-form-status"
              >
                {t("statusLabel")}
              </InputLabel>
              <Field
                id="term-form-status"
                name="status"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              <InputLabel className={classes.selectLabel} htmlFor="form-owner">
                {t("ownerLabel")}
              </InputLabel>
              <Field
                id="form-owner"
                type="text"
                name="owner"
                label={t("ownerLabel")}
                placeholder={t("ownerLabel")}
                value={
                  ownerOptions.find(
                    (owner) => props.values.owner === owner.value
                  )
                    ? ownerOptions.find(
                        (owner) => props.values.owner === owner.value
                      )
                    : null
                }
                options={ownerOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                loadOptions={loadCompanies}
              />

              <Field
                id="term-form-description"
                type="text"
                name="description"
                label={t("descriptionLabel")}
                placeholder={t("descriptionLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="new-term-form-terms_text"
                type="text"
                name="terms_text"
                label={t("termsTextLabel")}
                placeholder={t("termsTextLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="15"
                fullWidth
              />

              <Field
                id="new-term-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-term-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {term ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditTerm);
