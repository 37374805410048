import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { Document, Page } from "react-pdf";
import { useTranslate } from "../../../services/appLanguageService";

const useStyles = makeStyles((theme) =>
  createStyles({
    preview: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
      minHeight: "100vh",
      position: "relative",
      "& > *:first-child": {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(3)
      },
      "& > *:first-child > div": {
        display: "flex"
      },
      "& .preview--paper": {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(3),
        maxWidth: "min-content"
      },
      "& .preview--loading": {
        flexGrow: 1,
        marginLeft: theme.spacing(3)
      }
    }
  })
);

type InvoicePreviewProps = {
  blob: Blob;
  loading: boolean;
};

const InvoicePreview: React.FC<InvoicePreviewProps> = ({ blob, loading }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState(1.5);
  const t = useTranslate("InvoiceView");
  const classes = useStyles();

  return (
    <Paper className={classes.preview}>
      <div>
        <Typography variant="h4">{t("previewHeader")}</Typography>
        {loading && (
          <div className="preview--loading">
            <CircularProgress />
          </div>
        )}
        <div>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={() => setScale((s) => s + 0.2)}>
              <Add />
            </Button>
            <Button onClick={() => setScale((s) => Math.max(s - 0.2, 1))}>
              <Remove />
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Document
        file={blob}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        loading={<></>}
      >
        {Array.from({ length: numPages ?? 0 }, (_, index) => (
          <Paper key={index} className="preview--paper">
            <Page key={index} scale={scale} pageNumber={index + 1} />
          </Paper>
        ))}
      </Document>
    </Paper>
  );
};

export default InvoicePreview;
