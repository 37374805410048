import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  InvoicingProfile,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import InvoicingProfileView from "../../../components/invoices/invoicingProfiles/InvoicingProfile";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface InvoicingProfileContainerProps
  extends TabComponentProps<"invoicingprofileId"> {
  invoicingprofile: InvoicingProfile;
  loadingInvoicingProfile: boolean;
  resetInvoicingProfile: () => void;
  loadInvoicingProfile: (invoicingprofileId: number) => void;
}

const InvoicingProfileContainer: React.FC<InvoicingProfileContainerProps> = (
  props
) => {
  const {
    resetInvoicingProfile,
    loadingInvoicingProfile,
    loadInvoicingProfile,
    invoicingprofile,
    routeParams: { invoicingprofileId }
  } = props;
  useEffect(
    () => loadInvoicingProfile(invoicingprofileId),
    [loadInvoicingProfile, invoicingprofileId]
  );

  useEffect(() => () => resetInvoicingProfile(), [resetInvoicingProfile]);
  return (
    <div>
      <ViewActionButtons
        resourceId={invoicingprofileId}
        resource={ReduxActionStrings.INVOICINGPROFILE}
        url="invoices/invoicingprofiles"
      />
      {loadingInvoicingProfile ? (
        <LoadingSpinnerPaper />
      ) : (
        <InvoicingProfileView
          invoicingprofile={invoicingprofile}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadInvoicingProfile: (invoicingprofileId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "INVOICINGPROFILE",
        `invoices/invoicingprofiles/${invoicingprofileId}/`
      )
    );
  },
  resetInvoicingProfile: () =>
    dispatch(LoadingBooleansActions.ResetInvoicingProfile(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  invoicingprofile:
    state.tabStates[ownProps.tabId].invoicingProfiles.invoicingProfile
      .invoicingProfile,
  loadingInvoicingProfile:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingInvoicingProfile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicingProfileContainer);
