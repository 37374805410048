import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  TermForm,
  Term,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import TermNewEditForm from "../../../components/contracts/terms/TermNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditTermContainerProps
  extends FormikSubmitDispatchProps<TermForm>,
    TabComponentProps<"termId"> {
  term: Term;
  ownerOptions: SelectFieldOption[];
  loadTerm: (termId: number) => void;
  loadCompanies: (searchTerm?: string) => void;
  loadingTerm: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Term | TermForm> {
  loadTerm: (termId: number) => void;
  loadCompanies: (searchTerm?: string) => void;
}

const EditTermContainer: React.FC<EditTermContainerProps> = (props) => {
  const {
    loadTerm,
    term,
    routeParams: { termId },
    loadCompanies,
    loadingTerm
  } = props;
  useEffect(() => loadTerm(termId), [loadTerm, termId]);
  useEffect(() => loadCompanies(), [loadCompanies]);

  return loadingTerm ? (
    <LoadingSpinnerPaper />
  ) : (
    <TermNewEditForm
      term={term}
      ownerOptions={props.ownerOptions}
      handleSubmit={props.handleSubmit}
      loadCompanies={props.loadCompanies}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.TERM,
        `contracts/terms/${(checkedNullValues as Term).id}/`,
        checkedNullValues,
        `/contracts/terms/${(checkedNullValues as Term).id}?same_tab=true`
      )
    );
  },
  loadTerm: (termId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TERM,
        `contracts/terms/${termId}/`
      )
    );
  },
  loadCompanies: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  term: state.tabStates[ownProps.tabId].terms.term.term,
  ownerOptions: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  })),
  loadingTerm: state.tabStates[ownProps.tabId].loadingBooleans.loadingTerm
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTermContainer);
