import React, { useState } from "react";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { useTranslate } from "../../../services/appLanguageService";
import { ContractIndexFactors } from "../../../services/api-declaration";

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      display: "flex",
      flexWrap: "wrap",
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    },
    textField: {
      width: "220px",
    }
  })
);

type IndexContractDialogProps = {
  onIndex: (factors: ContractIndexFactors) => Promise<void>;
  onClose: () => void;
};

const IndexContractDialog: React.FC<IndexContractDialogProps> = ({
  onIndex,
  onClose
}) => {
  const [state, setState] = useState<{
    [key in keyof ContractIndexFactors]: string;
  }>({
    materials_price_factor: "0",
    accessories_price_factor: "0",
    supplements_price_factor: "0",
    addons_price_factor: "0",
    services_price_factor: "0",
    servicepackages_price_factor: "0"
  });
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {}
  );
  const [loading, setLoading] = useState(false);
  const classes = useModalStyles();
  const t = useTranslate("ContractPage");

  const schema = Yup.object({
    materials_price_factor: Yup.number()
      .required(t("fieldRequired"))
      .min(-100, t("fieldBelowMinValue")),
    accessories_price_factor: Yup.number()
      .required(t("fieldRequired"))
      .min(-100, t("fieldBelowMinValue")),
    supplements_price_factor: Yup.number()
      .required(t("fieldRequired"))
      .min(-100, t("fieldBelowMinValue")),
    addons_price_factor: Yup.number()
      .required(t("fieldRequired"))
      .min(-100, t("fieldBelowMinValue")),
    services_price_factor: Yup.number()
      .required(t("fieldRequired"))
      .min(-100, t("fieldBelowMinValue")),
    servicepackages_price_factor: Yup.number()
      .required(t("fieldRequired"))
      .min(-100, t("fieldBelowMinValue"))
  });

  const validateFields = async (
    ...fields: (keyof typeof state)[]
  ): Promise<boolean> => {
    let next = { ...errors };
    await Promise.all(
      (fields.length ? fields : Object.keys(state)).map((field) =>
        schema
          .validateAt(field, state)
          .then(() => field in next && delete next[field])
          .catch(
            (err: Yup.ValidationError) => (next[field] = err.errors.join(", "))
          )
      )
    );
    setErrors(next);
    return Object.keys(next).length === 0;
  };

  const handleIndex = async () => {
    setLoading(true);
    try {
      const valid = await validateFields();
      if (valid) {
        await onIndex({
          accessories_price_factor: parseFloat(state.accessories_price_factor),
          addons_price_factor: parseFloat(state.addons_price_factor),
          materials_price_factor: parseFloat(state.materials_price_factor),
          servicepackages_price_factor: parseFloat(
            state.servicepackages_price_factor
          ),
          services_price_factor: parseFloat(state.services_price_factor),
          supplements_price_factor: parseFloat(state.supplements_price_factor)
        });
        onClose();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog onClose={onClose} fullWidth open>
      <DialogTitle>{t("indexAdjustmentHeading")}</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <TextField
            label={t("servicesPriceFactorLabel")}
            value={state.services_price_factor}
            onChange={(e) =>
              setState({ ...state, services_price_factor: e.target.value })
            }
            onBlur={() => validateFields("services_price_factor")}
            error={!!errors["services_price_factor"]}
            helperText={errors["services_price_factor"]}
          />
        </div>
        <div>
          <TextField
            label={t("supplementsPriceFactorLabel")}
            value={state.supplements_price_factor}
            onChange={(e) =>
              setState({ ...state, supplements_price_factor: e.target.value })
            }
            onBlur={() => validateFields("supplements_price_factor")}
            error={!!errors["supplements_price_factor"]}
            helperText={errors["supplements_price_factor"]}
            className={classes.textField}
          />
        </div>
        <div>
          <TextField
            label={t("materialsPriceFactorLabel")}
            value={state.materials_price_factor}
            onChange={(e) =>
              setState({ ...state, materials_price_factor: e.target.value })
            }
            onBlur={() => validateFields("materials_price_factor")}
            error={!!errors["materials_price_factor"]}
            helperText={errors["materials_price_factor"]}
            className={classes.textField}
          />
        </div>
        <div>
          <TextField
            label={t("accessoriesPriceFactorLabel")}
            value={state.accessories_price_factor}
            onChange={(e) =>
              setState({ ...state, accessories_price_factor: e.target.value })
            }
            onBlur={() => validateFields("accessories_price_factor")}
            error={!!errors["accessories_price_factor"]}
            helperText={errors["accessories_price_factor"]}
            className={classes.textField}
          />
        </div>
        <div>
          <TextField
            label={t("servicepackagesPriceFactorLabel")}
            value={state.servicepackages_price_factor}
            onChange={(e) =>
              setState({
                ...state,
                servicepackages_price_factor: e.target.value
              })
            }
            onBlur={() => validateFields("servicepackages_price_factor")}
            error={!!errors["servicepackages_price_factor"]}
            helperText={errors["servicepackages_price_factor"]}
            className={classes.textField}
          />
        </div>
        <div>
          <TextField
            label={t("addonsPriceFactorLabel")}
            value={state.addons_price_factor}
            onChange={(e) =>
              setState({ ...state, addons_price_factor: e.target.value })
            }
            onBlur={() => validateFields("addons_price_factor")}
            error={!!errors["addons_price_factor"]}
            helperText={errors["addons_price_factor"]}
            className={classes.textField}
          />
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          aria-label="cancel"
          onClick={onClose}
          color="primary"
        >
          {t("cancelLabel")}
        </Button>
        <Button
          variant="contained"
          aria-label="add"
          startIcon={loading ? <CircularProgress size={20} /> : undefined}
          onClick={() => handleIndex()}
          disabled={loading}
          color="primary"
        >
          {t("indexButtonLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IndexContractDialog;
