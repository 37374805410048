import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularProgress, IconButton, WithStyles } from "@material-ui/core";
import { Done as DoneIcon, Clear as ClearIcon } from "@material-ui/icons";
import { fetchPatch } from "../services/fetchWrapper";
import { restUrl } from "../services/api-declaration";
import { useTranslate } from "../services/appLanguageService";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";

const styles = (theme: any) =>
  createStyles({
    readOnly: {
      paddingBottom: 2,
      borderBottom: "dashed 1px #0088cc",
      cursor: "pointer"
    },
    addLabel: {
      color: "grey",
      fontStyle: "italic",
      cursor: "pointer"
    },
    icon: {
      fontSize: "20px"
    }
  });

interface EditableDatePickerProps extends WithStyles<typeof styles> {
  field: string;
  initValue: string | null;
  url: string;
  updateResourceState: (newValue: any) => void;
}

const EditableDatePicker: React.FC<EditableDatePickerProps> = (props) => {
  const { classes, field } = props;
  const [value, setValue] = useState<string | null>(props.initValue);
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const t = useTranslate("EditableFieldComponent");

  const updateResource = async () => {
    setLoading(true);
    const response = await fetchPatch(restUrl(props.url), {
      [props.field]: value
    });
    setLoading(false);
    props.updateResourceState(response);
  };

  return loading ? (
    <CircularProgress size={18} />
  ) : !edit ? (
    <span
      className={value !== null ? classes.readOnly : classes.addLabel}
      onClick={() => setEdit(true)}
    >
      {value !== null ? value : `${t("addLabel")}...`}
    </span>
  ) : (
    <>
      <DatePicker
        format="yyyy-MM-dd"
        id={field}
        data-cy={field}
        value={value}
        onChange={(event) => {
          setValue(event ? format(event, "yyyy-MM-dd") : event);
        }}
        onKeyPress={(event) => {
          if (event.code === "Enter") {
            updateResource();
            setEdit(false);
          }
        }}
        clearable
        InputProps={{
          style: {
            fontSize: 13
          }
        }}
      />
      <IconButton
        size="small"
        aria-label="Save"
        onClick={() => {
          updateResource();
          setEdit(false);
        }}
      >
        <DoneIcon className={classes.icon} style={{ color: "#006400" }} />
      </IconButton>
      <IconButton
        size="small"
        aria-label="Reset"
        onClick={() => {
          setEdit(false);
          setValue(props.initValue);
        }}
      >
        <ClearIcon className={classes.icon} style={{ color: "Crimson" }} />
      </IconButton>
    </>
  );
};

export default withStyles(styles)(EditableDatePicker);
