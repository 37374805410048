import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  DistrictForm,
  District,
  mainRootState,
  SelectFieldOption
} from "../../../redux/types";
import DistrictNewForm from "../../../components/companies/districts/DistrictNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewDistrictContainerProps
  extends FormikSubmitDispatchProps<DistrictForm> {
  loadDistricts: (searchTerm?: string) => void;
  parentDistrictOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<District | DistrictForm> {
  loadDistricts: (searchTerm?: string) => void;
}

const NewDistrictContainer: React.FC<NewDistrictContainerProps> = (props) => {
  const { loadDistricts } = props;

  useEffect(() => loadDistricts(), [loadDistricts]);
  return (
    <DistrictNewForm
      parentDistrictOptions={props.parentDistrictOptions}
      handleSubmit={props.handleSubmit}
      loadDistricts={props.loadDistricts}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values,
      parent_district:
        values.parent_district !== -1 ? values.parent_district : null
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<DistrictForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.DISTRICT,
        "companies/districts/",
        checkedNullValues as District,
        "/companies/districts?same_tab=true"
      )
    );
  },
  loadDistricts: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.DISTRICTS,
        `companies/districts/?order__name=asc&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  parentDistrictOptions: state.tabStates[
    ownProps.tabId
  ].districts.districts.results.map((parentDistrict) => ({
    label: parentDistrict.name,
    value: parentDistrict.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDistrictContainer);
