import {
  IDataCarry,
  IApprovalCorrections,
  IApprovalCorrectionsKey,
  IDataCarryKey,
  SubmittedWork,
  TimeSheetRowApproval,
  TimeSheetRowSubmittedWork,
  IPackageData,
  TimeSheetRowMaterials
} from "../../../redux/types";
import {
  ALL_EDIT_FIELDS,
  APPROVAL_PRIOTITY
} from "../../../strings/WorkApprovalStrings";
import { copyApprovalAddon } from "../../timesheetApprovalHelper";

class WorkApproval {
  public id: TimeSheetRowApproval["id"];
  public submit_status: TimeSheetRowApproval["submit_status"];
  public notes: TimeSheetRowApproval["notes"];
  public submittedwork: TimeSheetRowApproval["submittedwork"];
  public corrections: TimeSheetRowApproval["corrections"];
  public contractor_payables: TimeSheetRowApproval["contractor_payables"];
  public customer_billables: TimeSheetRowApproval["customer_billables"];

  constructor({
    id,
    submit_status,
    notes,
    submittedwork,
    corrections,
    contractor_payables,
    customer_billables
  }: TimeSheetRowApproval) {
    this.id = id;
    this.submit_status = submit_status;
    this.notes = notes;
    this.submittedwork = submittedwork;
    this.corrections = { ...corrections };
    this.contractor_payables = {
      ...contractor_payables
    };
    this.customer_billables = {
      ...customer_billables
    };
  }

  public getCorrectionsOrRowData(
    dataCarry: IDataCarry,
    editMode: IDataCarryKey
  ) {
    const populatedRow: IApprovalCorrections = this.copyDataCarryValues(
      {} as IApprovalCorrections,
      this.submittedwork.timesheetrow
    );

    this.copyDataCarryValues(populatedRow, dataCarry[editMode]);

    return populatedRow;
  }

  public getCorrectionsByPriority(dataCarry: IDataCarry) {
    const populatedRow: IApprovalCorrections = this.copyDataCarryValues(
      {} as IApprovalCorrections,
      this.submittedwork.timesheetrow
    );

    APPROVAL_PRIOTITY.forEach((mode) =>
      this.copyDataCarryValues(populatedRow, dataCarry[mode])
    );

    return populatedRow;
  }

  public copyDataCarryValues = (
    targetData: IApprovalCorrections,
    sourceData: IApprovalCorrections
  ): IApprovalCorrections => {
    ALL_EDIT_FIELDS.forEach((fieldName) => {
      const hasValue = this.hasCorrectionValue(
        sourceData[fieldName],
        fieldName
      );
      if (hasValue) {
        const val = sourceData[fieldName];
        switch (fieldName) {
          case "servicecategory":
            (targetData["reported_service_category"] as any) = val;
            break;
          case "accessories":
            targetData[fieldName] = [...(val as any)];
            break;
          case "materials":
            targetData[fieldName] = copyApprovalAddon(
              val as TimeSheetRowMaterials
            );
            break;
          default:
            (targetData[fieldName] as any) = val;
            break;
        }
      }
    });
    return targetData;
  };

  public hasCorrectionValue(val: any, fieldName: IApprovalCorrectionsKey) {
    if (!val) {
      return false;
    }
    switch (fieldName) {
      case "task":
      case "service":
      case "reported_service_category":
      case "accessories":
        return val.length !== 0;
      case "accompanying_people":
      case "materials":
        return Object.keys(val).length !== 0;
      default:
        return true;
    }
  }

  public getPackage(): IPackageData {
    const { contractor_packageevent, customer_packageevent } =
      this.submittedwork;
    return {
      "": undefined,
      contractor: contractor_packageevent,
      customer: customer_packageevent
    };
  }

  public static constructWorkApprovalFromSBRow(
    submittedRow: SubmittedWork,
    timesheetId: number
  ) {
    const {
      workapproval_id,
      notes,
      status,
      general_corrections,
      contractor_corrections,
      customer_corrections
    } = submittedRow;
    const submittedwork = WorkApproval.constructSubmittedwork(
      submittedRow,
      timesheetId
    );
    return new WorkApproval({
      id: workapproval_id,
      notes,
      submit_status: status === "APPROVED" ? "APPROVED" : "DRAFT",
      submittedwork,
      corrections: general_corrections,
      contractor_payables: contractor_corrections,
      customer_billables: customer_corrections
    });
  }
  public static constructSubmittedwork(
    {
      submit_id,
      row_id,
      servicecategory,
      contractor_package_info,
      customer_package_info,
      start_time,
      end_time,
      task,
      service,
      service_name,
      accompanying_people,
      accessories,
      materials,
      reported_longitude,
      reported_latitude,
      reported_service_category,
      reported_service_categories
    }: SubmittedWork,
    timesheet: number
  ): TimeSheetRowSubmittedWork {
    return {
      id: submit_id,
      row_id,
      timesheet,
      timesheetrow: {
        start_time,
        end_time,
        task,
        service,
        service_name,
        accompanying_people,
        accessories,
        materials,
        reported_longitude,
        reported_latitude,
        reported_service_category,
        reported_service_categories,
        servicecategory
      },
      servicecategory,
      contractor_packageevent: contractor_package_info,
      customer_packageevent: customer_package_info
    };
  }
}

export default WorkApproval;
