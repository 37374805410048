import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  UserForm,
  User,
  mainRootState,
  TabComponentProps
} from "../../redux/types";
import UserNewEditForm from "../../components/users/UserNewEditForm";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import LoadingSpinnerPaper from "../../components/LoadingSpinnerPaper";
import {
  languagesObject,
  resetLocale
} from "../../services/appLanguageService";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface EditUserContainerProps
  extends FormikSubmitDispatchProps<UserForm>,
    TabComponentProps<"userId"> {
  user: User;
  loadUser: (userId: number) => void;
  loadingUser: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<User | UserForm> {
  loadUser: (userId: number) => void;
}

const EditUserContainer: React.FC<EditUserContainerProps> = (props) => {
  const {
    loadUser,
    user,
    routeParams: { userId },
    loadingUser,
    handleSubmit
  } = props;
  useEffect(() => loadUser(userId), [loadUser, userId]);

  return loadingUser ? (
    <LoadingSpinnerPaper />
  ) : (
    <UserNewEditForm
      user={user}
      handleSubmit={handleSubmit}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.USER,
        `auth/users/${(checkedNullValues as User).id}/`,
        checkedNullValues,
        `/auth/persons/${(checkedNullValues as User).person}?same_tab=true`,
        undefined,
        undefined,
        (response) => {
          const selfUserId = window.sessionUserId || window.masterUserId;
          if (selfUserId === response.id) {
            localStorage.setItem(
              "master_language",
              languagesObject[response.language]
            );
            resetLocale();
          }

          return response;
        }
      )
    );
  },
  loadUser: (userId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.USER,
        `auth/users/${userId}/`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  user: state.tabStates[ownProps.tabId].users.user.user,
  loadingUser: state.tabStates[ownProps.tabId].loadingBooleans.loadingUser
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserContainer);
