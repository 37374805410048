import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  BaseAccessory,
  BaseMaterial,
  BaseService,
  BaseSupplement,
  SelectFieldOption,
  ServiceCategory,
  TabComponentProps
} from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import AssociatedField from "../../AssociatedField";
import {
  getAllServiceCategories,
  getBaseServiceAPI,
  patchBaseServiceAPI,
  getBaseAccessoryOptionsAPI,
  getBaseMaterialOptionsAPI,
  getBaseSupplementOptionsAPI
} from "../../../services/api-declaration";
import * as Yup from "yup";
import SimpleImageUpload from "../../imageUploadComponent/SimpleImageUpload";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import EditableSimpleSelectField from "../../EditableSimpleSelectField";
import { EnumStrings } from "../../../redux/strings";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DeleteButtonContainer from "../../../containers/DeleteButtonContainer";
import {
  useConfirmationDialog,
  ConfirmationDialogServiceProvider
} from "../../confirmationDialog/ConfirmationDialogServiceProvider";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface BaseServiceProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"baseServiceId"> {}

const BaseServiceView: React.FC<BaseServiceProps> = (props) => {
  const {
    classes,
    tabId,
    routeParams: { baseServiceId }
  } = props;
  const t = useTranslate("ServicePage");
  const t2 = useTranslate("BaseServices");
  const t3 = useTranslate("ValidationErrorMessages");

  const [baseMaterialOptions, setBasematerialOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [baseMaterialsLoading, setBasematerialsLoading] =
    useState<boolean>(false);
  const [baseAccessoryOptions, setBaseaccessoryOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [baseAccessoriesLoading, setBaseaccessoriesLoading] =
    useState<boolean>(false);
  const [baseSupplementOptions, setBasesupplementOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [servicecategories, setServicecategories] = useState<ServiceCategory[]>(
    []
  );
  const [servicecategoriesLoading, setServicecategoriesLoading] =
    useState<boolean>(false);
  const [baseService, setBaseService] = useState<BaseService>();
  const [baseSupplementsLoading, setBasesupplementsLoading] =
    useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const updateResourceState = (value: BaseService) => setBaseService(value);

  const patchUrl = `contracts/baseservices/${baseServiceId}/`;

  const confirmationDialog = useConfirmationDialog();

  const statusOptions = [
    EnumStrings.ACTIVE,
    EnumStrings.LOCKED,
    EnumStrings.ARCHIVED,
    EnumStrings.DRAFT
  ];

  const translateFunction = (value: string) => t2(value);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (baseService) store.dispatch(setTitle(baseService.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseService]);

  useEffect(() => {
    let isAlive = true;

    (async () => {
      const baseService = await getBaseServiceAPI(baseServiceId);

      const [
        baseMaterialsResponse,
        baseSupplementsResponse,
        baseAccessoriesResponse,
        servicecategoriesResponse
      ] = await Promise.all([
        getBaseMaterialOptionsAPI(),
        getBaseSupplementOptionsAPI(),
        getBaseAccessoryOptionsAPI(),
        getAllServiceCategories()
      ]);

      if (isAlive) {
        setBaseService(baseService);
        setBasematerialOptions(
          baseMaterialsResponse.results.map(
            (baseMaterial: Pick<BaseMaterial, "name" | "id">) => ({
              value: baseMaterial.id,
              label: baseMaterial.name
            })
          )
        );
        setBasesupplementOptions(
          baseSupplementsResponse.results.map(
            (baseSupplement: Pick<BaseSupplement, "name" | "id">) => ({
              value: baseSupplement.id,
              label: baseSupplement.name
            })
          )
        );
        setBaseaccessoryOptions(
          baseAccessoriesResponse.results.map(
            (baseAccessory: Pick<BaseAccessory, "name" | "id">) => ({
              value: baseAccessory.id,
              label: baseAccessory.name
            })
          )
        );
        setServicecategories(servicecategoriesResponse.results);
      }
    })();

    return () => {
      isAlive = false;
    };
  }, [baseServiceId]);

  const addBaseMaterial = async (
    baseServiceId: number,
    currentBasematerialIds: number[]
  ) => {
    setBasematerialsLoading(true);

    const response = await patchBaseServiceAPI(baseServiceId, {
      base_materials: currentBasematerialIds
    });

    updateResourceState(response);
    setBasematerialsLoading(false);
  };

  const removeBaseMaterial = async (
    baseServiceId: number,
    currentBasematerialIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateBaseMaterialConfirmation")
    });
    setBasematerialsLoading(true);
    const response = await patchBaseServiceAPI(baseServiceId, {
      base_materials: currentBasematerialIds
    });

    updateResourceState(response);
    setBasematerialsLoading(false);
  };

  const addBaseAccessory = async (
    baseServiceId: number,
    currentAccessoryIds: number[]
  ) => {
    setBaseaccessoriesLoading(true);

    const response = await patchBaseServiceAPI(baseServiceId, {
      base_accessories: currentAccessoryIds
    });

    updateResourceState(response);
    setBaseaccessoriesLoading(false);
  };

  const removeBaseaccessory = async (
    baseServiceId: number,
    currentAccessoryIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateBaseAccessoryConfirmation")
    });
    setBaseaccessoriesLoading(true);
    const response = await patchBaseServiceAPI(baseServiceId, {
      base_accessories: currentAccessoryIds
    });

    updateResourceState(response);
    setBaseaccessoriesLoading(false);
  };

  const addBaseSupplement = async (
    baseServiceId: number,
    currentSupplementIds: number[]
  ) => {
    setBasesupplementsLoading(true);

    const response = await patchBaseServiceAPI(baseServiceId, {
      base_supplements: currentSupplementIds
    });

    updateResourceState(response);
    setBasesupplementsLoading(false);
  };

  const removeBaseSupplement = async (
    baseServiceId: number,
    currentSupplementIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateBaseSupplementConfirmation")
    });
    setBasesupplementsLoading(true);
    const response = await patchBaseServiceAPI(baseServiceId, {
      base_supplements: currentSupplementIds
    });

    updateResourceState(response);
    setBasesupplementsLoading(false);
  };

  const addServicecategory = async (
    baseServiceId: number,
    currentCategoryIds: number[]
  ) => {
    setServicecategoriesLoading(true);

    const response = await patchBaseServiceAPI(baseServiceId, {
      categories: currentCategoryIds
    });

    updateResourceState(response);
    setServicecategoriesLoading(false);
  };

  const removeServicecategory = async (
    baseServiceId: number,
    currentCategoryIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateCategoryConfirmation")
    });
    setServicecategoriesLoading(true);
    const response = await patchBaseServiceAPI(baseServiceId, {
      categories: currentCategoryIds
    });

    updateResourceState(response);
    setServicecategoriesLoading(false);
  };

  const addImage = async (image: string) => {
    setImageLoading(true);
    const response = await patchBaseServiceAPI(baseServiceId, {
      image: image
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  const deleteImage = async () => {
    setImageLoading(true);
    const response = await patchBaseServiceAPI(baseServiceId, {
      image: null
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  return !baseService ? (
    <LoadingSpinnerPaper />
  ) : (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/contracts/baseservices/"
      >
        <NavigateBeforeIcon /> {t("navigationButtonLabel")}
      </Button>
      <DeleteButtonContainer
        resourceId={baseService.id}
        resource={"BASESERVICE"}
        url={"contracts/baseservices"}
        backendUrl={"contracts/baseservices"}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={classes.bold} colSpan={2}>
                    <SimpleImageUpload
                      handleImageSave={addImage}
                      handleImageDelete={deleteImage}
                      image={baseService.image}
                      alt={baseService.name}
                      isLoading={imageLoading}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      <b>{baseService.name}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableTextField
                      field="name"
                      initValue={baseService.name}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      validation={Yup.string()
                        .min(1, t3("minLengthError"))
                        .max(255, t3("max255LengthError"))
                        .required(t3("requiredError"))}
                    />
                  </TableCell>
                </TableRow>
                {baseService.price !== null && (
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        isPrice
                        field="price"
                        initValue={baseService.price}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000000, t3("max10000000NumberError"))
                          .required(t3("requiredError"))}
                      />{" "}
                      kr {baseService.unit ? `/ ${baseService.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableSimpleSelectField
                      field="status"
                      initValue={baseService.status}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      options={statusOptions.map((option) => ({
                        label: t2(option),
                        value: option
                      }))}
                      translateFunction={translateFunction}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              data-cy="expand-details"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("materialTransportLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="material_transport"
                        label={t("materialTransportLabel")}
                        initValue={baseService.material_transport}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("automaticTransportLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="automatic_transport"
                        label={t("automaticTransportLabel")}
                        initValue={baseService.automatic_transport}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("multipleDriversLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="multiple_drivers"
                        label={t("multipleDriversLabel")}
                        initValue={baseService.multiple_drivers}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("isInanimateLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="is_inanimate"
                        label={t("isInanimateLabel")}
                        initValue={baseService.is_inanimate}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("hasManyLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="has_many"
                        label={t("hasManyLabel")}
                        initValue={baseService.has_many}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("isAccompanyingPersonAllowedLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="is_accompanying_person_allowed"
                        label={t("isAccompanyingPersonAllowedLabel")}
                        initValue={baseService.is_accompanying_person_allowed}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("vatPercentageLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="vat_percentage"
                        initValue={baseService.vat_percentage}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(99.99, t3("max99dot99NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit"
                        initValue={baseService.unit}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .min(1, t3("minLengthError"))
                          .max(255, t3("max255LengthError"))
                          .required(t3("requiredError"))}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitDividerLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit_divider"
                        initValue={baseService.unit_divider}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000, t3("max10000NumberError"))
                          .required(t3("requiredError"))}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("regNumberLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="reg_number"
                        initValue={baseService.reg_number}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .max(255, t3("max255LengthError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("lengthLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="length"
                        initValue={baseService.length}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      mm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("widthLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="width"
                        initValue={baseService.width}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      mm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("heightLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="height"
                        initValue={baseService.height}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      mm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("weightLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="weight"
                        initValue={baseService.weight}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      kg
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("horsepowerLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="horsepower"
                        initValue={baseService.horsepower}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000, t3("max10000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      hk
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("emissionStandardLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="emission_standard"
                        initValue={baseService.emission_standard}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .max(255, t3("max255LengthError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("driverDemandLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="driver_demand"
                        initValue={baseService.driver_demand}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .max(255, t3("max255LengthError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={baseService.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("descriptionLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="description"
                        initValue={baseService.description}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("yearLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="year"
                        initValue={baseService.year}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .integer(t3("inValidYear"))
                          .min(1000, t3("inValidYear"))
                          .max(9999, t3("inValidYear"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseService.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseService.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
          {servicecategories && baseService && (
            <AssociatedField
              field="categories"
              resourceIds={baseService.categories}
              options={servicecategories.map((servicecategory) => ({
                value: servicecategory.id,
                label: servicecategory.name
              }))}
              addResource={addServicecategory}
              owningId={baseService.id}
              label={t("serviceCategoryLabel")}
              removeResource={removeServicecategory}
              isLoading={servicecategoriesLoading}
              title={t("connectedServicecategoriesLabel")}
              atleastOneElement
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {baseMaterialOptions && baseService && (
            <AssociatedField
              field="materials"
              resourceIds={baseService.base_materials}
              options={baseMaterialOptions}
              addResource={addBaseMaterial}
              owningId={baseService.id}
              label={t("baseMaterialLabel")}
              removeResource={removeBaseMaterial}
              isLoading={baseMaterialsLoading}
              title={t("connectedBaseMaterialsLabel")}
            />
          )}
          {baseAccessoryOptions && baseService && (
            <AssociatedField
              field="accessories"
              resourceIds={baseService.base_accessories}
              options={baseAccessoryOptions}
              addResource={addBaseAccessory}
              owningId={baseService.id}
              label={t("baseAccessoryLabel")}
              removeResource={removeBaseaccessory}
              isLoading={baseAccessoriesLoading}
              title={t("connectedBaseAccessoriesLabel")}
            />
          )}
          {baseSupplementOptions && baseService && (
            <AssociatedField
              field="supplements"
              resourceIds={baseService.base_supplements}
              options={baseSupplementOptions}
              addResource={addBaseSupplement}
              owningId={baseService.id}
              label={t("baseSupplementLabel")}
              removeResource={removeBaseSupplement}
              isLoading={baseSupplementsLoading}
              title={t("connectedBaseSupplementsLabel")}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)((props: BaseServiceProps) => (
  <ConfirmationDialogServiceProvider>
    <BaseServiceView {...props} />
  </ConfirmationDialogServiceProvider>
));
