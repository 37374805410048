import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RoutePlan, mainRootState } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";
import ResourcesTableContainer from "../../components/resourcetable/ResourcesTableContainer";

interface RoutePlansContainerProps {
  routeplans: RoutePlan[];
  count: number;
  loadingRoutePlans: boolean;
  resetRoutePlans: () => void;
  loadRoutePlans: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => void;
  deleteRoutePlan: (routeplanId: number) => void;
}

const RoutePlansContainer: React.FC<RoutePlansContainerProps> = (props) => {
  const { loadRoutePlans, resetRoutePlans } = props;
  const page = 1;
  const pageSize = 20;

  useEffect(() => loadRoutePlans(page, pageSize), [loadRoutePlans]);
  useEffect(() => () => resetRoutePlans(), [resetRoutePlans]);

  return (
    <ResourcesTableContainer
      resources={props.routeplans}
      resourceName={"RoutePlan"}
      count={props.count}
      loadingResources={props.loadingRoutePlans}
      routeUrl={"orders/routeplans"}
      columnDefinitions={[{ name: "name", type: "text" }]}
      showNewButton={false}
      filterDefinitions={[]}
      resetResources={resetRoutePlans}
      defaultPageSize={pageSize}
      loadResources={loadRoutePlans}
      deleteResource={props.deleteRoutePlan}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadRoutePlans: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__name=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.ROUTEPLANS,
        `orders/routeplans/?include=["id", "name"]&page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}`
      )
    );
  },
  deleteRoutePlan: (routeplanId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.ROUTEPLAN,
        `orders/routeplans/${routeplanId}/`,
        undefined,
        undefined,
        routeplanId
      )
    );
  },
  resetRoutePlans: () =>
    dispatch(LoadingBooleansActions.ResetRouteplans(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  routeplans: state.tabStates[ownProps.tabId].routes.routeplans.routeplans,
  count: state.tabStates[ownProps.tabId].routes.routeplans.count,
  loadingRouteplans:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingRouteplans
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePlansContainer);
