import React, { useState, useCallback, useEffect, useRef } from "react";
import { createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  TablePagination,
  TableContainer,
  Grid,
  Slide,
  Tooltip,
  Button,
  TextField,
  TableRow,
  TableCell,
  TableBody,
  Table
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  RouteInstance,
  TimeFilterQuery,
  TableFilterConfig,
  RouteInstancesQuery,
  SelectFieldOption,
  IRowDetails
} from "../../../redux/types";
import { Add as AddIcon } from "@material-ui/icons";
import TableFilter from "../../tableFilter/TableFilter";
import { RouteChildrenProps } from "react-router";
import { useTranslate } from "../../../services/appLanguageService";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import { ExpandLess, ExpandMore, Map as MapIcon } from "@material-ui/icons";
import GoogleMaps, {
  convertFeatureToLocation,
  MarkersRef
} from "../../googleMaps/GoogleMaps";
import clsx from "clsx";
import RowDetailsComponent from "./RowDetails";
import { history } from "../../../redux/store";
import { showGlobalSnackbar } from "../../../helpers/globalHelper";
import { ensureMapScript } from "../../contracts/tasks/maps/MapHelpers";
import { getTaskByIdsAPI } from "../../../services/api-declaration";
import {
  MyTaskResponse,
  Task,
  LocationMark,
  IMapDrawings
} from "../../../redux/types";
import MapContainer from "../../../containers/contracts/tasks/MapContainer";
import SearchIcon from "@material-ui/icons/Search";
import { mapTabRouteProps } from "../../../helpers/routesHelper";
import { debounce } from "lodash";
import RouteInstancesTableNEW from "./RouteInstancesTableNEW";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    table: {
      minWidth: 500,
      [theme.breakpoints.down(600)]: {
        width: "100%"
      }
    },
    paper: {
      minHeight: "320px",
      marginTop: theme.spacing(2)
    },
    mapFlexBasis: {
      flex: "auto"
    },
    topPaper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down(600)]: {
        padding: "0px"
      }
    },
    header: {
      padding: 0,
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(1.5),
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1.5)
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      justifyContent: "start",
      [theme.breakpoints.down("sm")]: {
        alignSelf: "center",
        padding: "1px",
        justifyContent: "space-evenly"
      }
    },
    first: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down(600)]: {
        marginLeft: theme.spacing(1),
        width: "auto",
        fontSize: "12px"
      }
    },
    pagination: {
      padding: 0,
      width: "100%",
      textAlign: "right"
    },
    mapContainer: {
      display: "flex",
      padding: "0 !important",
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0`
    },
    mapGrid: {
      width: "100%",
      height: "100%",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      background: "#424242",
      padding: `${theme.spacing(3)}px 0 !important`
    },
    searchField: {
      display: "flex",
      alignItems: "flex-end",
      marginRight: theme.spacing(3)
    },
    iconCenter: {
      color: "#fff",
      margin: "0 auto"
    },
    mapView: {
      width: "100%",
      padding: theme.spacing(2)
    },
    mapPaper: {
      width: "100%",
      display: "flex",
      marginRight: theme.spacing(2)
    },
    descriptionBox: {
      overflow: "auto",
      maxHeight: "55px",
      padding: theme.spacing(2),
      border: "1px solid #b2b2b2",
      borderTop: 0
    },
    bold: { fontWeight: "bold" },
    fullHeight: { height: "100%" },
    mapStyles: { height: "90%" },
    mapnoToggled: {
      height: "100%",
      width: "100%",
      background: "rgba(0, 0, 0, 0.08)"
    },
    mapToggled: { width: "28px" },
    cursor: { cursor: "pointer" },
    mapPaperShrink: { width: "30px" },
    tableWidth: { minWidth: "100% !important" },
    tBody: {
      height: "200px",
      display: "flex",
      flexDirection: "column"
    },
    tableDetail: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    container: {
      maxHeight: "80vh",
      marginBottom: "0 !important"
    },
    rowTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    flexDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    }
  })
);

interface RouteInstancesProps extends RouteChildrenProps {
  routeinstances: RouteInstance[];
  getRouteInstances: (filter?: RouteInstancesQuery) => void;
  getRoutePlanOptions: () => void;
  resetRouteInstances: () => void;
  routeInstancesCount: number;
  loadingRouteinstances: boolean;
  routeplanOptions: SelectFieldOption[];
  routeinstance: RouteInstance;
}

const RouteInstances: React.FC<RouteInstancesProps> = (props) => {
  const {
    routeinstances,
    getRouteInstances,
    routeInstancesCount,
    loadingRouteinstances,
    routeplanOptions,
    getRoutePlanOptions
  } = props;
  const t = useTranslate("RouteInstancesPage");
  const t2 = useTranslate("MyTasksPage");
  const t3 = useTranslate("RouteInstanceEnums");
  const classes = useStyles();
  const now = new Date();
  const DEFAULT_TABLE_QUERY = {
    filter__status: "ALL",
    filter__plan_id: -1,
    filter__dispatched: "ALL",
    filter__time__gt: new Date(now.setDate(now.getDate() - 14)).toISOString()
  };
  const DEFAULT_QUERY: RouteInstancesQuery = {
    page: 1,
    page_size: 10,
    ...DEFAULT_TABLE_QUERY
  };
  const FILTERS: TableFilterConfig<RouteInstancesQuery, any>[][] = [
    [
      {
        title: t("statusLabel"),
        key: "filter__status",
        type: "SELECTOR",
        options: [
          "DRAFT",
          "WAITING_FOR_ACCEPT",
          "PARTLY_ACCEPTED",
          "PARTLY_DECLINED",
          "FULLY_ACCEPTED",
          "PARTLY_STARTED",
          "IN_PROGRESS",
          "SUBMITTED",
          "CANCELED",
          "RETROSPECTIVE",
          "COMPLETED"
        ].map((status: string) => ({
          label: t3(status),
          value: status
        })),
        optionLabelKey: "status",
        optionValueKey: "status"
      },
      {
        title: t("routeplanLabel"),
        key: "filter__plan_id",
        type: "SELECTOR",
        options: routeplanOptions,
        optionLabelKey: "title",
        optionValueKey: "id",
        loadOptions: getRoutePlanOptions
      }
    ],
    [
      {
        title: t("startDateFieldLabel"),
        key: "filter__time__gt",
        type: "DATE"
      },
      {
        title: t("endDateFieldLabel"),
        key: "filter__time__lt",
        type: "DATE"
      }
    ]
  ];
  const [query, setQuery] = useState<RouteInstancesQuery>({ ...DEFAULT_QUERY });
  const [toggledMap, setToggledMap] = useState(false);
  const [rowDetails, setRowDetails] = useState<any>({});
  const [mapDrawings, setMapDrawings] = useState<IMapDrawings>();
  const markersRef = useRef<MarkersRef[] | null>(null);
  const [selectedTask, setSelectedTask] = useState<Task | undefined>();
  const [expandedRowId, setExpandedRowId] = React.useState(-1);
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);

  const [isDescriptionShown, setIsDescriptionShown] = useState(false);

  const defaultMapOptions: google.maps.MapOptions = {
    zoom: 15,
    center: { lat: 57.7, lng: 11.96 },
    minZoom: 9,
    maxZoom: 18,
    mapTypeId: "hybrid"
  };

  const handleMapToggle = () => {
    setToggledMap(!toggledMap);
  };

  const setDetails = (details: IRowDetails) => {
    setRowDetails(details);
  };

  const initMap = async () => {
    let response: MyTaskResponse | undefined;
    try {
      response = await getTaskByIdsAPI(selectedTasks);
    } catch (err: any) {
      showGlobalSnackbar(err.message, "error");
    }
    ensureMapScript()
      .then(() => {
        if (response) {
          const markers: LocationMark[] = [];
          response.results.forEach((task: Task, idx: number, arr: Task[]) => {
            if (task.geo_polygons) {
              markers.push({
                data: task,
                position: convertFeatureToLocation(task.geo_polygons.features),
                markerOptions: {
                  title: task.name,
                  label: {
                    text: `${idx + 1}`,
                    color: "white",
                    fontWeight: "bold"
                  }
                }
              });
            }
          });

          setMapDrawings({ markers });
        }
      })
      .catch((error) => console.log(error));
  };
  const onMarkerClick = (mark: LocationMark) => {
    if (mark.data) {
      setCoordinates(mark.data);
    }
  };

  const onHoverRowIn = (id: number) => () => {
    if (!markersRef.current) return;
    const markers = markersRef.current as MarkersRef<Task>[];
    const marker = markers.find((marker) => marker.data.id === id);

    if (marker) {
      window.google.maps.event.trigger(marker.marker, "mouseover");
    }
  };

  const onHoverRowOut = (id: number) => () => {
    if (!markersRef.current) return;
    const markers = markersRef.current as MarkersRef<Task>[];
    const marker = markers.find((marker) => marker.data.id === id);

    if (marker) {
      window.google.maps.event.trigger(marker.marker, "mouseout");
    }
  };

  const onMarkerIn = (
    { data }: LocationMark<Task>,
    marker: google.maps.Marker
  ) => {
    const label = marker.getLabel() || { text: "" };
    marker.setLabel({
      ...label,
      color: "#424242"
    });
  };
  const setCoordinates = (task: Task) => {
    if (task && task.geo_polygons) {
      const geoPolygons =
        rowDetails.row.rows.find((row: any) => row.task === task.id)
          ?.geo_polygons || task.geo_polygons;
      setMapDrawings({ geoPolygons: geoPolygons });
      setSelectedTask(task);
    }
  };
  const onMarkerOut = (_: LocationMark<Task>, marker: google.maps.Marker) => {
    const label = marker.getLabel() || { text: "" };
    marker.setLabel({
      ...label,
      color: "white"
    });
  };
  const setMarkerRef = (refs: MarkersRef[]) => {
    if (selectedTasks.length) {
      markersRef.current = refs;
    }
  };

  const handlePageChange = (_: any, page: number) => {
    if (page + 1 === query.page) return;
    props.resetRouteInstances();
    setQuery({
      ...query,
      page: page + 1
    });
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.resetRouteInstances();
    setQuery((query) => ({
      ...query,
      page_size: parseInt(e.target.value),
      page: 1
    }));
  };

  const toggleOrderAndTask = (rInstance?: RouteInstance, task?: Task) => {
    if (rInstance) {
      const taskIds = rInstance.rows.map((r) => r.task);
      if (rInstance.id === expandedRowId) {
        setExpandedRowId(-1);
        setSelectedTasks([]);
        setMapDrawings({});
      } else {
        setDetails({ row: rInstance });
        setExpandedRowId(rInstance.id);
        setSelectedTasks(taskIds);
      }
      setSelectedTask(undefined);
    } else {
      if (task && task.geo_polygons) {
        const geoPolygons =
          rowDetails.row.rows.find((row: any) => row.task === task.id)
            ?.geo_polygons || task.geo_polygons;
        setMapDrawings({ geoPolygons: geoPolygons });
        setSelectedTask(task);
      } else {
        setSelectedTask(undefined);
        setMapDrawings({});
        initMap();
      }
    }
  };
  const handleFilterChange = (filter: TimeFilterQuery) => {
    props.resetRouteInstances();
    setQuery((q) => ({
      ...filter,
      page: DEFAULT_QUERY.page,
      page_size: q.page_size
    }));
  };
  const mapCb = useCallback(initMap, [selectedTasks]);
  useEffect(() => {
    if (selectedTasks.length) {
      mapCb();
    }
  }, [mapCb, selectedTasks]);

  useEffect(() => {
    getRouteInstances({
      ...query,
      filter__manually_entered: false,
      filter__is_active: true
    });
  }, [getRouteInstances, query]);

  useEffect(() => getRoutePlanOptions(), [getRoutePlanOptions]);
  useEffect(() => {
    if (selectedTask) {
      setIsDescriptionShown(true);
    } else {
      setIsDescriptionShown(false);
    }
  }, [selectedTask]);

  const handleChangeSearch = debounce((fieldVal: string) => {
    setQuery((query) => {
      const { filter__title__icontains: oldSearchStr, ...others } = query;
      if (oldSearchStr !== undefined || fieldVal.length > 1) {
        props.resetRouteInstances();
      }
      if (fieldVal.length > 1) {
        return {
          ...others,
          filter__title__icontains: fieldVal,
          page: DEFAULT_QUERY.page,
          page_size: query.page_size
        };
      }
      return oldSearchStr !== undefined ? { ...others } : query;
    });
  }, 700);

  return (
    <>
      <TableFilter<TimeFilterQuery>
        filters={FILTERS}
        onChange={handleFilterChange}
        defaultValues={{
          ...DEFAULT_TABLE_QUERY
        }}
      />

      <Paper className={clsx(classes.paper, classes.topPaper)}>
        <div className={classes.header}>
          <Typography variant="h6" component="h1" className={classes.bold}>
            {t("headerLabel")}
          </Typography>
          <div className={classes.searchField}>
            <TextField
              InputProps={{
                startAdornment: <SearchIcon />
              }}
              label={t("searchField")}
              onChange={(ev) => handleChangeSearch(ev.target.value)}
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            className={classes.first}
            onClick={() => history.replace("/orders/routeinstances/new")}
          >
            <AddIcon /> {t("addNewRouteinstanceLabel")}
          </Button>
        </div>
        {loadingRouteinstances ? (
          <LoadingSpinnerPaper />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={toggledMap ? 3 : 11}>
              <TableContainer
                component={Paper}
                className={clsx(classes.paper, classes.container)}
              >
                <>
                  {!toggledMap ? (
                    <RouteInstancesTableNEW routeinstances={routeinstances} />
                  ) : (
                    <Table
                      className={clsx(classes.table, classes.tableWidth)}
                      size="small"
                    >
                      <TableBody className={toggledMap ? classes.tBody : ""}>
                        {routeinstances.map((routeinstance) => (
                          <TableRow key={routeinstance.id}>
                            <TableCell
                              align="left"
                              colSpan={toggledMap ? 6 : 1}
                              onClick={() => toggleOrderAndTask(routeinstance)}
                              className={clsx(
                                classes.cursor,
                                toggledMap
                                  ? clsx(
                                      classes.tableDetail,
                                      expandedRowId === routeinstance.id &&
                                        classes.bold
                                    )
                                  : expandedRowId === routeinstance.id &&
                                      classes.bold
                              )}
                            >
                              <div className={classes.rowTitle}>
                                <div>{routeinstance.title}</div>
                                <div className={classes.flexDiv}>
                                  {expandedRowId !== routeinstance.id ? (
                                    <ExpandMore />
                                  ) : (
                                    <ExpandLess />
                                  )}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>

                {!toggledMap && (
                  <TablePagination
                    className={classes.pagination}
                    component="div"
                    page={
                      typeof query.page !== "undefined" ? query.page - 1 : 0
                    }
                    rowsPerPage={query.page_size || 10}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePageSizeChange}
                    count={routeInstancesCount}
                    rowsPerPageOptions={[
                      5,
                      10,
                      20,
                      { label: "All", value: -1 }
                    ]}
                  />
                )}
              </TableContainer>
              {rowDetails && rowDetails.row && toggledMap && (
                <div>
                  <RowDetailsComponent
                    routeinstance={rowDetails.row}
                    expandedId={expandedRowId}
                    toggledMap={toggledMap}
                    onMouseEnter={onHoverRowIn}
                    onMouseLeave={onHoverRowOut}
                    selectedTask={selectedTask}
                    toggleOrderAndTask={toggleOrderAndTask}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={toggledMap ? 9 : 1} className={classes.mapContainer}>
              <Paper
                className={clsx(
                  classes.mapPaper,
                  !toggledMap && classes.mapPaperShrink
                )}
              >
                <Tooltip title={t("toggleMapTooltip")} placement="left" arrow>
                  <Paper
                    className={clsx(
                      classes.mapGrid,
                      toggledMap ? classes.mapToggled : ""
                    )}
                    onClick={handleMapToggle}
                  >
                    <MapIcon className={classes.iconCenter} />
                  </Paper>
                </Tooltip>
                {toggledMap && expandedRowId > 0 ? (
                  <div className={classes.mapView}>
                    <Slide in={toggledMap} direction="left">
                      <div
                        className={
                          isDescriptionShown
                            ? classes.mapStyles
                            : classes.fullHeight
                        }
                      >
                        {mapDrawings &&
                        ((mapDrawings.markers && mapDrawings.markers.length) ||
                          mapDrawings.geoPolygons) ? (
                          <GoogleMaps
                            id="ordersMap"
                            markerRefs={setMarkerRef}
                            options={defaultMapOptions}
                            markers={mapDrawings.markers}
                            onMarkerClick={onMarkerClick}
                            onMarkerMouseover={onMarkerIn}
                            onMarkerMouseout={onMarkerOut}
                            className={classes.mapFlexBasis}
                            geoPolygons={mapDrawings.geoPolygons}
                          />
                        ) : (
                          <MapContainer
                            editable={false}
                            isInDialog={false}
                            geo_polygons={null}
                            {...mapTabRouteProps(props)}
                          />
                        )}
                        {selectedTask && (
                          <div className={classes.descriptionBox}>
                            {selectedTask.work_description
                              ? selectedTask.work_description
                              : t2("taskDescription")}
                          </div>
                        )}
                      </div>
                    </Slide>
                  </div>
                ) : (
                  <>
                    {toggledMap && (
                      <div className={classes.mapView}>
                        <div className={classes.mapnoToggled}></div>
                      </div>
                    )}
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default RouteInstances;
