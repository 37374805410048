import BaseItems from "../../../components/contracts/BaseItems";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../../redux/types";
import { LocalShipping } from "@material-ui/icons";
import ContractItemContainer from "../services/ContractItemContainer";
import Basesupplement from "../../../components/contracts/supplements/Basesupplement";

const SupplimentsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/basesupplements"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/basesupplements",
        label: t("contractNavbarBaseSupplementsLabel"),
        component: BaseItems,
        subTabIndex: 5,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS,
        icon: LocalShipping
      },
      {
        path: "/contracts/basesupplements/:baseSupplementId",
        parent: "/contracts/contracts",
        label: t("baseSupplementLabel"),
        component: Basesupplement,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/:contractId/supplements/:contractItemId",
        parent: "/contracts/supplements",
        label: t("supplementLabel"),
        component: ContractItemContainer,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/baseitems",
        label: t("baseItemsLabel"),
        component: BaseItems,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      }
    ]
  }
];

export default SupplimentsRouter;
