import React, { createContext, useContext, useEffect, useRef } from "react";
import { getFromSessionStore } from "../services/storageService";

type ChannelFilterNames = "POSITION" | "NOTIFICATION";

type Command =
  | { cmd: "AUTH"; token: string }
  | { cmd: "FILTER"; name: ChannelFilterNames }
  | { cmd: "STOP"; name: ChannelFilterNames };

type CommandResponseMessage = {
  message_type: "COMMAND_RESPONSE";
  cmd: Command["cmd"];
  code: number;
};

export type PositionMessage = {
  message_type: "POSITION";
  user_id: number;
  company_id: number;
  latitude: number;
  longitude: number;
  accuracy: number;
  timestamp: number;
};

export type NotificationMessage = {
  message_type: "NOTIFICATION";
  event_type: string;
  item_id: number;
  message: string;
  timestamp: number;
};

type AuthExpiredMessage = {
  message_type: "AUTH_EXPIRED";
};

type Message =
  | PositionMessage
  | NotificationMessage
  | AuthExpiredMessage
  | CommandResponseMessage;

enum ChannelState {
  Unauthorized,
  Open,
  Closed
}

enum FilterState {
  New,
  Sent,
  Active,
  Stopped,
  Error
}

export type Channel = {
  state: ChannelState;
  filters: { name: ChannelFilterNames; state: FilterState }[];
  listeners: (
    | { filter: "POSITION"; f: (pos: PositionMessage) => void }
    | { filter: "NOTIFICATION"; f: (pos: NotificationMessage) => void }
  )[];
  socket: WebSocket;
};

const sendCmd = (channel: Channel, cmd: Command) => {
  channel.socket.send(JSON.stringify(cmd));
};

const activateNewFilter = (
  channel: Channel
): Channel["filters"][number] | undefined => {
  const canActivate =
    channel.state === ChannelState.Open &&
    !channel.filters.some((f) => f.state === FilterState.Sent);
  const filter = canActivate
    ? channel.filters.find((f) => f.state === FilterState.New)
    : undefined;
  if (filter) {
    filter.state = FilterState.Sent;
    sendCmd(channel, { cmd: "FILTER", name: filter.name });
  }
  return filter;
};

const stopInactiveFilters = (channel: Channel): Channel["filters"] => {
  const [keep, remove] = channel.filters.reduce(
    (acc, f) => {
      if (channel.listeners.some((l) => l.filter === f.name)) {
        acc[0].push(f);
      } else {
        acc[1].push(f);
      }
      return acc;
    },
    [[], []] as [Channel["filters"], Channel["filters"]]
  );

  remove.forEach((f) => {
    f.state = FilterState.Stopped;
    sendCmd(channel, { cmd: "STOP", name: f.name });
  });
  channel.filters = keep;
  return remove;
};

let lastAuthTimestamp = 0;
const authenticate = (channel: Channel) => {
  if (channel.state === ChannelState.Closed) {
    return;
  }
  const token = getFromSessionStore("access_token");
  if (token && Date.now() - lastAuthTimestamp > 5000) {
    lastAuthTimestamp = Date.now();
    sendCmd(channel, { cmd: "AUTH", token });
  } else {
    setTimeout(() => authenticate(channel), 5000);
  }
};

const onChannelMessage = (channel: Channel, m: Readonly<Message>) => {
  if (m.message_type === "AUTH_EXPIRED") {
    channel.state = ChannelState.Unauthorized;
    authenticate(channel);
  } else if (m.message_type === "COMMAND_RESPONSE") {
    if (m.cmd === "AUTH") {
      if (m.code === 200) {
        channel.state = ChannelState.Open;
        activateNewFilter(channel);
      } else if (m.code === 401) {
        authenticate(channel);
      } else {
        console.error("Unknown auth error", m);
      }
    } else if (m.cmd === "FILTER") {
      const sent_filter = channel.filters.find(
        (f) => f.state === FilterState.Sent
      );
      if (sent_filter) {
        sent_filter.state =
          m.code === 200 ? FilterState.Active : FilterState.Error;
        if (m.code !== 200) {
          console.error(`error ${sent_filter.name}`, m);
        }
      }

      activateNewFilter(channel);
    }
  } else if (m.message_type === "POSITION") {
    channel.listeners
      .filter(
        (l): l is typeof l & { filter: "POSITION" } => l.filter === "POSITION"
      )
      .forEach((l) => l.f(m));
  } else if (m.message_type === "NOTIFICATION") {
    channel.listeners
      .filter(
        (l): l is typeof l & { filter: "NOTIFICATION" } =>
          l.filter === "NOTIFICATION"
      )
      .forEach((l) => l.f(m));
  }
};

let lastConnectTimestamp = 0;
const createChannel = (): Channel | null => {
  const ws = window.localStorage.getItem("ws");
  if (!ws) {
    throw new Error("Web socket not available on tenant");
  }

  const channel: Channel = {
    state: ChannelState.Unauthorized,
    filters: [],
    listeners: [],
    socket: new WebSocket(ws)
  };

  const openHandler = (_e: Event) => {
    authenticate(channel);
  };
  const messageHandler = (e: MessageEvent<string>) => {
    const m = JSON.parse(e.data);
    onChannelMessage(channel, m);
  };
  const errorHandler = (e: Event) => {
    console.error(e);
  };
  const closeHandler = (_e: CloseEvent) => {
    if (channel.state === ChannelState.Closed) {
      return;
    }
    if (Date.now() - lastConnectTimestamp > 5000) {
      lastConnectTimestamp = Date.now();
      // Reset channel
      channel.state = ChannelState.Unauthorized;
      channel.filters = channel.listeners.map((l) => ({
        name: l.filter,
        state: FilterState.New
      }));
      channel.socket = new WebSocket(ws);
      channel.socket.onopen = openHandler;
      channel.socket.onmessage = messageHandler;
      channel.socket.onerror = errorHandler;
      channel.socket.onclose = closeHandler;
    } else {
      setTimeout(() => closeHandler(_e), 5000);
    }
  };

  channel.socket.onopen = openHandler;
  channel.socket.onmessage = messageHandler;
  channel.socket.onerror = errorHandler;
  channel.socket.onclose = closeHandler;

  return channel;
};

export const closeChannel = (channel: Channel) => {
  channel.state = ChannelState.Closed;
  channel.socket.close();
};

export const addPositionListener = (
  channel: Channel,
  f: (m: PositionMessage) => void
): (() => void) => {
  channel.listeners.push({ filter: "POSITION", f });
  if (!channel.filters.some((f) => f.name === "POSITION")) {
    channel.filters.push({ name: "POSITION", state: FilterState.New });
    activateNewFilter(channel);
  }
  return () => {
    channel.listeners = channel.listeners.filter((filter) => filter.f !== f);
    stopInactiveFilters(channel);
  };
};

export const addNotificationListener = (
  channel: Channel,
  f: (m: NotificationMessage) => void
): (() => void) => {
  channel.listeners.push({ filter: "NOTIFICATION", f });
  if (!channel.filters.some((f) => f.name === "NOTIFICATION")) {
    channel.filters.push({ name: "NOTIFICATION", state: FilterState.New });
    activateNewFilter(channel);
  }
  return () => {
    channel.listeners = channel.listeners.filter((filter) => filter.f !== f);
    stopInactiveFilters(channel);
  };
};

const ChannelContext = createContext<Channel | null>(null);

export const ChannelProvider: React.FC<{}> = ({ children }) => {
  const channelRef = useRef<Channel | null>(null);
  if (!channelRef.current) {
    channelRef.current = createChannel();
  }

  useEffect(
    () => () => {
      if (channelRef.current) {
        closeChannel(channelRef.current);
        channelRef.current = null;
      }
    },
    []
  );

  return (
    <ChannelContext.Provider value={channelRef.current}>
      {children}
    </ChannelContext.Provider>
  );
};

export const useChannel = () => useContext(ChannelContext);
