import React from "react";
import { Link } from "@material-ui/core";
import * as Router from "react-router-dom";

interface ResourceLinkProps {
  label: string;
  url: string;
  id: number;
  extra?: string;
  colorClass?: string;
}

const ResourceLink: React.FC<ResourceLinkProps> = (props) => (
  <Link
    className={props.colorClass || ""}
    component={React.forwardRef<HTMLAnchorElement, Partial<Router.LinkProps>>(
      (linksProps, ref) => (
        <Router.Link
          to={`/${props.url}/${props.id}${props.extra || ""}`}
          {...linksProps}
          ref={ref as any}
        />
      )
    )}
  >
    {props.label || ""}
  </Link>
);

export default ResourceLink;
