import { PasscardsState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: PasscardsState = {
  passcards: {
    passcards: [],
    next: null,
    previous: null,
    count: 0
  },
  passcard: {
    passcard: {
      id: -1,
      created_at: "",
      updated_at: "",
      status: "ACTIVE",
      passcard_type: "PASSCARD",
      serial_number: "",
      notes: "",
      code: "",
      key_number: "",
      in_date: "",
      out_date: "",
      owner_company: -1,
      contractor_company: -1,
      contractor_person: -1,
      customerobjects: []
    },
    customers: [],
    contractors: [],
    owner_company: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      status: "ACTIVE",
      company_type: "CUSTOMER",
      membership_type: "STANDARD",
      organisation_number: "",
      invoice_ref: "",
      website: "",
      logo: null,
      notes: "",
      accounting_reference: 0,
      payment_account: "",
      external: false,
      passcard_type: "PASSCARD",
      after_registration: false,
      owner: -1,
      districts: [],
      location: null,
      invoicerecipients: [],
      cooperations: [],
      contact_person: null,
      invoice_location: null
    },
    contractor_company: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      status: "ACTIVE",
      company_type: "CUSTOMER",
      membership_type: "STANDARD",
      organisation_number: "",
      invoice_ref: "",
      website: "",
      logo: null,
      notes: "",
      accounting_reference: 0,
      payment_account: "",
      external: false,
      passcard_type: "PASSCARD",
      after_registration: false,
      owner: -1,
      districts: [],
      location: null,
      invoicerecipients: [],
      cooperations: [],
      contact_person: null,
      invoice_location: null
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PASSCARDS):
      return {
        ...state,
        passcards: {
          ...state.passcards,
          passcards: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PASSCARD):
      return {
        ...state,
        passcard: {
          ...state.passcard,
          passcard: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.PASSCARD):
      return {
        ...state,
        passcards: {
          ...state.passcards,
          passcards: [...state.passcards.passcards, action.payload]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.PASSCARD
    ):
      return {
        ...state,
        passcards: {
          ...state.passcards,
          passcards: [
            ...state.passcards.passcards.map((passcard) =>
              passcard.id === action.payload.id ? action.payload : passcard
            )
          ]
        }
      };
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.PASSCARD
    ):
      return {
        ...state,
        passcards: {
          ...state.passcards,
          passcards: [
            ...state.passcards.passcards.filter(
              (passcard) => passcard.id !== parseInt(action.payload.id)
            )
          ]
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PASSCARD_CUSTOMER
    ):
      return {
        ...state,
        passcard: {
          ...state.passcard,
          owner_company: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PASSCARD_CONTRACTOR
    ):
      return {
        ...state,
        passcard: {
          ...state.passcard,
          contractor_company: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PASSCARD_CONTRACTORS
    ):
      return {
        ...state,
        passcard: {
          ...state.passcard,
          contractors: action.payload.results
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PASSCARD_CUSTOMERS
    ):
      return {
        ...state,
        passcard: {
          ...state.passcard,
          customers: action.payload.results
        }
      };

    default:
      return state;
  }
}

export default reducer;
