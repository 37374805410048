import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, District } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import ResourceLink from "../../../components/links/ResourceLink";

interface ParentDistrictLinkContainerProps {
  parentdistrictId: number;
  parentdistrict: District;
  loadParentDistrict: (parentdistrictId: number) => void;
}

const ParentDistrictLinkContainer: React.FC<
  ParentDistrictLinkContainerProps
> = (props) => {
  const { loadParentDistrict, parentdistrictId, parentdistrict } = props;
  useEffect(
    () => loadParentDistrict(parentdistrictId),
    [loadParentDistrict, parentdistrictId]
  );
  return (
    <span>
      {parentdistrict && (
        <ResourceLink
          label={parentdistrict.name}
          id={parentdistrict.id}
          url="companies/districts"
        />
      )}
    </span>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadParentDistrict: (parentdistrictId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PARENT_DISTRICT,
        `companies/districts/${parentdistrictId}/`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  parentdistrict:
    state.tabStates[ownProps.tabId].districts.district.parent_district
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentDistrictLinkContainer);
