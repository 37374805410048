import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  GroupForm,
  Group,
  mainRootState,
  TabComponentProps
} from "../../redux/types";
import GroupNewEditForm from "../../components/groups/GroupNewEditForm";
import * as RestActions from "../../redux/sagas/restActions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";
import LoadingSpinnerPaper from "../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface EditGroupContainerProps
  extends FormikSubmitDispatchProps<GroupForm>,
    TabComponentProps<"groupId"> {
  group: Group;
  loadGroup: (groupId: number) => void;
  loadingGroup: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Group | GroupForm> {
  loadGroup: (groupId: number) => void;
}

const EditGroupContainer: React.FC<EditGroupContainerProps> = (props) => {
  const {
    loadGroup,
    group,
    routeParams: { groupId },
    loadingGroup
  } = props;
  useEffect(() => loadGroup(groupId), [loadGroup, groupId]);

  return loadingGroup ? (
    <LoadingSpinnerPaper />
  ) : (
    <GroupNewEditForm
      group={group}
      handleSubmit={props.handleSubmit}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.GROUP,
        `auth/groups/${(checkedNullValues as Group).id}/`,
        checkedNullValues,
        `/auth/groups/${(checkedNullValues as Group).id}?same_tab=true`
      )
    );
  },
  loadGroup: (groupId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.GROUP,
        `auth/groups/${groupId}/`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  group: state.tabStates[ownProps.tabId].groups.group.group,
  loadingGroup: state.tabStates[ownProps.tabId].loadingBooleans.loadingGroup
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupContainer);
