import React from "react";
import { WithStyles, Paper, Typography } from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import CreateProspectButtonContainer from "../../containers/contracts/prospects/CreateProspectButtonContainer";
import { mapTabRouteProps, validateApiUsage } from "../../helpers/routesHelper";
import Eventlog from "../events/Events";

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20
    }
  });

interface UnderConstructionProps extends WithStyles<typeof styles> {}

const UnderConstruction: React.FC<UnderConstructionProps> = (props) => {
  const t = useTranslate("HomePage");
  const { classes } = props;

  const showProspectButton = validateApiUsage({
    apiUsage: ["contracts/prospects"]
  });

  const showEvents = validateApiUsage({
    apiUsage: ["events/events"]
  });

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h5">{t("HomeTitle")}</Typography>
        <br />
        {showProspectButton && (
          <CreateProspectButtonContainer {...mapTabRouteProps(props)} />
        )}
      </Paper>
      {showEvents && <Eventlog limit={20} hideFilter />}
    </>
  );
};

export default withStyles(styles)(UnderConstruction);
