import { createStyles } from "@material-ui/core/styles";
import { Add as AddIcon } from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, Button } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import React from "react";
import {
  FilterDefinition,
  ResourcesButtonDefinition,
  SelectedFilters
} from "../../../redux/types";
import { convertToPlural, snakeToCamel } from "../../FormatHelpers";
import * as Router from "react-router-dom";
import { useTranslate } from "../../../services/appLanguageService";

const styles = (theme: any) =>
  createStyles({
    rightFloat: {
      float: "right",
      marginBottom: 10,
      marginTop: 10
    },
    resourceButton: {
      marginLeft: 10,
      marginTop: 10
    },
    filterButton: {
      marginRight: 10,
      marginTop: 10
    },
    container: {
      display: "flex",
      justifyContent: "space-between"
    },
    addNew: {
      marginBottom: 10,
      marginTop: 10
    }
  });

interface TableHeaderButtonsProps extends WithStyles<typeof styles> {
  filterDefinitions: FilterDefinition[];
  resourcesButtons?: ResourcesButtonDefinition[];
  showNewButton?: boolean;
  handleFlags: (flag: string) => void;
  resourceName: string;
  routeUrl: string;
  selectedFilters: SelectedFilters;
}

const TableHeaderButtons: React.FC<TableHeaderButtonsProps> = (props) => {
  const {
    filterDefinitions,
    classes,
    selectedFilters,
    resourceName,
    routeUrl
  } = props;

  const pluralName = convertToPlural(resourceName);

  const t = useTranslate(`${pluralName}Page`);

  return (
    <>
      <div className={`${filterDefinitions}` ? classes.container : ""}>
        <div>
          {filterDefinitions.map((filterDefinition) => (
            <ToggleButton
              key={filterDefinition.name}
              value={filterDefinition.name}
              size="small"
              className={classes.filterButton}
              selected={selectedFilters[filterDefinition.name]}
              onChange={() => {
                selectedFilters[filterDefinition.name] =
                  !selectedFilters[filterDefinition.name];
                props.handleFlags(filterDefinition.flag);
              }}
            >
              {t(`${snakeToCamel(filterDefinition.name)}Label`)}
            </ToggleButton>
          ))}
        </div>
        <div>
          {props.resourcesButtons &&
            props.resourcesButtons.map((resourcesButton) => (
              <Button
                key={resourcesButton.name}
                id={`${resourcesButton.name}`}
                data-cy={`${resourcesButton.name}`}
                variant="contained"
                color="primary"
                className={`${classes.resourceButton} ${classes.rightFloat}`}
                component={React.forwardRef<
                  HTMLAnchorElement,
                  Partial<Router.LinkProps>
                >((props, ref) => (
                  <Router.Link
                    to={`/${resourcesButton.url}`}
                    {...props}
                    ref={ref}
                  />
                ))}
              >
                {t(`${resourcesButton.name}Label`)}
              </Button>
            ))}
          {(typeof props.showNewButton === "undefined" ||
            (typeof props.showNewButton !== "undefined" &&
              props.showNewButton)) && (
            <Button
              id="resources-new-resource"
              data-cy={`${resourceName.toLowerCase()}-new-${resourceName.toLowerCase()}`}
              variant="contained"
              color="primary"
              className={
                filterDefinitions.length !== 0
                  ? classes.rightFloat
                  : classes.addNew
              }
              component={React.forwardRef<
                HTMLAnchorElement,
                Partial<Router.LinkProps>
              >((props, ref) => (
                <Router.Link to={`/${routeUrl}/new`} {...props} ref={ref} />
              ))}
            >
              <AddIcon />
              {t(`add${resourceName}Label`)}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(TableHeaderButtons);
