import React, { useCallback, useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, IconButton } from "@material-ui/core";
import {
  Edit as EditIcon,
  Done as DoneIcon,
  Close as CloseIcon,
  DeleteOutline as DeleteIcon,
  Map as MapIcon,
  Lock as LockIcon
} from "@material-ui/icons";
import {
  ColumnDefinition,
  FilterDefinition,
  ResourcesButtonDefinition,
  SelectedFilters,
  TableForm
} from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import { Column } from "react-table";
import ResourceLink from "../links/ResourceLink";
import {
  useConfirmationDialog,
  ConfirmationDialogServiceProvider
} from "../confirmationDialog/ConfirmationDialogServiceProvider";
import ImageContainer from "../../containers/ImageContainer";
import TableFormComponent from "./components/TableFormComponent";
import ColumnHeader from "./components/ColumnHeader";
import { convertToPlural, niceAmount, snakeToCamel } from "../FormatHelpers";
import ResourceTable from "./ResourceTable";
import validate from "./components/validate";
import TableHeaderButtons from "./components/TableHeaderButtons";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { debounce } from "lodash";

const styles = (theme: any) =>
  createStyles({
    rightFloat: {
      float: "right"
    },
    indent: {
      marginLeft: 40
    },
    leftAlign: {
      textAlign: "left"
    },
    tableHead: {
      backgroundColor: "#FFF",
      fontWeight: "bold"
    },
    hover: {
      "&:hover": {
        color: "#777"
      },
      cursor: "pointer"
    },
    actionButton: {
      padding: 8,
      marginRight: "-5px"
    },
    sameRow: {
      display: "flex"
    }
  });

interface ResourcesTableContainerProps extends WithStyles<typeof styles> {
  resources: any[];
  resourceName: string;
  count: number;
  loadingResources: boolean;
  routeUrl: string;
  columnDefinitions: ColumnDefinition[];
  defaultPageSize: number;
  customHeader?: string;
  customFilter?: string;
  showNewButton?: boolean;
  filterDefinitions: FilterDefinition[];
  resourcesButtons?: ResourcesButtonDefinition[];
  resetResources: () => void;
  loadResources: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => void;
  addResource?: (
    newData: any,
    owningId?: number,
    secondOwningId?: number
  ) => void;
  updateResource?: (resourceId: number, newData: any) => void;
  deleteResource?: (resourceId: number, secondResourceId?: number) => void;
  customDeleteResource?: (rowData: any) => void;
  owningId?: number;
  secondOwningId?: number;
  nrOfDeletableItems?: (rowData: any) => number;
  groupBy?: boolean;
  customEditFunction?: (rowData: any) => void;
  altFieldType?: string;
  handleSelectedRow?: (id: number) => void;
  selectedRow?: number;
  isItemEditLocked?: (rowData: any) => boolean;
  customHeaderComponent?: React.ReactNode;
}

function emptyObject(obj: object) {
  for (const i in obj) return false;
  return true;
}

const ResourcesTableContainer: React.FC<ResourcesTableContainerProps> = (
  props
) => {
  const {
    classes,
    columnDefinitions,
    routeUrl,
    resourceName,
    customFilter,
    filterDefinitions,
    updateResource,
    deleteResource,
    nrOfDeletableItems,
    customDeleteResource,
    handleSelectedRow,
    selectedRow,
    isItemEditLocked
  } = props;

  const pluralName = convertToPlural(resourceName);

  const confirmationDialog = useConfirmationDialog();

  const [selectedFilters] = React.useState<SelectedFilters>(() => {
    let tmpObj: SelectedFilters = {};

    filterDefinitions.forEach(
      (filterDefinition) =>
        (tmpObj[filterDefinition.name] = filterDefinition.selectedByDefualt)
    );

    return tmpObj;
  });

  const [selectedFlags] = React.useState<string[]>(() =>
    filterDefinitions
      .filter((filterDefinition) => filterDefinition.selectedByDefualt)
      .map((filterDefinition) => filterDefinition.flag)
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(props.defaultPageSize);
  const [currentSorting, setCurrentSorting] = useState("none");
  const [editableId, setEditableId] = useState<number | null>(null);
  const [editForm, setEditForm] = useState<TableForm>({});
  const [addForm, setAddForm] = useState<TableForm>({});
  const [addingResource, setAddingResource] = useState(false);
  const [focusedField, setFocusedField] = useState<string>("");
  const t = useTranslate(`${pluralName}Page`);
  const t2 = useTranslate(pluralName);
  const t3 = useTranslate("ResourceTable");
  const [formValidation, setFormValidation] = useState<{
    [field: string]: { error: boolean; errorText: string };
  }>({});
  const [searchText, setSearchText] = useState("");

  const handleFormValidation = (
    field: string,
    validation: { error: boolean; errorText: string }
  ) =>
    setFormValidation((prevState) => ({
      ...prevState,
      [field]: validation
    }));

  const handleFormValidationCb = useCallback(handleFormValidation, [
    handleFormValidation
  ]);

  const validated =
    Object.values(formValidation)
      .map((field) => field.error)
      .indexOf(true) === -1;

  useEffect(() => {
    if (!emptyObject(editForm) && emptyObject(formValidation)) {
      columnDefinitions.forEach((columnDefinition) => {
        validate(
          { [columnDefinition.name]: editForm[columnDefinition.name] },
          columnDefinition,
          handleFormValidationCb
        );
      });
    }
  }, [editForm, formValidation, columnDefinitions, handleFormValidationCb]);

  useEffect(() => {
    if (!emptyObject(addForm) && emptyObject(formValidation)) {
      columnDefinitions.forEach((columnDefinition) => {
        validate(
          { [columnDefinition.name]: addForm[columnDefinition.name] },
          columnDefinition,
          handleFormValidationCb
        );
      });
    }
  }, [addForm, formValidation, columnDefinitions, handleFormValidationCb]);

  const data = React.useMemo(() => props.resources, [props.resources]);

  const handleFlags = (flag: string) => {
    if (selectedFlags.includes(flag)) {
      const tmpSelectedFlags = selectedFlags.filter(
        (tmpFlag: string) => tmpFlag !== flag
      );
      while (selectedFlags.length > 0) {
        selectedFlags.pop();
      }
      selectedFlags.push(...tmpSelectedFlags);
    } else {
      selectedFlags.push(flag);
    }

    props.loadResources(
      currentPage + 1,
      currentPageSize,
      undefined,
      currentSorting,
      customFilter,
      selectedFlags
    );
    props.resetResources();
  };

  const closeEditing = () => {
    setEditableId(null);
  };

  const handleSearch = debounce((searchString: string) => {
    setCurrentPage(0);
    setSearchText(searchString);
    props.loadResources(
      1,
      currentPageSize,
      searchString,
      currentSorting,
      customFilter,
      selectedFlags
    );
    props.resetResources();
  }, 700);

  const handleSorting = (columnDefinition: ColumnDefinition) => {
    if (
      typeof columnDefinition.sortable !== "undefined" &&
      columnDefinition.sortable
    ) {
      let newSorting = "";
      if (currentSorting === "none") {
        newSorting = "asc";
        setCurrentSorting(newSorting);
      } else if (currentSorting === "asc") {
        newSorting = "desc";
        setCurrentSorting(newSorting);
      } else {
        newSorting = "none";
        setCurrentSorting(newSorting);
      }
      props.loadResources(
        currentPage + 1,
        currentPageSize,
        undefined,
        newSorting,
        customFilter,
        selectedFlags
      );
      props.resetResources();
    }
  };

  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
    props.loadResources(
      page + 1,
      currentPageSize,
      searchText,
      currentSorting,
      customFilter,
      selectedFlags
    );
    props.resetResources();
  };

  const handleRowsPerPageChange = (event: any) => {
    const newPageSize = event.target.value;
    let newPage = currentPage;
    if (currentPageSize < newPageSize) {
      setCurrentPage(0);
      newPage = 0;
    }
    setCurrentPageSize(newPageSize);
    props.loadResources(
      newPage + 1,
      newPageSize,
      undefined,
      currentSorting,
      customFilter,
      selectedFlags
    );
    props.resetResources();
  };

  const handleDelete = async (resourceId: number) => {
    if (props.deleteResource) {
      await confirmationDialog({
        title: t3("confirmationTitle"),
        description: t3("confirmationDescription")
      });
      props.deleteResource(resourceId);
    }
  };

  const getEditActions = (id: number | null) =>
    id !== null &&
    updateResource && (
      <div className={`${classes.rightFloat} ${classes.sameRow}`}>
        <IconButton
          aria-label="edit"
          data-cy={`${pluralName}-done-edit`}
          onClick={() => {
            if (validated) {
              updateResource(editForm["id"], editForm);
              closeEditing();
            }
          }}
        >
          <DoneIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          className={classes.actionButton}
          onClick={closeEditing}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );

  const handleFocusedField = (field: string) => setFocusedField(field);

  const handleSortingCb = useCallback(handleSorting, [handleSorting]);
  const getEditActionsCb = useCallback(getEditActions, [getEditActions]);
  const handleDeleteCb = useCallback(handleDelete, [handleDelete]);

  const firstColumn = columnDefinitions[0].name;

  let columns: Column<any>[] = React.useMemo(
    () => [
      ...columnDefinitions.map((columnDefinition) => {
        if (
          columnDefinition.type === "link" ||
          columnDefinition.type === "editLink"
        ) {
          return {
            accessor: columnDefinition.name,
            Header: (
              <ColumnHeader
                columnDefinition={columnDefinition}
                pluralName={pluralName}
                handleSorting={handleSortingCb}
                classes={classes}
                currentSorting={currentSorting}
              />
            ),
            Cell: ({ row }: { row: any }) =>
              row.index === editableId ? (
                <TableFormComponent
                  columnDefinition={columnDefinition}
                  handleForm={handleEditForm}
                  formData={editForm}
                  pluralName={pluralName}
                  type={"EDIT"}
                  handleFocusedField={handleFocusedField}
                  focusedField={focusedField}
                  handleFormValidation={handleFormValidationCb}
                  formValidation={formValidation[columnDefinition.name]}
                  altFieldType={props.altFieldType}
                />
              ) : (
                <ResourceLink
                  url={routeUrl}
                  id={row.values["id"]}
                  label={row.values[columnDefinition.name]}
                  extra={columnDefinition.type === "editLink" ? "/edit" : ""}
                />
              )
          };
        } else if (columnDefinition.type === "joined") {
          const joined = columnDefinitions.find(
            (columnDef: ColumnDefinition) =>
              columnDef.name === columnDefinition.joined
          );
          return {
            accessor: columnDefinition.name,
            Header: (
              <ColumnHeader
                columnDefinition={columnDefinition}
                pluralName={pluralName}
                handleSorting={handleSortingCb}
                classes={classes}
                currentSorting={currentSorting}
              />
            ),
            Cell: ({ row }: { row: any }) =>
              row.index === editableId ? (
                <TableFormComponent
                  columnDefinition={columnDefinition}
                  handleForm={handleEditForm}
                  formData={editForm}
                  pluralName={pluralName}
                  type={"EDIT"}
                  handleFocusedField={handleFocusedField}
                  focusedField={focusedField}
                  handleFormValidation={handleFormValidationCb}
                  formValidation={formValidation[columnDefinition.name]}
                  joinedFormValidation={
                    columnDefinition.joined
                      ? formValidation[columnDefinition.joined]
                      : undefined
                  }
                  joinColumnDefinition={joined}
                  altFieldType={props.altFieldType}
                />
              ) : (
                <ResourceLink
                  url={routeUrl}
                  id={row.values["id"]}
                  label={`${row.values[columnDefinition.name]} ${
                    columnDefinition.joined
                      ? row.values[columnDefinition.joined]
                      : ""
                  }`}
                />
              )
          };
        } else if (
          columnDefinition.type === "foreignLink" ||
          columnDefinition.type === "foreign"
        ) {
          return {
            id: columnDefinition.name,
            Header: (
              <div className={`${classes.leftAlign}`}>
                {t(`${snakeToCamel(columnDefinition.name)}Label`)}
              </div>
            ),
            accessor: columnDefinition.name,
            Cell: ({ row }: { row: any }) => {
              if (row.index === editableId) {
                return (
                  <TableFormComponent
                    columnDefinition={columnDefinition}
                    handleForm={handleEditForm}
                    formData={editForm}
                    pluralName={pluralName}
                    type={"EDIT"}
                    handleFormValidation={handleFormValidationCb}
                    formValidation={formValidation[columnDefinition.name]}
                    altFieldType={props.altFieldType}
                  />
                );
              }

              if (
                columnDefinition.foreignData &&
                row.values[columnDefinition.name]
              ) {
                const resource =
                  columnDefinition.foreignData.data.find(
                    (resource: any) =>
                      resource.id === row.values[columnDefinition.name]
                  ) || null;
                const nrOfVisibleColumns =
                  columnDefinition.foreignData.visibleColumns.length;

                const label =
                  resource && nrOfVisibleColumns > 0
                    ? columnDefinition.foreignData.visibleColumns.reduce(
                        (total, visibleColumn) =>
                          `${total} ${resource[visibleColumn]}`,
                        ""
                      )
                    : "";

                return columnDefinition.type === "foreign"
                  ? label
                  : row.values[columnDefinition.name] &&
                      columnDefinition.foreignData.url && (
                        <ResourceLink
                          url={columnDefinition.foreignData.url}
                          id={row.values[columnDefinition.name]}
                          label={label}
                        />
                      );
              }

              return <></>;
            }
          };
        } else if (columnDefinition.type === "multipleLinks") {
          return {
            id: columnDefinition.name,
            Header: (
              <div className={`${classes.leftAlign}`}>
                {t(`${snakeToCamel(columnDefinition.name)}Label`)}
              </div>
            ),
            accessor: columnDefinition.name,
            Cell: ({ row }: { row: any }) => {
              if (row.index === editableId) {
                return (
                  <TableFormComponent
                    columnDefinition={columnDefinition}
                    handleForm={handleEditForm}
                    formData={editForm}
                    pluralName={pluralName}
                    type={"EDIT"}
                    handleFormValidation={handleFormValidationCb}
                    formValidation={formValidation[columnDefinition.name]}
                    altFieldType={props.altFieldType}
                  />
                );
              }

              const multipleData = columnDefinition.multipleData;

              if (
                typeof multipleData !== "undefined" &&
                typeof multipleData.url !== "undefined"
              ) {
                if (
                  row.values[columnDefinition.name] &&
                  row.values[columnDefinition.name].length > 3
                ) {
                  return (
                    <ResourceLink
                      url={routeUrl}
                      id={row.values["id"]}
                      label={`${row.values[columnDefinition.name].length} ${t(
                        `${snakeToCamel(columnDefinition.name)}Label`
                      )}`}
                    />
                  );
                } else if (
                  row.values[columnDefinition.name] &&
                  row.values[columnDefinition.name].length > 0
                ) {
                  return row.values[columnDefinition.name].map(
                    (currentResourceId: number, index: number) => {
                      const resource =
                        multipleData.data.find(
                          (resource: any) => resource.id === currentResourceId
                        ) || null;
                      return (
                        <React.Fragment key={currentResourceId}>
                          <ResourceLink
                            url={multipleData.url}
                            id={currentResourceId}
                            label={
                              resource &&
                              multipleData.visibleColumns.reduce(
                                (total, visibleColumn) =>
                                  `${total} ${resource[visibleColumn]}`,
                                ""
                              )
                            }
                          />
                          <br />
                        </React.Fragment>
                      );
                    }
                  );
                }
              }
              return <></>;
            }
          };
        } else if (columnDefinition.type === "enum") {
          return {
            accessor: columnDefinition.name,
            Header: (
              <div className={`${classes.leftAlign}`}>
                {t(`${snakeToCamel(columnDefinition.name)}Label`)}
              </div>
            ),
            Aggregated: (cellProps: any) => <></>,
            Cell: ({ row }: { row: any }) => {
              return row.index !== null && row.index === editableId ? (
                <TableFormComponent
                  columnDefinition={columnDefinition}
                  handleForm={handleEditForm}
                  formData={editForm}
                  pluralName={pluralName}
                  type={"EDIT"}
                  handleFormValidation={handleFormValidationCb}
                  formValidation={formValidation[columnDefinition.name]}
                  altFieldType={props.altFieldType}
                />
              ) : (
                row.values[columnDefinition.name] &&
                  t2(row.values[columnDefinition.name])
              );
            }
          };
        } else if (columnDefinition.type === "hidden") {
          return {
            accessor: columnDefinition.name
          };
        } else if (
          columnDefinition.type === "thumbnail" ||
          columnDefinition.type === "roundThumbnail"
        ) {
          return {
            Header: "",
            Cell: ({ value }: { value: any }) => (
              <ImageContainer
                src={value}
                type={
                  columnDefinition.type === "thumbnail"
                    ? "thumbnail"
                    : "round_thumbnail"
                }
              />
            ),
            accessor: columnDefinition.name,
            width: 40
          };
        } else if (columnDefinition.type === "boolean") {
          return {
            Header: (
              <div className={`${classes.leftAlign}`}>
                {t(`${snakeToCamel(columnDefinition.name)}Label`)}
              </div>
            ),
            Cell: (cellProps: any) =>
              cellProps.row.index === editableId ? (
                <TableFormComponent
                  columnDefinition={columnDefinition}
                  handleForm={handleEditForm}
                  formData={editForm}
                  type={"EDIT"}
                  pluralName={pluralName}
                  handleFormValidation={handleFormValidationCb}
                  formValidation={formValidation[columnDefinition.name]}
                  altFieldType={props.altFieldType}
                />
              ) : columnDefinition.editableForm &&
                columnDefinition.editableForm === "star" ? (
                cellProps.row.values[columnDefinition.name] ? (
                  <StarIcon />
                ) : (
                  <StarBorderIcon />
                )
              ) : cellProps.row.values[columnDefinition.name] ? (
                t("yesLabel")
              ) : (
                t("noLabel")
              ),
            accessor: columnDefinition.name
          };
        } else {
          return {
            Aggregated: (cellProps: any) => {
              return firstColumn === columnDefinition.name ? (
                <>
                  <span className={classes.tableHead}>
                    {t(`${snakeToCamel(cellProps.row.groupByID)}Label`)}:
                  </span>{" "}
                  {t2(cellProps.row.groupByVal)}
                </>
              ) : (
                <></>
              );
            },
            accessor: columnDefinition.name,
            Header: (
              <div className={`${classes.leftAlign}`}>
                {t(`${snakeToCamel(columnDefinition.name)}Label`)}
              </div>
            ),
            Cell: (cellProps: any) => {
              if (columnDefinition.customRender) {
                return columnDefinition.customRender(
                  cellProps.row.values[columnDefinition.name]
                );
              } else if (columnDefinition.customRenderById) {
                return columnDefinition.customRenderById(
                  cellProps.row.values["id"]
                );
              } else if (columnDefinition.customRenderByRow) {
                return columnDefinition.customRenderByRow(cellProps.row.values);
              }

              return (
                <>
                  {cellProps.row.index === editableId ? (
                    <TableFormComponent
                      columnDefinition={columnDefinition}
                      handleForm={handleEditForm}
                      formData={editForm}
                      pluralName={pluralName}
                      type={"EDIT"}
                      handleFocusedField={handleFocusedField}
                      focusedField={focusedField}
                      handleFormValidation={handleFormValidationCb}
                      formValidation={formValidation[columnDefinition.name]}
                      altFieldType={props.altFieldType}
                    />
                  ) : (
                    <div
                      className={
                        props.groupBy && firstColumn === columnDefinition.name
                          ? classes.indent
                          : ""
                      }
                    >
                      {columnDefinition.name === "price"
                        ? niceAmount(
                            cellProps.row.values[columnDefinition.name]
                          )
                        : cellProps.row.values[columnDefinition.name]}
                    </div>
                  )}
                </>
              );
            }
          };
        }
      }),
      {
        Header: (
          <div className={`${classes.rightFloat}`}>{t3("optionsLabel")}</div>
        ),
        accessor: "id",
        width: 50,
        Aggregated: (cellProps: any) => <></>,
        Cell: ({ row }: { row: any }) =>
          row.index !== null && row.index === editableId ? (
            getEditActionsCb(editableId)
          ) : (
            <div
              key={row.values["id"]}
              className={`${classes.rightFloat} ${classes.sameRow}`}
            >
              {typeof isItemEditLocked !== "undefined" &&
                isItemEditLocked(row.values) && (
                  <div className={classes.actionButton}>
                    <LockIcon />
                  </div>
                )}
              {typeof handleSelectedRow !== "undefined" &&
              typeof selectedRow !== "undefined" ? (
                <IconButton
                  aria-label="select"
                  data-cy={`select-${resourceName.toLowerCase()}`}
                  className={classes.actionButton}
                  onClick={() => handleSelectedRow(row.values["id"])}
                >
                  {selectedRow === row.values["id"] ? (
                    <MapIcon />
                  ) : (
                    <MapIcon color="disabled" />
                  )}
                </IconButton>
              ) : (
                ""
              )}
              {typeof updateResource !== "undefined" ? (
                <IconButton
                  disabled={
                    typeof isItemEditLocked !== "undefined" &&
                    isItemEditLocked(row.values)
                  }
                  aria-label="edit"
                  data-cy={`edit-${resourceName.toLowerCase()}`}
                  className={classes.actionButton}
                  onClick={() => {
                    if (props.customEditFunction) {
                      props.customEditFunction(row.values);
                    }
                    setEditableId(+row.index);
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                ""
              )}
              {typeof deleteResource !== "undefined" ||
              typeof customDeleteResource !== "undefined" ? (
                <IconButton
                  disabled={(() => {
                    if (typeof nrOfDeletableItems !== "undefined") {
                      return nrOfDeletableItems(row.values) === 0;
                    }
                    return false;
                  })()}
                  data-nr-of-deletable-items={
                    typeof nrOfDeletableItems !== "undefined"
                      ? nrOfDeletableItems(row.values)
                      : 1
                  }
                  aria-label="delete"
                  id={`delete-${resourceName.toLowerCase()}-${row.index}`}
                  data-cy={`delete-${resourceName.toLowerCase()}`}
                  className={classes.actionButton}
                  onClick={() => {
                    if (typeof customDeleteResource !== "undefined") {
                      customDeleteResource(row.values);
                    } else {
                      handleDeleteCb(row.values["id"]);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                ""
              )}
            </div>
          )
      }
    ],
    [
      columnDefinitions,
      classes,
      pluralName,
      handleSortingCb,
      currentSorting,
      editableId,
      editForm,
      handleFormValidationCb,
      formValidation,
      routeUrl,
      focusedField,
      t,
      t2,
      t3,
      firstColumn,
      props,
      getEditActionsCb,
      handleSelectedRow,
      selectedRow,
      resourceName,
      updateResource,
      deleteResource,
      customDeleteResource,
      nrOfDeletableItems,
      handleDeleteCb,
      isItemEditLocked
    ]
  );

  useEffect(() => {
    if (editableId === null) {
      setEditForm({});
      setFormValidation({});
    } else if (editableId !== null) {
      const tempNewData = data.find((row, index) => index === editableId);
      if (tempNewData) {
        setAddingResource(false);
        setEditForm(tempNewData);
      }
    }
  }, [editableId, addingResource, data]);

  useEffect(() => {
    if (!addingResource && !emptyObject(addForm)) {
      setAddForm({});
      setFormValidation({});
    } else if (addingResource && emptyObject(addForm)) {
      setAddForm(
        columnDefinitions
          .filter((columnDefinition) => columnDefinition.editableForm)
          .reduce((total: TableForm, columnDefinition: ColumnDefinition) => {
            let defaultValue: any =
              typeof columnDefinition.defaultFormValue !== "undefined"
                ? columnDefinition.defaultFormValue
                : "";
            if (
              columnDefinition.editableForm === "boolean" ||
              columnDefinition.editableForm === "star"
            ) {
              defaultValue = false;
            } else if (
              columnDefinition.editableForm === "date" ||
              columnDefinition.type === "foreignLink"
            ) {
              defaultValue = null;
            }
            return { ...total, [columnDefinition.name]: defaultValue };
          }, {})
      );
    }
  }, [addForm, addingResource, columnDefinitions]);

  const handleEditForm = (data: TableForm) => setEditForm(data);

  const handleAddForm = (data: TableForm) => setAddForm(data);

  const handleAddingResource = (_addingResource: boolean) =>
    setAddingResource(_addingResource);

  return (
    <>
      <TableHeaderButtons
        filterDefinitions={props.filterDefinitions}
        resourcesButtons={props.resourcesButtons}
        showNewButton={props.showNewButton}
        handleFlags={handleFlags}
        resourceName={resourceName}
        routeUrl={routeUrl}
        selectedFilters={selectedFilters}
      />
      <ResourceTable
        title={
          props.customHeader
            ? props.customHeader
            : t(`${resourceName.toLowerCase()}HeadingText`)
        }
        columns={columns}
        data={data}
        count={props.count}
        pageSize={currentPageSize}
        pageIndex={currentPage}
        previous={null}
        next={null}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        handleSearch={handleSearch}
        columnDefinitions={columnDefinitions}
        pluralName={pluralName}
        updateResource={updateResource}
        addResource={props.addResource}
        owningId={props.owningId}
        secondOwningId={props.secondOwningId}
        showActionColumn={
          typeof updateResource !== "undefined" ||
          typeof deleteResource !== "undefined" ||
          typeof customDeleteResource !== "undefined"
        }
        loadingResources={props.loadingResources}
        addingResource={addingResource}
        handleAddForm={handleAddForm}
        addForm={addForm}
        handleAddingResource={handleAddingResource}
        groupBy={props.groupBy}
        formValidation={formValidation}
        handleFormValidation={handleFormValidationCb}
        validated={validated}
        altFieldType={props.altFieldType}
        customHeaderComponent={props.customHeaderComponent}
      />
    </>
  );
};

const withStylesWrapper = withStyles(styles);

export default withStylesWrapper((props: ResourcesTableContainerProps) => (
  <ConfirmationDialogServiceProvider>
    <ResourcesTableContainer {...props} />
  </ConfirmationDialogServiceProvider>
));
