import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  CustomerobjectForm,
  Customerobject,
  mainRootState,
  SelectFieldOption,
  LocationType
} from "../../../redux/types";
import CustomerobjectNewForm from "../../../components/contracts/customerobjects/CustomerobjectNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { timeToDate } from "../../../components/FormatHelpers";
import { format } from "date-fns";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewCustomerobjectContainerProps
  extends FormikSubmitDispatchProps<CustomerobjectForm> {
  loadCustomerPersons: (companyId: number) => void;
  personOptions: SelectFieldOption[];
  resetCustomerobject: () => void;
  location: LocationType;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Customerobject | CustomerobjectForm> {
  loadCustomerPersons: (companyId: number) => void;
  resetCustomerobject: () => void;
}

const NewCustomerobjectContainer: React.FC<NewCustomerobjectContainerProps> = (
  props
) => {
  const {
    resetCustomerobject,
    personOptions,
    handleSubmit,
    loadCustomerPersons
  } = props;

  useEffect(() => resetCustomerobject(), [resetCustomerobject]);

  return (
    <CustomerobjectNewForm
      personOptions={personOptions}
      handleSubmit={handleSubmit}
      loadCustomerPersons={loadCustomerPersons}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values,
      weekday_opening: values.weekday_opening
        ? format(timeToDate(values.weekday_opening), "HH:mm")
        : null,
      weekday_closing: values.weekday_closing
        ? format(timeToDate(values.weekday_closing), "HH:mm")
        : null,
      saturday_opening: values.saturday_opening
        ? format(timeToDate(values.saturday_opening), "HH:mm")
        : null,
      saturday_closing: values.saturday_closing
        ? format(timeToDate(values.saturday_closing), "HH:mm")
        : null,
      sunday_opening: values.sunday_opening
        ? format(timeToDate(values.sunday_opening), "HH:mm")
        : null,
      sunday_closing: values.sunday_closing
        ? format(timeToDate(values.sunday_closing), "HH:mm")
        : null
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<CustomerobjectForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.CUSTOMEROBJECT,
        "contracts/customerobjects/",
        checkedNullValues as Customerobject,
        "/contracts/customerobjects?same_tab=true"
      )
    );
  },
  resetCustomerobject: () => {
    dispatch(
      RestActions.resetResource(
        ownProps.tabId,
        ReduxActionStrings.CUSTOMEROBJECT_LOCATION
      )
    );
  },

loadCustomerPersons: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSONS,
        `auth/persons/?order__first_name=asc&filter__company_id=${companyId}&filter__status=ACTIVE`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  companyOptions: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  })),
  personOptions: state.tabStates[ownProps.tabId].persons.persons.persons.map(
    (person) => ({
      label: `${person.first_name} ${person.last_name || ""}`,
      value: person.id
    })
  ),
  passcardOptions: state.tabStates[
    ownProps.tabId
  ].passcards.passcards.passcards.map((passcard) => ({
    label: `${passcard.passcard_type}: ${passcard.serial_number}`,
    value: passcard.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCustomerobjectContainer);
