import { LayoutRouteUsage } from "../../../redux/types";
import TaskContainer from "./TaskContainer";
import TaskEditFormContainer from "./TaskEditFormContainer";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";

const TasksRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/tasks"],
    routes: [
      {
        path: "/contracts/tasks",
        label: t("contractNavbarTasksLabel"),
        component: undefined, // just for search icon
        icon: AssignmentIcon,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS
      },
      //   {
      //     path: "/contracts/tasks/new",
      //     parent: "/contracts/tasks",
      //     label: t("newNavbarLabel"),
      //     component: undefined
      //   },
      {
        path: "/contracts/tasks/:taskId",
        parent: "/contracts/tasks",
        label: t("taskLabel"),
        component: TaskContainer,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS
      },
      {
        path: "/contracts/tasks/:taskId/edit",
        parent: "/contracts/tasks/:taskId",
        label: t("editTaskLabel"),
        component: TaskEditFormContainer,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS
      }
    ]
  }
];

export default TasksRouter;
