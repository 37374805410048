import { connect } from "react-redux";

import TimesheetApprovalsPage from "../../components/verification/TimesheetApprovalsPage";
import { mainRootState } from "../../redux/types";
import { Dispatch } from "redux";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  contractors: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  }))
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  getContractors: () => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?filter__company_type=CONTRACTOR`
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetApprovalsPage);
