import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField } from "formik-material-ui";
import { FormikSubmitDispatchProps, NewPasswordForm } from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import LoadingSpinner from "../LoadingSpinner";

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    password: Yup.string()
      .min(1, t("minLengthError"))
      .max(128, t("max128LengthError"))
      .required(t("requiredError")),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password")], t("confirmPassword"))
      .required(t("requiredError"))
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    }
  });

interface EditUserProps
  extends FormikSubmitDispatchProps<NewPasswordForm>,
    WithStyles<typeof styles> {
  userId: number;
  personId: number;
}

const NewUser: React.FC<EditUserProps> = (props) => {
  const { classes } = props;
  const t = useTranslate("PersonPage");
  const t3 = useTranslate("ValidationErrorMessages");
  const initialValues = {
    id: props.userId,
    person: props.personId,
    password: "",
    passwordConfirmation: ""
  };

  const [sendingForm, setSendingForm] = useState<boolean>(false);

  const handleFormSubmit = (
    values: NewPasswordForm,
    actions: FormikHelpers<NewPasswordForm>
  ) => {
    setSendingForm(true);

    props.handleSubmit(values, actions);
  };

  const [values, setValues] = useState({
    showPassword: false
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {t("headerLabel")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={handleFormSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="new-password-form-new-password"
                data-cy="new-password-field"
                type={values.showPassword ? "text" : "password"}
                name="password"
                label={t("newPasswordLabel")}
                placeholder={t("newPasswordLabel")}
                component={TextField}
                margin="normal"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Field
                id="new-password-form-password-confirm"
                data-cy="new-password-confirm-field"
                type={values.showPassword ? "text" : "password"}
                name="passwordConfirmation"
                label={t("passwordConfirmationLabel")}
                placeholder={t("passwordConfirmationLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              {sendingForm ? (
                <LoadingSpinner />
              ) : (
                <Button
                  id="new-password-form-submit"
                  data-cy="new-password-submit-btn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {t("saveButtonLabel")}
                </Button>
              )}
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewUser);
