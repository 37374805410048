import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Company,
  SelectFieldOption,
  Customerobject,
  ColumnDefinition,
  SingnedCooperationsDialogData,
  Contract,
  mainRootState,
  Cooperation
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../../redux/strings";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";
import { useTranslate } from "../../../services/appLanguageService";
import {
  getCustomerObjectAPI,
  getSearchCustomerobjectOptionsAPI,
  getTaskByCustomerobjectIdAPI,
  getTasksByFilterIds
} from "../../../services/api-declaration";
import SignedCooperationsDialog from "../../../components/companies/companies/SignedCooperationsDialog";
import {
  useConfirmationDialog,
  ConfirmationDialogServiceProvider
} from "../../../components/confirmationDialog/ConfirmationDialogServiceProvider";
import * as Yup from "yup";
import _ from "lodash";

interface ActiveCustomerobjectsContainerProps {
  customerobjects: Customerobject[];
  count: number;
  loadingCustomerobjects: boolean;
  resetCustomerobjects: () => void;
  loadBusinessareas: () => void;
  loadCustomers: (customerIds: number[]) => void;
  loadCustomerobjects: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  contractor: Company;
  customers: Company[];
  businessareaOptions: SelectFieldOption[];
  addCooperation: (taskId: number, contractorId: number) => void;
  removeCooperation: (cooperationId: number) => void;
  validContract: Contract;
  loadCooperations: (contractId: number) => void;
  cooperations: Cooperation[];
}

interface ActiveCustomerobjectForm {
  name: number;
  customer?: number;
}

const ActiveCustomerobjectsContainer: React.FC<
  ActiveCustomerobjectsContainerProps
> = (props) => {
  const {
    loadCustomerobjects,
    resetCustomerobjects,
    customerobjects,
    loadingCustomerobjects,
    contractor,
    loadBusinessareas,
    loadCustomers,
    removeCooperation,
    businessareaOptions,
    validContract,
    loadCooperations,
    cooperations
  } = props;
  const page = 1;
  const pageSize = 20;
  let filter = "";

  if (contractor && contractor.id) {
    filter = `&filter__active_customer_objects=${contractor.id}`;
  }

  const t = useTranslate("ActiveCustomerobjectsPage");
  const t3 = useTranslate("ValidationErrorMessages");

  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogData] =
    React.useState<SingnedCooperationsDialogData>({
      tasks: [],
      contractorId: null
    });
  const [customerobjectOptions, setCustomerobjectOptions] = React.useState<
    SelectFieldOption[]
  >([]);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleDialogData = (newDialogData: SingnedCooperationsDialogData) =>
    setDialogData(newDialogData);

  useEffect(
    () => loadCustomerobjects(page, pageSize, undefined, undefined, filter),
    [loadCustomerobjects, filter, cooperations]
  );
  useEffect(
    () => loadCooperations(validContract.id),
    [loadCooperations, validContract.id]
  );
  useEffect(() => loadBusinessareas(), [loadBusinessareas]);
  useEffect(
    () =>
      loadCustomers(
        _.uniq(customerobjects.map((customerobject) => customerobject.customer))
      ),
    [loadCustomers, customerobjects]
  );
  useEffect(() => () => resetCustomerobjects(), [resetCustomerobjects]);

  useEffect(() => {
    (async () => {
      const response = await getSearchCustomerobjectOptionsAPI();

      setCustomerobjectOptions(
        response.results.map((customerobject) => ({
          label: customerobject.name,
          value: customerobject.id
        }))
      );
    })();
  }, []);

  const columnDefinitions: ColumnDefinition[] = [
    {
      name: "company_logo",
      type: "thumbnail"
    },
    {
      name: "name",
      type: "link",
      editableForm: "select",
      sortable: true,
      options: customerobjectOptions,
      validation: Yup.number()
        .positive(t3("requiredError"))
        .required(t3("requiredError")),
      defaultFormValue: -1
    },
    {
      name: "customer",
      type: "foreignLink",
      editableForm: "select",
      foreignData: {
        data: props.customers,
        visibleColumns: ["name"],
        url: "companies/companies"
      },
      options: businessareaOptions,
      customOptionLabel: t("businessareaLabel")
    },
    {
      name: "district_name",
      type: "text"
    },
    {
      name: "tasks",
      type: "hidden"
    }
  ];

  const addNewRow = (newData: ActiveCustomerobjectForm) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const customerObjectId = newData.name;
        const businessareaId = newData.customer;

        if (customerObjectId && businessareaId) {
          const customerobject = await getCustomerObjectAPI(customerObjectId);

          const tasks = await getTaskByCustomerobjectIdAPI(customerObjectId);

          const selectedTask = tasks.results.find(
            (task) => task.businessarea === businessareaId
          );

          if (
            customerobject.tasks.length > 0 &&
            selectedTask &&
            selectedTask.id
          ) {
            props.addCooperation(selectedTask.id, validContract.id);
            resetCustomerobjects();
            resolve();
          } else {
            throw Error("No tasks available for " + customerobject.name);
          }
        }
      } catch (err) {
        console.warn(err);
        reject();
      }
    });
  };

  const confirmationDialog = useConfirmationDialog();
  const handleCooperationDelete = async (cooperationId: number) => {
    await confirmationDialog({
      title: t("deleteUserAccountTitle"),
      description: t("areYouSureLabel")
    });
    props.removeCooperation(cooperationId);
  };

  const getCooperationsForRow = (rowData: any) =>
    cooperations.filter((cooperation) =>
      rowData.tasks.includes(cooperation.task)
    );

  const deleteRow = async (rowData: any) => {
    const cooperationIds = getCooperationsForRow(rowData).map(
      (cooperation) => cooperation.id
    );

    if (cooperationIds.length === 1 && cooperationIds[0]) {
      handleCooperationDelete(cooperationIds[0]);
    } else if (cooperationIds.length > 1) {
      const currentTasks = await getTasksByFilterIds(rowData.tasks);

      setDialogData({
        tasks: currentTasks.results,
        contractorId: contractor.id
      });
      handleClickOpen();
    }
  };

  const nrOfDeletableItems = (rowData: any) =>
    getCooperationsForRow(rowData).length;

  return (
    <>
      <ResourcesTableContainer
        customHeader={t("activeCustomerobjectsHeadingText")}
        showNewButton={false}
        resources={customerobjects}
        resourceName={"Customerobject"}
        count={props.count}
        loadingResources={loadingCustomerobjects}
        routeUrl={"contracts/customerobjects"}
        columnDefinitions={columnDefinitions}
        defaultPageSize={pageSize}
        filterDefinitions={[]}
        resetResources={resetCustomerobjects}
        loadResources={loadCustomerobjects}
        customFilter={filter}
        addResource={addNewRow}
        customDeleteResource={deleteRow}
        nrOfDeletableItems={nrOfDeletableItems}
      />
      <SignedCooperationsDialog
        open={open}
        handleClose={handleClose}
        dialogData={dialogData}
        removeSignedCooperation={removeCooperation}
        handleDialogData={handleDialogData}
        signedCooperations={cooperations}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadCustomerobjects: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    const customFilter = filter || "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CUSTOMEROBJECTS,
        `contracts/customerobjects/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${customFilter}`
      )
    );
  },
  loadCooperations: (contractId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CUSTOMEROBJECT_COOPERATIONS,
        `contracts/cooperations/?filter__contract_id=${contractId}`
      )
    );
  },
  addCooperation: (taskId: number, contractId: number) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.CUSTOMEROBJECT_COOPERATIONS,
        `contracts/cooperations/`,
        {
          task: taskId,
          contract: contractId
        }
      )
    );
  },
  removeCooperation: (cooperationId: number) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.CUSTOMEROBJECT_COOPERATIONS,
        `contracts/cooperations/${cooperationId}/`,
        undefined,
        undefined,
        undefined,
        cooperationId
      )
    );
  },
  loadBusinessareas: () => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.BUSINESSAREAS,
        "contracts/businessareas/"
      )
    );
  },
  loadCustomers: (customerIds: number[]) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?filter__company_type=CUSTOMER&filter__id__in=[${customerIds}]`
      )
    );
  },
  resetCustomerobjects: () =>
    dispatch(LoadingBooleansActions.ResetCustomerobjects(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  customerobjects:
    state.tabStates[ownProps.tabId].customerObjects.customerobjects
      .customerobjects,
  businessareaOptions: state.tabStates[
    ownProps.tabId
  ].businessAreas.businessAreas.results.map((businessarea) => ({
    label: businessarea.name,
    value: businessarea.id
  })),
  customers: state.tabStates[ownProps.tabId].companies.companies.companies,
  count: state.tabStates[ownProps.tabId].customerObjects.customerobjects.count,
  loadingCustomerobjects:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCustomerobjects,
  loadingCooperations:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCooperations,
  cooperations:
    state.tabStates[ownProps.tabId].cooperations.customerobjectCooperations
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: ActiveCustomerobjectsContainerProps) => (
  <ConfirmationDialogServiceProvider>
    <ActiveCustomerobjectsContainer {...props} />
  </ConfirmationDialogServiceProvider>
));
