import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Typography,
  withStyles,
  createStyles,
  WithStyles
} from "@material-ui/core";
import store from "../../redux/store";
import { LayoutRoute, TabTitleState } from "../../redux/types";
import {
  convertToUrlSearch,
  deleteFromUrlSearch,
  extractURLSearch,
  makePathStaticWithParams,
  validateApiUsage
} from "../../helpers/routesHelper";
import { selectTab } from "../../redux/reducers/tabRouter/tabSelection/actions";
import { useHistory } from "react-router-dom";
import { AppStaticRoutesMap } from "../AppRouter";
import TabHeader from "./TabHeader";
import TabPanel from "./TabPanel";
import {
  addTabRoute,
  getRectifiedTab,
  getTabIndex,
  getTabIndexByPath,
  updateTabRoute,
  removeTabById,
  updateURLQueryParams,
  getTabByIndex,
  getTabRoutes,
  getAdhocSearchString,
  handleDefaultUrlSearch,
  deleteAllTab
} from "../../helpers/tabsHelper";
import { AppUrlStrings } from "../../strings/AppUrlStrings";

const styles = (theme: any) =>
  createStyles({
    tabContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    topBar: {
      flexDirection: "row",
      top: "64px",
      height: theme.spacing(4.5),
      minHeight: theme.spacing(4.5),
      [theme.breakpoints.down("xs")]: {
        position: "fixed",
        marginTop: "-10px"
      }
    }
  });

interface TabRouterProps extends WithStyles<typeof styles> {
  tabTitle: TabTitleState;
}

const TabRouter: React.FC<TabRouterProps> = (props) => {
  const { classes, tabTitle } = props;
  const history = useHistory();
  const selectedTabIndexRef = useRef(-1);
  const updatingSearchParams = useRef(false);
  const [reloadTabId, setReloadTabId] = useState(-1);
  const [selectedTab, setSelectedTab] = useState<LayoutRoute>();
  const deleteTabId = useRef(-1);
  // const t = useTranslate("TabRouter");

  const handleRemoveTab = (tabIdToRemove: number) => {
    removeTabById(tabIdToRemove);

    deleteTabId.current = tabIdToRemove;
    if (getTabRoutes().length === 0) {
      history.push({
        pathname: "/",
        search: window.sessionUserId
          ? getAdhocSearchString(window.sessionUserId)
          : ""
      });
      return undefined;
    }
    setSelectedTab((selectedTab) => {
      const { tabId } = selectedTab as LayoutRoute;
      if (tabIdToRemove === tabId) {
        const newSelectedTab = getRectifiedTab(tabIdToRemove);
        history.push({
          pathname: newSelectedTab.actualPath,
          search: String(convertToUrlSearch(newSelectedTab))
        });
        return newSelectedTab;
      } else if (selectedTab) {
        return { ...selectedTab };
      }
      return selectedTab;
    });
  };

  useEffect(() => {
    if (updatingSearchParams.current) {
      updatingSearchParams.current = false;
      return;
    }
    const urlSearchParams = new URLSearchParams(history.location.search);
    const searchParams = extractURLSearch(urlSearchParams);
    const isSearchChanged = deleteFromUrlSearch(urlSearchParams, [
      AppUrlStrings.USE_SAME_TAB,
      AppUrlStrings.USE_RELOAD_TAB,
      AppUrlStrings.REDIRECT_URL
    ]);
    const shouldReloadTab = Boolean(searchParams[AppUrlStrings.USE_RELOAD_TAB]);
    const currentTab = getTabByIndex(selectedTabIndexRef.current);

    if (shouldReloadTab && currentTab) {
      setReloadTabId(currentTab.tabId);
    }
    let shouldSameTab = Boolean(searchParams[AppUrlStrings.USE_SAME_TAB]);
    if (shouldSameTab && currentTab) {
      removeTabById(currentTab.tabId);
    }

    const currentTabIndex =
      selectedTabIndexRef.current === -1 ? 0 : selectedTabIndexRef.current;

    const actualPath =
      searchParams[AppUrlStrings.REDIRECT_URL] || history.location.pathname;
    let newSelectedTabIndex = getTabIndexByPath(actualPath);
    let checkLogin = true;
    if (newSelectedTabIndex === -1) {
      const { staticPath, ids } = makePathStaticWithParams(actualPath);
      let newTabRoute = AppStaticRoutesMap.get(staticPath);
      if (
        !newTabRoute ||
        !newTabRoute.component ||
        !validateApiUsage(newTabRoute)
      ) {
        console.log(`ERROR: ${actualPath} not found or unauthorized`);
        newTabRoute = AppStaticRoutesMap.get("/error") as LayoutRoute;
      }

      if (shouldSameTab) {
        setSelectedTab(undefined); // update state so that component gets unmounted
      }
      newSelectedTabIndex = addTabRoute({
        newTabRoute,
        currentTabIndex,
        actualPath,
        ids,
        shouldSameTab,
        deletedTabId: currentTab ? currentTab.tabId : 1
      });

      shouldSameTab = false;
    } else {
      checkLogin = false;
    }


    const newSelectedTab = getTabByIndex(newSelectedTabIndex);
    if (!checkLogin) {
      store.dispatch({ type: "ADD_TAB", tabId: newSelectedTab.tabId });
    }

    if ([...urlSearchParams.keys()].length) {
      updateURLQueryParams(urlSearchParams, newSelectedTabIndex);
    } else if (Object.keys(newSelectedTab.urlQueryParams).length) {
      history.replace({ search: new URLSearchParams(newSelectedTab.urlQueryParams).toString() })
    }

    if (newSelectedTabIndex !== currentTabIndex) {
      updateTabRoute(newSelectedTabIndex);
    }

    if (shouldSameTab) {
      setReloadTabId(newSelectedTab.tabId);
    }

    setSelectedTab(newSelectedTab);
    if (isSearchChanged || handleDefaultUrlSearch(urlSearchParams)) {
      history.push({
        pathname: newSelectedTab.actualPath,
        search: String(urlSearchParams)
      });
      updatingSearchParams.current = true;
    }
  }, [history, history.location]);

  useEffect(() => {
    const { instanceId, instanceTitle } = tabTitle;
    if (instanceId !== -1) {
      const matchedTabIndex = getTabIndex(instanceId);
      if (matchedTabIndex !== -1) {
        updateTabRoute(matchedTabIndex, {
          instanceTitle
        });
        setSelectedTab((selectedTab) =>
          selectedTab ? { ...selectedTab } : selectedTab
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabTitle]);

  useEffect(() => {
    if (selectedTab) {
      store.dispatch(selectTab(selectedTab));
      if (deleteTabId.current !== -1) {
        store.dispatch({ type: "DELETE_TAB", tabId: deleteTabId.current });
        deleteTabId.current = -1;
      }
    }
    selectedTabIndexRef.current = selectedTab
      ? getTabIndex(selectedTab.tabId)
      : -1;
  }, [selectedTab]);

  useEffect(() => {
    if (reloadTabId !== -1) {
      setReloadTabId(-1);
    }
  }, [reloadTabId]);

  useEffect(
    () => () => {
      deleteAllTab();
    },
    []
  );

  if (!selectedTab) {
    return (
      <Typography variant="h5" component="h5">
        No Content to Display
      </Typography>
    );
  }
  const tabRoutes = getTabRoutes();
  return (
    <div className={classes.tabContainer}>
      <AppBar position="sticky" color="inherit" className={classes.topBar}>
        <TabHeader
          selectedTabIndex={getTabIndex(selectedTab.tabId)}
          tabRoutes={tabRoutes}
          handleRemoveTab={handleRemoveTab}
          handleReloadTab={(tabId) => setReloadTabId(tabId)}
          handleReloadAllTab={() => setReloadTabId(0)}
        />
      </AppBar>
      {reloadTabId !== 0 &&
        tabRoutes.map(
          (tabRoute) =>
            tabRoute.tabId === reloadTabId || (
              <TabPanel
                key={tabRoute.tabId}
                tabRoute={tabRoute}
                selectedTab={selectedTab}
              />
            )
        )}
    </div>
  );
};

export default withStyles(styles)(TabRouter);
