import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  PersonForm,
  SelectFieldOption,
  mainRootState
} from "../../redux/types";
import PersonNewForm from "../../components/persons/PersonNewEditForm";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface NewPersonContainerProps
  extends FormikSubmitDispatchProps<PersonForm> {
  companyOptions: SelectFieldOption[];
  loadCompanies: (searchTerm?: string) => void;
  loadCustomerobjects: (companyId: number | null) => void;
  customerobjectOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<PersonForm> {
  loadCompanies: () => void;
  loadCustomerobjects: (companyId: number | null) => void;
}

const NewPersonContainer: React.FC<NewPersonContainerProps> = (props) => {
  const { loadCompanies } = props;
  useEffect(() => loadCompanies(), [loadCompanies]);

  return (
    <PersonNewForm
      companyOptions={props.companyOptions}
      handleSubmit={props.handleSubmit}
      customerobjectOptions={props.customerobjectOptions}
      loadCustomerobjects={props.loadCustomerobjects}
      loadCompanies={props.loadCompanies}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values,
      user: values.user !== -1 ? values.user : null,
      company: values.company !== -1 ? values.company : null
    };

    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<PersonForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.PERSON,
        "auth/persons/",
        checkedNullValues,
        "/auth/persons?same_tab=true"
      )
    );
  },
  loadCompanies: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?order__name=asc&filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerobjects: (companyId: number | null) => {
    if (companyId) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.CUSTOMEROBJECTS,
          `contracts/customerobjects/?order__name=asc&filter__customer=${companyId}`
        )
      );
    }
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  companyOptions: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  })),
  customerobjectOptions: state.tabStates[
    ownProps.tabId
  ].customerObjects.customerobjects.customerobjects.map((customerObject) => ({
    label: customerObject.name,
    value: customerObject.id
  }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPersonContainer);
