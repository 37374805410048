import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, BusinessArea } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings } from "../../redux/strings";
import ResourceLink from "../../components/links/ResourceLink";

interface BusinessAreaLinkContainerProps {
  businessAreaId: number;
  businessArea: BusinessArea;
  loadBusinessArea: (businessAreaId: number) => void;
}

const BusinessAreaLinkContainer: React.FC<BusinessAreaLinkContainerProps> = (
  props
) => {
  const { loadBusinessArea, businessAreaId, businessArea } = props;
  useEffect(
    () => loadBusinessArea(businessAreaId),
    [loadBusinessArea, businessAreaId]
  );
  return (
    <span>
      {businessArea && (
        <ResourceLink
          label={businessArea.name}
          id={businessArea.id}
          url="contracts/businessareas"
        />
      )}
    </span>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadBusinessArea: (businessAreaId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "BUSINESSAREA",
        `contracts/businessareas/${businessAreaId}/`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  businessArea:
    state.tabStates[ownProps.tabId].businessAreas.businessArea.businessArea
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessAreaLinkContainer);
