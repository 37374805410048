import React, { useState, useEffect, useCallback } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import { TextField, Select } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  CompanyForm,
  Company,
  SelectFieldOption,
  ImageRow,
  TabComponentProps
} from "../../../redux/types";
import { EnumStrings } from "../../../redux/strings";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  AutoCompleteSelect,
  AutoCompleteSelectMultiNEW
} from "../../AutoCompleteSelect";
import { useTranslate } from "../../../services/appLanguageService";
import ImageUploadComponent from "../../imageUploadComponent/ImageUploadComponent";
import { getImageByURL } from "../../../services/imageHelperService";
import LocationMap from "../locations/LocationMap";
import { getSearchDistrictOptionsAPI } from "../../../services/api-declaration";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";

const companyTypeOptions = [
  EnumStrings.CONTRACTOR,
  EnumStrings.CUSTOMER,
  EnumStrings.MATERIAL_SUPPLIER,
  EnumStrings.OTHER,
  EnumStrings.DOMAIN_OWNER
];

const statusOptions = [
  EnumStrings.ACTIVE,
  EnumStrings.ARCHIVED,
  EnumStrings.LOCKED
];

const membershipTypeOptions = [EnumStrings.STANDARD, EnumStrings.PROSPECT];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    status: Yup.string()
      .oneOf(statusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    company_type: Yup.string()
      .oneOf(companyTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    membership_type: Yup.string()
      .oneOf(membershipTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    organisation_number: Yup.string()
      .min(10, t("min10LengthError"))
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    invoice_ref: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    website: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    notes: Yup.string().nullable().notRequired(),
    accounting_reference: Yup.number()
      .typeError(t("numberRequiredError"))
      .nullable()
      .notRequired(),
    payment_account: Yup.string()
      .min(1, t("minLengthError"))
      .max(50, t("max50LengthError"))
      .nullable()
      .notRequired(),
    districts: Yup.array().of(Yup.number().min(1)).notRequired(),
    location: Yup.object().nullable().notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    mapContainer: {
      marginTop: 25
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    },
    logoLabel: {
      marginTop: theme.spacing(3)
    }
  });

interface NewCompanyProps
  extends FormikSubmitDispatchProps<CompanyForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  company?: Company;
  personOptions?: SelectFieldOption[];
  newLocation: boolean;
  hasValidContracts?: boolean;
}

const NewEditCompany: React.FC<NewCompanyProps> = (props) => {
  const { classes, company, personOptions, hasValidContracts, tabId } = props;
  const t = useTranslate("CompanyPage");
  const t2 = useTranslate("Companies");
  const t3 = useTranslate("ValidationErrorMessages");
  const [images, setImages] = useState<ImageRow[]>([]);
  let initialValues: Company | CompanyForm;
  if (company && (company as Company).id) {
    initialValues = {
      ...company
    };
  } else {
    initialValues = {
      id: -1,
      name: "",
      status: "ACTIVE",
      company_type: "CUSTOMER",
      membership_type: "STANDARD",
      organisation_number: null,
      invoice_ref: null,
      website: null,
      logo: null,
      notes: null,
      accounting_reference: 0,
      payment_account: null,
      external: false,
      districts: [],
      location: null,
      contact_person: null,
      invoice_location: null
    };
  }

  const handleFormSubmit = (
    values: CompanyForm,
    actions: FormikHelpers<CompanyForm>
  ) => {
    let checkedValues: any;
    const imageStr = images.length ? images[0].imageStr : null;

    if (!imageStr || (imageStr && imageStr.includes("base64"))) {
      checkedValues = { ...values, logo: imageStr };
    } else {
      const { logo, ...other } = values;
      checkedValues = other;
    }

    props.handleSubmit(checkedValues, actions);
  };

  const getLogo = async () => {
    if (company && company.logo) {
      const imageStr = await getImageByURL(company.logo);
      setImages([
        {
          imageStr
        }
      ]);
    }
  };
  const getLogoCb = useCallback(getLogo, [company]);
  useEffect(() => {
    getLogoCb();
    if (company) {
      store.dispatch(setTitle(company.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, getLogoCb]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/companies/companies${
                company ? `/${company.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {company ? t("editCompanyHeading") : t("newCompanyHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={handleFormSubmit}
        >
          {(formikProps) => (
            <form
              className={classes.form}
              onSubmit={formikProps.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="new-company-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              {/* Status type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-company-form-status"
              >
                {t("statusLabel")}
              </InputLabel>
              <Field
                id="new-company-form-status"
                name="status"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              {/* Company type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-company-form-company_type"
              >
                {t("companyTypeLabel")}
              </InputLabel>
              <Field
                id="new-company-form-company_type"
                name="company_type"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {companyTypeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              {hasValidContracts && (
                <>
                  <InputLabel
                    className={classes.selectLabel}
                    htmlFor="new-company-form-membership_type"
                  >
                    {t("membershipTypeLabel")}
                  </InputLabel>
                  <Field
                    id="new-company-form-membership_type"
                    name="membership_type"
                    component={Select}
                    className={classes.select}
                    fullWidth
                  >
                    {membershipTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {t2(option)}
                      </MenuItem>
                    ))}
                  </Field>
                </>
              )}

              {personOptions && (
                <>
                  <InputLabel
                    className={classes.selectLabel}
                    htmlFor="form-company-contact_person"
                  >
                    {t("contactPersonLabel")}
                  </InputLabel>
                  <Field
                    id="form-company-contact_person"
                    type="text"
                    name="contact_person"
                    label={t("contactPersonLabel")}
                    placeholder={t("contactPersonLabel")}
                    value={
                      personOptions.find(
                        (person) =>
                          formikProps.values.contact_person === person.value
                      )
                        ? personOptions.find(
                            (person) =>
                              formikProps.values.contact_person === person.value
                          )
                        : null
                    }
                    options={personOptions}
                    component={AutoCompleteSelect}
                    margin="normal"
                    fullWidth
                  />
                </>
              )}

              <Field
                id="new-company-form-organisation_number"
                type="text"
                name="organisation_number"
                label={t("organisationNumberLabel")}
                placeholder={t("organisationNumberLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="new-company-form-invoice_ref"
                type="text"
                name="invoice_ref"
                label={t("invoiceRefLabel")}
                placeholder={t("invoiceRefLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="new-company-form-website"
                type="text"
                name="website"
                label={t("websiteLabel")}
                placeholder={t("websiteLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="new-company-form-accounting_reference"
                type="text"
                name="accounting_reference"
                label={t("accountingReferenceLabel")}
                placeholder={t("accountingReferenceLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="new-company-form-payment_account"
                type="text"
                name="payment_account"
                label={t("paymentAccountLabel")}
                placeholder={t("paymentAccountLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="new-company-form-districts"
                type="text"
                name="districts"
                placeholder={t("districtsLabel")}
                TextFieldProps={{
                  label: t("districtsLabel"),
                  InputLabelProps: {
                    htmlFor: "react-select-single",
                    shrink: true
                  }
                }}
                component={AutoCompleteSelectMultiNEW}
                margin="normal"
                fullWidth
                loadOptionsAPI={getSearchDistrictOptionsAPI}
                selectedIds={company ? company.districts : []}
              />

              <div className={classes.mapContainer}>
                <LocationMap
                  location={formikProps.values.location}
                  editable={true}
                  formikProps={formikProps}
                />
              </div>
              <InputLabel className={classes.logoLabel}>Logo</InputLabel>
              <ImageUploadComponent
                images={images}
                onImageUpdate={setImages}
                singleImageMode
              />

              <Field
                id="new-company-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-company-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {company ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditCompany);
