import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../../redux/types";
import TermContainer from "./TermContainer";
import TermEditFormContainer from "./TermEditFormContainer";
import TermNewFormContainer from "./TermNewFormContainer";
import TermsContainer from "./TermsContainer";

const TermsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/terms"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/terms",
        label: t("contractNavbarTermsLabel"),
        component: TermsContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
      {
        path: "/contracts/terms/new",
        parent: "/contracts/terms",
        label: t("newTermLabel"),
        component: TermNewFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
      {
        path: "/contracts/terms/:termId",
        parent: "/contracts/terms",
        label: t("termLabel"),
        component: TermContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
      {
        path: "/contracts/terms/:termId/edit",
        parent: "/contracts/terms/:termId",
        label: t("editTermLabel"),
        component: TermEditFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      }
    ]
  }
];

export default TermsRouter;
