import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  TemplateImageForm,
  TemplateImage,
  mainRootState
} from "../../redux/types";
import TemplateImageNewEditForm from "../../components/templateimages/TemplateImageNewEditForm";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface NewTemplateImageContainerProps
  extends FormikSubmitDispatchProps<TemplateImageForm> {}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<TemplateImage | TemplateImageForm> {}

const NewTemplateImageContainer: React.FC<NewTemplateImageContainerProps> = (
  props
) => {
  const { handleSubmit } = props;
  return (
    <TemplateImageNewEditForm
      handleSubmit={handleSubmit}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<TemplateImageForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.TEMPLATEIMAGES,
        "services/templateimages/",
        checkedNullValues as TemplateImage,
        "/services/templateimages?same_tab=true"
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  businessAreaOptions: state.tabStates[
    ownProps.tabId
  ].businessAreas.businessAreas.results.map((businessArea) => ({
    label: businessArea.name,
    value: businessArea.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTemplateImageContainer);
