import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Replay";
import { red, green, blue, grey } from "@material-ui/core/colors";
import { IDataCarryKey } from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import clsx from "clsx";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  defaultPenColor: { color: grey[400], borderColor: grey[500] },
  font14: { fontSize: 14 },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    gap: theme.spacing(4)
  },
  flexViewTwo: {
    display: "flex",
    alignItems: "center",
    "&.inline_selector :not(.selected_pen) .pen_label": {
      display: "none"
    }
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    "&.selected_pen .pen_label": {
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: "underline"
    },
    "&.selected_pen .pen_border": {
      borderWidth: 3,
      borderRadius: "50%",
      marginRight: 10
    }
  },
  penWrapper: {
    borderWidth: 1,
    borderStyle: "solid",
    display: "flex",
    alignItems: "center",
    borderRadius: 2,
    cursor: "pointer"
  },
  penModesBorder: {
    borderLeftWidth: 3,
    margin: "0 12px 0 4px",
    padding: 6
  },
  pen_label: {},
  pen_color: {
    color: grey[400]
  },
  pen_border: {
    borderColor: grey[500]
  },
  pencorrections: {
    "&.selected_pen .pen_color": {
      color: red[400]
    },
    "& .pen_border": {
      borderColor: red[600]
    }
  },
  pencontractor_payables: {
    "&.selected_pen .pen_color": {
      color: green[600]
    },
    "& .pen_border": {
      borderColor: green[600]
    }
  },
  pencustomer_billables: {
    "&.selected_pen .pen_color": {
      color: blue[600]
    },
    "& .pen_border": {
      borderColor: blue[600]
    }
  },
  resetBtnContainer: {
    "& :not(.inline_selector)": {
      borderRadius: "50%"
    },
    borderLeftWidth: 3,
    borderRightWidth: 3
  }
}));

type ApprovalEditModeButtonsConfig = {
  editMode: IDataCarryKey;
  penClass: string;
  buttonLabel: string;
};

interface RowApprovalEditModeButtonsProps {
  selectedEditMode: IDataCarryKey;
  handleEditModeChange: (mode: IDataCarryKey) => void;
  disabledMode?: IDataCarryKey;
  resetFormChanges: () => void;
  inLineEdit: boolean;
}

function RowApprovalEditModeButtons(props: RowApprovalEditModeButtonsProps) {
  const t = useTranslate("TimeSheetRowsAndApprovals");
  const {
    disabledMode,
    handleEditModeChange,
    resetFormChanges,
    selectedEditMode,
    inLineEdit
  } = props;
  const classes = useStyles();
  const configs: ApprovalEditModeButtonsConfig[] = [
    {
      buttonLabel: t("correctionHeading"),
      penClass: classes.pencorrections,
      editMode: "corrections"
    },
    {
      buttonLabel: t("contractorHeading"),
      penClass: classes.pencontractor_payables,
      editMode: "contractor_payables"
    },
    {
      buttonLabel: t("customerHeading"),
      penClass: classes.pencustomer_billables,
      editMode: "customer_billables"
    }
  ];

  return (
    <div className={classes.mainContainer}>
      <div
        className={clsx(classes.flexViewTwo, inLineEdit && "inline_selector")}
      >
        {configs
          .filter((config) => config.editMode !== disabledMode)
          .map((config, idx) => (
            <div
              key={idx}
              className={clsx(
                classes.displayFlex,
                config.penClass,
                selectedEditMode === config.editMode && "selected_pen"
              )}
            >
              <label
                className={clsx(
                  classes.pen_color,
                  "pen_color",
                  classes.pen_label,
                  "pen_label"
                )}
              >{`${config.buttonLabel}:`}</label>
              <div onClick={() => handleEditModeChange(config.editMode)}>
                <div
                  className={clsx(
                    classes.penWrapper,
                    classes.penModesBorder,
                    classes.pen_border,
                    "pen_border",
                    classes.pen_color,
                    "pen_color"
                  )}
                >
                  <EditIcon className={classes.font14} />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div
        className={clsx(
          classes[`pen${selectedEditMode}` as keyof typeof classes],
          "selected_pen"
        )}
      >
        <Button
          onClick={resetFormChanges}
          size="small"
          className={clsx(
            classes.resetBtnContainer,
            classes.penWrapper,
            classes.pen_border,
            "pen_border",
            classes.pen_color,
            "pen_color",
            inLineEdit && "inline_selector"
          )}
        >
          {inLineEdit ? <ClearIcon /> : t("resetButtonText")}
        </Button>
      </div>
    </div>
  );
}

export default RowApprovalEditModeButtons;
