import { FC } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  IApprovalSummary,
  IApprovalSummaryDetails,
  IApprovalDictionaries,
  IApprovalSubmittedRow,
  SummaryViewMode
} from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import approvalDetailsHead from "./templates/approvalDetailsHead";
import ApprovalSubmittedRows from "./ApprovalSubmittedRows";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    expandedContainer: { paddingLeft: theme.spacing(0) },
    expandableDiv: {
      padding: theme.spacing(1),
      backgroundColor: "#FAFAFA",
      border: "1px solid #a9a9a9",
      marginTop: theme.spacing(1.5)
    }
  })
);
interface TimesheetApprovalDetailsProps {
  summaryDetails: IApprovalSummaryDetails;
  approvalSummary: IApprovalSummary;
  dictionaries: IApprovalDictionaries;
  updateApprovalSelection: () => void;
  submitRowDeleted: (routeInstance_id: number) => void;
  submitRowModified: (routeInstance_id: number, submit_id: number) => void;
  SummaryViewMode: SummaryViewMode;
  toggleSummaryRowDetails?: (row: IApprovalSubmittedRow) => void;
  openDocumentationPopup: (routeinstance_id: number, submit_id: number) => void;
  businessAreaNames: Map<number, string>;
}
const TimesheetApprovalDetails: FC<TimesheetApprovalDetailsProps> = (props) => {
  const {
    summaryDetails,
    approvalSummary: {
      participants,
      selectedRows,
      serviceCategories,
      allServiceCategories,
      summaryRows
    },
    dictionaries,
    updateApprovalSelection,
    submitRowDeleted,
    submitRowModified,
    toggleSummaryRowDetails,
    SummaryViewMode,
    openDocumentationPopup,
    businessAreaNames
  } = props;
  const classes = useStyles();
  const t = useTranslate("TimesheetApprovalPage");

  return (
    <div className={classes.expandedContainer}>
      {approvalDetailsHead({
        routeinstance_notes: summaryDetails.routeinstance_notes,
        participants,
        classes,
        t
      })}
      <div className={classes.expandableDiv}>
        {summaryDetails.submittedRows.length && serviceCategories ? (
          <ApprovalSubmittedRows
            summaryDetails={summaryDetails}
            data={
              SummaryViewMode === "DIALOG"
                ? summaryRows
                : summaryDetails.submittedRows
            }
            selectedRows={selectedRows}
            dictionaries={dictionaries}
            submitRowDeleted={submitRowDeleted}
            submitRowModified={submitRowModified}
            updateApprovalSelection={updateApprovalSelection}
            SummaryViewMode={SummaryViewMode}
            toggleSummaryRowDetails={toggleSummaryRowDetails}
            serviceCategories={serviceCategories}
            allServiceCategories={allServiceCategories}
            businessAreaNames={businessAreaNames}
            openDocumentationPopup={openDocumentationPopup}
          />
        ) : (
          <>{t("notTasksMessage")}</>
        )}
      </div>
    </div>
  );
};
export default TimesheetApprovalDetails;
