import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, Paper, WithStyles } from "@material-ui/core";
import { useTranslate } from "../services/appLanguageService";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import LoadingSpinnerPaper from "./LoadingSpinnerPaper";
import { saveAs } from "file-saver";
import DownloadIcon from "@material-ui/icons/GetApp";

const styles = (theme: any) =>
  createStyles({
    paper: {
      marginBottom: 20
    },
    pdfContainer: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 1000,
      margin: 20,
      marginTop: 50,
      position: "relative"
    },
    test: {
      zIndex: 9999,
      position: "fixed",
      bottom: 50,
      right: 50
    },
    btnIcon: { marginRight: theme.spacing(0.5) },
    button: {
      zIndex: 9999
    }
  });

interface PDFViewerProps extends WithStyles<typeof styles> {
  pdfName: string;
  pdfBlob: Blob;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ pdfName, pdfBlob, classes }) => {
  const t = useTranslate("PDFViewer");
  const [numPages, setNumPages] = useState<number | null>(null);

  const downloadPdf = async () => {
    saveAs(pdfBlob, `${pdfName}.pdf`);
  };

  const onDocumentLoadSuccess = (object: { numPages: number }) => {
    const { numPages } = object;
    setNumPages(numPages);
  };

  return (
    <div className={classes.pdfContainer}>
      <Document
        file={pdfBlob}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<LoadingSpinnerPaper />}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Paper key={`page_${index + 1}`} className={classes.paper}>
            <Page pageNumber={index + 1} scale={1} width={1000} />
          </Paper>
        ))}
      </Document>
      <div className={classes.test}>
        <Button
          variant="contained"
          color="primary"
          onClick={downloadPdf}
          className={classes.button}
        >
          <DownloadIcon className={classes.btnIcon} />
          {t("downloadButtonLabel")}
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(PDFViewer);
