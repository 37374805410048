import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Company,
  LocationType,
  Person,
  Contract,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import CompanyView from "../../../components/companies/companies/Company";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface CompanyContainerProps extends TabComponentProps<"companyId"> {
  company: Company;
  companyLocation: LocationType;
  invoiceLocation: LocationType;
  loadingCompany: boolean;
  resetCompany: () => void;
  loadCompany: (companyId: number) => void;
  loadContactPerson: (personId: number | null) => void;
  contactPerson: Person;
  saveNotes: (companyId: number, notes: string | null) => void;
  loadValidContracts: (companyId: number) => void;
  validContracts: Contract[];
}

const CompanyContainer: React.FC<CompanyContainerProps> = (props) => {
  const {
    resetCompany,
    loadingCompany,
    loadCompany,
    company,
    loadContactPerson,
    contactPerson,
    loadValidContracts,
    validContracts,
    saveNotes,
    routeParams: { companyId }
  } = props;
  useEffect(() => loadCompany(companyId), [loadCompany, companyId]);

  useEffect(
    () => loadContactPerson(company.contact_person),
    [loadContactPerson, company.contact_person]
  );
  useEffect(
    () => loadValidContracts(companyId),
    [loadValidContracts, companyId]
  );
  useEffect(() => () => resetCompany(), [resetCompany]);
  return (
    <div>
      <ViewActionButtons
        resourceId={companyId}
        resource={ReduxActionStrings.COMPANY}
        url="companies/companies"
      />
      {loadingCompany ? (
        <LoadingSpinnerPaper />
      ) : (
        <CompanyView
          company={company}
          contactPerson={contactPerson}
          saveNotes={saveNotes}
          validContracts={validContracts}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadCompany: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANY,
        `companies/companies/${companyId}/`
      )
    );
  },
  loadContactPerson: (personId: number | null) => {
    if (personId) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.PERSON,
          `auth/persons/${personId}/`
        )
      );
    } else {
      dispatch(
        RestActions.resetResource(ownProps.tabId, ReduxActionStrings.PERSON)
      );
    }
  },
  resetCompany: () =>
    dispatch(LoadingBooleansActions.ResetCompany(ownProps.tabId)),
  saveNotes: (companyId: number, notes: string | null) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.COMPANY,
        `companies/companies/${companyId}/`,
        {
          notes: notes
        }
      )
    );
  },
  loadValidContracts: (companyId: number) => {
    if (companyId) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.CONTRACTS,
          `contracts/contracts/?filter__company=${companyId}&filter__valid=true`
        )
      );
    }
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  company: state.tabStates[ownProps.tabId].companies.companies.company,
  contactPerson: state.tabStates[ownProps.tabId].persons.person.person,
  loadingCompany:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCompany,
  validContracts: state.tabStates[ownProps.tabId].contracts.contracts.contracts
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainer);
