import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Person } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";
import ResourceLink from "../../components/links/ResourceLink";

interface PersonLinkContainerProps {
  personId: number;
  person: Person;
  loadPerson: (personId: number) => void;
}

const PersonLinkContainer: React.FC<PersonLinkContainerProps> = (props) => {
  const { loadPerson, personId, person } = props;
  useEffect(() => loadPerson(personId), [loadPerson, personId]);
  return (
    <span>
      {" "}
      {person && (
        <ResourceLink
          label={`${person.first_name} ${person.last_name || ""}`}
          id={person.id}
          url="auth/persons"
        />
      )}
    </span>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadPerson: (personId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSON,
        `auth/persons/${personId}/`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  person: state.tabStates[ownProps.tabId].persons.person.person
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonLinkContainer);
