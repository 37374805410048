import { connect } from "react-redux";
import {
  mainRootState,
  RouteInstancesQuery,
} from "../../redux/types";
import { Dispatch } from "redux";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import { filterQueryParams } from "../../helpers/queryParams";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import RouteInstances from "../../components/routes/routeinstances/RouteInstances";

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  getRouteInstances: (filter: RouteInstancesQuery = {}) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.ROUTEINSTANCES,
        `orders/routeinstances/${filterQueryParams(filter)}`
      )
    );
  },
  getRoutePlanOptions: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.ROUTEPLAN_OPTIONS,
        `orders/routeplans/?include=["id","name"]&page=1&page_size=10&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  resetRouteInstances: () =>
    dispatch(LoadingBooleansActions.ResetRouteinstances(ownProps.tabId)),
  resetRoutePlans: () =>
    dispatch(LoadingBooleansActions.ResetRouteplans(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  routeinstances:
    state.tabStates[ownProps.tabId].routes.routeinstances.routeinstances,
  routeplans: state.tabStates[ownProps.tabId].routes.routeplans.routeplans,
  routeInstancesCount:
    state.tabStates[ownProps.tabId].routes.routeinstances.count,
  routePlansCount: state.tabStates[ownProps.tabId].routes.routeplans.count,
  loadingRouteinstances:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingRouteinstances,
  routeplanOptions:
    state.tabStates[ownProps.tabId].routes.routeplans.routeplanOptions
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteInstances);
