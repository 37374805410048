import React, { useEffect, useState } from "react";
import store from "../redux/store";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Formik, Field, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { FormikSubmitDispatchProps, LoginSubmitValues } from "../redux/types";
import { Checkbox, CircularProgress } from "@material-ui/core";
import { logout } from "./../redux/reducers/authentication/actions";
import { createResetLoginError } from "../redux/reducers/authentication/actions";
import { Alert } from "@material-ui/lab";
import { useTranslate } from "../services/appLanguageService";
import { getFromSessionStore } from "../services/storageService";

interface ComponentProps extends FormikSubmitDispatchProps<LoginSubmitValues> {
  open: boolean;
  username: string;
  domain: string;
  isLoginError: boolean;
  authenticating: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    checkboxField: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    checkboxFieldLabel: {
      fontWeight: 600,
      color: "gray"
    },
    errorAlert: {
      margin: 10
    },
    dialogActionButtonsContainer: {
      justifyContent: "space-between"
    },
    dialogActionsButton: {
      width: "50%"
    },
    loginButtonLoader: {
      paddingLeft: "12px"
    }
  })
);

export default function AuthorizationDialog(props: ComponentProps) {
  const { isLoginError } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const t = useTranslate("AuthorizationDialog");
  const classes = useStyles();
  const [userDetails, setUserDetails] = useState({
    domain: props.domain,
    username: props.username,
    password: "",
    rememberMe: false
  });

  const initLogin = () => {
    const rememberUser = getFromSessionStore("rememberUser");
    if (rememberUser) {
      const { username, rememberMe } = JSON.parse(rememberUser);
      setUserDetails((_details) => ({ ..._details, username, rememberMe }));
    }
  };

  const handleLoginLoading = (
    values: LoginSubmitValues,
    actions: FormikHelpers<LoginSubmitValues>
  ) => {
    props.handleSubmit(values, actions);
  };

  const navigateToLoginScreen = () => {
    store.dispatch(logout());
  };
  const handleFormChange = () => {
    if (isLoginError) {
      store.dispatch(createResetLoginError());
    }
  };

  useEffect(initLogin, []);
  return (
    <Dialog fullScreen={fullScreen} open={props.open}>
      <Formik
        initialValues={userDetails}
        enableReinitialize
        onSubmit={handleLoginLoading}
      >
        {(formikProps) => (
          <form
            onSubmit={formikProps.handleSubmit}
            autoComplete="off"
            onChange={handleFormChange}
          >
            <DialogTitle id="authorization-dialog-title">
              {t("expiredSession")}
            </DialogTitle>
            {isLoginError && (
              <Alert className={classes.errorAlert} severity="error">
                {t("loginError")}
              </Alert>
            )}
            <DialogContent>
              <DialogContentText>{t("writePasswordHint")}</DialogContentText>
              {t("domain") + ": " + formikProps.values.domain}
              <br />
              {t("username") + ": " + formikProps.values.username}
              <Field
                id="authorization-dialog-password"
                type="password"
                required
                name="password"
                label={t("passwordFieldLabel")}
                placeholder={t("passwordFieldPlaceholder")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              <div className={classes.checkboxField}>
                <label className={classes.checkboxFieldLabel}>
                  {t("saveMe")}
                </label>
                <Checkbox
                  name="rememberMe"
                  value={formikProps.values.rememberMe}
                  checked={formikProps.values.rememberMe}
                  onClick={formikProps.handleChange}
                />
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActionButtonsContainer}>
              <Button
                id="authorization-dialog-logout"
                variant="outlined"
                color="secondary"
                className={classes.dialogActionsButton}
                onClick={navigateToLoginScreen}
              >
                {t("logoutButton")}
              </Button>
              <Button
                id="authorization-dialog-submit"
                type="submit"
                disabled={props.authenticating}
                className={classes.dialogActionsButton}
                variant="contained"
                color="primary"
              >
                {t("loginButtonLabel")}
                {props.authenticating && (
                  <span className={classes.loginButtonLoader}>
                    <CircularProgress size="1rem" />
                  </span>
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
