import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Company } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";
import ResourceLink from "../../components/links/ResourceLink";

interface CompanyLinkContainerProps {
  companyId: number;
  company: Company;
  loadCompany: (companyId: number) => void;
}

const CompanyLinkContainer: React.FC<CompanyLinkContainerProps> = (props) => {
  const { loadCompany, companyId, company } = props;
  useEffect(() => loadCompany(companyId), [loadCompany, companyId]);
  return (
    <span>
      {company && (
        <ResourceLink
          label={company.name}
          id={company.id}
          url="companies/companies"
        />
      )}
    </span>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadCompany: (companyId: number) => {
    if (companyId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COMPANY,
          `companies/companies/${companyId}/`
        )
      );
    }
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  company: state.tabStates[ownProps.tabId].companies.companies.company
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyLinkContainer);
