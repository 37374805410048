import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Field, Formik } from "formik";
import { WithStyles, Button, Grid } from "@material-ui/core";
import { LocationForm, SelectFieldOption } from "../redux/types";
import { useTranslate } from "../services/appLanguageService";
import { TextField } from "formik-material-ui";
import { AutoCompleteSelect } from "./AutoCompleteSelect";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    }
  });

interface InvoiceLocationFormProps extends WithStyles<typeof styles> {
  initialValues: LocationForm;
  handleSubmit: (values: LocationForm) => void;
  districtOptions: SelectFieldOption[];
}

const InvoiceLocationForm: React.FC<InvoiceLocationFormProps> = (props) => {
  const { classes, initialValues, handleSubmit, districtOptions } = props;
  const t = useTranslate("InvoiceLocationFormPage");

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <form
          className={classes.form}
          onSubmit={formikProps.handleSubmit}
          autoComplete="off"
        >
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Field
                  type="text"
                  name="addressee"
                  variant="outlined"
                  label={t("addresseeLabel")}
                  placeholder={t("addresseeLabel")}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  type="text"
                  name="address"
                  variant="outlined"
                  label={t("addressLabel")}
                  placeholder={t("addressLabel")}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  type="text"
                  name="extra_address"
                  variant="outlined"
                  label={t("extraAddressLabel")}
                  placeholder={t("extraAddressLabel")}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  type="text"
                  name="zip_code"
                  variant="outlined"
                  label={t("zipCodeLabel")}
                  placeholder={t("zipCodeLabel")}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  type="text"
                  name="city"
                  variant="outlined"
                  label={t("cityLabel")}
                  placeholder={t("cityLabel")}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  type="text"
                  name="country"
                  variant="outlined"
                  label={t("countryLabel")}
                  placeholder={t("countryLabel")}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  type="text"
                  name="district"
                  label={t("districtLabel")}
                  placeholder={t("districtLabel")}
                  options={districtOptions}
                  component={AutoCompleteSelect}
                  margin="normal"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  type="text"
                  name="notes"
                  variant="outlined"
                  label={t("notesLabel")}
                  placeholder={t("notesLabel")}
                  component={TextField}
                  multiline={true}
                  rows="4"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>

          <Button
            id="new-company-form-submit"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("saveButtonLabel")}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(InvoiceLocationForm);
