import { InvoiceRecipientsState, Action, InvoiceRecipient } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: InvoiceRecipientsState = {
  invoiceRecipients: {
    results: [],
    next: null,
    previous: null,
    count: 0
  },
  invoiceRecipient: {
    invoiceRecipient: {
      id: -1,
      created_at: "",
      updated_at: "",
      type: "MAIN",
      email: "",
      person: null,
      company: -1
    }
  },
  persons: []
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.INVOICERECIPIENTS
    ):
      return {
        ...state,
        invoiceRecipients: {
          results: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.INVOICERECIPIENTS_PERSONS
    ):
      return {
        ...state,
        persons: action.payload.results
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.INVOICERECIPIENT
    ):
      return {
        ...state,
        invoiceRecipient: {
          invoiceRecipient: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.INVOICERECIPIENTS
    ):
      return {
        ...state,
        invoiceRecipients: {
          ...state.invoiceRecipients,
          results: [...state.invoiceRecipients.results, action.payload]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.INVOICERECIPIENT
    ):
      return {
        ...state,
        invoiceRecipients: {
          ...state.invoiceRecipients,
          results: [
            ...state.invoiceRecipients.results.map((invoiceRecipient) =>
              invoiceRecipient.id === action.payload.id
                ? action.payload
                : invoiceRecipient
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.INVOICERECIPIENT
    ):
      return {
        ...state,
        invoiceRecipients: {
          ...state.invoiceRecipients,
          results: state.invoiceRecipients.results.filter(
            (invoiceRecipient: InvoiceRecipient) =>
              invoiceRecipient.id !== parseInt(action.payload.id)
          )
        }
      };

    default:
      return state;
  }
}

export default reducer;
