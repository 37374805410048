import React from "react";
import {
  createStyles,
  Dialog,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import MapContainer from "../../../containers/contracts/tasks/MapContainer";
import { FeatureCollection } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    fullWidth: { maxWidth: "800px !important" },
    container: { background: "rgba(255, 255, 255, 0)" },
    mapContainer: {
      margin: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
      width: 650,
      [theme.breakpoints.down(768)]: {
        width: "auto"
      }
    }
  })
);

interface GeoPolygonBuilderDialogProps {
  geoPolygons: FeatureCollection | null;
  open: boolean;
  closeDialog: () => void;
}

const GeoPolygonBuilderDialog: React.FC<GeoPolygonBuilderDialogProps> = (
  props
) => {
  const classes = useStyles();
  const t = useTranslate("RowDetailsPage");
  const { geoPolygons, open, closeDialog } = props;

  console.log(geoPolygons);
  return (
    geoPolygons && (
      <>
        <Dialog
          open={open}
          onClose={() => closeDialog()}
          classes={{
            container: classes.container,
            paperWidthSm: classes.fullWidth
          }}
          aria-labelledby="geo-polygon-builder-dialog"
        >
          <DialogTitle id="geo-polygon-builder-dialog">
            {t("geoPolygonDialogHeading")}
          </DialogTitle>
          <div className={classes.mapContainer}>
            <MapContainer
              editable={false}
              isInDialog={true}
              geo_polygons={geoPolygons}
              {...mapTabRouteProps(props)}
            />
          </div>
        </Dialog>
      </>
    )
  );
};

export default GeoPolygonBuilderDialog;
