import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../../redux/types";
import InvoicingProfileContainer from "./InvoicingProfileContainer";
import InvoicingProfileEditFormContainer from "./InvoicingProfileEditFormContainer";
import InvoicingProfileNewFormContainer from "./InvoicingProfileNewFormContainer";
import InvoicingProfilesContainer from "./InvoicingProfilesContainer";
import BeenhereIcon from "@material-ui/icons/Beenhere";

const InvoicingProfilesRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["invoices/invoicingprofiles"],
    requireSuperuser: true,
    routes: [
      {
        path: "/invoices/invoicingprofiles",
        label: t("invoicingProfilesNavbarInvoicesLabel"),
        component: InvoicingProfilesContainer,
        icon: BeenhereIcon,
        highlightClass: NavItemClassEnum.INVOICINGPROFILES,
        navId: NavItemClassEnum.INVOICINGPROFILES,
      },
      {
        path: "/invoices/invoicingprofiles/new",
        parent: "/invoices/invoicingprofiles",
        label: t("newInvoicingProfileLabel"),
        component: InvoicingProfileNewFormContainer,
        highlightClass: NavItemClassEnum.INVOICINGPROFILES,
      },
      {
        path: "/invoices/invoicingprofiles/:invoicingprofileId",
        parent: "/invoices/invoicingprofiles",
        label: t("invoicingProfileLabel"),
        component: InvoicingProfileContainer,
        highlightClass: NavItemClassEnum.INVOICINGPROFILES,
      },
      {
        path: "/invoices/invoicingprofiles/:invoicingprofileId/edit",
        parent: "/invoices/invoicingprofiles/:invoicingprofileId",
        label: t("editInvoicingProfileLabel"),
        component: InvoicingProfileEditFormContainer,
        highlightClass: NavItemClassEnum.INVOICINGPROFILES,
      },
    ],
  },
];

export default InvoicingProfilesRouter;
