import { ConnectionStatusAction } from "./actionTypes";
import { ConnectionEvent } from "../../types";

export const updateConnectionStatus = (
  e: ConnectionEvent
): ConnectionStatusAction => {
  return {
    type: e.type
  };
};
