import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Link
} from "@material-ui/core";
import * as types from "../../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import DistrictsContainer from "../../../containers/companies/districts/DistrictsContainer";
import InvoiceRecipientsContainer from "../../../containers/companies/companies/InvoiceRecipientsContainer";
import { useTranslate } from "../../../services/appLanguageService";
import PersonsContainer from "../../../containers/persons/PersonsContainer";
import CustomerobjectsContainer from "../../../containers/contracts/customerobjects/CustomerobjectsContainer";
import { EnumStrings } from "../../../redux/strings";
import ImageContainer from "../../../containers/ImageContainer";
import EditableNotes from "../../EditableNotes";
import ContactPerson from "../../ContactPerson";
import EditIcon from "@material-ui/icons/Edit";
import * as Router from "react-router-dom";
import { Contract, TabComponentProps } from "../../../redux/types";
import ActiveCustomerobjectsContainer from "../../../containers/companies/companies/ActiveCustomerobjectsContainer";
import LocationMap from "../locations/LocationMap";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    mapContainer: {
      marginTop: 20,
      padding: 10
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 100,
      float: "right",
      padding: 10
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    },
    notes: {
      padding: 20,
      marginTop: 20,
      marginBottom: 20,
      overflow: "hidden"
    },
    table: {
      marginBottom: 20
    },
    editButton: {
      margin: 0,
      marginLeft: 10
    },
    invoiceAddress: {
      verticalAlign: "middle"
    },
    doneButton: {
      float: "right"
    },
    contact: {
      borderLeft: "1px solid #212121",
      marginLeft: 7,
      paddingLeft: 7
    }
  });

interface CompanyProps extends WithStyles<typeof styles>, TabComponentProps {
  company: types.Company;
  persons?: types.Person[];
  contactPerson: types.Person | null;
  saveNotes: (companyId: number, notes: string | null) => void;
  validContracts: Contract[];
}

const Company: React.FC<CompanyProps> = (props) => {
  const { classes, company, contactPerson, validContracts, tabId } = props;
  const t = useTranslate("CompanyPage");
  const t2 = useTranslate("Companies");

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    store.dispatch(setTitle(company.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell colSpan={2}>
                    <ImageContainer src={company.logo} type="logo" />
                    <Typography variant="h5">{company.name}</Typography>
                    {company.location && (
                      <Typography>
                        {company.location.address} <br />
                        {company.location.zip_code} {company.location.city}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contactPersonLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contactPerson && (
                      <ContactPerson contactPerson={contactPerson} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("companyTypeLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {t2(company.company_type)}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">{t2(company.status)}</TableCell>
                </TableRow>
                {validContracts.length > 0 && (
                  <TableRow key={5}>
                    <TableCell align="left" className={classes.bold}>
                      {t("membershipTypeLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {t2(company.membership_type)}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key={6}>
                  <TableCell align="left" className={classes.bold}>
                    {t("organisationNumberLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {company.organisation_number}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={10}>
                    <TableCell align="left" className={classes.bold}>
                      {t("contractLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {validContracts.length > 0
                        ? validContracts.map((contract) => (
                            <React.Fragment key={contract.id}>
                              <Link
                                component={React.forwardRef<
                                  HTMLAnchorElement,
                                  Partial<Router.LinkProps>
                                >((props, ref) => (
                                  <Router.Link
                                    to={`/contracts/contracts/${contract.id}`}
                                    {...props}
                                    ref={ref as any}
                                  />
                                ))}
                              >
                                {contract.name}
                              </Link>{" "}
                            </React.Fragment>
                          ))
                        : t("noValidContracts")}
                    </TableCell>
                  </TableRow>

                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("invoiceRefLabel")}
                    </TableCell>
                    <TableCell align="right">{company.invoice_ref}</TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("invoiceAddressLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <span>
                        {company.invoice_location &&
                          company.invoice_location.address &&
                          `${company.invoice_location.addressee} ${company.invoice_location.address}`}
                      </span>
                      <IconButton
                        aria-label="edit"
                        className={classes.editButton}
                        size="small"
                        component={React.forwardRef<
                          HTMLAnchorElement,
                          Partial<Router.LinkProps>
                        >((props, ref) => (
                          <Router.Link
                            to={`/companies/companies/${company.id}/edit-invoice-location`}
                            {...props}
                            ref={ref as any}
                          />
                        ))}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("websiteLabel")}
                    </TableCell>
                    <TableCell align="right">{company.website}</TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className={classes.bold}>
                      {t("accountingReferenceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {company.accounting_reference}
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("paymentAccountLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {company.payment_account}
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(company.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(company.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
          <EditableNotes
            resourceId={company.id}
            notes={company.notes}
            saveNotes={props.saveNotes}
          />
          <PersonsContainer
            companyId={company.id}
            slimmedDown={true}
            showNewButton={false}
            showResourceButton={false}
            customHeader={t("personnelHeader")}
            editable={true}
            forCompanyType={company.company_type}
            {...mapTabRouteProps(props)}
          />
          <DistrictsContainer
            customHeader={t("districtsHeader")}
            showNewButton={false}
            showResourceButton={false}
            districtIds={company.districts}
            {...mapTabRouteProps(props)}
          />
          <InvoiceRecipientsContainer
            customHeader={t("invoiceRecipientsHeader")}
            showNewButton={false}
            invoiceRecipientIds={company.invoicerecipients}
            companyId={company.id}
            {...mapTabRouteProps(props)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {props.company.location && (
            <Paper className={classes.mapContainer}>
              <LocationMap
                location={props.company.location}
                secondLocation={props.company.invoice_location}
                editable={false}
              />
            </Paper>
          )}
          {company.company_type === EnumStrings.CUSTOMER && (
            <CustomerobjectsContainer
              companyId={company.id}
              showResourceButton={false}
              showNewButton={false}
              slimmedDown={true}
              editable={true}
              {...mapTabRouteProps(props)}
            />
          )}
          {company.company_type === EnumStrings.CONTRACTOR &&
            (validContracts.length > 0 ? (
              <ActiveCustomerobjectsContainer
                contractor={company}
                validContract={validContracts[0]}
                {...mapTabRouteProps(props)}
              />
            ) : (
              <Paper>No valid contracts for contractor</Paper>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Company);
