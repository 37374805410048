import languages from "./../locales/index";
import { LanguageState } from "../redux/types";

let globalLocale: LanguageState;

resetLocale();

const blackList = ["AppHeader", "RouteLabels"];

const langKeySet = new Set();
let langDict: { [langKey: string]: string } = {};
let displayTimeout = 0;

const displayUsedTranslations = () => {
  const display = Object.entries(langDict)
    .filter(([, value]) => value !== "MISSING")
    .map(([key, value]) => `${key}: "${value}"`)
    .sort()
    .join("\n");
  const displayMissing = Object.entries(langDict)
    .filter(([, value]) => value === "MISSING")
    .map(([key, value]) => `${key}: "${value}"`)
    .sort()
    .join("\n");
  console.debug(`---TRANSLATIONS---\n${display}`);
  if (displayMissing) {
    console.error(`---TRANSLATIONS MISSING---\n${displayMissing}`);
  }
  langDict = {};
};

export function useTranslate(component: string): (key?: string | null) => string {
  const language = languages[globalLocale];
  const t = language[component];
  if (typeof t === "string") {
    return (_) => t;
  } else if (t) {
    return (key) => {
      if (!blackList.includes(component)) {
        const fullKey = `${component}.${key}`;
        if (!langKeySet.has(fullKey)) {
          langKeySet.add(fullKey);
          langDict[fullKey] = t[key ?? ""] || "MISSING";
          if (displayTimeout) {
            window.clearTimeout(displayTimeout);
          }
          displayTimeout = window.setTimeout(displayUsedTranslations, 2000);
        }
      }
      return t[key ?? ""] || key;
    };
  } else {
    return (key) => key ?? "";
  }
}
export const translate = useTranslate;

export function setLocale(locale: LanguageState) {
  globalLocale = locale;
  setLocaleOnHtml();
}
export function resetLocale() {
  globalLocale =
    ((localStorage.getItem("master_language") ||
      process.env.REACT_APP_LOCALE) as LanguageState) || "sv-SE";
  setLocaleOnHtml();
}
function setLocaleOnHtml() {
  const htmlTag = window.document.querySelector("html");
  if (htmlTag) {
    htmlTag.setAttribute("lang", globalLocale);
  } else {
    console.error("could not set locale on html");
  }
}

export const languagesObject: { [key: string]: LanguageState } = {
  sv: "sv-SE",
  en: "en-EN"
};

export const translateRITitle = (title: string) => {
  if (title.indexOf("AR,") === -1) {
    return title;
  }

  const language = localStorage.getItem("master_language");

  if (language === "sv-SE") {
    return title.replace("AR", "ER");
  } else {
    return title;
  }
};

// ::: warn user for mistakes in translations :::
const maps = Object.entries(languages).map(([locale, file]) => {
  const map = new Map<string, string>();
  Object.entries(file).forEach(([moduleName, module]) => {
    if (typeof module !== "string") {
      Object.entries(module as any).forEach(([fieldName, val]) =>
        map.set(`${moduleName} ##### ${fieldName}`, val as string)
      );
    }
  });
  return [locale, map] as [string, Map<string, string>];
});
const locales = maps.slice(0).reverse();
maps.forEach(([locale, map], idx) => {
  const errorMsgs = new Set<string>();
  const warningMsgs = new Set<string>();
  const [otherLocale, otherMap] = locales[idx];
  map.forEach((val, key) => {
    const commonExpressions = [
      "status",
      "ok",
      "start",
      "stop",
      "medium",
      "person",
      "material",
      "transport",
      "index",
      "order",
      "administration",
      "workprecision",
      "n/a",
      "be",
      "fe",
      "css",
      "digital",
      "socket",
      "deadline",
      "asap",
      "digital",
      "standard"
    ];

    if (!otherMap.has(key)) {
      errorMsgs.add(`${key} ##### ${val}`);
    }
    if (
      otherMap.get(key) === val &&
      !commonExpressions.includes(val.toLowerCase())
    ) {
      warningMsgs.add(`${key} ##### ${val}`);
    }
  });

  if (errorMsgs.size > 0) {
    console.error(
      `${errorMsgs.size} translations are present in ${locale} but not present in ${otherLocale}\n`,
      Array.from(errorMsgs).join("\n")
    );
  }

  if (locale === languagesObject.sv && warningMsgs.size > 0) {
    console.warn(
      `${warningMsgs.size} translations might not be translated in swedish\n`,
      Array.from(warningMsgs).join("\n")
    );
  }
});
