import { Fragment } from "react";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import HappyIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import SadIcon from "@material-ui/icons/SentimentDissatisfiedOutlined";
import {
  IApprovalSummary,
  IApprovalSummaryDetails
} from "../../../redux/types";
import { EnumStrings } from "../../../redux/strings";
import { ClassNameMap } from "@material-ui/styles/withStyles";

interface ApprovalDetailsHeadProps {
  routeinstance_notes: IApprovalSummaryDetails["routeinstance_notes"];
  participants: IApprovalSummary["participants"];
  classes: ClassNameMap<"expandableDiv">;
  t: any;
}
const approvalDetailsHead = (props: ApprovalDetailsHeadProps) => {
  const { routeinstance_notes, participants, classes, t } = props;

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9"
    }
  }))(Tooltip);
  return (
    <>
      <div className={classes.expandableDiv}>
        <b style={{ marginRight: "5px" }}>{t("instructionHeading")}:</b>
        {routeinstance_notes || t("notAvailableText")}
      </div>
      <div className={classes.expandableDiv}>
        <Grid container spacing={1}>
          {Array.from(participants.values()).map((routeParticipant) => (
            <Fragment key={routeParticipant.participantKey}>
              <Grid item xs={3}>
                <div>
                  <b>#{routeParticipant.positionNo}</b>
                  <b
                    style={{
                      marginLeft: "5px"
                    }}
                  >
                    {routeParticipant.contractor_name}
                  </b>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div>{routeParticipant.service_name}</div>
              </Grid>
              <Grid item xs={3}>
                <div>{routeParticipant.person_name}</div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  {routeParticipant.notes && (
                    <HtmlTooltip
                      title={
                        <>
                          <Typography color="inherit">
                            {t("commentTitle")}
                          </Typography>
                          {routeParticipant.notes}
                        </>
                      }
                      placement="bottom"
                      arrow
                    >
                      <ChatBubbleOutlineIcon />
                    </HtmlTooltip>
                  )}
                </div>
              </Grid>
              <Grid item xs={1}>
                {routeParticipant.workrating && (
                  <div>
                    {routeParticipant.workrating === EnumStrings.HAPPY ? (
                      <Tooltip
                        title={t("happyTooltip")}
                        placement="bottom"
                        arrow
                      >
                        <HappyIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={t("unhappyTooltip")}
                        placement="bottom"
                        arrow
                      >
                        <SadIcon />
                      </Tooltip>
                    )}
                  </div>
                )}
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </div>
    </>
  );
};
export default approvalDetailsHead;
