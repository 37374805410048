import { FC, useRef } from "react";
import { IconButton, Paper, Popover } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import {
  ICorrectionsCarry,
  IDataCarryKey,
  IApprovalCorrectionsKey
} from "../../redux/types";
import RowApprovalEditModeButtons from "../timesheetApprovalDialog/rowApprovalEditModeButtons";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    iconButton: { padding: theme.spacing(0.5), marginLeft: theme.spacing(1) },
    actionBar: {
      alignSelf: "flex-end"
    }
  })
);

interface ApprovalInlinePopupProps {
  correctionsCarry: ICorrectionsCarry;
  fieldName: IApprovalCorrectionsKey;
  editModeChanged: (editMode: IDataCarryKey) => void;
  resetField: (fieldName: IApprovalCorrectionsKey) => void;
  correctionPopupClosed: () => void;
  open: boolean;
}

const ApprovalInlinePopup: FC<ApprovalInlinePopupProps> = (props) => {
  const {
    fieldName,
    correctionsCarry,
    editModeChanged,
    resetField,
    correctionPopupClosed,
    open,
    children
  } = props;
  const classes = useStyles();
  const elementRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={elementRef}>
      <Popover
        open={open}
        anchorEl={elementRef.current}
        onClose={correctionPopupClosed}
      >
        <Paper elevation={8} style={{ padding: "10px" }}>
          <RowApprovalEditModeButtons
            handleEditModeChange={editModeChanged}
            resetFormChanges={() => resetField(fieldName)}
            selectedEditMode={correctionsCarry.editMode}
            inLineEdit={true}
          />

          {open && children}

          <div className={classes.actionBar}>
            <IconButton
              onClick={correctionPopupClosed}
              className={classes.iconButton}
            >
              <DoneIcon />
            </IconButton>
          </div>
        </Paper>
      </Popover>
    </div>
  );
};

export default ApprovalInlinePopup;
