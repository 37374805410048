import React, {
  CSSProperties,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import Select from "react-select";
import { FieldProps, getIn } from "formik";
import {
  InputActionMeta,
  InputActionTypes,
  ValueType
} from "react-select/src/types";
import {
  createStyles,
  emphasize,
  makeStyles,
  useTheme
} from "@material-ui/core/styles";
import { Paper, TextField, Typography, MenuItem } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { BaseTextFieldProps } from "@material-ui/core/TextField";
import { ControlProps } from "react-select/src/components/Control";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import { MenuProps, NoticeProps } from "react-select/src/components/Menu";
import { PlaceholderProps } from "react-select/src/components/Placeholder";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import { ValueContainerProps } from "react-select/src/components/containers";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { OptionProps } from "react-select/src/components/Option";
import clsx from "clsx";
import LoadingSpinner from "./LoadingSpinner";

const useStyles: any = makeStyles((theme: any) =>
  createStyles({
    input: {
      display: "flex",
      padding: 7,
      height: "auto"
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden"
    },
    chip: {
      margin: theme.spacing(0.5, 0.25)
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      )
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2)
    },
    singleValue: {
      fontSize: 14
    },
    placeholder: {
      position: "absolute",
      left: 10,
      bottom: 14,
      fontSize: 14
    },
    paper: {
      position: "absolute",
      zIndex: 100,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      width: "max-content",
      minWidth: "100%",
      wordWrap: "break-word"
    },
    select: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0)
    }
  })
);

export interface OptionType {
  value: number;
  label: string;
}

interface AutoCompleteSelectProps {
  options: OptionType[];
  isMulti: boolean;
  error?: string;
  style?: object;
  className?: string;
  readOnlyMode?: boolean;
  customHandleChange?: (selectedId: number | number[]) => void;
  loadOptions?: (searchString?: string) => void;
  loadOptionsAPI?: (
    searchString?: string,
    selectedIds?: number[]
  ) => Promise<any>;
  customAttribute?: string;
  selectedIds?: number[];
  disabled?: boolean;
  handleScrollBottom?: () => void;
  complexName?: { [key: string]: string | number };
  isLoading?: boolean;
}

function NoOptionsMessage(props: NoticeProps<OptionType, boolean>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Option(props: OptionProps<OptionType, boolean>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType, boolean>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps, variant }
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      variant={variant}
      {...TextFieldProps}
    />
  );
}

type MuiPlaceholderProps = Omit<
  PlaceholderProps<OptionType, boolean>,
  "innerProps"
> &
  Partial<Pick<PlaceholderProps<OptionType, boolean>, "innerProps">>;
function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType, boolean>) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={
        !props.selectProps.readOnlyMode ? (
          <CancelIcon {...props.removeProps} />
        ) : (
          <CheckIcon />
        )
      }
    />
  );
}

function Menu(props: MenuProps<OptionType, boolean>) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

const AutoCompleteSelect: React.FC<
  AutoCompleteSelectProps &
    FieldProps<OptionType["value"] | OptionType["value"][]>
> = (props) => {
  const { options, field, form, className, style, loadOptions, isLoading } =
    Object.assign({ style: {}, className: "" }, props);
  const classes = useStyles();
  const theme = useTheme();
  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
  };
  const error =
    !!getIn(props.form.errors, props.field.name) &&
    getIn(props.form.touched, props.field.name);

  function handleChange(value: ValueType<OptionType, boolean>) {
    const option = value as OptionType;
    if (option) {
      form.setFieldValue(field.name, option.value);
      props.customHandleChange?.(option.value);
    } else {
      form.setFieldValue(field.name, -1);
      props.customHandleChange?.(-1);
    }
  }

  const timerRef = useRef<number>();

  const value = Array.isArray(props.field.value)
    ? props.field.value[0]
    : props.field.value;

  return (
    <div className={className} style={style}>
      <Select
        value={options.find((o) => o.value === value) ?? null}
        {...props}
        onMenuScrollToBottom={props.handleScrollBottom}
        className={classes.select}
        classes={classes}
        styles={selectStyles}
        options={options}
        onChange={handleChange}
        components={{
          ...components,
          Menu: (props: MenuProps<OptionType, boolean>) => {
            return isLoading ? (
              <Paper>
                <LoadingSpinner />
              </Paper>
            ) : (
              <Paper
                square
                className={props.selectProps.classes.paper}
                {...props.innerProps}
              >
                {props.children}
              </Paper>
            );
          }
        }}
        isClearable={true}
        menuPortalTarget={document.body}
        onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
          const INPUT_CHANGE: InputActionTypes = "input-change";

          if (timerRef.current) {
            window.clearTimeout(timerRef.current);
          }

          if (loadOptions && actionMeta.action === INPUT_CHANGE) {
            timerRef.current = window.setTimeout(
              () => loadOptions(newValue),
              500
            );
          }
        }}
        isDisabled={typeof props.disabled !== "undefined" && props.disabled}
      />
      {error && (
        <div style={{ color: "red", marginTop: ".5rem" }}>
          {getIn(props.form.errors, props.field.name)}
        </div>
      )}
    </div>
  );
};

const AutoCompleteSelectMultiNEW: React.FC<
  AutoCompleteSelectProps & FieldProps
> = (props) => {
  const { field, form, loadOptionsAPI, className, style } = Object.assign(
    { style: {}, className: "" },
    props
  );
  const error =
    !!getIn(props.form.errors, props.field.name) &&
    getIn(props.form.touched, props.field.name);

  const [loadedOptions, setLoadedOptions] = useState<OptionType[]>([]);

  const classes = useStyles();
  const theme = useTheme();
  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
  };

  const fetchOptions = (inputValue: string, selectedIds?: number[]) =>
    new Promise<OptionType[]>(async (resolve) => {
      if (loadOptionsAPI) {
        try {
          const response = await loadOptionsAPI(inputValue, selectedIds);
          resolve(
            response.results.map((result: any) => ({
              label: props.customAttribute
                ? result[props.customAttribute]
                : result.name,
              value: result.id
            }))
          );
        } catch (e) {
          console.warn(e);
        }
      }
    });

  const fetchOptionsCb = useCallback(fetchOptions, [
    loadOptionsAPI,
    props.customAttribute
  ]);

  async function updateOptions(
    searchString: string,
    currentOptions: OptionType[]
  ) {
    const fetchedOptions = await fetchOptionsCb(searchString);
    if (currentOptions.length || fetchedOptions.length) {
      setLoadedOptions([
        ...currentOptions,
        ...fetchedOptions.filter(
          (option: OptionType) =>
            !currentOptions
              .map((_option: OptionType) => _option.value)
              .includes(option.value)
        )
      ]);
    }
  }
  const updateOptionsCb = useCallback(updateOptions, [fetchOptionsCb]);

  useEffect(() => {
    async function fetchOptions() {
      if (loadedOptions.length === 0) {
        const selectedOptions =
          props.selectedIds && props.selectedIds.length > 0
            ? await fetchOptionsCb("", props.selectedIds)
            : [];

        updateOptionsCb("", selectedOptions);
      }
    }

    fetchOptions();
  }, [
    props.options,
    loadedOptions,
    updateOptionsCb,
    fetchOptionsCb,
    props.selectedIds
  ]);

  function handleChange(values: ValueType<OptionType, boolean>) {
    const option = values
      ? (values as OptionType[]).map((option) => option.value)
      : values;
    if (option) {
      form.setFieldValue(field.name, option);
      props.customHandleChange?.(option);
    } else {
      form.setFieldValue(field.name, []);
      props.customHandleChange?.([]);
    }
  }

  return (
    <div className={className} style={style}>
      <Select
        {...props}
        className={classes.select}
        classes={classes}
        styles={selectStyles}
        onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
          const INPUT_CHANGE: InputActionTypes = "input-change";
          if (actionMeta.action === INPUT_CHANGE) {
            updateOptionsCb(newValue, loadedOptions);
          }
        }}
        onChange={handleChange}
        components={components}
        isMulti
        options={loadedOptions}
        value={loadedOptions.filter((option: any) => {
          if (props.complexName) {
            return form.values[props.complexName.firstName][
              props.complexName.index
            ][props.complexName.secondName].includes(option.value);
          }
          return form.values[field.name].includes(option.value);
        })}
        isDisabled={typeof props.disabled !== "undefined" && props.disabled}
      />
      {error && (
        <div style={{ color: "red", marginTop: ".5rem" }}>
          {getIn(props.form.errors, props.field.name)}
        </div>
      )}
    </div>
  );
};

const AutoCompleteSelectMulti: React.FC<
  AutoCompleteSelectProps & FieldProps<OptionType["value"][]>
> = (props) => {
  const { options, field, form, className, style } = Object.assign(
    { style: {}, className: "" },
    props
  );
  const error =
    !!getIn(props.form.errors, props.field.name) &&
    getIn(props.form.touched, props.field.name);

  const classes = useStyles();
  const theme = useTheme();
  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
  };

  function handleChange(values: ValueType<OptionType, boolean>) {
    const option = values
      ? (values as OptionType[]).map((option) => option.value)
      : values;
    if (option) {
      form.setFieldValue(field.name, option);
      props.customHandleChange?.(option);
    } else {
      form.setFieldValue(field.name, []);
      props.customHandleChange?.([]);
    }
  }

  const values = options.filter((o) => props.field.value.includes(o.value));

  return (
    <div className={className} style={style}>
      <Select
        value={values}
        {...props}
        className={classes.select}
        classes={classes}
        styles={selectStyles}
        options={options}
        onChange={handleChange}
        components={components}
        isMulti
        menuPortalTarget={document.body}
        selectProps={{
          readOnlyMode: props.readOnlyMode
        }}
        isDisabled={typeof props.disabled !== "undefined" && props.disabled}
      />
      {error && (
        <div style={{ color: "red", marginTop: ".5rem" }}>
          {getIn(props.form.errors, props.field.name)}
        </div>
      )}
    </div>
  );
};

export {
  components,
  AutoCompleteSelect,
  AutoCompleteSelectMulti,
  AutoCompleteSelectMultiNEW
};
