import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, NotificationsProfile } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import { RestStrings } from "../../redux/strings";
import ResourcesTableContainer from "../../components/resourcetable/ResourcesTableContainer";

interface NotificationsProfilesContainerProps {
  notificationsprofiles: NotificationsProfile[];
  count: number;
  loadingNotificationsProfiles: boolean;
  notificationsprofileIds?: number[];
  resetNotificationsProfiles: () => void;
  loadNotificationsProfiles: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  showNewButton?: boolean;
  customHeader?: string;
}

const NotificationsProfilesContainer: React.FC<
  NotificationsProfilesContainerProps
> = (props) => {
  const {
    notificationsprofileIds,
    loadNotificationsProfiles,
    resetNotificationsProfiles,
    loadingNotificationsProfiles,
    notificationsprofiles
  } = props;

  const page = 1;
  const pageSize = 20;

  let filter = "";

  if (notificationsprofileIds) {
    const filterStr =
      notificationsprofileIds.length !== 1 ? "filter__id__in" : "filter__id";
    const notificationsprofileIdsStr = notificationsprofileIds.length
      ? notificationsprofileIds.toString()
      : "";

    filter = `&${filterStr}=${notificationsprofileIdsStr}`;
  }

  useEffect(
    () =>
      loadNotificationsProfiles(page, pageSize, undefined, undefined, filter),
    [filter, loadNotificationsProfiles]
  );
  useEffect(
    () => () => resetNotificationsProfiles(),
    [resetNotificationsProfiles]
  );

  const columnDefinitions = [
    { name: "avatar", type: "roundThumbnail" },
    { name: "name", type: "link" }
  ];

  const showNewButton =
    (typeof props.showNewButton !== "undefined" && props.showNewButton) ||
    typeof props.showNewButton === "undefined";

  return (
    <ResourcesTableContainer
      resources={notificationsprofiles}
      resourceName={"NotificationsProfile"}
      count={props.count}
      loadingResources={loadingNotificationsProfiles}
      routeUrl={"notification-profiles"}
      columnDefinitions={columnDefinitions}
      resetResources={resetNotificationsProfiles}
      loadResources={loadNotificationsProfiles}
      showNewButton={showNewButton}
      customFilter={filter}
      customHeader={props.customHeader}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadNotificationsProfiles: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__contains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "NOTIFICATIONSPROFILES",
        `services/notificationsprofiles/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  resetNotificationsProfiles: () =>
    dispatch(LoadingBooleansActions.ResetNotificationsProfiles(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  notificationsprofiles:
    state.tabStates[ownProps.tabId].notificationsProfiles.notificationsProfiles
      .results,
  count:
    state.tabStates[ownProps.tabId].notificationsProfiles.notificationsProfiles
      .count,
  loadingNotificationsProfiles:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingNotificationsProfiles
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsProfilesContainer);
