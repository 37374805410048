import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  IconButton,
  Paper,
  Grid,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
  Table as MuiTable,
  GridSize
} from "@material-ui/core";
import {
  useTable,
  useSortBy,
  usePagination,
  useGroupBy,
  useExpanded
} from "react-table";
import { ColumnDefinition, TableForm } from "../../redux/types";
import TableFormComponent from "./components/TableFormComponent";
import TableSearch from "./components/TableSearch";

import {
  Done as DoneIcon,
  Close as CloseIcon,
  AddBox as AddBoxIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon
} from "@material-ui/icons";
import { useTranslate } from "../../services/appLanguageService";
import React, { useEffect } from "react";

const styles = (theme: any) =>
  createStyles({
    tableTitle: {
      padding: 20
    },
    paper: {
      marginTop: 20,
      paddingTop: 10
    },
    rightFloat: {
      float: "right"
    },
    noRows: {
      textAlign: "center"
    },
    tableHead: {
      backgroundColor: "#FFF",
      fontWeight: "bold"
    },
    hover: {
      "&:hover": {
        color: "#777"
      },
      cursor: "pointer"
    },
    spinner: {
      margin: theme.spacing(3)
    },
    actionButton: {
      padding: 5,
      marginRight: "-5px"
    },
    sameRow: {
      display: "flex"
    },
    dense: {
      padding: 6,
      "&:last-child": {
        paddingRight: 10
      },
      "&:first-child": {
        paddingLeft: 10
      }
    }
  });

interface ResourceTableProps extends WithStyles<typeof styles> {
  title: string;
  columns: any;
  data: any[];
  count: number;
  pageSize: number;
  pageIndex: number;
  previous: string | null;
  next: string | null;
  handlePageChange: (event: any, newPage: number) => void;
  handleRowsPerPageChange: (event: any) => void;
  columnDefinitions: ColumnDefinition[];
  pluralName: string;
  addResource?: (
    newData: any,
    owningId?: number,
    secondOwningId?: number
  ) => void;
  updateResource?: (resourceId: number, newData: any) => void;
  handleSearch: (searchString: string) => void;
  owningId?: number;
  secondOwningId?: number;
  showActionColumn: boolean;
  loadingResources: boolean;
  addingResource: boolean;
  handleAddForm: (data: TableForm) => void;
  addForm: TableForm;
  handleAddingResource: (addingResource: boolean) => void;
  groupBy?: boolean;
  handleFormValidation: (
    field: string,
    validation: { error: boolean; errorText: string }
  ) => void;
  formValidation: {
    [field: string]: { error: boolean; errorText: string };
  };
  validated: boolean;
  altFieldType?: string;
  customHeaderComponent?: React.ReactNode;
}

const ResourceTable: React.FC<ResourceTableProps> = (props) => {
  const {
    columns,
    data,
    classes,
    count,
    columnDefinitions,
    pluralName,
    addResource,
    addingResource,
    handleAddingResource,
    customHeaderComponent
  } = props;

  const t = useTranslate("ResourceTable");

  const hiddenColumns = columnDefinitions
    .filter((columnDefinition) => columnDefinition.type === "hidden")
    .map((columnDefinition) => columnDefinition.name);

  const groupedByColumns = columnDefinitions
    .filter(
      (columnDefinition) =>
        typeof columnDefinition.groupedBy !== "undefined" &&
        columnDefinition.groupedBy
    )
    .map((columnDefinition) => columnDefinition.name);

  // Generate default expanded rows
  const initialExpanded = React.useMemo(
    () => ({
      expanded: groupedByColumns.reduce((total: any, column: string) => {
        const expandedRows = data
          .map((row) => row[column])
          .reduce((total: any, value: string) => {
            return { ...total, [`${column}:${value}`]: true };
          }, {});

        return { ...total, ...expandedRows };
      }, {})
    }),
    [data, groupedByColumns]
  );

  const hiddenGroupColumns = addingResource ? [] : groupedByColumns;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGroupBy,
    setHiddenColumns,
    toggleHideAllColumns
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: props.showActionColumn
          ? [...hiddenColumns, ...hiddenGroupColumns]
          : [...hiddenColumns, "id", ...hiddenGroupColumns],
        groupBy: groupedByColumns.length > 0 ? groupedByColumns : [],
        ...initialExpanded
      }
    },
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  const nrOfColumns =
    columnDefinitions.length + (props.showActionColumn ? 1 : 0);

  const getAddActions = () =>
    addResource &&
    addingResource && (
      <div className={`${classes.rightFloat} ${classes.sameRow}`}>
        <IconButton
          aria-label="add"
          data-cy={`${pluralName}-done-add`}
          className={classes.actionButton}
          onClick={() => {
            if (props.validated) {
              addResource(props.addForm, props.owningId, props.secondOwningId);
              handleAddingResource(false);
            }
          }}
        >
          <DoneIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          className={classes.actionButton}
          onClick={() => {
            handleAddingResource(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );

  useEffect(() => {
    if (props.groupBy && addingResource && rows[0] && rows[0].isGrouped) {
      setGroupBy([]);
    } else if (
      props.groupBy &&
      !addingResource &&
      rows[0] &&
      !rows[0].isGrouped
    ) {
      setGroupBy(groupedByColumns);
    }
  }, [
    addingResource,
    setGroupBy,
    rows,
    setHiddenColumns,
    hiddenColumns,
    groupedByColumns,
    props.groupBy,
    toggleHideAllColumns
  ]);

  let titleGridWidth = 8;

  if (customHeaderComponent) {
    titleGridWidth -= 4;
  }

  if (addResource) {
    titleGridWidth -= 2;
  }

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12} sm={titleGridWidth as GridSize}>
          <Typography
            variant="h6"
            component="h6"
            className={classes.tableTitle}
          >
            {props.title}
          </Typography>
        </Grid>
        {customHeaderComponent && (
          <Grid item xs={12} sm={4}>
            {customHeaderComponent}
          </Grid>
        )}
        <Grid item xs={10} sm={4}>
          <TableSearch
            handleSearch={props.handleSearch}
            showAddButton={typeof addResource !== "undefined"}
            pluralName={pluralName}
          />
        </Grid>
        {addResource && (
          <Grid item xs={2} sm={1}>
            <IconButton
              aria-label="add"
              data-cy={`${pluralName}-add`}
              className={classes.tableTitle}
              onClick={() => {
                handleAddingResource(true);
              }}
            >
              <AddBoxIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <TableContainer>
        <MuiTable
          {...getTableProps()}
          stickyHeader
          size="small"
          key={"resource_table"}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    className={`${classes.tableHead} ${classes.dense}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {addResource && addingResource && (
            <TableRow>
              {columnDefinitions
                .filter(
                  (columnDefinition) => columnDefinition.type !== "hidden"
                )
                .map((columnDefinition) => (
                  <TableCell className={classes.dense}>
                    <TableFormComponent
                      columnDefinition={columnDefinition}
                      handleForm={props.handleAddForm}
                      formData={props.addForm}
                      type={"ADD"}
                      pluralName={pluralName}
                      handleFormValidation={props.handleFormValidation}
                      formValidation={
                        props.formValidation[columnDefinition.name]
                      }
                      altFieldType={props.altFieldType}
                    />
                  </TableCell>
                ))}
              <TableCell>{getAddActions()}</TableCell>
            </TableRow>
          )}
          {props.loadingResources ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={nrOfColumns}>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={3}>
                      <CircularProgress className={classes.spinner} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody
              {...getTableBodyProps()}
              data-cy={`${pluralName}-table-body`}
            >
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow
                    {...row.getRowProps()}
                    key={i}
                    data-cy={`${pluralName}-table-row`}
                  >
                    {row.cells.map((cell, j) => {
                      return (
                        <TableCell
                          className={classes.dense}
                          {...cell.getCellProps()}
                          key={j}
                        >
                          <div>
                            {row.isGrouped && j === 0 && (
                              <IconButton
                                aria-label="expand"
                                onClick={() => row.toggleRowExpanded()}
                              >
                                {row.isExpanded ? (
                                  <ExpandMoreIcon />
                                ) : (
                                  <ChevronRightIcon />
                                )}
                              </IconButton>
                            )}
                            {!cell.isGrouped && cell.isAggregated
                              ? // If the cell is aggregated, use the Aggregated
                                // renderer for cell
                                cell.render("Aggregated")
                              : cell.isPlaceholder
                              ? null // For cells with repeated values, render null
                              : // Otherwise, just render the regular cell
                                cell.render("Cell")}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {rows.length === 0 && (
                <TableRow>
                  <TableCell className={classes.noRows} colSpan={nrOfColumns}>
                    {t("noRowsFoundLabel")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 20]}
        count={count}
        rowsPerPage={props.pageSize}
        page={props.pageIndex}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true
        }}
        onPageChange={props.handlePageChange}
        onRowsPerPageChange={props.handleRowsPerPageChange}
      />
    </Paper>
  );
};

export default withStyles(styles)(ResourceTable);
