import { SnackbarTypes } from "./../../../redux/types";
import { ClearSnackbarAction, ShowSnackbarAction } from "./actionTypes";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR";

export const clearSnackbar = (): ClearSnackbarAction => ({
  type: CLEAR_SNACKBAR
});
export const showSnackbar = (
  message: string,
  variant: SnackbarTypes = "default"
): ShowSnackbarAction => ({
  type: SHOW_SNACKBAR,
  payload: {
    variant,
    message
  }
});
