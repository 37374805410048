import { SHOW_SNACKBAR, CLEAR_SNACKBAR } from "./actions";
import { Action, SnackbarState } from "../../types";

const initialState: SnackbarState = {
  isSnackbar: false,
  message: "",
  variant: "info"
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        isSnackbar: true,
        message: action.payload.message,
        variant: action.payload.variant
      };
    case CLEAR_SNACKBAR:
      return {
        ...state,
        isSnackbar: false,
        message: ""
      };
    default:
      return state;
  }
}

export default reducer;
