import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  WeatherAreaForm,
  WeatherArea,
  mainRootState,
  SelectFieldOption
} from "../../../redux/types";
import WeatherAreaNewForm from "../../../components/companies/weatherAreas/WeatherAreaNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewWeatherAreaContainerProps
  extends FormikSubmitDispatchProps<WeatherAreaForm> {
  loadParentWeatherAreas: () => void;
  parentWeatherAreaOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<WeatherArea | WeatherAreaForm> {
  loadParentWeatherAreas: () => void;
}

const NewWeatherAreaContainer: React.FC<NewWeatherAreaContainerProps> = (
  props
) => {
  const { loadParentWeatherAreas } = props;

  useEffect(() => loadParentWeatherAreas(), [loadParentWeatherAreas]);
  return (
    <WeatherAreaNewForm
      handleSubmit={props.handleSubmit}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<WeatherAreaForm>(
        ownProps.tabId,
        RestStrings.POST,
        "WEATHERAREA",
        "companies/weatherareas/",
        checkedNullValues as WeatherArea,
        "/companies/weatherareas?same_tab=true"
      )
    );
  },
  loadParentWeatherAreas: () => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.WEATHERAREAS,
        "companies/weatherareas"
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  parentWeatherAreaOptions: state.tabStates[
    ownProps.tabId
  ].weatherAreas.weatherAreas.weatherAreas.map((parentWeatherArea) => ({
    label: parentWeatherArea.name,
    value: parentWeatherArea.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewWeatherAreaContainer);
