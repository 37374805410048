export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";
export const RESET_LOGIN_ERROR = "RESET_LOGIN_ERROR";
export const AUTHENTICATING = "AUTHENTICATING";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const LOGEDOUT = "LOGEDOUT";
export const FORBIDDEN = "FORBIDDEN";

interface AuthenticationRequestAction {
  type: typeof AUTHENTICATION_REQUEST;
  payload: {
    domain: string;
    username: string;
    password: string;
    rememberMe: boolean;
  };
}

interface AuthenticationSuccessAction {
  type: typeof AUTHENTICATION_SUCCESS;
  payload: {
    domain: string;
    username: string;
  };
}

interface AuthenticationFailureAction {
  type: typeof AUTHENTICATION_FAILURE;
  payload: object;
}
interface AuthenticatingAction {
  type: typeof AUTHENTICATING;
}

interface LogoutRequestAction {
  type: typeof LOGOUT_REQUEST;
}

interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

interface LogoutFailureAction {
  type: typeof LOGOUT_FAILURE;
  payload: object;
}

interface UnauthorizedAction {
  type: typeof UNAUTHORIZED;
}

interface ForbiddenAction {
  type: typeof FORBIDDEN;
}

interface ResetLoginErrorAction {
  type: typeof RESET_LOGIN_ERROR;
}

export type AuthenticationActionTypes =
  | AuthenticationRequestAction
  | AuthenticationSuccessAction
  | AuthenticationFailureAction
  | AuthenticatingAction
  | LogoutRequestAction
  | LogoutSuccessAction
  | LogoutFailureAction
  | UnauthorizedAction
  | ResetLoginErrorAction
  | ForbiddenAction;
