import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  ContractingProfileForm,
  ContractingProfile,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import ContractingProfileNewEditForm from "../../../components/contracts/contractingProfiles/ContractingProfileNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import {
  RestStrings,
  ReduxActionStrings,
  EnumStrings
} from "../../../redux/strings";
import {
  patchContractProfile,
  getContractProfileTestPdf
} from "../../../services/contractsService";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { saveAs } from "file-saver";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditContractingProfileContainerProps
  extends FormikSubmitDispatchProps<ContractingProfileForm>,
    TabComponentProps<"contractingprofileId"> {
  contractingProfile: ContractingProfile;
  companyOptions: SelectFieldOption[];
  customerContractOptions: SelectFieldOption[];
  contractorContractOptions: SelectFieldOption[];
  loadContractingProfile: (contractingprofileId: number) => void;
  loadCompanies: (searchTerm?: string) => void;
  loadCustomerContracts: (searchTerm?: string) => void;
  loadContractorContracts: (searchTerm?: string) => void;
  handleSaveAndCreatePDF: (
    contractingProfileId: number,
    contractingId: number,
    jinjaTemplate: string,
    contracting_css: string,
    type: string
  ) => void;
  loadingContractingProfile: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<
    ContractingProfile | ContractingProfileForm
  > {
  loadContractingProfile: (contractingprofileId: number) => void;
  loadCompanies: (searchTerm?: string) => void;
  loadCustomerContracts: (searchTerm?: string) => void;
  loadContractorContracts: (searchTerm?: string) => void;
  handleSaveAndCreatePDF: (
    contractingProfileId: number,
    contractingId: number,
    jinjaTemplate: string,
    contracting_css: string,
    type: string
  ) => void;
}

const EditContractingProfileContainer: React.FC<
  EditContractingProfileContainerProps
> = (props) => {
  const {
    loadContractingProfile,
    contractingProfile,
    routeParams: { contractingprofileId },
    loadCompanies,
    loadCustomerContracts,
    loadContractorContracts,
    handleSaveAndCreatePDF,
    loadingContractingProfile
  } = props;
  useEffect(
    () => loadContractingProfile(contractingprofileId),
    [loadContractingProfile, contractingprofileId]
  );
  useEffect(() => loadCompanies(), [loadCompanies]);
  useEffect(() => loadCustomerContracts(), [loadCustomerContracts]);
  useEffect(() => loadContractorContracts(), [loadContractorContracts]);

  return loadingContractingProfile ? (
    <LoadingSpinnerPaper />
  ) : (
    <ContractingProfileNewEditForm
      handleSaveAndCreatePDF={handleSaveAndCreatePDF}
      contractingProfile={contractingProfile}
      companyOptions={props.companyOptions}
      customerContractOptions={props.customerContractOptions}
      contractorContractOptions={props.contractorContractOptions}
      handleSubmit={props.handleSubmit}
      loadCompanies={props.loadCompanies}
      loadCustomerContracts={props.loadCustomerContracts}
      loadContractorContracts={props.loadContractorContracts}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.CONTRACTINGPROFILE,
        `contracts/contractingprofiles/${
          (checkedNullValues as ContractingProfile).id
        }/`,
        checkedNullValues,
        `/contracts/contractingprofiles/${
          (checkedNullValues as ContractingProfile).id
        }?same_tab=true`
      )
    );
  },
  handleSaveAndCreatePDF: async (
    contractingProfileId: number,
    contractingId: number,
    jinjaTemplate: string,
    contracting_css: string,
    type: string
  ) => {
    try {
      let values = {};
      if (type === EnumStrings.CUSTOMER) {
        values = {
          customer_contract_jinja: jinjaTemplate,
          contracting_css: contracting_css
        };
      } else if (type === EnumStrings.CONTRACTOR) {
        values = {
          contractor_contract_jinja: jinjaTemplate,
          contracting_css: contracting_css
        };
      }

      const response: ContractingProfile = await patchContractProfile(
        contractingProfileId,
        values
      );

      if (response) {
        let pdfBlob: string = "";
        pdfBlob = await getContractProfileTestPdf(
          contractingProfileId,
          contractingId
        );
        if (pdfBlob) {
          saveAs(pdfBlob, `contract-${contractingId}.pdf`);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  },
  loadContractingProfile: (contractingProfileId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CONTRACTINGPROFILE,
        `contracts/contractingprofiles/${contractingProfileId}/`
      )
    );
  },
  loadCompanies: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerContracts: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CUSTOMER_CONTRACTS,
        `contracts/contracts/?filter__company_type=CUSTOMER&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadContractorContracts: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CONTRACTOR_CONTRACTS,
        `contracts/contracts/?filter__company_type=CONTRACTOR&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  contractingProfile:
    state.tabStates[ownProps.tabId].contractingProfiles.contractingProfile
      .contractingProfile,
  companyOptions: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  })),
  customerContractOptions: state.tabStates[ownProps.tabId].contractingProfiles
    .contractingProfile.customerContracts
    ? state.tabStates[
        ownProps.tabId
      ].contractingProfiles.contractingProfile.customerContracts.map(
        (customerContract) => ({
          label: customerContract.name,
          value: customerContract.id
        })
      )
    : [],
  contractorContractOptions: state.tabStates[ownProps.tabId].contractingProfiles
    .contractingProfile.contractorContracts
    ? state.tabStates[
        ownProps.tabId
      ].contractingProfiles.contractingProfile.contractorContracts.map(
        (contractorContract) => ({
          label: contractorContract.name,
          value: contractorContract.id
        })
      )
    : [],
  loadingContractingProfile:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingContractingProfile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContractingProfileContainer);
