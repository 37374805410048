import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  PasscardForm,
  Passcard,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import PasscardNewEditForm from "../../../components/companies/passcards/PasscardNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { format } from "date-fns";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditPasscardContainerProps
  extends FormikSubmitDispatchProps<PasscardForm>,
    TabComponentProps<"passcardId"> {
  passcard: Passcard;
  customerOptions: SelectFieldOption[];
  contractorOptions: SelectFieldOption[];
  loadPasscard: (passcardId: number) => void;
  loadCustomers: (searchTerm?: string) => void;
  loadContractors: (searchTerm?: string) => void;
  personOptions: SelectFieldOption[];
  loadContractorPersons: (companyId: number) => void;
  loadingPasscard: boolean;
  loadCustomerobjects: (companyId: number) => void;
  customerobjectOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Passcard | PasscardForm> {
  loadPasscard: (passcardId: number) => void;
  loadCustomers: (searchTerm?: string) => void;
  loadContractors: (searchTerm?: string) => void;
  loadContractorPersons: (companyId: number) => void;
  loadCustomerobjects: (companyId: number) => void;
}

const EditPasscardContainer: React.FC<EditPasscardContainerProps> = (props) => {
  const {
    loadPasscard,
    passcard,
    routeParams: { passcardId },
    loadCustomers,
    loadContractors,
    loadContractorPersons,
    loadingPasscard,
    loadCustomerobjects
  } = props;
  useEffect(() => loadPasscard(passcardId), [loadPasscard, passcardId]);
  useEffect(() => loadCustomers(), [loadCustomers]);
  useEffect(() => loadContractors(), [loadContractors]);
  useEffect(
    () => loadContractorPersons(passcard.contractor_company),
    [loadContractorPersons, passcard.contractor_company]
  );
  useEffect(
    () => loadCustomerobjects(passcard.owner_company),
    [loadCustomerobjects, passcard.owner_company]
  );

  return loadingPasscard ? (
    <LoadingSpinnerPaper />
  ) : (
    <PasscardNewEditForm
      passcard={passcard}
      customerOptions={props.customerOptions}
      contractorOptions={props.contractorOptions}
      personOptions={props.personOptions}
      handleSubmit={props.handleSubmit}
      customerobjectOptions={props.customerobjectOptions}
      loadCustomerobjects={props.loadCustomerobjects}
      loadContractorPersons={props.loadContractorPersons}
      loadContractors={loadContractors}
      loadCustomers={loadCustomers}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values,
      out_date: values.out_date
        ? format(new Date(values.out_date), "yyyy-MM-dd")
        : null,
      in_date: values.in_date
        ? format(new Date(values.in_date), "yyyy-MM-dd")
        : null
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.PASSCARD,
        `companies/passcards/${(checkedNullValues as Passcard).id}/`,
        checkedNullValues,
        `/companies/passcards/${
          (checkedNullValues as Passcard).id
        }?same_tab=true`
      )
    );
  },
  loadPasscard: (passcardId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PASSCARD,
        `companies/passcards/${passcardId}/`
      )
    );
  },
  loadCustomers: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PASSCARD_CUSTOMERS,
        `companies/companies/?order__name=asc&filter__company_type=CUSTOMER&filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadContractors: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PASSCARD_CONTRACTORS,
        `companies/companies/?order__name=asc&filter__company_type=CONTRACTOR&filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerobjects: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CUSTOMEROBJECTS,
        `contracts/customerobjects/?order__name=asc&filter__customer=${companyId}&filter__status=ACTIVE`
      )
    );
  },
  loadContractorPersons: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSONS,
        `auth/persons/?order__first_name=asc&filter__company=${companyId}&filter__status=ACTIVE`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  passcard: state.tabStates[ownProps.tabId].passcards.passcard.passcard,
  customerOptions: state.tabStates[
    ownProps.tabId
  ].passcards.passcard.customers.map((company) => ({
    label: company.name,
    value: company.id
  })),
  contractorOptions: state.tabStates[
    ownProps.tabId
  ].passcards.passcard.contractors.map((company) => ({
    label: company.name,
    value: company.id
  })),
  personOptions: state.tabStates[ownProps.tabId].persons.persons.persons.map(
    (person) => ({
      label: `${person.first_name} ${person.last_name || ""}`,
      value: person.id
    })
  ),
  customerobjectOptions: state.tabStates[
    ownProps.tabId
  ].customerObjects.customerobjects.customerobjects.map((customerObject) => ({
    label: customerObject.name,
    value: customerObject.id
  })),
  loadingPasscard:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingPasscard
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPasscardContainer);
