import { useState, SetStateAction, Dispatch } from "react";
import { TimeSheetOptions, LocationMark } from "../redux/types";
import { MarkersRef } from "../components/googleMaps/GoogleMaps";

export const generateTaskLocation = (
  taskId: number,
  taskOptions: TimeSheetOptions["contracted"]["tasks"]
): LocationMark[] => {
  const selectedTask = taskOptions[taskId];
  const result: LocationMark[] = [];
  if (selectedTask) {
    const { latitude, longitude } = selectedTask;
    result.push({
      position: { lng: Number(longitude), lat: Number(latitude) },
      markerOptions: {}
    });
  }
  return result;
};

type MapAndTableHoverEventState<T> = [
  number | undefined,
  (id: number) => () => void,
  (id: number) => () => void,
  (locationMark: LocationMark<T>, marker: google.maps.Marker) => void,
  (locationMark: LocationMark<T>, marker: google.maps.Marker) => void,
  Dispatch<SetStateAction<number | undefined>>
];

export function useMapAndTableHoverEventState<T>(
  markersRef: MarkersRef<T>[],
  dataKey: keyof T,
  componentHoverInActive: boolean = false
): MapAndTableHoverEventState<T> {
  const [hoverMarkerId, setHoverMarkerId] = useState<number | undefined>();
  const onHoverRowIn = (id: number) => () => {

    if (componentHoverInActive || markersRef.length === 0) return;
    const marker = markersRef.find((marker) => {
      const data = marker.data as T & { [key: string]: number };
      return data[dataKey] === id;
    });
    if (marker) {
      window.google.maps.event.trigger(marker.marker, "mouseover");
    }
  };
  const onHoverRowOut = (id: number) => () => {
    if (componentHoverInActive || markersRef.length === 0) return;
    const marker = markersRef.find((marker) => {
      const data = marker.data as T & { [key: string]: number };
      return data[dataKey] === id;
    });
    if (marker) {
      window.google.maps.event.trigger(marker.marker, "mouseout");
    }
  };

  const onMarkerIn = (
    { data }: LocationMark<T>,
    marker: google.maps.Marker
  ) => {
    const label = marker.getLabel() || { text: "" };
    marker.setLabel({
      ...label,
      color: "#424242"
    });
    if (data) {
      setHoverMarkerId((data as T & { [key: string]: number })[dataKey]);
    }
  };

  const onMarkerOut = (_: LocationMark<T>, marker: google.maps.Marker) => {
    const label = marker.getLabel() || { text: "" };
    marker.setLabel({
      ...label,
      color: "white"
    });
    setHoverMarkerId(undefined);
  };

  return [
    hoverMarkerId,
    onHoverRowIn,
    onHoverRowOut,
    onMarkerIn,
    onMarkerOut,
    setHoverMarkerId
  ];
}
