import { updateArrows } from "./MapHelpers";

interface HistoryEvent extends google.maps.Data.SetGeometryEvent {}

/**
 * Class that keeps track of the history of
 * geometric objects on the map,
 * making it possible to undo and redo
 */
export default class FeatureHistory {
  items: HistoryEvent[];
  futureItems: HistoryEvent[];
  useSetGeometry: boolean;
  currentGroup: React.MutableRefObject<string>;
  mapInstance: google.maps.Map;

  constructor(
    currentGroup: React.MutableRefObject<string>,
    mapInstance: google.maps.Map
  ) {
    this.items = [];
    this.futureItems = [];
    this.useSetGeometry = true;
    this.currentGroup = currentGroup;
    this.mapInstance = mapInstance;
  }

  addPost(event: HistoryEvent) {
    if (this.useSetGeometry) {
      this.futureItems = [];
      this.items.push(event);
    }
  }

  /**
   * Going backwards in geometry history (undoing)
   */
  moveBackward() {
    if (this.items.length > 0) {
      const lastEvent = this.items.pop();

      if (lastEvent) {
        this.currentGroup = lastEvent.feature.getProperty("group");

        let featureHistoryInstance = this;

        this.mapInstance.data.forEach(function (feature) {
          if (
            feature === lastEvent.feature &&
            feature.getProperty("editable")
          ) {
            featureHistoryInstance.useSetGeometry = false;
            feature.setGeometry(lastEvent.oldGeometry);
            featureHistoryInstance.useSetGeometry = true;
          }
        });

        if (!this.mapInstance.data.contains(lastEvent.feature)) {
          this.mapInstance.data.add(lastEvent.feature);
          // this.currentGroup = lastEvent.feature.getProperty("group");
        }

        this.futureItems.push(lastEvent);
      }
      updateArrows(this.mapInstance);
    }
  }
  /**
   * Going forward in geometry history (redoing)
   */
  moveForward() {
    if (this.futureItems.length > 0) {
      const lastEvent = this.futureItems.pop();

      if (lastEvent) {
        this.currentGroup = lastEvent.feature.getProperty("group");

        let featureHistoryInstance = this;

        this.mapInstance.data.forEach(function (feature) {
          if (
            feature === lastEvent.feature &&
            feature.getProperty("editable")
          ) {
            featureHistoryInstance.useSetGeometry = false;
            feature.setGeometry(lastEvent.newGeometry);
            featureHistoryInstance.useSetGeometry = true;
          } else if (
            feature === lastEvent.feature &&
            feature.getGeometry() === lastEvent.newGeometry
          ) {
            featureHistoryInstance.mapInstance.data.remove(lastEvent.feature);
          }
        });
        this.items.push(lastEvent);
      }
      updateArrows(this.mapInstance);
    }
  }

  clearForFeature(feature: google.maps.Data.Feature) {
    let featureHistoryInstance = this;
    this.items.forEach(function (item) {
      if (item.feature === feature) {
        featureHistoryInstance.items.splice(
          featureHistoryInstance.items.indexOf(item)
        );
      }
    });

    this.futureItems.forEach(function (item) {
      if (item.feature === feature) {
        featureHistoryInstance.futureItems.splice(
          featureHistoryInstance.futureItems.indexOf(item)
        );
      }
    });
  }
}
