import React from "react";
import {
  updateCustomerInvoiceStatusAPI,
  customerBulkInvoiceStatusUpdate,
  customerBulkSendEmailAPI
} from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import BaseInvoicesComponent from "../BaseInvoicesComponent";

interface CustomerInvoicesProps {}

const CustomerInvoices: React.FC<CustomerInvoicesProps> = () => {
  const t = useTranslate("InvoicesView");

  return (
    <BaseInvoicesComponent
      bulkInvoiceStatusUpdate={customerBulkInvoiceStatusUpdate}
      bulkSendEmailUpdate={customerBulkSendEmailAPI}
      updateInvoiceStatus={updateCustomerInvoiceStatusAPI}
      disableInvoiceWorkApprovalEditMode="contractor_payables"
      invoiceEntityLabel={t("customerTableHeader")}
      companyType="CUSTOMER"
    />
  );
};

export default CustomerInvoices;
