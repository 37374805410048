import { CooperationsState, Action, Cooperation } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: CooperationsState = {
  cooperations: {
    cooperations: [],
    next: null,
    previous: null,
    count: 0
  },
  cooperation: {
    cooperation: {
      id: -1,
      title: "",
      accepted_by_user: null,
      accepted_ts: null,
      contract: -1,
      person: null,
      priority: 0,
      created_at: "",
      updated_at: "",
      task: -1
    },
    task: null,
    contract: null,
    person: null,
    user: null
  },
  customerobjectCooperations: [],
  contractorCooperations: []
};

function reducer(state = initialState, action: Action<any>): CooperationsState {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.COOPERATIONS
    ):
      return {
        ...state,
        cooperations: {
          cooperations: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.COOPERATION
    ):
      return {
        ...state,
        cooperation: {
          ...state.cooperation,
          cooperation: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.COOPERATION
    ):
      return {
        ...state,
        cooperations: {
          ...state.cooperations,
          cooperations: [...state.cooperations.cooperations, action.payload]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.COOPERATION
    ):
      return {
        ...state,
        cooperations: {
          ...state.cooperations,
          cooperations: [
            ...state.cooperations.cooperations.map((cooperation) =>
              cooperation.id === action.payload.id
                ? action.payload
                : cooperation
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CUSTOMEROBJECT_COOPERATIONS
    ):
      return {
        ...state,
        customerobjectCooperations: action.payload.results
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.CUSTOMEROBJECT_COOPERATIONS
    ):
      return {
        ...state,
        customerobjectCooperations: [
          ...state.customerobjectCooperations,
          action.payload
        ]
      };
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.CUSTOMEROBJECT_COOPERATIONS
    ):
      return {
        ...state,
        customerobjectCooperations: state.customerobjectCooperations.filter(
          (cooperation: Cooperation) =>
            cooperation.id !== parseInt(action.payload.id)
        )
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTOR_COOPERATIONS
    ):
      return {
        ...state,
        contractorCooperations: action.payload.results
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.CONTRACTOR_COOPERATIONS
    ):
      return {
        ...state,
        contractorCooperations: [
          ...state.contractorCooperations,
          action.payload
        ]
      };
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.CONTRACTOR_COOPERATIONS
    ):
      return {
        ...state,
        contractorCooperations: state.contractorCooperations.filter(
          (cooperation: Cooperation) =>
            cooperation.id !== parseInt(action.payload.id)
        )
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.COOPERATION_TASK
    ):
      return {
        ...state,
        cooperation: {
          ...state.cooperation,
          task: action.payload
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.COOPERATION_CONTRACT
    ):
      return {
        ...state,
        cooperation: {
          ...state.cooperation,
          contract: action.payload
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.COOPERATION_PERSON
    ):
      return {
        ...state,
        cooperation: {
          ...state.cooperation,
          person: action.payload
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.COOPERATION_USER
    ):
      return {
        ...state,
        cooperation: {
          ...state.cooperation,
          user: action.payload
        }
      };

    default:
      return state;
  }
}

export default reducer;
