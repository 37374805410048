import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  WithStyles
} from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React, { Fragment } from "react";
import { useTranslate } from "../../../services/appLanguageService";
import {
  AcceptedData,
  DeclinedData,
  RouteInstance,
  Service,
  ServiceCategory,
  Task
} from "../../../redux/types";
import clsx from "clsx";
import ResourceLink from "../../links/ResourceLink";
import { Link, LinkProps } from "react-router-dom";

const styles = (theme: any) =>
  createStyles({
    expandedContainer: { paddingLeft: theme.spacing(0) },
    expandableDiv: {
      padding: theme.spacing(1),
      backgroundColor: "#FAFAFA",
      border: "1px solid #a9a9a9",
      marginTop: theme.spacing(1.5)
    },
    flex: {
      display: "flex",
      justifyContent: "center"
    },
    root: {
      "& > *": {
        position: "relative",
        borderBottom: "unset"
      }
    },
    tableRow: {
      "& > *": {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
      }
    },
    alternatingRow: {
      backgroundColor: "#e7e7e7"
    },
    table: { minWidth: 750 },
    smallText: {
      fontSize: theme.typography.pxToRem(13)
    },
    nowrap: { whiteSpace: "nowrap" },
    pointer: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    mergedRow: {
      "& > *": {
        verticalAlign: "top",
        paddingTop: "0px",
        paddingBottom: "0px"
      },
      "&:first-child > *": {
        paddingTop: theme.spacing(2.5)
      },
      "&.group-last-row > *": {
        paddingBottom: theme.spacing(1)
      },
      "&.group-first-row > *": {
        paddingTop: theme.spacing(1)
      }
    },
    thRow: {
      "& > *": {
        color: "#000",
        fontWeight: "bold",
        padding: `0 ${theme.spacing(1.5)}px`
      }
    },
    declined: {
      color: "#fc3503"
    }
  });

interface RouteInstanceDetailsProps extends WithStyles<typeof styles> {
  routeinstance: RouteInstance;
  tasks: Task[];
  companies: { id: number; name: string }[];
  servicecategories: ServiceCategory[];
  services: Service[];
  declinedData: DeclinedData;
  acceptedByData: AcceptedData;
}

const RouteInstanceDetails: React.FC<RouteInstanceDetailsProps> = (props) => {
  const {
    classes,
    routeinstance,
    tasks,
    companies,
    servicecategories,
    services,
    declinedData,
    acceptedByData
  } = props;

  const t = useTranslate("RouteInstancesPage");

  const participantIndexes = Object.fromEntries(
    routeinstance.participants.map((participant, index) => [
      participant.id,
      index + 1
    ])
  );

  return (
    <div className={classes.expandedContainer}>
      <div className={classes.expandableDiv}>
        <Grid container spacing={1}>
          {routeinstance.participants.map((routeParticipant) => {
            const contractorName =
              companies.find(
                (company) => company.id === routeParticipant.contractor
              )?.name || "";
            const serviceName = [
              services.find((s) => s.id === routeParticipant.service)?.name ??
                "n/a"
            ]
              .concat(
                Object.entries(routeParticipant.accompanying_people)
                  .filter((e): e is [string, { count: number }] => !!e[1])
                  .map(
                    ([serviceId, { count }]) =>
                      `${count} ${
                        services.find((s) => s.id === parseInt(serviceId))
                          ?.name ?? "n/a"
                      }`
                  )
              )
              .join(", ");
            return (
              <Fragment key={routeParticipant.id}>
                <Grid item xs={3}>
                  <div>
                    <b>#{participantIndexes[routeParticipant.id]}</b>
                    <b
                      style={{
                        marginLeft: "5px"
                      }}
                    >
                      {contractorName}
                    </b>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  {serviceName}
                </Grid>
                <Grid item xs={3}>
                  <div>
                    {declinedData[routeParticipant.id] && (
                      <div className={classes.declined}>
                        {`${t("declinedByLabel")}: `}
                        {declinedData[routeParticipant.id].map(
                          (declingPerson, index, persons) => (
                            <React.Fragment key={index}>
                              <ResourceLink
                                url={"auth/persons"}
                                id={declingPerson.id}
                                label={`${declingPerson.first_name} ${declingPerson.last_name}`}
                                colorClass={classes.declined}
                              />
                              {index !== persons.length - 1 && ", "}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div>{routeParticipant.specific_person}</div>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={React.forwardRef<
                      HTMLAnchorElement,
                      Partial<LinkProps>
                    >((props, ref) => (
                      <Link
                        to={`/routeinstances/modify-ongoing/${routeParticipant.id}`}
                        {...props}
                        ref={ref as any}
                      />
                    ))}
                  >
                    Open
                  </Button>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </div>
      <div className={classes.expandableDiv}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.thRow}>
              <TableCell>{t("assignmentHeading")}</TableCell>
              <TableCell>{t("contractorHeading")}</TableCell>
              <TableCell>{t("serviceHeading")}</TableCell>
              <TableCell>{t("instructionHeading")}</TableCell>
              <TableCell>{t("acceptedByHeading")}</TableCell>
              <TableCell>{t("serviceCategoriesHeading")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {routeinstance.rows.map((routesegment) =>
              routesegment.participants.map(
                (participantId, index, participants) => {
                  const participant = routeinstance.participants.find(
                    (_participant) =>
                      _participant.participant_id === participantId
                  );

                  const contractorName =
                    companies.find(
                      (company) => company.id === participant?.contractor
                    )?.name || "";

                  const serviceName =
                    services.find(
                      (service) => service.id === participant?.service
                    )?.name || "";

                  const acceptingPerson =
                    participant && acceptedByData[participant.id]
                      ? acceptedByData[participant.id]
                      : undefined;

                  return (
                    <TableRow
                      key={`${participant?.id}_${routesegment.sort_order}`}
                      className={clsx(
                        routesegment.sort_order % 2 === 1
                          ? classes.alternatingRow
                          : "",
                        classes.tableRow,
                        classes.mergedRow,
                        classes.root,
                        index === 0 && "group-first-row",
                        index === participants.length - 1 && "group-last-row"
                      )}
                    >
                      <TableCell className={classes.smallText}>
                        {index === 0 &&
                          tasks.find((task) => task.id === routesegment.task)
                            ?.name}
                      </TableCell>
                      <TableCell className={classes.smallText}>
                        #{participant ? participantIndexes[participant.id] : ""}{" "}
                        {contractorName}
                      </TableCell>
                      <TableCell className={classes.smallText}>
                        {serviceName}
                      </TableCell>
                      <TableCell className={classes.smallText}>
                        {index === 0 && routesegment.instructions}
                      </TableCell>
                      <TableCell className={classes.smallText}>
                        {acceptingPerson && (
                          <ResourceLink
                            url={"auth/persons"}
                            id={acceptingPerson.id}
                            label={`${acceptingPerson.first_name} ${acceptingPerson.last_name}`}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.smallText}>
                        {servicecategories
                          .filter((_servicecategory) =>
                            routesegment.servicecategories.includes(
                              _servicecategory.id
                            )
                          )
                          .map(
                            (servicecategory, index, _servicecategories) =>
                              `${servicecategory.name}${
                                index + 1 !== _servicecategories.length
                                  ? ", "
                                  : ""
                              }`
                          )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default withStyles(styles)(RouteInstanceDetails);
