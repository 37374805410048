import { FeatureTypes } from "../../../../redux/types";

const featureTypes: FeatureTypes = {
  area: {
    style: {
      fillColor: "#0080FF",
      strokeColor: "#0080FF",
      fillOpacity: 0.3,
      strokeWeight: 2,
      zIndex: 3,
      editable: false
    },
    drawingMode: "Polygon"
  },
  dumpsite: {
    style: {
      fillColor: "#ED207B",
      strokeColor: "#ED207B",
      fillOpacity: 0.3,
      strokeWeight: 2,
      zIndex: 5,
      editable: false
    },
    drawingMode: "Polygon"
  },
  priority: {
    style: {
      fillColor: "#F15929",
      strokeColor: "#F15929",
      fillOpacity: 0.3,
      strokeWeight: 2,
      zIndex: 5,
      editable: false
    },
    drawingMode: "Polygon"
  },
  manual: {
    style: {
      fillColor: "#39B54A",
      strokeColor: "#39B54A",
      fillOpacity: 0.3,
      strokeWeight: 2,
      zIndex: 5,
      editable: false
    },
    drawingMode: "Polygon"
  },
  building: {
    style: {
      fillColor: "#111111",
      strokeColor: "#111111",
      fillOpacity: 0.3,
      strokeWeight: 2,
      zIndex: 1,
      editable: false
    },
    drawingMode: "Polygon"
  },
  entrance: {
    style: {
      strokeColor: "#4caf50",
      strokeWeight: 2,
      zIndex: 7,
      editable: false
    },
    drawingMode: "LineString"
  },
  exit: {
    style: {
      strokeColor: "#ff5722",
      strokeWeight: 2,
      zIndex: 7,
      editable: false
    },
    drawingMode: "LineString"
  },
  direction: {
    style: {
      strokeColor: "#ffea00",
      strokeWeight: 2,
      zIndex: 7,
      editable: false
    },
    drawingMode: "LineString"
  },
  directionArrow: {
    style: {
      strokeColor: "#ffea00",
      strokeWeight: 2,
      zIndex: 7,
      editable: false
    },
    drawingMode: "LineString"
  },
  directionArrowExit: {
    style: {
      strokeColor: "#ff5722",
      strokeWeight: 2,
      zIndex: 7,
      editable: false
    },
    drawingMode: "LineString"
  },
  directionArrowEntrance: {
    style: {
      strokeColor: "#4caf50",
      strokeWeight: 2,
      zIndex: 7,
      editable: false
    },
    drawingMode: "LineString"
  }
};

export default featureTypes;
