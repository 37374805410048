import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  InvoicingProfileForm,
  InvoicingProfile,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import InvoicingProfileNewEditForm from "../../../components/invoices/invoicingProfiles/InvoicingProfileNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import {
  RestStrings,
  ReduxActionStrings,
  EnumStrings
} from "../../../redux/strings";
import { niceDate } from "../../../components/FormatHelpers";
import {
  patchInvoiceProfile,
  getCustomerInvoiceProfileTestPdf,
  getContractorInvoiceProfileTestPdf
} from "../../../services/invoicesService";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { saveAs } from "file-saver";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditInvoicingProfileContainerProps
  extends FormikSubmitDispatchProps<InvoicingProfileForm>,
    TabComponentProps<"invoicingprofileId"> {
  invoicingProfile: InvoicingProfile;
  companyOptions: SelectFieldOption[];
  customerInvoiceOptions: SelectFieldOption[];
  contractorInvoiceOptions: SelectFieldOption[];
  loadInvoicingProfile: (invoicingprofileId: number) => void;
  loadCompanies: (searchTerm?: string) => void;
  loadCustomerInvoices: () => void;
  loadContractorInvoices: () => void;
  handleSaveAndCreatePDF: (
    invoicingProfileId: number,
    invoicingId: number,
    jinjaTemplate: string,
    invoicing_css: string,
    type: string
  ) => void;
  loadingInvoicingProfile: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<InvoicingProfile | InvoicingProfileForm> {
  loadInvoicingProfile: (invoicingprofileId: number) => void;
  loadCompanies: (searchTerm?: string) => void;
  loadCustomerInvoices: () => void;
  loadContractorInvoices: () => void;
  handleSaveAndCreatePDF: (
    invoicingProfileId: number,
    invoicingId: number,
    jinjaTemplate: string,
    invoicing_css: string,
    type: string
  ) => void;
}

const EditInvoicingProfileContainer: React.FC<
  EditInvoicingProfileContainerProps
> = (props) => {
  const {
    loadInvoicingProfile,
    invoicingProfile,
    routeParams: { invoicingprofileId },
    loadCompanies,
    loadCustomerInvoices,
    loadContractorInvoices,
    handleSaveAndCreatePDF,
    loadingInvoicingProfile
  } = props;
  useEffect(
    () => loadInvoicingProfile(invoicingprofileId),
    [loadInvoicingProfile, invoicingprofileId]
  );
  useEffect(() => loadCompanies(), [loadCompanies]);
  useEffect(() => loadCustomerInvoices(), [loadCustomerInvoices]);
  useEffect(() => loadContractorInvoices(), [loadContractorInvoices]);

  return loadingInvoicingProfile ? (
    <LoadingSpinnerPaper />
  ) : (
    <InvoicingProfileNewEditForm
      handleSaveAndCreatePDF={handleSaveAndCreatePDF}
      invoicingProfile={invoicingProfile}
      companyOptions={props.companyOptions}
      customerInvoiceOptions={props.customerInvoiceOptions}
      contractorInvoiceOptions={props.contractorInvoiceOptions}
      handleSubmit={props.handleSubmit}
      loadCompanies={props.loadCompanies}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        "INVOICINGPROFILE",
        `invoices/invoicingprofiles/${
          (checkedNullValues as InvoicingProfile).id
        }/`,
        checkedNullValues,
        `/invoices/invoicingprofiles/${
          (checkedNullValues as InvoicingProfile).id
        }?same_tab=true`
      )
    );
  },
  handleSaveAndCreatePDF: async (
    invoicingProfileId: number,
    invoicingId: number,
    jinjaTemplate: string,
    invoicing_css: string,
    type: string
  ) => {
    try {
      let values = {};
      if (type === EnumStrings.CUSTOMER) {
        values = {
          customer_invoice_jinja: jinjaTemplate,
          invoicing_css: invoicing_css
        };
      } else if (type === EnumStrings.CONTRACTOR) {
        values = {
          contractor_invoice_jinja: jinjaTemplate,
          invoicing_css: invoicing_css
        };
      }

      const response: InvoicingProfile = await patchInvoiceProfile(
        invoicingProfileId,
        values
      );
      if (response) {
        let pdfBlob: string = "";
        if (type === EnumStrings.CUSTOMER) {
          pdfBlob = await getCustomerInvoiceProfileTestPdf(
            invoicingProfileId,
            invoicingId
          );
        } else if (type === EnumStrings.CONTRACTOR) {
          pdfBlob = await getContractorInvoiceProfileTestPdf(
            invoicingProfileId,
            invoicingId
          );
        }
        if (pdfBlob) {
          saveAs(pdfBlob, `PREVIEW_INVOICE_ID_${invoicingId}.pdf`);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  },
  loadInvoicingProfile: (invoicingProfileId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.INVOICINGPROFILE,
        `invoices/invoicingprofiles/${invoicingProfileId}/`
      )
    );
  },
  loadCompanies: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerInvoices: () => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CUSTOMER_INVOICES,
        "invoices/customerinvoices/?page=1&page_size=10"
      )
    );
  },
  loadContractorInvoices: () => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CONTRACTOR_INVOICES,
        "invoices/contractorinvoices/?page=1&page_size=10"
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  invoicingProfile:
    state.tabStates[ownProps.tabId].invoicingProfiles.invoicingProfile
      .invoicingProfile,
  companyOptions: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  })),
  customerInvoiceOptions: state.tabStates[ownProps.tabId].invoicingProfiles
    .invoicingProfile.customerInvoices
    ? state.tabStates[
        ownProps.tabId
      ].invoicingProfiles.invoicingProfile.customerInvoices.map(
        (customerInvoice) => ({
          label: `${customerInvoice.company_name} | ${niceDate(
            customerInvoice.cover_start,
            false
          )} - ${niceDate(customerInvoice.cover_end, false)}`,
          value: customerInvoice.id
        })
      )
    : [],
  contractorInvoiceOptions: state.tabStates[ownProps.tabId].invoicingProfiles
    .invoicingProfile.contractorInvoices
    ? state.tabStates[
        ownProps.tabId
      ].invoicingProfiles.invoicingProfile.contractorInvoices.map(
        (contractorInvoice) => ({
          label: `${contractorInvoice.company_name} | ${niceDate(
            contractorInvoice.cover_start,
            false
          )} - ${niceDate(contractorInvoice.cover_end, false)}`,
          value: contractorInvoice.id
        })
      )
    : [],
  loadingInvoicingProfile:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingInvoicingProfile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInvoicingProfileContainer);
