import {
  Dialog,
  MenuItem,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { IPackageType, IPackageOption } from "../../redux/types";
import { Select } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import Button from "@material-ui/core/Button";
import { useTranslate } from "../../services/appLanguageService";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import PackageCarry from "../../helpers/classes/verification/PackageCarry";

const useStyles = makeStyles((theme: any) => ({
  mr40: { marginRight: theme.spacing(5) },
  dialogActionsStyles: { margin: "8px 0 4px 0" }
}));

export interface AddServicePackageEventDialogProps {
  isOpen: boolean;
  packageType: IPackageType;
  packageCarry: PackageCarry;
  onPackageCreated: (packageCarry: PackageCarry) => void;
  closeDialog: () => void;
}

export default function AddServicePackageEventDialog(
  props: AddServicePackageEventDialogProps
) {
  const classes = useStyles();
  const { isOpen, packageType, packageCarry, onPackageCreated, closeDialog } =
    props;
  const t = useTranslate("AddServicePackageEventDialogPage");

  const availableOptions = packageCarry.packageOptions.available[packageType];

  const handleServicePackageSubmit = async ({
    _package
  }: {
    _package: number;
  }) => {
    try {
      const packageToCreate = availableOptions.find(
        (item) => item.event_id === _package
      );
      const isCreated = await packageCarry.createPackage(
        packageType,
        packageToCreate as IPackageOption
      );
      if (isCreated) {
        showGlobalSnackbar(t("addedMessage"), "success");
        onPackageCreated(packageCarry);
        closeDialog();
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle className={classes.mr40}>{t("dialogTitle")}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            _package: packageCarry.current[packageType]?.event_id || -1
          }}
          onSubmit={handleServicePackageSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form>
              <Field name="_package" component={Select} fullWidth>
                {availableOptions.map((opt) => (
                  <MenuItem key={opt.event_id} value={opt.event_id}>
                    {opt.title}
                  </MenuItem>
                ))}
              </Field>
              <DialogActions className={classes.dialogActionsStyles}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={formikProps.values._package === -1}
                >
                  {t("createButtonLabel")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={closeDialog}
                >
                  {t("cancelButtonLabel")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
