import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Chip,
  Badge,
  IconButton,
  Button
} from "@material-ui/core";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AppsIcon from "@material-ui/icons/Apps";
import ViewListIcon from "@material-ui/icons/ViewList";
import { grey, deepPurple, blue } from "@material-ui/core/colors";
import UnapprovedWorkAdd from "./UnapprovedWorkAdd";
import {
  getNonApprovableTimesheetRows,
  approveUnplannedTimesheets
} from "../../services/unapprovedWorkService";
import {
  TimesheetRowsGroup,
  TimesheetsDict,
  TimesheetDict,
  TimesheetRowsSelection,
  TimesheetQuery,
  TableFilterConfig
} from "../../redux/types";
import TableFilter from "../tableFilter/TableFilter";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import { getTimeFromDate } from "../FormatHelpers";
import { useTranslate } from "../../services/appLanguageService";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paperPadding: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
    },
    paper: {
      marginTop: theme.spacing(2)
    },
    gridContainer: {
      marginBottom: theme.spacing(2)
    },
    groupPaper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    groupSection: {
      marginTop: theme.spacing(2)
    },
    tableWrapper: {
      padding: `${theme.spacing(0)} ${theme.spacing(1)}px ${theme.spacing(
        1
      )}px ${theme.spacing(1)}px`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    timesheetDetailsContainer: {
      display: "flex",
      alignItems: "center"
    },
    timesheetDetails: {
      fontWeight: theme.typography.fontWeightBold
    },
    selectTimesheet: {
      flex: "1",
      textAlign: "right"
    },
    lastColumnHead: {
      padding: `${theme.spacing(0)} ${theme.spacing(1)}px`
    },
    lastColumnBody: {
      padding: theme.spacing(0)
    },
    fullHeight: {
      height: "100%"
    },
    transition: {
      transition: "0.1s all ease"
    },
    font16: {
      fontSize: "16px"
    },
    groupsDetails: {
      flex: 1,
      paddingLeft: theme.spacing(1),
      borderLeft: `3px solid ${blue[800]}`,
      marginLeft: theme.spacing(1.5)
    },
    groupLayout: {
      display: "flex",
      justifyContent: "space-between"
    },
    iconBtn: {
      padding: theme.spacing(0.5)
    },
    nonActiveView: {
      color: "#a6a6a6"
    },
    activeView: {
      color: "#4056b5"
    },
    gridView: {
      display: "inline-block"
    },
    listView: {
      display: "block"
    },
    flexBox: {
      display: "flex",
      alignItems: "center"
    },
    expandIcon: {
      cursor: "pointer"
    },
    lastColumn: {
      textAlign: "right"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(2)
    },
    alignedRight: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(1.5)
    },
    dataFormat: {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(0.5)
    },
    formatItem: { margin: theme.spacing(0), color: "#798081" },
    tsDetails: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(0.5)
    },
    tsRowDetails: {
      display: "flex",
      marginLeft: theme.spacing(1.5),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
      borderLeft: `3px solid ${deepPurple[400]}`
    },
    tsRowTitle: {
      flex: "1 0 auto",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    rowDetailsContainer: {
      display: "flex",
      marginLeft: theme.spacing(1)
    },
    tsRowTime: {
      flexShrink: 0
    },
    tsHeading: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      fontWeight: theme.typography.fontWeightBold
    },
    divider: {
      width: 2,
      flexShrink: 0,
      height: theme.spacing(3),
      backgroundColor: grey[600],
      margin: `${theme.spacing(0)} ${theme.spacing(1.5)}px`
    }
  })
);

interface UnapprovedWorkProps {}
const UnapprovedWorkComponent: React.FC<UnapprovedWorkProps> = (props) => {
  const classes = useStyles();
  const t = useTranslate("RouteLabels");
  const g = useTranslate("UnplannedWorkGroups");
  const ts = useTranslate("TimesheetApprovalPage");
  const v = useTranslate("TimeSheetRowsAndApprovals");
  const [timesheetsDict, setTimesheetsDict] = useState<TimesheetsDict>({});
  const [selections, setSelections] = useState<TimesheetRowsSelection>({});
  const DEFAULT_QUERY: TimesheetQuery = {};
  const FILTERS: TableFilterConfig<TimesheetQuery, any>[][] = [
    [
      {
        title: v("startDateFieldLabel"),
        key: "filter__time__gt",
        type: "DATE"
      },
      {
        title: v("endDateFieldLabel"),
        key: "filter__time__lt",
        type: "DATE"
      }
    ]
  ];
  const [filterQuery, setFilterQuery] = useState<TimesheetQuery>({
    ...DEFAULT_QUERY
  });
  const [groups, setGroups] = useState<TimesheetRowsGroup[]>([]);
  const [panelStatus, setPanelStatus] = useState<boolean>(false);
  const [localGroupId, setlocalGroupId] = useState<{
    [groupId: number]: boolean;
  }>({});
  const [groupsView, setGroupsView] = useState<string>("list");
  const [timesheetProject, setTimesheetProject] = useState<number | null>(null);

  const getNonApprovableRows = () => {
    getNonApprovableTimesheetRows(filterQuery).then(
      (result) => {
        const timesheetsDict: TimesheetsDict = {};
        result.forEach((timesheet) => {
          const timesheetDict: TimesheetDict = {
            timesheet: { ...timesheet },
            rows: {}
          };
          timesheetDict.timesheet.rows.length = 0;
          timesheet.rows.forEach((r) => {
            if (r.row_id !== undefined) {
              timesheetDict.rows[r.row_id] = r;
            }
          });
          timesheetsDict[timesheet.id] = timesheetDict;
          setTimesheetProject(1);
        });
        setTimesheetsDict(timesheetsDict);
      },
      (error) => console.warn(`Unable to get unplanned work: ${error}`)
    );
  };

  const createGroup = () => {
    const idx = groups[groups.length - 1]
      ? groups[groups.length - 1].group_id + 1
      : 1;
    setGroups((data) =>
      data.concat({
        group_id: idx,
        timesheets: {}
      })
    );
    moveIntoGroup(idx)();
  };

  const moveIntoGroup = (group_id: number) => () => {
    setTimesheetsDict((timesheetsDict) => {
      const timesheetsClone: TimesheetsDict = { ...timesheetsDict };

      setGroups((data) =>
        data.map((group) => {
          const groupClone: TimesheetRowsGroup = {
            group_id: group.group_id,
            timesheets: { ...group.timesheets }
          };
          if (groupClone.group_id === group_id) {
            Object.entries(selections).forEach(([timesheetId, rowsDict]) => {
              if (groupClone.timesheets[timesheetId]) {
                groupClone.timesheets[timesheetId] = {
                  timesheet: { ...timesheetsClone[timesheetId].timesheet },
                  rows: { ...group.timesheets[timesheetId].rows, ...rowsDict }
                };
              } else {
                groupClone.timesheets[timesheetId] = {
                  timesheet: { ...timesheetsClone[timesheetId].timesheet },
                  rows: { ...rowsDict }
                };
              }
              Object.keys(rowsDict).forEach((rowId) => {
                delete timesheetsClone[timesheetId].rows[rowId];
              });
              if (Object.keys(timesheetsClone[timesheetId].rows).length === 0) {
                delete timesheetsClone[timesheetId];
              }
            });
          }
          return groupClone;
        })
      );
      return timesheetsClone;
    });
    setSelections({});
    setPanelStatus(false);
  };

  const deleteGroup = (groupId: number) => () => {
    setGroups((groups) =>
      groups.filter((group) => {
        if (group.group_id === groupId) {
          setTimesheetsDict((timesheetsDict) => {
            let timesheetsClone: TimesheetsDict = { ...timesheetsDict };
            Object.entries(group.timesheets).forEach(
              ([timesheetId, groupTimesheetDict]) => {
                if (timesheetsClone[timesheetId]) {
                  timesheetsClone[timesheetId] = {
                    timesheet: { ...timesheetsClone[timesheetId].timesheet },
                    rows: {
                      ...timesheetsClone[timesheetId].rows,
                      ...groupTimesheetDict.rows
                    }
                  };
                } else {
                  timesheetsClone[timesheetId] = {
                    timesheet: { ...groupTimesheetDict.timesheet },
                    rows: { ...groupTimesheetDict.rows }
                  };
                }
              }
            );
            return timesheetsClone;
          });
          return false;
        }
        return true;
      })
    );
  };

  const checkTimesheet =
    (timesheetId: string, timesheetDict: TimesheetDict) => () => {
      setSelections((data) => {
        const clone: TimesheetRowsSelection = { ...data };
        if (clone[timesheetId]) {
          delete clone[timesheetId];
        } else {
          clone[timesheetId] = { ...timesheetDict.rows };
        }
        expandRightPanel(clone);
        return clone;
      });
    };
  const checkTimesheetRow =
    (timesheetId: string, rowId: string, timesheetDict: TimesheetDict) =>
    () => {
      setSelections((data) => {
        const clone: TimesheetRowsSelection = { ...data };
        clone[timesheetId] = clone[timesheetId] || {};

        if (clone[timesheetId][rowId]) {
          delete clone[timesheetId][rowId];
          if (Object.keys(clone[timesheetId]).length === 0) {
            delete clone[timesheetId];
          }
        } else {
          clone[timesheetId][rowId] = { ...timesheetDict.rows[rowId] };
        }
        expandRightPanel(clone);
        return clone;
      });
    };

  const expandRightPanel = (cloneObj: TimesheetRowsSelection) => {
    if (Object.values(cloneObj).length) {
      setPanelStatus(true);
    } else {
      setPanelStatus(false);
    }
  };

  const approveUnplanned = async () => {
    try {
      const retrospectRouteInstace = [
        ...groups
          .map((el) =>
            Object.entries(el.timesheets).map(([ts_id, timesheet]) =>
              Object.entries(timesheet.rows).map(([row_id, row]) => ({
                timesheet_id: +ts_id,
                row_id: +row_id
              }))
            )
          )
          .flat(2)
      ];
      const res = await approveUnplannedTimesheets({
        timesheet_rows: retrospectRouteInstace,
        project: timesheetProject
      });
      console.log("Success approving: ", res);
      setGroups([]);
      getNonApprovableRows();
      showGlobalSnackbar(g("approvedSuccessfully"), "success");
    } catch (e) {
      showGlobalSnackbar("Approved Successfully", "success");
    }
  };

  const badgeCount = (group: TimesheetRowsGroup) => {
    return (
      <>
        {Object.values(group.timesheets).reduce((acc: number, data: any) => {
          return acc + Object.keys(data.rows).length;
        }, 0)}
      </>
    );
  };

  const toggleGroupData = (groupId: number) => () => {
    const group = groups.find((g) => g.group_id === groupId);
    setlocalGroupId({
      [groupId]: Boolean(group && group.group_id === groupId)
    });
  };

  const toggleGroupView = (viewType: string) => () => {
    setGroupsView(viewType);
  };

  useEffect(getNonApprovableRows, [filterQuery]);

  return (
    <div>
      <TableFilter<TimesheetQuery>
        filters={FILTERS}
        onChange={setFilterQuery}
        defaultValues={{ ...DEFAULT_QUERY }}
      />
      <Paper className={`${classes.header} ${classes.paperPadding}`}>
        <Typography component="h1" variant="h6">
          {t("UnplannedWorkNavbarLabel")}
        </Typography>
        <div className={classes.dataFormat}>
          <p className={classes.formatItem}>{g("timesheetHeading")}</p>
          <div className={classes.divider}></div>
          <p className={classes.formatItem}>{g("personHeading")}</p>
          <div className={classes.divider}></div>
          <p className={classes.formatItem}>{g("contractorHeading")}</p>
        </div>
      </Paper>
      {Boolean(groups.length) && (
        <Paper className={`${classes.groupSection} ${classes.paperPadding}`}>
          <div className={classes.groupLayout}>
            <div>
              <Typography
                component="h1"
                variant="h6"
                className={classes.font16}
              >
                {g("groupsHeading")}
              </Typography>
            </div>
            <div>
              <IconButton
                className={classes.iconBtn}
                onClick={toggleGroupView("grid")}
              >
                <AppsIcon
                  className={
                    groupsView === "grid"
                      ? classes.activeView
                      : classes.nonActiveView
                  }
                ></AppsIcon>
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                onClick={toggleGroupView("list")}
              >
                <ViewListIcon
                  className={
                    groupsView === "list"
                      ? classes.activeView
                      : classes.nonActiveView
                  }
                ></ViewListIcon>
              </IconButton>
            </div>
          </div>
          {groups.map((group, index) => (
            <div
              key={index}
              className={`${
                localGroupId[group.group_id]
                  ? classes.listView
                  : classes.gridView
              }
                      ${
                        groupsView === "grid"
                          ? classes.gridView
                          : classes.listView
                      }`}
            >
              <span className={classes.flexBox}>
                <Badge badgeContent={badgeCount(group)} color="primary">
                  <Chip
                    icon={<VerticalSplitIcon />}
                    label={`Group ${group.group_id}`}
                    onDelete={deleteGroup(group.group_id)}
                    deleteIcon={<HighlightOffIcon />}
                  />
                </Badge>
                {!!Object.keys(group.timesheets).length && (
                  <IconButton
                    onClick={
                      localGroupId[group.group_id]
                        ? toggleGroupData(0)
                        : toggleGroupData(group.group_id)
                    }
                  >
                    {(!localGroupId[group.group_id] && (
                      <ExpandMoreIcon className={classes.expandIcon} />
                    )) || <ExpandLessIcon className={classes.expandIcon} />}
                  </IconButton>
                )}
              </span>
              {localGroupId[group.group_id] && (
                <div className={classes.groupsDetails}>
                  {Object.values(group.timesheets).map((data, tIndex) => (
                    <Paper
                      key={tIndex}
                      className={`${classes.paperPadding} ${classes.groupPaper}`}
                    >
                      <div className={classes.tsDetails}>
                        <div className={classes.tsHeading}>
                          {data.timesheet.title}
                        </div>
                        <div className={classes.divider}></div>
                        <div>{data.timesheet.person_name}</div>
                        <div className={classes.divider}></div>
                        <div>{data.timesheet.contractor_name}</div>
                      </div>
                      {Object.values(data.rows).map((row, rIndex) => (
                        <div key={rIndex} className={classes.tsRowDetails}>
                          <div className={classes.tsRowTitle}>{row.title}</div>
                          <div className={classes.rowDetailsContainer}>
                            <div className={classes.tsRowTime}>
                              {getTimeFromDate(row.start_time)}
                            </div>
                            <div className={classes.divider}></div>
                            <div className={classes.tsRowTime}>
                              {row.end_time
                                ? getTimeFromDate(row.end_time)
                                : ""}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Paper>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className={classes.alignedRight}>
            <Button
              variant="outlined"
              color="primary"
              onClick={approveUnplanned}
            >
              {g("submitButton")}
            </Button>
          </div>
        </Paper>
      )}
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid
          item
          sm={6}
          md={panelStatus ? 9 : 12}
          className={classes.transition}
        >
          {Object.entries(timesheetsDict).map(
            ([timesheetId, timesheetDict]) => (
              <Paper
                className={`${classes.paper} ${classes.paperPadding}`}
                key={timesheetId}
              >
                <div className={classes.timesheetDetailsContainer}>
                  <div className={classes.timesheetDetails}>
                    {timesheetDict.timesheet.title}
                  </div>
                  <div className={classes.divider}></div>
                  <div>{timesheetDict.timesheet.person_name}</div>
                  <div className={classes.divider}></div>
                  <div>{timesheetDict.timesheet.contractor_name}</div>
                  <div className={classes.selectTimesheet}>
                    <Checkbox
                      color="primary"
                      size="small"
                      onChange={checkTimesheet(timesheetId, timesheetDict)}
                      checked={Boolean(selections[timesheetId])}
                      indeterminate={
                        Boolean(selections[timesheetId]) &&
                        Object.keys(selections[timesheetId]).length !==
                          Object.keys(timesheetDict.rows).length
                      }
                    />
                  </div>
                </div>
                <Paper className={classes.tableWrapper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{ts("titleHeading")}</TableCell>
                        <TableCell>{ts("startTimeHeading")}</TableCell>
                        <TableCell>{ts("endTimeHeading")}</TableCell>
                        <TableCell
                          align="right"
                          className={classes.lastColumnHead}
                        >
                          {ts("actionsHeading")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(timesheetsDict[timesheetId].rows).map(
                        ([rowId, _row]) => {
                          return (
                            <TableRow key={rowId}>
                              <TableCell>{_row.title}</TableCell>
                              <TableCell>
                                {getTimeFromDate(_row.start_time)}
                              </TableCell>
                              <TableCell>
                                {_row.end_time
                                  ? getTimeFromDate(_row.end_time)
                                  : ""}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.lastColumnBody}
                              >
                                <Checkbox
                                  color="primary"
                                  size="small"
                                  onChange={checkTimesheetRow(
                                    timesheetId,
                                    rowId,
                                    timesheetDict
                                  )}
                                  checked={Boolean(
                                    selections[timesheetId] &&
                                      selections[timesheetId][rowId]
                                  )}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </Paper>
            )
          )}
        </Grid>
        {panelStatus && (
          <Grid item sm={6} md={3}>
            <Paper
              className={`${classes.groupSection} ${classes.paperPadding} ${classes.fullHeight}`}
            >
              <UnapprovedWorkAdd
                groups={groups}
                createGroup={createGroup}
                moveIntoGroup={moveIntoGroup}
                badgeCount={badgeCount}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default UnapprovedWorkComponent;
