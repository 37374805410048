import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  Collapse
} from "@material-ui/core";
import { TextField, Select } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  PasscardForm,
  Passcard,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  AutoCompleteSelect,
  AutoCompleteSelectMulti
} from "../../AutoCompleteSelect";
import DatePicker from "../../DatePicker";
import { EnumStrings } from "../../../redux/strings";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";

const passcardTypeOptions = [
  EnumStrings.KEY,
  EnumStrings.CODE,
  EnumStrings.PASSCARD,
  EnumStrings.TAG
];

const statusOptions = [
  EnumStrings.ACTIVE,
  EnumStrings.ARCHIVED,
  EnumStrings.LOCKED,
  EnumStrings.DRAFT
];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    serial_number: Yup.string()
      .min(1, t("minLengthError"))
      .max(100, t("max100LengthError"))
      .required(t("requiredError")),
    status: Yup.string()
      .oneOf(statusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    passcard_type: Yup.string()
      .oneOf(passcardTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    notes: Yup.string().nullable().notRequired(),
    code: Yup.string()
      .min(1, t("minLengthError"))
      .max(20, t("max20LengthError"))
      .nullable()
      .notRequired(),
    key_number: Yup.string()
      .min(1, t("minLengthError"))
      .max(20, t("max20LengthError"))
      .nullable()
      .notRequired(),
    // out_date: Yup.date().nullable().notRequired(),
    in_date: Yup.date()
      .min(Yup.ref("out_date"), t("inDateLaterThanOutDateError"))
      .nullable()
      .notRequired(),
    owner_company: Yup.number()
      .positive(t("requiredError"))
      .required(t("requiredError")),
    contractor_company: Yup.number()
      .positive(t("requiredError"))
      .required(t("requiredError")),
    contractor_person: Yup.number().nullable().notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    }
  });

interface NewPasscardProps
  extends FormikSubmitDispatchProps<PasscardForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  passcard?: Passcard;
  customerOptions: SelectFieldOption[];
  contractorOptions: SelectFieldOption[];
  personOptions: SelectFieldOption[];
  customerobjectOptions: SelectFieldOption[];
  loadCustomerobjects: (companyId: number) => void;
  loadContractorPersons: (companyId: number) => void;
  loadCustomers: (searchTerm?: string) => void;
  loadContractors: (searchTerm?: string) => void;
}

const NewEditPasscard: React.FC<NewPasscardProps> = (props) => {
  const {
    classes,
    passcard,
    customerOptions,
    contractorOptions,
    personOptions,
    customerobjectOptions,
    loadCustomerobjects,
    loadContractorPersons,
    loadCustomers,
    loadContractors,
    tabId
  } = props;
  const t = useTranslate("PasscardPage");
  const t2 = useTranslate("Passcards");
  const t3 = useTranslate("ValidationErrorMessages");
  let initialValues: Passcard | PasscardForm;
  if (passcard && (passcard as Passcard).id) {
    initialValues = {
      ...passcard
    };
  } else {
    initialValues = {
      id: -1,
      status: "ACTIVE",
      passcard_type: "PASSCARD",
      serial_number: "",
      notes: "",
      code: "",
      key_number: "",
      in_date: null,
      out_date: null,
      owner_company: -1,
      contractor_company: -1,
      contractor_person: null,
      customerobjects: []
    };
  }
  useEffect(() => {
    if (passcard) {
      store.dispatch(setTitle(passcard.serial_number, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passcard]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/companies/passcards${
                passcard ? `/${passcard.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {passcard ? t("editPasscardHeading") : t("newPasscardHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={props.handleSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              {/* Serial number */}
              <Field
                id="passcard-form-serial_number"
                type="text"
                name="serial_number"
                label={t("serialNumberLabel")}
                placeholder={t("serialNumberLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              {/* Code */}
              <Field
                id="passcard-form-code"
                type="text"
                name="code"
                label={t("codeLabel")}
                placeholder={t("codeLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              {/* Key number */}
              <Field
                id="passcard-form-key_number"
                type="text"
                name="key_number"
                label={t("keyNumberLabel")}
                placeholder={t("keyNumberLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              {/* Status type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="passcard-form-status"
              >
                {t("statusLabel")}
              </InputLabel>
              <Field
                id="passcard-form-status"
                name="status"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              {/* Passcard type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="passcard-form-passcard_type"
              >
                {t("passcardTypeLabel")}
              </InputLabel>
              <Field
                id="passcard-form-passcard_type"
                name="passcard_type"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {passcardTypeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              {/* Owner company */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-owner_company"
              >
                {t("ownerCompanyLabel")}
              </InputLabel>
              <Field
                id="form-owner_company"
                type="text"
                name="owner_company"
                label={t("ownerCompanyLabel")}
                placeholder={t("ownerCompanyLabel")}
                value={
                  customerOptions.find(
                    (company) => props.values.owner_company === company.value
                  )
                    ? customerOptions.find(
                        (company) =>
                          props.values.owner_company === company.value
                      )
                    : null
                }
                options={customerOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                customHandleChange={loadCustomerobjects}
                loadOptions={loadCustomers}
              />
              <Collapse
                in={
                  props.values.owner_company !== -1 &&
                  customerobjectOptions.length > 0
                }
              >
                <InputLabel
                  className={classes.selectLabel}
                  htmlFor="passcard-form-customerobjects"
                >
                  {t("customerobjectsLabel")}
                </InputLabel>
                <Field
                  id="passcard-form-customerobjects"
                  type="text"
                  name="customerobjects"
                  placeholder={t("customerobjectsLabel")}
                  TextFieldProps={{
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true
                    }
                  }}
                  value={
                    props.values.customerobjects
                      ? (props.values.customerobjects.map((customerobject) =>
                          customerobjectOptions.find(
                            (customerobjectOption) =>
                              customerobject === customerobjectOption.value
                          )
                        ) as SelectFieldOption[])
                      : props.values.customerobjects
                  }
                  options={customerobjectOptions}
                  component={AutoCompleteSelectMulti}
                  margin="normal"
                  fullWidth
                  isMulti
                />
              </Collapse>

              {/* Contractor company */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-contractor_company"
              >
                {t("contractorCompanyLabel")}
              </InputLabel>
              <Field
                id="form-contractor_company"
                type="text"
                name="contractor_company"
                label={t("contractorCompanyLabel")}
                placeholder={t("contractorCompanyLabel")}
                value={
                  contractorOptions.find(
                    (company) =>
                      props.values.contractor_company === company.value
                  )
                    ? contractorOptions.find(
                        (company) =>
                          props.values.contractor_company === company.value
                      )
                    : null
                }
                options={contractorOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                customHandleChange={loadContractorPersons}
                loadOptions={loadContractors}
              />

              <Collapse
                in={
                  props.values.contractor_company !== -1 &&
                  personOptions.length > 0
                }
              >
                {/* Contractor person */}
                <InputLabel
                  className={classes.selectLabel}
                  htmlFor="form-contractor_person"
                >
                  {t("contractorPersonLabel")}
                </InputLabel>
                <Field
                  id="form-contractor_person"
                  type="text"
                  name="contractor_person"
                  label={t("contractorPersonLabel")}
                  placeholder={t("contractorPersonLabel")}
                  value={
                    personOptions.find(
                      (person) =>
                        props.values.contractor_person === person.value
                    )
                      ? personOptions.find(
                          (person) =>
                            props.values.contractor_person === person.value
                        )
                      : null
                  }
                  options={personOptions}
                  component={AutoCompleteSelect}
                  margin="normal"
                  fullWidth
                />
              </Collapse>

              {/* Out date */}
              <Field
                id="new-contract-form-out_date"
                name="out_date"
                label={t("outDateLabel")}
                placeholder={initialValues.out_date}
                component={DatePicker}
                format="yyyy-MM-dd"
                margin="normal"
                fullWidth
              />

              {/* In date */}
              <Field
                id="new-contract-form-in_date"
                name="in_date"
                label={t("inDateLabel")}
                placeholder={initialValues.in_date}
                component={DatePicker}
                format="yyyy-MM-dd"
                margin="normal"
                fullWidth
              />

              {/* Notes */}
              <Field
                id="new-passcard-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-passcard-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {passcard ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditPasscard);
