import React, { useEffect, useState } from "react";
import { Contract, TabComponentProps } from "../../../redux/types";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  getContractAPI,
  getContractPdfAPI
} from "../../../services/api-declaration";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import PDFViewer from "../../PDFViewer";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";

const ContractPDFViewer: React.FC<TabComponentProps<"contractId" | "taskId">> = ({
  tabId,
  routeParams: { contractId, taskId }
}) => {
  const [contract, setContract] = useState<Contract>();
  const [contractPdf, setContractPdf] = useState<Blob>();

  useEffect(() => {
    let alive = true;

    Promise.all([
      getContractAPI(contractId),
      getContractPdfAPI(contractId, taskId)
    ]).then(([contract, contractPdf]) => {
      if (alive) {
        setContract(contract);
        setContractPdf(contractPdf);
        store.dispatch(setTitle(contract.name, tabId));
      }
    });

    return () => {
      alive = false;
    };
  }, [tabId, contractId, taskId]);

  return contract && contractPdf ? (
    <PDFViewer pdfBlob={contractPdf} pdfName={contract.name || ""} />
  ) : (
    <LoadingSpinnerPaper />
  );
};

export default ContractPDFViewer;
