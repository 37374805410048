import React, { FC, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  TableHead,
  TableSortLabel,
  TablePagination,
  Toolbar,
  Typography,
  IconButton
} from "@material-ui/core";
import { INotificationSettingDisplay } from "../../redux/types";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslate } from "../../services/appLanguageService";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    top: 0,
    position: "sticky",
    background: "white",
    zIndex: 10
  },
  title: {
    flex: "0 0 auto"
  }
}));
const useTableHeaderStyles = makeStyles((theme) => ({
  tableHeader: {
    top: theme.spacing(6),
    position: "sticky",
    backgroundColor: "white",
    paddingTop: theme.spacing(1),
    zIndex: 1
  }
}));
const useStyles = makeStyles(() => ({
  tableContainer: {
    height: "100%",
    overflowY: "auto"
  },
  table: {
    minWidth: 750
  },
  numberCellText: {
    color: "#616161"
  },
  rowIconCell: {
    display: "flex",
    alignItems: "center"
  }
}));

function desc(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array: any, cmp: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function getSorting(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy);
}

// Columns
const headRows = (t: (translation: string) => string) => [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: t("titleHeading")
  },
  {
    id: "notificationtype",
    numeric: false,
    disablePadding: true,
    label: t("notificationTypeHeading")
  },
  {
    id: "eventtype",
    numeric: false,
    disablePadding: false,
    label: t("eventTypeHeading")
  },
  {
    id: "search_term",
    numeric: false,
    disablePadding: false,
    label: t("searchTermHeading")
  }
];

const TableToolbar = () => {
  const classes = useToolbarStyles();
  const t = useTranslate("NotificationSettingsTable");
  return (
    <Toolbar className={classes.root}>
      <Typography variant="h6" id="tableTitle" className={classes.title}>
        {t("notificationSettingsHeaderTitle")}
      </Typography>
    </Toolbar>
  );
};

interface EnhancedTableHeadProps {
  order: "asc" | "desc" | false;
  orderBy: string;
  onRequestSort: (_: any, property: any) => void;
}

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const classes = useTableHeaderStyles();
  const t = useTranslate("NotificationSettingsTable");
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headRows(t).map((row) => (
          <TableCell
            key={row.id}
            align="left"
            sortDirection={orderBy === row.id ? order : false}
            className={classes.tableHeader}
          >
            {row.id ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={typeof order === "string" ? order : "asc"}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              row.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface NotificationSettingsTableProps {
  dataSource: INotificationSettingDisplay[];
  deleteNotificationSetting: (
    notificationSetting: INotificationSettingDisplay
  ) => () => void;
  onRowUpdate: (nSettings: INotificationSettingDisplay[]) => void;
  saveNotificationSetting: (
    notificationProfileId: number,
    notificationSetting: INotificationSettingDisplay
  ) => () => void;
  notificationProfileId: number;
}

const NotificationSettingsTable: FC<NotificationSettingsTableProps> = (
  props
) => {
  const {
    dataSource,
    deleteNotificationSetting,
    onRowUpdate,
    saveNotificationSetting,
    notificationProfileId
  } = props;
  const classes = useStyles();
  const [order, setOrder] = useState<"asc" | "desc" | false>("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const e = useTranslate("EventTypes");
  const n = useTranslate("NotificationTypes");

  function handleRequestSort(_: any, property: any) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(_: any, newPage: any) {
    setPage(newPage);
  }
  const updateRows = () => {
    const rows = stableSort(dataSource, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    onRowUpdate(rows);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(updateRows, [order, orderBy]);

  function handleChangeRowsPerPage(event: any) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <div className={classes.tableContainer}>
      <TableToolbar />
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {dataSource.map(
            (nSetting: INotificationSettingDisplay, index: number) =>
              nSetting.id !== undefined && (
                <TableRow key={index} tabIndex={-1}>
                  <TableCell className={classes.rowIconCell}>
                    {
                      <>
                        <IconButton
                          aria-label="edit-icon"
                          component="span"
                          data-cy={`notification-setting-edit-btn-${index}`}
                          onClick={saveNotificationSetting(
                            notificationProfileId,
                            nSetting
                          )}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete-icon"
                          component="span"
                          data-cy={`notification-setting-delete-btn-${index}`}
                          onClick={deleteNotificationSetting(nSetting)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                  </TableCell>
                  <TableCell align="left">{nSetting.title || "N/A"}</TableCell>
                  <TableCell align="left">
                    {n(nSetting.notificationtype)}
                  </TableCell>
                  <TableCell align="left">{e(nSetting.eventtype)}</TableCell>
                  <TableCell align="left">{nSetting.search_term}</TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={dataSource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

NotificationSettingsTable.defaultProps = {
  dataSource: [],
  saveNotificationSetting: () => () => {},
  deleteNotificationSetting: () => () => {}
};
export default NotificationSettingsTable;
