import React, { useCallback, useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableFooter,
  TablePagination,
  Checkbox,
  Button,
  IconButton
} from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import {
  HistoryFilterQuery,
  HistoryRow,
  SelectFieldOption,
  ServiceCategory,
  TableFilterConfig
} from "../../redux/types";
import {
  Column,
  useTable,
  useGroupBy,
  useExpanded,
  useSortBy,
  usePagination,
  CellProps,
  Row
} from "react-table";
import { niceAmount, niceDecimal } from "../FormatHelpers";
import TableFilter from "../tableFilter/TableFilter";
import LoadingSpinnerPaper from "../LoadingSpinnerPaper";
import {
  getAllServiceCategories,
  reverseWorkapprovalAPI
} from "../../services/api-declaration";
import TransactionDialog from "./transactionDialog/TransactionDialog";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { orderBy } from "lodash";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: `${theme.spacing(1.5)}px 0px 0px`,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      paddingTop: 0
    },
    mainRow: {
      cursor: "pointer"
    },
    totalLoss: {
      backgroundColor: "#eba5a3"
    },
    partialLoss: {
      backgroundColor: "#f8d9ac"
    },
    subRow: {
      backgroundColor: "#c7daf2"
    },
    sumRow: {
      backgroundColor: "#b0e1ef"
    },
    rightAlign: {
      textAlign: "right",
      whiteSpace: "nowrap"
    },
    leftAlign: {
      textAlign: "left",
      paddingLeft: 10,
      whiteSpace: "nowrap"
    },
    leftAdjustment: {
      paddingLeft: 10
    },
    rightAdjustment: {
      paddingRight: 10
    },
    bold: {
      fontWeight: "bold"
    },
    italic: {
      fontStyle: "italic"
    },
    tableCell: {
      fontSize: 13,
      fontFamily: "Open sans, Arial",
      paddingRight: 5,
      paddingLeft: 5
    },
    small: {
      fontSize: 10
    },
    profit: {
      color: "green"
    },
    loss: {
      color: "red"
    },
    normal: {
      color: "black"
    },
    mainTableCell: {
      borderTop: "10px solid white",
      boxShadow: "0px 1px #e0e0e0 inset",
      paddingTop: 7
    },
    paperVerify: {
      position: "sticky",
      bottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    flexView: {
      flex: 1,
      display: "flex",
      alignItems: "center"
    },
    flexBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "spaceBetween"
    },
    alignCenter: { alignItems: "center" },
    hasStatusIcon: {
      marginRight: theme.spacing(0.5)
    },
    tableHead: {
      backgroundColor: "#FFF",
      fontWeight: "bold",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 7,
      paddingBottom: 7
    },
    unapproave: {
      backgroundColor: "#FF0000",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#FF0000"
      }
    },
    nowrap: { whiteSpace: "nowrap" },
    selectedApproval: { marginRight: theme.spacing(1) }
  });

interface HistoryProps extends WithStyles<typeof styles> {
  historyData: HistoryRow[];
  contractorOptions: SelectFieldOption[];
  customerOptions: SelectFieldOption[];
  customerobjectOptions: SelectFieldOption[];
  businessareaOptions: SelectFieldOption[];
  loadHistory: (filter: HistoryFilterQuery) => void;
  loadContractorOptions: (searchTerm?: string) => void;
  loadCustomerOptions: (searchTerm?: string) => void;
  loadCustomerobjectOptions: (searchTerm?: string) => void;
  loadBusinessareaOptions: (searchTerm?: string) => void;
  resetHistory: () => void;
  count: number;
  previous: string | null;
  next: string | null;
  loadingHistory: boolean;
}

interface TableProps {
  columns: any;
  data: HistoryRow[];
  count: number;
  classes: any;
  pageSize: number;
  pageIndex: number;
  previous: string | null;
  next: string | null;
  showPrices: boolean;
  handlePageChange: (event: any, newPage: number) => void;
  handleRowsPerPageChange: (event: any) => void;
}

const Table: React.FC<TableProps> = (props) => {
  const {
    columns,
    data,
    classes,
    pageSize,
    pageIndex,
    handlePageChange,
    handleRowsPerPageChange,
    count,
    showPrices
  } = props;

  const t = useTranslate("HistoryPage");

  const hiddenPriceColumns = showPrices
    ? []
    : ["timesheet_id", "contractor_total", "customer_total", "profit"];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: [
            ...hiddenPriceColumns,
            "timesheet_id",
            "driver",
            "customer_company_name"
          ],
          groupBy: ["timesheet_id"],
          sortBy: [{ id: "start", desc: false }]
        }
      },
      useGroupBy,
      useSortBy,
      useExpanded,
      usePagination
    );

  let sum = { contractor_total: 0, customer_total: 0, profit: 0 };

  rows.forEach((row) => {
    if (row.isGrouped) {
      sum.contractor_total += row.values.contractor_total;
      sum.customer_total += row.values.customer_total;
      sum.profit += row.values.profit.sum;
    }
  });

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <MuiTable
        {...getTableProps()}
        stickyHeader
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  className={classes.tableHead}
                  style={{ width: column.width }}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()} data-cy="history-table-body">
          {rows.map((row) => {
            prepareRow(row);

            const isSubRow = !row.isExpanded && !row.isGrouped;

            let rowClasses = classes.mainRow;
            if (isSubRow) {
              rowClasses = classes.subRow;
            } else {
              if (row.values.profit.sum < 0) {
                rowClasses = `${classes.mainRow} ${classes.totalLoss}`;
              } else if (row.values.profit.containsLoss) {
                rowClasses = `${classes.mainRow} ${classes.partialLoss}`;
              }
            }

            return (
              <TableRow
                className={rowClasses}
                {...row.getRowProps()}
                onClick={() => {
                  if (!isSubRow) {
                    row.toggleRowExpanded();
                  }
                }}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      className={`${classes.tableCell} ${
                        !isSubRow ? classes.mainTableCell : ""
                      }`}
                      key={index}
                    >
                      <div className={classes.greyTopBorder}>
                        {!cell.isGrouped && cell.isAggregated
                          ? // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render("Aggregated")
                          : cell.isPlaceholder
                            ? null // For cells with repeated values, render null
                            : // Otherwise, just render the regular cell
                              cell.render("Cell")}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          {showPrices && (
            <TableRow className={classes.sumRow}>
              <TableCell colSpan={7}></TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.rightAlign} ${classes.bold} ${classes.normal}`}
              >
                {t("sumLabel")}:
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.rightAlign} ${classes.bold} ${classes.normal}`}
              >
                {niceAmount(sum.contractor_total)}
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.rightAlign} ${classes.bold} ${classes.normal}`}
              >
                {niceAmount(sum.customer_total)}
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.rightAlign} ${
                  sum.profit >= 0 ? classes.profit : classes.loss
                }`}
              >
                {niceAmount(sum.profit)}
              </TableCell>
              <TableCell />
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={showPrices ? 12 : 9}>
              <TablePagination
                component="div"
                rowsPerPageOptions={[
                  50,
                  100,
                  200,
                  { label: "All", value: count }
                ]}
                count={count}
                rowsPerPage={pageSize}
                page={pageIndex - 1}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </MuiTable>
    </TableContainer>
  );
};

const History: React.FC<HistoryProps> = ({
  classes,
  historyData,
  contractorOptions,
  customerOptions,
  customerobjectOptions,
  businessareaOptions,
  loadHistory,
  loadContractorOptions,
  loadCustomerOptions,
  loadCustomerobjectOptions,
  loadBusinessareaOptions,
  previous,
  next,
  count,
  loadingHistory,
  resetHistory
}) => {
  const t = useTranslate("HistoryPage");

  const showPrices = historyData.some(
    (historyRow) => historyRow.profit !== null
  );

  const columns: Column<HistoryRow>[] = [
    {
      accessor: "timesheet_id"
    },
    {
      Header: (
        <div className={`${classes.leftAlign} ${classes.leftAdjustment}`}>
          {t("CheckHeader")}
        </div>
      ),
      accessor: "approval_id",
      Cell: ({ value }) => (
        <>
          <Checkbox
            onChange={() => handleUnApprovedTimesheet(value)}
            checked={unApprovalRows && Boolean(unApprovalRows[value])}
          />
        </>
      ),
      Aggregated: () => <></>,
      width: "5%",
      aggregate: "max"
    },
    {
      accessor: "driver"
    },
    {
      accessor: "customer_company_name"
    },
    {
      Header: (
        <div className={`${classes.leftAlign} ${classes.leftAdjustment}`}>
          {t("startHeader")}
        </div>
      ),
      accessor: "start",
      Cell: ({ value }) => {
        const [date, time] = value.split(" ");
        return (
          <>
            <div>{time}</div>
            <div>{date}</div>
          </>
        );
      },
      aggregate: (leafValues: string[], _) => leafValues.sort()[0], // Assume our dates format sorts nicely
      Aggregated: ({ value }) => {
        const [date, time] = value.split(" ");
        return (
          <>
            <div className={classes.bold}>{time}</div>
            <div>{date}</div>
          </>
        );
      },
      width: "5%"
    },
    {
      Header: (
        <div className={`${classes.leftAlign} ${classes.leftAdjustment}`}>
          {t("stopHeader")}
        </div>
      ),
      accessor: "stop",
      Cell: ({ value }) => {
        const [date, time] = value.split(" ");
        return (
          <>
            <div>{time}</div>
            <div>{date}</div>
          </>
        );
      },
      aggregate: (leafValues: string[], _) =>
        leafValues.sort()[leafValues.length - 1], // Assume our date format sorts nicely
      Aggregated: ({ value }) => {
        const [date, time] = value.split(" ");
        return (
          <>
            <div className={classes.bold}>{time}</div>
            <div>{date}</div>
          </>
        );
      },
      width: "5%"
    },

    {
      Header: (
        <div className={`${classes.leftAlign} ${classes.leftAdjustment}`}>
          {t("hoursHeader")}
        </div>
      ),
      accessor: "hours",
      aggregate: "sum",
      Cell: ({ value }) => niceDecimal(value),
      Aggregated: ({ value }) => (
        <div className={classes.bold}>{niceDecimal(value)}</div>
      ),
      width: "5%"
    },

    {
      Header: (
        <div className={`${classes.leftAlign} ${classes.leftAdjustment}`}>
          {t("taskHeader")}
        </div>
      ),
      accessor: "task_name",
      aggregate: "count",
      Cell: ({ value, row }) => (
        <>
          <div>{value}</div>
          <div className={classes.italic}>
            {row.original.businessarea_name}
            {" - "}
            {row.original.service_categories.length ? (
              <>{row.original.service_categories.join(", ")}</>
            ) : null}
          </div>
        </>
      ),
      Aggregated: ({ value }) => (
        <div className={classes.bold}>
          {value} {t("aggregatedTasks")}
        </div>
      ),
      width: "15%"
    },

    {
      id: "contractor_company_name",
      Header: (
        <div className={`${classes.leftAlign} ${classes.leftAdjustment}`}>
          {t("contractorHeader")}
        </div>
      ),
      accessor: (originalRow, rowIndex) => ({
        contractor_company_name: originalRow.contractor_company_name,
        driver: originalRow.driver
      }),
      aggregate: (leafValues, aggregatedValues) => leafValues[0],
      Cell: ({
        value
      }: {
        value: { contractor_company_name: string; driver: string };
      }) => (
        <>
          <div>{value.contractor_company_name}</div>
          <div className={classes.italic}>{value.driver}</div>
        </>
      ),
      Aggregated: ({
        value
      }: {
        value: { contractor_company_name: string; driver: string };
      }) => (
        <>
          <div className={classes.bold}>{value.contractor_company_name}</div>
          <div>({value.driver})</div>
        </>
      ),
      width: "15%"
    },

    {
      id: "service_name",
      Header: (
        <div className={`${classes.leftAlign} ${classes.leftAdjustment}`}>
          {t("serviceHeader")}
        </div>
      ),
      accessor: "service_name",
      aggregate: "unique",
      Aggregated: ({ value, row }) => {
        const accompanyingPeopleSet = new Set();

        const accompanyingPeopleCount = Object.values(row.values).reduce(
          (total, array) => {
            if (Array.isArray(array)) {
              array.forEach((item) => {
                if (
                  Array.isArray(item?.accompanying_people) &&
                  item?.accompanying_people.length > 0
                ) {
                  item.accompanying_people.forEach(
                    (person: { person: unknown }) => {
                      if (!accompanyingPeopleSet.has(person.person)) {
                        accompanyingPeopleSet.add(person.person);
                        total += 1;
                      }
                    }
                  );
                }
              });
            }
            return total;
          },
          0
        );

        const accompanyingPeopleServiceName =
          row.allCells?.[0]?.row?.subRows?.[0]?.original
            ?.accompanying_people?.[0]?.service_name || "";
        const accompanyingPeople = accompanyingPeopleCount > 0;

        return (
          <>
            <div className={classes.bold}>{value}</div>
            {accompanyingPeople && (
              <div>
                + {accompanyingPeopleServiceName} ({accompanyingPeopleCount})
              </div>
            )}
          </>
        );
      },

      Cell: ({ row }) => {
        const accompanyingPeople = row.original?.accompanying_people || [];
        const serviceName = row.original?.service_name;
        const uniquePeopleSet = new Set(
          accompanyingPeople.map((person) => person.person)
        );

        return (
          <div>
            <span>{serviceName}</span>
            {accompanyingPeople.length > 0 && (
              <div className={classes.italic}>
                <span>
                  + {accompanyingPeople[0].service_name} ({uniquePeopleSet.size}
                  )
                </span>
              </div>
            )}
          </div>
        );
      },

      width: "15%"
    },

    {
      id: "material",
      Header: (
        <div className={`${classes.leftAlign} ${classes.leftAdjustment}`}>
          {t("materialHeader")}
        </div>
      ),
      accessor: "material_usages",
      Cell: ({
        value
      }: {
        value: {
          name: string;
          amount: number;
          unit: string;
        }[];
      }) =>
        value.map((v, i) => (
          <div key={i}>{`${v.amount} ${v.unit} ${v.name}`}</div>
        )),
      aggregate: (
        leafValues: { name: string; unit: string; amount: number }[][],
        _
      ) => {
        const usages: {
          [material: string]: { name: string; unit: string; amount: number };
        } = {};
        for (const value of leafValues.flat()) {
          if ("amount" in value) {
            const entry = usages[value.name];
            usages[value.name] = {
              ...value,
              amount: entry ? entry.amount + value.amount : value.amount
            };
          }
        }
        return Object.values(usages);
      },
      Aggregated: ({
        value
      }: {
        value: {
          name: string;
          amount: number;
          unit: string;
        }[];
      }) =>
        value.map((v, i) => (
          <div key={i}>{`${v.amount.toFixed(2)} ${v.unit} ${v.name}`}</div>
        ))
    },
    {
      Header: (
        <div className={`${classes.rightAlign} ${classes.rightAdjustment}`}>
          {t("contractorTotalHeader")}
        </div>
      ),
      accessor: "contractor_total",
      aggregate: "sum",
      Cell: ({ value }) => (
        <div className={classes.rightAlign}>{niceAmount(value)}</div>
      ),
      Aggregated: ({ value }) => (
        <div className={`${classes.rightAlign} ${classes.bold}`}>
          {niceAmount(value)}
        </div>
      ),
      width: "8%"
    },
    {
      Header: (
        <div className={`${classes.rightAlign} ${classes.rightAdjustment}`}>
          {t("customerTotalHeader")}
        </div>
      ),
      accessor: "customer_total",
      aggregate: "sum",
      Cell: ({ value }) => (
        <div className={classes.rightAlign}>{niceAmount(value)}</div>
      ),
      Aggregated: ({ value }) => (
        <div className={`${classes.rightAlign} ${classes.bold}`}>
          {niceAmount(value)}
        </div>
      ),
      width: "8%"
    },
    {
      Header: (
        <div className={`${classes.rightAlign} ${classes.rightAdjustment}`}>
          {t("profitHeader")}
        </div>
      ),
      accessor: "profit",
      aggregate: (leafValues, aggregatedValues) => ({
        sum: leafValues.reduce((a, b) => a + b, 0),
        containsLoss: leafValues.filter((value) => value < 0).length > 0
      }),
      Cell: ({ value }) => (
        <div
          className={`${classes.rightAlign} ${
            value >= 0 ? classes.profit : classes.loss
          }`}
        >
          {niceAmount(value)}
        </div>
      ),
      Aggregated: ({ value }) => (
        <div
          className={`${classes.rightAlign} ${classes.bold} ${
            value.sum >= 0 ? classes.profit : classes.loss
          }`}
        >
          {niceAmount(value.sum)}
        </div>
      ),
      width: "5%"
    },
    {
      id: "forTransactionIcon",
      Header: <></>,
      accessor: (originalRow) => originalRow,
      Cell: ({ row }: CellProps<HistoryRow>) => (
        <IconButton
          size="small"
          aria-label="transaction"
          onClick={handleTransactionClicked(row)}
        >
          <LocalAtmIcon />
        </IconButton>
      ),
      aggregate: (row) => row,
      Aggregated: ({ value }) => (
        <IconButton
          size="small"
          aria-label="transaction"
          onClick={handleTransactionGroupClicked(value as HistoryRow[])}
        >
          <LocalAtmIcon />
        </IconButton>
      ),
      width: "5%"
    }
  ];

  const data = historyData;
  const [pageSize, setPageSize] = useState(100);
  const [pageIndex, setPageIndex] = useState(1);
  const [openTransactions, setOpenTransactions] =
    useState<[number, HistoryRow[] | null]>();
  const [serviceCategories, setServiceCategories] = useState<ServiceCategory[]>(
    []
  );

  const now = new Date();
  const DEFAULT_TABLE_QUERY: HistoryFilterQuery = {
    filter__time__lt: now.toISOString(),
    filter__time__gt: new Date(now.setDate(now.getDate() - 14)).toISOString(),
    filter__customer_id: -1,
    filter__contractor_id: -1,
    filter__customer_object_id: -1,
    filter__business_area_id: -1
  };
  const DEFAULT_QUERY: HistoryFilterQuery = {
    page_size: pageSize,
    page: pageIndex,
    ...DEFAULT_TABLE_QUERY
  };

  const FILTERS: TableFilterConfig<HistoryFilterQuery, any>[][] = [
    [
      {
        title: t("customerFieldLabel"),
        key: "filter__customer_id",
        type: "SELECTOR",
        options: customerOptions,
        optionLabelKey: "title",
        optionValueKey: "id",
        loadOptions: loadCustomerOptions
      },
      {
        title: t("contractorFieldLabel"),
        key: "filter__contractor_id",
        type: "SELECTOR",
        options: contractorOptions,
        optionLabelKey: "title",
        optionValueKey: "id",
        loadOptions: loadContractorOptions
      },
      {
        title: t("customerobjectFieldLabel"),
        key: "filter__customer_object_id",
        type: "SELECTOR",
        options: customerobjectOptions,
        optionLabelKey: "title",
        optionValueKey: "id",
        loadOptions: loadCustomerobjectOptions
      }
    ],
    [
      {
        title: t("businessareaFieldLabel"),
        key: "filter__business_area_id",
        type: "SELECTOR",
        options: businessareaOptions,
        optionLabelKey: "title",
        optionValueKey: "id",
        loadOptions: loadBusinessareaOptions
      },
      {
        title: t("startDateFieldLabel"),
        key: "filter__time__gt",
        type: "DATE"
      },
      {
        title: t("endDateFieldLabel"),
        key: "filter__time__lt",
        type: "DATE"
      }
    ]
  ];
  const [query, setQuery] = useState<HistoryFilterQuery>({
    ...DEFAULT_QUERY
  });

  const [unApprovalRows, setUnApprovalRows] = useState<{
    [key: string]: Boolean;
  }>({});

  const updateApprovalRows = async () => {
    resetHistory();
    const row_approvals = Object.keys(unApprovalRows);
    const request = {
      unset_approvals: {
        row_approvals
      }
    };
    const bulkResponse = await reverseWorkapprovalAPI(request);
    if (bulkResponse) {
      updateHistory(query);
    }
    setUnApprovalRows({});
  };

  const handleUnApprovedTimesheet = (approvalId: number) => {
    setUnApprovalRows((da) => {
      const clone = { ...da };
      if (clone[approvalId]) {
        delete clone[approvalId];
      } else {
        clone[approvalId] = true;
      }
      return clone;
    });
  };

  const handlePageChange = (_: any, newPage: number) => {
    setPageIndex(newPage + 1);
    setQuery({ ...query, page: newPage + 1 });
  };

  const handleRowsPerPageChange = (event: any) => {
    setPageSize(parseInt(event.target.value));
    setPageIndex(1);
    setQuery({ ...query, page_size: parseInt(event.target.value), page: 1 });
  };
  const handleTransactionGroupClicked = (rows: HistoryRow[]) => () => {
    const orderedRows = orderBy(rows, ["start"]);
    setOpenTransactions([orderedRows[0].approval_id, orderedRows]);
  };

  const handleTransactionClicked = (row: Row<HistoryRow>) => () => {
    setOpenTransactions([row.original.approval_id, [row.original]]);
  };

  const updateHistory = (filter: HistoryFilterQuery) => {
    loadHistory(filter);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCallback = useCallback(updateHistory, [query]);

  useEffect(() => {
    updateCallback(query);
  }, [updateCallback, query]);

  useEffect(() => loadContractorOptions(), [loadContractorOptions]);
  useEffect(() => loadCustomerOptions(), [loadCustomerOptions]);
  useEffect(() => loadCustomerobjectOptions(), [loadCustomerobjectOptions]);
  useEffect(() => loadBusinessareaOptions(), [loadBusinessareaOptions]);

  useEffect(() => () => resetHistory(), [resetHistory, query]);

  useEffect(() => {
    let isAlive = true;

    getAllServiceCategories().then(
      (response) => isAlive && setServiceCategories(response.results)
    );

    return () => {
      isAlive = false;
    };
  }, []);

  const handleQuery = (newQuery: HistoryFilterQuery) =>
    setQuery((q) => ({
      page: q.page,
      page_size: q.page_size,
      ...newQuery
    }));

  return (
    <div>
      <TableFilter<HistoryFilterQuery, any>
        filters={FILTERS}
        onChange={handleQuery}
        defaultValues={{ ...DEFAULT_TABLE_QUERY }}
      />
      {loadingHistory ? (
        <LoadingSpinnerPaper />
      ) : (
        <>
          <Table
            columns={columns}
            data={data}
            count={count}
            classes={classes}
            pageSize={pageSize}
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            previous={previous}
            next={next}
            showPrices={showPrices}
          />
          {Object.keys(unApprovalRows).length > 0 && (
            <Paper className={classes.paperVerify}>
              <div className={classes.flexBox}>
                <div className={classes.selectedApproval}>
                  {`${t("selectionTitle")}: ${
                    Object.keys(unApprovalRows).length
                  }`}
                </div>
                <Button
                  variant="outlined"
                  className={classes.unapproave}
                  onClick={updateApprovalRows}
                >
                  {t("unApproveButtonText")}
                </Button>
              </div>
            </Paper>
          )}
        </>
      )}
      {openTransactions && openTransactions[1] && (
        <TransactionDialog
          defaultExpandedId={openTransactions[0]}
          historyRows={openTransactions[1]}
          handleClose={() => setOpenTransactions(undefined)}
          showPrices={showPrices}
          serviceCategories={serviceCategories}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(History);
