import {
  ContractEditPayload,
  Contract,
  ContractingProfile,
  IContractOptions,
} from "../redux/types";
import {
  updateContractAPI,
  getContractAPI,
  getContractProfileTestPdfAPI
} from "./api-declaration";
import { fetchPatch } from "./fetchWrapper";
import { SelectOption } from "react-select-material-ui";
import { useTranslate } from "./appLanguageService";

// eslint-disable-next-line react-hooks/rules-of-hooks
const t = useTranslate("ContractSummaryTypes");

export const contractPropertiesByType: IContractOptions = {
  SERVICE: "services",
  MATERIAL: "materials",
  ACCESSORY: "accessories",
  ADDON: "addons",
  SUPPLEMENT: "supplements",
  SERVICEPACKAGE: "servicepackages"
};

export const getContract = async (contractId: number) => {
  try {
    const response: Contract = await getContractAPI(contractId);
    return Promise.resolve(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateContractItem = async (
  contractId: number,
  payload: ContractEditPayload
) => {
  try {
    const response = await updateContractAPI(contractId, payload);
    return Promise.resolve(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

/* Contracting profiles */

export async function getContractProfileTestPdf(
  id: number,
  contractId: number
): Promise<string> {
  try {
    const contractPDF: Blob = await getContractProfileTestPdfAPI(
      id,
      contractId
    );
    const newBlob = new Blob([contractPDF], { type: "application/pdf" });
    return Promise.resolve(window.URL.createObjectURL(newBlob));
  } catch (e) {
    return Promise.reject(null);
  }
}

export const patchContractProfile = async (
  id: number,
  values: object
): Promise<ContractingProfile> => {
  try {
    const response: ContractingProfile = await fetchPatch(
      `${localStorage.getItem("baseURL")}contracts/contractingprofiles/${id}/`,
      values
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const typeOptions: SelectOption[] = [
  { value: "SERVICE", label: t("SERVICE") },
  { value: "MATERIAL", label: t("MATERIAL") },
  { value: "ACCESSORY", label: t("ACCESSORY") },
  { value: "ADDON", label: t("ADDON") },
  { value: "SUPPLEMENT", label: t("SUPPLEMENT") },
  { value: "SERVICEPACKAGE", label: t("SERVICEPACKAGE") }
];
