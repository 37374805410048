import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  Grid,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import { TextField, Select } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  InvoicingProfileForm,
  InvoicingProfile,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import { EnumStrings } from "../../../redux/strings";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import Editor from "@monaco-editor/react";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";

const currencyOptions = [
  EnumStrings.SEK,
  EnumStrings.NOK,
  EnumStrings.DKK,
  EnumStrings.EUR,
  EnumStrings.USD
];

const localeOptions = [EnumStrings.EN, EnumStrings.SV];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    currency: Yup.string()
      .oneOf(currencyOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    locale: Yup.string()
      .oneOf(localeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    notes: Yup.string().nullable().notRequired(),
    invoicing_css: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    customer_invoice_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    contractor_invoice_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    customer_report_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    contractor_report_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    from_email: Yup.string()
      .min(1, t("minLengthError"))
      .max(150, t("max150LengthError"))
      .email(t("emailInvalidError"))
      .nullable()
      .notRequired(),
    bcc_email: Yup.string()
      .min(1, t("minLengthError"))
      .max(150, t("max150LengthError"))
      .email(t("emailInvalidError"))
      .nullable()
      .notRequired(),
    invoice_email_body_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    invoice_email_subject_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    default_mail_message_text: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    invoicing_company: Yup.number()
      .positive(t("requiredError"))
      .required(t("requiredError"))
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    },
    editor: {
      border: "1px solid #E4E4E4",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    editorLabel: {
      marginTop: theme.spacing(5)
    },
    root: {
      flexGrow: 1
    }
  });

interface NewInvoicingProfileProps
  extends FormikSubmitDispatchProps<InvoicingProfileForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  invoicingProfile?: InvoicingProfile;
  companyOptions: SelectFieldOption[];
  customerInvoiceOptions?: SelectFieldOption[];
  contractorInvoiceOptions?: SelectFieldOption[];
  handleSaveAndCreatePDF?: (
    invoicingProfileId: number,
    invoicingId: number,
    jinjaTemplate: string,
    invoicing_css: string,
    type: string
  ) => void;
  loadCompanies: (searchTerm?: string) => void;
}

const NewEditInvoicingProfile: React.FC<NewInvoicingProfileProps> = (props) => {
  const {
    classes,
    invoicingProfile,
    companyOptions,
    customerInvoiceOptions,
    contractorInvoiceOptions,
    handleSaveAndCreatePDF,
    loadCompanies,
    tabId
  } = props;
  const t = useTranslate("InvoicingProfilePage");
  const t3 = useTranslate("ValidationErrorMessages");
  let initialValues: InvoicingProfile | InvoicingProfileForm;
  if (invoicingProfile && (invoicingProfile as InvoicingProfile).id) {
    initialValues = {
      ...invoicingProfile
    };
  } else {
    initialValues = {
      id: -1,
      currency: "SEK",
      locale: "SV",
      name: "",
      default_vat_percentage: 0.0,
      notes: null,
      invoicing_css: null,
      customer_invoice_jinja: null,
      contractor_invoice_jinja: null,
      from_email: null,
      bcc_email: null,
      invoice_email_body_jinja: null,
      invoice_email_subject_jinja: null,
      default_mail_message_text: null,
      customer_report_jinja: null,
      contractor_report_jinja: null,
      invoicing_company: -1,
      customer_invoice: -1,
      contractor_invoice: -1
    };
  }
  useEffect(() => {
    if (invoicingProfile) {
      store.dispatch(setTitle(invoicingProfile.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicingProfile]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/invoices/invoicingprofiles${
                invoicingProfile ? `/${invoicingProfile.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {invoicingProfile
            ? t("editInvoicingProfileHeading")
            : t("newInvoicingProfileHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={props.handleSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="invoicingprofile-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-invoicing_company"
              >
                {t("invoicingCompanyLabel")}
              </InputLabel>
              <Field
                id="form-invoicing_company"
                type="text"
                name="invoicing_company"
                label={t("invoicingCompanyLabel")}
                placeholder={t("invoicingCompanyLabel")}
                value={
                  companyOptions.find(
                    (company) =>
                      props.values.invoicing_company === company.value
                  )
                    ? companyOptions.find(
                        (company) =>
                          props.values.invoicing_company === company.value
                      )
                    : null
                }
                options={companyOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                loadOptions={loadCompanies}
              />

              {/* Currency */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="invoicingprofile-form-currency"
              >
                {t("currencyLabel")}
              </InputLabel>
              <Field
                id="invoicingprofile-form-currency"
                name="currency"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {currencyOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>

              <Field
                id="invoicingprofile-form-default_vat_percentage"
                type="text"
                name="default_vat_percentage"
                label={t("defaultVatPercentageLabel")}
                placeholder={t("defaultVatPercentageLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              {/* Locale */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="invoicingprofile-form-locale"
              >
                {t("localeLabel")}
              </InputLabel>
              <Field
                id="invoicingprofile-form-locale"
                name="locale"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {localeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>

              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-invoicingprofile-form-invoicing_css"
              >
                {t("invoicingCssLabel")}
              </InputLabel>
              <Field
                id="new-invoicingprofile-form-invoicing_css"
                type="hidden"
                name="invoicing_css"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="css"
                  options={{
                    extraEditorClassName:
                      "new-invoicingprofile-form-invoicing_css"
                  }}
                  value={initialValues.invoicing_css || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("invoicing_css", value);
                    }
                  }}
                />
              </div>

              <InputLabel
                className={classes.editorLabel}
                htmlFor="new-invoicingprofile-form-customer_invoice_jinja"
              >
                {t("customerInvoiceJinjaLabel")}
              </InputLabel>
              <Field
                id="new-invoicingprofile-form-customer_invoice_jinja"
                type="hidden"
                name="customer_invoice_jinja"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="html"
                  options={{
                    extraEditorClassName:
                      "new-invoicingprofile-form-customer_invoice_jinja"
                  }}
                  value={initialValues.customer_invoice_jinja || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("customer_invoice_jinja", value);
                    }
                  }}
                />
              </div>
              {invoicingProfile &&
              customerInvoiceOptions &&
              typeof handleSaveAndCreatePDF !== "undefined" ? (
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                      <Field
                        id="form-customer_invoice"
                        type="text"
                        name="customer_invoice"
                        label={t("customerInvoicesLabel")}
                        placeholder={t("customerInvoicesLabel")}
                        options={customerInvoiceOptions}
                        component={AutoCompleteSelect}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        id="invoicingprofile-form-customer_invoice_jinja-button"
                        type="button"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          if (
                            props.values.customer_invoice_jinja &&
                            props.values.invoicing_css &&
                            props.values.customer_invoice &&
                            props.values.customer_invoice !== -1
                          ) {
                            handleSaveAndCreatePDF(
                              invoicingProfile.id,
                              props.values.customer_invoice,
                              props.values.customer_invoice_jinja,
                              props.values.invoicing_css,
                              EnumStrings.CUSTOMER
                            );
                          }
                        }}
                      >
                        {t("previewButtonLabel")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                ""
              )}

              <InputLabel
                className={classes.editorLabel}
                htmlFor="new-invoicingprofile-form-contractor_invoice_jinja"
              >
                {t("contractorInvoiceJinjaLabel")}
              </InputLabel>
              <Field
                id="new-invoicingprofile-form-contractor_invoice_jinja"
                type="hidden"
                name="contractor_invoice_jinja"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="html"
                  options={{
                    extraEditorClassName:
                      "new-invoicingprofile-form-contractor_invoice_jinja"
                  }}
                  value={initialValues.contractor_invoice_jinja || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("contractor_invoice_jinja", value);
                    }
                  }}
                />
              </div>

              {invoicingProfile &&
              contractorInvoiceOptions &&
              typeof handleSaveAndCreatePDF !== "undefined" ? (
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                      <Field
                        id="form-contractor_invoice"
                        type="text"
                        name="contractor_invoice"
                        label={t("contractorInvoicesLabel")}
                        placeholder={t("contractorInvoicesLabel")}
                        options={contractorInvoiceOptions}
                        component={AutoCompleteSelect}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        id="invoicingprofile-form-contractor_invoice_jinja-button"
                        type="button"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          if (
                            props.values.contractor_invoice_jinja &&
                            props.values.invoicing_css &&
                            props.values.contractor_invoice &&
                            props.values.contractor_invoice !== -1
                          ) {
                            handleSaveAndCreatePDF(
                              invoicingProfile.id,
                              props.values.contractor_invoice,
                              props.values.contractor_invoice_jinja,
                              props.values.invoicing_css,
                              EnumStrings.CONTRACTOR
                            );
                          }
                        }}
                      >
                        {t("previewButtonLabel")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                ""
              )}

              <InputLabel
                className={classes.editorLabel}
                htmlFor="new-invoicingprofile-form-customer_report_jinja"
              >
                {t("customerReportJinjaLabel")}
              </InputLabel>
              <Field
                id="new-invoicingprofile-form-customer_report_jinja"
                type="hidden"
                name="customer_report_jinja"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="html"
                  options={{
                    extraEditorClassName:
                      "new-invoicingprofile-form-customer_report_jinja"
                  }}
                  value={initialValues.customer_report_jinja || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("customer_report_jinja", value);
                    }
                  }}
                />
              </div>

              <InputLabel
                className={classes.editorLabel}
                htmlFor="new-invoicingprofile-form-contractor_report_jinja"
              >
                {t("contractorReportJinjaLabel")}
              </InputLabel>
              <Field
                id="new-invoicingprofile-form-contractor_report_jinja"
                type="hidden"
                name="contractor_report_jinja"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="html"
                  options={{
                    extraEditorClassName:
                      "new-invoicingprofile-form-contractor_report_jinja"
                  }}
                  value={initialValues.contractor_report_jinja || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("contractor_report_jinja", value);
                    }
                  }}
                />
              </div>

              <Field
                id="invoicingprofile-form-from_email"
                type="text"
                name="from_email"
                label={t("fromEmailLabel")}
                placeholder={t("fromEmailLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="invoicingprofile-form-bcc_email"
                type="text"
                name="bcc_email"
                label={t("bccEmailLabel")}
                placeholder={t("bccEmailLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="invoicingprofile-form-invoice_email_subject_jinja"
                type="text"
                name="invoice_email_subject_jinja"
                label={t("invoiceEmailSubjectJinjaLabel")}
                placeholder={t("invoiceEmailSubjectJinjaLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <InputLabel
                className={classes.editorLabel}
                htmlFor="new-invoicingprofile-form-invoice_email_body_jinja"
              >
                {t("invoiceEmailBodyJinjaLabel")}
              </InputLabel>
              <Field
                id="new-invoicingprofile-form-invoice_email_body_jinja"
                type="hidden"
                name="invoice_email_body_jinja"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="html"
                  options={{
                    extraEditorClassName:
                      "new-invoicingprofile-form-invoice_email_body_jinja"
                  }}
                  value={initialValues.invoice_email_body_jinja || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("invoice_email_body_jinja", value);
                    }
                  }}
                />
              </div>

              <Field
                id="new-invoicingprofile-form-default_mail_message_text"
                type="text"
                name="default_mail_message_text"
                label={t("defaultMailMessageTextLabel")}
                placeholder={t("defaultMailMessageTextLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="6"
                fullWidth
              />

              <Field
                id="new-invoicingprofile-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-invoicingprofile-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {invoicingProfile
                  ? t("saveButtonLabel")
                  : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditInvoicingProfile);
