import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  SelectFieldOption,
  ProspectForm,
  COMPLEXITY,
  CUSTOMER_OBJECT_TYPE,
  PASSCARD_TYPE,
  REPORT_INTERVAL,
  VULNERABILITY,
  mainRootState
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";
import { useTranslate } from "../../../services/appLanguageService";

interface CreateProspectButtonContainerProps {
  loadBusinessareas: () => void;
  createProspect: (businessareaId: number) => void;
  businessareasOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps {
  loadBusinessareas: () => void;
  createProspect: (businessareaId: number) => void;
}

const CreateProspectButtonContainer: React.FC<
  CreateProspectButtonContainerProps
> = (props) => {
  const { loadBusinessareas, businessareasOptions } = props;

  const t = useTranslate("NewProspectButton");

  useEffect(() => loadBusinessareas(), [loadBusinessareas]);

  return businessareasOptions.length > 0 ? (
    <Button
      id="prospects-new-prospect"
      variant="contained"
      color="primary"
      onClick={() => props.createProspect(businessareasOptions[0].value)}
    >
      <AddIcon /> {t("newProspectLabel")}
    </Button>
  ) : (
    <></>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  createProspect: (businessareaId: number) => {
    const initialValues = {
      geo_polygons: null,
      customer_object_type: "PROPERTY_USER" as CUSTOMER_OBJECT_TYPE,
      passcard_type: "PASSCARD" as PASSCARD_TYPE,
      report_interval: "MONTHLY" as REPORT_INTERVAL,
      complexity: "MEDIUM" as COMPLEXITY,
      vulnerability: "MEDIUM" as VULNERABILITY,
      task_options: [],
      persons: [],
      customer_name: "",
      customer_attention: "",
      customer_organisation_number: "",
      customer_notes: "",
      customer_object_name: `Prospekt ${format(
        new Date(),
        "yyyy-MM-dd HH:mm:ss"
      )}`,
      work_description: "",
      task_notes: "",
      weekday_opening: "07:00",
      weekday_closing: "16:00",
      saturday_opening: "07:00",
      saturday_closing: "16:00",
      sunday_opening: "07:00",
      sunday_closing: "16:00",
      special: "",
      risk_description: "",
      businessarea: businessareaId,
      customer: null,
      customer_location: null,
      customer_object_location: null,
      submit_button: ""
    };

    dispatch(
      RestActions.restRequestWithData<ProspectForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.PROSPECT,
        "contracts/prospects/",
        initialValues,
        undefined,
        `/contracts/prospects/:id`
      )
    );
  },
  loadBusinessareas: () => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.BUSINESSAREAS,
        `contracts/businessareas/?page=1&page_size=10&include=["id","name"]`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  businessareasOptions: state.tabStates[
    ownProps.tabId
  ].businessAreas.businessAreas.results.map((businessarea) => ({
    label: businessarea.name,
    value: businessarea.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProspectButtonContainer);
