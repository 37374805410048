import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { FormikSubmitDispatchProps, GroupForm } from "../../redux/types";
import GroupNewForm from "../../components/groups/GroupNewEditForm";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings } from "../../redux/strings";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface NewGroupContainerProps extends FormikSubmitDispatchProps<GroupForm> {}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<GroupForm> {}

const NewGroupContainer: React.FC<NewGroupContainerProps> = (props) => {
  return (
    <GroupNewForm
      handleSubmit={props.handleSubmit}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<GroupForm>(
        ownProps.tabId,
        RestStrings.POST,
        "GROUP",
        "auth/groups/",
        values,
        "/auth/groups?same_tab=true"
      )
    );
  }
});

export default connect(undefined, mapDispatchToProps)(NewGroupContainer);
