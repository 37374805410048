import { BusinessAreasState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: BusinessAreasState = {
  businessAreas: {
    results: [],
    next: null,
    previous: null,
    count: 0
  },
  businessAreaOptions: [],
  activeContractorsBusinessareas: [],
  businessArea: {
    businessArea: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      notes: "",
      invoicingprofile: -1,
      contractingprofile: null
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.BUSINESSAREAS
    ):
      return {
        ...state,
        businessAreas: {
          ...state.businessAreas,
          results: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.BUSINESSAREA_OPTIONS
    ):
      return {
        ...state,
        businessAreaOptions: action.payload.results
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ACTIVE_CONTRACTOR_BUSINESSAREAS
    ):
      return {
        ...state,
        activeContractorsBusinessareas: action.payload.results
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.BUSINESSAREA
    ):
      return {
        ...state,
        businessArea: {
          businessArea: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.BUSINESSAREA
    ):
      return {
        ...state,
        businessAreas: {
          ...state.businessAreas,
          results: [...state.businessAreas.results, action.payload]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.BUSINESSAREA
    ):
      return {
        ...state,
        businessAreas: {
          ...state.businessAreas,
          results: [
            ...state.businessAreas.results.map((businessArea) =>
              businessArea.id === action.payload.id
                ? action.payload
                : businessArea
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
