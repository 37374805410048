import React, { ChangeEvent, useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  getAllServiceCategories,
  getBaseServicesAPI,
  createBaseServiceAPI
} from "../../../services/api-declaration";
import {
  BaseService,
  BaseServicesFilteredResponse,
  SelectFieldOption
} from "../../../redux/types";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  DialogActions,
  InputLabel
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useTranslate } from "../../../services/appLanguageService";
import { niceAmount } from "../../FormatHelpers";
import LoadingSpinner from "../../LoadingSpinner";
import * as Yup from "yup";
import { sanitizePrice } from "../../../helpers/globalHelper";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import ImageContainer from "../../../containers/ImageContainer";
import SearchField from "../../SearchField";

const styles = (theme: any) =>
  createStyles({
    root: {
      marginTop: "20px"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h6": {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
      }
    },
    bold: {
      fontWeight: "bold"
    },
    addButton: {
      marginRight: theme.spacing(2)
    },
    addCategoryButton: {
      marginTop: 25
    },
    select: {
      margin: 10
    },
    deleteIcon: {
      color: "Crimson"
    },
    deleteColumn: {
      textAlign: "right"
    },
    categoryTable: {
      margin: 16,
      width: "94%"
    },
    smallContainer: {
      flexGrow: 1
    },
    title: {
      flexGrow: 14
    },
    dialogContent: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    }
  });

interface BaseServicesProps extends WithStyles<typeof styles> {}

interface AddForm {
  name: string;
  price: string;
  unit: string;
  categories: number[];
}

const BaseServices: React.FC<BaseServicesProps> = (props) => {
  const { classes } = props;

  const pageSize = 20;
  const [baseServicesResponse, setBaseServicesResponse] =
    useState<BaseServicesFilteredResponse>({
      results: [],
      count: 0,
      next: null,
      previous: null
    });
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addForm, setAddForm] = useState<AddForm>({
    name: "",
    price: "0",
    unit: "",
    categories: []
  });
  const [servicecategoryOptions, setServicecategoryOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [reload, setReload] = useState(false);
  const [formValidation, setFormValidation] = useState<{
    [key: string]: {
      error: boolean;
      errorText: string;
    };
  }>({});
  const t = useTranslate("BaseServicesPage");
  const t2 = useTranslate("BaseServices");
  const t3 = useTranslate("ValidationErrorMessages");

  useEffect(() => {
    let isAlive = true;
    setIsLoading(true);
    getBaseServicesAPI(pageIndex + 1, pageSize, searchTerm).then((response) => {
      if (isAlive) {
        setBaseServicesResponse(response);
        setIsLoading(false);
        setReload(false);
      }
    });

    return () => {
      isAlive = false;
    };
  }, [pageIndex, pageSize, reload, searchTerm]);

  const handleSearch = (s: string) => setSearchTerm(s);

  const createBaseService = async () => {
    createBaseServiceAPI({
      name: addForm.name,
      status: "ACTIVE",
      base_materials: [],
      base_accessories: [],
      base_supplements: [],
      image: null,
      price: +addForm.price,
      unit_divider: 1,
      has_many: true,
      is_inanimate: false,
      vat_percentage: 25,
      material_transport: false,
      length: null,
      width: null,
      height: null,
      weight: null,
      horsepower: null,
      year: null,
      driver_demand: null,
      multiple_drivers: false,
      emission_standard: null,
      reg_number: null,
      automatic_transport: false,
      unit: addForm.unit,
      notes: null,
      description: null,
      categories: addForm.categories,
      is_accompanying_person_allowed: false
    }).then(() => {
      clearDialog();
      setReload(true);
    });
  };

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(1, t3("minLengthError"))
      .max(255, t3("max255LengthError")),
    price: Yup.number()
      .typeError(t3("numberRequiredError"))
      .min(0, t3("minZeroError"))
      .max(10000000, t3("max10000000NumberError")),
    unit: Yup.string()
      .min(1, t3("minLengthError"))
      .max(10, t3("max10LengthError")),
    categories: Yup.array().min(1, t("requiredError"))
  });

  const validate = (value: string, field: keyof AddForm) => {
    schema
      .validate({ [field]: value })
      .then((valid: any) => {
        setFormValidation((fv) => ({
          ...fv,
          [field]: {
            error: false,
            errorText: ""
          }
        }));
      })
      .catch((e: any) => {
        setFormValidation((fv) => ({
          ...fv,
          [field]: {
            error: true,
            errorText: e.errors.join()
          }
        }));
      });
  };

  const formInvalid =
    Object.values(formValidation)
      .map((fv) => fv.error)
      .includes(true) ||
    addForm.name === "" ||
    addForm.categories.length === 0 ||
    addForm.unit === "";

  useEffect(() => {
    let isAlive = true;
    (async () => {
      const response = await getAllServiceCategories();

      if (isAlive) {
        setServicecategoryOptions(
          response.results.map((category) => ({
            label: category.name,
            value: category.id
          }))
        );
      }
    })();

    return () => {
      isAlive = false;
    };
  }, []);

  const clearDialog = () => {
    setDialogOpen(false);
    setAddForm({ name: "", price: "0", unit: "", categories: [] });
    setFormValidation({});
    setSelectedCategory(-1);
  };

  const hideImageColumn =
    baseServicesResponse.results.filter((s) => s.image !== null).length === 0;

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.title} variant="h6">
          {t("baseServiceHeadingText")}
        </Typography>
        <div className={classes.smallContainer}>
          <SearchField handleSearch={handleSearch} />
        </div>
        <Button
          data-cy="add-baseservice"
          variant="contained"
          startIcon={<Add />}
          className={classes.addButton}
          onClick={() => setDialogOpen(true)}
        >
          {t("addLabel")}
        </Button>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              {!hideImageColumn && <TableCell></TableCell>}
              <TableCell className={classes.bold}>{t("nameLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("priceLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("unitLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("statusLabel")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {baseServicesResponse.results.map((baseService) => (
              <TableRow key={baseService.id}>
                {!hideImageColumn && (
                  <TableCell>
                    <ImageContainer type="thumbnail" src={baseService.image} />
                  </TableCell>
                )}
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/contracts/baseservices/${baseService.id}`}
                  >
                    {baseService.name}
                  </Link>
                </TableCell>
                <TableCell>{niceAmount(baseService.price)}</TableCell>
                <TableCell>{baseService.unit}</TableCell>
                <TableCell>{t2(baseService.status)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[pageSize]}
                count={baseServicesResponse.count}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(_, page) => setPageIndex(page)}
              />
            </TableRow>
          </TableBody>
        </Table>
      )}
      <Dialog open={dialogOpen} onClose={clearDialog} fullWidth>
        <DialogTitle>{t("addBaseServiceLabel")}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div>
            <InputLabel htmlFor="name">{t("nameLabel")}</InputLabel>
            <TextField
              name="name"
              autoComplete="off"
              data-cy="baseservice-form-name"
              value={addForm.name}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                validate(event.target.value, "name");
                setAddForm((f) => ({ ...f, name: event.target.value }));
              }}
              error={formValidation.name?.error}
              helperText={formValidation.name?.errorText}
              fullWidth
            />
          </div>

          <div>
            <InputLabel htmlFor="price">{t("priceLabel")}</InputLabel>
            <TextField
              name="price"
              autoComplete="off"
              data-cy="baseservice-form-price"
              value={addForm.price}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const sanitizedValue = sanitizePrice(event.target.value);
                validate(sanitizedValue, "price");
                setAddForm((f) => ({ ...f, price: sanitizedValue }));
              }}
              error={formValidation.price?.error}
              helperText={formValidation.price?.errorText}
              fullWidth
            />
          </div>

          <div>
            <InputLabel htmlFor="unit">{t("unitLabel")}</InputLabel>
            <TextField
              name="unit"
              autoComplete="off"
              data-cy="baseservice-form-unit"
              value={addForm.unit}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                validate(event.target.value, "unit");
                setAddForm((f) => ({ ...f, unit: event.target.value }));
              }}
              error={formValidation.unit?.error}
              helperText={formValidation.unit?.errorText}
              fullWidth
            />
          </div>

          <Autocomplete
            data-cy="baseservice-form-categories"
            value={
              servicecategoryOptions.find(
                (option) => option.value === selectedCategory
              ) || {
                label: "",
                value: 0
              }
            }
            onChange={(
              event: any,
              selectedOption: SelectFieldOption | null
            ) => {
              if (selectedOption && selectedOption.value !== -1) {
                setAddForm((f) => ({
                  ...f,
                  categories: [...f.categories, selectedOption.value]
                }));
              }
              setSelectedCategory(-1);
            }}
            options={servicecategoryOptions.filter(
              (sc) => !addForm.categories.includes(sc.value)
            )}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label={t("servicecategoryLabel")} />
            )}
          />

          <Table size="small" className={classes.categoryTable}>
            <TableBody>
              {servicecategoryOptions
                .filter((option) => addForm.categories.includes(option.value))
                .map((option, index) => (
                  <TableRow key={option.value}>
                    <TableCell>{option.label}</TableCell>
                    <TableCell className={classes.deleteColumn}>
                      <IconButton
                        data-cy={`baseservice-form-categories-delete-${index}`}
                        size="small"
                        onClick={() => {
                          setAddForm((f) => ({
                            ...f,
                            categories: f.categories.filter(
                              (c) => c !== option.value
                            )
                          }));
                        }}
                      >
                        <DeleteIcon className={classes.deleteIcon} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" onClick={clearDialog}>
            {t("cancelLabel")}
          </Button>
          <Button
            data-cy="baseservice-form-create-button"
            disabled={formInvalid}
            variant="contained"
            onClick={() => {
              createBaseService();
              setDialogOpen(false);
            }}
          >
            {t("createLabel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default withStyles(styles)(BaseServices);
