import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Permission, Group, User } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import Permissions from "../../components/groups/Permissions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";

interface PermissionsContainerProps {
  permissionsHolder: Group | User;
  permissionIds: number[];
  groupPermissions: Permission[];
  userPermissions: Permission[];
  loadPermissions: () => void;
  handleOnChange: (values: any) => void;
  handleOnDelete: (values: any) => void;
  handleOnAdd: (values: any) => void;
  editable: boolean;
}

interface mapDispatchToPropsProps {
  loadPermissions: () => void;
  handleOnChange: (values: any) => void;
  handleOnDelete: (values: any) => void;
  handleOnAdd: (values: any) => void;
}

const PermissionsContainer: React.FC<PermissionsContainerProps> = (props) => {
  const { permissionIds, loadPermissions } = props;
  const filterStr =
    permissionIds.length !== 1 ? "filter__id__in" : "filter__id";
  const permissionIdsStr = permissionIds.length ? permissionIds.toString() : "";
  useEffect(
    () => loadPermissions(),
    [loadPermissions, filterStr, permissionIdsStr]
  );

  let usedPermissions: Permission[] = [];
  let availablePermissions: Permission[] = [];

  const permissions: Permission[] = (props.permissionsHolder as User).username
    ? props.userPermissions
    : props.groupPermissions;

  for (let i = 0; permissions && i < permissions.length; i += 1) {
    if (props.permissionIds.includes(permissions[i].id)) {
      usedPermissions.push(permissions[i]);
    } else {
      availablePermissions.push(permissions[i]);
    }
  }

  return (
    <Permissions
      editable={props.editable}
      permissionIds={props.permissionIds}
      handleSubmit={() => {}}
      handleOnChange={props.handleOnChange}
      handleOnDelete={props.handleOnDelete}
      handleOnAdd={props.handleOnAdd}
      availablePermissions={availablePermissions}
      usedPermissions={usedPermissions}
      permissionsHolder={props.permissionsHolder}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  loadPermissions: () => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERMISSIONS,
        "auth/permissions/"
      )
    );
  },
  handleOnAdd: (values) => {
    const checkedNullValues = {
      permissions: [...values.permissions, values.permission_id]
    };
    const type = (values.permissionsHolder as User).username
      ? ReduxActionStrings.USER
      : ReduxActionStrings.GROUP;

    const url = `auth/${
      type === ReduxActionStrings.GROUP ? "groups" : "users"
    }/${values.permissionsHolder.id}/`;

    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        type,
        url,
        checkedNullValues
      )
    );
  },
  handleOnChange: (values: any) => {
    let checkedNullValues = {};
    if (values.permissions.includes(values.permission_id)) {
      checkedNullValues = {
        permissions: values.permissions.filter(
          (id: number) => id !== values.permission_id
        )
      };
    } else {
      checkedNullValues = {
        permissions: [...values.permissions, values.permission_id]
      };
    }

    const type = (values.permissionsHolder as User).username
      ? ReduxActionStrings.USER
      : ReduxActionStrings.GROUP;
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        type,
        `auth/${type === ReduxActionStrings.GROUP ? "groups" : "users"}/${
          values.permissionsHolder.id
        }/`,
        checkedNullValues
      )
    );
  },
  handleOnDelete: (values: any) => {
    let checkedNullValues = {};

    checkedNullValues = {
      permissions: values.permissions.filter(
        (id: number) => !values.permissionIdsToRemove.includes(id)
      )
    };

    const type = (values.permissionsHolder as User).username
      ? ReduxActionStrings.USER
      : ReduxActionStrings.GROUP;
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        type,
        `auth/${type === ReduxActionStrings.GROUP ? "groups" : "users"}/${
          values.permissionsHolder.id
        }/`,
        checkedNullValues
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  groupPermissions: state.tabStates[ownProps.tabId].groups.group.permissions,
  userPermissions: state.tabStates[ownProps.tabId].users.user.permissions
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsContainer);
