import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import * as AuthenticationActions from "../redux/reducers/authentication/actions";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";

interface LogoutContainerProps {
  handleClick: () => void;
  buttonLabel: string;
  closeMenu?: () => void;
}

const LogoutContainer: React.FC<LogoutContainerProps> = (props) => {
  const { closeMenu, handleClick } = props;

  return (
    <MenuItem
      id="navbar-logout"
      onClick={() => {
        if (closeMenu) {
          closeMenu();
        }
        handleClick();
      }}
    >
      <ListItemIcon>
        <PowerSettingsNewIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={props.buttonLabel} />
    </MenuItem>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleClick: () => {
    dispatch(AuthenticationActions.logout());
  }
});

export default connect(null, mapDispatchToProps)(LogoutContainer);
