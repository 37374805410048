import { combineReducers } from "redux";
import users from "./users/reducer";
import notificationsProfiles from "./notificationsProfiles/reducer";
import persons from "./persons/reducer";
import groups from "./groups/reducer";
import companies from "./companies/reducer";
import contractingProfiles from "./contractingProfiles/reducer";
import invoiceRecipients from "./invoicerecipients/reducer";
import holidays from "./holidays/reducer";
import passcards from "./passcards/reducer";
import districts from "./districts/reducer";
import historyView from "./history/reducer";
import weatherAreas from "./weatherAreas/reducer";
import invoicingProfiles from "./invoicingProfiles/reducer";
import businessAreas from "./businessAreas/reducer";
import projects from "./projects/reducer";
import cooperations from "./cooperations/reducer";
import prospects from "./prospects/reducer";
import templateImages from "./templateimages/reducer";
import terms from "./terms/reducer";
import routes from "./routes/reducer";
import tasks from "./tasks/reducer";
import contracts from "./contracts/reducer";
import serviceCategories from "./serviceCategories/reducer";
import taskOptions from "./taskOptions/reducer";
import customerObjects from "./customerObjects/reducer";
import loadingBooleans from "./loadingBooleans/reducer";

const apiReducer = combineReducers({
  users,
  notificationsProfiles,
  persons,
  groups,
  companies,
  passcards,
  districts,
  historyView,
  weatherAreas,
  holidays,
  contractingProfiles,
  invoicingProfiles,
  invoiceRecipients,
  businessAreas,
  projects,
  cooperations,
  prospects,
  templateImages,
  terms,
  routes,
  tasks,
  contracts,
  serviceCategories,
  taskOptions,
  customerObjects,
  loadingBooleans
});

export default apiReducer;
