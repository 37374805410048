import React, { FC } from "react";
import { Box, withStyles, createStyles, WithStyles } from "@material-ui/core";
import { LayoutRoute, TabComponentProps } from "../../redux/types";

const styles = (theme: any) =>
  createStyles({
    boxRoot: {
      padding: `${theme.spacing(1.5)}px 0`,
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px"
      }
    }
  });

interface TabPanelProps extends WithStyles<typeof styles> {
  key: number;
  tabRoute: LayoutRoute;
  selectedTab: LayoutRoute;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const {
    classes,
    selectedTab,
    tabRoute: {
      tabId,
      subTabIndex,
      suspendable,
      routeParams,
      urlQueryParams,
      actualPath,
      component
    }
  } = props;
  const RouteComponent: FC<TabComponentProps<any>> = component;
  const isSelected = tabId === selectedTab.tabId;
  const isSuspended = !isSelected && suspendable;

  return (
    <div
      role="tabpanel"
      hidden={!isSelected}
      id={`main-tabpanel-${tabId}`}
      className={"tab-panel-content-view"}
      aria-labelledby={`simple-tab-${tabId}`}
    >
      <Box p={3} className={classes.boxRoot}>
        {!isSuspended ? (
          <RouteComponent
            tabId={tabId}
            subTabIndex={subTabIndex}
            suspendable={Boolean(suspendable)}
            routeParams={routeParams}
            urlQueryParams={urlQueryParams}
            actualPath={actualPath}
          />
        ) : null}
      </Box>
    </div>
  );
};

export default withStyles(styles)(TabPanel);
