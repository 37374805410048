import { Action, RootState } from "../../../types";
import apiReducer from "../../apiReducer";

const initialState: Record<number, RootState> = {};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case "DELETE_TAB":
      delete state[action.tabId as number];
      return {
        ...state
      };
    default:
      if (action.tabId) {
        const newTabState = apiReducer(state[action.tabId], action);
        if (newTabState !== state[action.tabId]) {
          return {
            ...state,
            [action.tabId]: newTabState
          };
        }
      }
      return state;
  }
}

export default reducer;
