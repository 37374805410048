import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  ContractForm,
  FormikSubmitDispatchProps,
  mainRootState,
  SelectFieldOption
} from "../../../redux/types";
import ContractNewForm from "../../../components/contracts/contracts/ContractNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { format } from "date-fns";

interface NewContractContainerProps
  extends FormikSubmitDispatchProps<ContractForm> {
  loadCompanyPersons: (companyId: number) => void;
  personOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<ContractForm> {
  loadCompanyPersons: (companyId: number) => void;
}

const NewContractContainer: React.FC<NewContractContainerProps> = (props) => {
  return (
    <ContractNewForm
      handleSubmit={props.handleSubmit}
      personOptions={props.personOptions}
      loadCompanyPersons={props.loadCompanyPersons}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values,
      valid_from: values.valid_from
        ? format(new Date(values.valid_from), "yyyy-MM-dd")
        : null,
      valid_to: values.valid_to
        ? format(new Date(values.valid_to), "yyyy-MM-dd")
        : null
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<ContractForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.CONTRACT,
        "contracts/contracts/",
        checkedNullValues,
        undefined,
        "/contracts/contracts/?same_tab=true"
      )
    );
  },
  loadCompanyPersons: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSONS,
        `auth/persons/?filter__company_id=${companyId}`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  personOptions: state.tabStates[ownProps.tabId].persons.persons.persons.map(
    (person) => ({
      label: `${person.first_name} ${person.last_name || ""}`,
      value: person.id
    })
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewContractContainer);
