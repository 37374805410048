import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, Button } from "@material-ui/core";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useTranslate } from "../../../services/appLanguageService";

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    }
  });

interface RouteInstanceProps extends WithStyles<typeof styles> {
  routeinstanceId: number;
}

const RouteInstance: React.FC<RouteInstanceProps> = ({ routeinstanceId }) => {
  const t = useTranslate("TimesheetApprovalPage");
  const [paramId, setParamId] = useState<number | null>(null);
  useEffect(() => {
    if (routeinstanceId) {
      setParamId(routeinstanceId);
    }
  }, [routeinstanceId]);
  return (
    <div>
      <Button
        id="routeinstance-to-routeinstances"
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/orders/routeinstances${
                paramId ? `/${paramId}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon />
        {paramId ? t("routeInstanceBackButton") : t("routeInstancesBackButton")}
      </Button>
    </div>
  );
};

export default withStyles(styles)(RouteInstance);
