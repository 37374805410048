import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  mainRootState,
  Task,
  TaskForm,
  SelectFieldOption,
  Customerobject,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import TaskFormComponent from "../../../components/contracts/tasks/TaskForm";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";

interface TaskEditFormContainerProps
  extends FormikSubmitDispatchProps<Task | TaskForm>,
    TabComponentProps<"taskId">,
    mapDispatchToPropsProps {
  task: Task;
  customerObject: Customerobject;
  contractorContracts: SelectFieldOption[];
  loadingTask: boolean;
  businessAreaOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Task | TaskForm> {
  loadTask: (taskId: number) => void;
  loadBusinessAreas: (searchTerm?: string) => void;
  loadCustomerObject: (customerObjectId: number) => void;
}

const TaskEditFormContainer: React.FC<TaskEditFormContainerProps> = (props) => {
  const {
    loadTask,
    routeParams: { taskId },
    loadingTask,
    loadCustomerObject,
    loadBusinessAreas,
    task
  } = props;

  useEffect(() => loadTask(taskId), [taskId, loadTask]);
  useEffect(
    () => loadCustomerObject(task.customerobject),
    [task.customerobject, loadCustomerObject]
  );
  useEffect(() => loadBusinessAreas(), [loadBusinessAreas]);

  return loadingTask ? (
    <LoadingSpinnerPaper />
  ) : (
    <TaskFormComponent
      handleSubmit={props.handleSubmit}
      task={props.task}
      customerObject={props.customerObject}
      businessAreaOptions={props.businessAreaOptions}
      loadBusinessAreas={props.loadBusinessAreas}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const { geo_polygons_image, ...valuesWithoutImage } = values;

    const checkedNullValues = {
      ...valuesWithoutImage,
      notes: values.notes === "" ? null : values.notes,
      work_description:
        values.work_description === "" ? null : values.work_description
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.TASK,
        `contracts/tasks/${(checkedNullValues as Task).id}/`,
        checkedNullValues,
        `/contracts/tasks/${(checkedNullValues as Task).id}?same_tab=true`
      )
    );
  },
  loadTask: (taskId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TASK,
        `contracts/tasks/${taskId}/`
      )
    );
  },
  loadBusinessAreas: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.BUSINESSAREAS,
        `contracts/businessareas/?page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerObject: (customerObjectId: number) => {
    if (customerObjectId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.CUSTOMEROBJECT,
          `contracts/customerobjects/${customerObjectId}/`
        )
      );
    }
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  task: state.tabStates[ownProps.tabId].tasks.task.task,
  loadingTask: state.tabStates[ownProps.tabId].loadingBooleans.loadingTask,
  businessAreaOptions: state.tabStates[
    ownProps.tabId
  ].businessAreas.businessAreas.results.map((businessArea) => ({
    label: businessArea.name,
    value: businessArea.id
  })),
  customerObject:
    state.tabStates[ownProps.tabId].customerObjects.customerobject
      .customerobject
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskEditFormContainer);
