import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  WithStyles
} from "@material-ui/core";
import { Done as DoneIcon, Clear as ClearIcon } from "@material-ui/icons";
import { fetchPatch } from "../services/fetchWrapper";
import { restUrl } from "../services/api-declaration";
import { useTranslate } from "../services/appLanguageService";

const styles = (theme: any) =>
  createStyles({
    readOnly: {
      paddingBottom: 2,
      borderBottom: "dashed 1px #0088cc",
      cursor: "pointer"
    },
    addLabel: {
      color: "grey",
      fontStyle: "italic",
      cursor: "pointer"
    },
    icon: {
      fontSize: "20px"
    }
  });

interface EditableSimpleSelectFieldProps extends WithStyles<typeof styles> {
  field: string;
  initValue: string | number | null;
  url: string;
  updateResourceState: (newValue: any) => void;
  options: { value: number | string; label: string }[];
  translateFunction?: (value: string) => string;
}

const EditableSimpleSelectField: React.FC<EditableSimpleSelectFieldProps> = (
  props
) => {
  const { classes, options } = props;

  const [value, setValue] = useState<string | number | null>(props.initValue);
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const t = useTranslate("EditableFieldComponent");

  const updateResource = async () => {
    setLoading(true);
    const response = await fetchPatch(restUrl(props.url), {
      [props.field]: value
    });
    setLoading(false);
    props.updateResourceState(response);
  };

  const generateLabel = () => {
    if (value === null) {
      return t("addLabel");
    }
    if (props.translateFunction) {
      return props.translateFunction(value as string);
    }
    return value;
  };

  return loading ? (
    <CircularProgress size={18} />
  ) : !edit ? (
    <span
      data-cy={`${props.field}-field`}
      className={value !== null ? classes.readOnly : classes.addLabel}
      onClick={() => setEdit(true)}
    >
      {generateLabel()}
    </span>
  ) : (
    <>
      <Select
        data-cy={`${props.field}-field`}
        value={value}
        onChange={(event: any) => setValue(event.target.value)}
      >
        {options.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
      <IconButton
        data-cy={`${props.field}-save-button`}
        size="small"
        aria-label="Save"
        onClick={() => {
          updateResource();
          setEdit(false);
        }}
      >
        <DoneIcon className={classes.icon} style={{ color: "#006400" }} />
      </IconButton>
      <IconButton
        size="small"
        aria-label="Reset"
        onClick={() => {
          setEdit(false);
          setValue(props.initValue);
        }}
      >
        <ClearIcon className={classes.icon} style={{ color: "Crimson" }} />
      </IconButton>
    </>
  );
};

export default withStyles(styles)(EditableSimpleSelectField);
