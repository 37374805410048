import { InvoicingProfilesState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: InvoicingProfilesState = {
  invoicingProfiles: {
    invoicingProfiles: [],
    next: null,
    previous: null,
    count: 0
  },
  invoicingProfile: {
    invoicingProfile: {
      id: -1,
      created_at: "",
      updated_at: "",
      currency: "SEK",
      locale: "SV",
      name: "",
      default_vat_percentage: 0.0,
      notes: "",
      invoicing_css: "",
      customer_invoice_jinja: "",
      contractor_invoice_jinja: "",
      from_email: "",
      bcc_email: "",
      invoice_email_body_jinja: "",
      invoice_email_subject_jinja: "",
      default_mail_message_text: "",
      customer_report_jinja: "",
      contractor_report_jinja: "",
      invoicing_company: -1
    },
    customerInvoices: [],
    contractorInvoices: []
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.INVOICINGPROFILES
    ):
      return {
        ...state,
        invoicingProfiles: {
          ...state.invoicingProfiles,
          invoicingProfiles: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.INVOICINGPROFILE
    ):
      return {
        ...state,
        invoicingProfile: {
          ...state.invoicingProfile,
          invoicingProfile: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.INVOICINGPROFILE
    ):
      return {
        ...state,
        invoicingProfiles: {
          ...state.invoicingProfiles,
          invoicingProfiles: [
            ...state.invoicingProfiles.invoicingProfiles,
            action.payload
          ]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.INVOICINGPROFILE
    ):
      return {
        ...state,
        invoicingProfiles: {
          ...state.invoicingProfiles,
          invoicingProfiles: [
            ...state.invoicingProfiles.invoicingProfiles.map(
              (invoicingProfile) =>
                invoicingProfile.id === action.payload.id
                  ? action.payload
                  : invoicingProfile
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CUSTOMER_INVOICES
    ):
      return {
        ...state,
        invoicingProfile: {
          ...state.invoicingProfile,
          customerInvoices: action.payload.results
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTOR_INVOICES
    ):
      return {
        ...state,
        invoicingProfile: {
          ...state.invoicingProfile,
          contractorInvoices: action.payload.results
        }
      };

    default:
      return state;
  }
}

export default reducer;
