import React, { useCallback, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  GroupForm,
  Group,
  ImageRow,
  TabComponentProps
} from "../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { AutoCompleteSelectMultiNEW } from "../AutoCompleteSelect";
import { useTranslate } from "../../services/appLanguageService";
import { getImageByURL } from "../../services/imageHelperService";
import ImageUploadComponent from "../imageUploadComponent/ImageUploadComponent";
import { getSearchGroupOptionsAPI } from "../../services/api-declaration";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    notes: Yup.string().nullable().notRequired(),
    sub_groups: Yup.array().of(Yup.number().min(1)).notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    imageLabel: {
      marginTop: theme.spacing(3)
    }
  });

interface NewGroupProps
  extends FormikSubmitDispatchProps<GroupForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  group?: Group;
}

const NewEditGroup: React.FC<NewGroupProps> = (props) => {
  const { classes, group, tabId } = props;
  const t = useTranslate("GroupPage");
  const t3 = useTranslate("ValidationErrorMessages");
  const [images, setImages] = useState<ImageRow[]>([]);
  let initialValues: Group | GroupForm;
  if (group && (group as Group).id) {
    initialValues = {
      ...group
    };
  } else {
    initialValues = {
      name: "",
      notes: "",
      avatar: null,
      permissions: [],
      sub_groups: []
    };
  }

  const handleFormSubmit = (
    values: GroupForm,
    actions: FormikHelpers<GroupForm>
  ) => {
    let checkedValues: any;
    const imageStr = images.length ? images[0].imageStr : null;

    if (!imageStr || (imageStr && imageStr.includes("base64"))) {
      checkedValues = { ...values, avatar: imageStr };
    } else {
      const { avatar, ...other } = values;
      checkedValues = other;
    }
    props.handleSubmit(checkedValues, actions);
  };

  const getAvatar = async () => {
    if (group && group.avatar) {
      const imageStr = await getImageByURL(group.avatar);
      setImages([
        {
          imageStr
        }
      ]);
    }
  };
  const getAvatarCb = useCallback(getAvatar, [group]);
  useEffect(() => {
    getAvatarCb();
  }, [group, getAvatarCb]);
  useEffect(() => {
    if (group) {
      store.dispatch(setTitle(group.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/auth/groups${group ? `/${group.id}` : ""}?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {group ? t("editGroupHeading") : t("newGroupHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={handleFormSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="new-group-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="edit-group-form-groups"
                type="text"
                name="sub_groups"
                placeholder={t("subGroupsFieldsPlaceholder")}
                TextFieldProps={{
                  label: t("subGroupsFieldsLabel"),
                  InputLabelProps: {
                    htmlFor: "react-select-single",
                    shrink: true
                  }
                }}
                component={AutoCompleteSelectMultiNEW}
                margin="normal"
                fullWidth
                loadOptionsAPI={getSearchGroupOptionsAPI}
                selectedIds={group ? group.sub_groups : []}
              />

              <InputLabel className={classes.imageLabel}>
                {t("avatarLabel")}
              </InputLabel>
              <ImageUploadComponent
                images={images}
                onImageUpdate={setImages}
                singleImageMode
              />

              <Field
                id="new-group-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-group-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {group ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditGroup);
