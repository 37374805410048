import { FC, useState, useEffect, useCallback } from "react";
import { Paper, Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import * as Yup from "yup";
import store, { history } from "../../redux/store";
import { Formik, Field, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { INotificationProfile, TabComponentProps } from "../../redux/types";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import {
  getNotificationProfileAPI,
  editNotificationProfileAPI
} from "../../services/api-declaration";
import { useTranslate } from "../../services/appLanguageService";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";

interface NotificationProfileEditProps extends TabComponentProps<"id"> {}

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(t("requiredError"))
  });

const useStyles = makeStyles((theme: any) =>
  createStyles({
    navButton: {
      marginBottom: theme.spacing(2.5)
    },
    paper: {
      padding: theme.spacing(2.5)
    },
    form: {
      width: "100%"
    },
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5)
    }
  })
);

const defaultValues = {
  id: -1,
  name: "",
  notes: "",
  users: [],
  items: []
};

const EditNotificationProfile: FC<NotificationProfileEditProps> = (props) => {
  const {
    routeParams: { id: notificationProfileId },
    tabId
  } = props;
  const t = useTranslate("NotificationProfile");
  const u = useTranslate("Utility");
  const classes = useStyles();
  const [formValues, setFormValues] =
    useState<INotificationProfile>(defaultValues);
  const navigate = (id: number) => () => {
    history.replace(`/notification-profiles/${id}?same_tab=true`);
  };

  const initTimesheet = async (id: number) => {
    try {
      const response = await getNotificationProfileAPI(id);
      if (response) {
        setFormValues(response);
      }
    } catch (e: any) {
      showGlobalSnackbar(e.message, "error");
    }
  };

  const handleSubmit = async (
    values: INotificationProfile,
    actions: FormikHelpers<INotificationProfile>
  ) => {
    const submittedValues: INotificationProfile = { ...values };
    try {
      const response = await editNotificationProfileAPI(
        values.id,
        submittedValues
      );
      if (response) {
        history.replace(`/notification-profiles/${values.id}?same_tab=true`);
        showGlobalSnackbar(u("editedSuccessfullyMessage"), "success");
      }
    } catch (e: any) {
      showGlobalSnackbar(e.message, "error");
    }
  };

  const initCb = useCallback(initTimesheet, []);
  useEffect(() => {
    initCb(notificationProfileId);
  }, [initCb, notificationProfileId]);
  useEffect(() => {
    store.dispatch(setTitle(formValues.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        centerRipple
        onClick={navigate(formValues.id)}
        className={classes.navButton}
      >
        <NavigateBeforeIcon /> {t("backBtn")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {t("editHeading")}
        </Typography>
        <Formik
          initialValues={formValues}
          validationSchema={ValidationSchema(t)}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                data-cy="profile-name"
                id="new-profile-name"
                type="text"
                autoFocus
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              <Field
                data-cy="profile-notes"
                id="new-profile-notes"
                type="text"
                autoFocus
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              <Button
                id="edit-profile-submit"
                data-cy="profile-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={props.values.name === ""}
              >
                {t("saveButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default EditNotificationProfile;
