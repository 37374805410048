import { Material, SubmittedWork } from "../../../redux/types";
import { getContractMaterialsAPI } from "../../../services/api-declaration";
import Dictionary from "./Dictionary";

class MaterialDictionary extends Dictionary<Material> {
  public collectFromEnteries(
    corrections: Array<{
      materials: SubmittedWork["materials"];
    }>
  ) {
    corrections.forEach((correction) => {
      if (correction?.materials) {
        Object.keys(correction.materials).forEach((materialId) =>
          this.collectId(Number(materialId))
        );
      }
    });
  }

  public addTimesheetOptions(materials: Record<string, Material>) {
    Object.entries(materials).forEach(([materialId, material]) =>
      this.set(+materialId, material)
    );
  }

  public async fetchData() {
    const filter__id__in = this.getIdsToSync();
    if (filter__id__in.length !== 0) {
      const { results: materials } = await getContractMaterialsAPI({
        filter__id__in
      });
      materials.forEach((material) => {
        this.set(material.id, {
          name: material.name,
          unit: material.unit
        } as Material);
      });
      this.idsToSync.clear();
      return true;
    }
    return false;
  }
}

export default MaterialDictionary;
