import { TabSelectionState, Action } from "../../../types";
import { SELECT_TAB } from "./actionTypes";

const initialState: TabSelectionState = {
  selectedTab: undefined,
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case SELECT_TAB:
      return {
        selectedTab: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;
