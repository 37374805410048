import React from "react";
import { connect } from "react-redux";
import {
  LayoutRoute,
  mainRootState,
  VerificationUpdatedAtType
} from "../redux/types";
import NavbarDrawer from "../components/NavbarDrawer";

interface NavbarDrawerContainerProps {
  selectedTab?: LayoutRoute;
  verificationUpdatedAt?: VerificationUpdatedAtType;
}

const NavbarDrawerContainer: React.FC<NavbarDrawerContainerProps> = (props) => {
  const { selectedTab, verificationUpdatedAt } = props;
  return (
    <NavbarDrawer
      selectedTab={selectedTab}
      verificationUpdatedAt={verificationUpdatedAt as number}
    />
  );
};

const mapStateToProps = (state: mainRootState) => ({
  selectedTab: state.tabSelection.selectedTab,
  verificationUpdatedAt: state.verificationUpdatedAt
});

export default connect(mapStateToProps, null)(NavbarDrawerContainer);
