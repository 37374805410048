import React from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper
} from "@material-ui/core";
import { useTranslate } from "../../../services/appLanguageService";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    taskPricesIndicator: {
      width: 12,
      height: 12,
      marginRight: 1,
      borderRadius: 50,
      backgroundColor: red[500],
      display: "inline-block"
    },
    table: {
      border: "1px solid #888"
    },
    bold: {
      fontWeight: "bold"
    }
  })
);

type ContractItemsProps = {
  taskPrices: {
    businessareaName: string;
    taskName: string;
    price: number;
    unit: string;
    serviceCategoryName?: string;
  }[];
};

const TaskPricesIndicator: React.FC<ContractItemsProps> = ({ taskPrices }) => {
  const classes = useStyles();

  const t = useTranslate("ContractPage");
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "transparent",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 700
    }
  }))(Tooltip);

  return (
    <HtmlTooltip
      title={
        <TableContainer component={Paper} className={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.bold}>
                  {t("taskHeader")}
                </TableCell>
                <TableCell className={classes.bold}>
                  {t("serviceCategoryHeader")}
                </TableCell>
                <TableCell className={classes.bold}>
                  {t("priceLabel")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskPrices.map((taskPrice) => (
                <TableRow>
                  <TableCell>
                    {taskPrice.taskName} - {taskPrice.businessareaName}
                  </TableCell>
                  <TableCell>
                    {taskPrice.serviceCategoryName || t("allLabel")}
                  </TableCell>
                  <TableCell>{`${taskPrice.price} kr/${taskPrice.unit}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      interactive
    >
      <div>
        <div className={classes.taskPricesIndicator}></div>
      </div>
    </HtmlTooltip>
  );
};

export default TaskPricesIndicator;
