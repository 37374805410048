import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  CooperationForm,
  Cooperation,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import CooperationNewEditForm from "../../../components/contracts/cooperations/CooperationNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditCooperationContainerProps
  extends FormikSubmitDispatchProps<CooperationForm>,
    TabComponentProps<"cooperationId"> {
  cooperation: Cooperation;
  personOptions: SelectFieldOption[];
  userOptions: SelectFieldOption[];
  loadCooperation: (cooperationId: number) => void;
  loadPersons: (searchTerm?: string) => void;
  loadUsers: (searchTerm?: string) => void;
  loadingCooperation: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Cooperation | CooperationForm> {
  loadCooperation: (cooperationId: number) => void;
  loadPersons: (searchTerm?: string) => void;
  loadUsers: (searchTerm?: string) => void;
}

const EditCooperationContainer: React.FC<EditCooperationContainerProps> = (
  props
) => {
  const {
    loadCooperation,
    cooperation,
    routeParams: { cooperationId },
    loadPersons,
    loadUsers,
    loadingCooperation
  } = props;
  useEffect(
    () => loadCooperation(cooperationId),
    [loadCooperation, cooperationId]
  );
  useEffect(() => loadPersons(), [loadPersons]);
  useEffect(() => loadUsers(), [loadUsers]);

  return loadingCooperation ? (
    <LoadingSpinnerPaper />
  ) : (
    <CooperationNewEditForm
      cooperation={cooperation}
      personOptions={props.personOptions}
      userOptions={props.userOptions}
      handleSubmit={props.handleSubmit}
      loadPersons={props.loadPersons}
      loadUsers={props.loadUsers}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.COOPERATION,
        `contracts/cooperations/${(checkedNullValues as Cooperation).id}/`,
        checkedNullValues,
        `/contracts/cooperations/${
          (checkedNullValues as Cooperation).id
        }?same_tab=true`
      )
    );
  },
  loadCooperation: (cooperationId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COOPERATION,
        `contracts/cooperations/${cooperationId}/`
      )
    );
  },
  loadPersons: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSONS,
        `auth/persons/?page=1&page_size=10&include=["id","first_name", "last_name"]&filter__first_name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadUsers: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.USERS,
        `auth/users/?page=1&page_size=10&include=["id","username"]&filter__username__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  cooperation:
    state.tabStates[ownProps.tabId].cooperations.cooperation.cooperation,
  personOptions: state.tabStates[ownProps.tabId].persons.persons.persons.map(
    (person) => ({
      label: `${person.first_name} ${person.last_name}`,
      value: person.id
    })
  ),
  userOptions: state.tabStates[ownProps.tabId].users.users.users.map(
    (user) => ({
      label: user.username,
      value: user.id
    })
  ),
  loadingCooperation:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCooperation
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCooperationContainer);
