import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  CopyContractForm,
  mainRootState,
  SelectFieldOption,
  Contract
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import CopyContractDialog from "../../../components/contracts/contracts/CopyContractDialog";

interface EditContractContainerProps
  extends FormikSubmitDispatchProps<CopyContractForm> {
  contractId: number;
  open: boolean;
  handleDialog: () => void;
  loadCompanies: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => void;
  loadCompany: (companyId: number) => void;
  contractName?: string;
  contract?: Contract;
  companies: SelectFieldOption[];
  totalCompanyCount: number;
  company: SelectFieldOption;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<CopyContractForm> {
  loadCompanies: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => void;
  loadCompany: (companyId: number) => void;
  loadTerms: (page: number, pageSize: number) => void;
}

const EditContractContainer: React.FC<EditContractContainerProps> = (props) => (
  <CopyContractDialog
    contractId={props.contractId}
    handleSubmit={props.handleSubmit}
    handleDialog={props.handleDialog}
    contractName={props.contractName}
    loadCompanies={props.loadCompanies}
    open={props.open}
    totalCompanyCount={props.totalCompanyCount}
    contract={props.contract}
    companies={props.companies}
  />
);

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.CLONE_CONTRACT,
        `contracts/contracts/${values.id}/clone/`,
        checkedNullValues,
        undefined,
        "/contracts/contracts/"
      )
    );
  },
  loadCompanies: (page: number, pageSize: number, searchStr?: string) => {
    // const selectableTypes = [EnumStrings.CUSTOMER, EnumStrings.CONTRACTOR];

    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    // const archivedFilter = `&filter__status__in=[ACTIVE,LOCKED,DRAFT,FLAGGED${
    //   flags && flags.includes(EnumStrings.ARCHIVED)
    //     ? `,${EnumStrings.ARCHIVED}`
    //     : ""
    // }]`;

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?include=["id", "name", "districts", "company_type", "logo", "status"]&page=${page}&page_size=${pageSize}${searchFilter}`
      )
    );
  },
  loadCompany: (companyId: number) => {
    if (companyId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COMPANY,
          `companies/companies/${companyId}/`
        )
      );
    }
  },
  loadTerms: (page: number, pageSize: number, searchStr?: string) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TERMS,
        `contracts/terms/?page=${page}&page_size=${pageSize}${searchFilter}`
      )
    );
  }
});
const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  totalCompanyCount: state.tabStates[ownProps.tabId].companies.companies.count,
  companies: state.tabStates[ownProps.tabId].companies.companies.companies.map(
    (company) => ({
      label: company.name,
      value: company.id
    })
  ),
  company: {
    label: state.tabStates[ownProps.tabId].companies.companies.company.name,
    value: state.tabStates[ownProps.tabId].companies.companies.company.id
  },
  terms: state.tabStates[ownProps.tabId].terms.terms.terms.map((term) => ({
    label: term.name,
    value: term.id
  })),
  totalTermCount: state.tabStates[ownProps.tabId].terms.terms.count
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContractContainer);
