import React from "react";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Hidden, Drawer } from "@material-ui/core";
import AppHeader from "./AppHeader";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NavbarDrawerContainer from "../containers/NavbarDrawerContainer";

const drawerWidth = 260;

const styles = (theme: any) =>
  createStyles({
    drawerClosed: {
      width: 1,
      [theme.breakpoints.up("sm")]: {
        width: 40,
        flexShrink: 0
      }
    },
    drawer: {
      width: 0,
      transition: "all 1s cubic-bezier(0.25, 0.8, 0.25, 1)",
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
        transition: "all 1s cubic-bezier(0.25, 0.8, 0.25, 1)"
      }
    },
    drawerPaper: {
      width: drawerWidth,
      borderRightColor: "#6f6f6f",
      borderRightWidth: 2
    },
    drawerSS: {
      width: drawerWidth
    },
    title: {
      color: theme.palette.common.white
    },
    iconButton: {
      position: "fixed",
      left: 245,
      top: 90,
      backgroundColor: "white",
      color: "#424242",
      border: "2px solid #424242",
      zIndex: 1201,
      transition: "all 1s cubic-bezier(0.25, 0.8, 0.25, 1)",
      "&:hover": {
        backgroundColor: "#424242",
        color: "white"
      }
    },
    buttonLine: {
      height: "100%",
      width: 2,
      backgroundColor: "#6f6f6f",
      position: "fixed",
      zIndex: 999,
      left: 30
    }
  });

interface NavbarProps extends WithStyles<typeof styles> {}

const Navbar: React.FC<NavbarProps> = (props) => {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div>
      <AppHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <Hidden xsDown implementation="css">
        <div className={open ? "" : classes.buttonLine} />
        <IconButton
          className={classes.iconButton}
          style={open ? {} : { left: 15 }}
          size="small"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Hidden>
      <nav
        className={open ? classes.drawer : classes.drawerClosed}
        aria-label="Mailbox folders"
      >
        {mobileOpen ? (
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              <NavbarDrawerContainer />
            </Drawer>
          </Hidden>
        ) : (
          <Hidden xsDown implementation="css">
            <div>
              <Drawer
                className={classes.drawerSS}
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="persistent"
                open={open}
                transitionDuration={450}
              >
                <NavbarDrawerContainer />
              </Drawer>
            </div>
          </Hidden>
        )}
      </nav>
    </div>
  );
};

export default withStyles(styles)(Navbar);
