import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Paper,
  Typography,
  Link
} from "@material-ui/core";
import {
  Task,
  Contract,
  IContractSummaryItem,
  IContractSummaryType,
  TabComponentProps
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { EnumStrings } from "../../../redux/strings";
import store from "../../../redux/store";
import { ContractCooperationTable } from "../../../components/contracts/cooperations/CooperationTable";
import { ContractTaskTable } from "../../../components/contracts/tasks/ContractTaskTable";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  adjustContractPricesAPI,
  createContractItemAPI,
  deleteContractItemAPI,
  getAllServiceCategories,
  getBusinessareasAPI,
  getCompanyByIdAPI,
  getContractAPI,
  getContractItemsAPI,
  getContractTasksAPI,
  sortContractItemsAPI,
  updateContractAPI,
  updateContractItemAPI
} from "../../../services/api-declaration";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Add as AddIcon,
  FileCopy as FileCopyIcon,
  PictureAsPdf as PictureAsPdfIcon,
  NavigateBefore as NavigateBeforeIcon,
  LockOpen as LockOpenIcon,
  LockOutlined as LockOutlinedIcon
} from "@material-ui/icons";
import AddContractItemDialog from "./AddContractItemDialog";
import ConfirmationDialog from "../../confirmationDialog/ConfirmationDialog";
import ContractItemsTable from "./ContractItemsTable";
import ContractFieldsView from "./ContractFields";
import { contractItemTypeToApi } from "./contractItemTypeToAPI";
import CopyContractContainer from "../../../containers/contracts/contracts/CopyContractContainer";
import DeleteButtonContainer from "../../../containers/DeleteButtonContainer";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import IndexContractDialog from "./IndexContractDialog";
import { Link as RouterLink } from "react-router-dom";
import ReorderContractItemsDialog from "./ReorderContractItemsDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: "20px"
    },
    header: {
      margin: theme.spacing(2),
      "& > h5, & > h6": {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
      }
    },
    fields: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      display: "flex",
      "& > div": {
        margin: theme.spacing(1),
        flex: 1
      }
    },
    toolbar: {
      marginTop: "20px",
      display: "flex",
      justifyContent: "space-between",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(1)
    },
    toolbarGroup: {
      display: "flex",
      "& > div": {
        margin: "auto",
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
      }
    },
    dropDown: {
      backgroundColor: "lightgray",
      borderBottom: "none",
      width: "25rem",
      height: "55px",
      margin: theme.spacing(1),

      "& > div": {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
      },

      transition: "background-color 0.3s ease-in-out",
      "&:hover": {
        backgroundColor: "#E8E9EB"
      }
    },
    menuItem: {
      backgroundColor: "white",
      transition: "background-color 0.5s ease-in-out",
      "&:hover": {
        backgroundColor: "#D7D2CB"
      }
    },
    bold: {
      fontWeight: "bold"
    },
    btnBack: {
      minWidth: "auto",
      [theme.breakpoints.down("sm")]: {
        minWidth: 220
      }
    },
    unlockBtnLabel: {
      display: "flex",
      alignItems: "center"
    },
    btnIcon: { marginRight: theme.spacing(0.5) },
    btnContainer: {
      display: "flex",
      "& > div:last-child": {
        marginLeft: "auto"
      },
      "& > *:not(:first-child)": {
        marginLeft: theme.spacing(1)
      }
    },
    companyHeader: {
      fontSize: 14
    }
  })
);

interface ContractViewProps {
  tabId: number;
  contract: Contract;
  tasks: Task[];
  contractItems: IContractSummaryItem[];
  serviceCategoryNames: Map<number, string>;
  businessAreaNames: Map<number, string>;
  updateContract: (
    patch: Partial<Omit<Contract, "id" | "oca">>
  ) => Promise<void>;
  adjustContractPrices: (
    payload: Parameters<typeof adjustContractPricesAPI>[1]
  ) => Promise<void>;
  createContractItem: (
    payload: Parameters<typeof createContractItemAPI>[1]
  ) => Promise<void>;
  updateContractItem: (
    id: number,
    type: IContractSummaryType,
    patch: { display: boolean }
  ) => Promise<void>;
  deleteContractItem: (id: number, type: IContractSummaryType) => Promise<void>;
  saveSortOrder: (
    payload: Parameters<typeof sortContractItemsAPI>[1]
  ) => Promise<void>;
}

type ContractModals =
  | {
      m: "updateVisibility";
      itemId: number;
      type: IContractSummaryType;
      resolve: () => void;
    }
  | {
      m: "create";
      resolve: () => void;
    }
  | {
      m: "delete";
      itemId: number;
      type: IContractSummaryType;
      resolve: () => void;
      name?: string;
    }
  | {
      m: "copy";
      resolve: () => void;
    }
  | {
      m: "index";
      resolve: () => void;
    }
    | {
      m: "reorder-items";
      resolve: () => void;
    };

const ContractView: React.FC<ContractViewProps> = ({
  tabId,
  contract,
  tasks,
  contractItems,
  serviceCategoryNames,
  businessAreaNames,
  updateContract,
  adjustContractPrices,
  createContractItem,
  updateContractItem,
  deleteContractItem,
  saveSortOrder,
}) => {
  const [modal, setModal] = useState<ContractModals>();
  const [searchText, setSearchText] = useState("");
  const [selectedContractTaskId, setSelectedContractTaskId] = useState<
    number | "default" | "task_missing"
  >("default");

  const classes = useStyles();
  const t = useTranslate("ContractPage");
  const t2 = useTranslate("ViewActionButtons");
  const [companyName, setCompanyName] = useState<string>("");

  useEffect(() => {
    if (contract.company) {
      let alive = true;
      void getCompanyByIdAPI(contract.company).then(
        (companyResponse) => alive && setCompanyName(companyResponse.name)
      );
      return () => {
        alive = false;
      };
    }
  }, [contract.company]);

  const regEx = new RegExp(searchText, "i");
  const filteredItems = searchText
    ? contractItems?.filter((item) => {
        return regEx.test(item.name ?? "") || regEx.test(item.base_name);
      })
    : contractItems;

  const onSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (
      event.target.value === "default" ||
      event.target.value === "task_missing"
    ) {
      setSelectedContractTaskId(event.target.value);
    } else {
      setSelectedContractTaskId(event.target.value as number);
    }
  };

  const onDelete = (itemId: number, type: IContractSummaryType) =>
    new Promise<void>((resolve) =>
      setModal({ m: "delete", itemId, type, resolve })
    );
  const onAdd = () =>
    new Promise<void>((resolve) => setModal({ m: "create", resolve }));

  const onCopy = () =>
    new Promise<void>((resolve) => setModal({ m: "copy", resolve }));

  const onIndex = () =>
    new Promise<void>((resolve) => setModal({ m: "index", resolve }));

  const onReorderItems = () =>
    new Promise<void>((resolve) => setModal({ m: "reorder-items", resolve }));

  const onUpdateVisibility = (itemId: number, type: IContractSummaryType) =>
    new Promise<void>((resolve) =>
      setModal({
        m: "updateVisibility",
        itemId,
        type,
        resolve
      })
    );

  const modalItem =
    modal?.m === "delete" || modal?.m === "updateVisibility"
      ? contractItems.find(
          (ci) => ci.type === modal.type && ci.id === modal.itemId
        )
      : undefined;

  let taskSpecificPrices: {
    [itemId: number]: {
      businessareaName: string;
      taskName: string;
      price: number;
      unit: string;
      serviceCategoryName?: string;
    }[];
  } = {};

  const availableTaskIds = tasks.map((t) => t.id);

  const missingTaskContractItems = contractItems.filter(
    (item) => item.task_id && !availableTaskIds.includes(item.task_id)
  );

  contractItems.forEach((item) => {
    if (item.task_id) {
      return;
    }
    const commonBaseItems = contractItems.filter(
      (ci) =>
        ci.task_id !== null &&
        ci.type === item.type &&
        ci.base_id === item.base_id &&
        availableTaskIds.includes(ci.task_id)
    );
    if (!commonBaseItems.length) {
      return;
    }

    taskSpecificPrices[item.id] = commonBaseItems
      .map((ci) => {
        const task = tasks.find((t) => t.id === ci.task_id);

        return {
          businessareaName: task
            ? businessAreaNames.get(task.businessarea) ?? ""
            : "",
          taskName: task?.name || "",
          price: ci.price,
          unit: ci.unit,
          serviceCategoryName: ci.service_category_id
            ? serviceCategoryNames.get(ci.service_category_id)
            : undefined
        };
      })
      .sort((a, b) => a.taskName.localeCompare(b.taskName));
  });

  return (
    <>
      <div className={classes.btnContainer}>
        <Button
          className={classes.btnBack}
          id="contract-to-contracts"
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/contracts/contracts?same_tab=true"
        >
          <NavigateBeforeIcon /> {t2("contractNavigationButtonLabel")}
        </Button>
        {(contract.status === "ACTIVE" || contract.status === "ARCHIVED") && (
          <Button
            data-cy="unlock-contract"
            variant="contained"
            color="primary"
            onClick={() => updateContract({ status: "DRAFT" })}
            startIcon={<LockOpenIcon />}
          >
            {t2("unlockButtonLabel")}
          </Button>
        )}
        {contract.status === "DRAFT" && (
          <Button
            data-cy="contract-save-button"
            color="primary"
            variant="contained"
            startIcon={<LockOutlinedIcon />}
            onClick={() => void updateContract({ status: "ACTIVE" })}
          >
            {t("finalizeChangesBtnLabel")}
          </Button>
        )}
        <Button
          data-cy="preview-contract-offer"
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/contracts/contracts/${contract.id}/show-pdf`}
          startIcon={<PictureAsPdfIcon />}
        >
          {t2("previewButton")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onIndex}
          startIcon={<FileCopyIcon />}
        >
          {t("indexButtonLabel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onReorderItems}
          startIcon={<FileCopyIcon />}
        >
          {t("reorderButtonLabel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onCopy}
          startIcon={<FileCopyIcon />}
        >
          {t2("copyButtonLabel")}
        </Button>
        <div>
          <DeleteButtonContainer
            resourceId={contract.id}
            resource="CONTRACT"
            url="contracts/contracts"
            backendUrl="contracts/contracts"
            tabId={tabId}
          />
        </div>
      </div>
      <Paper className={classes.paper}>
        {companyName !== "" && contract.company && (
          <div className={classes.header}>
            <Typography variant="h5">{contract.name}</Typography>
            <Typography variant="h6" className={classes.companyHeader}>
              {contract.company_type === EnumStrings.CUSTOMER
                ? t("customerLabel")
                : t("contractorLabel")}
              :{" "}
              <Link
                component={RouterLink}
                to={`/companies/companies/${contract.company}`}
              >
                {companyName}
              </Link>
            </Typography>
          </div>
        )}
        <div className={classes.fields}>
          <div>
            <ContractFieldsView
              companyId={contract.company!}
              fields={contract}
              onUpdate={(patch) => updateContract(patch)}
            />
          </div>
          <div>
            {contract.company_type === EnumStrings.CUSTOMER ? (
              contract.company && (
                <ContractTaskTable
                  contractId={contract.id}
                  customerId={contract.company}
                />
              )
            ) : (
              <ContractCooperationTable contractId={contract.id} />
            )}
          </div>
        </div>
      </Paper>

      <Paper className={classes.toolbar}>
        {tasks.length > 0 ? (
          <Select
            value={selectedContractTaskId}
            onChange={onSelectChange}
            className={classes.dropDown}
            variant="outlined"
          >
            <MenuItem value="default" className={classes.menuItem}>
              {t("defaultPricesOption")}
            </MenuItem>
            {tasks.map((task) => (
              <MenuItem
                key={task.id}
                value={task.id}
                className={classes.menuItem}
              >
                <div>
                  <Typography component="div" variant="body1">
                    {task.name}
                  </Typography>
                  <Typography component="div" variant="subtitle2">
                    {businessAreaNames.get(task.businessarea) ?? ""}
                  </Typography>
                </div>
              </MenuItem>
            ))}
            {missingTaskContractItems.length > 0 && (
              <MenuItem value="task_missing" className={classes.menuItem}>
                {t("taskMissingOption")}
              </MenuItem>
            )}
          </Select>
        ) : (
          <div></div>
        )}

        <div className={classes.toolbarGroup}>
          <div>
            <TextField
              value={searchText}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearchText(event.target.value)
              }
              placeholder={t("searchField")}
              inputProps={{ "aria-label": t("searchField") }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearchText("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>

          <div>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={onAdd}
              disabled={selectedContractTaskId === "task_missing"}
            >
              {t("addLabel")}
            </Button>
          </div>
        </div>
      </Paper>

      {selectedContractTaskId === "task_missing" && (
        <Paper>
          <ContractItemsTable
            contractId={contract.id}
            header={
              <Typography variant="h6" className={classes.bold}>
                {t("missingContractItemsLabel")}
              </Typography>
            }
            contractItems={missingTaskContractItems}
            serviceCategoryNames={serviceCategoryNames}
            onDelete={onDelete}
            updateVisibility={onUpdateVisibility}
          />
        </Paper>
      )}

      {typeof selectedContractTaskId === "number" && (
        <Paper>
          <ContractItemsTable
            contractId={contract.id}
            header={
              <Typography variant="h6" className={classes.bold}>
                {t("contractItemsLabel")}
              </Typography>
            }
            contractItems={filteredItems.filter(
              (ci) => ci.task_id === selectedContractTaskId
            )}
            serviceCategoryNames={serviceCategoryNames}
            onDelete={onDelete}
            updateVisibility={onUpdateVisibility}
          />
        </Paper>
      )}

      <Paper>
        <ContractItemsTable
          contractId={contract.id}
          header={
            <Typography variant="h6" className={classes.bold}>
              {t("basePricesLabel")}
            </Typography>
          }
          contractItems={filteredItems.filter((ci) => ci.task_id === null)}
          serviceCategoryNames={serviceCategoryNames}
          taskSpecificPrices={taskSpecificPrices}
          onDelete={onDelete}
          updateVisibility={onUpdateVisibility}
        />
      </Paper>

      {modal?.m === "create" && (
        <AddContractItemDialog
          contractId={contract.id}
          selectedTaskId={
            typeof selectedContractTaskId === "number"
              ? selectedContractTaskId
              : null
          }
          serviceCategoryNames={serviceCategoryNames}
          businessAreaNames={businessAreaNames}
          tasks={tasks}
          createContractItem={(payload) =>
            createContractItem(payload).then(() => {
              modal?.resolve();
              setModal(undefined);
            })
          }
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
        />
      )}
      {modal?.m === "delete" && modalItem && (
        <ConfirmationDialog
          title={t("confirmDeleteTitle")}
          description={`${t("confirmDeleteText")}${
            ` ${modalItem.name}?` || "?"
          }`}
          onSuccess={() => {
            void deleteContractItem(modal.itemId, modal.type).then(() => {
              modal?.resolve();
              setModal(undefined);
            });
          }}
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
          open
        />
      )}
      {modal?.m === "updateVisibility" && modalItem && (
        <ConfirmationDialog
          title={t("confirmUpdateTitle")}
          description={`${
            modalItem.display ? t("confirmTurnoffText") : t("confirmTurnonText")
          } ${modalItem.name}?`}
          onSuccess={() =>
            void updateContractItem(modal.itemId, modal.type, {
              display: !modalItem.display
            }).then(() => {
              modal?.resolve();
              setModal(undefined);
            })
          }
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
          open
        />
      )}
      {modal?.m === "copy" && (
        <CopyContractContainer
          contractId={contract.id}
          contractName={contract.name}
          handleDialog={() => setModal(undefined)}
          contract={contract}
          open
          tabId={tabId}
        />
      )}
      {modal?.m === "index" && (
        <IndexContractDialog
          onIndex={adjustContractPrices}
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
        />
      )}
      {modal?.m === "reorder-items" && (
        <ReorderContractItemsDialog
          contractItems={contractItems}
          saveSortOrder={saveSortOrder}
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
        />
      )}
    </>
  );
};

type ContractContainerProps = TabComponentProps<"contractId">;

const ContractContainer: React.FC<ContractContainerProps> = ({
  tabId,
  routeParams: { contractId }
}) => {
  const queueRef = useRef<Promise<{ oca: number }>>();
  const [contract, setContract] = useState<Contract>();
  const [tasks, setTasks] = useState<Task[]>();
  const [contractItems, setContractItems] = useState<IContractSummaryItem[]>();
  const [serviceCategoryNames, setServiceCategoryNames] =
    useState<Map<number, string>>();
  const [businessAreaNames, setBusinessAreaNames] =
    useState<Map<number, string>>();

  if (contract?.id && contract.id !== contractId) {
    setContract(undefined);
    setTasks(undefined);
    setContractItems(undefined);
  }

  useEffect(() => {
    let alive = true;
    void Promise.all([
      getContractAPI(contractId),
      getContractItemsAPI(contractId),
      getContractTasksAPI({ customer_contracts: contractId }),
      getAllServiceCategories(),
      getBusinessareasAPI()
    ]).then(
      ([
        contract,
        contractItems,
        { results: tasks },
        { results: serviceCategories },
        { results: businessAreas }
      ]) => {
        if (alive) {
          store.dispatch(setTitle(contract.name, tabId));
          setContract(contract);
          setContractItems(contractItems);
          setTasks(tasks.sort((a, b) => a.name.localeCompare(b.name)));
          setServiceCategoryNames(
            new Map(serviceCategories.map((c) => [c.id, c.name]))
          );
          setBusinessAreaNames(
            new Map(businessAreas.map((b) => [b.id, b.name]))
          );
        }
      }
    );
    return () => {
      alive = false;
    };
  }, [tabId, contractId]);

  const updateContract = async (
    patch: Partial<Omit<Contract, "id" | "oca">>
  ) => {
    if (contract) {
      const current = contract;
      setContract({ ...current, ...patch });

      const promise = Promise.resolve(queueRef.current ?? { oca: contract.oca })
        .then(async ({ oca }) => {
          const r = await updateContractAPI(contractId, { ...patch, oca });
          setContract((c) => (c !== undefined ? { ...c, oca: r.oca } : c));
          return { oca: r.oca };
        })
        .catch(() => ({ oca: contract.oca }));

      queueRef.current = promise;
      await promise;
    }
  };

  const adjustContractPrices = async (
    payload: Parameters<typeof adjustContractPricesAPI>[1]
  ): Promise<void> => {
    const response = await adjustContractPricesAPI(contractId, payload);
    setContractItems(response);
  };

  const createContractItem = async (
    payload: Parameters<typeof createContractItemAPI>[1]
  ) => {
    const response = await createContractItemAPI(contractId, payload);
    setContractItems((cis) => [response, ...(cis ?? [])]);
  };

  const updateContractItem = async (
    id: number,
    type: IContractSummaryType,
    patch: {
      display?: boolean;
    }
  ) => {
    const apiType = contractItemTypeToApi[type];
    await updateContractItemAPI(apiType, id, patch);
    setContractItems((cis) =>
      cis?.map((ci) =>
        ci.id === id && ci.type === type ? { ...ci, ...patch } : ci
      )
    );
  };

  const deleteContractItem = async (id: number, type: IContractSummaryType) => {
    const apiType = contractItemTypeToApi[type];
    await deleteContractItemAPI(apiType, id);
    setContractItems((cis) =>
      cis?.filter((ci) => ci.type !== type || ci.id !== id)
    );
  };

  const saveSortOrder = async (payload: Parameters<typeof sortContractItemsAPI>[1]): Promise<void> => {
    if (contract) {
      const current = contractItems
      setContractItems(current?.map(ci => ({ ...ci, sort_order: payload.find(i => i.type === ci.type && i.item_id === ci.id)?.sort_order ?? ci.sort_order })))

      await sortContractItemsAPI(contractId, payload)
        .catch((e) => { setContractItems(current); throw e });
    }
  };

  return (
    <div>
      {!contract ||
      !tasks ||
      !contractItems ||
      !serviceCategoryNames ||
      !businessAreaNames ? (
        <LoadingSpinnerPaper />
      ) : (
        <ContractView
          tabId={tabId}
          contract={contract}
          tasks={tasks}
          contractItems={contractItems}
          serviceCategoryNames={serviceCategoryNames}
          businessAreaNames={businessAreaNames}
          updateContract={updateContract}
          adjustContractPrices={adjustContractPrices}
          createContractItem={createContractItem}
          updateContractItem={updateContractItem}
          deleteContractItem={deleteContractItem}
          saveSortOrder={saveSortOrder}
        />
      )}
    </div>
  );
};

export default ContractContainer;
