import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  TabComponentProps,
  TemplateImage
} from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import TemplateImageView from "../../components/templateimages/TemplateImage";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import LoadingSpinnerPaper from "../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface templateImageContainerProps
  extends TabComponentProps<"templateImageId"> {
  templateImage: TemplateImage;
  loadingTemplateImage: boolean;
  resetTemplateImage: () => void;
  loadTemplateImage: (templateImageId: number) => void;
}

const TemplateImageContainer: React.FC<templateImageContainerProps> = (
  props
) => {
  const {
    resetTemplateImage,
    loadingTemplateImage,
    loadTemplateImage,
    templateImage,
    routeParams: { templateImageId }
  } = props;
  useEffect(
    () => loadTemplateImage(templateImageId),
    [loadTemplateImage, templateImageId]
  );

  useEffect(() => () => resetTemplateImage(), [resetTemplateImage]);
  return (
    <div>
      <ViewActionButtons
        resourceId={templateImageId}
        resource={ReduxActionStrings.TEMPLATEIMAGE}
        url="services/templateimages"
      />
      {loadingTemplateImage ? (
        <LoadingSpinnerPaper />
      ) : (
        <TemplateImageView
          templateImage={templateImage}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadTemplateImage: (templateImageId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TEMPLATEIMAGE,
        `services/templateimages/${templateImageId}/`
      )
    );
  },
  resetTemplateImage: () =>
    dispatch(LoadingBooleansActions.ResetTemplateImage(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  templateImage:
    state.tabStates[ownProps.tabId].templateImages.templateImage.templateImage,
  loadingtemplateImage:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingTemplateImage
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateImageContainer);
