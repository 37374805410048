import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, WeatherArea } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { RestStrings } from "../../../redux/strings";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface WeatherAreasContainerProps {
  weatherAreas: WeatherArea[];
  count: number;
  loadingWeatherAreas: boolean;
  weatherAreaIds?: number[];
  resetWeatherAreas: () => void;
  loadWeatherAreas: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  showNewButton?: boolean;
}

const WeatherAreasContainer: React.FC<WeatherAreasContainerProps> = (props) => {
  const {
    weatherAreaIds,
    loadWeatherAreas,
    resetWeatherAreas,
    loadingWeatherAreas,
    weatherAreas
  } = props;

  const page = 1;
  const pageSize = 20;

  let filter = "";

  if (weatherAreaIds) {
    const filterStr =
      weatherAreaIds.length !== 1 ? "filter__id__in" : "filter__id";
    const weatherAreaIdsStr = weatherAreaIds.length
      ? weatherAreaIds.toString()
      : "";

    filter = `&${filterStr}=${weatherAreaIdsStr}`;
  }

  useEffect(
    () => loadWeatherAreas(page, pageSize, undefined, undefined, filter),
    [filter, loadWeatherAreas]
  );
  useEffect(() => () => resetWeatherAreas(), [resetWeatherAreas]);

  const columnDefinitions = [{ name: "name", type: "link" }];

  const showNewButton =
    (typeof props.showNewButton !== "undefined" && props.showNewButton) ||
    typeof props.showNewButton === "undefined";

  return (
    <ResourcesTableContainer
      resources={weatherAreas}
      resourceName={"WeatherArea"}
      count={props.count}
      loadingResources={loadingWeatherAreas}
      routeUrl={"companies/weatherareas"}
      columnDefinitions={columnDefinitions}
      resetResources={resetWeatherAreas}
      loadResources={loadWeatherAreas}
      showNewButton={showNewButton}
      customFilter={filter}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadWeatherAreas: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__contains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__name=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "WEATHERAREAS",
        `companies/weatherareas/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  resetWeatherAreas: () =>
    dispatch(LoadingBooleansActions.ResetWeatherAreas(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  weatherAreas:
    state.tabStates[ownProps.tabId].weatherAreas.weatherAreas.weatherAreas,
  count: state.tabStates[ownProps.tabId].weatherAreas.weatherAreas.count,
  loadingWeatherAreas:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingWeatherAreas
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeatherAreasContainer);
