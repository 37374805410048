import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Field, Formik } from "formik";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IDateTimeFragmentType } from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import TimePicker from "../TimePicker";
import { dashDateFormat, formatDashDate, timeFormat } from "../FormatHelpers";
import { mergeDateAndTime } from "../../helpers/timesheetHelperService";

const useStyles = makeStyles((theme: any) => ({
  timeField: { marginTop: theme.spacing(0.5) }
}));

interface DateTimeFragmentProps {
  fieldName: IDateTimeFragmentType;
  fieldValue: string;
  fieldInitials: string;
  correctionChanged: (fieldName: IDateTimeFragmentType, value: string) => void;
}

export default function DateTimeFragment(props: DateTimeFragmentProps) {
  const classes = useStyles();
  const { fieldName, fieldValue, fieldInitials, correctionChanged } = props;
  const t = useTranslate("TimeSheetRowsAndApprovals");
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const maxDate = new Date();

  const handleTimeChange = (_value: Date) => {
    if (_value) {
      if (_value.toString() !== "Invalid Date") {
        setTimeError(false);
        let oldDateTime = new Date(fieldValue).toString();
        const oldDate = oldDateTime.substring(15, 25);
        const newDate = _value.toString().substring(15, 25);
        oldDateTime = oldDateTime.replace(oldDate, newDate);
        const newDateTime = new Date(oldDateTime).toISOString();

        correctionChanged(fieldName, newDateTime);
      } else {
        setTimeError(true);
      }
    }
  };
  const handleDateFieldChange = (_value: Date) => {
    if (_value) {
      if (_value.toString() !== "Invalid Date") {
        setDateError(false);
        let value = _value.toISOString();
        if (fieldValue) {
          value = mergeDateAndTime(value, fieldValue);
        }
        correctionChanged(fieldName, value);
      } else {
        setDateError(true);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        date: formatDashDate(fieldValue),
        time: new Date(fieldValue)
      }}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {(_props) => (
        <>
          <Field
            data-cy={`${fieldInitials}_date`}
            label={t(`${fieldInitials}DateFieldLabel`)}
            placeholder={"20YY-MM-DD"}
            value={_props.values.date}
            onChange={handleDateFieldChange}
            component={KeyboardDatePicker}
            format={dashDateFormat}
            maxDate={maxDate}
            margin="normal"
            fullWidth
            error={dateError}
            helperText={dateError ? "Wrong Date" : undefined}
          />
          <Field
            data-cy={`${fieldInitials}_time`}
            name={`${fieldInitials}_time`}
            label={t(`${fieldInitials}TimeFieldLabel`)}
            placeholder={t(`${fieldInitials}TimeFieldLabel`)}
            type="text"
            className={`${classes.timeField}`}
            value={_props.values.time}
            onChange={handleTimeChange}
            component={TimePicker}
            format={timeFormat}
            ampm={false}
            margin="normal"
            fullWidth
            error={timeError}
            helperText={timeError ? "Wrong Time" : undefined}
          />
        </>
      )}
    </Formik>
  );
}
