import {
  RetrospectRoutePlanInstance,
  TimeSheet,
  TimeSheetsResponse,
  TimeSheetOptions,
  TimeSheetForm,
  TimeSheetEditForm,
  RouteInstance,
  TimesheetAPIResponse,
  TimesheetQuery,
  SaveTimesheetAPIPayload,
  RouteInstancesResponse,
  RowApprovalModificationsPayload,
  BulkApprovals,
  TimeSheetRowApproval,
  TimeSheetRowsEditPayload,
  TimeSheetExtended,
  CurrentUserResponse,
  TimesheetSummaryOrderRouteInstances,
  MyTaskResponse,
  PatchInvoiceStatusPayload,
  TimesheetRowImageResponse,
  CreateRowImagePayload,
  GetRowImagesQuery,
  UpdateRowImagePayload,
  TimesheetRowImage,
  ManualInvoiceItem,
  ManualInvoiceFormItem,
  ManualInvoiceItemCreatePayload,
  ManualInvoiceItemDeletePayload,
  ManualInvoiceItemPatchPayload,
  ContractForm,
  ContractEditPayload,
  Contract,
  InvoicesResponse,
  BulkInvoiceStatusUpdatePayload,
  BulkInvoiceStatusReturn,
  SuggestionsResponse,
  SearchPayload,
  SearchResponse,
  RouteInstanceProgressResponse,
  DispatchPayload,
  InvoiceReport,
  InvoiceReportSummary,
  InvoiceFilterQuery,
  Invoice,
  Order,
  TaskTabResponse,
  IUsersResponse,
  IPersonsResponse,
  ICompanyUsersResponse,
  ICompaniesResponse,
  INotificationSettingsResponse,
  INotificationProfiles,
  INotificationProfile,
  INotificationProfileForm,
  INotificationSettingForm,
  INotificationSettingDisplay,
  IContractSummaryItem,
  IContractItemType,
  ContractItemResponse,
  ContractCooperationsResponse,
  ContractCooperation,
  ContractsAPIResponse,
  PersonFormResponse,
  TaskFormResponse,
  PersonForm,
  User,
  TasksResponse,
  EmailsResponse,
  Email,
  BaseServicesResponse,
  ServiceCategoriesResponse,
  ContractingProfile,
  ProspectForm,
  Prospect,
  RoutePlansResponse,
  IReportedCommentsAndImages,
  Customerobject,
  IApplicableServicePackageEvents,
  IServicePackageEventResponse,
  IAvailableServicePackageEvents,
  BaseSupplementsResponse,
  BaseMaterialsResponse,
  BaseAccessoriesResponse,
  PasscardsResponse,
  DistrictsResponse,
  GroupsResponse,
  NotificationsProfilesResponse,
  IAddServicePackagePayload,
  VersionResponse,
  IQuery,
  IPaginationQnum,
  AllQueryFilters,
  IApprovalSummaryQnum,
  IContractsServicecategoriesQnum,
  IPackageAddOrRemoveQnum,
  AuthenticationResponse,
  LoginAPIPayload,
  LogoutAPIPayload,
  DomainResponse,
  LoginAdhocAPIPayload,
  Term,
  ServicesResponse,
  IPersonQnum,
  IPaginationResponse,
  CompaniesResponse,
  RoutePlanForm,
  TaskOption,
  InvoiceSendData,
  Task,
  Person,
  UsersResponse,
  EventsResponse,
  EventsFilterQuery,
  Service,
  BaseService,
  Material,
  BaseMaterial,
  Addon,
  BaseAddon,
  BaseAccessory,
  BaseServicepackage,
  BaseSupplement,
  Accessory,
  Servicepackage,
  Supplement,
  RouteInstancesQuery,
  Company,
  TimeSheetModificationRequest,
  TimeSheetModificationRequestResponse,
  TaskResponse,
  IContractSummaryType,
  ICopyContractItem,
  ICustomerobjectsResponse,
  CompanyType,
  TaskOptionsResponse,
  RouteInstanceForm,
  CompanyForm,
  CustomerobjectForm,
  MaterialsResponse,
  RoutePlan,
  BaseServiceForm,
  BaseMaterialForm,
  BaseAccessoryForm,
  BaseAddonForm,
  BaseServicepackageForm,
  BaseSupplementForm,
  BaseServiceOptionsResponse,
  BaseMaterialOptionsResponse,
  BaseAccessoryOptionsResponse,
  BaseSupplementOptionsResponse,
  BaseServicesFilteredResponse,
  BaseSupplementsFilteredResponse,
  BaseAccessoriesFilteredResponse,
  BaseAddonsFilteredResponse,
  BaseMaterialsFilteredResponse,
  BaseServicepackagesFilteredResponse,
  InvoiceRecipientsResponse
} from "../redux/types";
import {
  fetchGet,
  fetchPost,
  fetchPatch,
  fetchBlob,
  fetchDelete
} from "./fetchWrapper";
import { filterQueryParams, queryParams } from "../helpers/queryParams";
import { EnumStrings } from "../redux/strings";
import { pick } from "lodash";

// TODO: Move releavant APIs to api-declerations directory in separate files with respect to each view/route

export const restUrl = (
  url: string,
  filters?: AllQueryFilters | string
): string => {
  let queryParamsStr = "";
  if (typeof filters === "string") {
    queryParamsStr = filters;
    console.warn(
      `Please Use object type filters to the api: ${url}, string queryparams are outdated!!`
    );
  } else {
    queryParamsStr = queryParams(filters);
  }
  const completeUrl = `${localStorage.getItem(
    "baseURL"
  )}${url}${queryParamsStr}`;

  // if (!completeUrl.includes("?")) {
  //   console.warn(`Better to Add Query filters to api: ${url}!!`);
  // }
  return completeUrl;
};

export const getBackendVersionAPI = () =>
  fetchGet<VersionResponse>(restUrl("version"));

export const getVerificationSummariesAPI = (
  filters: IQuery<IApprovalSummaryQnum>
): Promise<TimesheetSummaryOrderRouteInstances> =>
  fetchGet(restUrl("orders/routeinstances/summaries/", filters));

export const getVerificationCountAPI =
  (): Promise<TimesheetSummaryOrderRouteInstances> =>
    fetchGet(restUrl("orders/routeinstances/count/"));

export const getPartlyDeclinedCountAPI = (): Promise<number> =>
  fetchGet(restUrl("orders/routeinstances/count_partly_declined/"));

export const getAllTimesheets = (): Promise<TimesheetAPIResponse> =>
  fetchGet(restUrl("timesheets/timesheets/"));

export const updateTimesheetAPI = (
  timesheetId: number,
  timesheet: TimeSheetForm
) =>
  fetchPatch<TimeSheetExtended>(
    restUrl(`timesheets/timesheets/${timesheetId}/`),
    timesheet
  );

export const deleteWorkAPI = (id: number) =>
  fetchDelete<void>(restUrl(`timesheets/timesheets/${id}/`));

export const getBaseServiceAPI = (id: number): Promise<BaseService> =>
  fetchGet(restUrl(`contracts/baseservices/${id}/`));

export const patchBaseServiceAPI = (
  id: number,
  payload: Partial<BaseService>
) => fetchPatch<BaseService>(restUrl(`contracts/baseservices/${id}/`), payload);

export const getBaseMaterialAPI = (id: number): Promise<BaseMaterial> =>
  fetchGet(restUrl(`contracts/basematerials/${id}/`));

export const patchBaseMaterialAPI = (
  id: number,
  payload: Partial<BaseMaterial>
) =>
  fetchPatch<BaseMaterial>(restUrl(`contracts/basematerials/${id}/`), payload);

export const patchBaseAccessoryAPI = (
  id: number,
  payload: Partial<BaseAccessory>
) =>
  fetchPatch<BaseAccessory>(
    restUrl(`contracts/baseaccessories/${id}/`),
    payload
  );

export const getBaseAddonAPI = (id: number): Promise<BaseAddon> =>
  fetchGet(restUrl(`contracts/baseaddons/${id}/`));

export const getBaseAccessoryAPI = (id: number): Promise<BaseAccessory> =>
  fetchGet(restUrl(`contracts/baseaccessories/${id}/`));

export const getBaseServicepackageAPI = (
  id: number
): Promise<BaseServicepackage> =>
  fetchGet(restUrl(`contracts/baseservicepackages/${id}/`));

export const patchBaseServicepackageAPI = (
  id: number,
  payload: Partial<BaseServicepackage>
) =>
  fetchPatch<BaseServicepackage>(
    restUrl(`contracts/baseservicepackages/${id}/`),
    payload
  );

export const getBaseSupplementAPI = (id: number): Promise<BaseSupplement> =>
  fetchGet(restUrl(`contracts/basesupplements/${id}/`));

export const getBaseContractItemsAPI = (
  contractId: number,
  filters?: Partial<{
    name: string;
    item_id: number;
  }>
): Promise<
  {
    id: number;
    name: string;
    price: string;
    type: IContractSummaryType;
    categories: number[];
  }[]
> =>
  fetchGet(
    restUrl(
      `contracts/contracts/${contractId}/baseitems/`,
      pick(filters, ["name", "item_id"]) as any
    )
  );

export const getBaseServicesByIdsAPI = (
  ids: number[]
): Promise<BaseServicesResponse> =>
  fetchGet(restUrl("contracts/baseservices/", { filter__id__in: ids }));

export const getServicesByIdsAPI = (ids: number[]): Promise<ServicesResponse> =>
  fetchGet(restUrl("contracts/services/", { filter__id__in: ids }));

export const getServiceAPI = (id: number): Promise<Service> =>
  fetchGet(restUrl(`contracts/services/${id}/`));

export const getMaterialAPI = (id: number): Promise<Material> =>
  fetchGet(restUrl(`contracts/materials/${id}/`));

export const getAddonAPI = (id: number): Promise<Addon> =>
  fetchGet(restUrl(`contracts/addons/${id}/`));

export const getAccessoryAPI = (id: number): Promise<Accessory> =>
  fetchGet(restUrl(`contracts/accessories/${id}/`));

export const getServicepackageAPI = (id: number): Promise<Servicepackage> =>
  fetchGet(restUrl(`contracts/servicepackages/${id}/`));

export const getSupplementAPI = (id: number): Promise<Supplement> =>
  fetchGet(restUrl(`contracts/supplements/${id}/`));

export const getSearchServiceCategoryOptionsAPI = (
  searchTerm: string,
  ids?: number[]
) =>
  fetchGet<ServiceCategoriesResponse>(
    restUrl("contracts/servicecategories/", {
      order__name: "asc",
      ...(ids && ids.length > 0
        ? { filter__id__in: ids }
        : { page: 1, page_size: 10 }),
      include: ["id", "name"],
      filter__name__icontains: searchTerm
    })
  );

export const getBaseServiceOptionsAPI = () =>
  fetchGet<BaseServiceOptionsResponse>(
    restUrl("contracts/baseservices/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      include: ["id", "name"]
    })
  );

export const getBaseMaterialOptionsAPI = () =>
  fetchGet<BaseMaterialOptionsResponse>(
    restUrl("contracts/basematerials/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      include: ["id", "name"]
    })
  );

export const getBaseAccessoryOptionsAPI = () =>
  fetchGet<BaseAccessoryOptionsResponse>(
    restUrl("contracts/baseaccessories/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      include: ["id", "name"]
    })
  );

export const getBaseSupplementOptionsAPI = () =>
  fetchGet<BaseSupplementOptionsResponse>(
    restUrl("contracts/basesupplements/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      include: ["id", "name"]
    })
  );

export const getBaseServicesAPI = (
  page: number,
  pageSize: number,
  searchStr?: string,
  orderingStr?: "asc" | "desc"
) =>
  fetchGet<BaseServicesFilteredResponse>(
    restUrl("contracts/baseservices/", {
      page: page,
      page_size: pageSize,
      order__name: orderingStr || "asc",
      filter__status: "ACTIVE",
      include: ["id", "name", "price", "unit", "status", "image"],
      filter__name__icontains: searchStr || ""
    })
  );

export const getBaseMaterialsAPI = (
  page: number,
  pageSize: number,
  searchStr?: string,
  orderingStr?: "asc" | "desc"
) =>
  fetchGet<BaseMaterialsFilteredResponse>(
    restUrl("contracts/basematerials/", {
      page: page,
      page_size: pageSize,
      order__name: orderingStr || "asc",
      filter__status: "ACTIVE",
      include: ["id", "name", "price", "unit", "status", "image"],
      filter__name__icontains: searchStr || ""
    })
  );

export const getBaseAddonsAPI = (
  page: number,
  pageSize: number,
  searchStr?: string,
  orderingStr?: "asc" | "desc"
) =>
  fetchGet<BaseAddonsFilteredResponse>(
    restUrl("contracts/baseaddons/", {
      page: page,
      page_size: pageSize,
      order__name: orderingStr || "asc",
      filter__status: "ACTIVE",
      include: ["id", "name", "price", "unit", "status"],
      filter__name__icontains: searchStr || ""
    })
  );

export const getBaseSupplementsAPI = (
  page: number,
  pageSize: number,
  searchStr?: string,
  orderingStr?: "asc" | "desc"
) =>
  fetchGet<BaseSupplementsFilteredResponse>(
    restUrl("contracts/basesupplements/", {
      page: page,
      page_size: pageSize,
      order__name: orderingStr || "asc",
      filter__status: "ACTIVE",
      include: ["id", "name", "price", "unit", "status"],
      filter__name__icontains: searchStr || ""
    })
  );

export const getBaseAccessoriesAPI = (
  page: number,
  pageSize: number,
  searchStr?: string,
  orderingStr?: "asc" | "desc"
) =>
  fetchGet<BaseAccessoriesFilteredResponse>(
    restUrl("contracts/baseaccessories/", {
      page: page,
      page_size: pageSize,
      order__name: orderingStr || "asc",
      filter__status: "ACTIVE",
      include: ["id", "name", "price", "unit", "status", "image"],
      filter__name__icontains: searchStr || ""
    })
  );

export const getBaseServicepackagesAPI = (
  page: number,
  pageSize: number,
  searchStr?: string,
  orderingStr?: "asc" | "desc"
) =>
  fetchGet<BaseServicepackagesFilteredResponse>(
    restUrl("contracts/baseservicepackages/", {
      page: page,
      page_size: pageSize,
      order__name: orderingStr || "asc",
      filter__status: "ACTIVE",
      include: ["id", "name", "price", "unit", "status"],
      filter__name__icontains: searchStr || ""
    })
  );

export const createBaseServiceAPI = (baseserviceform: BaseServiceForm) =>
  fetchPost<BaseService>(restUrl("contracts/baseservices/"), baseserviceform);

export const createBaseMaterialAPI = (baseMaterialform: BaseMaterialForm) =>
  fetchPost<BaseService>(restUrl("contracts/basematerials/"), baseMaterialform);

export const createBaseAddonAPI = (baseaddonform: BaseAddonForm) =>
  fetchPost<BaseService>(restUrl("contracts/baseaddons/"), baseaddonform);

export const createBaseSupplementAPI = (
  basesupplementform: BaseSupplementForm
) =>
  fetchPost<BaseService>(
    restUrl("contracts/basesupplements/"),
    basesupplementform
  );

export const createBaseAccessoryAPI = (baseaccessoryform: BaseAccessoryForm) =>
  fetchPost<BaseService>(
    restUrl("contracts/baseaccessories/"),
    baseaccessoryform
  );

export const createBaseServicepackageAPI = (
  baseservicepackageform: BaseServicepackageForm
) =>
  fetchPost<BaseService>(
    restUrl("contracts/baseservicepackages/"),
    baseservicepackageform
  );

export const getSearchBaseServiceOptionsAPI = (
  searchTerm: string,
  ids?: number[]
) =>
  fetchGet<BaseServicesResponse>(
    restUrl("contracts/baseservices/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      ...(ids && ids.length > 0
        ? { filter__id__in: ids }
        : { page: 1, page_size: 10 }),
      include: ["id", "name"],
      filter__name__icontains: searchTerm
    })
  );

export const getSearchBaseMaterialOptionsAPI = (
  searchTerm: string,
  ids?: number[]
) =>
  fetchGet<BaseMaterialsResponse>(
    restUrl("contracts/basematerials/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      ...(ids && ids.length > 0
        ? { filter__id__in: ids }
        : { page: 1, page_size: 10 }),
      include: ["id", "name"],
      filter__name__icontains: searchTerm
    })
  );

export const getSearchBaseAccessoryOptionsAPI = (
  searchTerm: string,
  ids?: number[]
) =>
  fetchGet<BaseAccessoriesResponse>(
    restUrl("contracts/baseaccessories/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      ...(ids && ids.length > 0
        ? { filter__id__in: ids }
        : { page: 1, page_size: 10 }),
      include: ["id", "name"],
      filter__name__icontains: searchTerm
    })
  );

export const getSearchBaseSupplementOptionsAPI = (
  searchTerm: string,
  ids?: number[]
) =>
  fetchGet<BaseSupplementsResponse>(
    restUrl("contracts/basesupplements/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      ...(ids && ids.length > 0
        ? { filter__id__in: ids }
        : { page: 1, page_size: 10 }),
      include: ["id", "name"],
      filter__name__icontains: searchTerm
    })
  );

export const getAllServiceCategories = (
  filters?: IQuery<IContractsServicecategoriesQnum>
): Promise<ServiceCategoriesResponse> =>
  fetchGet(restUrl("contracts/servicecategories/", filters));

export const getSearchPasscardOptionsAPI = (
  searchTerm: string,
  ids?: number[]
) =>
  fetchGet<PasscardsResponse>(
    restUrl("companies/passcards/", {
      order__serial_number: "asc",
      filter__status: "ACTIVE",
      ...(ids && ids.length > 0
        ? { filter__id__in: ids }
        : { page: 1, page_size: 10 }),
      include: ["id", "serial_number"],
      filter__serial_number__icontains: searchTerm
    })
  );

export const getSearchDistrictOptionsAPI = (
  searchTerm: string,
  ids?: number[]
) =>
  fetchGet<DistrictsResponse>(
    restUrl("companies/districts/", {
      order__name: "asc",
      ...(ids && ids.length > 0 ? { filter__id__in: ids } : {}),
      include: ["id", "name"],
      ...(searchTerm !== ""
        ? { filter__name__icontains: searchTerm }
        : undefined)
    })
  );

export const getSearchGroupOptionsAPI = (searchTerm: string, ids?: number[]) =>
  fetchGet<GroupsResponse>(
    restUrl("auth/groups/", {
      order__name: "asc",
      ...(ids && ids.length > 0
        ? { filter__id__in: ids }
        : { page: 1, page_size: 10 }),
      include: ["id", "name"],
      filter__name__icontains: searchTerm
    })
  );

export const getSearchNotificationsProfileOptionsAPI = (
  searchTerm: string,
  ids?: number[]
) =>
  fetchGet<NotificationsProfilesResponse>(
    restUrl("services/notificationsprofiles/", {
      order__name: "asc",
      ...(ids && ids.length > 0
        ? { filter__id__in: ids }
        : { page: 1, page_size: 10 }),
      include: ["id", "name"],
      filter__name__icontains: searchTerm
    })
  );

export const getDistrictsByIdsAPI = (ids: (number | undefined)[]) =>
  fetchGet<DistrictsResponse>(
    restUrl(
      `companies/districts/?include=["id", "name"]&filter__id__in=[${ids}]`
    )
  );

export const getTimesheetsAPI = (
  filter: TimesheetQuery
): Promise<TimesheetAPIResponse> =>
  fetchGet(restUrl("timesheets/timesheets/", filter));

export const getExtendedTimesheetsAPI = (
  filter: TimesheetQuery
): Promise<{ results: TimeSheetExtended[] }> =>
  fetchGet(restUrl("timesheets/extendedtimesheets/", filter));

export const unsubmitTimesheetAPI = (
  timesheetId: number,
  payload: { oca: number; submitted: boolean }
) =>
  fetchPatch<TimeSheetForm>(
    restUrl(`timesheets/timesheets/${timesheetId}/`),
    payload
  );

export const getContractorTimesheetAPI = (
  id: number
): Promise<TimeSheetExtended> =>
  fetchGet(restUrl(`timesheets/extendedtimesheets/${id}/`));

export const getContractorTimesheetsAPI = (): Promise<TimeSheetsResponse> =>
  fetchGet(restUrl("contractor/timesheets/"));

export const getContractorFilteredTimesheetsAPI = (
  filter: TimesheetQuery
): Promise<TimeSheetsResponse> =>
  fetchGet(restUrl("contractor/timesheets/", filter));

export const getOptionsForTimesheetsAPI = (
  personId: number
): Promise<TimeSheetOptions> =>
  fetchGet(restUrl(`auth/persons/${personId}/timesheet_options/`));

export const getTimesheetOptionsForContractorAPI = (
  companyId: number
): Promise<TimeSheetOptions> =>
  fetchGet(restUrl(`companies/companies/${companyId}/timesheet_options/`));

export const getTimesheetOptionsForApprovalAPI = (
  timesheetId: number
): Promise<TimeSheetOptions> =>
  fetchGet(restUrl(`timesheets/timesheets/${timesheetId}/options/`));

export const getCopyofTimesheetAPI = (
  timesheet_id: number,
  payload: TimeSheetRowsEditPayload
) =>
  fetchPost<TimeSheet>(
    restUrl(`timesheets/timesheets/${timesheet_id}/edit/`),
    payload
  );

export const getContractTermsByIdAPI = (termId: number) =>
  fetchGet<Term>(restUrl(`contracts/terms/${termId}/`));

export const addTimesheetAPI = (
  timesheet: TimeSheetForm
): Promise<TimeSheetForm> =>
  fetchPost(restUrl("timesheets/timesheets/manual/"), timesheet);

export const addNewTimesheetAPI = (
  data: TimeSheetForm
): Promise<TimeSheetExtended> =>
  fetchPost(restUrl("timesheets/timesheets/"), data);

export const getTimesheetByIdAPI = (id: number) =>
  fetchGet<TimeSheetExtended>(restUrl(`timesheets/timesheets/${id}/`));

export const updateTimesheetRowsAPI = (
  id: number,
  payload: SaveTimesheetAPIPayload
) => fetchPatch<TimeSheet>(restUrl(`timesheets/timesheets/${id}/`), payload);

export const submitTimesheetAPI = (
  timesheet: TimeSheetEditForm
): Promise<TimeSheetEditForm> =>
  fetchPatch(restUrl(`timesheets/timesheets/${timesheet.id}/`), timesheet);

export const saveAndSubmitWorkAPI = (
  timesheetId: number,
  timesheet: Partial<TimeSheetExtended>
) =>
  fetchPatch<TimeSheetExtended>(
    restUrl(`timesheets/timesheets/${timesheetId}/`),
    timesheet
  );

export const createTimeSheetModificationRequestAPI = (
  data: TimeSheetModificationRequest
): Promise<TimeSheetModificationRequestResponse> =>
  fetchPost(restUrl("timesheets/timesheetmodificationrequests/"), data);

export const getTimeSheetModificationRequestsAPI = (
  timesheetId: number
): Promise<TimeSheetModificationRequestResponse[]> =>
  fetchGet(
    restUrl(`timesheets/timesheets/${timesheetId}/modificationrequests/`)
  );

export const approveUnplannedRouteInstance = (
  payload: RetrospectRoutePlanInstance
): Promise<RouteInstance> =>
  fetchPost(restUrl("orders/routeinstances/create_retrospect/"), payload);

export const getRouteInstancesAPI = (
  filter: RouteInstancesQuery
): Promise<RouteInstancesResponse> =>
  fetchGet(restUrl(`orders/routeinstances/`, filter));

export const getRouteInstanceProgressAPI = (
  id: number
): Promise<RouteInstanceProgressResponse> =>
  fetchGet(restUrl(`orders/routeinstances/${id}/progress`));

export const createWorkApprovalAPI = (
  payload: { submittedwork: number } & RowApprovalModificationsPayload
): Promise<TimeSheetRowApproval> =>
  fetchPost(restUrl("timesheets/workapprovals/"), payload);

export const reverseWorkapprovalAPI = (payload: any): Promise<any> =>
  fetchPost(restUrl(`timesheets/workapprovals/bulk_reverse/`), payload);

export const updateWorkApprovalAPI = (
  approvalId: number | undefined,
  rowApproval: RowApprovalModificationsPayload
): Promise<TimeSheetRowApproval> =>
  fetchPatch(restUrl(`timesheets/workapprovals/${approvalId}/`), rowApproval);

export const getWorkApprovalAPI = (
  approvalId: number
): Promise<TimeSheetRowApproval> =>
  fetchGet(restUrl(`timesheets/workapprovals/${approvalId}/`));

export const bulkApprovalsAPI = (
  payload: BulkApprovals
): Promise<BulkApprovals> =>
  fetchPost(restUrl("timesheets/workapprovals/bulk/"), payload);

export const getMyUserInfoAPI = () =>
  fetchGet<CurrentUserResponse>(restUrl("auth/users/me/"));

export const getMyTasks = () =>
  fetchGet<MyTaskResponse>(restUrl("contractor/tasks/"));

type ContractBusinessareasAPIFilters = Partial<{
  id__in: number[];
}>;

export const getContractBusinessAreasAPI = (
  filters: ContractBusinessareasAPIFilters
): Promise<{ results: { id: number; name: string }[] }> => {
  const params = Object.fromEntries(
    Object.entries(filters)
      .filter(
        <K, V>(e: [K, V]): e is [K, Exclude<V, undefined>] => e[1] !== undefined
      )
      .map(([key, value]) => [`filter__${key}`, value])
  );
  return fetchGet<{ results: { id: number; name: string }[] }>(
    restUrl("contracts/businessareas/", { ...params, include: ["id", "name"] })
  );
};

type ContractTasksAPIFilters = Partial<{
  id: number;
  id__in: number[];
  customer_contracts: number;
  customer_id: number;
  contractor_id: number;
  customerobject: number;
}>;

export const getContractTasksAPI = (
  filters: ContractTasksAPIFilters
): Promise<TasksResponse> => {
  const params = Object.fromEntries(
    Object.entries(filters)
      .filter(
        <K, V>(e: [K, V]): e is [K, Exclude<V, undefined>] => e[1] !== undefined
      )
      .map(([key, value]) => [`filter__${key}`, value])
  );
  return fetchGet<TasksResponse>(restUrl("contracts/tasks/", params));
};

export const createContractTaskAPI = (
  businessAreaId: number,
  customerObjectId: number,
  customerContractIds: number[]
) =>
  fetchPost<TaskResponse>(restUrl("contracts/tasks/"), {
    status: EnumStrings.ACTIVE,
    complexity: EnumStrings.LOW,
    vulnerability: EnumStrings.LOW,
    report_interval: EnumStrings.MONTHLY,
    product_package: false,
    material_depot: false,
    default_transport: 0,
    spoilage_coefficient: 0,
    businessarea: businessAreaId,
    customerobject: customerObjectId,
    customer_contracts: customerContractIds
  });

export const createContractItemAPI = (
  contractId: number,
  payload: {
    type: IContractSummaryType;
    base_id: number;
    task_id: number | null;
    name: string;
    price: number;
    service_category_id: number | null;
  }
) =>
  fetchPost<IContractSummaryItem>(
    restUrl(`contracts/contracts/${contractId}/add_item/`),
    payload
  );

export const sortContractItemsAPI = (
  contractId: number,
  payload: {
    type: IContractSummaryType;
    item_id: number;
    sort_order: number;
  }[]
) =>
  fetchPatch<IContractSummaryItem>(
    restUrl(`contracts/contracts/${contractId}/sort_items/`),
    payload
  );

export const updateContractTaskAPI = (
  taskId: number,
  payload: Partial<Pick<Task, "work_description" | "customer_contracts">>
) => fetchPatch<TaskResponse>(restUrl(`contracts/tasks/${taskId}/`), payload);

export const deleteContractTaskAPI = (taskId: number) =>
  fetchDelete<TaskResponse>(restUrl(`contracts/tasks/${taskId}/`));

type ContractCooperationsAPIFilters = Partial<{
  contract_id: number;
  task_id: number;
}>;

export const getContractCooperationsAPI = (
  filters: ContractCooperationsAPIFilters
): Promise<ContractCooperationsResponse> => {
  const params = Object.fromEntries(
    Object.entries(filters)
      .filter(
        <K, V>(e: [K, V]): e is [K, Exclude<V, undefined>] => e[1] !== undefined
      )
      .map(([key, value]) => [`filter__${key}`, value])
  );
  return fetchGet<ContractCooperationsResponse>(
    restUrl("contracts/cooperations/", {
      ...params,
      filter__valid_contracts: true
    })
  );
};

export const getContractsTaskAPI = (id: number) =>
  fetchGet<TaskTabResponse>(
    restUrl("contracts/tasks/", { filter__customer_id: id })
  );

export const getContractorContractTasksAPI = (id: number) =>
  fetchGet<TasksResponse>(
    restUrl("contracts/tasks/", {
      filter__contractor_id: id.toString(),
      order__name: "asc"
    })
  );

export const getTaskByIdsAPI = (ids: number[]) =>
  fetchGet<MyTaskResponse>(
    restUrl("contracts/tasks/", { filter__id__in: ids })
  );

export const getTaskByCustomerobjectIdAPI = (customerobjectId: number) =>
  fetchGet<TasksResponse>(
    restUrl("contracts/tasks/", { filter__customerobject: customerobjectId })
  );

export const getSearchTaskOptionsAPI = (): Promise<TaskOption[]> =>
  fetchGet<TaskOption[]>(restUrl("contracts/tasks/options/"));

export const getTaskNamesAPI = (): Promise<Task[]> =>
  fetchGet<Task[]>(restUrl("contracts/tasks/options/"));

export const getContractorNamesAPI = () =>
  fetchGet<{ results: { id: number; name: string }[] }>(
    restUrl("companies/companies/", {
      include: ["name", "id"],
      filter__company_type__in: ["CONTRACTOR", "MATERIAL_SUPPLIER"],
      filter__status: "ACTIVE",
      order__name: "asc",
      page_size: 10000
    })
  );

export const getServiceCategoryNamesAPI =
  (): Promise<ServiceCategoriesResponse> =>
    fetchGet<ServiceCategoriesResponse>(
      restUrl("contracts/servicecategories/", { include: ["name", "id"] })
    );

export const createRowImageAPI = (payload: CreateRowImagePayload) =>
  fetchPost<TimesheetRowImageResponse>(
    restUrl("timesheets/timesheetrowimages/new/"),
    payload
  );

export const getRowImagesAPI = (filter: GetRowImagesQuery) =>
  fetchGet<TimesheetRowImageResponse>(
    restUrl("timesheets/timesheetrowimages/", filter)
  );

export const deleteRowImageAPI = (id: number) =>
  fetchDelete<void>(restUrl(`timesheets/timesheetrowimages/${id}`));

export const updateRowImageAPI = (id: number, payload: UpdateRowImagePayload) =>
  fetchPatch<TimesheetRowImage>(
    restUrl(`timesheets/timesheetrowimages/${id}/`),
    payload
  );

/*
 * Contractor Invoice Endpoints
 */

export const getContractorInvoiceAPI = (id: number): Promise<Invoice> =>
  fetchGet(restUrl(`invoices/contractorinvoices/${id}/`));

export const sendContractorInvoiceEmailAPI = (
  invoiceId: number,
  payload: { oca: number }
): Promise<{ oca: number }> =>
  fetchPost(
    restUrl(`invoices/contractorinvoices/${invoiceId}/send_email/`),
    payload
  );

export const updateContractorInvoiceStatusAPI = (
  id: number,
  payload: PatchInvoiceStatusPayload
): Promise<Invoice> =>
  fetchPatch(restUrl(`invoices/contractorinvoices/${id}/`), payload);

export const updateContractorInvoiceAPI = (
  id: number,
  payload: Partial<InvoiceSendData>
): Promise<Invoice> =>
  fetchPatch(restUrl(`invoices/contractorinvoices/${id}/`), payload);

export const updateCustomerInvoiceAPI = (
  id: number,
  payload: Partial<InvoiceSendData>
): Promise<Invoice> =>
  fetchPatch(restUrl(`invoices/customerinvoices/${id}/`), payload);

export const updateCustomerInvoiceItemStatusAPI = (
  invoiceId: number,
  payload: {
    invoice_item_id: number;
    invoice_item_object_type: string;
    disabled: boolean;
  }
): Promise<Invoice> =>
  fetchPost(
    restUrl(
      `invoices/customerinvoices/${invoiceId}/update_invoice_item_status/`
    ),
    payload
  );

export const updateContractorInvoiceItemStatusAPI = (
  invoiceId: number,
  payload: {
    invoice_item_id: number;
    invoice_item_object_type: string;
    disabled: boolean;
  }
): Promise<Invoice> =>
  fetchPost(
    restUrl(
      `invoices/contractorinvoices/${invoiceId}/update_invoice_item_status/`
    ),
    payload
  );

export const createManualInvoiceAPI = (
  type: "customerinvoices" | "contractorinvoices",
  payload: {
    contract: number;
    customerobject: number | null;
    period_start: string;
    period_end: string;
    copy_from_invoice_id: number | null;
    copy_quantity_factor: number | null;
  }
): Promise<Invoice> =>
  fetchPost(restUrl(`invoices/${type}/create_manual/`), payload);

export const createInvoiceManualItemAPI = (
  type: "customerinvoices" | "contractorinvoices",
  invoiceId: number,
  payload: {
    oca: number;
    title: string;
    date: string;
    sort_order: number;
    quantity: number;
  }
): Promise<Invoice> =>
  fetchPatch(restUrl(`invoices/${type}/${invoiceId}/create_manual/`), payload);

export const deleteInvoiceManualItemAPI = (
  type: "customerinvoices" | "contractorinvoices",
  invoiceId: number,
  payload: {
    oca: number;
    item_id: number;
  }
): Promise<Invoice> =>
  fetchPatch(restUrl(`invoices/${type}/${invoiceId}/delete_manual/`), payload);

export const patchInvoiceManualItemAPI = (
  type: "customerinvoices" | "contractorinvoices",
  invoiceId: number,
  payload: {
    oca: number;
    item_id: number;
    item_data: Partial<{
      title: string;
      line_break: boolean;
      date: string;
      in_time: string;
      out_time: string;
      quantity: number;
      unit_price: number;
      fortnox_data_index: number;
    }>;
  }
): Promise<Invoice> =>
  fetchPatch(restUrl(`invoices/${type}/${invoiceId}/patch_manual/`), payload);

export const updateInvoiceItemOrderAPI = (
  type: "customerinvoices" | "contractorinvoices",
  invoiceId: number,
  payload: {
    invoice_item_id: number;
    invoice_item_object_type: string;
    sort_order: number;
  }[]
): Promise<Invoice> =>
  fetchPost(
    restUrl(`invoices/${type}/${invoiceId}/update_invoice_items_order/`),
    payload
  );

export const getContractorInvoicePdfAPI = (id: number) =>
  fetchBlob(restUrl(`invoices/contractorinvoices/${id}/pdf/`));

export const createManualContractorInvoiceItem = (
  id: number,
  payload: ManualInvoiceItemCreatePayload
) =>
  fetchPatch<ManualInvoiceItem>(
    restUrl(`invoices/contractorinvoices/${id}/create_manual/`),
    payload
  );

export const deleteContractorManualInvoiceItem = (
  id: number,
  payload: ManualInvoiceItemDeletePayload
) =>
  fetchPatch<ManualInvoiceFormItem>(
    restUrl(`invoices/contractorinvoices/${id}/delete_manual/`),
    payload
  );

export const editContractorManualInvoiceItem = (
  id: number,
  payload: ManualInvoiceItemPatchPayload
) =>
  fetchPatch<ManualInvoiceFormItem>(
    restUrl(`invoices/contractorinvoices/${id}/patch_manual/`),
    payload
  );

/*
 * Customer Invoice Endpoints
 */

export const getInvoiceFortnoxTypesAPI = (
  invoiceType: "customerinvoices" | "contractorinvoices"
): Promise<{ id: string; name: string }[]> =>
  fetchGet(restUrl(`invoices/${invoiceType}/fortnox_types`));

export const getInvoicesAPI = (
  invoiceType: "customerinvoices" | "contractorinvoices",
  query: InvoiceFilterQuery
): Promise<InvoicesResponse> =>
  fetchGet(restUrl(`invoices/${invoiceType}/`, filterQueryParams(query)));

export const getCustomerInvoiceAPI = (id: number): Promise<Invoice> =>
  fetchGet(restUrl(`invoices/customerinvoices/${id}/`));

export const sendCustomerInvoiceEmailAPI = (
  invoiceId: number,
  payload: { oca: number }
): Promise<{ oca: number }> =>
  fetchPost(
    restUrl(`invoices/customerinvoices/${invoiceId}/send_email/`),
    payload
  );

export const updateCustomerInvoiceStatusAPI = (
  id: number,
  payload: PatchInvoiceStatusPayload
): Promise<Invoice> =>
  fetchPatch(restUrl(`invoices/customerinvoices/${id}/`), payload);

export const getCustomerInvoicePdfAPI = (id: number) =>
  fetchBlob(restUrl(`invoices/customerinvoices/${id}/pdf/`));

export const createManualCustomerInvoiceItem = (
  id: number,
  payload: ManualInvoiceItemCreatePayload
) =>
  fetchPatch<ManualInvoiceItem>(
    restUrl(`invoices/customerinvoices/${id}/create_manual/`),
    payload
  );

export const deleteCustomerManualInvoiceItem = (
  id: number,
  payload: ManualInvoiceItemDeletePayload
) =>
  fetchPatch<ManualInvoiceFormItem>(
    restUrl(`invoices/customerinvoices/${id}/delete_manual/`),
    payload
  );

export const editCustomerManualInvoiceItem = (
  id: number,
  payload: ManualInvoiceItemPatchPayload
) =>
  fetchPatch<ManualInvoiceFormItem>(
    restUrl(`invoices/customerinvoices/${id}/patch_manual/`),
    payload
  );

/*
 * Invoice Bulk update endpoints
 */

export const customerBulkInvoiceStatusUpdate = (
  payload: BulkInvoiceStatusUpdatePayload
) =>
  fetchPost<BulkInvoiceStatusReturn>(
    restUrl("invoices/customerinvoices/bulk_status/"),
    payload
  );

export const contractorBulkInvoiceStatusUpdate = (
  payload: BulkInvoiceStatusUpdatePayload
) =>
  fetchPost<BulkInvoiceStatusReturn>(
    restUrl("invoices/contractorinvoices/bulk_status/"),
    payload
  );

export const customerBulkSendEmailAPI = (payload: { invoice_ids: number[] }) =>
  fetchPost<BulkInvoiceStatusReturn>(
    restUrl("invoices/customerinvoices/bulk_send_email/"),
    payload
  );

export const contractorBulkSendEmailAPI = (payload: {
  invoice_ids: number[];
}) =>
  fetchPost<BulkInvoiceStatusReturn>(
    restUrl("invoices/contractorinvoices/bulk_send_email/"),
    payload
  );

/*
 * Invoicing profile endpoints
 */

export const getContractorInvoiceProfileTestPdfAPI = (
  id: number,
  contractorInvoiceId: number
) =>
  fetchBlob(
    restUrl(`invoices/invoicingprofiles/${id}/test_pdf/`, {
      contractorinvoice_id: contractorInvoiceId
    })
  );

export const getCustomerInvoiceProfileTestPdfAPI = (
  id: number,
  customerInvoiceId: number
) =>
  fetchBlob(
    restUrl(`invoices/invoicingprofiles/${id}/test_pdf/`, {
      customerinvoice_id: customerInvoiceId
    })
  );

/*
 * Contracting profile endpoints
 */

export const getContractProfileTestPdfAPI = (id: number, contractId: number) =>
  fetchBlob(
    restUrl(`contracts/contractingprofiles/${id}/test_pdf/`, {
      contract_id: contractId
    })
  );

/*
 * Invoice Report end points
 */

export const getCustomerInvoiceReportSummary = (payload: InvoiceReport) =>
  fetchPost<InvoiceReportSummary>(
    restUrl("invoices/customerreports/summary/"),
    payload
  );

export const getContractorInvoiceReportSummary = (payload: InvoiceReport) =>
  fetchPost<InvoiceReportSummary>(
    restUrl("invoices/contractorreports/summary/"),
    payload
  );

export const getContractorInvoiceReportPDFAPI = (payload: InvoiceReport) =>
  fetchPost<Blob>(restUrl("invoices/contractorreports/pdf/"), payload);

export const getCustomerInvoiceReportPDFAPI = (payload: InvoiceReport) =>
  fetchPost<Blob>(restUrl("invoices/customerreports/pdf/"), payload);

/*** END Invoice Report End Pointer ***/

export const getImageByURLAPI = (url: string) => fetchGet<Blob>(url);

export const getContractItemsAPI = (
  contractId: number
): Promise<IContractSummaryItem[]> =>
  fetchGet(restUrl(`contracts/contracts/${contractId}/items/`));

export const getContractBaseItemsAPI = (
  contractId: number
): Promise<IContractSummaryItem[]> =>
  fetchGet(restUrl(`contracts/contracts/${contractId}/items/`));

export const copyContractItemAPI = (
  contractId: number | undefined,
  contractItem: ICopyContractItem
) =>
  fetchPost<IContractSummaryItem>(
    restUrl(`contracts/contracts/${contractId}/copy_item/`),
    {
      type: contractItem.type,
      task_id: contractItem.task_id,
      item_id: contractItem.item_id,
      name: contractItem.name,
      price: contractItem.price,
      service_category_id: contractItem.service_category_id
    }
  );

export const getContractAPI = (contractId: number) =>
  fetchGet<Contract>(restUrl(`contracts/contracts/${contractId}/`));

export const getContractCompanyIdAPI = (contractId: number) =>
  fetchGet<Contract>(
    restUrl(`contracts/contracts/${contractId}/`, { include: ["company"] })
  );

type ContractsAPIFilters = Partial<{
  id__in: number[];
}>;

export const getContractNamesAPI = async (filters: ContractsAPIFilters) => {
  const params = Object.fromEntries(
    Object.entries(filters)
      .filter(
        <K, V>(e: [K, V]): e is [K, Exclude<V, undefined>] => e[1] !== undefined
      )
      .map(([key, value]) => [`filter__${key}`, value])
  );
  return await fetchGet<{ results: { id: number; name: string }[] }>(
    restUrl("contracts/contracts/", {
      ...params,
      include: ["id", "name"]
    })
  );
};

export const getActiveContractIdsByCompanyIdAPI = (companyId: number) =>
  fetchGet<{ results: { id: number }[] }>(
    restUrl("contracts/contracts/", {
      filter__company: companyId,
      filter__valid: true,
      include: ["id"]
    })
  );

export const getActiveContractsByCompanyIdsAPI = (companyIds: number[]) =>
  fetchGet<ContractsAPIResponse>(
    restUrl("contracts/contracts/", {
      filter__company__in: companyIds,
      filter__valid: true
    })
  );

export const getActiveContractsAPI = (companyType: CompanyType) =>
  fetchGet<{ results: { id: number; name: string; company: number }[] }>(
    restUrl("contracts/contracts/", {
      filter__valid: true,
      filter__company_type: companyType,
      page_size: 10000,
      include: ["id", "name", "company"]
    })
  );

// an endpoint used to delete single item from contract option(services, materials etc)
export const updateContractAPI = (
  contractId: number,
  payload: Partial<ContractEditPayload | ContractForm>
) =>
  fetchPatch<ContractForm>(
    restUrl(`contracts/contracts/${contractId}/`),
    payload
  );

export type ContractIndexFactors = {
  materials_price_factor: number;
  accessories_price_factor: number;
  supplements_price_factor: number;
  addons_price_factor: number;
  services_price_factor: number;
  servicepackages_price_factor: number;
};

export const adjustContractPricesAPI = (
  contractId: number,
  payload: ContractIndexFactors
): Promise<IContractSummaryItem[]> =>
  fetchPatch(
    restUrl(`contracts/contracts/${contractId}/adjust_prices/`),
    payload
  );

// Search Endpoints
export const searchSuggestionsAPI = (payload: SearchPayload) =>
  fetchPost<SuggestionsResponse>(restUrl("services/search_suggest/"), payload);

export const searchAPI = (payload: SearchPayload) =>
  fetchPost<SearchResponse>(restUrl("services/search/"), payload);

export const createRouteInstanceAPI = (payload: RouteInstanceForm) =>
  fetchPost<RouteInstance>(restUrl(`orders/routeinstances/`), payload);

export const deleteRouteInstanceAPI = (routeinstanceId: number) =>
  fetchDelete<RouteInstance>(
    restUrl(`orders/routeinstances/${routeinstanceId}`)
  );

export const dispatchRouteInstanceAPI = (payload: DispatchPayload) =>
  fetchPatch<RouteInstance>(
    restUrl(`orders/routeinstances/${payload.id}/`),
    payload
  );

/** EDIT ROUTE INSTANCE ORDERS */

export const editRouteInstanceOrdersAPI = (id: number, payload: Order[]) =>
  fetchPatch<RouteInstance>(
    restUrl(`orders/routeinstances/${id}/edit_orders/`),
    payload
  );

export const getUsersInfoAPI = (userIds: Array<number>) =>
  fetchGet<IUsersResponse>(
    restUrl("auth/users/", {
      filter__id__in: userIds,
      include: ["avatar", "username", "id", "person"]
    })
  );

export const getUsersAPI = (userIds: number[]) =>
  fetchGet<UsersResponse>(
    restUrl("auth/users/", {
      filter__id__in: userIds
    })
  );

export const getPersonsInfoAPI = (personIds: Array<number>) =>
  fetchGet<IPersonsResponse>(
    restUrl("auth/persons/", {
      filter__id__in: personIds,
      include: ["first_name", "last_name", "id"]
    })
  );

export const getCompanyUsersAPI = (companyId: number) =>
  fetchGet<ICompanyUsersResponse>(
    restUrl("auth/users/", { filter__company: companyId, include: ["id"] })
  );

export const getCompanyByIdAPI = (companyId: number) =>
  fetchGet<Company>(restUrl(`companies/companies/${companyId}/`));

export const updateCompanyAPI = (
  companyId: number,
  payload: Partial<Company | CompanyForm>
) => fetchPatch<Company>(restUrl(`companies/companies/${companyId}/`), payload);

type CompaniesAPIFilters = Partial<{
  id__in: number[];
  company_type: CompanyType;
}>;

export const getCompanyNamesAPI = async (filters: CompaniesAPIFilters) => {
  const params = Object.fromEntries(
    Object.entries(filters)
      .filter(
        <K, V>(e: [K, V]): e is [K, Exclude<V, undefined>] => e[1] !== undefined
      )
      .map(([key, value]) => [`filter__${key}`, value])
  );
  return await fetchGet<{ results: { id: number; name: string }[] }>(
    restUrl("companies/companies/", {
      ...params,
      filter__status: "ACTIVE",
      include: ["id", "name"]
    })
  );
};

export const getSearchContractorOptionsAPI = (searchTerm: string) =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", {
      filter__company_type__in: ["CONTRACTOR", "MATERIAL_SUPPLIER"],
      include: ["id", "name"],
      filter__name__icontains: searchTerm
    })
  );

export const getCompanyOptionsByTypeAPI = (companyType: string[]) =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", {
      filter__company_type__in: companyType,
      include: ["id", "name", "districts", "company_type"],
      order__name: "asc",
      page_size: 10000
    })
  );

export const getSearchCompanyOptionsByTypeAPI = (
  companyType: string[],
  page: number,
  searchTerm?: string
) =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", {
      filter__company_type__in: companyType,
      include: ["id", "name", "districts"],
      filter__name__icontains: searchTerm,
      order__name: "asc",
      page,
      page_size: 10
    })
  );

export const getSearchCustomerobjectOptionsAPI = () =>
  fetchGet<{ results: { id: number; name: string }[] }>(
    restUrl("contracts/customerobjects/", {
      include: ["id", "name"],
      filter__status: "ACTIVE",
      order__name: "asc"
    })
  );
export const getCustomerobjectByTaskAPI = (taskId: number) =>
  fetchGet<ICustomerobjectsResponse>(
    restUrl("contracts/customerobjects/", {
      filter__task: taskId
    })
  );

export const getCustomerobjectByIdsAPI = (customerobjectIds: number[]) =>
  fetchGet<ICustomerobjectsResponse>(
    restUrl("contracts/customerobjects/", {
      filter__id__in: customerobjectIds
    })
  );

export const getCustomerobjectByCompanyIdsAPI = (companyIds: number[]) =>
  fetchGet<ICustomerobjectsResponse>(
    restUrl("contracts/customerobjects/", {
      filter__customer__in: companyIds
    })
  );

export const getCustomerObjectAPI = (customerobjectId: number) =>
  fetchGet<Customerobject>(
    restUrl(`contracts/customerobjects/${customerobjectId}/`)
  );

export const updateCustomerobjectAPI = (
  customerobjectId: number,
  payload: Partial<Customerobject | CustomerobjectForm>
) =>
  fetchPatch<Customerobject>(
    restUrl(`contracts/customerobjects/${customerobjectId}/`),
    payload
  );

export const patchProspectAPI = (
  prospectId: number,
  prospectForm: ProspectForm
) =>
  fetchPatch<Prospect>(
    restUrl(`contracts/prospects/${prospectId}/`),
    prospectForm
  );

export const finalizeProspectAPI = (prospectId: number, oca: number) =>
  fetchPost<Prospect>(restUrl(`contracts/prospects/${prospectId}/finalize/`), {
    oca
  });

export const getProspectAPI = (prospectId: number) =>
  fetchGet<Prospect>(restUrl(`contracts/prospects/${prospectId}/`));

export const deleteProspectAPI = (prospectId: number) =>
  fetchDelete<void>(restUrl(`contracts/prospects/${prospectId}/`));

export const getTaskoptionsAPI = (businessareaId: number) =>
  fetchGet<TaskOptionsResponse>(
    restUrl("contracts/taskoptions/", {
      filter__businessarea: businessareaId
    })
  );

export const getBusinessareasAPI = () =>
  fetchGet<{ results: { id: number; name: string }[] }>(
    restUrl("contracts/businessareas/", {
      include: ["id", "name"],
      order__name: "asc"
    })
  );

export const getCustomersAPI = () =>
  fetchGet<CompaniesResponse>(
    restUrl("companies/companies/", {
      include: ["id", "name"],
      filter__status: "ACTIVE",
      filter__company_type: "CUSTOMER",
      order__name: "asc"
    })
  );

export const getCompanyOptionsAPI = () =>
  fetchGet<CompaniesResponse>(
    restUrl("companies/companies/", {
      include: ["id", "name"],
      filter__status: "ACTIVE",
      order__name: "asc"
    })
  );

export const getContractorsAPI = () =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", { filter__company_type: "CONTRACTOR" })
  );

export const getMaterialSuppliersAPI = () =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", {
      include: ["id", "name"],
      filter__company_type: "MATERIAL_SUPPLIER"
    })
  );

export const getSearchContractorsAPI = (
  page: number = 1,
  searchTerm?: string
) =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", {
      order__name: "asc",
      filter__company_type: "CONTRACTOR",
      filter__status: "ACTIVE",
      page: page,
      page_size: 10,
      include: ["id", "name"],
      filter__name__icontains: searchTerm || ""
    })
  );

export const getCompaniesByIdAPI = (companyIds: number[]) =>
  fetchGet<CompaniesResponse>(
    restUrl("companies/companies/", {
      order__name: "asc",
      filter__status: "ACTIVE",
      filter__id__in: companyIds
    })
  );

export const getSearchContractorsSimpleAPI = (searchTerm?: string) =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", {
      order__name: "asc",
      filter__company_type: "CONTRACTOR",
      filter__status: "ACTIVE",
      filter__name__icontains: searchTerm || ""
    })
  );

export const getFilteredCompaniesAPI = () =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", {
      filter__company_type: EnumStrings.DOMAIN_OWNER
    })
  );

export const getContractorCompaniesAPI = (ids: number[]) =>
  fetchGet<ICompaniesResponse>(
    restUrl("companies/companies/", { filter__id__in: ids })
  );

export const saveNotificationProfileAPI = (payload: INotificationProfileForm) =>
  fetchPost<INotificationProfileForm>(
    restUrl("services/notificationsprofiles/"),
    payload
  );

export const getNotificationProfilesAPI = () =>
  fetchGet<INotificationProfiles>(restUrl("services/notificationsprofiles/"));

export const getNotificationProfileAPI = (id: number) =>
  fetchGet<INotificationProfile>(
    restUrl(`services/notificationsprofiles/${id}/`)
  );

export const editNotificationProfileAPI = (
  id: number,
  payload: INotificationProfileForm
) =>
  fetchPatch<INotificationProfileForm>(
    restUrl(`services/notificationsprofiles/${id}/`),
    payload
  );

export const createNotificationSettingAPI = (
  payload: INotificationSettingForm
) => fetchPost<void>(restUrl("services/notificationsettings/"), payload);

export const getNotificationSettingsByProfileIdAPI = (
  notificationProfileId: number
) =>
  fetchGet<INotificationSettingsResponse>(
    restUrl("services/notificationsettings/", {
      filter__profile: notificationProfileId
    })
  );

export const getNotificationSettingAPI = (notificationSettingId: number) =>
  fetchGet<INotificationSettingDisplay>(
    restUrl(`services/notificationsettings/${notificationSettingId}/`)
  );

export const deleteNotificationSettingAPI = (notificationSettingId: number) =>
  fetchDelete<void>(
    restUrl(`services/notificationsettings/${notificationSettingId}/`)
  );

export const updateNotificationSettingAPI = (
  notificationSettingId: number,
  payload: INotificationSettingForm
) =>
  fetchPatch<INotificationProfileForm>(
    restUrl(`services/notificationsettings/${notificationSettingId}/`),
    payload
  );

export const getContractItemGenericAPI = (
  type: string,
  id: number
): Promise<IContractItemType> => {
  const key = `${type.toLowerCase().replace(/y$/, "ie")}s`;
  return fetchGet(restUrl(`contracts/${key}/${id}/`));
};

type ServiceAPIFilters = Partial<{
  id__in: number[];
  contract: number;
  contract__company: number;
}>;

export const getServicesAPI = (
  filters: ServiceAPIFilters
): Promise<ServicesResponse> => {
  const params = Object.fromEntries(
    Object.entries(filters)
      .filter(
        <K, V>(e: [K, V]): e is [K, Exclude<V, undefined>] => e[1] !== undefined
      )
      .map(([key, value]) => [`filter__${key}`, value])
  );
  return fetchGet<ServicesResponse>(restUrl("contracts/services/", params));
};

type ContractItemTypeLookup = {
  services: Service;
  materials: Material;
  accessories: Accessory;
  servicepackages: Servicepackage;
  supplements: Supplement;
  addons: Addon;
};

export const updateContractItemAPI = <T extends keyof ContractItemTypeLookup>(
  type: T,
  id: number,
  patch: Partial<Omit<ContractItemTypeLookup[T], "id">>
): Promise<ContractItemTypeLookup[T]> =>
  fetchPatch<ContractItemTypeLookup[T]>(restUrl(`contracts/${type}/${id}/`), {
    ...patch,
    id
  });

export const deleteContractItemAPI = (
  type: keyof ContractItemTypeLookup,
  id: number
): Promise<void> => fetchDelete<void>(restUrl(`contracts/${type}/${id}/`));

export const patchServiceAPI = (serviceId: number, payload: Partial<Service>) =>
  fetchPatch<Service>(restUrl(`contracts/services/${serviceId}/`), payload);

export const patchMaterialAPI = (
  materialId: number,
  payload: Partial<Material>
) =>
  fetchPatch<Material>(restUrl(`contracts/materials/${materialId}/`), payload);

export const patchAddonAPI = (addonId: number, payload: Partial<Addon>) =>
  fetchPatch<Addon>(restUrl(`contracts/addons/${addonId}/`), payload);

export const patchAccessoryAPI = (
  accessoryId: number,
  payload: Partial<Accessory>
) =>
  fetchPatch<Accessory>(
    restUrl(`contracts/accessories/${accessoryId}/`),
    payload
  );

export const patchServicepackageAPI = (
  servicepackageId: number,
  payload: Partial<Servicepackage>
) =>
  fetchPatch<Servicepackage>(
    restUrl(`contracts/servicepackages/${servicepackageId}/`),
    payload
  );

export const patchSupplementAPI = (
  supplementId: number,
  payload: Partial<Supplement>
) =>
  fetchPatch<Supplement>(
    restUrl(`contracts/supplements/${supplementId}/`),
    payload
  );

export const getContractMaterialsAPI = (
  filters: IQuery<"filter__id__in" | "filter__contract">
) => fetchGet<ContractItemResponse>(restUrl("contracts/materials/", filters));

export const getMaterialsAPI = (
  filters: IQuery<"filter__id__in" | "filter__contract">
) => fetchGet<MaterialsResponse>(restUrl("contracts/materials/", filters));

export const getContractSupplementsAPI = (contractId: number) =>
  fetchGet<ContractItemResponse>(
    restUrl("contracts/supplements/", { filter__contract: contractId })
  );

export const getContractAccessoriesAPI = (contractId: number) =>
  fetchGet<ContractItemResponse>(
    restUrl("contracts/accessories/", { filter__contract: contractId })
  );

export const createContractCooperationAPI = ({
  taskId,
  contractId
}: {
  taskId: number;
  contractId: number;
}) =>
  fetchPost<ContractCooperation>(restUrl("contracts/cooperations/"), {
    task: taskId,
    contract: contractId
  });

export const deleteContractCooperationAPI = (cooperationId: number) =>
  fetchDelete<void>(restUrl(`contracts/cooperations/${cooperationId}`));

export const getTaskAPI = (id: number) =>
  fetchGet<TaskFormResponse>(restUrl(`contracts/tasks/${id}/`));

export const getPersonsByCompanyAPI = (companyId: number) =>
  fetchGet<PersonFormResponse>(
    restUrl("auth/persons/", {
      order__first_name: "asc",
      filter__company: companyId
    })
  );

export const getPersonsAPI = (filter?: IQuery<IPersonQnum>) =>
  fetchGet<IPaginationResponse<Person>>(restUrl("auth/persons/", filter));

export const getPersonAPI = (id: number) =>
  fetchGet<Person>(restUrl(`auth/persons/${id}/`));

export const patchPersonNotesAPI = (id: number, notes: string | null) =>
  fetchPatch<PersonForm>(restUrl(`auth/persons/${id}/`), { notes });

export const getCompanyPersonsAPI = (companyId: number) =>
  fetchGet<PersonFormResponse>(
    restUrl("auth/persons/", { filter__company: companyId })
  );

export const getUserAPI = (id: number) =>
  fetchGet<User>(restUrl(`auth/users/${id}`));

export const getContractPdfAPI = (id: number, taskId: number | undefined) => {
  const queryParams = taskId
    ? `?task_id=${taskId}`
    : "";
  return fetchBlob(restUrl(`contracts/contracts/${id}/pdf/${queryParams}`));
}

export const getTasksByFilterIds = (ids: number[]) =>
  fetchGet<TasksResponse>(
    restUrl("contracts/tasks/", { filter__id__in: ids, order__name: "asc" })
  );

export const getContractsBusinessareaAPI = (id: number) =>
  fetchGet<TaskTabResponse>(
    restUrl("contracts/tasks/", { filter__businessarea__id: id })
  );

export const getInvoiceRecipientsEmails = () =>
  fetchGet<InvoiceRecipientsResponse>(
    restUrl("companies/invoicerecipients/", { include: ["email"] })
  );

export const getEmailsAPI = (filter: IQuery<IPaginationQnum> = {}) =>
  fetchGet<EmailsResponse>(restUrl("services/emails/", filter));

export const getEmailAPI = (id: number) =>
  fetchGet<Email>(restUrl(`services/emails/${id}/`));

export const fetchAllowedPaths = () =>
  fetchGet<string[]>(restUrl("auth/permissions/allowed_paths/"));

export const domainAPI = (domainURL: string, domain: string) =>
  fetchGet<DomainResponse>(`${domainURL}/${domain}`);

export const loginAPI = (payload: LoginAPIPayload) =>
  fetchPost<AuthenticationResponse>(restUrl("token/"), payload);

export const loginAdhocAPI = (payload: LoginAdhocAPIPayload) =>
  fetchPost<AuthenticationResponse>(
    restUrl("auth/users/login_as_user/"),
    payload
  );

export const logoutAPI = (payload: LogoutAPIPayload) =>
  fetchPost<void>(restUrl("token/revoke/"), payload);

export const resetPassword = (id: number, payload: { password: string }) =>
  fetchPatch<any>(restUrl(`auth/users/${id}/set_password/`), payload);

export const sendResetPasswordEmailAPI = (username: string) =>
  fetchPost<any>(restUrl("auth/users/search_lost_pwd_reset/"), {
    username_or_email: username
  });

export const sendPasswordResetEmailByIdAPI = (id: number) =>
  fetchPost(restUrl(`auth/users/${id}/send_pwd_reset_email/`));

export const getContractingProfileAPI = (id: number) =>
  fetchGet<ContractingProfile>(restUrl(`contracts/contractingprofiles/${id}`));

export const getReportedCommentsAndImagesAPI = (id: number) =>
  fetchGet<IReportedCommentsAndImages[]>(
    restUrl(`orders/routeinstances/${id}/reported_comments_and_images/`)
  );

export const getAllRoutePlanNamesAPI = () =>
  fetchGet<{ results: { id: number; oca: number; name: string }[] }>(
    restUrl("orders/routeplans/", {
      page_size: 10000,
      include: ["id", "name", "oca"],
      order__name: "asc"
    })
  );

export const getAllRoutePlansAPI = () =>
  fetchGet<RoutePlansResponse>(
    restUrl("orders/routeplans/", { page_size: 10000 })
  );

export const getSearchRoutePlansAPI = (searchTerm: string) =>
  fetchGet<RoutePlansResponse>(
    restUrl("orders/routeplans/", {
      filter__name__icontains: searchTerm
    })
  );

export const createRoutePlanAPI = (
  routePlan: RoutePlanForm
): Promise<{ id: number }> =>
  fetchPost(restUrl("orders/routeplans/"), routePlan);

export const updateRoutePlanAPI = (
  id: number,
  oca: number,
  routePlan: RoutePlanForm
): Promise<RoutePlanForm> =>
  fetchPatch(restUrl(`orders/routeplans/${id}/`), { ...routePlan, oca });

export const getRoutePlanAPI = (id: number): Promise<RoutePlan> =>
  fetchGet(restUrl(`orders/routeplans/${id}/`));

export const getEmailAttachmentFromURL = (url: string) => fetchBlob(url);

export const getDistrictsAPI = () =>
  fetchGet<DistrictsResponse>(
    restUrl("companies/districts/", { order__name: "asc" })
  );

export const getApplicableServicePackageEventsAPI = (submittedWorkId: number) =>
  fetchGet<IApplicableServicePackageEvents>(
    restUrl(
      `timesheets/submittedworks/${submittedWorkId}/get_applicable_service_package_events/`
    )
  );

export const getAvailableServicePackageEventsAPI = (submittedWorkId: number) =>
  fetchGet<IAvailableServicePackageEvents>(
    restUrl(
      `timesheets/submittedworks/${submittedWorkId}/get_available_service_packages/`
    )
  );

export const patchPackageEventAddSubmittedWorkAPI = (
  servicePackageEventId: number,
  filters: IQuery<IPackageAddOrRemoveQnum>
) =>
  fetchPatch<IServicePackageEventResponse>(
    restUrl(
      `timesheets/servicepackageevents/${servicePackageEventId}/add_submitted_work/`
    ),
    filters
  );

export const patchPackageEventRemoveSubmittedWorkAPI = (
  servicePackageEventId: number,
  filters: IQuery<IPackageAddOrRemoveQnum>
) =>
  fetchPatch<TimeSheetForm>(
    restUrl(
      `timesheets/servicepackageevents/${servicePackageEventId}/remove_submitted_work/`
    ),
    filters
  );

export const postServicePackageEventAPI = (
  payload: IAddServicePackagePayload
) =>
  fetchPost<IServicePackageEventResponse>(
    restUrl("timesheets/servicepackageevents/"),
    {
      title: payload.title,
      package: payload.package,
      customer_submitted: payload.customer_submitted,
      contractor_submitted: payload.contractor_submitted,
      default_for_route: payload.default_for_route
    }
  );

export const getEventsAPI = (query?: EventsFilterQuery) =>
  fetchGet<EventsResponse>(restUrl("events/events/", query));
