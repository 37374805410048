import { LayoutRouteUsage } from "../../../redux/types";
import ContractsContainer from "./ContractsContainer";
import ContractNewFormContainer from "./ContractNewFormContainer";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import DescriptionIcon from "@material-ui/icons/Description";
import ContractPDFViewer from "../../../components/contracts/contracts/ContractPDFViewer";
import ContractContainer from "../../../components/contracts/contracts/Contract";

const ContractsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/contracts"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/contracts",
        label: t("contractNavbarContractsLabel"),
        component: ContractsContainer,
        icon: DescriptionIcon,
        highlightClass: NavItemClassEnum.CONTRACTS,
        navId: NavItemClassEnum.CONTRACTS
      },
      {
        path: "/contracts/contracts/new",
        parent: "/contracts/contracts",
        label: t("newContractLabel"),
        component: ContractNewFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTS
      },
      {
        path: "/contracts/contracts/:contractId",
        parent: "/contracts/contracts",
        label: t("contractLabel"),
        component: ContractContainer,
        highlightClass: NavItemClassEnum.CONTRACTS
      },
      {
        path: "/contracts/contracts/:contractId/show-pdf",
        parent: "/contracts/contracts",
        label: t("contractLabel"),
        component: ContractPDFViewer,
        highlightClass: NavItemClassEnum.CONTRACTS
      },
      {
        path: "/contracts/contracts/:contractId/:taskId/show-pdf",
        parent: "/contracts/contracts",
        label: t("contractLabel"),
        component: ContractPDFViewer,
        highlightClass: NavItemClassEnum.CONTRACTS
      },
    ]
  }
];

export default ContractsRouter;
