import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Person,
  ResourcesButtonDefinition,
  Company,
  User,
  CompanyType,
  SelectFieldOption
} from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import {
  EnumStrings,
  ReduxActionStrings,
  RestStrings
} from "../../redux/strings";
import { useTranslate } from "../../services/appLanguageService";
import * as Yup from "yup";
import _ from "lodash";
import ResourcesTableContainer from "../../components/resourcetable/ResourcesTableContainer";
import { validateApiUsage } from "../../helpers/routesHelper";
import { getFromSessionStore } from "../../services/storageService";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { getCompanyOptionsAPI } from "../../services/api-declaration";

interface PersonsContainerProps {
  persons: Person[];
  companies: Company[];
  users: User[];
  count: number;
  loadingPersons: boolean;
  personIds?: number[];
  companyId?: number;
  resetPersons: () => void;
  loadPersons: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  loadCompanies: (companyIds: number[]) => void;
  loadUsers: (userIds: number[]) => void;
  showNewButton?: boolean;
  customHeader?: string;
  showResourceButton?: boolean;
  slimmedDown?: boolean;
  addPerson: (newData: any, owningId?: number, secondOwningId?: number) => void;
  updatePerson: (personId: number, newData: any) => void;
  deletePerson: (personId: number) => void;
  editable?: boolean;
  customerobjectId?: number;
  forCompanyType?: CompanyType;
}

const PersonsContainer: React.FC<PersonsContainerProps> = (props) => {
  const {
    personIds,
    loadPersons,
    resetPersons,
    loadingPersons,
    persons,
    companies,
    loadCompanies,
    loadUsers,
    users,
    companyId,
    editable,
    customerobjectId,
    forCompanyType,
    slimmedDown
  } = props;

  let titles = [
    EnumStrings.CONTACT,
    EnumStrings.CONFIRMED_BUYER,
    EnumStrings.ADMINISTRATIVE_CONTACT,
    EnumStrings.ADMINISTRATIVE_CONTACT_AND_CONFIRMED_BUYER,
    EnumStrings.DRIVER,
    EnumStrings.TEAM_LEADER,
    EnumStrings.DRIVER_AND_TEAM_LEADER
  ];

  if (forCompanyType && forCompanyType === EnumStrings.CUSTOMER) {
    titles = [
      EnumStrings.CONTACT,
      EnumStrings.CONFIRMED_BUYER,
      EnumStrings.ADMINISTRATIVE_CONTACT,
      EnumStrings.ADMINISTRATIVE_CONTACT_AND_CONFIRMED_BUYER
    ];
  } else if (forCompanyType && forCompanyType === EnumStrings.CONTRACTOR) {
    titles = [
      EnumStrings.CONTACT,
      EnumStrings.DRIVER,
      EnumStrings.TEAM_LEADER,
      EnumStrings.DRIVER_AND_TEAM_LEADER
    ];
  }

  const t = useTranslate("PersonsPage");
  const t2 = useTranslate("Persons");
  const t3 = useTranslate("ValidationErrorMessages");

  const page = 1;
  const pageSize = 20;

  const [filteredCompanyValue, setFilteredCompanyValue] =
    useState<SelectFieldOption>({
      label: "",
      value: 0
    });
  const [companyOptions, setCompanyOptions] = useState<SelectFieldOption[]>([]);

  let filter = "";

  if (companyId) {
    filter = `&filter__company=${companyId}`;
  } else if (filteredCompanyValue.value !== 0) {
    filter = `&filter__company=${filteredCompanyValue.value}`;
  }

  if (personIds) {
    const filterStr = personIds.length !== 1 ? "filter__id__in" : "filter__id";
    const personIdsStr = personIds.length ? personIds.toString() : "";

    filter = `${filter}&${filterStr}=${personIdsStr}`;
  }

  useEffect(() => {
    if (!slimmedDown) {
      let isAlive = true;

      getCompanyOptionsAPI().then(
        (companiesResponse) =>
          isAlive &&
          setCompanyOptions(
            companiesResponse.results.map((company) => ({
              label: company.name,
              value: company.id
            }))
          )
      );
      return () => {
        isAlive = false;
      };
    }
  }, [slimmedDown]);

  const autocompleteSelect = (
    <Autocomplete
      value={filteredCompanyValue}
      onChange={(event: any, newCompany: SelectFieldOption | null) => {
        setFilteredCompanyValue(newCompany || { label: "", value: 0 });
      }}
      options={companyOptions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label={t("filterCompanyLabel")} />
      )}
    />
  );

  useEffect(
    () => loadPersons(page, pageSize, undefined, undefined, filter),
    [filter, loadPersons]
  );
  useEffect(() => {
    const companyIds = _.uniq(
      persons.map((person) => person.company || -1)
    ).filter((companyId) => companyId !== -1);
    loadCompanies(companyIds);
  }, [loadCompanies, persons]);

  useEffect(() => () => resetPersons(), [resetPersons]);

  useEffect(() => {
    const userIds = _.uniq(persons.map((person) => person.user || -1)).filter(
      (userId) => userId !== -1
    );
    loadUsers(userIds);
  }, [loadUsers, persons]);

  const columnDefinitions = slimmedDown
    ? [
        { name: "image", type: "roundThumbnail" },
        {
          name: "first_name",
          type: "joined",
          joined: "last_name",
          editableForm: "text",
          validation: Yup.string()
            .min(1, t3("minLengthError"))
            .max(255, t3("max255LengthError"))
            .required(t3("requiredError")),
          sortable: true
        },
        {
          name: "last_name",
          type: "hidden",
          editableForm: "text",
          validation: Yup.string()
            .max(255, t3("max255LengthError"))
            .nullable()
            .notRequired()
        },
        {
          name: "title",
          type: "enum",
          editableForm: "select",
          options: titles.map((title) => ({
            label: t2(title),
            value: title
          })),
          validation: Yup.string()
            .oneOf(titles, t3("notValidEnumValueError"))
            .required(t3("requiredError"))
        },
        {
          name: "email",
          type: "string",
          editableForm: "text",
          validation: Yup.string()
            .max(150, t3("max150LengthError"))
            .email(t3("emailInvalidError"))
            .nullable()
            .notRequired()
        },
        {
          name: "phone1",
          type: "string",
          editableForm: "text",
          validation: Yup.string()
            .max(15, t3("max15LengthError"))
            .matches(/^[0-9\s\-+()]*$/, t3("invalidPhoneNumberError"))
            .nullable()
            .notRequired()
        },
        {
          name: "is_key_person",
          type: "boolean",
          editableForm: "star"
        }
      ]
    : [
        { name: "image", type: "roundThumbnail" },
        {
          name: "first_name",
          type: "joined",
          joined: "last_name",
          editableForm: "text",
          sortable: true
        },
        { name: "last_name", type: "hidden", editableForm: "text" },
        {
          name: "company",
          type: "foreignLink",
          foreignData: {
            data: companies,
            visibleColumns: ["name"],
            url: "companies/companies"
          }
        },
        {
          name: "user",
          type: "foreign",
          foreignData: {
            data: users,
            visibleColumns: ["username"],
            url: ""
          }
        },
        { name: "email", type: "string" },
        { name: "phone1", type: "string" },
        { name: "title", type: "enum" },
        { name: "status", type: "enum" }
      ];

  const isSuperuser = getFromSessionStore("is_superuser") === "true";
  const readonly =
    !isSuperuser && !validateApiUsage({ apiUsage: ["auth/persons"] });

  const showNewButton =
    !readonly &&
    ((typeof props.showNewButton !== "undefined" && props.showNewButton) ||
      typeof props.showNewButton === "undefined");

  const showResourceButton =
    !readonly &&
    ((typeof props.showResourceButton !== "undefined" &&
      props.showResourceButton) ||
      typeof props.showResourceButton === "undefined");

  const resourcesButtons: ResourcesButtonDefinition[] = showResourceButton
    ? [
        {
          name: "groups",
          url: "auth/groups"
        }
      ]
    : [];

  return (
    <ResourcesTableContainer
      resources={persons}
      resourceName={"Person"}
      count={props.count}
      loadingResources={loadingPersons}
      routeUrl={"auth/persons"}
      columnDefinitions={columnDefinitions}
      resetResources={resetPersons}
      loadResources={loadPersons}
      customFilter={filter}
      filterDefinitions={[]}
      customHeader={props.customHeader}
      defaultPageSize={pageSize}
      resourcesButtons={resourcesButtons}
      showNewButton={showNewButton}
      addResource={editable ? props.addPerson : undefined}
      updateResource={editable ? props.updatePerson : undefined}
      deleteResource={editable ? props.deletePerson : undefined}
      owningId={companyId}
      secondOwningId={customerobjectId}
      customHeaderComponent={slimmedDown ? undefined : autocompleteSelect}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadPersons: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    let searchFilter = searchStr
      ? `&filter__first_name__icontains=${searchStr}`
      : "";
    if (searchStr?.includes(" ")) {
      const d = searchStr.match(/^([^\s]+)\s+(.+)/);
      if (d) {
        const [, pre, post] = d;
        searchFilter = `&filter__first_name__icontains=${pre}&filter__last_name__icontains=${post}`;
      }
    }

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__first_name=${orderingStr}`
        : "&order__first_name=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSONS,
        `auth/persons/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  loadCompanies: (companyIds: number[]) => {
    if (companyIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.PERSON_COMPANIES,
          `companies/companies/?include=["id","name"]&filter__id__in=[${companyIds}]`
        )
      );
    }
  },
  loadUsers: (userIds: number[]) => {
    if (userIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.USERS,
          `auth/users/?include=["id","username"]&filter__id__in=[${userIds}]`
        )
      );
    }
  },
  addPerson: (newData: any, owningId?: number, secondOwningId?: number) => {
    if (owningId) {
      dispatch(
        RestActions.restRequestWithData(
          ownProps.tabId,
          RestStrings.POST,
          ReduxActionStrings.PERSON,
          "auth/persons/",
          {
            status: EnumStrings.ACTIVE,
            title: newData.title,
            first_name: newData.first_name,
            last_name: newData.last_name,
            phone1: newData.phone1,
            phone2: null,
            email: newData.email,
            personal_code: null,
            is_key_person: newData.is_key_person,
            notes: null,
            user: null,
            company: owningId,
            customerobjects: secondOwningId ? [secondOwningId] : []
          }
        )
      );
    }
  },
  updatePerson: (personId: number, newData: any) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.PERSON,
        `auth/persons/${personId}/`,
        {
          title: newData.title,
          first_name: newData.first_name,
          last_name: newData.last_name,
          phone1: newData.phone1,
          email: newData.email,
          is_key_person: newData.is_key_person
        }
      )
    );
  },
  deletePerson: (personId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.PERSON,
        `auth/persons/${personId}/`,
        undefined,
        undefined,
        personId
      )
    );
  },
  resetPersons: () =>
    dispatch(LoadingBooleansActions.ResetPersons(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  persons: state.tabStates[ownProps.tabId].persons.persons.persons,
  companies: state.tabStates[ownProps.tabId].persons.companies,
  users: state.tabStates[ownProps.tabId].users.users.users,
  count: state.tabStates[ownProps.tabId].persons.persons.count,
  loadingPersons: state.tabStates[ownProps.tabId].loadingBooleans.loadingPersons
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonsContainer);
