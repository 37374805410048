import { createSuccessActionType } from "../actionTypes";
import { CustomerobjectsState, Action } from "../../types";
import { RestStrings, ReduxActionStrings } from "../../strings";

const initialState: CustomerobjectsState = {
  customerobjects: {
    customerobjects: [],
    count: 0,
    next: null,
    previous: null
  },
  allCustomerobjects: [],
  customerobject: {
    customerobject: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      status: "ACTIVE",
      customer: -1,
      contact_person: -1,
      customer_object_type: "PROPERTY_USER",
      passcard_type: "PASSCARD",
      district_name: null,
      notes: "",
      allow_photos: true,
      invoice_name: "",
      weekday_opening: "",
      weekday_closing: "",
      saturday_opening: "",
      saturday_closing: "",
      sunday_opening: "",
      sunday_closing: "",
      location: null,
      passcards: [],
      persons: [],
      tasks: [],
      company_logo: null,
      invoice_location: null,
      invoice_address_enabled: false,
      accounting_reference: null,
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CUSTOMEROBJECTS
    ):
      return {
        ...state,
        customerobjects: {
          ...state.customerobjects,
          customerobjects: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ALL_CUSTOMEROBJECTS
    ):
      return {
        ...state,
        allCustomerobjects: action.payload.results
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CUSTOMEROBJECT
    ):
      return {
        ...state,
        customerobject: {
          ...state.customerobject,
          customerobject: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.CUSTOMEROBJECT
    ):
      return {
        ...state,
        customerobjects: {
          ...state.customerobjects,
          customerobjects: [
            ...state.customerobjects.customerobjects,
            action.payload
          ]
        },
        customerobject: {
          customerobject: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.CUSTOMEROBJECT
    ):
      return {
        ...state,
        customerobjects: {
          ...state.customerobjects,
          customerobjects: [
            ...state.customerobjects.customerobjects.map((customerobject) =>
              customerobject.id === action.payload.id
                ? action.payload
                : customerobject
            )
          ]
        },
        customerobject: {
          customerobject: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.CUSTOMEROBJECT
    ):
      return {
        ...state,
        customerobjects: {
          ...state.customerobjects,
          customerobjects: [
            ...state.customerobjects.customerobjects.filter(
              (customerobject) =>
                customerobject.id !== parseInt(action.payload.id)
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
