import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core";
// import { useTranslate } from "../../services/appLanguageService";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { EventRelation } from "../../redux/types";
import ResourceLink from "../links/ResourceLink";
import { fetchGet } from "../../services/fetchWrapper";
import { restUrl } from "../../services/api-declaration";

const styles = (theme: any) => createStyles({});

interface EventRelationLinkProps extends WithStyles<typeof styles> {
  eventRelation: EventRelation;
}

const EventRelationLink: React.FC<EventRelationLinkProps> = (props) => {
  // const t = useTranslate("EventlogPage");
  const { eventRelation } = props;
  const [relationObject, setRelationObject] = useState<any>();

  let backendUrl = "";
  let frontendUrl = "";
  let visibleAttribute = "";

  switch (eventRelation.object_type) {
    case "COMPANY":
      backendUrl = "companies/companies";
      frontendUrl = "companies/companies";
      visibleAttribute = "name";
      break;
  }

  useEffect(() => {
    (async () => {
      if (backendUrl !== "") {
        const response = await fetchGet(
          restUrl(`${backendUrl}/${eventRelation.object_id}/`)
        );

        setRelationObject(response);
      }
    })();
  }, [eventRelation, backendUrl]);

  return relationObject ? (
    <ResourceLink
      label={relationObject[visibleAttribute]}
      url={frontendUrl}
      id={eventRelation.object_id}
    />
  ) : (
    <></>
  );
};

export default withStyles(styles)(EventRelationLink);
