import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../redux/types";
import AddNewNotificationProfile from "./AddNewNotificationProfile";
import EditNotificationProfile from "./EditNotificationProfile";
import NotificationProfiles from "./NotificationProfiles";
import NotificationSettingsView from "./NotificationSettingsView";
import NotificationSettingsSave from "./NotificationSettingsSave";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

const NotificationsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["services/notificationsprofiles"],
    requireSuperuser: true,
    routes: [
      {
        path: "/notification-profiles",
        label: t("notificationsProfilesLabel"),
        component: NotificationProfiles,
        icon: NotificationsNoneIcon,
        highlightClass: NavItemClassEnum.NOTIFICATIONPROFILES,
        navId: NavItemClassEnum.NOTIFICATIONPROFILES,
      },
      {
        path: "/notification-profiles/add-new",
        parent: "/notification-profiles",
        label: t("newNotificationProfileLabel"),
        component: AddNewNotificationProfile,
        highlightClass: NavItemClassEnum.NOTIFICATIONPROFILES,
      },
      {
        path: "/notification-profiles/:id",
        parent: "/notification-profiles",
        label: t("notificationProfileLabel"),
        component: NotificationSettingsView,
        highlightClass: NavItemClassEnum.NOTIFICATIONPROFILES,
      },
      {
        path: "/notification-profiles/:id/edit",
        parent: "/notification-profiles/:id",
        label: t("editNotificationProfileLabel"),
        component: EditNotificationProfile,
        highlightClass: NavItemClassEnum.NOTIFICATIONPROFILES,
      },
    ],
  },
  {
    apiUsage: ["services/notificationsettings"],
    requireSuperuser: true,
    routes: [
      {
        path: "/notification-profiles/:id/save",
        parent: "/notification-profiles/:id",
        label: t("newNotificationSettingLabel"),
        component: NotificationSettingsSave,
        highlightClass: NavItemClassEnum.NOTIFICATIONPROFILES,
      },
      {
        path: "/notification-profiles/:id/save/:nSettingId/",
        parent: "/notification-profiles/:id",
        label: t("editNotificationSettingLabel"),
        component: NotificationSettingsSave,
        highlightClass: NavItemClassEnum.NOTIFICATIONPROFILES,
      },
    ],
  },
];

export default NotificationsRouter;
