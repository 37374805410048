import React, { ChangeEvent, useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  getBaseAddonsAPI,
  createBaseAddonAPI
} from "../../../services/api-declaration";
import { BaseAddonsFilteredResponse } from "../../../redux/types";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  InputLabel
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useTranslate } from "../../../services/appLanguageService";
import { niceAmount } from "../../FormatHelpers";
import LoadingSpinner from "../../LoadingSpinner";
import * as Yup from "yup";
import { sanitizePrice } from "../../../helpers/globalHelper";
import SearchField from "../../SearchField";

const styles = (theme: any) =>
  createStyles({
    root: {
      marginTop: "20px"
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h6": {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
      }
    },
    bold: {
      fontWeight: "bold"
    },
    addButton: {
      marginRight: theme.spacing(2)
    },
    smallContainer: {
      flexGrow: 1
    },
    title: {
      flexGrow: 14
    },
    dialogContent: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    }
  });

interface BaseAddonsProps extends WithStyles<typeof styles> {}

interface AddForm {
  name: string;
  price: string;
  unit: string;
}

const BaseAddons: React.FC<BaseAddonsProps> = (props) => {
  const { classes } = props;

  const pageSize = 20;
  const [baseAddonsResponse, setBaseAddonsResponse] =
    useState<BaseAddonsFilteredResponse>({
      results: [],
      count: 0,
      next: null,
      previous: null
    });
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addForm, setAddForm] = useState<AddForm>({
    name: "",
    price: "0",
    unit: ""
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [reload, setReload] = useState(false);
  const [formValidation, setFormValidation] = useState<{
    [key: string]: {
      error: boolean;
      errorText: string;
    };
  }>({});
  const t = useTranslate("BaseAddonsPage");
  const t2 = useTranslate("BaseAddons");
  const t3 = useTranslate("ValidationErrorMessages");

  useEffect(() => {
    let isAlive = true;
    setIsLoading(true);
    getBaseAddonsAPI(pageIndex + 1, pageSize, searchTerm).then((response) => {
      if (isAlive) {
        setBaseAddonsResponse(response);
        setIsLoading(false);
        setReload(false);
      }
    });

    return () => {
      isAlive = false;
    };
  }, [pageIndex, pageSize, reload, searchTerm]);

  const createBaseAddon = async () => {
    createBaseAddonAPI({
      name: addForm.name,
      status: "ACTIVE",
      periodicity: "WEEKLY",
      price: +addForm.price,
      unit_divider: 1,
      has_many: true,
      is_inanimate: false,
      vat_percentage: 25,
      unit: addForm.unit,
      notes: null,
      description: null,
      start_date: null,
      end_date: null
    }).then(() => {
      clearDialog();
      setReload(true);
    });
  };

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(1, t3("minLengthError"))
      .max(255, t3("max255LengthError")),
    price: Yup.number()
      .typeError(t3("numberRequiredError"))
      .min(0, t3("minZeroError"))
      .max(10000000, t3("max10000000NumberError")),
    unit: Yup.string()
      .min(1, t3("minLengthError"))
      .max(10, t3("max10LengthError")),
    categories: Yup.array().min(1, t("requiredError"))
  });

  const validate = (value: string, field: keyof AddForm) => {
    schema
      .validate({ [field]: value })
      .then((valid: any) => {
        setFormValidation((fv) => ({
          ...fv,
          [field]: {
            error: false,
            errorText: ""
          }
        }));
      })
      .catch((e: any) => {
        setFormValidation((fv) => ({
          ...fv,
          [field]: {
            error: true,
            errorText: e.errors.join()
          }
        }));
      });
  };

  const formInvalid =
    Object.values(formValidation)
      .map((fv) => fv.error)
      .includes(true) ||
    addForm.name === "" ||
    addForm.unit === "";

  const clearDialog = () => {
    setDialogOpen(false);
    setAddForm({ name: "", price: "0", unit: "" });
    setFormValidation({});
  };

  const handleSearch = (s: string) => setSearchTerm(s);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          {t("baseAddonHeadingText")}
        </Typography>
        <div className={classes.smallContainer}>
          <SearchField handleSearch={handleSearch} />
        </div>
        <Button
          data-cy="add-baseaddon"
          variant="contained"
          startIcon={<Add />}
          className={classes.addButton}
          onClick={() => setDialogOpen(true)}
        >
          {t("addLabel")}
        </Button>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.bold}>{t("nameLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("priceLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("unitLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("statusLabel")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {baseAddonsResponse?.results.map((baseAddon) => (
              <TableRow key={baseAddon.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/contracts/baseaddons/${baseAddon.id}`}
                  >
                    {baseAddon.name}
                  </Link>
                </TableCell>
                <TableCell>{niceAmount(baseAddon.price)}</TableCell>
                <TableCell>{baseAddon.unit}</TableCell>
                <TableCell>{t2(baseAddon.status)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[pageSize]}
                count={baseAddonsResponse.count}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(_, page) => setPageIndex(page)}
              />
            </TableRow>
          </TableBody>
        </Table>
      )}
      <Dialog open={dialogOpen} onClose={clearDialog} fullWidth>
        <DialogTitle>{t("addBaseAddonLabel")}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div>
            <InputLabel htmlFor="name">{t("nameLabel")}</InputLabel>
            <TextField
              name="name"
              autoComplete="off"
              data-cy="baseaddon-form-name"
              value={addForm.name}
              fullWidth
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                validate(event.target.value, "name");
                setAddForm((f) => ({ ...f, name: event.target.value }));
              }}
              error={formValidation.name?.error}
              helperText={formValidation.name?.errorText}
            />
          </div>

          <div>
            <InputLabel htmlFor="price">{t("priceLabel")}</InputLabel>
            <TextField
              name="price"
              autoComplete="off"
              data-cy="baseaddon-form-price"
              value={addForm.price}
              fullWidth
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const sanitizedValue = sanitizePrice(event.target.value);
                validate(sanitizedValue, "price");
                setAddForm((f) => ({ ...f, price: sanitizedValue }));
              }}
              error={formValidation.price?.error}
              helperText={formValidation.price?.errorText}
            />
          </div>

          <div>
            <InputLabel htmlFor="unit">{t("unitLabel")}</InputLabel>
            <TextField
              name="unit"
              autoComplete="off"
              data-cy="baseaddon-form-unit"
              value={addForm.unit}
              fullWidth
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                validate(event.target.value, "unit");
                setAddForm((f) => ({ ...f, unit: event.target.value }));
              }}
              error={formValidation.unit?.error}
              helperText={formValidation.unit?.errorText}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" onClick={clearDialog}>
            {t("cancelLabel")}
          </Button>
          <Button
            data-cy="baseaddon-form-create-button"
            disabled={formInvalid}
            variant="contained"
            onClick={() => {
              createBaseAddon();
              setDialogOpen(false);
            }}
          >
            {t("createLabel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default withStyles(styles)(BaseAddons);
