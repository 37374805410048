import React from "react";
import {
  Button,
  FormControl,
  Select,
  IconButton,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { createStyles } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "formik";
import {
  TimeSheetRowMaterials,
  TimeSheetRowMaterial,
  TimeSheetOptions,
  ITimesheetAddonType,
  TimesheetRowSetAddon
} from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import AddIcon from "@material-ui/icons/Add";
import { copyApprovalAddon } from "../../helpers/timesheetApprovalHelper";
import TextFieldComponent from "../TextFieldComponent";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    formControl: {
      width: "100%",
      marginTop: 2,
      marginBottom: 2,
      display: "flex",
      flexDirection: "row"
    },
    addButtons: {
      marginTop: 8,
      alignSelf: "start"
    },
    delIconGrid: {
      textAlign: "center",
      paddingLeft: "0 !important",
      paddingRight: "0 !important"
    },
    mouseOver: {
      "&:hover": {
        color: "#e13e4e"
      }
    },
    materialField: {
      width: 80
    },
    amoutField: {
      width: 50,
      paddingBottom: 9,
      "& input[type=number]": {
        "-moz-appearance": "textfield"
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      }
    },
    amountFieldInput: {
      marginBottom: 2
    },
    materialContainer: {
      minWidth: 200
    },
    addMaterial: {
      display: "flex"
    },
    addMaterialFields: {
      paddingRight: theme.spacing(2)
    }
  })
);

interface TimesheetRowAddonComponentProps {
  options: TimeSheetOptions["contracted"]["materials"];
  fieldName: ITimesheetAddonType;
  disabled?: boolean;
  showNoButton?: boolean;
  fieldValue?: TimeSheetRowMaterials;
  correctionChanged: TimesheetRowSetAddon;
}

const TimesheetRowMaterialAddonComponent: React.FC<
  TimesheetRowAddonComponentProps
> = (props) => {
  const classes = useStyles();
  const {
    options,
    fieldName,
    correctionChanged,
    disabled,
    fieldValue,
    showNoButton
  } = props;
  const t = useTranslate("TimeSheetRowsAndApprovals");
  const fieldOptionValue = fieldValue ? copyApprovalAddon(fieldValue) : {};

  const addField = () => {
    let optionId = Object.keys(options)[0];
    if (fieldValue) {
      optionId = Object.keys(options).filter((id) => !fieldValue[id])[0];
    }
    const value: TimeSheetRowMaterial = { amount: 0 };
    const _fieldOptionValue = { ...fieldOptionValue };
    _fieldOptionValue[optionId] = value;
    correctionChanged(fieldName, _fieldOptionValue);
  };

  const deleteField = (id: string) => () => {
    const _fieldOptionValue = { ...fieldOptionValue };
    delete _fieldOptionValue[id];
    correctionChanged(fieldName, _fieldOptionValue);
  };

  const handleFieldChange =
    (previousId: string) =>
    ({ target }: React.ChangeEvent<{ value: any }>) => {
      const newId: string = target.value;
      if (newId && fieldOptionValue[previousId]) {
        fieldOptionValue[newId] = fieldOptionValue[previousId];
        delete fieldOptionValue[previousId];
        correctionChanged(fieldName, fieldOptionValue);
      }
    };

  const handleAmountChange =
    (id: string) =>
    ({ target }: React.ChangeEvent<{ value: string }>) => {
      let amount = target.value as any;
      if (!target.value) {
        amount = 0 as any;
      }
      if (fieldOptionValue[id]) {
        fieldOptionValue[id] = { amount };
        correctionChanged(fieldName, fieldOptionValue);
      }
    };
  const handleAmountKeyDown = (evt: React.KeyboardEvent<{ value: string }>) => {
    if (evt.key === "-" || evt.key === "e" || evt.key === "Enter") {
      evt.preventDefault();
    }
  };

  return (
    <>
      {Object.keys(fieldOptionValue).map((materialId: string) => (
        <div key={materialId} className={classes.materialContainer}>
          <div className={classes.addMaterial}>
            <div className={classes.addMaterialFields}>
              <FormControl className={classes.formControl}>
                <InputLabel>{t("selectMaterialInputLabel")}</InputLabel>
                <Select
                  data-cy={`${fieldName}-select-${materialId}`}
                  value={materialId}
                  className={classes.materialField}
                  onChange={handleFieldChange(materialId)}
                  fullWidth
                  disabled={disabled}
                >
                  {Object.entries(options).map(([optionId, option]) => (
                    <MenuItem
                      key={optionId}
                      value={Number(optionId)}
                      disabled={Boolean(fieldOptionValue[optionId])}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.addMaterialFields}>
              <Field
                className={classes.amoutField}
                key={materialId}
                type="number"
                id={`${fieldName}[${materialId}].amount`}
                data-cy={`${fieldName}-amount-${materialId}`}
                label={t("amountFieldLabel")}
                component={TextFieldComponent}
                onBlur={handleAmountChange(materialId)}
                onKeyDown={handleAmountKeyDown}
                keyName={"asd"}
                disabled={disabled}
                fieldValueData={fieldOptionValue[materialId].amount}
                fullWidth
                inputProps={{
                  className: classes.amountFieldInput,
                  step: "0.0001"
                }}
              />
            </div>
            {!showNoButton && (
              <IconButton
                disabled={disabled}
                onClick={deleteField(materialId)}
                className={classes.mouseOver}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
      ))}
      {!showNoButton && (
        <Button
          color="primary"
          data-cy={`timesheet-row-add-${fieldName}`}
          variant="contained"
          size="small"
          onClick={addField}
          className={classes.addButtons}
          disabled={
            disabled ||
            Object.keys(options).length === Object.keys(fieldOptionValue).length
          }
        >
          <AddIcon fontSize="small" /> {t("addMaterialButtonLabel")}
        </Button>
      )}
    </>
  );
};

export default TimesheetRowMaterialAddonComponent;
