class Dictionary<T> extends Map<number, T> {
  protected idsToSync = new Set<number>();

  public forDisplay(id: number) {
    return this.get(id) as T;
  }
  public collectId(id: number) {
    this.idsToSync.add(id);
  }

  public getIdsToSync() {
    this.idsToSync.forEach((id) => {
      if (this.has(id)) {
        this.idsToSync.delete(id);
      }
    });
    return Array.from(this.idsToSync);
  }
}

export default Dictionary;
