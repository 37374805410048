import { Beenhere } from "@material-ui/icons";
import { LayoutRouteUsage } from "../../redux/types";
import MyTasks from "./MyTasks";

const MyTasksRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contractor/tasks"],
    requireSuperuser: true,
    routes: [
      {
        path: "/tasks",
        label: t("myTasksNavbarLabel"),
        component: MyTasks,
        icon: Beenhere,
      },
      {
        path: "/tasks/:taskId",
        label: t("myTasksNavbarLabel"),
        component: MyTasks,
        icon: Beenhere,
      },
    ],
  },
];

export default MyTasksRouter;
