import React from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from "@material-ui/core";
import {
  Permission,
  Group,
  User,
  FormikSubmitDispatchProps
} from "../../redux/types";
import PermissionsTableRow from "./PermissionsTableRow";
import { EnumStrings } from "../../redux/strings";
import AddIcon from "@material-ui/icons/Add";
import { AutoCompleteSelect } from "../AutoCompleteSelect";
import { Formik, Field } from "formik";
import { useTranslate } from "../../services/appLanguageService";
import _ from "lodash";

const styles = (theme: any) =>
  createStyles({
    table: {
      minWidth: 500
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    submit: {
      marginTop: theme.spacing(3),
      marginBottom: 20
    },
    checkBoxCell: {
      width: "40%"
    }
  });

interface PermissionsProps
  extends FormikSubmitDispatchProps<any>,
    WithStyles<typeof styles> {
  usedPermissions: Permission[];
  availablePermissions: Permission[];
  permissionsHolder: Group | User;
  permissionIds: number[];
  handleOnChange: (values: any) => void;
  handleOnDelete: (values: any) => void;
  handleOnAdd: (values: any) => void;
  editable: boolean;
}

const Permissions: React.FC<PermissionsProps> = (props) => {
  const {
    classes,
    editable,
    usedPermissions,
    availablePermissions,
    permissionIds,
    permissionsHolder,
    handleOnChange,
    handleOnDelete,
    handleOnAdd
  } = props;
  const t = useTranslate("PermissionsPage");
  const t2 = useTranslate("Permissions");
  let groupedPermissions: any[] = [];
  if (usedPermissions) {
    groupedPermissions = _.chain(usedPermissions)
      .groupBy((item: Permission) => `${item.category}--${item.object}`)
      .map(function (items: any, object: string) {
        let permission = {
          categoryObject: object,
          access_rights: items.reduce(
            (accessObject: object, item: any) => ({
              ...accessObject,
              [item.access_right]: { id: item.id, used: true }
            }),
            {}
          )
        };

        if (!permission.access_rights.EDIT) {
          const editPermission = availablePermissions.find(
            (item) =>
              `${item.category}--${item.object}` ===
                permission.categoryObject &&
              item.access_right === EnumStrings.EDIT
          );

          if (editPermission) {
            permission = {
              ...permission,
              access_rights: {
                ...permission.access_rights,
                [editPermission.access_right]: {
                  id: editPermission.id,
                  used: false
                }
              }
            };
          }
        }
        return permission;
      })
      .value();
  }

  const permissionOptions = availablePermissions
    .filter((permission) => permission.access_right === EnumStrings.VIEW)
    .map((permission) => ({
      label: `${t2(permission.category)}, ${t2(permission.object)}`,
      value: permission.id
    }));

  return (
    <Paper>
      <Formik
        initialValues={{
          permission_id: -1
        }}
        onSubmit={() => {}}
      >
        {(props) => (
          <form onSubmit={() => {}} autoComplete="off">
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">{t("categoryLabel")}</TableCell>
                  <TableCell align="left">{t("objectLabel")}</TableCell>
                  <TableCell className={classes.checkBoxCell} align="center">
                    {t("editLabel")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedPermissions
                  ? groupedPermissions.map((permission) => (
                      <PermissionsTableRow
                        key={permission.categoryObject}
                        permission={permission}
                        permissionIds={permissionIds}
                        permissionsHolder={permissionsHolder}
                        handleOnChange={handleOnChange}
                        handleOnDelete={handleOnDelete}
                        editable={editable}
                      />
                    ))
                  : ""}
                {editable ? (
                  <TableRow>
                    <TableCell colSpan={3} align="left">
                      <Field
                        id={`add-permission-form-select`}
                        type="text"
                        name="permission_id"
                        label={t("selectPermissionLabel")}
                        placeholder={t("selectPermissionPlaceholder")}
                        options={permissionOptions}
                        component={AutoCompleteSelect}
                        margin="normal"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        id="add-permission-form-submit"
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {
                          handleOnAdd({
                            permission_id: props.values.permission_id,
                            permissions: permissionIds,
                            permissionsHolder: permissionsHolder
                          });
                        }}
                      >
                        <AddIcon /> {t("addPermissionlabel")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

export default withStyles(styles)(Permissions);
