import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  WeatherAreaForm,
  WeatherArea,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import WeatherAreaNewEditForm from "../../../components/companies/weatherAreas/WeatherAreaNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditWeatherAreaContainerProps
  extends FormikSubmitDispatchProps<WeatherAreaForm>,
    TabComponentProps<"weatherAreaId"> {
  weatherArea: WeatherArea;
  parentWeatherAreaOptions: SelectFieldOption[];
  loadWeatherArea: (weatherAreaId: number) => void;
  loadWeatherAreas: () => void;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<WeatherArea | WeatherAreaForm> {
  loadWeatherArea: (weatherAreaId: number) => void;
  loadWeatherAreas: () => void;
}

const EditWeatherAreaContainer: React.FC<EditWeatherAreaContainerProps> = (
  props
) => {
  const {
    loadWeatherArea,
    weatherArea,
    routeParams: { weatherAreaId },
    loadWeatherAreas
  } = props;
  useEffect(
    () => loadWeatherArea(weatherAreaId),
    [loadWeatherArea, weatherAreaId]
  );
  useEffect(() => loadWeatherAreas(), [loadWeatherAreas]);

  return (
    <WeatherAreaNewEditForm
      weatherArea={weatherArea}
      handleSubmit={props.handleSubmit}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        "WEATHERAREA",
        `companies/weatherareas/${(checkedNullValues as WeatherArea).id}/`,
        checkedNullValues,
        `/companies/weatherareas/${
          (checkedNullValues as WeatherArea).id
        }?same_tab=true`
      )
    );
  },
  loadWeatherArea: (weatherAreaId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "WEATHERAREA",
        `companies/weatherareas/${weatherAreaId}/`
      )
    );
  },
  loadWeatherAreas: () => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.WEATHERAREAS,
        "companies/weatherareas"
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  weatherArea:
    state.tabStates[ownProps.tabId].weatherAreas.weatherArea.weatherArea,
  parentWeatherAreaOptions: state.tabStates[
    ownProps.tabId
  ].weatherAreas.weatherAreas.weatherAreas.map((weatherArea) => ({
    label: weatherArea.name,
    value: weatherArea.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditWeatherAreaContainer);
