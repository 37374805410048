import React, { FC } from "react";
import {
  Button,
  Dialog,
  makeStyles,
  DialogTitle,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";

interface DialogProps {
  show: boolean;
  title: string;
  dialogType: string;
  description: string;
  onClose: () => void;
  onSuccess: () => void;
  closeButtonLabel: string;
  successButtonLabel: string;
  dispatchError: { title: string; services?: string[] } | null;
}
const useStyles = makeStyles((theme: any) =>
  createStyles({
    parent: {
      minWidth: 400,
      margin: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
      },
    },
    heading: { padding: `${theme.spacing(1)}px 0` },
    content: { padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px` },
    dialogContentText: { marginBottom: 0, [theme.breakpoints.down("sm")]: {
        minWidth: 250,
      }
    },
    alignCenter: { alignItems: "center" },
    btnContainer: {
      [theme.breakpoints.down("sm")]: {
        minWidth: 130,
        justifyContent: "center"
      }
    }
  })
);
export const DialogComponent: FC<DialogProps> = (props) => {
  const {
    show,
    title,
    onClose,
    onSuccess,
    dialogType,
    description,
    closeButtonLabel,
    successButtonLabel,
    dispatchError
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.parent}>
        <DialogTitle id="alert-dialog-title" className={classes.heading}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogContentText}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        {dispatchError && (
          <Alert severity="error" className={classes.alignCenter}>
            <AlertTitle>{dispatchError.title}</AlertTitle>
            <ul>
              {dispatchError.services &&
                dispatchError.services.map((service, i) => (
                  <li key={i}>{service}</li>
                ))}
            </ul>
          </Alert>
        )}
        <DialogActions className={classes.btnContainer}>
          <Button
            id="route-instance-delete-cancel"
            onClick={onClose}
            color="default"
          >
            {closeButtonLabel}
          </Button>
          <Button
            id="route-instance-delete-confirm"
            onClick={onSuccess}
            color={dialogType === "Remove" ? "secondary" : "primary"}
            disabled={Boolean(dispatchError)}
          >
            {successButtonLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
