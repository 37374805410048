import React from "react";
import CancelScheduleIcon from "@material-ui/icons/CancelScheduleSend";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/FolderOpen";
import SMSIcon from "@material-ui/icons/TextsmsOutlined";
import CloseIcon from "@material-ui/icons/Close";

import DraftsIcon from "@material-ui/icons/Drafts";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import { InvoiceStatus, EmailStatus } from "../redux/types";
import { Cancel, RotateLeft } from "@material-ui/icons";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { Tooltip } from "@material-ui/core";
import { useTranslate } from "../services/appLanguageService";

type InvoiceStatusColors = { [key in InvoiceStatus]: string };
type EmailStatusColors = { [key in EmailStatus]: string };

export const invoiceStatusColor = {
  CANCELLED: "#af1d23",
  DRAFT: "gray",
  READY: "black",
  CREDITED: "#d60000",
  EXPORTED: "darkgreen"
} as InvoiceStatusColors;

export const invoiceEmailStatusColor = {
  CANCELLED: "#af1d23",
  DRAFT: "gray",
  FAILED_TO_SEND: "#d60000",
  NOT_SENT: "#dc8e00",
  SENDING: "darkgreen",
  SENT: "#2abb00"
} as EmailStatusColors;

export const getInvoiceStatusIcon = (status: InvoiceStatus): JSX.Element => {
  switch (status) {
    case "DRAFT":
      return <FolderIcon />;
    case "READY":
      return <DoneIcon />;
    case "EXPORTED":
      return <CloudUpload />;
    case "CREDITED":
      return <RotateLeft />;
    case "CANCELLED":
      return <Cancel />;
    default:
      return <></>;
  }
};

export const InvoiceEmailStatusIcon = (status: EmailStatus): JSX.Element => {
  const t = useTranslate("EmailsPage");

  switch (status) {
    case "DRAFT":
      return (
        <Tooltip title={t("draftLabel")}>
          <DraftsIcon />
        </Tooltip>
      );
    case "CANCELLED":
      return (
        <Tooltip title={t("cancelledLabel")}>
          <CloseIcon />
        </Tooltip>
      );
    case "FAILED_TO_SEND":
      return (
        <Tooltip title={t("failedToSendLabel")}>
          <CancelScheduleIcon />
        </Tooltip>
      );
    case "NOT_SENT":
      return (
        <Tooltip title={t("notSentLabel")}>
          <DraftsOutlinedIcon />
        </Tooltip>
      );
    case "SENDING":
      return (
        <Tooltip title={t("sendingLabel")}>
          <SMSIcon />
        </Tooltip>
      );
    case "SENT":
      return (
        <Tooltip title={t("sentLabel")}>
          <DoneIcon />
        </Tooltip>
      );
    default:
      return <></>;
  }
};

export const getInvoiceStatusIconWithColor = (status: InvoiceStatus) => {
  return React.cloneElement(getInvoiceStatusIcon(status), {
    style: { color: invoiceStatusColor[status] }
  });
};

export const getInvoiceEmailStatusIconWithColor = (status: EmailStatus) => {
  return React.cloneElement(InvoiceEmailStatusIcon(status), {
    style: { color: invoiceEmailStatusColor[status] }
  });
};
