import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles, Paper, Typography, Button } from "@material-ui/core";
import {
  CustomerobjectForm,
  Customerobject,
  TabComponentProps,
  LocationForm,
  SelectFieldOption
} from "../../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useTranslate } from "../../../services/appLanguageService";
import {
  getCustomerObjectAPI,
  getDistrictsAPI,
  updateCustomerobjectAPI
} from "../../../services/api-declaration";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import { history } from "../../../redux/store";
import InvoiceLocationForm from "../../InvoiceLocationForm";

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      marginTop: 20
    }
  });

interface CustomerobjectInvoiceLocationFormProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"customerobjectId"> {}

const CustomerobjectInvoiceLocationForm: React.FC<
  CustomerobjectInvoiceLocationFormProps
> = (props) => {
  const {
    classes,
    routeParams: { customerobjectId }
  } = props;
  const t = useTranslate("InvoiceLocationFormPage");
  const [customerobject, setCustomerobject] = useState<
    Customerobject | CustomerobjectForm
  >();
  const [loadingCustomerobject, setLoadingCustomerobject] = useState(false);
  const [districtOptions, setDistrictOptions] = useState<SelectFieldOption[]>(
    []
  );

  useEffect(() => {
    let isAlive = true;
    setLoadingCustomerobject(true);
    void getCustomerObjectAPI(customerobjectId).then(
      (customerobjectResponse) => {
        if (isAlive) {
          setCustomerobject(customerobjectResponse);
          setLoadingCustomerobject(false);
        }
      }
    );

    return () => {
      isAlive = false;
    };
  }, [customerobjectId]);

  useEffect(() => {
    let isAlive = true;

    void getDistrictsAPI().then(
      (districtsResponse) =>
        isAlive &&
        setDistrictOptions(
          districtsResponse.results.map((district) => ({
            label: district.name,
            value: district.id
          }))
        )
    );

    return () => {
      isAlive = false;
    };
  }, []);

  const handleSubmit = (values: LocationForm) => {
    void updateCustomerobjectAPI(customerobjectId, {
      invoice_location: values
    }).then(() =>
      history.replace(
        `/contracts/customerobjects/${customerobjectId}?same_tab=true`
      )
    );
  };

  const initialValues: LocationForm =
    customerobject && customerobject.invoice_location !== null
      ? customerobject.invoice_location
      : {
          notes: "",
          address: "",
          addressee: "",
          zip_code: "",
          country: "",
          extra_address: "",
          district: null,
          city: "",
          full_address: "-",
          latitude: 0,
          longitude: 0
        };

  return loadingCustomerobject ? (
    <LoadingSpinnerPaper />
  ) : (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/contracts/customerobjects${
                customerobject ? `/${customerobject.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {t("editInvoiceLocation")}
        </Typography>
        <InvoiceLocationForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          districtOptions={districtOptions}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(CustomerobjectInvoiceLocationForm);
