import React, { useRef, useState } from "react";
import { isEqual } from "lodash";
import { SchemaOf, ValidationError } from "yup";
import clsx from "clsx";
import { IconButton, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";

type EditableCommiterProps<T> = {
  display: (() => React.ReactNode) | React.ReactNode;
  value: T;
  onCommit: (value: T) => Promise<void> | void;
  schema?: SchemaOf<T>;
  className?: string;
  children: (
    state: T,
    onChange: (value: T) => void,
    error?: string
  ) => React.ReactNode;
};

export function EditableCommiter<T>({
  display,
  value,
  onCommit,
  schema,
  className,
  children
}: EditableCommiterProps<T>) {
  const classes = makeStyles(() =>
    createStyles({
      editor: {
        display: "flex",
        "& > .editor--display": {
          flexGrow: 1,
          display: "-webkit-box",
          lineClamp: 3,
          boxOrient: "vertical",
          overflow: "hidden"
        },
        "& .editor--display > *": {
          flexGrow: 1,
          alignSelf: "end"
        },
        "&.displaying ": {
          cursor: "pointer"
        }
      },
      icon: {
        fontSize: "20px"
      },
      buttonGroup: {
        display: "flex",
        flexShrink: 0
      },
      handCursor: {
        cursor: "pointer"
      }
    })
  )();
  const validationTimerRef = useRef<number>();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(value);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState<string>();

  const commit = () => {
    if (touched) {
      setLoading(true);
      void Promise.resolve(onCommit(state)).then(() => {
        setEdit(false);
        setLoading(false);
      });
    } else {
      setEdit(false);
    }
  };

  const onChange = (v: T) => {
    if (!isEqual(state, v)) {
      if (schema) {
        if (validationTimerRef.current) {
          window.clearTimeout(validationTimerRef.current);
        }
        validationTimerRef.current = window.setTimeout(() => {
          void schema
            .validate(v)
            .catch((err: ValidationError) => setError(err.errors[0]));
        }, 50);
      }
      setState(v);
      setTouched(true);
    }
  };

  const reset = () => {
    setState(value);
    setEdit(false);
  };

  return (
    <div
      className={clsx(
        classes.editor,
        edit ? "editing" : "displaying",
        className
      )}
      onClick={!edit ? () => setEdit(true) : undefined}
    >
      {loading && <CircularProgress size={20} />}
      {!loading && edit && (
        <>
          <div className="editor--display">
            {children(state, onChange, error)}
          </div>

          <div className={classes.buttonGroup}>
            <IconButton
              size="small"
              aria-label="Save"
              disabled={!!error}
              onClick={commit}
            >
              <Done className={classes.icon} />
            </IconButton>
            <IconButton size="small" aria-label="Reset" onClick={reset}>
              <Clear className={classes.icon} />
            </IconButton>
          </div>
        </>
      )}
      {!loading && !edit && (
        <>
          <div className="editor--display">
            {display !== undefined && typeof display === "function"
              ? display()
              : display}
          </div>

          <div className={classes.buttonGroup}>
            <IconButton
              size="small"
              aria-label="Edit"
              onClick={() => setEdit(true)}
            >
              <Edit className={classes.icon} />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
}

export function WithLoading<T extends unknown[]>({
  onChange,
  children,
  className
}: {
  onChange: (...args: T) => Promise<void>;
  children: (onChange: (...args: T) => Promise<void>) => React.ReactNode;
  className?: string;
}) {
  const classes = makeStyles(() =>
    createStyles({
      root: {
        width: "44px",
        height: "44px"
      }
    })
  )();
  const [loading, setLoading] = useState(false);
  const innerOnChange: typeof onChange = async (...args) => {
    setLoading(true);
    await onChange(...args).then(() => setLoading(false));
  };
  return (
    <div className={`${classes.root} ${className ?? ""}`}>
      {loading ? <CircularProgress size={34} /> : children(innerOnChange)}
    </div>
  );
}
