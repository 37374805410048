import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel
} from "@material-ui/core";
import {
  FormikSubmitDispatchProps,
  CooperationForm,
  Cooperation,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import { useTranslate } from "../../../services/appLanguageService";
import DatePicker from "../../DatePicker";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";

let ValidationSchema = (t: any) => Yup.object().shape({});

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    }
  });

interface NewCooperationProps
  extends FormikSubmitDispatchProps<CooperationForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  cooperation?: Cooperation;
  personOptions: SelectFieldOption[];
  userOptions: SelectFieldOption[];
  loadPersons: (searchTerm?: string) => void;
  loadUsers: (searchTerm?: string) => void;
}

const NewEditCooperation: React.FC<NewCooperationProps> = (props) => {
  const {
    classes,
    cooperation,
    personOptions,
    userOptions,
    loadPersons,
    loadUsers,
    tabId
  } = props;
  const t = useTranslate("CooperationFormPage");
  const t3 = useTranslate("ValidationErrorMessages");
  let initialValues: Cooperation | CooperationForm;
  if (cooperation && (cooperation as Cooperation).id) {
    initialValues = {
      ...cooperation
    };
  } else {
    initialValues = {
      id: -1,
      title: "",
      accepted_by_user: null,
      accepted_ts: null,
      contract: -1,
      person: null,
      priority: 0,
      task: -1
    };
  }
  useEffect(() => {
    if (cooperation) {
      store.dispatch(setTitle(cooperation.title, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cooperation]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/contracts/cooperations${
                cooperation ? `/${cooperation.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateCooperationBtnLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {cooperation
            ? t("editCooperationHeading")
            : t("newCooperationHeading")}
          : {cooperation && cooperation.title}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={props.handleSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <InputLabel className={classes.selectLabel} htmlFor="form-person">
                {t("personLabel")}
              </InputLabel>
              <Field
                id="form-person"
                type="text"
                name="person"
                label={t("personLabel")}
                placeholder={t("personLabel")}
                value={
                  personOptions.find(
                    (personOption) => props.values.person === personOption.value
                  )
                    ? personOptions.find(
                        (personOption) =>
                          props.values.person === personOption.value
                      )
                    : null
                }
                options={personOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                loadOptions={loadPersons}
              />
              <InputLabel className={classes.selectLabel} htmlFor="form-person">
                {t("acceptedByUserLabel")}
              </InputLabel>
              <Field
                id="form-accepted_by_user"
                type="text"
                name="accepted_by_user"
                label={t("acceptedByUserLabel")}
                placeholder={t("acceptedByUserLabel")}
                value={
                  userOptions.find(
                    (user) => props.values.accepted_by_user === user.value
                  )
                    ? userOptions.find(
                        (user) => props.values.accepted_by_user === user.value
                      )
                    : null
                }
                options={userOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                loadOptions={loadUsers}
              />

              <Field
                id="form-accepted_ts"
                name="accepted_ts"
                label={t("acceptedTsLabel")}
                placeholder={initialValues.accepted_ts}
                component={DatePicker}
                format="yyyy-MM-dd"
                margin="normal"
                fullWidth
              />

              <Button
                id="new-cooperation-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {cooperation ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditCooperation);
