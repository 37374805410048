import React from "react";
import { connect } from "react-redux";
import { mainRootState, TabTitleState } from "../redux/types";
import TabRouter from "../components/tabRouter/TabRouter";

interface TabRouterContainerProps {
  tabTitle: TabTitleState;
}

const TabRouterContainer: React.FC<TabRouterContainerProps> = (props) => {
  const { tabTitle } = props;
  return <TabRouter tabTitle={tabTitle} />;
};

const mapStateToProps = (state: mainRootState) => ({
  tabTitle: state.tabTitle
});

export default connect(mapStateToProps, null)(TabRouterContainer);
