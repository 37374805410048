import { Paper, Link } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "../../services/appLanguageService";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    paper: {
      width: 500,
      padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`
    },
    flexDiv: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 0"
    }
  })
);

interface ResetPasswordSuccessProps {}

export default function ResetPasswordSuccess(props: ResetPasswordSuccessProps) {
  const classes = useStyles();
  const t = useTranslate("ResetPasswordPage");

  const history = useHistory();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Alert severity="success">{t("passwordResetSuccess")}</Alert>

        <div className={classes.flexDiv}>
          <Link
            component="button"
            onClick={() => {
              history.push("/login");
            }}
          >
            {t("navigateToLoginLabel")}
          </Link>
        </div>
      </Paper>
    </div>
  );
}
