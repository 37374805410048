import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Customerobject,
  Company,
  FilterDefinition,
  ResourcesButtonDefinition,
  SelectFieldOption
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import {
  EnumStrings,
  ReduxActionStrings,
  RestStrings
} from "../../../redux/strings";
import * as Yup from "yup";
import { useTranslate } from "../../../services/appLanguageService";
import _ from "lodash";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { getCustomersAPI } from "../../../services/api-declaration";

interface CustomerobjectsContainerProps {
  customerobjects: Customerobject[];
  companies: Company[];
  count: number;
  loadingCustomerobjects: boolean;
  companyId?: number;
  contractorId?: number;
  resetCustomerobjects: () => void;
  loadCustomerobjects: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => void;
  loadCompanies: (companyIds: number[]) => void;
  showNewButton?: boolean;
  showResourceButton?: boolean;
  addCustomerobject: (newData: any, owningId?: number) => void;
  updateCustomerobject: (personId: number, newData: any) => void;
  deleteCustomerobject: (personId: number) => void;
  editable?: boolean;
  slimmedDown?: boolean;
  customerHeader?: string;
}

const CustomerobjectsContainer: React.FC<CustomerobjectsContainerProps> = (
  props
) => {
  const {
    loadCustomerobjects,
    customerobjects,
    loadingCustomerobjects,
    loadCompanies,
    resetCustomerobjects,
    companies,
    companyId,
    editable,
    slimmedDown,
    showNewButton,
    contractorId
  } = props;
  const page = 1;
  const pageSize = 20;

  const t = useTranslate("CustomerobjectsPage");
  const t3 = useTranslate("ValidationErrorMessages");

  const [filteredCompanyValue, setFilteredCompanyValue] =
    useState<SelectFieldOption>({
      label: "",
      value: 0
    });
  const [companyOptions, setCompanyOptions] = useState<SelectFieldOption[]>([]);

  let filter = "";

  if (companyId) {
    filter = `&filter__customer=${companyId}`;
  } else if (contractorId) {
    filter = `&filter__active_customer_objects=${contractorId}`;
  } else if (filteredCompanyValue.value !== 0) {
    filter = `&filter__customer=${filteredCompanyValue.value}`;
  }

  useEffect(() => {
    if (!slimmedDown) {
      let isAlive = true;

      getCustomersAPI().then(
        (companiesResponse) =>
          isAlive &&
          setCompanyOptions(
            companiesResponse.results.map((company) => ({
              label: company.name,
              value: company.id
            }))
          )
      );

      return () => {
        isAlive = false;
      };
    }
  }, [slimmedDown]);

  const autocompleteSelect = (
    <Autocomplete
      value={filteredCompanyValue}
      onChange={(event: any, newCompany: SelectFieldOption | null) => {
        setFilteredCompanyValue(newCompany || { label: "", value: 0 });
      }}
      options={companyOptions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label={t("filterCustomerLabel")} />
      )}
    />
  );

  useEffect(
    () => loadCustomerobjects(page, pageSize, undefined, undefined, filter),
    [loadCustomerobjects, filter]
  );
  useEffect(
    () =>
      loadCompanies(
        _.uniq(customerobjects.map((customerobject) => customerobject.customer))
      ),
    [loadCompanies, customerobjects]
  );
  useEffect(() => () => resetCustomerobjects(), [resetCustomerobjects]);

  const columnDefinitions = slimmedDown
    ? [
        {
          name: "name",
          type: "link",
          editableForm: "text",
          validation: Yup.string()
            .min(1, t3("minLengthError"))
            .max(255, t3("max255LengthError"))
            .required(t3("requiredError"))
        },
        { name: "district_name", type: "string" },
        { name: "status", type: "enum" }
      ]
    : [
        { name: "company_logo", type: "thumbnail" },
        { name: "name", type: "link" },
        {
          name: "customer",
          type: "foreignLink",
          foreignData: {
            data: companies,
            visibleColumns: ["name"],
            url: "companies/companies"
          }
        },
        { name: "district_name", type: "string" },
        { name: "status", type: "enum" }
      ];

  const showResourceButton =
    (typeof props.showResourceButton !== "undefined" &&
      props.showResourceButton) ||
    typeof props.showResourceButton === "undefined";

  const filterDefinitions: FilterDefinition[] = showResourceButton
    ? [
        {
          name: "archived",
          flag: EnumStrings.ARCHIVED,
          selectedByDefualt: false
        }
      ]
    : [];

  const resourcesButtons: ResourcesButtonDefinition[] = showResourceButton
    ? [
        {
          name: "prospects",
          url: "contracts/prospects"
        }
      ]
    : [];

  return (
    <ResourcesTableContainer
      resources={customerobjects}
      resourceName={"Customerobject"}
      count={props.count}
      loadingResources={loadingCustomerobjects}
      routeUrl={"contracts/customerobjects"}
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      customFilter={filter}
      resetResources={resetCustomerobjects}
      showNewButton={showNewButton}
      defaultPageSize={pageSize}
      loadResources={loadCustomerobjects}
      addResource={editable ? props.addCustomerobject : undefined}
      updateResource={editable ? props.updateCustomerobject : undefined}
      deleteResource={editable ? props.deleteCustomerobject : undefined}
      owningId={companyId}
      customHeader={props.customerHeader}
      resourcesButtons={resourcesButtons}
      customHeaderComponent={slimmedDown ? undefined : autocompleteSelect}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadCustomerobjects: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__name=asc";

    const archivedFilter = `&filter__status__in=[ACTIVE,LOCKED,DRAFT,FLAGGED${
      flags && flags.includes(EnumStrings.ARCHIVED)
        ? `,${EnumStrings.ARCHIVED}`
        : ""
    }]`;

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CUSTOMEROBJECTS,
        `contracts/customerobjects/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${archivedFilter}${filter}`
      )
    );
  },
  loadCompanies: (companyIds: number[]) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?filter__company_type=CUSTOMER&filter__id__in=[${companyIds}]`
      )
    );
  },
  addCustomerobject: (newData: any, owningId?: number) => {
    if (owningId) {
      dispatch(
        RestActions.restRequestWithData(
          ownProps.tabId,
          RestStrings.POST,
          ReduxActionStrings.CUSTOMEROBJECT,
          "contracts/customerobjects/",
          {
            status: EnumStrings.ACTIVE,
            customer_object_type: EnumStrings.PROPERTY_USER,
            passcard_type: EnumStrings.PASSCARD,
            name: newData.name,
            notes: "",
            allow_photos: true,
            invoice_name: "",
            weekday_opening: "07:00",
            weekday_closing: "16:00",
            saturday_opening: "07:00",
            saturday_closing: "16:00",
            sunday_opening: "07:00",
            sunday_closing: "16:00",
            customer: owningId,
            location: null,
            contact_person: null
          }
        )
      );
    }
  },
  updateCustomerobject: (customerobjectId: number, newData: any) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.CUSTOMEROBJECT,
        `contracts/customerobjects/${customerobjectId}/`,
        {
          name: newData.name
        }
      )
    );
  },
  deleteCustomerobject: (customerobjectId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.CUSTOMEROBJECT,
        `contracts/customerobjects/${customerobjectId}/`,
        undefined,
        undefined,
        customerobjectId
      )
    );
  },
  resetCustomerobjects: () =>
    dispatch(LoadingBooleansActions.ResetCustomerobjects(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  customerobjects:
    state.tabStates[ownProps.tabId].customerObjects.customerobjects
      .customerobjects,
  companies: state.tabStates[ownProps.tabId].companies.companies.companies,
  count: state.tabStates[ownProps.tabId].customerObjects.customerobjects.count,
  loadingCustomerobjects:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCustomerobjects
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerobjectsContainer);
