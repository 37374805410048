import { useState, useEffect, useCallback } from "react";
import { Grid, Paper, Typography, Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AttachmentIcon from "@material-ui/icons/Attachment";
import clsx from "clsx";
import { Email, EmailAttachments, TabComponentProps } from "../../redux/types";
import store, { history } from "../../redux/store";
import {
  getEmailAPI,
  getEmailAttachmentFromURL
} from "../../services/api-declaration";
import { useTranslate } from "../../services/appLanguageService";
import saveAs from "file-saver";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";

interface EmailProps extends TabComponentProps<"id"> {}

const useStyles = makeStyles((theme: any) => ({
  attachmentLabelIcon: {
    fontSize: "1.2rem"
  },
  attachmentsLabel: {
    "&:hover": {
      color: "#2196f3",
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  attachmentsLabelContainer: {
    marginTop: theme.spacing(2)
  },
  bold: {
    fontWeight: "bold"
  },
  emailBody: {
    borderBottom: "1px solid gainsboro",
    minHeight: 250,
    padding: "0px 10px 10px 10px"
  },
  emailsButton: {
    marginBottom: theme.spacing(2.5)
  },
  emailInfoContainer: {
    marginBottom: theme.spacing(2)
  },
  emailInfoGray: {
    color: "#737373"
  },
  emailSubjectCell: {
    width: "11%"
  },
  fontSize14: {
    fontSize: 14
  },
  ml2: {
    marginLeft: 2
  },
  mr_4: {
    marginRight: theme.spacing(0.5)
  },
  mt14: {
    marginTop: 14
  },
  paper: {
    padding: theme.spacing(2)
  },
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  w98p: {
    width: "98%"
  },
  paragraphSpacing: {
    marginLeft: theme.spacing(1.5)
  }
}));

interface AttachmentProps extends EmailAttachments {
  key: number;
}

const Attachment = ({ filename, file }: AttachmentProps) => {
  const classes = useStyles();
  const downloadAttachment = (attachmentURL: string) => async () => {
    try {
      const attachment: Blob = await getEmailAttachmentFromURL(attachmentURL);
      saveAs(attachment, filename);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Grid container direction="column">
      <Grid alignItems="center" container direction="row">
        <AttachFileIcon
          className={clsx(
            classes.attachmentLabelIcon,
            classes.mr_4,
            classes.emailInfoGray
          )}
        />
        <Typography
          className={classes.attachmentsLabel}
          component="a"
          onClick={downloadAttachment(file)}
          variant="subtitle2"
        >
          {filename}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default function EmailView(props: EmailProps) {
  const {
    tabId,
    routeParams: { id: emailId }
  } = props;
  const classes = useStyles();
  const t = useTranslate("EmailView");
  const e = useTranslate("EmailsPage");
  const [email, setEmail] = useState<Email | undefined>();

  const getEmail = async () => {
    try {
      const response = await getEmailAPI(emailId);
      setEmail(response);
    } catch (e) {
      console.log(e);
    }
  };
  const navigateToEmails = () => {
    history.replace("/services/emails?same_tab=true");
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const init = useCallback(getEmail, []);
  useEffect(() => {
    init();
  }, [init]);

  const renderEmailToAddresses = (email: string, idx: number) => {
    const split = email.split(" ");
    const address = split.pop() || "";
    const person = split.join(" ").replace(/"/g, "");

    return (
      <Grid className={classes.emailSubjectCell} key={idx} item>
        <Tooltip placement="top-start" title={person}>
          <Typography
            className={clsx(
              classes.bold,
              classes.fontSize14,
              classes.textEllipsis
            )}
            component="p"
          >
            {person}
          </Typography>
        </Tooltip>
        <Tooltip placement="top" title={address}>
          <Typography
            className={clsx(classes.fontSize14, classes.textEllipsis)}
            component="p"
          >
            {address}
          </Typography>
        </Tooltip>
      </Grid>
    );
  };
  useEffect(() => {
    if (email) {
      store.dispatch(setTitle(email.from_address, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <>
      <Button
        className={classes.emailsButton}
        color="primary"
        onClick={navigateToEmails}
        variant="contained"
        startIcon={<NavigateBeforeIcon />}
      >
        {e("emailTitle")}
      </Button>
      <Paper className={classes.paper}>
        {email === undefined ? (
          <div>
            <Typography variant="h5">{t("noEmailFoundText")}</Typography>
          </div>
        ) : (
          <div>
            <Grid container direction="column">
              <Grid className={classes.emailInfoContainer} container>
                <Grid alignItems="center" container direction="row">
                  <Typography component="p" variant="h6">
                    {email.subject}
                  </Typography>
                </Grid>
                <Grid
                  alignItems="center"
                  className={classes.mt14}
                  container
                  direction="row"
                >
                  <Typography
                    className={clsx(classes.emailInfoGray, classes.mr_4)}
                    component="span"
                    variant="body2"
                  >
                    {t("emailTo")}:
                  </Typography>
                  <Grid
                    className={clsx(classes.w98p, classes.paragraphSpacing)}
                    container
                    direction="row"
                  >
                    {email.to_addresses.split(",").map(renderEmailToAddresses)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.emailBody}>
                <div dangerouslySetInnerHTML={{ __html: email.body }} />
              </Grid>
              <Grid
                alignItems="center"
                className={clsx(
                  classes.attachmentsLabelContainer,
                  classes.emailInfoGray
                )}
                container
                direction="row"
              >
                <AttachmentIcon
                  className={clsx(classes.attachmentLabelIcon, classes.mr_4)}
                />
                <Typography variant="body2">{t("attachments")}</Typography>
              </Grid>
              <Grid
                className={clsx(classes.ml2, classes.mt14)}
                container
                direction="row"
              >
                {email.attachments.map((attachment, idx) => (
                  <Attachment key={idx} {...attachment} />
                ))}
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    </>
  );
}
