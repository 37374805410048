import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  ContractingProfileForm,
  ContractingProfile,
  mainRootState,
  SelectFieldOption
} from "../../../redux/types";
import ContractingProfileNewForm from "../../../components/contracts/contractingProfiles/ContractingProfileNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewContractingProfileContainerProps
  extends FormikSubmitDispatchProps<ContractingProfileForm> {
  loadCompanies: (searchTerm?: string) => void;
  companyOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<
    ContractingProfile | ContractingProfileForm
  > {
  loadCompanies: (searchTerm?: string) => void;
}

const NewContractingProfileContainer: React.FC<
  NewContractingProfileContainerProps
> = (props) => {
  const { loadCompanies } = props;

  useEffect(() => loadCompanies(), [loadCompanies]);
  return (
    <ContractingProfileNewForm
      companyOptions={props.companyOptions}
      handleSubmit={props.handleSubmit}
      loadCompanies={props.loadCompanies}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<ContractingProfileForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.CONTRACTINGPROFILE,
        "contracts/contractingprofiles/",
        checkedNullValues as ContractingProfile,
        "/contracts/contractingprofiles?same_tab=true"
      )
    );
  },
  loadCompanies: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  companyOptions: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewContractingProfileContainer);
