import React, { FC, useEffect, useState, useCallback } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { createStyles } from "@material-ui/styles";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Paper,
  Typography,
  TextField,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Grid
} from "@material-ui/core";
import { useParams } from "react-router";
import GoogleMaps from "../googleMaps/GoogleMaps";
import {
  getTimesheet,
  saveTimesheetRows,
  saveImages
} from "../../services/timesheetService";
import {
  TimeSheetOptions,
  TimeSheetRowForm,
  TimeSheet,
  TimeSheetRow,
  FormikSetFieldValue,
  LocationMark,
  ImageRow,
  TimesheetRowOrigin,
  TimeSheetForm,
  TimeSheetRowExtended
} from "../../redux/types";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { mergeDateAndTime } from "../../helpers/timesheetHelperService";
import TimesheetRowMaterialAddonComponent from "../timesheet-row-add-material/timesheetRowAddMaterial";
import { generateTaskLocation } from "../../helpers/mapsHelperService";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import { useTranslate } from "../../services/appLanguageService";
import ImageUploadComponent from "../imageUploadComponent/ImageUploadComponent";
import useTimesheetRowImages from "./useTimesheetRowImages";
import {
  getOptionsForTimesheetsAPI,
  getTaskAPI
} from "../../services/api-declaration";

const styles = makeStyles((theme: any) =>
  createStyles({
    formPaper: {
      flex: "auto",
      minWidth: 400,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      padding: theme.spacing(2)
    },
    formWrapper: {
      display: "flex",
      flexFlow: "column"
    },
    formControl: {
      width: "100%",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      display: "flex",
      flexDirection: "row"
    },
    mapPaper: {
      flex: "0.6 0 auto",
      minWidth: 220,
      marginTop: theme.spacing(1),
      height: "calc(100VH - 172px)",
      display: "flex",
      flexFlow: "column",
      padding: theme.spacing(2.5)
    },
    mapFlexBasis: {
      flex: "auto"
    },
    m20: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5)
    },
    gridViewFields: {
      display: "flex"
    },
    mr15: {
      marginRight: 15
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: 2
    }
  })
);

interface TimeSheetRowSaveProps {
  setTimesheetRows: React.Dispatch<React.SetStateAction<TimeSheetRow | any>>;
  setRowData: React.Dispatch<React.SetStateAction<TimeSheetRowExtended[]>>;
  allRows: TimeSheetRowExtended[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  timesheetData: TimeSheetForm;
  rowId: number;
}
let ValidationSchema = (t: any) => {
  return Yup.object().shape({
    title: Yup.string().required(t("requiredError")),
    start_time: Yup.string().required(t("requiredError")),
    end_time: Yup.string().required(t("requiredError"))
  });
};
const defaultDate = new Date().toISOString();
const rowOriginOptions: TimesheetRowOrigin[] = [
  "MANUAL_ADDITION",
  "ROUTE_CHANGE",
  "ROUTE_DISPATCH"
];
export const defaultTimesheetRow: TimeSheetRowForm = {
  materials: {},
  origin: "MANUAL_ADDITION",
  accessories: [],
  location_latitude: 0,
  location_longitude: 0,
  geo_polygons: null,
  title: "",
  details: "",
  notes: "",
  start_time: defaultDate,
  end_time: defaultDate,
  task: 0,
  service: 0,
  service_name: "",
  servicecategory: 0,
  reported_service_category: 0,
  reported_service_categories: [],
  expected_service_categories: [],
  accompanying_people: {},
  routesegment: null,
  instructions: null,
  submit_status: "DRAFT"
};
let timeSheetOptionsInitState: TimeSheetOptions = {
  contracted: {
    services: {},
    servicecategories: {},
    accessories: {},
    tasks: {},
    materials: {}
  },
  colleagues: {}
};
export type FormFieldNames = keyof TimeSheetRowForm;
const getStyles = (id: string, theme: any, selections?: number[]) => ({
  fontWeight:
    selections && selections.includes(Number(id))
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightRegular
});

const TimeSheetRowSave: FC<TimeSheetRowSaveProps> = (props) => {
  const {
    setTimesheetRows,
    setRowData,
    allRows,
    setOpen,
    timesheetData,
    rowId
  } = props;
  const theme = useTheme();
  const classes = styles();
  const t = useTranslate("TimeSheetRowsAndApprovals");
  const u = useTranslate("Utility");
  const e = useTranslate("APIErrorMessages");
  const params: {
    rowId?: string;
    id?: string;
    contractorId?: string;
  } = useParams();
  const [options, setOptions] = useState<TimeSheetOptions>(
    timeSheetOptionsInitState
  );
  const [timesheetRowForm, setTimesheetRowForm] =
    useState<TimeSheetRowForm>(defaultTimesheetRow);
  const [timesheet, setTimesheet] = useState<TimeSheet | null>(null);
  const [markers, setMarkers] = useState<LocationMark[]>();
  const [images, setImages] = useTimesheetRowImages(
    rowId === -1 ? -1 : rowId,
    timesheet
  );

  const saveTimesheet = async (_timesheet: TimeSheet) => {
    _timesheet.rows.forEach((row) => cleanRowForPayload(row));
    return saveTimesheetRows(_timesheet);
  };
  const cleanRowForPayload = (row: TimeSheetRow) => {
    for (let i in row) {
      let rowItem: any = (row as any)[i];
      if (
        (Array.isArray(rowItem) && !rowItem.length) ||
        (typeof rowItem === "number" && rowItem === -1) ||
        (typeof rowItem === "string" && rowItem === "") ||
        (i === "location" && !rowItem) ||
        (i === "service" && !rowItem)
      ) {
        delete (row as any)[i];
      }
    }
  };
  // Field Handlers
  const handleDateTimeChange =
    (fieldName: FormFieldNames, setFieldValue: FormikSetFieldValue) =>
    (value: Date) => {
      setFieldValue(fieldName, value.toISOString());
    };
  const handleDateFieldChange =
    (
      fieldName: FormFieldNames,
      setFieldValue: FormikSetFieldValue,
      selectedTime: string | undefined
    ) =>
    (_value: Date) => {
      if (!_value || isNaN(_value.getTime())) {
        return;
      }
      let value = _value.toISOString();
      if (selectedTime) {
        value = mergeDateAndTime(value, selectedTime);
      }
      setFieldValue(fieldName, value);
    };
  const handlSelectChange =
    (fieldName: FormFieldNames, setFieldValue: FormikSetFieldValue) =>
    ({ target: { value } }: React.ChangeEvent<{ value: any }>) => {
      setFieldValue(fieldName, value);
      if (fieldName === "task") {
        displayTaskLocation(value);
      }
    };

  const displayTaskLocation = useCallback(
    (taskId: number) => {
      setMarkers(generateTaskLocation(taskId, options.contracted.tasks));
    },
    [options.contracted.tasks]
  );

  const addUpdateRow = useCallback(
    (rowData: TimeSheetRowForm) => async () => {
      const taskResponse = await getTaskAPI(Number(rowData.task));
      rowData.geo_polygons = taskResponse.geo_polygons;
      if (rowId !== -1) {
        const idx = timesheetData.rows.findIndex((r) => r.row_id === rowId);
        setRowData((r) => {
          const clone = [...r];
          clone[idx] = rowData as TimeSheetRowExtended;
          return clone;
        });
      } else {
        setTimesheetRows(rowData);
        timesheetData.rows = [
          ...timesheetData.rows,
          { ...(rowData as TimeSheetRowExtended) }
        ];
      }
      setOpen(false);
    },
    [rowId, setTimesheetRows, setRowData, timesheetData, setOpen]
  );
  // Form Handler
  const handleSubmit = async (
    formValues: TimeSheetRowForm,
    { setSubmitting }: FormikHelpers<TimeSheetRowForm>
  ) => {
    if (!timesheet) {
      return;
    }
    setSubmitting(true);
    let rows = [...timesheet.rows];
    const row: TimeSheetRow = { ...formValues };
    if (rowId !== -1) {
      rows = rows.map((el) => (el.row_id === rowId ? row : el));
    } else {
      if (rows.length) {
        // Manually managing row_id -- start
        let index = rows[rows.length - 1].row_id;
        index !== undefined && ++index;
        row.row_id = index;
      } else {
        row.row_id = 0;
      } // Manually managing row_id -- end
      rows.push(row);
    }
    try {
      await saveTimesheet({ ...timesheet, rows });
      saveImages(images, row, timesheet.id);
      showGlobalSnackbar(
        rowId !== -1
          ? u("editedSuccessfullyMessage")
          : u("addedSuccessfullyMessage"),
        "success"
      );
    } catch (err: any) {
      let errMessage = e("genericErrorMessage");
      if (err.response_data) {
        console.warn("ERR", err.response_data);
        if (
          err.response_data.detail &&
          typeof err.response_data.detail === "string"
        ) {
          errMessage = err.response_data.detail;
        }
        if (err.response_data.rows) {
          if (
            err.response_data.rows[1] &&
            err.response_data.rows[1].non_field_errors &&
            err.response_data.rows[1].non_field_errors[0] &&
            typeof err.response_data.rows[1].non_field_errors[0] === "string"
          ) {
            errMessage = err.response_data.rows[1].non_field_errors[0];
          } else if (err.response_data.rows[0]) {
            if (err.response_data.rows[0].non_field_errors) {
              errMessage = err.response_data.rows[0].non_field_errors;
            } else if (typeof err.response_data.rows[0] === "string") {
              errMessage = err.response_data.rows[0];
            }
          }
        }
      }
      showGlobalSnackbar(errMessage, "error");
    } finally {
      setSubmitting(false);
    }
  };
  const renderAccessoryChip = (selected: unknown) => (
    <div className={classes.chips}>
      {(selected as string[]).map((value) => (
        <Chip
          key={value}
          className={classes.chip}
          label={options.contracted.accessories[value]?.name}
        />
      ))}
    </div>
  );
  const handleImagesUpdate = (st: ImageRow[]) => {
    setImages(st);
  };

  useEffect(() => {
    const fetchTimesheetOptions = async (contractorId: number) => {
      try {
        const optionsResponse: TimeSheetOptions =
          await getOptionsForTimesheetsAPI(contractorId);
        setOptions(optionsResponse);
      } catch (err: any) {
        console.warn(
          "Error fetching timesheet options for the contractor:",
          err
        );
      }
    };
    if (params.contractorId) {
      fetchTimesheetOptions(Number(params.contractorId));
    } else if (timesheet) {
      fetchTimesheetOptions(timesheet.contractor);
    }
  }, [params.contractorId, timesheet]);

  useEffect(() => {
    const populateForm = async (timesheetRow: TimeSheetRow) => {
      timesheetRow.notes = timesheetRow.notes || "";
      setTimesheetRowForm({ ...timesheetRow });
      if (timesheetRow.task && timesheetRow.task !== -1) {
        displayTaskLocation(timesheetRow.task);
      }
    };
    if (allRows.length !== 0) {
      const timesheetRow = allRows.find((r) => r.row_id === rowId);
      if (timesheetRow) {
        populateForm(timesheetRow);
      }
    }
  }, [allRows, displayTaskLocation, rowId]);

  useEffect(() => {
    const fetchTimesheet = async (id: number) => {
      try {
        const _timesheet = await getTimesheet(id);
        setTimesheet(_timesheet);
      } catch (err: any) {
        showGlobalSnackbar(err.message, "error");
      }
    };
    if (params.id) {
      fetchTimesheet(+params.id);
      console.log("timesheet fetched");
    }
  }, [params.id]);

  // TODO: Code needs to be modularized
  return (
    <div>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Grid container>
          <Grid item md={8} xl={9} style={{ flexGrow: 1 }}>
            <Paper className={classes.formPaper}>
              <Typography component="h1" variant="h6">
                {rowId !== -1 ? t("editHeaderLabel") : t("addHeaderLabel")}
              </Typography>
              <Formik
                initialValues={timesheetRowForm}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={ValidationSchema(t)}
              >
                {(_props) => (
                  <>
                    <Form className={classes.formWrapper}>
                      <Field
                        autoFocus
                        component={TextField}
                        data-cy="timesheet-row-title"
                        error={_props.errors.title && _props.touched.title}
                        fullWidth
                        id="title"
                        label={t("titleFieldLabel")}
                        margin="normal"
                        onChange={_props.handleChange}
                        placeholder={t("titleFieldPlaceholder")}
                        type="text"
                        value={_props.values.title || ""}
                      />
                      <div className={classes.gridViewFields}>
                        <Field
                          component={KeyboardDatePicker}
                          id="timesheet-row-start-date"
                          data-cy="timesheet-row-start-date"
                          label={t("startDateFieldLabel")}
                          placeholder={t("startDateFieldLabel")}
                          onChange={handleDateFieldChange(
                            "start_time",
                            _props.setFieldValue,
                            _props.values.start_time
                          )}
                          onBlur={_props.handleBlur}
                          value={_props.values.start_time}
                          margin="normal"
                          className={classes.mr15}
                          fullWidth
                        />
                        <Field
                          component={KeyboardTimePicker}
                          data-cy="timesheet-row-start-time"
                          id="timesheet-row-start-time"
                          fullWidth
                          ampm={false}
                          // id="start_time"
                          label={t("startTimeFieldLabel")}
                          margin="normal"
                          onBlur={_props.handleBlur}
                          onChange={handleDateTimeChange(
                            "start_time",
                            _props.setFieldValue
                          )}
                          placeholder={t("startTimeFieldLabel")}
                          value={_props.values.start_time}
                        />
                      </div>
                      <div className={classes.gridViewFields}>
                        <Field
                          className={classes.mr15}
                          component={KeyboardDatePicker}
                          data-cy="timesheet-row-end-date"
                          id="timesheet-row-end-date"
                          fullWidth
                          label={t("endDateFieldLabel")}
                          margin="normal"
                          onChange={() => {
                            if (_props.values.end_time) {
                              handleDateFieldChange(
                                "end_time",
                                _props.setFieldValue,
                                _props.values.end_time
                              );
                            }
                          }}
                          placeholder={t("endDateFieldLabel")}
                          value={_props.values.end_time}
                        />
                        <Field
                          data-cy="timesheet-row-end-time"
                          id="timesheet-row-end-time"
                          // id="end_time"
                          label={t("endTimeFieldLabel")}
                          placeholder={t("endTimeFieldLabel")}
                          component={KeyboardTimePicker}
                          ampm={false}
                          error={
                            _props.touched.end_time && _props.errors.end_time
                          }
                          onChange={handleDateTimeChange(
                            "end_time",
                            _props.setFieldValue
                          )}
                          value={_props.values.end_time}
                          margin="normal"
                          fullWidth
                        />
                      </div>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="origin">
                          {t("selectOriginInputLabel")}
                        </InputLabel>
                        <Select
                          data-cy="timesheet-row-origin"
                          fullWidth
                          name="origin"
                          onChange={_props.handleChange}
                          value={_props.values.origin || ""}
                        >
                          {rowOriginOptions.map((origin, idx) => (
                            <MenuItem key={idx} value={origin}>
                              {origin}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Field
                        type="text"
                        id="notes"
                        data-cy="timesheet-row-notes"
                        label={t("notesFieldLabel")}
                        placeholder={t("notesFieldPlaceholder")}
                        component={TextField}
                        onChange={_props.handleChange}
                        value={_props.values.notes || ""}
                        margin="normal"
                        fullWidth
                      />
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="service">
                          {t("selectServiceInputLabel")}
                        </InputLabel>
                        <Select
                          data-cy="timesheet-row-service"
                          disabled={
                            Object.keys(options.contracted.services).length ===
                            0
                          }
                          fullWidth
                          name="service"
                          onChange={_props.handleChange}
                          value={_props.values.service || ""}
                        >
                          {Object.entries(options.contracted.services).map(
                            ([serviceId, service]) => (
                              <MenuItem
                                key={serviceId}
                                value={Number(serviceId)}
                              >
                                {service.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="task">
                          {t("selectTaskInputLabel")}
                        </InputLabel>
                        <Select
                          data-cy="timesheet-row-task"
                          name="task"
                          value={_props.values.task || ""}
                          onChange={handlSelectChange(
                            "task",
                            _props.setFieldValue
                          )}
                          disabled={
                            Object.keys(options.contracted.tasks).length === 0
                          }
                          fullWidth
                        >
                          {Object.entries(options.contracted.tasks).map(
                            ([taskId, task]) => (
                              <MenuItem key={taskId} value={Number(taskId)}>
                                {task.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <InputLabel>
                          {t("selectAccessoryInputLabel")}
                        </InputLabel>
                        <Select
                          data-cy="timesheet-row-accessories"
                          multiple
                          value={_props.values.accessories || []}
                          onChange={handlSelectChange(
                            "accessories",
                            _props.setFieldValue
                          )}
                          disabled={
                            Object.keys(options.contracted.accessories)
                              .length === 0
                          }
                          fullWidth
                          renderValue={renderAccessoryChip}
                        >
                          {Object.entries(options.contracted.accessories).map(
                            ([accessoryId, accessory]) => (
                              <MenuItem
                                key={accessoryId}
                                value={Number(accessoryId)}
                                style={getStyles(
                                  accessoryId,
                                  theme,
                                  _props.values.accessories
                                )}
                              >
                                {accessory.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <TimesheetRowMaterialAddonComponent
                        options={options.contracted.materials}
                        fieldName="materials"
                        fieldValue={_props.values.materials}
                        correctionChanged={_props.setFieldValue}
                      />
                      <ImageUploadComponent
                        images={images}
                        onImageUpdate={handleImagesUpdate}
                      />
                    </Form>
                    <Button
                      className={classes.m20}
                      color="primary"
                      data-cy="timesheet-row-submit"
                      fullWidth
                      id="new-user-form-submit"
                      variant="contained"
                      onClick={addUpdateRow(_props.values)}
                    >
                      {rowId !== -1
                        ? t("saveButtonLabel")
                        : t("addButtonLabel")}
                    </Button>
                  </>
                )}
              </Formik>
            </Paper>
          </Grid>
          <Grid item md={4} xl={3}>
            <Paper className={classes.mapPaper} style={{ flexGrow: 1 }}>
              <Typography component="h1" variant="h6">
                {t("locationHeading")}
              </Typography>
              <GoogleMaps
                id="dialogMap"
                className={classes.mapFlexBasis}
                markers={markers}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TimeSheetRowSave;
