import React, { useState, useCallback, useEffect } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  TextField,
  Typography,
  Button
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import authenticationService from "../../services/authenticationService";
import store from "../../redux/store";
import { useTranslate } from "../../services/appLanguageService";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import {
  getMyUserInfoAPI,
  resetPassword
} from "../../services/api-declaration";
import * as AuthenticationActions from "../../redux/reducers/authentication/actions";
import { useHistory } from "react-router-dom";

const styles = (theme: any) =>
  createStyles({
    formPaper: {
      marginTop: theme.spacing(2.5),
      padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`
    },
    submitBtn: {
      margin: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px 0`
    },
    errMessage: {
      color: "#f44336",
      fontSize: theme.spacing(1.5)
    },
    relativeBlock: { position: "relative" },
    absoluteBlock: {
      cursor: "pointer",
      position: "absolute",
      bottom: theme.spacing(1),
      right: theme.spacing(1.5)
    }
  });

interface IResetPassword {
  newPassword: string;
  confirmPassword: string;
}

interface ResetPasswordProps extends WithStyles<typeof styles> {}

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const { classes } = props;
  const t = useTranslate("ResetPasswordPage");
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const initialValues = {
    newPassword: "",
    confirmPassword: ""
  };
  const schema: any = {
    newPassword: Yup.string().min(1).max(255).required(t("requiredFieldError")),
    confirmPassword: Yup.string()
      .min(1)
      .max(255)
      .required(t("requiredFieldError"))
  };
  const ValidationSchema = Yup.object().shape(schema);

  const history = useHistory();

  const initComponent = async () => {
    try {
      const response = await getMyUserInfoAPI();
      setCurrentUserId(response.user.id);
    } catch (e) {
      console.warn(e);
    }
  };
  const handleOnSubmit = async (formValues: IResetPassword) => {
    if (formValues.newPassword !== formValues.confirmPassword) {
      showGlobalSnackbar(t("passwordMismatchError"), "error");
      return;
    }
    try {
      if (currentUserId) {
        await resetPassword(currentUserId, {
          password: formValues.confirmPassword
        });
        showGlobalSnackbar(t("passwordResetSuccess"), "success");
        store.dispatch(AuthenticationActions.logout());
        authenticationService.clearStorage();
        history.push("/reset-password-success");
      }
    } catch (e) {
      showGlobalSnackbar(t("passwordResetFail"), "error");
      console.warn(e);
    }
  };
  const togglePasswordField = () => setVisiblePassword(!visiblePassword);
  const toggleConfirmPasswordField = () =>
    setVisibleConfirmPassword(!visibleConfirmPassword);
  const initCallback = useCallback(initComponent, []);
  useEffect(() => {
    initCallback();
  }, [initCallback]);

  return (
    <div>
      <Typography component="h1" variant="h6">
        {t("resetPasswordHeading")}
      </Typography>
      <Paper className={classes.formPaper}>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={(values) => handleOnSubmit(values)}
        >
          {(props) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                autoComplete="off"
                onChange={props.handleChange}
              >
                <div className={classes.relativeBlock}>
                  <Field
                    fullWidth
                    margin="normal"
                    id="newPassword"
                    name="newPassword"
                    component={TextField}
                    label={t("newPasswordLabel")}
                    onChange={() => {
                      if (!props.values.confirmPassword) {
                        setVisiblePassword(false);
                      }
                    }}
                    placeholder={t("newPasswordLabel")}
                    type={`${visiblePassword ? "text" : "password"}`}
                  />
                  <div className={classes.absoluteBlock}>
                    {props.values.newPassword &&
                      (visiblePassword ? (
                        <VisibilityOff onClick={togglePasswordField} />
                      ) : (
                        <Visibility onClick={togglePasswordField} />
                      ))}
                  </div>
                  <div className={classes.errMessage}>
                    <ErrorMessage name="newPassword" />
                  </div>
                </div>
                <div className={classes.relativeBlock}>
                  <Field
                    fullWidth
                    margin="normal"
                    id="confirmPassword"
                    name="confirmPassword"
                    component={TextField}
                    onChange={() => {
                      if (!props.values.confirmPassword) {
                        setVisibleConfirmPassword(false);
                      }
                    }}
                    label={t("confirmPasswordLabel")}
                    placeholder={t("confirmPasswordLabel")}
                    type={`${visibleConfirmPassword ? "text" : "password"}`}
                  />
                  <div className={classes.absoluteBlock}>
                    {props.values.confirmPassword &&
                      (visibleConfirmPassword ? (
                        <VisibilityOff onClick={toggleConfirmPasswordField} />
                      ) : (
                        <Visibility onClick={toggleConfirmPasswordField} />
                      ))}
                  </div>
                  <div className={classes.errMessage}>
                    <ErrorMessage name="confirmPassword" />
                  </div>
                </div>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.submitBtn}
                >
                  {t("saveFormButton")}
                </Button>
              </form>
            );
          }}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ResetPassword);
