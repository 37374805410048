import { AllQueryFilters } from "../redux/types";

export const queryParams = (obj?: AllQueryFilters): string => {
  let queryies: string[] = [];
  for (const key in obj) {
    const val = obj[key as keyof AllQueryFilters];
    queryies.push(`${key}=${Array.isArray(val) ? JSON.stringify(val) : val}`);
  }
  return queryies.length !== 0 ? `?${queryies.join("&")}` : "";
};

export const filterQueryParams = (obj: any): string => {
  return queryParams(tableFilterQueryParams(obj));
};

export const tableFilterQueryParams = (obj: AllQueryFilters) => {
  const out: AllQueryFilters = {};
  for (const key in obj) {
    const val = obj[key as keyof AllQueryFilters];
    if (val !== -1 && val !== "ALL") {
      out[key as keyof AllQueryFilters] = val as any;
    }
  }
  return out;
};
