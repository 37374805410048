import { createSuccessActionType } from "../actionTypes";
import { TaskOptionsState, Action, TaskOption } from "../../types";
import { RestStrings, ReduxActionStrings } from "../../strings";

const initialState: TaskOptionsState = {
  taskOptions: {
    results: [],
    next: null,
    previous: null,
    count: 0
  },
  taskOption: {
    id: -1,
    created_at: "",
    updated_at: "",
    name: "",
    notes: "",
    present_by_default: false,
    businessarea: -1,
    businessAreaName: ""
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TASKOPTIONS
    ):
      return {
        ...state,
        taskOptions: {
          results: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.TASKOPTIONS
    ):
      return {
        ...state,
        taskOptions: {
          ...state.taskOptions,
          results: [...state.taskOptions.results, action.payload]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.TASKOPTION
    ):
      return {
        ...state,
        taskOptions: {
          ...state.taskOptions,
          results: [
            ...state.taskOptions.results.map((taskOption) =>
              taskOption.id === action.payload.id ? action.payload : taskOption
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.TASKOPTION
    ):
      return {
        ...state,
        taskOptions: {
          ...state.taskOptions,
          results: state.taskOptions.results.filter(
            (taskOption: TaskOption) =>
              taskOption.id !== parseInt(action.payload.id)
          )
        }
      };

    default:
      return state;
  }
}

export default reducer;
