import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "@material-ui/core";
import { Add, Close, PictureAsPdf } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { Customerobject, Task } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import {
  getContractBusinessAreasAPI,
  getContractTasksAPI,
  getCustomerobjectByCompanyIdsAPI,
  getTaskAPI,
  updateContractTaskAPI
} from "../../../services/api-declaration";
import clsx from "clsx";
import ConfirmationDialog from "../../confirmationDialog/ConfirmationDialog";
import * as Yup from "yup";
import { ValidationError } from "yup";
import { Autocomplete } from "@material-ui/lab";

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    }
  })
);

type CustomerObjectCreateTaskModalProps = {
  customerObjects: Customerobject[];
  taskIds: Set<number>;
  onLink: (taskId: number) => Promise<void>;
  onClose: () => void;
};

const LinkTaskModal: React.FC<CustomerObjectCreateTaskModalProps> = ({
  customerObjects,
  taskIds,
  onLink,
  onClose
}) => {
  const [state, setState] = useState<{
    customerObjectId: number;
    taskId: number;
  }>({
    customerObjectId: 0,
    taskId: 0
  });
  const [tasks, setTasks] = useState<Task[]>();
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {}
  );
  const t = useTranslate("TaskTable");
  const classes = useModalStyles();

  const schema = Yup.object({
    customerObjectId: Yup.number()
      .required(t("fieldRequired"))
      .oneOf(customerObjects.map((o) => o.id)),
    taskId: Yup.number().notOneOf([...taskIds], t("taskAlreadyInContract"))
  });

  const validateFields = async (
    patch: Partial<typeof state> | undefined,
    ...fields: (keyof typeof state)[]
  ): Promise<boolean> => {
    let next = { ...errors };
    const patched = patch ? { ...state, ...patch } : state;
    await Promise.all(
      fields.map((field) =>
        schema
          .validateAt(field, patched)
          .then(() => field in next && delete next[field])
          .catch(
            (err: ValidationError) => (next[field] = err.errors.join(", "))
          )
      )
    );
    setErrors(next);
    return !Object.keys(next).length;
  };

  useEffect(() => {
    if (state.customerObjectId) {
      let alive = true;
      setTasks(undefined);
      void getContractTasksAPI({ customerobject: state.customerObjectId }).then(
        ({ results }) => {
          if (alive) {
            setTasks(results);
            setState((s) => ({
              ...s,
              taskId: results.find((t) => !taskIds.has(t.id))?.id ?? 0
            }));
          }
        }
      );
      return () => {
        alive = false;
      };
    } else {
      setTasks([]);
      setState((s) => ({ ...s, taskId: 0 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.customerObjectId]);

  const handleLink = () => {
    void validateFields(undefined, "customerObjectId", "taskId").then(
      async (valid) => {
        if (valid && state.taskId) {
          await onLink(state.taskId);
        }
      }
    );
  };

  const objectOptions = [
    { label: "", value: 0 },
    ...customerObjects.map(({ id, name }) => ({ label: name, value: id }))
  ];
  const taskOptions = [
    { label: "", value: 0 },
    ...(tasks
      ?.filter((t) => !taskIds.has(t.id))
      .map(({ id, name }) => ({ label: name, value: id })) ?? [])
  ];

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>{t("addTaskLabel")}</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <Autocomplete
            value={objectOptions.find(
              (o) => o.value === state.customerObjectId
            )}
            fullWidth
            onChange={(_, v) =>
              setState((s) => ({ ...s, customerObjectId: v?.value ?? 0 }))
            }
            renderInput={(params: any) => (
              <TextField {...params} label={t("customerObjectLabel")} />
            )}
            getOptionLabel={(o) => o.label}
            options={objectOptions}
            disableClearable
          />
        </div>
        <div>
          {taskOptions && taskOptions.length > 1 ? (
            <Autocomplete
              value={taskOptions?.find((o) => o.value === state.taskId)}
              fullWidth
              onChange={(_, v) =>
                setState((s) => ({ ...s, taskId: v?.value ?? 0 }))
              }
              renderInput={(params: any) => (
                <TextField {...params} label={t("taskLabel")} />
              )}
              getOptionLabel={(o) => o.label}
              options={taskOptions ?? []}
              disableClearable
              disabled={taskOptions && taskOptions.length <= 1}
            />
          ) : (
            <TextField
              fullWidth
              disabled
              label={t("taskLabel")}
              value={t("noTasks")}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t("cancelButtonText")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleLink}>
          {t("linkButtonText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "20px"
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2)
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h6": {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
      }
    },
    mapContainer: {
      marginTop: "20px"
    },
    noMap: {
      padding: theme.spacing(2)
    },
    bold: {
      fontWeight: "bold"
    },
    addButton: {
      marginRight: theme.spacing(2)
    }
  })
);

type ContractTaskTableProps = {
  customerId: number;
  contractId: number;
  onAdd?: (taskId: number) => void;
  onRemove?: (taskId: number) => void;
  className?: string;
};

export const ContractTaskTable: React.FC<ContractTaskTableProps> = ({
  customerId,
  contractId,
  onAdd,
  onRemove,
  className
}) => {
  const [modal, setModal] = useState<
    { m: "create" } | { m: "delete"; taskId: number }
  >();
  const [objects, setObjects] = useState<Customerobject[]>();
  const [tasks, setTasks] = useState<Task[]>();
  const [businessAreaNames, setBusinessAreaNames] =
    useState<Map<number, { name: string }>>();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let alive = true;
    void Promise.all([
      getCustomerobjectByCompanyIdsAPI([customerId]),
      getContractTasksAPI({ customer_contracts: contractId }),
      getContractBusinessAreasAPI({})
    ]).then(
      ([
        { results: objects },
        { results: tasks },
        { results: businessareas }
      ]) => {
        if (alive) {
          tasks.sort((a, b) => a.name.localeCompare(b.name));
          setObjects(objects);
          setTasks(tasks);
          setBusinessAreaNames(new Map(businessareas.map((a) => [a.id, a])));
        }
      }
    );
    return () => {
      alive = false;
    };
  }, [contractId, customerId, refresh]);

  const classes = useStyles();
  const t = useTranslate("TaskTable");
  const [pageIndex, setPageIndex] = useState(0);

  const handleUnlink = (taskId: number) => {
    setModal({ m: "delete", taskId });
  };

  const handleAdd = () => {
    setModal({ m: "create" });
  };

  const unlinkSelectedTask = () => {
    if (tasks && modal?.m === "delete") {
      const taskId = modal.taskId;
      const task = tasks.find((t) => t.id === taskId);
      if (task) {
        const customer_contracts = task.customer_contracts.filter(
          (c) => c !== contractId
        );
        void updateContractTaskAPI(taskId, { customer_contracts }).then(() => {
          setModal(undefined);
          setRefresh((r) => !r);
          onRemove?.(task.id);
        });
      }
    }
  };

  const linkTask = async (taskId: number) => {
    const task = await getTaskAPI(taskId);
    if (!task.customer_contracts.includes(contractId)) {
      const customer_contracts = [...task.customer_contracts, contractId];
      await updateContractTaskAPI(taskId, { customer_contracts });
      setModal(undefined);
      setRefresh((r) => !r);
      onAdd?.(task.id);
    }
  };

  return (
    <Paper className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Typography variant="h6">{t("taskHeadingText")}</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          className={classes.addButton}
          onClick={handleAdd}
        >
          Add
        </Button>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.bold}>{t("nameLabel")}</TableCell>
            <TableCell className={classes.bold}>
              {t("businessareaLabel")}
            </TableCell>
            <TableCell className={classes.bold} padding="checkbox" colSpan={2}>{t("previewLabel")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks?.slice(pageIndex * 10, pageIndex * 10 + 10).map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Link component={RouterLink} to={`/contracts/tasks/${row.id}`}>
                  {row.name}
                </Link>
              </TableCell>
              <TableCell>
                {businessAreaNames?.get(row.businessarea)?.name}
              </TableCell>
              <TableCell padding="checkbox">
                <IconButton component={RouterLink} to={`/contracts/contracts/${contractId}/${row.id}/show-pdf`}>
                  <PictureAsPdf />
                </IconButton>
              </TableCell>
              <TableCell align="right">
                <IconButton size="small" onClick={() => handleUnlink(row.id)}>
                  <Close />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={tasks?.length ?? 0}
              rowsPerPage={10}
              page={pageIndex}
              onPageChange={(_, page) => setPageIndex(page)}
            />
          </TableRow>
        </TableBody>
      </Table>
      {modal?.m === "create" && objects && contractId && (
        <LinkTaskModal
          customerObjects={objects}
          taskIds={new Set(tasks?.map((t) => t.id) ?? [])}
          onLink={linkTask}
          onClose={() => {
            setModal(undefined);
          }}
        />
      )}
      {modal?.m === "delete" && modal.taskId && (
        <ConfirmationDialog
          title={t("confirmUnlinkTitle")}
          description={t("confirmUnlinkText")}
          onSuccess={unlinkSelectedTask}
          onClose={() => {
            setModal(undefined);
          }}
          open
        />
      )}
    </Paper>
  );
};
