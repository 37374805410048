import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../redux/types";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import HistoryIcon from "@material-ui/icons/History";
import TimesheetApprovalsPageContainer from "./TimesheetApprovalsPageContainer";
import HistoryView from "../history/HistoryContainer";

const TimesheetApprovalRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["timesheets/workapprovals"],
    routes: [
      {
        path: "/approvals",
        label: t("WorkVerificationNavbarLabel"),
        component: TimesheetApprovalsPageContainer,
        icon: PlaylistAddCheckIcon,
        highlightClass: NavItemClassEnum.WORKVERIFICATION,
        navId: NavItemClassEnum.WORKVERIFICATION
      },
      {
        path: "/history",
        label: t("historyNavbarLabel"),
        component: HistoryView,
        icon: HistoryIcon,
        highlightClass: NavItemClassEnum.HISTORY,
        navId: NavItemClassEnum.HISTORY
      }
    ]
  }
];

export default TimesheetApprovalRouter;
