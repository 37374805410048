import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Passcard,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import PasscardView from "../../../components/companies/passcards/Passcard";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface PasscardContainerProps extends TabComponentProps<"passcardId"> {
  passcard: Passcard;
  loadingPasscard: boolean;
  resetPasscard: () => void;
  loadPasscard: (passcardId: number) => void;
}

const PasscardContainer: React.FC<PasscardContainerProps> = (props) => {
  const {
    resetPasscard,
    loadingPasscard,
    loadPasscard,
    passcard,
    routeParams: { passcardId }
  } = props;
  useEffect(() => loadPasscard(passcardId), [loadPasscard, passcardId]);

  useEffect(() => () => resetPasscard(), [resetPasscard]);
  return (
    <div>
      <ViewActionButtons
        resourceId={passcardId}
        resource={ReduxActionStrings.PASSCARD}
        url="companies/passcards"
      />
      {loadingPasscard ? (
        <LoadingSpinnerPaper />
      ) : (
        <PasscardView passcard={passcard} {...mapTabRouteProps(props)} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadPasscard: (passcardId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "PASSCARD",
        `companies/passcards/${passcardId}/`
      )
    );
  },
  resetPasscard: () =>
    dispatch(LoadingBooleansActions.ResetPasscard(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  passcard: state.tabStates[ownProps.tabId].passcards.passcard.passcard,
  loadingPasscard:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingPasscard
});

export default connect(mapStateToProps, mapDispatchToProps)(PasscardContainer);
