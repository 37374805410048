import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Avatar, WithStyles } from "@material-ui/core";
import { getImageByURL } from "../services/imageHelperService";

const styles = (theme: any) =>
  createStyles({
    image: {
      maxWidth: "100%"
    },
    medium_image: {
      maxWidth: 300,
      maxHeight: 300
    },
    large_image: {
      maxWidth: 500,
      maxHeight: 500
    },
    logo: {
      maxWidth: 300,
      maxHeight: 100,
      float: "right",
      padding: 10
    },
    avatar: {
      width: theme.spacing(9),
      height: theme.spacing(9),
      float: "left"
    },
    round_thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    thumbnail: {
      maxWidth: 100,
      maxHeight: 30
    }
  });

let imageCache: { [key: string]: Promise<string> } = {};

interface ImageContainerProps extends WithStyles<typeof styles> {
  src: string | null;
  type?:
    | "image"
    | "medium_image"
    | "large_image"
    | "logo"
    | "avatar"
    | "round_thumbnail"
    | "thumbnail";
  alt?: string;
}

const ImageContainer: React.FC<ImageContainerProps> = (props) => {
  const { classes, src } = props;

  const [image, setImage] = useState("");
  const type = props.type ? props.type : "image";
  const alt = props.alt ? props.alt : "";

  useEffect(() => {
    let isAlive = true;

    (async () => {
      const fullSrc = src
        ? `${src}${type.search("thumbnail") !== -1 ? "?thumbnail=True" : ""}`
        : null;
      if (fullSrc && !(fullSrc in imageCache)) {
        const imageStr = getImageByURL(fullSrc);
        if (type.search("thumbnail") !== -1) {
          imageCache = { ...imageCache, [fullSrc]: imageStr };
        }
        const imageResult = await imageStr;
        if (isAlive) {
          setImage(imageResult);
        }
      } else if (fullSrc) {
        const imageResult = await imageCache[fullSrc];
        if (isAlive) {
          setImage(imageResult);
        }
      }
    })();

    return () => {
      isAlive = false;
    };
  }, [type, src]);

  if (src) {
    switch (type) {
      case "image":
      case "medium_image":
      case "large_image":
      case "logo":
      case "thumbnail":
        return <img alt={alt} src={image} className={classes[type]} />;
      case "avatar":
      case "round_thumbnail":
        return <Avatar alt={alt} src={image} className={classes[type]} />;
    }
  }
  return <></>;
};

export default withStyles(styles)(ImageContainer);
