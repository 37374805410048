import React, { useCallback, useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import * as types from "../../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import CompanyLinkContainer from "../../../containers/links/CompanyLinkContainer";
import { useTranslate } from "../../../services/appLanguageService";
import {
  getCompanyByIdAPI,
  getContractingProfileAPI
} from "../../../services/api-declaration";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../../redux/types";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface ContractingProfileProps
  extends WithStyles<typeof styles>,
    TabComponentProps {
  contractingprofile: types.ContractingProfile;
}

const ContractingProfile: React.FC<ContractingProfileProps> = (props) => {
  const { classes, contractingprofile, tabId } = props;
  const t = useTranslate("ContractingProfilePage");
  const [expanded, setExpanded] = useState(false);
  const [defaultSigner, setDefaultSigner] = useState("");
  const getCompanyForSigner = async () => {
    try {
      const response = await getContractingProfileAPI(
        Number(contractingprofile.id)
      );
      if (response.default_contract_signer) {
        const companyResponse = await getCompanyByIdAPI(
          response.default_contract_signer
        );
        setDefaultSigner(companyResponse.name);
      }
    } catch (e) {
      console.warn(e);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initCb = useCallback(getCompanyForSigner, []);
  useEffect(() => {
    initCb();
  }, [initCb]);
  useEffect(() => {
    store.dispatch(setTitle(contractingprofile.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractingprofile]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">{contractingprofile.name}</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" className={classes.bold}>
                    {t("currencyLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contractingprofile.currency}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractingCompanyLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contractingprofile.contracting_company ? (
                      <CompanyLinkContainer
                        companyId={contractingprofile.contracting_company}
                        {...mapTabRouteProps(props)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("localeLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {contractingprofile.locale}
                    </TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("fromEmailLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {contractingprofile.from_email}
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("bccEmailLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {contractingprofile.bcc_email}
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className={classes.bold}>
                      {t("defaultMailMessageTextLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {contractingprofile.default_mail_message_text}
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("defaultContractSignerLabel")}
                    </TableCell>
                    <TableCell align="right">{defaultSigner}</TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {contractingprofile.notes}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(contractingprofile.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={7}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(contractingprofile.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(ContractingProfile);
