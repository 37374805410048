import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  PasscardForm,
  Passcard,
  mainRootState,
  SelectFieldOption
} from "../../../redux/types";
import PasscardNewForm from "../../../components/companies/passcards/PasscardNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { format } from "date-fns";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewPasscardContainerProps
  extends FormikSubmitDispatchProps<PasscardForm> {
  loadCustomers: (searchTerm?: string) => void;
  loadContractors: (searchTerm?: string) => void;
  customerOptions: SelectFieldOption[];
  contractorOptions: SelectFieldOption[];
  loadContractorPersons: (companyId: number) => void;
  personOptions: SelectFieldOption[];
  loadCustomerobjects: (companyId: number) => void;
  customerobjectOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Passcard | PasscardForm> {
  loadCustomers: (searchTerm?: string) => void;
  loadContractors: (searchTerm?: string) => void;
  loadContractorPersons: (companyId: number) => void;
  loadCustomerobjects: (companyId: number) => void;
}

const NewPasscardContainer: React.FC<NewPasscardContainerProps> = (props) => {
  const { loadCustomers, loadContractors } = props;

  useEffect(() => loadCustomers(), [loadCustomers]);
  useEffect(() => loadContractors(), [loadContractors]);
  return (
    <PasscardNewForm
      customerOptions={props.customerOptions}
      contractorOptions={props.contractorOptions}
      personOptions={props.personOptions}
      handleSubmit={props.handleSubmit}
      customerobjectOptions={props.customerobjectOptions}
      loadCustomerobjects={props.loadCustomerobjects}
      loadContractorPersons={props.loadContractorPersons}
      loadContractors={loadContractors}
      loadCustomers={loadCustomers}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values,
      out_date: values.out_date
        ? format(new Date(values.out_date), "yyyy-MM-dd")
        : null,
      in_date: values.in_date
        ? format(new Date(values.in_date), "yyyy-MM-dd")
        : null
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<PasscardForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.PASSCARD,
        "companies/passcards/",
        checkedNullValues as Passcard,
        "/companies/passcards?same_tab=true"
      )
    );
  },
  loadCustomers: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PASSCARD_CUSTOMERS,
        `companies/companies/?order__name=asc&filter__company_type=CUSTOMER&filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadContractors: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PASSCARD_CONTRACTORS,
        `companies/companies/?order__name=asc&filter__company_type=CONTRACTOR&filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerobjects: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CUSTOMEROBJECTS,
        `contracts/customerobjects/?order__name=asc&filter__customer=${companyId}&filter__status=ACTIVE`
      )
    );
  },
  loadContractorPersons: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSONS,
        `auth/persons/?order__first_name=asc&filter__company=${companyId}&filter__status=ACTIVE`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  customerOptions: state.tabStates[
    ownProps.tabId
  ].passcards.passcard.customers.map((company) => ({
    label: company.name,
    value: company.id
  })),
  contractorOptions: state.tabStates[
    ownProps.tabId
  ].passcards.passcard.contractors.map((company) => ({
    label: company.name,
    value: company.id
  })),
  personOptions: state.tabStates[ownProps.tabId].persons.persons.persons.map(
    (person) => ({
      label: `${person.first_name} ${person.last_name || ""}`,
      value: person.id
    })
  ),
  customerobjectOptions: state.tabStates[
    ownProps.tabId
  ].customerObjects.customerobjects.customerobjects.map((customerObject) => ({
    label: customerObject.name,
    value: customerObject.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPasscardContainer);
