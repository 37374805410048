import React from "react";
import { WithStyles, Grid } from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  ErrorPayloadType,
  ErrorReportPayloadType,
  EventRelation,
  EventRow
} from "../../redux/types";
import EventRelationLink from "./EventRelationLink";
import ErrorPayload from "./ErrorPayload";
import ErrorReportPayload from "./ErrorReportPayload";

const styles = (theme: any) =>
  createStyles({
    tableCell: {
      borderBottom: 0
    },
    container: {
      marginTop: 20,
      marginBottom: 40
    },
    HIGH: {
      color: "red"
    },
    MEDIUM: {
      color: "orange"
    },
    LOW: {
      color: "green"
    }
  });

interface EventDetailsProps extends WithStyles<typeof styles> {
  event: EventRow;
}

const EventDetails: React.FC<EventDetailsProps> = (props) => {
  const t = useTranslate("EventlogPage");
  const t2 = useTranslate("Events");
  const { classes, event } = props;

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item md={2} xs={4}>
        <b>{t("typeLabel")}</b>
        <br />
        {t2(event.event_type)}
      </Grid>
      <Grid item md={4} xs={4}>
        <b>{t("relatedLabel")}</b>
        <br />
        {event.relations.map((relation: EventRelation, idx) => (
          <EventRelationLink key={idx} eventRelation={relation} />
        ))}
      </Grid>
      <Grid item md={4} xs={4}>
        <b>{t("severityLabel")}</b>
        <br />
        <span className={classes[event.severity]}>{t2(event.severity)}</span>
      </Grid>
      {Object.keys(event.payload).length > 0 && (
        <Grid item md={12} xs={12}>
          <b>{t("detailsLabel")}</b>
          <br />
          {event.event_type === "EXCEPTION" && (
            <ErrorPayload payload={event.payload as ErrorPayloadType} />
          )}
          {event.event_type === "ERROR_REPORT" && (
            <ErrorReportPayload
              payload={event.payload as ErrorReportPayloadType}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(EventDetails);
