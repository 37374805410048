export const RESET_LOADING_ROUTESEGMENTS_TASKS =
  "RESET_LOADING_ROUTESEGMENTS_TASKS";
export const RESET_ROUTE = "RESET_ROUTE";
export const RESET_CONTRACT = "RESET_CONTRACT";
export const RESET_CONTRACTS = "RESET_CONTRACTS";
export const RESET_CONTRACT_ITEMS = "RESET_CONTRACT_ITEMS";
export const RESET_LOADING_SERVICES = "RESET_LOADING_SERVICES";
export const RESET_LOADING_MATERIALS = "RESET_LOADING_MATERIALS";
export const RESET_LOADING_ACCESSORIES = "RESET_LOADING_MATERIALS";
export const RESET_LOADING_ADDONS = "RESET_LOADING_MATERIALS";
export const RESET_ROUTEPLAN = "RESET_ROUTEPLAN";
export const RESET_ROUTEPLANS = "RESET_ROUTEPLANS";
export const RESET_ROUTEINSTANCE = "RESET_ROUTEINSTANCE";
export const RESET_ROUTEINSTANCES = "RESET_ROUTEINSTANCES";
export const RESET_LOADING_TASK_COOPERATIONS =
  "RESET_LOADING_TASK_COOPERATIONS";
export const RESET_LOADING_CONTRACTOR_CONTRACTS =
  "RESET_LOADING_CONTRACTOR_CONTRACTS";
export const RESET_LOADING_TASK_SERVICES = "RESET_LOADING_TASK_SERVICES";
export const RESET_TASK = "RESET_TASK";
export const RESET_TASKS = "RESET_TASKS";
export const RESET_CUSTOMEROBJECT = "RESET_CUSTOMEROBJECT";
export const RESET_CUSTOMEROBJECTS = "RESET_CUSTOMEROBJECTS";
export const RESET_PROSPECT = "RESET_PROSPECT";
export const RESET_PROSPECTS = "RESET_PROSPECTS";
export const RESET_COMPANY = "RESET_COMPANY";
export const RESET_COMPANIES = "RESET_COMPANIES";
export const RESET_INVOICERECIPIENT = "RESET_INVOICERECIPIENT";
export const RESET_INVOICERECIPIENTS = "RESET_INVOICERECIPIENTS";
export const RESET_PASSCARD = "RESET_PASSCARD";
export const RESET_PASSCARDS = "RESET_PASSCARDS";
export const RESET_DISTRICT = "RESET_DISTRICT";
export const RESET_DISTRICTS = "RESET_DISTRICTS";
export const RESET_WEATHERAREA = "RESET_WEATHERAREA";
export const RESET_WEATHERAREAS = "RESET_WEATHERAREAS";
export const RESET_LOCATION = "RESET_LOCATION";
export const RESET_LOCATIONS = "RESET_LOCATIONS";
export const RESET_INVOICINGPROFILE = "RESET_INVOICINGPROFILE";
export const RESET_INVOICINGPROFILES = "RESET_INVOICINGPROFILES";
export const RESET_CONTRACTINGPROFILE = "RESET_CONTRACTINGPROFILE";
export const RESET_CONTRACTINGPROFILES = "RESET_CONTRACTINGPROFILES";
export const RESET_BUSINESSAREA = "RESET_BUSINESSAREA";
export const RESET_BUSINESSAREAS = "RESET_BUSINESSAREAS";
export const RESET_SERVICECATEGORIES = "RESET_SERVICECATEGORIES";
export const RESET_TASKOPTIONS = "RESET_TASKOPTIONS";
export const RESET_PROJECT = "RESET_PROJECT";
export const RESET_PROJECTS = "RESET_PROJECTS";
export const RESET_COOPERATION = "RESET_COOPERATION";
export const RESET_COOPERATIONS = "RESET_COOPERATIONS";
export const RESET_TEMPLATEIMAGE = "RESET_TEMPLATEIMAGE";
export const RESET_TEMPLATEIMAGES = "RESET_TEMPLATEIMAGES";
export const RESET_HOLIDAYS = "RESET_HOLIDAYS";
export const RESET_TERM = "RESET_TERM";
export const RESET_TERMS = "RESET_TERMS";
export const RESET_GROUP = "RESET_GROUP";
export const RESET_GROUPS = "RESET_GROUPS";
export const RESET_USER = "RESET_USER";
export const RESET_USERS = "RESET_USERS";
export const RESET_NOTIFICATIONSPROFILES = "RESET_NOTIFICATIONSPROFILES";
export const RESET_PERSON = "RESET_PERSON";
export const RESET_PERSONS = "RESET_PERSONS";
export const RESET_HISTORY = "RESET_HISTORY";
export const RESET_EMAILS = "RESET_EMAILS";
