import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  BaseServicepackage,
  Contract,
  ContractBaseItem,
  Service,
  Servicepackage,
  Task
} from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import ResourceLink from "../../links/ResourceLink";
import {
  getBaseServicepackageAPI,
  getBusinessareasAPI,
  getContractSupplementsAPI,
  getContractTasksAPI,
  getServicepackageAPI,
  getServicesAPI,
  patchServicepackageAPI
} from "../../../services/api-declaration";
import * as Yup from "yup";
import LockIcon from "@material-ui/icons/Lock";
import AssociatedField from "../../AssociatedField";
import {
  ConfirmationDialogServiceProvider,
  useConfirmationDialog
} from "../../confirmationDialog/ConfirmationDialogServiceProvider";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ContractItemTask from "../ContractItemTask";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface ServicepackageProps extends WithStyles<typeof styles> {
  tabId: number;
  servicepackageId: number;
  contract: Contract | undefined;
}

const ServicepackageView: React.FC<ServicepackageProps> = (props) => {
  const { classes, tabId, contract, servicepackageId } = props;
  const t = useTranslate("ServicepackagePage");
  const t3 = useTranslate("ValidationErrorMessages");

  const [services, setServices] = useState<Service[]>([]);
  const [servicesLoading, setServicesLoading] = useState<boolean>(false);
  const [supplements, setSupplements] = useState<ContractBaseItem[]>([]);
  const [supplementsLoading, setSupplementsLoading] = useState<boolean>(false);
  const [servicepackage, setServicepackage] = useState<Servicepackage>();
  const [baseServicepackage, setBaseServicepackage] =
    useState<BaseServicepackage>();
  const [expanded, setExpanded] = useState(false);
  const updateResourceState = (value: Servicepackage) =>
    setServicepackage(value);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [businessareas, setBusinessareas] = useState<
    { id: number; name: string }[]
  >([]);

  const confirmationDialog = useConfirmationDialog();

  const patchUrl = `contracts/servicepackages/${servicepackageId}/`;

  useEffect(() => {
    if (servicepackage) store.dispatch(setTitle(servicepackage.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicepackage]);

  useEffect(() => {
    if (contract?.id) {
      let isAlive = true;
      (async () => {
        const servicepackage = await getServicepackageAPI(servicepackageId);

        const [
          servicesResponse,
          supplementsResponse,
          baseServiceResponse,
          tasksResponse,
          businessareasResponse
        ] = await Promise.all([
          getServicesAPI({ contract: contract.id }),
          getContractSupplementsAPI(contract.id),
          getBaseServicepackageAPI(servicepackage.base),
          getContractTasksAPI({ customer_contracts: contract.id }),
          getBusinessareasAPI()
        ]);

        if (isAlive) {
          setServicepackage(servicepackage);
          setServices(servicesResponse.results);
          setSupplements(supplementsResponse.results);
          setBaseServicepackage(baseServiceResponse);
          setTasks(tasksResponse.results);
          setBusinessareas(businessareasResponse.results);
        }
      })();
      return () => {
        isAlive = false;
      };
    }
  }, [contract?.id, servicepackageId]);

  const addService = async (
    servicepackageId: number,
    currentSupplementIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("associateServiceConfirmation")
    });
    setServicesLoading(true);

    const response = await patchServicepackageAPI(servicepackageId, {
      services: currentSupplementIds
    });

    updateResourceState(response);
    setServicesLoading(false);
  };

  const removeService = async (
    servicepackageId: number,
    currentServiceIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateServiceConfirmation")
    });
    setServicesLoading(true);
    const response = await patchServicepackageAPI(servicepackageId, {
      services: currentServiceIds
    });

    updateResourceState(response);
    setServicesLoading(false);
  };

  const addSupplement = async (
    servicepackageId: number,
    currentSupplementIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("associateSupplementConfirmation")
    });
    setSupplementsLoading(true);

    const response = await patchServicepackageAPI(servicepackageId, {
      supplements: currentSupplementIds
    });

    updateResourceState(response);
    setSupplementsLoading(false);
  };

  const removeSupplement = async (
    servicepackageId: number,
    currentSupplementIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateSupplementConfirmation")
    });
    setSupplementsLoading(true);
    const response = await patchServicepackageAPI(servicepackageId, {
      supplements: currentSupplementIds
    });

    updateResourceState(response);
    setSupplementsLoading(false);
  };

  const servicepackageIsLocked =
    contract?.locked_items["servicepackages"].includes(servicepackageId) ||
    false;

  if (!servicepackage) {
    return <LoadingSpinnerPaper />;
  }

  const supplementOptions = supplements
    .filter(
      (s) =>
        !servicepackage.task ||
        s.task === null ||
        s.task === servicepackage.task
    )
    .map((supplement) => {
      const task = tasks.find((t) => t.id === supplement.task);
      const businessarea = businessareas.find(
        (b) => b.id === task?.businessarea
      );
      return {
        value: supplement.id,
        label: `${supplement.name} ${
          supplement.task && task && businessarea
            ? `(${task.name}) - ${businessarea.name}`
            : ""
        }`
      };
    });

  const serviceOptions = services
    .filter(
      (s) =>
        !servicepackage.task ||
        s.task === null ||
        s.task === servicepackage.task
    )
    .map((service) => {
      const task = tasks.find((t) => t.id === service.task);
      const businessarea = businessareas.find(
        (b) => b.id === task?.businessarea
      );
      return {
        value: service.id,
        label: `${service.name} ${
          service.task && task && businessarea
            ? `(${task.name}) - ${businessarea.name}`
            : ""
        }`
      };
    });

  return servicepackage.contract !== contract?.id ? (
    <Paper className={classes.pageNotFound}>
      <Typography variant="h6">{t("pageNotFoundTitle")}</Typography>
    </Paper>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      {contract?.name}: <b>{servicepackage.name}</b>{" "}
                      {servicepackageIsLocked && (
                        <Tooltip title={t("serviceLockedLabel")}>
                          <LockIcon />
                        </Tooltip>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {servicepackageIsLocked ? (
                      servicepackage.name
                    ) : (
                      <EditableTextField
                        field="name"
                        initValue={servicepackage.name}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .min(1, t3("minLengthError"))
                          .max(255, t3("max255LengthError"))
                          .required(t3("requiredError"))}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contract && (
                      <ResourceLink
                        label={contract.name}
                        id={contract.id}
                        url="contracts/contracts"
                      />
                    )}
                  </TableCell>
                </TableRow>
                {servicepackage.price !== null && (
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {servicepackageIsLocked ? (
                        servicepackage.price
                      ) : (
                        <EditableTextField
                          isPrice
                          field="price"
                          initValue={servicepackage.price}
                          url={patchUrl}
                          updateResourceState={updateResourceState}
                          number={true}
                          validation={Yup.number()
                            .typeError(t3("numberRequiredError"))
                            .min(0, t3("minZeroError"))
                            .max(10000000, t3("max10000000NumberError"))
                            .required(t3("requiredError"))}
                        />
                      )}{" "}
                      kr {servicepackage.unit ? `/ ${servicepackage.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key={5}>
                  <TableCell align="left" className={classes.bold}>
                    {t("baseServicepackageLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {baseServicepackage && (
                      <ResourceLink
                        url="contracts/baseservicepackages"
                        label={baseServicepackage.name}
                        id={baseServicepackage.id}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("reportLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="report"
                        label={t("reportLabel")}
                        initValue={servicepackage.report}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("dayReportLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="day_report"
                        label={t("dayReportLabel")}
                        initValue={servicepackage.day_report}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("orderByLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="order_by"
                        initValue={servicepackage.order_by}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={servicepackage.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("displayLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="display"
                        label={t("displayLabel")}
                        initValue={servicepackage.display}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className={classes.bold}>
                      {t("priorityLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="priority"
                        initValue={servicepackage.priority}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={7}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(servicepackage.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={8}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(servicepackage.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContractItemTask taskId={servicepackage.task} />
          {services && servicepackage && (
            <AssociatedField
              field="services"
              resourceIds={servicepackage.services}
              options={serviceOptions}
              addResource={addService}
              owningId={servicepackage.id}
              label={t("serviceLabel")}
              removeResource={removeService}
              isLoading={servicesLoading}
              title={t("servicesLabel")}
              locked={servicepackageIsLocked}
            />
          )}
          {supplements && servicepackage && (
            <AssociatedField
              field="supplements"
              resourceIds={servicepackage.supplements}
              options={supplementOptions}
              addResource={addSupplement}
              owningId={servicepackage.id}
              label={t("supplementLabel")}
              removeResource={removeSupplement}
              isLoading={supplementsLoading}
              title={t("supplementsLabel")}
              locked={servicepackageIsLocked}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)((props: ServicepackageProps) => (
  <ConfirmationDialogServiceProvider>
    <ServicepackageView {...props} />
  </ConfirmationDialogServiceProvider>
));
