import { TextField } from "@material-ui/core";
import { FieldProps } from "formik";
import React, { useEffect, useState } from "react";

interface TextFieldProps {
  keyName: string;
  fieldValueData: any;
  onBlur?: (e: any) => void;
}

const TextFieldComponent: React.FC<TextFieldProps & FieldProps> = (props) => {
  const { keyName, form, fieldValueData, onBlur, ...others } = props;
  const [data, setData] = useState(fieldValueData);

  useEffect(() => {
    setData(fieldValueData);
  }, [fieldValueData]);

  const handleOnBlur = (e: any) => {
    form.setFieldValue(keyName, e.target.value);
  };

  return (
    <TextField
      value={data}
      onChange={(e) => setData(e.target.value)}
      onBlur={onBlur ? onBlur : handleOnBlur}
      {...others}
    />
  );
};

export default TextFieldComponent;
