import React, { useState, useEffect } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Collapse,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import * as types from "../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarIcon from "@material-ui/icons/Star";
import AddIcon from "@material-ui/icons/Add";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { niceDate } from "../FormatHelpers";
import { useTranslate } from "../../services/appLanguageService";
import CompanyLinkContainer from "../../containers/links/CompanyLinkContainer";
import GroupsContainer from "../../containers/groups/GroupsContainer";
import { Formik, Field } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link, LinkProps } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ConfirmationDialogServiceProvider,
  useConfirmationDialog
} from "../confirmationDialog/ConfirmationDialogServiceProvider";
import NotificationsProfilesContainer from "../../containers/users/NotificationsProfilesContainer";
import ImageContainer from "../../containers/ImageContainer";
import {
  patchPersonNotesAPI,
  sendPasswordResetEmailByIdAPI
} from "../../services/api-declaration";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import DistrictsContainer from "../../containers/companies/districts/DistrictsContainer";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../redux/types";
import { mapTabRouteProps, validateApiUsage } from "../../helpers/routesHelper";
import { getFromSessionStore } from "../../services/storageService";
import { setAdhocUser } from "../../services/authenticationService";
import EditableNotes from "../EditableNotes";

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    starIcon: {
      color: "#EE0",
      float: "right"
    },
    root: {
      flexGrow: 1
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    tablePaper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    userButton: {
      float: "right"
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    large: {
      width: theme.spacing(9),
      height: theme.spacing(9),
      float: "left"
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    },
    title: {
      clear: "left",
      paddingTop: 10
    }
  });

interface PersonProps
  extends types.FormikSubmitDispatchProps<types.CreateUserForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  person: types.Person;
  user: types.User;
  deleteUser: (userId: number) => void;
  loadPerson: (userId: number) => void;
  deletePerson: (personId: number) => void;
}

const Person: React.FC<PersonProps> = (props) => {
  const { classes, person, user, handleSubmit, tabId } = props;
  const t = useTranslate("PersonPage");
  const t2 = useTranslate("Persons");
  const t4 = useTranslate("Users");
  const [expanded, setExpanded] = useState(false);
  const [userExpanded, setUserExpanded] = useState(false);
  const [showNewUser, setShowNewUser] = React.useState(false);
  const [values, setValues] = useState({
    showPassword: false
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const isSuperuser = getFromSessionStore("is_superuser") === "true";
  const readonly =
    !isSuperuser && !validateApiUsage({ apiUsage: ["auth/persons"] });

  const initialValues = {
    username: "",
    password: "",
    passwordConfirmation: "",
    is_staff: false,
    is_superuser: false,
    person: person.id
  };

  const confirmationDialog = useConfirmationDialog();
  const handleUserDeleteClick = async () => {
    await confirmationDialog({
      title: t("deleteUserAccountTitle"),
      description: t("areYouSureLabel")
    });
    if (user && user.id) {
      setShowNewUser(false);
      props.deleteUser(user.id);
    }
  };
  const handlePersonDeleteClick = async () => {
    await confirmationDialog({
      title: t("deletePersonTitle"),
      description: t("areYouSureLabel")
    });
    props.deletePerson(person.id);
  };
  const handleEmailSend = async (user: types.User) => {
    try {
      if (user.email) {
        const response = await sendPasswordResetEmailByIdAPI(user.id);
        if (response) {
          showGlobalSnackbar(
            `${t("successSnackbarMessage")} ${user.email}`,
            "success"
          );
        }
      } else {
        showGlobalSnackbar(`${t("noEmailAddressMessage")}`, "default");
      }
    } catch (e) {
      console.warn(e);
    }
  };
  const saveNotes = async (id: number, notes: string | null) => {
    await patchPersonNotesAPI(id, notes);
    props.loadPerson(id);
  };
  const handleAdhocKeyClick = (userId: number) => async () => {
    try {
      await setAdhocUser(userId);

      showGlobalSnackbar("Adhoc User is set", "success"); // TODO: Use language translation
    } catch (error) {
      console.log(error);
      showGlobalSnackbar("Failed to get token Adhoc User", "error"); // TODO: Use language translation
    }
  };
  useEffect(() => {
    store.dispatch(setTitle(`${person.first_name} ${person.last_name}`, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  return (
    <>
      <div>
        <Button
          id={"person-to-persons"}
          variant="contained"
          color="primary"
          component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
            (props, ref) => (
              <Link
                to="/auth/persons?same_tab=true"
                {...props}
                ref={ref as any}
              />
            )
          )}
        >
          <NavigateBeforeIcon /> {t("personNavigationButtonLabel")}
        </Button>
        {!readonly && !(user && user.id !== -1 && user.person === person.id) ? (
          <Button
            id="user-new-user"
            data-cy="user-new-user"
            className={classes.editButton}
            variant="contained"
            color="primary"
            onClick={() =>
              showNewUser ? setShowNewUser(false) : setShowNewUser(true)
            }
          >
            <AddIcon /> {t("createUserAccount")}
          </Button>
        ) : (
          ""
        )}
      </div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper} className={classes.tablePaper}>
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell colSpan={2}>
                      <ImageContainer src={person.image} type="avatar" />
                      {isSuperuser && (
                        <>
                          <Button
                            id={`person-delete-person`}
                            data-cy={`person-delete-person`}
                            color="secondary"
                            className={classes.userButton}
                            onClick={handlePersonDeleteClick}
                          >
                            <DeleteIcon />
                          </Button>
                          <Button
                            id="person-edit-person"
                            data-cy="person-edit-person"
                            className={classes.userButton}
                            color="primary"
                            component={React.forwardRef<
                              HTMLAnchorElement,
                              Partial<LinkProps>
                            >((props, ref) => (
                              <Link
                                to={`/auth/persons/${person.id}/edit`}
                                {...props}
                                ref={ref as any}
                              />
                            ))}
                          >
                            <EditIcon />
                          </Button>
                        </>
                      )}
                      {person.is_key_person && (
                        <StarIcon className={classes.starIcon} />
                      )}
                      <Typography variant="h5" className={classes.title}>{`${
                        person.first_name
                      } ${person.last_name || ""}`}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("statusLabel")}
                    </TableCell>
                    <TableCell align="right">{t2(person.status)}</TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("titleLabel")}
                    </TableCell>
                    <TableCell align="right">{t2(person.title)}</TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className={classes.bold}>
                      {t("companyLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {person.company && (
                        <CompanyLinkContainer
                          companyId={person.company}
                          {...mapTabRouteProps(props)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.expandHeader}
                onClick={() =>
                  expanded ? setExpanded(false) : setExpanded(true)
                }
              >
                <Typography>
                  {expanded ? t("hideLabel") : t("showMoreLabel")}
                </Typography>
              </AccordionSummary>
              <TableContainer
                component={AccordionDetails}
                className={classes.tableContainer}
              >
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    <TableRow key={0}>
                      <TableCell align="left" className={classes.bold}>
                        {t("phone1Label")}
                      </TableCell>
                      <TableCell align="right">{person.phone1}</TableCell>
                    </TableRow>
                    <TableRow key={1}>
                      <TableCell align="left" className={classes.bold}>
                        {t("phone2Label")}
                      </TableCell>
                      <TableCell align="right">{person.phone2}</TableCell>
                    </TableRow>
                    <TableRow key={2}>
                      <TableCell align="left" className={classes.bold}>
                        {t("emailLabel")}
                      </TableCell>
                      <TableCell align="right">{person.email}</TableCell>
                    </TableRow>
                    <TableRow key={3}>
                      <TableCell align="left" className={classes.bold}>
                        {t("personalCodeLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {person.personal_code}
                      </TableCell>
                    </TableRow>
                    <TableRow key={4}>
                      <TableCell align="left" className={classes.bold}>
                        {t("isKeyPersonLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {person.is_key_person ? t("yes") : t("no")}
                      </TableCell>
                    </TableRow>
                    <TableRow key={5}>
                      <TableCell align="left" className={classes.bold}>
                        {t("createdAtLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {niceDate(person.created_at)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={6}>
                      <TableCell align="left" className={classes.bold}>
                        {t("updatedAtLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {niceDate(person.updated_at)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={7}>
                      <TableCell align="left" className={classes.bold}>
                        {t("gdprAcceptedAtLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {niceDate(user.accepted_terms_date)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={8}>
                      <TableCell align="left" className={classes.bold}>
                        {t("ipAcceptedAtLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {user.accepted_from_ip}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Accordion>
            <EditableNotes
              resourceId={person.id}
              notes={person.notes}
              saveNotes={saveNotes}
              readonly={readonly}
            />
            {user && user.id !== -1 && user.districts && (
              <DistrictsContainer
                showNewButton={false}
                districtIds={user.districts}
                {...mapTabRouteProps(props)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {user && user.id !== -1 && user.person === person.id ? (
              <>
                <TableContainer
                  component={Paper}
                  className={classes.tablePaper}
                >
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow key={0}>
                        <TableCell colSpan={2}>
                          <Typography component="h1" variant="h6">
                            {t("userProfileLabel")}: {user.username}
                            {isSuperuser && (
                              <>
                                <Button
                                  id={`user-delete-user`}
                                  data-cy={`user-delete-user`}
                                  color="secondary"
                                  className={classes.userButton}
                                  onClick={handleUserDeleteClick}
                                >
                                  <DeleteIcon />
                                </Button>
                                <Button
                                  id="user-edit-user"
                                  data-cy="user-edit-user"
                                  color="primary"
                                  className={classes.userButton}
                                  component={React.forwardRef<
                                    HTMLAnchorElement,
                                    Partial<LinkProps>
                                  >((props, ref) => (
                                    <Link
                                      to={`/auth/users/${user.id}/edit`}
                                      {...props}
                                      ref={ref as any}
                                    />
                                  ))}
                                >
                                  <EditIcon />
                                </Button>
                              </>
                            )}
                            {isSuperuser && window.masterUserId !== user.id && (
                              <Button
                                id={`user-adhoc-user`}
                                data-cy={`user-adhoc-user`}
                                color="primary"
                                className={classes.userButton}
                                onClick={handleAdhocKeyClick(user.id)}
                              >
                                <VpnKeyIcon />
                              </Button>
                            )}
                            {!readonly && (
                              <Button
                                color="primary"
                                variant="outlined"
                                className={classes.userButton}
                                onClick={() => handleEmailSend(user)}
                              >
                                {t("resetPasswordLabel")}
                              </Button>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow key={1}>
                        <TableCell align="left" className={classes.bold}>
                          {t("statusLabel")}
                        </TableCell>
                        <TableCell align="right">{t4(user.status)}</TableCell>
                      </TableRow>
                      {isSuperuser && (
                        <TableRow key={2}>
                          <TableCell align="left" className={classes.bold}>
                            {t("isSuperUserLabel")}
                          </TableCell>
                          <TableCell align="right">
                            {user.is_superuser ? t("yes") : t("no")}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow key={3}>
                        <TableCell align="left" className={classes.bold}>
                          {t("isStaffLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {user.is_staff ? t("yes") : t("no")}
                        </TableCell>
                      </TableRow>
                      <TableRow key={4}>
                        <TableCell align="left" className={classes.bold}>
                          {t("languageLabel")}
                        </TableCell>
                        <TableCell align="right">{t2(user.language)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.expandHeader}
                    onClick={() =>
                      userExpanded
                        ? setUserExpanded(false)
                        : setUserExpanded(true)
                    }
                  >
                    <Typography>
                      {userExpanded ? t("hideLabel") : t("showMoreLabel")}
                    </Typography>
                  </AccordionSummary>
                  <TableContainer
                    component={AccordionDetails}
                    className={classes.tableContainer}
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow key={0}>
                          <TableCell align="left" className={classes.bold}>
                            {t("lastLoginLabel")}
                          </TableCell>
                          <TableCell align="right">
                            {user.last_login
                              ? niceDate(user.last_login)
                              : t("neverLabel")}
                          </TableCell>
                        </TableRow>
                        <TableRow key={1}>
                          <TableCell align="left" className={classes.bold}>
                            {t("createdAtLabel")}
                          </TableCell>
                          <TableCell align="right">
                            {niceDate(user.created_at)}
                          </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell align="left" className={classes.bold}>
                            {t("updatedAtLabel")}
                          </TableCell>
                          <TableCell align="right">
                            {niceDate(user.updated_at)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Accordion>
                {user && user.groups && (
                  <GroupsContainer
                    showNewButton={false}
                    groupIds={user.groups}
                    {...mapTabRouteProps(props)}
                  />
                )}
                {user && user.notifications_profiles && (
                  <NotificationsProfilesContainer
                    showNewButton={false}
                    notificationsprofileIds={user.notifications_profiles}
                    {...mapTabRouteProps(props)}
                  />
                )}
              </>
            ) : (
              showNewUser && <>
                <Collapse in={showNewUser}>
                  <Paper className={classes.paper}>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={handleSubmit}
                    >
                      {(props) => (
                        <form
                          className={classes.form}
                          onSubmit={props.handleSubmit}
                          autoComplete="off"
                        >
                          <Typography component="h1" variant="h6">
                            {t("newUserAccount")}
                          </Typography>
                          <Field
                            data-cy="username-field"
                            type="text"
                            name="username"
                            label={t("usernameLabel")}
                            placeholder={t("usernameLabel")}
                            component={TextField}
                            margin="normal"
                            fullWidth
                          />
                          <Field
                            data-cy="password-field"
                            type={values.showPassword ? "text" : "password"}
                            name="password"
                            label={t("passwordLabel")}
                            placeholder={t("passwordLabel")}
                            component={TextField}
                            margin="normal"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={handleClickShowPassword}
                                  >
                                    {values.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          <Field
                            data-cy="password-confirm-field"
                            type={values.showPassword ? "text" : "password"}
                            name="passwordConfirmation"
                            label={t("passwordConfirmationLabel")}
                            placeholder={t("passwordConfirmationLabel")}
                            component={TextField}
                            margin="normal"
                            fullWidth
                          />

                          {isSuperuser && (
                            <Field
                              data-cy="is-superuser-checkbox"
                              name="is_superuser"
                              type="checkbox"
                              Label={{ label: t("isSuperUserLabel") }}
                              component={CheckboxWithLabel}
                            />
                          )}
                          <Field
                            data-cy="is-staff-checkbox"
                            name="is_staff"
                            type="checkbox"
                            Label={{ label: t("isStaffLabel") }}
                            component={CheckboxWithLabel}
                          />

                          <Button
                            data-cy="form-submit-btn"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                          >
                            {t("createButtonLabel")}
                          </Button>
                        </form>
                      )}
                    </Formik>
                  </Paper>
                </Collapse>
              </>)
            }
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)((props: PersonProps) => (
  <ConfirmationDialogServiceProvider>
    <Person {...props} />
  </ConfirmationDialogServiceProvider>
));
