import {
  AUTHENTICATION_REQUEST,
  LOGOUT_REQUEST,
  AuthenticationActionTypes,
  AUTHENTICATION_SUCCESS,
  RESET_LOGIN_ERROR
} from "./actionTypes";

export function authenticate(
  domain: string,
  username: string,
  password: string,
  rememberMe: boolean = false
): AuthenticationActionTypes {
  return {
    type: AUTHENTICATION_REQUEST,
    payload: {
      domain,
      username,
      password,
      rememberMe
    }
  };
}

export function createResetLoginError(): AuthenticationActionTypes {
  return {
    type: RESET_LOGIN_ERROR
  };
}

export function authSuccess(username: string, domain: string) {
  return {
    type: AUTHENTICATION_SUCCESS,
    payload: { username, domain }
  };
}

export function logout(): AuthenticationActionTypes {
  return {
    type: LOGOUT_REQUEST
  };
}
