import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {
  FieldArray,
  FormikProps,
  FieldArrayRenderProps,
  ErrorMessage
} from "formik";
import {
  WithStyles,
  Paper,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer
} from "@material-ui/core";

import NoteAddIcon from "@material-ui/icons/NoteAdd";
import {
  SelectFieldOption,
  FormikSubmitDispatchProps,
  RouteParticipantForm,
  RouteSegment,
  RouteInstanceDeleteDialogOptions,
  RouteInstance,
  RoutePlan,
  RouteInstanceForm,
  Service
} from "../../../redux/types";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import { DialogComponent } from "../../confirmationDialog/RouteInstanceDialog";
import { useTranslate } from "../../../services/appLanguageService";
import RouteParticipantsTableRow from "./RouteParticipantsTableRow";
import {
  getActiveContractIdsByCompanyIdAPI,
  getServicesAPI
} from "../../../services/api-declaration";

const styles = (theme: any) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down("sm")]: {
        padding: 1,
        whiteSpace: "nowrap",
        overflowY: "scroll"
      }
    },
    table: {
      minWidth: 800
    },
    participantNumber: {
      width: 30,
      [theme.breakpoints.down("sm")]: {
        width: 10,
        padding: 0
      }
    },
    textField: {
      width: 270,        whiteSpace: "nowrap", 
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: 10,
        padding: 0,
        textAlign: "center",
        marginRight: 15,

      }
    },
    paddingTop: {
      paddingTop: theme.spacing(0.5)
    },
    addNoteCell: {
      border: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    disabledPaper: { backgroundColor: "whitesmoke" },
    enabled: { pointerEvents: "all" },
    disabled: { pointerEvents: "none" },
    bold: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 5
      }
    },
    errorMessage: {
      color: "red"
    }
  });

interface RouteParticipantsProps
  extends FormikSubmitDispatchProps<any>,
    WithStyles<typeof styles> {
  route?: RouteInstance | RoutePlan;
  companyOptions: SelectFieldOption[];
  searchContractorCompanies?: (
    filter: string,
    serviceCategoriesIds: string,
    pageNumber?: number,
    keepHistory?: boolean
  ) => void;
  isLoading: boolean;
  formikProps: FormikProps<RouteInstanceForm>;
  isDispatched: boolean;
  readOnlyMode: boolean;
}

const RouteParticipantsComponent: React.FC<RouteParticipantsProps> = (
  props
) => {
  const {
    classes,
    companyOptions,
    formikProps,
    isDispatched,
    isLoading,
    route,
    readOnlyMode,
    searchContractorCompanies
  } = props;
  const t = useTranslate("NewRouteInstancePage");
  const d = useTranslate("RouteInstanceDialog");
  const initialDialogValues: RouteInstanceDeleteDialogOptions = {
    show: false,
    description: ""
  };
  const [dialogContent, setDialogContent] =
    useState<RouteInstanceDeleteDialogOptions>(initialDialogValues);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const showDialogWithData =
    (index: number, arrHelpers: FieldArrayRenderProps) => () => {
      setDialogContent({
        show: !dialogContent.show,
        rowIndex: index,
        arrayHelpers: arrHelpers,
        description: d("routeParticipantDescription")
      });
    };

  // This creates a comparible effect dependency for a list of ids
  const contractorKey = formikProps.values.participants
    .map((p) => p.contractor)
    .join("_");
  const [contractorServices, setContractorServices] = useState<{
    [contractorId: number]: Service[];
  }>({});
  useEffect(() => {
    let alive = true;
    setIsLoadingServices(true);
    const contractors = formikProps.values.participants
      .map((p) => p.contractor)
      .filter((p) => p !== -1);
    void Promise.all(
      contractors.map((companyId) =>
        getActiveContractIdsByCompanyIdAPI(companyId).then(
          async ({ results }) =>
            [
              companyId,
              results.length > 0
                ? (await getServicesAPI({ contract: results[0].id }))?.results
                : []
            ] as const
        )
      )
    ).then((entries) => {
      if (alive) {
        setContractorServices(Object.fromEntries(entries));
        setIsLoadingServices(false);
      }
    });
    return () => {
      alive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractorKey]);

  const onCloseDialog = () => setDialogContent(initialDialogValues);
  const onDeleteDialogSuccess = () => {
    if (dialogContent.arrayHelpers) {
      dialogContent.arrayHelpers.remove(dialogContent.rowIndex);
      onCloseDialog();
    }
  };

  const addParticipant = (arrayHelpers: FieldArrayRenderProps) => {
    const participant_id =
      formikProps.values.participants.length > 0
        ? formikProps.values.participants.reduce(
            (
              max_participant: RouteParticipantForm,
              participant: RouteParticipantForm
            ) =>
              max_participant.participant_id > participant.participant_id
                ? max_participant
                : participant
          ).participant_id + 1
        : 0;

    arrayHelpers.push({
      additional_people: [],
      multiple: 1,
      instructions: "",
      sort_order: -1,
      contractor: -1,
      baseservice: -1,
      participant_id: participant_id
    });
    formikProps.values.rows = formikProps.values.rows.map(
      (route_row: RouteSegment) => ({
        ...route_row,
        participants: [...route_row.participants, participant_id]
      })
    );
  };

  return (
    <>
      {(!route || (route && route.id !== -1)) && !isLoading ? (
        <div>
          <Paper
            className={`${classes.paper} ${
              !readOnlyMode && isDispatched && classes.disabledPaper
            }`}
          >
            <Typography component="h1" variant="h6" className={classes.bold}>
              {t("routeParticipantsLabel")}
            </Typography>
            <TableContainer>
              <Table
                className={`${classes.table} ${
                  isDispatched || readOnlyMode
                    ? classes.disabled
                    : classes.enabled
                }`}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.participantNumber}
                      align="center"
                    >
                      {t("numberLabel")}
                    </TableCell>
                    <TableCell className={classes.textField}>
                      {t("contractorLabel")}
                    </TableCell>
                    <TableCell className={classes.textField}>
                      {t("personLabel")}
                    </TableCell>
                    <TableCell className={classes.textField}>
                      {t("serviceLabel")}
                    </TableCell>
                    <TableCell>{t("instructionsLabel")}</TableCell>
                    {!readOnlyMode && (
                      <TableCell
                        className={classes.participantNumber}
                        align="center"
                      >
                        {t("removeLabel")}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <FieldArray name="participants">
                  {(arrayHelpers) => (
                    <TableBody>
                      {formikProps.values.participants.map(
                        (participant: RouteParticipantForm, index: number) => (
                          <RouteParticipantsTableRow
                            key={index}
                            formikProps={formikProps}
                            index={index}
                            route={route}
                            readOnlyMode={readOnlyMode}
                            isDispatched={isDispatched}
                            showDialogWithData={showDialogWithData}
                            arrayHelpers={arrayHelpers}
                            participant={participant}
                            companyOptions={companyOptions}
                            services={
                              contractorServices[participant.contractor] ?? []
                            }
                            searchContractorCompanies={
                              searchContractorCompanies
                            }
                            isLoadingServices={isLoadingServices}
                          />
                        )
                      )}
                      {!readOnlyMode && (
                        <TableRow>
                          <TableCell className={classes.addNoteCell}>
                            <IconButton
                              id={`routeplan-form-routeparticipant-new`}
                              color="primary"
                              onClick={() => {
                                addParticipant(arrayHelpers);
                              }}
                              disabled={isDispatched}
                            >
                              <NoteAddIcon fontSize="large" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </FieldArray>
              </Table>
            </TableContainer>
            <ErrorMessage name="participants">
              {(msg) => (
                <div className={classes.errorMessage}>
                  {typeof msg === "string" && msg}
                </div>
              )}
            </ErrorMessage>
          </Paper>
          <DialogComponent
            show={dialogContent.show}
            title={d("participantDialogTitle")}
            description={dialogContent.description}
            successButtonLabel={d("removeButtonLabel")}
            closeButtonLabel={d("closeButtonLabel")}
            onClose={onCloseDialog}
            onSuccess={onDeleteDialogSuccess}
            dialogType="Remove"
            dispatchError={null}
          />
        </div>
      ) : (
        <LoadingSpinnerPaper />
      )}
    </>
  );
};

RouteParticipantsComponent.defaultProps = {
  readOnlyMode: false
};

export default withStyles(styles)(RouteParticipantsComponent);
