export const REST_REQUEST = "REST_REQUEST";
export const REST_REQUEST_WITH_DATA = "REST_REQUEST_WITH_DATA";

export interface Payload {
  restType: string;
  url: string;
  actionType: string;
  redirect?: string;
  redirectWithId?: string;
  id?: number;
  callback?: (response: any) => any;
  failCallback?: (response: any) => any;
}

export interface PayloadWithData<T> extends Payload {
  data: T;
}

export interface RestRequest {
  type: typeof REST_REQUEST;
  tabId: number;
  payload: Payload;
}

export interface RestRequestWithData<T> {
  type: typeof REST_REQUEST_WITH_DATA;
  tabId: number;
  payload: PayloadWithData<T>;
}
