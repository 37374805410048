import { LayoutRouteUsage } from "../../../redux/types";
import CustomerobjectsContainer from "./CustomerobjectsContainer";
import CustomerobjectNewFormContainer from "./CustomerobjectNewFormContainer";
import CustomerobjectEditFormContainer from "./CustomerobjectEditFormContainer";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import CustomerobjectInvoiceLocationForm from "../../../components/contracts/customerobjects/CustomerobjectInvoiceLocationForm";
import CustomerobjectView from "../../../components/contracts/customerobjects/Customerobject";

const CustomerObjectRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["features/customerobjects"],
    routes: [
      {
        path: "/contracts/customerobjects",
        label: t("contractNavbarCustomerobjectsLabel"),
        component: CustomerobjectsContainer,
        icon: BeenhereIcon,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS,
        navId: NavItemClassEnum.CUSTOMEROBJECTS
      },
      {
        path: "/contracts/customerobjects/new",
        parent: "/contracts/customerobjects",
        label: t("newCustomerObject"),
        component: CustomerobjectNewFormContainer,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS
      },
      {
        path: "/contracts/customerobjects/:customerobjectId",
        parent: "/contracts/customerobjects",
        label: t("customerobjectLabel"),
        component: CustomerobjectView,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS,
        suspendable: true
      },
      {
        path: "/contracts/customerobjects/:customerobjectId/edit",
        parent: "/contracts/customerobjects/:customerobjectId",
        label: t("editCustomerObject"),
        component: CustomerobjectEditFormContainer,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS
      },
      {
        path: "/contracts/customerobjects/:customerobjectId/edit-invoice-location",
        parent: "/contracts/customerobjects/:customerobjectId",
        label: t("editInvoiceLocation"),
        component: CustomerobjectInvoiceLocationForm,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS
      }
    ]
  }
];

export default CustomerObjectRouter;
