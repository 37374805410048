import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Company } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import ResourceLink from "../../../components/links/ResourceLink";

interface CompanyLinkContainerProps {
  companyId: number;
  company: Company;
  loadCompany: (companyId: number) => void;
}

const CompanyLinkContainer: React.FC<CompanyLinkContainerProps> = (props) => {
  const { loadCompany, companyId, company } = props;
  useEffect(() => loadCompany(companyId), [loadCompany, companyId]);
  return (
    <span>
      {company && (
        <ResourceLink
          label={company.name}
          id={company.id}
          url="companies/companies"
        />
      )}
    </span>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadCompany: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PASSCARD_CUSTOMER,
        `companies/companies/${companyId}/`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  company: state.tabStates[ownProps.tabId].passcards.passcard.owner_company
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyLinkContainer);
