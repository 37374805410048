import { HistoryState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: HistoryState = {
  history: {
    history: [],
    next: null,
    previous: null,
    count: 0
  },
  customers: [],
  contractors: [],
  customerobjects: [],
  businessareas: []
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.HISTORY):
      return {
        ...state,
        history: {
          ...state.history,
          history: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.HISTORY_CUSTOMERS
    ):
      return {
        ...state,
        customers: action.payload.results
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.HISTORY_CONTRACTORS
    ):
      return {
        ...state,
        contractors: action.payload.results
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.HISTORY_CUSTOMEROBJECTS
    ):
      return {
        ...state,
        customerobjects: action.payload.results
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.HISTORY_BUSINESSAREAS
    ):
      return {
        ...state,
        businessareas: action.payload.results
      };

    default:
      return state;
  }
}

export default reducer;
