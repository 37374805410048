import svenska from "./sv-SE.json";
import english from "./en-EN.json";
import { LanguageState } from "../redux/types";

interface ILanguageJSON {
  [key: string]: any;
  locale: LanguageState;
}
const languagesList = [svenska as ILanguageJSON, english as ILanguageJSON];

const languages: { [locale: string]: ILanguageJSON } = {};
languagesList.forEach((lng, index) => {
  if (lng) {
    languages[lng.locale] = lng;
  } else {
    console.log(`${index}th language does not exist from the list`);
  }
});

export default languages;
