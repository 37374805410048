import {
  InputAdornment,
  IconButton,
  createStyles,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";
import { useRef, useState } from "react";
import { useTranslate } from "../../../services/appLanguageService";

const styles = (theme: any) =>
  createStyles({
    search: {
      marginTop: 15,
      float: "right"
    },
    freeStandingSearch: {
      marginRight: 20
    }
  });

interface TableSearchProps extends WithStyles<typeof styles> {
  showAddButton: boolean;
  pluralName: string;
  handleSearch: (searchString: string) => void;
}

const TableSearch: React.FC<TableSearchProps> = (props) => {
  const { classes, showAddButton, pluralName, handleSearch } = props;

  const [searchText, setSearchText] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const t = useTranslate("ResourceTable");

  const windowSize = useRef([window.innerWidth]);

  return (
    <div
      className={`${classes.search} ${
        !showAddButton && classes.freeStandingSearch
      }`}
    >
      {!showSearch && +windowSize.current[0] < 1000 ? (
        <IconButton
          aria-label="show search"
          data-cy={`${pluralName}-show-search`}
          onClick={() => {
            setShowSearch(true);
          }}
        >
          <SearchIcon />
        </IconButton>
      ) : (
        <TextField
          data-cy={`${pluralName}-search-field`}
          value={searchText}
          onChange={(event: any) => {
            const _searchText = event.target.value;
            setSearchText(_searchText);
            handleSearch(_searchText);
          }}
          placeholder={t("searchLabel")}
          inputProps={{ "aria-label": t("searchLabel") }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  data-cy={`${pluralName}-clear-search`}
                  disabled={searchText === ""}
                  onClick={() => {
                    setSearchText("");
                    handleSearch("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(TableSearch);
