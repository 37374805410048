import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  CompanyForm,
  Company,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import CompanyNewEditForm from "../../../components/companies/companies/CompanyNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditCompanyContainerProps
  extends FormikSubmitDispatchProps<CompanyForm>,
    TabComponentProps<"companyId"> {
  company: Company;
  personOptions: SelectFieldOption[];
  loadingCompany: boolean;
  hasValidContracts: boolean;
  loadCompany: (companyId: number) => void;
  loadPersons: (companyId: number) => void;
  loadValidContracts: (companyId: number) => void;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Company | CompanyForm> {
  loadCompany: (companyId: number) => void;
  loadPersons: (companyId: number) => void;
  loadValidContracts: (companyId: number) => void;
}

const EditCompanyContainer: React.FC<EditCompanyContainerProps> = (props) => {
  const {
    loadCompany,
    company,
    routeParams: { companyId },
    loadPersons,
    loadingCompany,
    loadValidContracts,
    handleSubmit,
    personOptions,
    hasValidContracts
  } = props;
  useEffect(() => loadCompany(companyId), [loadCompany, companyId]);
  useEffect(() => loadPersons(company.id), [loadPersons, company.id]);
  useEffect(
    () => loadValidContracts(company.id),
    [loadValidContracts, company.id]
  );

  return loadingCompany ? (
    <LoadingSpinnerPaper />
  ) : (
    <CompanyNewEditForm
      company={company}
      personOptions={personOptions}
      handleSubmit={handleSubmit}
      newLocation={false}
      hasValidContracts={hasValidContracts}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      id: values.id,
      name: values.name,
      company_type: values.company_type,
      organisation_number: values.organisation_number,
      invoice_ref: values.invoice_ref,
      website: values.website,
      logo: values.logo,
      notes: values.notes,
      status: values.status,
      membership_type: values.membership_type,
      accounting_reference: values.accounting_reference,
      payment_account: values.payment_account,
      external: values.external,
      districts: values.districts,
      location: values.location,
      contact_person: values.contact_person
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.COMPANY,
        `companies/companies/${(checkedNullValues as Company).id}/`,
        checkedNullValues,
        `/companies/companies/${
          (checkedNullValues as Company).id
        }?same_tab=true`
      )
    );
  },
  loadCompany: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANY,
        `companies/companies/${companyId}/`
      )
    );
  },
  loadPersons: (companyId: number) => {
    if (companyId && companyId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.PERSONS,
          `auth/persons/?filter__company=${companyId}`
        )
      );
    }
  },
  loadValidContracts: (companyId: number) => {
    if (companyId) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.CONTRACTS,
          `contracts/contracts/?filter__company=${companyId}&filter__valid=true`
        )
      );
    }
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  company: state.tabStates[ownProps.tabId].companies.companies.company,
  personOptions: state.tabStates[ownProps.tabId].persons.persons.persons.map(
    (person) => ({
      label: `${person.first_name} ${person.last_name || ""}`,
      value: person.id
    })
  ),
  loadingCompany:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCompany,
  hasValidContracts:
    state.tabStates[ownProps.tabId].contracts.contracts.contracts.length > 0
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCompanyContainer);
