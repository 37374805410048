import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {
  Formik,
  Field,
  FormikHelpers,
  FormikProps,
  FormikTouched,
  setNestedObjectValues
} from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  FormControlLabel,
  Checkbox,
  Collapse,
  Paper
} from "@material-ui/core";
import { Select } from "formik-material-ui";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Prospect,
  ProspectForm,
  ProspectPerson,
  SelectFieldOption,
  TaskOption,
  TabComponentProps
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import TimePicker from "../../TimePicker";
import { EnumStrings, ReduxActionStrings } from "../../../redux/strings";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { format } from "date-fns";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DeleteButtonContainer from "../../../containers/DeleteButtonContainer";
import LocationMap from "../../companies/locations/LocationMap";
import MapView from "../../../components/contracts/tasks/Map";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { mapTabRouteProps } from "../../../helpers/routesHelper";
import {
  finalizeProspectAPI,
  getBusinessareasAPI,
  getCustomersAPI,
  getProspectAPI,
  getTaskoptionsAPI,
  patchProspectAPI
} from "../../../services/api-declaration";
import { showGlobalSnackbar } from "../../../helpers/globalHelper";
import { timeToDate } from "../../FormatHelpers";
import {
  ConfirmationDialogServiceProvider,
  useConfirmationDialog
} from "../../confirmationDialog/ConfirmationDialogServiceProvider";
import { BlurTextField } from "../../routes/routeinstances/BlurTextField";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";

const complexityOptions = [
  EnumStrings.LOW,
  EnumStrings.MEDIUM,
  EnumStrings.HIGH
];

const vulnerabilityOptions = [
  EnumStrings.LOW,
  EnumStrings.MEDIUM,
  EnumStrings.HIGH
];

const reportIntervalOptions = [EnumStrings.MONTHLY, EnumStrings.WEEKLY];

const titleOptions = [
  EnumStrings.CONTACT,
  EnumStrings.CONFIRMED_BUYER,
  EnumStrings.ADMINISTRATIVE_CONTACT,
  EnumStrings.ADMINISTRATIVE_CONTACT_AND_CONFIRMED_BUYER
];

const customerobjectTypeOptions = [
  EnumStrings.PROPERTY_USER,
  EnumStrings.PROPERTY_OWNER,
  EnumStrings.TRUSTEE
];

const passcardTypeOptions = [
  EnumStrings.KEY,
  EnumStrings.CODE,
  EnumStrings.PASSCARD,
  EnumStrings.TAG
];

const statusOptions = [EnumStrings.DRAFT, EnumStrings.FINALIZED];

export const ValidationSchema = (t: any) =>
  Yup.object().shape({
    status: Yup.string()
      .oneOf(statusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    customer_object_type: Yup.string()
      .oneOf(customerobjectTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    passcard_type: Yup.string()
      .oneOf(passcardTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    report_interval: Yup.string()
      .oneOf(reportIntervalOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    complexity: Yup.string()
      .oneOf(complexityOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    vulnerability: Yup.string()
      .oneOf(vulnerabilityOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    task_options: Yup.array().of(Yup.number().min(1)).notRequired(),
    persons: Yup.array()
      .of(
        Yup.object().shape({
          first_name: Yup.string()
            .min(1, t("minLengthError"))
            .max(255, t("max255LengthError"))
            .required(t("requiredError")),
          last_name: Yup.string()
            .max(255, t("max255LengthError"))
            .notRequired(),
          title: Yup.string()
            .oneOf(titleOptions, t("notValidEnumValueError"))
            .required(t("requiredError")),
          phone1: Yup.string()
            .max(15, t("max15LengthError"))
            .matches(/^[0-9\s\-+()]*$/, t("invalidPhoneNumberError"))
            .nullable()
            .notRequired(),
          phone2: Yup.string()
            .max(15, t("max15LengthError"))
            .matches(/^[0-9\s\-+()]*$/, t("invalidPhoneNumberError"))
            .nullable()
            .notRequired(),
          email: Yup.string()
            .max(150, t("max150LengthError"))
            .email(t("emailInvalidError"))
            .nullable()
            .notRequired()
        })
      )
      .notRequired(),
    customer_name: Yup.string().when("customer", {
      is: null,
      then: Yup.string()
        .max(255, t("max255LengthError"))
        .required(t("requiredError")),
      otherwise: Yup.string().max(255, t("max255LengthError")).notRequired()
    }),
    customer_attention: Yup.string().nullable().notRequired(),
    customer_organisation_number: Yup.string()
      .min(10, t("min10LengthError"))
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    customer_notes: Yup.string().nullable().notRequired(),
    customer_object_name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    work_description: Yup.string().nullable().notRequired(),
    task_notes: Yup.string().nullable().notRequired(),
    geo_polygons: Yup.object().nullable().notRequired(),
    weekday_opening: Yup.string().nullable().notRequired(),
    weekday_closing: Yup.string().nullable().notRequired(),
    saturday_opening: Yup.string().nullable().notRequired(),
    saturday_closing: Yup.string().nullable().notRequired(),
    sunday_opening: Yup.string().nullable().notRequired(),
    sunday_closing: Yup.string().nullable().notRequired(),
    special: Yup.string().nullable().notRequired(),
    risk_description: Yup.string().nullable().notRequired(),
    businessarea: Yup.number()
      .positive(t("requiredError"))
      .required(t("requiredError")),
    customer: Yup.number()
      .positive(t("requiredError"))
      .nullable()
      .notRequired(),
    customer_location: Yup.object().nullable().notRequired(),
    customer_object_location: Yup.object().nullable().notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    mapContainer: {
      marginTop: 16
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0
    },
    noTopMargin: {
      marginTop: 0
    },
    noBottomMargin: {
      marginBottom: 0
    },
    margin20: {
      marginTop: 20
    },
    finalizeButton: {
      marginLeft: 20
    },
    rightFloat: {
      float: "right"
    }
  });

interface NewEditProspectProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"prospectId"> {}

const NewEditProspect: React.FC<NewEditProspectProps> = (props) => {
  const {
    classes,
    routeParams: { prospectId },
    tabId
  } = props;
  const t = useTranslate("ProspectsNewFormPage");
  const t2 = useTranslate("Prospects");
  const t3 = useTranslate("ValidationErrorMessages");
  const confirmationDialog = useConfirmationDialog();

  const [expanded, setExpanded] = useState<string | false>("basic_info");

  const [prospect, setProspect] = useState<Prospect>();
  const [customerOptions, setCustomeroptions] = useState<SelectFieldOption[]>(
    []
  );
  const [businessareaOptions, setBusinessareaOptions] = useState<
    SelectFieldOption[]
  >([]);

  const [taskoptions, setTaskoptions] = useState<TaskOption[]>([]);
  const [selectedBusinessareaId, setSelectedBusinessareaId] = useState<
    number | null
  >(prospect?.businessarea || null);
  const [isLoadingProspect, setIsLoadingProspect] = useState(false);

  const extendedCompanyOptions = [
    { label: `+ ${t("newCustomerLabel")}`, value: null },
    ...customerOptions
  ];

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  let initialValues: Prospect | ProspectForm;
  if (prospect && (prospect as Prospect).id) {
    initialValues = {
      ...prospect,
      customer:
        prospect.customer === null && prospect.customer_name === ""
          ? -1
          : prospect.customer
    };
  } else {
    initialValues = {
      id: -1,
      status: "DRAFT",
      geo_polygons: null,
      customer_object_type: "PROPERTY_USER",
      passcard_type: "PASSCARD",
      report_interval: "MONTHLY",
      complexity: "MEDIUM",
      vulnerability: "MEDIUM",
      task_options: [],
      persons: [],
      customer_name: "",
      customer_attention: "",
      customer_organisation_number: "",
      customer_notes: "",
      customer_object_name: `Prospekt ${format(
        new Date(),
        "yyyy-MM-dd HH:mm:ss"
      )}`,
      work_description: "",
      task_notes: "",
      weekday_opening: "07:00",
      weekday_closing: "16:00",
      saturday_opening: "07:00",
      saturday_closing: "16:00",
      sunday_opening: "07:00",
      sunday_closing: "16:00",
      special: "",
      risk_description: "",
      businessarea: -1,
      customer: -1,
      customer_location: null,
      customer_object_location: null
    };
  }
  useEffect(() => {
    if (prospect) {
      store.dispatch(setTitle(prospect.customer_object_name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospect]);

  useEffect(() => {
    let isAlive = true;

    void Promise.all([getBusinessareasAPI(), getCustomersAPI()]).then(
      ([{ results: businessareas }, { results: companies }]) => {
        if (isAlive) {
          setCustomeroptions(
            companies.map((company) => ({
              label: company.name,
              value: company.id
            }))
          );
          setBusinessareaOptions(
            businessareas.map((businessarea) => ({
              label: businessarea.name,
              value: businessarea.id
            }))
          );
        }
      }
    );

    return () => {
      isAlive = false;
    };
  }, []);

  useEffect(() => {
    if (selectedBusinessareaId) {
      let isAlive = true;

      void getTaskoptionsAPI(selectedBusinessareaId).then(
        ({ results }) => isAlive && setTaskoptions(results)
      );

      return () => {
        isAlive = false;
      };
    }
  }, [selectedBusinessareaId]);

  useEffect(() => {
    let isAlive = true;
    setIsLoadingProspect(true);

    void getProspectAPI(prospectId).then((_prospect) => {
      if (isAlive) {
        setProspect(_prospect);
        setIsLoadingProspect(false);
      }
    });

    return () => {
      isAlive = false;
    };
  }, [prospectId]);

  const handleSubmit = (
    values: Prospect | ProspectForm,
    actions: FormikHelpers<Prospect | ProspectForm>
  ) => {
    actions.setSubmitting(false);

    const checkedValues = {
      ...values,
      weekday_opening: values.weekday_opening
        ? format(timeToDate(values.weekday_opening), "HH:mm")
        : null,
      weekday_closing: values.weekday_closing
        ? format(timeToDate(values.weekday_closing), "HH:mm")
        : null,
      saturday_opening: values.saturday_opening
        ? format(timeToDate(values.saturday_opening), "HH:mm")
        : null,
      saturday_closing: values.saturday_closing
        ? format(timeToDate(values.saturday_closing), "HH:mm")
        : null,
      sunday_opening: values.sunday_opening
        ? format(timeToDate(values.sunday_opening), "HH:mm")
        : null,
      sunday_closing: values.sunday_closing
        ? format(timeToDate(values.sunday_closing), "HH:mm")
        : null
    };

    void patchProspectAPI(prospectId, checkedValues)
      .then((prospectResponse) => {
        setProspect(prospectResponse);
        showGlobalSnackbar(t("saveSuccessMessage"), "success");
      })
      .catch(() => showGlobalSnackbar(t("saveErrorMessage"), "error"));
  };

  const finalizeProspect = async (
    prospectId: number,
    formikProps: FormikProps<Prospect | ProspectForm>
  ) => {
    try {
      await confirmationDialog({
        description: t("confirmFinalizingDescription"),
        title: t("confirmFinalizingTitle")
      });

      const errors = await formikProps.validateForm();
      if (Object.keys(errors).length === 0) {
        const checkedValues = {
          ...formikProps.values,
          weekday_opening: formikProps.values.weekday_opening
            ? format(timeToDate(formikProps.values.weekday_opening), "HH:mm")
            : null,
          weekday_closing: formikProps.values.weekday_closing
            ? format(timeToDate(formikProps.values.weekday_closing), "HH:mm")
            : null,
          saturday_opening: formikProps.values.saturday_opening
            ? format(timeToDate(formikProps.values.saturday_opening), "HH:mm")
            : null,
          saturday_closing: formikProps.values.saturday_closing
            ? format(timeToDate(formikProps.values.saturday_closing), "HH:mm")
            : null,
          sunday_opening: formikProps.values.sunday_opening
            ? format(timeToDate(formikProps.values.sunday_opening), "HH:mm")
            : null,
          sunday_closing: formikProps.values.sunday_closing
            ? format(timeToDate(formikProps.values.sunday_closing), "HH:mm")
            : null
        };

        const prospectResponse = await patchProspectAPI(
          prospectId,
          checkedValues
        );
        setProspect(prospectResponse);

        void finalizeProspectAPI(prospectId, prospectResponse.oca)
          .then(() => {
            setProspect((_prospect) =>
              _prospect ? { ..._prospect, status: "FINALIZED" } : _prospect
            );
            showGlobalSnackbar(t("prospectFinalizedMessage"), "success");
          })
          .catch(() =>
            showGlobalSnackbar(t("prospectFinalizingFailedMessage"), "error")
          );
      } else {
        formikProps.setTouched(
          setNestedObjectValues<FormikTouched<Prospect | ProspectForm>>(
            errors,
            true
          )
        );
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return isLoadingProspect ? (
    <LoadingSpinnerPaper />
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ValidationSchema(t3)}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <form
          className={classes.form}
          onSubmit={formikProps.handleSubmit}
          autoComplete="off"
        >
          <Button
            variant="contained"
            color="primary"
            component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
              (props, ref) => (
                <Link
                  to="/contracts/prospects?same_tab=true"
                  {...props}
                  ref={ref as any}
                />
              )
            )}
          >
            <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
          </Button>

          <Button
            data-cy="prospect-form-finalize"
            variant="contained"
            color="primary"
            className={classes.finalizeButton}
            onClick={() => finalizeProspect(prospectId, formikProps)}
            disabled={prospect && prospect.status === EnumStrings.FINALIZED}
          >
            {t("finalizeButtonLabel")}
          </Button>

          {prospect && (
            <DeleteButtonContainer
              resourceId={prospect.id}
              resource={ReduxActionStrings.PROSPECT}
              url={"contracts/prospects"}
              {...mapTabRouteProps(props)}
            />
          )}
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h6">
              {t("prospectHeading")}
            </Typography>
          </Paper>

          <Accordion
            expanded={expanded === "basic_info"}
            onChange={handleChange("basic_info")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="accordion_1-content"
              id="accordion_1-header"
            >
              <Typography className={classes.heading}>
                {t("basicInfoHeading")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <BlurTextField
                      id="prospect-form-customer_object_name"
                      type="text"
                      name="customer_object_name"
                      label={t("customerObjectLabel")}
                      placeholder={t("customerObjectLabel")}
                      className={classes.noBottomMargin}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                    />
                    <Field
                      id="prospect-form-businessarea"
                      type="text"
                      name="businessarea"
                      label={t("businessareaLabel")}
                      placeholder={t("businessareaLabel")}
                      className={classes.margin20}
                      options={businessareaOptions}
                      component={AutoCompleteSelect}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      customHandleChange={(businessareaId: number) =>
                        setSelectedBusinessareaId(businessareaId)
                      }
                    />
                    <Field
                      id="prospect-form-customer"
                      type="text"
                      name="customer"
                      label={t("belongsToCustomerLabel")}
                      placeholder={t("selectCustomerLabel")}
                      className={classes.margin20}
                      options={extendedCompanyOptions}
                      component={AutoCompleteSelect}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                    />
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.margin20}
                    >
                      <InputLabel htmlFor="prospect-form-customer_object_type">
                        {t("customerobjectTypeLabel")}
                      </InputLabel>
                      <Field
                        data-cy="prospect-form-customer_object_type"
                        name="customer_object_type"
                        component={Select}
                        label={t("customerobjectTypeLabel")}
                        fullWidth
                      >
                        {customerobjectTypeOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {t2(option)}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.mapContainer}>
                      <LocationMap
                        location={formikProps.values.customer_object_location}
                        editable={true}
                        formikProps={formikProps}
                        fieldName="customer_object_location"
                        mapHeight={295}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Collapse in={formikProps.values.customer === null}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Typography>{t("newCustomerLabel")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <BlurTextField
                            data-cy="prospect-form-customer_name"
                            type="text"
                            name="customer_name"
                            label={t("customerNameLabel")}
                            placeholder={t("customerNameLabel")}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                          />
                          <BlurTextField
                            data-cy="prospect-form-customer_attention"
                            type="text"
                            name="customer_attention"
                            label={t("customerAttentionLabel")}
                            placeholder={t("customerAttentionLabel")}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                          />
                          <BlurTextField
                            data-cy="prospect-form-customer_organisation_number"
                            type="text"
                            name="customer_organisation_number"
                            label={t("customerOrganisationNumberLabel")}
                            placeholder={t("customerOrganisationNumberLabel")}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                          />
                          <BlurTextField
                            data-cy="prospect-form-customer_notes"
                            type="text"
                            name="customer_notes"
                            label={t("customerNotesLabel")}
                            placeholder={t("customerNotesLabel")}
                            multiline={true}
                            margin="normal"
                            rows="5"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={classes.mapContainer}>
                            <LocationMap
                              location={formikProps.values.customer_location}
                              editable={true}
                              formikProps={formikProps}
                              fieldName="customer_location"
                              mapHeight={295}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "contact_info"}
            onChange={handleChange("contact_info")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="accordion_2-content"
              id="accordion_2-header"
            >
              <Typography className={classes.heading}>
                {t("contactInfoHeading")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  {formikProps.values.persons.map((person: ProspectPerson) => {
                    return (
                      <React.Fragment key={person.row_id}>
                        <Grid item xs={12} sm={12}>
                          <Typography style={{ float: "left" }}>
                            {person.first_name
                              ? `${person.first_name} ${person.last_name || ""}`
                              : `${t("personHeading")} ${person.row_id + 1}`}
                          </Typography>
                          <Button
                            data-cy={`prospect-form-remove-person-${person.row_id}`}
                            type="button"
                            variant="contained"
                            color="secondary"
                            className={classes.rightFloat}
                            onClick={() => {
                              formikProps.setValues({
                                ...formikProps.values,
                                persons: formikProps.values.persons.filter(
                                  (_person) => person.row_id !== _person.row_id
                                )
                              });
                            }}
                          >
                            <DeleteIcon /> {t("removePersonButtonLabel")}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={person.is_contact_person}
                                onChange={(event: any) => {
                                  formikProps.setValues({
                                    ...formikProps.values,
                                    persons: formikProps.values.persons.map(
                                      (_person) => {
                                        if (
                                          _person.row_id === person.row_id &&
                                          !_person.is_contact_person
                                        ) {
                                          return {
                                            ..._person,
                                            is_contact_person: true
                                          };
                                        } else if (
                                          (_person.row_id === person.row_id &&
                                            _person.is_contact_person) ||
                                          _person.is_contact_person
                                        ) {
                                          return {
                                            ..._person,
                                            is_contact_person: false
                                          };
                                        } else {
                                          return _person;
                                        }
                                      }
                                    )
                                  });
                                }}
                                color="secondary"
                              />
                            }
                            label={t("contactPersonLabel")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <BlurTextField
                            data-cy={`prospect-form-person-${person.row_id}-first_name`}
                            type="text"
                            name={`persons.${person.row_id}.first_name`}
                            label={t("firstNameLabel")}
                            placeholder={t("firstNameLabel")}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                          />
                          <BlurTextField
                            data-cy={`prospect-form-person-${person.row_id}-last_name`}
                            type="text"
                            name={`persons.${person.row_id}.last_name`}
                            label={t("lastNameLabel")}
                            placeholder={t("lastNameLabel")}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                          />
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.margin20}
                          >
                            <InputLabel
                              htmlFor={`prospect-form-person-${person.row_id}-title`}
                            >
                              {t("titleLabel")}
                            </InputLabel>
                            <Field
                              data-cy={`prospect-form-person-${person.row_id}-title`}
                              name={`persons.${person.row_id}.title`}
                              component={Select}
                              label={t("titleLabel")}
                              fullWidth
                            >
                              {titleOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {t2(option)}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <BlurTextField
                            data-cy={`prospect-form-person-${person.row_id}-phone1`}
                            type="text"
                            name={`persons.${person.row_id}.phone1`}
                            label={t("phone1Label")}
                            placeholder={t("phone1Label")}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                          />
                          <BlurTextField
                            data-cy={`prospect-form-person-${person.row_id}-phone2`}
                            type="text"
                            name={`persons.${person.row_id}.phone2`}
                            label={t("phone2Label")}
                            placeholder={t("phone2Label")}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                          />
                          <BlurTextField
                            data-cy={`prospect-form-person-${person.row_id}-email`}
                            type="text"
                            name={`persons.${person.row_id}.email`}
                            label={t("emailLabel")}
                            placeholder={t("emailLabel")}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Divider />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item xs={12} sm={12}>
                    <Button
                      data-cy="prospect-form-add-person"
                      type="button"
                      variant="contained"
                      color="primary"
                      className={`${classes.submit} ${classes.rightFloat}`}
                      onClick={() => {
                        formikProps.setValues({
                          ...formikProps.values,
                          persons: [
                            ...formikProps.values.persons,
                            {
                              row_id:
                                formikProps.values.persons.length > 0
                                  ? Math.max(
                                      ...formikProps.values.persons.map(
                                        (person) => person.row_id
                                      )
                                    ) + 1
                                  : 0,
                              first_name: "",
                              last_name: "",
                              title: "CONTACT",
                              phone1: "",
                              phone2: "",
                              email: "",
                              is_contact_person: false
                            }
                          ]
                        });
                      }}
                    >
                      <AddIcon /> {t("addPersonButtonLabel")}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "graphical_spec"}
            onChange={handleChange("graphical_spec")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="accordion_3-content"
              id="accordion_3-header"
            >
              <Typography className={classes.heading}>
                {t("graphicalSpecHeading")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {prospect && (
                <MapView
                  geo_polygons={prospect.geo_polygons}
                  editable={true}
                  formikProps={formikProps}
                  defaultLatitude={
                    formikProps.values.customer_object_location?.latitude ||
                    undefined
                  }
                  defaultLongitude={
                    formikProps.values.customer_object_location?.longitude ||
                    undefined
                  }
                />
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "details"}
            onChange={handleChange("details")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="accordion_4-content"
              id="accordion_4-header"
            >
              <Typography className={classes.heading}>
                {t("detailsHeading")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.root}>
                <Grid container spacing={4} alignItems="flex-start">
                  <Grid item xs={12} sm={6}>
                    <BlurTextField
                      data-cy="prospect-form-work_description"
                      type="text"
                      name="work_description"
                      label={t("workDescriptionLabel")}
                      placeholder={t("workDescriptionLabel")}
                      className={classes.noTopMargin}
                      multiline={true}
                      margin="normal"
                      rows="5"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <BlurTextField
                      data-cy="prospect-form-notes"
                      type="text"
                      name="task_notes"
                      label={t("notesCommanderLabel")}
                      placeholder={t("notesCommanderLabel")}
                      className={classes.noTopMargin}
                      multiline={true}
                      margin="normal"
                      rows="5"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>

                  <Grid container item xs={12} sm={6}>
                    <Grid container spacing={1} item xs={12} sm={12}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="prospect-form-weekday_opening"
                          name="weekday_opening"
                          label={t("weekDayOpeningLabel")}
                          placeholder={initialValues.weekday_opening}
                          component={TimePicker}
                          format="HH:mm"
                          margin="normal"
                          fullWidth
                          inputVariant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="prospect-form-weekday_closing"
                          name="weekday_closing"
                          label={t("weekDayClosingLabel")}
                          placeholder={initialValues.weekday_closing}
                          component={TimePicker}
                          format="HH:mm"
                          margin="normal"
                          fullWidth
                          inputVariant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} item xs={12} sm={12}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="prospect-form-saturday_opening"
                          name="saturday_opening"
                          label={t("saturdayOpeningLabel")}
                          placeholder={initialValues.saturday_opening}
                          component={TimePicker}
                          format="HH:mm"
                          margin="normal"
                          fullWidth
                          inputVariant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="prospect-form-saturday_closing"
                          name="saturday_closing"
                          label={t("saturdayClosingLabel")}
                          placeholder={initialValues.saturday_closing}
                          component={TimePicker}
                          format="HH:mm"
                          margin="normal"
                          fullWidth
                          inputVariant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} item xs={12} sm={12}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="prospect-form-sunday_opening"
                          name="sunday_opening"
                          label={t("sundayOpeningLabel")}
                          placeholder={initialValues.sunday_opening}
                          component={TimePicker}
                          format="HH:mm"
                          margin="normal"
                          fullWidth
                          inputVariant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          id="prospect-form-sunday_closing"
                          name="sunday_closing"
                          label={t("sundayClosingLabel")}
                          placeholder={initialValues.sunday_closing}
                          component={TimePicker}
                          format="HH:mm"
                          margin="normal"
                          fullWidth
                          inputVariant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} item xs={12} sm={6}>
                    {taskoptions.length > 0 &&
                      taskoptions.map((taskoption) => (
                        <Grid item xs={12} sm={6} key={taskoption.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formikProps.values.task_options.includes(
                                  taskoption.id
                                )}
                                onChange={(event: any) => {
                                  if (event.target.checked) {
                                    formikProps.setValues({
                                      ...formikProps.values,
                                      task_options: [
                                        ...formikProps.values.task_options,
                                        taskoption.id
                                      ]
                                    });
                                  } else {
                                    formikProps.setValues({
                                      ...formikProps.values,
                                      task_options:
                                        formikProps.values.task_options.filter(
                                          (task_option) =>
                                            task_option !== taskoption.id
                                        )
                                    });
                                  }
                                }}
                                color="secondary"
                              />
                            }
                            label={taskoption.name}
                          />
                        </Grid>
                      ))}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>

                  <Grid container item xs={12} sm={6}>
                    <Grid container spacing={1} item xs={12} sm={12}>
                      <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="prospect-form-passcard_type">
                            {t("passcardTypeLabel")}
                          </InputLabel>
                          <Field
                            data-cy="prospect-form-passcard_type"
                            name="passcard_type"
                            component={Select}
                            label={t("passcardTypeLabel")}
                            fullWidth
                            variant="outlined"
                          >
                            {passcardTypeOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {t2(option)}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="prospect-form-complexity">
                            {t("complexityLabel")}
                          </InputLabel>
                          <Field
                            data-cy="prospect-form-complexity"
                            name="complexity"
                            component={Select}
                            label={t("complexityLabel")}
                          >
                            {complexityOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {t2(option)}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="prospect-form-vulnerability">
                            {t("vulnerabilityLabel")}
                          </InputLabel>
                          <Field
                            data-cy="prospect-form-vulnerability"
                            name="vulnerability"
                            component={Select}
                            label={t("vulnerabilityLabel")}
                            fullWidth
                            variant="outlined"
                          >
                            {vulnerabilityOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {t2(option)}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} item xs={12} sm={12}>
                      <Grid item xs={12} sm={8}>
                        <BlurTextField
                          data-cy="prospect-form-special"
                          type="text"
                          name="special"
                          label={t("specialLabel")}
                          placeholder={t("specialLabel")}
                          className={classes.margin20}
                          margin="normal"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.margin20}
                        >
                          <InputLabel htmlFor="prospect-form-report_interval">
                            {t("reportIntervalLabel")}
                          </InputLabel>
                          <Field
                            data-cy="prospect-form-report_interval"
                            name="report_interval"
                            component={Select}
                            label={t("reportIntervalLabel")}
                            fullWidth
                            variant="outlined"
                          >
                            {reportIntervalOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {t2(option)}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <BlurTextField
                      data-cy="prospect-form-risk_description"
                      className={classes.noTopMargin}
                      type="text"
                      name="risk_description"
                      label={t("riskDescriptionLabel")}
                      placeholder={t("riskDescriptionLabel")}
                      multiline={true}
                      margin="normal"
                      rows="5"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Button
                data-cy="prospect-form-save"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={prospect && prospect.status === EnumStrings.FINALIZED}
              >
                {t("saveButtonLabel")}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)((props: NewEditProspectProps) => (
  <ConfirmationDialogServiceProvider>
    <NewEditProspect {...props} />
  </ConfirmationDialogServiceProvider>
));
