import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import AuthorizationDialogContainer from "../containers/AuthorizationDialogContainer";
import { mainRootState } from "../redux/types";
import { useTranslate } from "../services/appLanguageService";
import { AppUrlStrings } from "../strings/AppUrlStrings";
import { ChannelProvider } from "./ChannelContext";

type PrivateRouteProps = {
  path: string;
  component: React.ComponentType<any>;
};

const mapStateToProps = (state: mainRootState) => ({
  authenticated: state.authentication.authenticated,
  authenticating: state.authentication.authenticating
});

const connector = connect(mapStateToProps);

const PrivateRoute: React.FC<
  PrivateRouteProps & ConnectedProps<typeof connector>
> = ({ path, authenticated, authenticating, component: Component }) => {
  const history = useHistory();
  const t = useTranslate("Utility");

  let loginUrl = "/login";
  if (!authenticated && !authenticating && history.location.pathname !== "/") {
    const urlSearchParams = new URLSearchParams(history.location.search);
    urlSearchParams.delete(AppUrlStrings.ADHOC_USER);
    urlSearchParams.append("redirect", history.location.pathname);
    loginUrl += `?${urlSearchParams}`;
  }

  return (
    <div>
      <Route
        path={path}
        render={(props) => {
          return authenticating ? (
            <div id="root">
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  backgroundColor: "#424242",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div className="lds-dual-ring"></div>
                <div
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    marginTop: "8px"
                  }}
                >
                  {t("authenticatingMessage")}
                </div>
              </div>
            </div>
          ) : authenticated ? (
            <ChannelProvider>
              <Component {...props} />
            </ChannelProvider>
          ) : (
            <Redirect to={loginUrl} />
          );
        }}
      />
      <AuthorizationDialogContainer />
    </div>
  );
};

export default connector(PrivateRoute);
