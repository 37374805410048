import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

interface TextFieldResourceProps {
  fieldValueData: any;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  InputProps?: any;
  error?: any;
  helperText?: string;
  onFocus?: (e: any) => void;
  autoFocus?: boolean;
  className?: string;
}

const TextFieldResourceComponent: React.FC<TextFieldResourceProps> = (
  props
) => {
  const { fieldValueData, onChange, ...others } = props;
  const [data, setData] = useState(fieldValueData);

  useEffect(() => {
    setData(fieldValueData);
  }, [fieldValueData]);

  return (
    <TextField
      value={data}
      onChange={(e) => {
        setData(e.target.value);
        onChange(e);
      }}
      {...others}
    />
  );
};

export default TextFieldResourceComponent;
