import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import { TextField, Select, CheckboxWithLabel } from "formik-material-ui";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  FormikSubmitDispatchProps,
  Task,
  SelectFieldOption,
  TaskForm,
  Customerobject
} from "../../../redux/types";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import { useTranslate } from "../../../services/appLanguageService";
import { Link } from "react-router-dom";
import { EnumStrings } from "../../../redux/strings";

const statusOptions = [
  EnumStrings.ACTIVE,
  EnumStrings.ARCHIVED,
  EnumStrings.LOCKED,
  EnumStrings.DRAFT
];

const complexityOptions = [
  EnumStrings.LOW,
  EnumStrings.MEDIUM,
  EnumStrings.HIGH
];

const vulnerabilityOptions = [
  EnumStrings.LOW,
  EnumStrings.MEDIUM,
  EnumStrings.HIGH
];

const reportIntervalOptions = [EnumStrings.MONTHLY, EnumStrings.WEEKLY];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    contractorContractOptions: Yup.array()
      .notRequired()
      .of(Yup.number().notRequired())
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    marginTop: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    }
  });

interface EditTaskProps
  extends FormikSubmitDispatchProps<Task | TaskForm>,
    WithStyles<typeof styles> {
  task?: Task;
  customerObject?: Customerobject;
  businessAreaOptions: SelectFieldOption[];
  loadBusinessAreas: (searchTerm?: string) => void;
}

const TaskFormComponent: React.FC<EditTaskProps> = (props) => {
  const {
    classes,
    task,
    businessAreaOptions,
    customerObject,
    loadBusinessAreas
  } = props;
  const t = useTranslate("TaskPage");
  const t2 = useTranslate("Tasks");
  const t3 = useTranslate("ValidationErrorMessages");

  let initialValues: Task | TaskForm;
  if (task && (task as Task).id) {
    initialValues = {
      ...task,
      work_description: task.work_description || "",
      notes: task.notes || "",
      risk_description: task.risk_description || "",
      geo_polygons_image: task.geo_polygons_image || ""
    };
  } else {
    initialValues = {
      name: "",
      customer_contracts: [],
      contractor_cooperations: [],
      geo_polygons: null,
      services: [],
      notes: "",
      work_description: "",
      risk_description: "",
      businessarea: -1,
      customerobject: -1,
      geo_polygons_image: "",
      complexity: "",
      vulnerability: "",
      report_interval: "",
      status: "",
      product_package: false,
      material_depot: false,
      default_transport: 0,
      spoilage_coefficient: 0,
      accounting_reference: null,
      task_options: []
    };
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={`/contracts/tasks${task ? `/${task.id}` : ""}?same_tab=true`}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {t("editTaskLabel")} {customerObject ? customerObject.name : ""}
        </Typography>
      </Paper>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema(t3)}
        onSubmit={props.handleSubmit}
      >
        {(props) => (
          <form
            className={classes.form}
            onSubmit={props.handleSubmit}
            autoComplete="off"
          >
            <Paper className={classes.paper}>
              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-businessArea"
              >
                {t("businessAreaLabel")}
              </InputLabel>
              <Field
                id="form-businessArea"
                type="text"
                name="businessarea"
                label={t("businessAreaLabel")}
                placeholder={t("businessAreaLabel")}
                value={
                  businessAreaOptions.find(
                    (businessArea) =>
                      props.values.businessarea === businessArea.value
                  )
                    ? businessAreaOptions.find(
                        (businessArea) =>
                          props.values.businessarea === businessArea.value
                      )
                    : null
                }
                options={businessAreaOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                loadOptions={loadBusinessAreas}
              />

              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-company-form-status"
              >
                {t("statusLabel")}
              </InputLabel>
              <Field
                id="new-company-form-status"
                name="status"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-company-form-complexity"
              >
                {t("complexityLabel")}
              </InputLabel>
              <Field
                id="new-company-form-complexity"
                name="complexity"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {complexityOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-company-form-vulnerability"
              >
                {t("vulnerabilityLabel")}
              </InputLabel>
              <Field
                id="new-company-form-vulnerability"
                name="vulnerability"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {vulnerabilityOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-company-form-report_interval"
              >
                {t("reportIntervalLabel")}
              </InputLabel>
              <Field
                id="new-company-form-report_interval"
                name="report_interval"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {reportIntervalOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              <Field
                id="task-form-product_package"
                name="product_package"
                type="checkbox"
                Label={{ label: t("productPackageFieldLabel") }}
                component={CheckboxWithLabel}
              />
              <Field
                id="task-form-material_depot"
                name="material_depot"
                type="checkbox"
                Label={{ label: t("materialDepotFieldLabel") }}
                component={CheckboxWithLabel}
              />

              <Field
                id="task-form-default_transport"
                type="text"
                name="default_transport"
                label={t("defaultTransportLabel")}
                placeholder={t("defaultTransportLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="task-form-spoilage_coefficient"
                type="text"
                name="spoilage_coefficient"
                label={t("spoilageCoefficientLabel")}
                placeholder={t("spoilageCoefficientLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="task-form-accounting_reference"
                type="text"
                name="accounting_reference"
                label={t("accountingReferenceLabel")}
                placeholder={t("accountingReferenceLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="task-form-risk_description"
                type="text"
                name="risk_description"
                label={t("riskDescriptionLabel")}
                placeholder={t("riskDescriptionLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              <Field
                id="task-form-work-description"
                type="text"
                name="work_description"
                label={t("workingInstructionsLabel")}
                placeholder={t("workingInstructionsLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              <Field
                id="task-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Paper>
            <Paper className={classes.paper}>
              <Button
                id="task-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {task ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </Paper>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default withStyles(styles)(TaskFormComponent);
