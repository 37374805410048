import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../../redux/types";
import ContractingProfileContainer from "./ContractingProfileContainer";
import ContractingProfileEditFormContainer from "./ContractingProfileEditFormContainer";
import ContractingProfileNewFormContainer from "./ContractingProfileNewFormContainer";
import ContractingProfilesContainer from "./ContractingProfilesContainer";
import BeenhereIcon from "@material-ui/icons/Beenhere";

const ContractingProfilesRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/contractingprofiles"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/contractingprofiles",
        label: t("contractingProfilesNavbarContractsLabel"),
        component: ContractingProfilesContainer,
        icon: BeenhereIcon,
        highlightClass: NavItemClassEnum.CONTRACTINGPROFILES,
        navId: NavItemClassEnum.CONTRACTINGPROFILES,
      },
      {
        path: "/contracts/contractingprofiles/new",
        parent: "/contracts/contractingprofiles",
        label: t("newContractingProfile"),
        component: ContractingProfileNewFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTINGPROFILES,
      },
      {
        path: "/contracts/contractingprofiles/:contractingprofileId",
        parent: "/contracts/contractingprofiles",
        label: t("contractingProfileLabel"),
        component: ContractingProfileContainer,
        highlightClass: NavItemClassEnum.CONTRACTINGPROFILES,
      },
      {
        path: "/contracts/contractingprofiles/:contractingprofileId/edit",
        parent: "/contracts/contractingprofiles/:contractingprofileId",
        label: t("editContractingProfile"),
        component: ContractingProfileEditFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTINGPROFILES,
      },
    ],
  },
];

export default ContractingProfilesRouter;
