import React from "react";
import {
  Button,
  withStyles,
  createStyles,
  WithStyles,
  IconButton
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const styles = (theme: any) =>
  createStyles({
    button: {
      float: "right",
      [theme.breakpoints.down("sm")]: {
        minWidth: 140,
        marginLeft: 20
      }
    },
    smallButton: {
      padding: 5
    }
  });

interface DeleteResourceButtonProps extends WithStyles<typeof styles> {
  label: string;
  handleClick: (
    resourceId: number,
    resource: string,
    url: string,
    customRedirect?: string,
    backendUrl?: string,
    noRedirect?: boolean
  ) => void;
  resourceId: number;
  id: string;
  resource: string;
  url: string;
  customRedirect?: string;
  backendUrl?: string;
  smallButton?: boolean;
  noRedirect?: boolean;
}

const DeleteResourceButton: React.FC<DeleteResourceButtonProps> = (props) => {
  const { classes, resource } = props;

  return !props.smallButton ? (
    <Button
      id={`${resource.toLowerCase()}-delete-${resource.toLowerCase()}`}
      data-cy={`${resource.toLowerCase()}-delete-${resource.toLowerCase()}`}
      variant="contained"
      color="secondary"
      className={classes.button}
      onClick={() =>
        props.handleClick(
          props.resourceId,
          props.resource,
          props.url,
          props.customRedirect,
          props.backendUrl,
          props.noRedirect
        )
      }
    >
      <DeleteIcon /> {props.label}
    </Button>
  ) : (
    <IconButton
      aria-label="delete"
      id={`${resource.toLowerCase()}-delete-${resource.toLowerCase()}`}
      data-cy={`${resource.toLowerCase()}-delete-${resource.toLowerCase()}`}
      className={classes.smallButton}
      onClick={() =>
        props.handleClick(
          props.resourceId,
          props.resource,
          props.url,
          props.customRedirect,
          props.backendUrl,
          props.noRedirect
        )
      }
    >
      <DeleteOutlineIcon />
    </IconButton>
  );
};

export default withStyles(styles)(DeleteResourceButton);
