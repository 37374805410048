import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Cooperation,
  Contract,
  Person,
  Task,
  User,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import CooperationView from "../../../components/contracts/cooperations/Cooperation";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface CooperationContainerProps extends TabComponentProps<"cooperationId"> {
  cooperation: Cooperation;
  loadingCooperation: boolean;
  resetCooperation: () => void;
  loadCooperation: (cooperationId: number) => void;
  task: Task | null;
  contract: Contract | null;
  person: Person | null;
  user: User | null;
  loadTask: (taskId: number) => void;
  loadContract: (contractId: number) => void;
  loadPerson: (personId: number | null) => void;
  loadUser: (userId: number | null) => void;
}

const CooperationContainer: React.FC<CooperationContainerProps> = (props) => {
  const {
    resetCooperation,
    loadingCooperation,
    loadCooperation,
    cooperation,
    loadTask,
    loadContract,
    loadPerson,
    loadUser,
    task,
    contract,
    person,
    user,
    routeParams: { cooperationId }
  } = props;
  useEffect(
    () => loadCooperation(cooperationId),
    [loadCooperation, cooperationId]
  );
  useEffect(() => loadTask(cooperation.task), [loadTask, cooperation.task]);
  useEffect(
    () => loadContract(cooperation.contract),
    [loadContract, cooperation.contract]
  );
  useEffect(
    () => loadPerson(cooperation.person),
    [loadPerson, cooperation.person]
  );
  useEffect(
    () => loadUser(cooperation.accepted_by_user),
    [loadUser, cooperation.accepted_by_user]
  );

  useEffect(() => () => resetCooperation(), [resetCooperation]);
  return (
    <div>
      <ViewActionButtons
        resourceId={cooperationId}
        resource={ReduxActionStrings.COOPERATION}
        url="contracts/cooperations"
      />
      {loadingCooperation ? (
        <LoadingSpinnerPaper />
      ) : (
        <CooperationView
          cooperation={cooperation}
          task={task}
          contract={contract}
          person={person}
          user={user}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadCooperation: (cooperationId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COOPERATION,
        `contracts/cooperations/${cooperationId}/`
      )
    );
  },
  loadTask: (taskId: number) => {
    if (taskId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COOPERATION_TASK,
          `contracts/tasks/${taskId}/`
        )
      );
    }
  },
  loadContract: (contractId: number) => {
    if (contractId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COOPERATION_CONTRACT,
          `contracts/contracts/${contractId}/`
        )
      );
    }
  },
  loadPerson: (personId: number | null) => {
    if (personId && personId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COOPERATION_PERSON,
          `auth/persons/${personId}/`
        )
      );
    }
  },
  loadUser: (userId: number | null) => {
    if (userId && userId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COOPERATION_USER,
          `auth/users/${userId}/`
        )
      );
    }
  },
  resetCooperation: () =>
    dispatch(LoadingBooleansActions.ResetCooperation(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  cooperation:
    state.tabStates[ownProps.tabId].cooperations.cooperation.cooperation,
  contract: state.tabStates[ownProps.tabId].cooperations.cooperation.contract,
  task: state.tabStates[ownProps.tabId].cooperations.cooperation.task,
  person: state.tabStates[ownProps.tabId].cooperations.cooperation.person,
  user: state.tabStates[ownProps.tabId].cooperations.cooperation.user,
  loadingCooperation:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCooperation
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CooperationContainer);
