import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/core";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FieldProps } from "formik";

const styles = (theme: any) =>
  createStyles({
    label: {
      fontSize: theme.typography.fontSize,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    bottomMargin: {
      marginBottom: theme.spacing(1)
    },
    editor: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "5px",
      height: 400,
      paddingRight: 14,
      paddingLeft: 14,
      paddingBottom: 14
    }
  });

interface MarkdownFieldProps extends WithStyles<typeof styles> {
  label: string;
}

const MarkdownField = ({
  field,
  form,
  classes,
  ...props
}: FieldProps & MarkdownFieldProps) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      convertFromRaw(markdownToDraft(form.values.contract_text))
    )
  );
  const [valueState, setValueState] = useState(form.values.contract_text);

  const onBlurHandler = (event: any) => {
    form.setFieldValue(field.name, valueState);
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    setValueState(
      draftToMarkdown(convertToRaw(newEditorState.getCurrentContent()))
    );
    setEditorState(newEditorState);
  };

  return (
    <div className={classes.bottomMargin} id="markdown_field">
      <div className={classes.label}>{props.label}</div>
      <Editor
        toolbar={{
          options: ["inline", "blockType", "list", "remove", "history"],
          inline: {
            options: ["bold", "italic", "monospace"]
          },
          blockType: {
            inDropdown: false,
            options: [
              "Normal",
              "H1",
              "H2",
              "H3",
              "H4",
              "H5",
              "H6",
              "Blockquote"
            ]
          }
        }}
        editorState={editorState}
        editorClassName={classes.editor}
        onEditorStateChange={onEditorStateChange}
        onBlur={onBlurHandler}
      />
    </div>
  );
};

export default withStyles(styles)(MarkdownField);
