import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { WithStyles, Paper, Typography, Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  WeatherAreaForm,
  WeatherArea,
  TabComponentProps
} from "../../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError"))
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    }
  });

interface NewWeatherAreaProps
  extends FormikSubmitDispatchProps<WeatherAreaForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  weatherArea?: WeatherArea;
}

const NewEditWeatherArea: React.FC<NewWeatherAreaProps> = (props) => {
  const { classes, weatherArea, tabId } = props;
  const t = useTranslate("WeatherAreaPage");
  let initialValues: WeatherArea | WeatherAreaForm;
  if (weatherArea && (weatherArea as WeatherArea).id) {
    initialValues = {
      ...weatherArea
    };
  } else {
    initialValues = {
      id: -1,
      name: "",
      notes: "",
      latitude: 57.7,
      longitude: 11.96,
      latest_fetch: null
    };
  }
  useEffect(() => {
    if (weatherArea) {
      store.dispatch(setTitle(weatherArea.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherArea]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/companies/weatherareas${
                weatherArea ? `/${weatherArea.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {weatherArea
            ? t("editWeatherAreaHeading")
            : t("newWeatherAreaHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={props.handleSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="weatherArea-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="new-weatherArea-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-weatherArea-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {weatherArea ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditWeatherArea);
