import React from "react";
import {
  updateContractorInvoiceStatusAPI,
  contractorBulkInvoiceStatusUpdate,
  contractorBulkSendEmailAPI
} from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import BaseInvoices from "../BaseInvoicesComponent";

interface ContractorInvoicesProps {}

const ContractorInvoices: React.FC<ContractorInvoicesProps> = () => {
  const t = useTranslate("InvoicesView");
  return (
    <BaseInvoices
      bulkInvoiceStatusUpdate={contractorBulkInvoiceStatusUpdate}
      bulkSendEmailUpdate={contractorBulkSendEmailAPI}
      disableInvoiceWorkApprovalEditMode="customer_billables"
      updateInvoiceStatus={updateContractorInvoiceStatusAPI}
      invoiceEntityLabel={t("contractorTableHeader")}
      companyType="CONTRACTOR"
    />
  );
};

export default ContractorInvoices;
