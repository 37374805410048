import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  CompanyForm,
  Company,
  mainRootState
} from "../../../redux/types";
import CompanyNewForm from "../../../components/companies/companies/CompanyNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewCompanyContainerProps
  extends FormikSubmitDispatchProps<CompanyForm> {
  resetCompany: () => void;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Company | CompanyForm> {
  resetCompany: () => void;
}

const NewCompanyContainer: React.FC<NewCompanyContainerProps> = (props) => {
  const { resetCompany } = props;
  useEffect(() => resetCompany(), [resetCompany]);

  return (
    <CompanyNewForm
      handleSubmit={props.handleSubmit}
      newLocation={true}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      id: values.id,
      name: values.name,
      company_type: values.company_type,
      organisation_number: values.organisation_number,
      invoice_ref: values.invoice_ref,
      website: values.website,
      logo: values.logo,
      notes: values.notes,
      status: values.status,
      membership_type: values.membership_type,
      accounting_reference: values.accounting_reference,
      payment_account: values.payment_account,
      external: values.external,
      districts: values.districts,
      location: values.location,
      contact_person: values.contact_person,
      invoice_location: values.invoice_location
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<CompanyForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.COMPANY,
        "companies/companies/",
        checkedNullValues,
        "/companies/companies?same_tab=true"
      )
    );
  },
  resetCompany: () => {
    dispatch(
      RestActions.resetResource(
        ownProps.tabId,
        ReduxActionStrings.COMPANY_LOCATION
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  districtOptions: state.tabStates[
    ownProps.tabId
  ].districts.districts.results.map((district) => ({
    label: district.name,
    value: district.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCompanyContainer);
