import React, { useState, ChangeEvent } from "react";
import {
  Button,
  TextField,
  IconButton,
  Input,
  Tooltip,
  Card,
  Fade
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import UndoIcon from "@material-ui/icons/Undo";
import DoneIcon from "@material-ui/icons/Done";
import { createStyles } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import { ImageRow } from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    addButtons: {
      marginTop: theme.spacing(1),
      alignSelf: "start"
    },
    mouseOver: {
      "&:hover": {
        color: "#e13e4e"
      }
    },
    imgIcon: {
      width: 150,
      height: 150,
      objectFit: "contain",
      backgroundColor: theme.palette.primary.main
    },
    imgRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: theme.spacing(1)
    },
    imgDeleted: {
      opacity: 0.6
    },
    container: {
      position: "relative"
    },
    flexBox: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: "flex",
      flexWrap: "wrap",
      alignItems: "start"
    },
    notesBox: {
      width: 150,
      fontSize: 10,
      margin: "5px 0 5px 0"
    },
    card: {
      margin: theme.spacing(1),
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(
        2
      )}px`,
      display: "flex",
      flexDirection: "column"
    },
    imgOptions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      flexDirection: "row-reverse"
    },
    imgChange: {
      position: "absolute",
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.65)",
      color: "#fff",
      textAlign: "center",
      width: "100%",
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
      "&:hover": {
        cursor: "pointer"
      }
    }
  });
});

type FileEvent = {
  files: FileList;
  value: string;
};

interface ImageAddonComponentProps {
  onImageUpdate: (images: ImageRow[]) => void;
  images: ImageRow[];
  singleImageMode?: boolean;
}

function ImageUploadComponent(props: ImageAddonComponentProps) {
  const { onImageUpdate, images, singleImageMode } = props;
  const classes = useStyles();

  const i = useTranslate("Images");

  const [curHover, setCurHover] = useState<null | number>(null);
  const [imgToChange, setImgToChange] = useState<number | null>(null);

  const fileSelector = React.useRef<HTMLInputElement>();

  const tobase64Str = (file: File | null | undefined) =>
    new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error: any) => reject(error);
    });

  const handleFileSelector = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileEvent = e.target as FileEvent;
    if (!fileEvent.files.length) {
      return;
    }
    const file = fileEvent.files[0];
    const imgstr: any = await tobase64Str(file);
    const ar = images.splice(0);
    if (imgToChange == null) {
      ar.push({ imageStr: imgstr });
    } else {
      ar[imgToChange].imageStr = imgstr;
    }
    onImageUpdate(ar);
    setImgToChange(null);
    fileEvent.value = "";
  };
  const handleNotesChange =
    (idx: number) =>
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let clone = images.splice(0);
      clone[idx].newNotes = ev.target.value;
      onImageUpdate(clone);
    };

  const handleDelete = (idx: number) => () => {
    let val: ImageRow[];
    if (typeof images === "string") {
      val = [];
    } else {
      val = images.splice(0);
      if (val[idx].id) val[idx].toDelete = !val[idx].toDelete;
      else val.splice(idx, 1);
    }
    onImageUpdate(val);
  };

  const handleImageChange = (imgId: number) => () => {
    setImgToChange(imgId);
    fileSelector.current && fileSelector.current.click();
  };

  return (
    <>
      <div className={classes.flexBox}>
        {images.map((img, imgId) => {
          return (
            <Card
              key={imgId}
              className={`${img.toDelete && classes.imgDeleted} ${
                classes.card
              }`}
            >
              <div
                className={`${classes.imgIcon} ${classes.container}`}
                onMouseOver={() => {
                  setCurHover(imgId);
                }}
                onMouseLeave={() => {
                  setCurHover(null);
                }}
              >
                <img
                  src={img.imageStr}
                  alt={i("imgErr")}
                  className={classes.imgIcon}
                />
                <Fade in={imgId === curHover} timeout={250}>
                  <div
                    className={classes.imgChange}
                    onClick={handleImageChange(imgId)}
                  >
                    {i("changeImage")}
                  </div>
                </Fade>
              </div>
              {!singleImageMode && (
                <TextField
                  className={classes.notesBox}
                  placeholder={i("notes")}
                  value={img.newNotes}
                  onChange={handleNotesChange(imgId)}
                  disabled={img.toDelete}
                  multiline
                />
              )}
              <div className={classes.imgOptions}>
                <IconButton
                  onClick={handleDelete(imgId)}
                  className={classes.mouseOver}
                >
                  <Tooltip title={img.toDelete ? i("undoDelete") : i("delete")}>
                    {img.toDelete ? <UndoIcon /> : <DeleteIcon />}
                  </Tooltip>
                </IconButton>
                {img.id && !img.toDelete && (
                  <Tooltip title={i("submitted")}>
                    <DoneIcon />
                  </Tooltip>
                )}
              </div>
            </Card>
          );
        })}
      </div>
      {!(singleImageMode && images.length === 1) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setImgToChange(null);
            fileSelector.current && fileSelector.current.click();
          }}
          className={classes.addButtons}
        >
          {i("addImageBtn")}
        </Button>
      )}
      <Input
        type="file"
        onChange={handleFileSelector}
        inputRef={fileSelector}
        style={{ display: "none" }}
      />
    </>
  );
}

export default ImageUploadComponent;
