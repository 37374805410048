import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  TemplateImageForm,
  TemplateImage,
  mainRootState,
  TabComponentProps
} from "../../redux/types";
import TemplateImageNewEditForm from "../../components/templateimages/TemplateImageNewEditForm";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import LoadingSpinnerPaper from "../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface EditTemplateImageContainerProps
  extends FormikSubmitDispatchProps<TemplateImageForm>,
    TabComponentProps<"templateImageId"> {
  templateImage: TemplateImage;
  loadTemplateImage: (templateImageId: number) => void;
  loadingtemplateImage: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<TemplateImage | TemplateImageForm> {
  loadTemplateImage: (templateImageId: number) => void;
}

const EdittemplateImageContainer: React.FC<EditTemplateImageContainerProps> = (
  props
) => {
  const {
    loadTemplateImage,
    templateImage,
    routeParams: { templateImageId },
    loadingtemplateImage,
    handleSubmit
  } = props;
  useEffect(
    () => loadTemplateImage(templateImageId),
    [loadTemplateImage, templateImageId]
  );

  return loadingtemplateImage ? (
    <LoadingSpinnerPaper />
  ) : (
    <TemplateImageNewEditForm
      templateImage={templateImage}
      handleSubmit={handleSubmit}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.TEMPLATEIMAGE,
        `services/templateimages/${(checkedNullValues as TemplateImage).id}/`,
        checkedNullValues,
        `/services/templateimages/${
          (checkedNullValues as TemplateImage).id
        }?same_tab=true`
      )
    );
  },
  loadTemplateImage: (templateImageId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TEMPLATEIMAGE,
        `services/templateimages/${templateImageId}/`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  templateImage:
    state.tabStates[ownProps.tabId].templateImages.templateImage.templateImage,
  businessAreaOptions: state.tabStates[
    ownProps.tabId
  ].businessAreas.businessAreas.results.map((businessArea) => ({
    label: businessArea.name,
    value: businessArea.id
  })),
  loadingtemplateImage:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingTemplateImage
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EdittemplateImageContainer);
