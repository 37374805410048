import React from "react";
import { DatePicker } from "@material-ui/pickers";
import { useTranslate } from "../services/appLanguageService";
import { FieldProps, FormikProps, FormikValues } from "formik";

interface ExtendedFieldProps extends FieldProps {
  name: string;
}

export interface FDatePickerProps {
  label?: string;
  field: ExtendedFieldProps;
  form: FormikProps<FormikValues>;
  fullWidth?: boolean;
  margin?: "none" | "dense" | "normal";
  autoOk?: boolean;
}

const FDatePicker: React.FC<FDatePickerProps> = (props) => {
  const {
    label,
    field: { name },
    form: { touched, errors, values, setFieldValue },
    ...other
  } = props;

  const t = useTranslate("DatePicker");
  const errorText = errors[name];
  const hasError = touched[name] && errorText !== undefined;
  return (
    <DatePicker
      cancelLabel={t("cancelLabel")}
      label={label}
      error={hasError}
      helperText={hasError ? errorText : ""}
      onChange={(value) => setFieldValue(name, value)}
      value={values[name]}
      {...other}
    />
  );
};

export default FDatePicker;
