import { TabTitleState, Action } from "../../../types";
import { SET_TITLE } from "./actionTypes";

const initialState: TabTitleState = {
  instanceTitle: "",
  instanceId: -1
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case SET_TITLE:
      return {
        instanceTitle: action.payload.instanceTitle || "",
        instanceId: action.payload.instanceId || ""
      };
    default:
      return state;
  }
}

export default reducer;
