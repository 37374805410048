import React from "react";
import {
  IconButton,
  Tab,
  Tabs,
  withStyles,
  createStyles,
  WithStyles,
  Tooltip
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import { LayoutRoute } from "../../redux/types";
import { convertToUrlSearch } from "../../helpers/routesHelper";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

const styles = (theme: any) =>
  createStyles({
    tabsRoot: {
      display: "flex",
      height: theme.spacing(4.5),
      minHeight: theme.spacing(4.5),
      width: "95%"
    },
    flexContainerTab: {
      height: "100%",
      alignItems: "center"
    },
    reload: {
      display: "flex",
      height: "inherit",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      alignItems: "center",
      cursor: "pointer",
      justifyContent: "center"
    },
    reloadIcon: {
      color: "#424242"
    },
    tabContent: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    tabLabel: {
      flex: 1,
      fontSize: "10px",
      fontWeight: "bold"
    },
    indicator: {
      backgroundColor: "inherit",
      resize: "horizontal",
      minWidth: "95px",
      maxWidth: "210px"
    },
    selection: {
      borderBottom: "2px solid #424242",
      backgroundColor: "#e3e3e3",
      fontWeight: "bold",
    },
    tabRoot: {
      padding: "0 0 0 8px",
      minWidth: "95px",
      maxWidth: "210px",
      minHeight: theme.spacing(4.5)
    },
    ellipsisText: {
      fontWeight: "bold",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    titledTabStyle: {
      top: "18px",
      color: "#686868",
      position: "absolute",
      left: theme.spacing(1),
      fontSize: theme.spacing(1)
    },
    untitledTabStyle: {
      top: "-6px",
      flex: "auto 0",
      position: "relative"
    },
    titleWidth: { width: "90%" },
    marginLeft: { marginLeft: theme.spacing(0.5) },
    align: { textAlign: "initial" },
    iconSize: { fontSize: "0.9rem" },
    titleSpacing: { marginBottom: theme.spacing(0.5) },
    tooltipContainer: { padding: `${theme.spacing(0.5)}px 0` }
  });

interface TabHeaderProps extends WithStyles<typeof styles> {
  selectedTabIndex: number;
  tabRoutes: LayoutRoute[];
  handleRemoveTab: (tabIdToRemove: number) => void;
  handleReloadTab: (tabIdToReload: number) => void;
  handleReloadAllTab: () => void;
}

const TabHeader: React.FC<TabHeaderProps> = (props: TabHeaderProps) => {
  const {
    classes,
    selectedTabIndex,
    tabRoutes,
    handleRemoveTab,
    handleReloadTab,
    handleReloadAllTab
  } = props;
  // const [ripple, setRipple] = useState(false);
  const history = useHistory();

  const tooltipContent = (_tabRoute: LayoutRoute) => {
    return (
      <div className={classes.tooltipContainer}>
        <div>
          <span>{_tabRoute.label}</span>
        </div>
        <div className={classes.titleSpacing}>
          <span>{_tabRoute.instanceTitle}</span>
        </div>
      </div>
    );
  };

  const reloadAll = () => {
    handleReloadAllTab();
  };

  const handleChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    event.preventDefault();
    const tabRoute = tabRoutes[newIndex];
    const search = String(convertToUrlSearch(tabRoute));
    if (
      tabRoute.actualPath !== history.location.pathname ||
      search === history.location.search
    ) {
      history.push({
        pathname: tabRoute.actualPath,
        search
      });
    }
  };

  const handleReload = ({ tabId }: LayoutRoute) => {
    handleReloadTab(tabId);
  };

  const deleteTab = (e: any, { tabId }: LayoutRoute) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveTab(tabId);
  };

  return (
    <>
      <Tooltip title={"Reload All"} placement="bottom" arrow>
        <IconButton className={classes.reload} onClick={reloadAll}>
          <RefreshIcon fontSize="medium" className={classes.reloadIcon} />
        </IconButton>
      </Tooltip>
      <Tabs
        value={selectedTabIndex}
        onChange={handleChange}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabsRoot}
        classes={{
          flexContainer: classes.flexContainerTab,
          indicator: classes.indicator
        }}
      >
        {tabRoutes.map((tabRoute, idx, tabRoutes) => (
          <Tooltip
            key={tabRoute.tabId}
            title={tooltipContent(tabRoute)}
            placement="bottom"
            arrow
          >
            <Tab
              value={idx}
              classes={{ root: classes.tabRoot, selected: classes.selection }}
              onDoubleClick={() => handleReload(tabRoute)}
              label={
                <div className={classes.tabContent}>
                  <span
                    className={
                      tabRoute.instanceTitle
                        ? clsx(
                            classes.tabLabel,
                            classes.ellipsisText,
                            classes.untitledTabStyle,
                            classes.marginLeft,
                            classes.align
                          )
                        : clsx(classes.tabLabel, classes.ellipsisText)
                    }
                  >
                    {tabRoute.label}
                  </span>
                  {tabRoute.instanceTitle && (
                    <span
                      className={clsx(
                        classes.tabLabel,
                        classes.ellipsisText,
                        classes.titledTabStyle,
                        classes.titleWidth,
                        classes.align
                      )}
                    >
                      {tabRoute.instanceTitle}
                    </span>
                  )}
                  {tabRoutes.length !== 1 || tabRoute.path !== "/" ? (
                    <IconButton
                      size="small"
                      className={
                        tabRoute.instanceTitle
                          ? clsx(classes.iconSize, classes.untitledTabStyle)
                          : ""
                      }
                      component="span"
                      // onMouseOver={() => setRipple(true)}
                      // onMouseLeave={() => setRipple(false)}
                      onClick={(e: any) => deleteTab(e, tabRoute)}
                    >
                      <CloseIcon
                        className={
                          tabRoute.instanceTitle ? classes.iconSize : ""
                        }
                        fontSize="small"
                      />
                    </IconButton>
                  ) : null}
                </div>
              }
              id={`simple-tab-${tabRoute.tabId}`}
              aria-controls={`simple-tabpanel-${tabRoute.tabId}`}
              // disableRipple={ripple}
            />
          </Tooltip>
        ))}
      </Tabs>
    </>
  );
};

export default withStyles(styles)(TabHeader);
