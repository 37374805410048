import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  ProjectForm,
  Project,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import ProjectNewEditForm from "../../../components/contracts/projects/ProjectNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditProjectContainerProps
  extends FormikSubmitDispatchProps<ProjectForm>,
    TabComponentProps<"projectId"> {
  project: Project;
  businessAreaOptions: SelectFieldOption[];
  loadProject: (projectId: number) => void;
  loadBusinessAreas: (searchTerm?: string) => void;
  loadingProject: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Project | ProjectForm> {
  loadProject: (projectId: number) => void;
  loadBusinessAreas: (searchTerm?: string) => void;
}

const EditProjectContainer: React.FC<EditProjectContainerProps> = (props) => {
  const {
    loadProject,
    project,
    routeParams: { projectId },
    loadBusinessAreas,
    loadingProject
  } = props;
  useEffect(() => loadProject(projectId), [loadProject, projectId]);
  useEffect(() => loadBusinessAreas(), [loadBusinessAreas]);

  return loadingProject ? (
    <LoadingSpinnerPaper />
  ) : (
    <ProjectNewEditForm
      project={project}
      businessAreaOptions={props.businessAreaOptions}
      handleSubmit={props.handleSubmit}
      loadBusinessAreas={props.loadBusinessAreas}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.PROJECT,
        `contracts/projects/${(checkedNullValues as Project).id}/`,
        checkedNullValues,
        `/contracts/projects/${(checkedNullValues as Project).id}?same_tab=true`
      )
    );
  },
  loadProject: (projectId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PROJECT,
        `contracts/projects/${projectId}/`
      )
    );
  },
  loadBusinessAreas: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.BUSINESSAREAS,
        `contracts/businessareas/?page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  project: state.tabStates[ownProps.tabId].projects.project.project,
  businessAreaOptions: state.tabStates[
    ownProps.tabId
  ].businessAreas.businessAreas.results.map((businessArea) => ({
    label: businessArea.name,
    value: businessArea.id
  })),
  loadingProject: state.tabStates[ownProps.tabId].loadingBooleans.loadingProject
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProjectContainer);
