import React, {
  useState,
  useRef,
  useEffect,
  KeyboardEventHandler
} from "react";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircleSharp";
import LanguageIcon from "@material-ui/icons/Language";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import PersonIcon from "@material-ui/icons/Person";
import { connect } from "react-redux";
import * as router from "react-router-dom";
import { history } from "../redux/store";
import clsx from "clsx";
import {
  alpha,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
  Menu,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Breadcrumb,
  SearchResponseDictionary,
  mainRootState
} from "../redux/types";
import { AppStaticRoutesMap, AppRoutesStatic } from "./AppRouter";
import LogoutContainer from "../containers/LogoutContainer";
import { useTranslate } from "../services/appLanguageService";
import NavbarLink from "./NavbarLink";
import { searchAPI } from "../services/api-declaration";
import { showGlobalSnackbar } from "../helpers/globalHelper";
import {
  getBreadcrumbsByRoute,
  getSearchInfo,
  makePathStaticWithParams,
  transformSearchData
} from "../helpers/routesHelper";
import {
  getBackendVersion,
  getFrontendVersion
} from "../services/appVersionService";
import { getFromSessionStore } from "../services/storageService";

const drawerWidth = 260;

const styles = (theme: any) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100%)`,
        zIndex: theme.zIndex.drawer + 1
      }
    },
    menuButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    colorWhite: {
      color: theme.palette.common.white,
      [theme.breakpoints.down(768)]: {
        display: "none"
      }
    },
    fontNormal: { fontWeight: "normal" },
    fontBold: {
      fontWeight: "bold",
      textDecoration: "none"
    },
    title: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.common.white,
      [theme.breakpoints.down(1100)]: {
        display: "none"
      }
    },
    userprofileContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "4px 12px",
      margin: "-4px -12px",
      transition: "background 0.2s ease",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.2)",
        borderRadius: "4px"
      }
    },
    userprofileMenu: {
      transformOrigin: "center bottom"
    },
    userIcon: {
      padding: 0,
      paddingBottom: 5
    },
    verticalSeparator: {
      width: 1,
      minHeight: 50,
      marginRight: 20,
      marginLeft: 20,
      background: "gray"
    },
    smallVerticalSeparator: {
      width: 1,
      minHeight: 15,
      marginRight: 7,
      marginLeft: 7,
      background: "gray"
    },
    headerRightContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center"
    },
    headerRightContainerLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.breakpoints.down(600)]: {
        display: "none"
      }
    },
    logo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.breakpoints.down(600)]: {
        display: "none"
      }
    },
    headerContentContainer: {
      padding: "0",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end"
    },
    headerContainer: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    separator: {
      height: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      margin: `0 ${theme.spacing(1.5)}px`,
      padding: `0 ${theme.spacing(0.125)}px`
    },
    domainContainer: {
      display: "flex",
      alignItems: "center",
      fontSize: theme.typography.fontSize
    },
    domainIcon: {
      fontSize: theme.spacing(2.5),
      marginRight: theme.spacing(0.5)
    },
    alertContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      transition: ".5s ease height",
      background: "#f8d7da",
      color: "#721c24"
    },
    connectionAlertText: {
      marginLeft: 10
    },
    connectionAlertContent: {
      transition: ".4s linear all",
      marginLeft: 10
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
      },
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        left: drawerWidth,
        top: "4px",
        paddingLeft: 0,
        width: "auto",
        position: "absolute"
      }
    },
    searchInput: {
      color: "#fff",
      padding: `0 ${theme.spacing(0.5)}px ${theme.spacing(2)}px
       ${theme.spacing(0.5)}px !important`
    },
    rootElement: {
      width: 200,
      transition: theme.transitions.create("width")
    },
    fieldIndicatorColor: { color: "#c1c1c1" },
    focusedRoot: { width: "300px !important" },
    optionsPaper: {
      width: "300px",
      marginTop: "-2px !important"
    },
    iconSpan: {
      display: "flex",
      minWidth: "30px",
      justifyContent: "center",
      marginRight: theme.spacing(1.5)
    },
    check: {
      display: "flex"
    },
    hideNoOptionsPaper: { display: "none" },
    nestedConnection: {
      marginTop: -5,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      width: 36,
      "& > *": {
        borderColor: theme.palette.primary.main,
        borderLeft: "2px solid",
        borderBottom: "2px solid",
        width: "calc(50% + 3px)",
        height: "calc(50% + 3px)"
      }
    }
  });

interface HeaderProps extends WithStyles<typeof styles> {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileOpen: boolean;
  route: string;
  username: string;
  isOnline: boolean;
}

const AppHeader = (props: HeaderProps) => {
  const { classes, setMobileOpen, mobileOpen, route, username, isOnline } =
    props;

  const h = useTranslate("AppHeader");
  const e = useTranslate("LayoutRoute");
  const routeRef = useRef<string>();
  const searchTimerRef = useRef<number>();
  const searchArrowMoved = useRef(false);
  const [backendVersion, setBackendVersion] = useState<
    string | null | undefined
  >();
  const [searchText, setSearchText] = useState("");

  const [breadcrumbsList, setBreadcrumbsList] = useState<Breadcrumb[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;
  const [searchResults, setSearchResults] = useState<
    SearchResponseDictionary[]
  >([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  useEffect(() => {
    const callBackendVersion = async () => {
      const response = await getBackendVersion();
      const beVersion = response?.version;

      if (beVersion) {
        setBackendVersion(beVersion);
      }
    };

    callBackendVersion();
  }, []);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const handleSearchChange = (value: string) => {
    if (value) {
      let alive = true;
      setSearchText(value);
      setIsSearchLoading(true);
      if (searchTimerRef.current) {
        window.clearTimeout(searchTimerRef.current);
      }
      searchTimerRef.current = window.setTimeout(() => {
        void searchAPI({ query: `${value}*` }).then(({ items }) => {
          if (alive) {
            setSearchResults(transformSearchData(items, AppStaticRoutesMap));
            setIsSearchLoading(false);
          }
        });
      }, 400);
      return () => {
        alive = false;
      };
    } else {
      setSearchText("");
      setSearchResults([]);
    }
  };

  const handleSelectedSearch = (listItem: any) => {
    searchArrowMoved.current = true;
    setSearchResults([]);
    const searchInfo = getSearchInfo(AppStaticRoutesMap, listItem);
    if (searchInfo) {
      history.push(searchInfo.path);
      return;
    }
    showGlobalSnackbar(h("noPathAvailableLabel"), "default");
  };

  const handleEnterHit: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (
      event.key === "Enter" &&
      !searchArrowMoved.current &&
      event.target instanceof HTMLInputElement
    ) {
      const search = new URLSearchParams({
        searchText,
        reload_tab: "true"
      }).toString();
      history.push({
        pathname: "/search-suggestions",
        search
      });
      event.target.blur();
    }
    searchArrowMoved.current = false;
  };

  useEffect(() => {
    if (routeRef.current && routeRef.current === route) return;
    routeRef.current = route;
    const { staticPath, ids } = makePathStaticWithParams(route);
    const routes = getBreadcrumbsByRoute(AppRoutesStatic, staticPath, ids);
    setBreadcrumbsList(routes);
  }, [e, route]);

  useEffect(() => {
    document.title =
      getFromSessionStore("domain_name") || h("workPrecisionHeading");
  }, [h]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.headerContainer}>
          <div className={classes.headerContentContainer}>
            <router.Link to="/">
              <img
                className={classes.logo}
                src="WP_logo_white.png"
                alt={h("workPrecisionHeading")}
                width="170"
                height="45"
              />
            </router.Link>
            <div className={classes.search}>
              <Autocomplete
                loading={isSearchLoading}
                loadingText={`${h("loadingLabel")}...`}
                id="search-autocomplete-box"
                classes={{
                  root: classes.rootElement,
                  input: classes.searchInput,
                  paper: classes.optionsPaper,
                  focused: classes.focusedRoot,
                  popupIndicator: classes.fieldIndicatorColor,
                  clearIndicator: classes.fieldIndicatorColor
                }}
                onChange={(_: React.ChangeEvent<{}>, value: any) =>
                  handleSelectedSearch(value)
                }
                options={searchResults}
                getOptionLabel={(o) => o.name || ""}
                renderOption={(option: SearchResponseDictionary) => (
                  <div className={classes.check}>
                    {option.related_to_path && (
                      <div className={classes.nestedConnection}>
                        <div></div>
                      </div>
                    )}
                    <span className={classes.iconSpan}>
                      {option.icon && <option.icon />}
                    </span>
                    <span>{option.name}</span>
                  </div>
                )}
                blurOnSelect
                clearOnEscape
                disableClearable
                onKeyDown={handleEnterHit}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    onChange={(event) => handleSearchChange(event.target.value)}
                    placeholder={`${h("searchLabel")}...`}
                  />
                )}
              />
            </div>
          </div>
          <div className={classes.headerRightContainer}>
            <div className={classes.headerRightContainerLeft}>
              <Typography variant="h6" noWrap className={classes.title}>
                <Tooltip title={h("domainTooltip")} placement="right" arrow>
                  <div className={classes.domainContainer}>
                    <LanguageIcon className={classes.domainIcon} />
                    {getFromSessionStore("domain_name")}
                  </div>
                </Tooltip>
                <div className={classes.smallVerticalSeparator} />
                {backendVersion && (
                  <Tooltip
                    title={h("backendVersionTooltip")}
                    placement="right"
                    arrow
                  >
                    <div className={classes.domainContainer}>
                      {`${h("backendVersionLabel")}: ${backendVersion}`}
                    </div>
                  </Tooltip>
                )}
                <div className={classes.smallVerticalSeparator} />
                <Tooltip
                  title={h("frontendVersionTooltip")}
                  placement="right"
                  arrow
                >
                  <div className={classes.domainContainer}>
                    {`${h("frontendVersionLabel")}: ${getFrontendVersion()}`}
                  </div>
                </Tooltip>
              </Typography>
              <Breadcrumbs
                className={classes.colorWhite}
                aria-label="Breadcrumb"
              >
                <Link
                  style={{}}
                  color="inherit"
                  href={route === "/" ? "#" : "/"}
                >
                  {h("dashboardHeading")}
                </Link>
                {breadcrumbsList.map((breadcrumb, idx: number) =>
                  idx + 1 !== breadcrumbsList.length ? (
                    <Link
                      key={idx}
                      color="inherit"
                      href={`#${breadcrumb.path}?same_tab=true`}
                      className={classes.fontNormal}
                    >
                      {breadcrumb.label}
                    </Link>
                  ) : (
                    <Link
                      key={idx}
                      color="inherit"
                      className={classes.fontBold}
                    >
                      {breadcrumb.label}
                    </Link>
                  )
                )}
              </Breadcrumbs>
            </div>
            <div className={classes.verticalSeparator} />

            <div
              className={classes.userprofileContainer}
              onClick={handleMenu}
              style={{ cursor: "pointer" }}
            >
              <IconButton
                id="profile-menu-button"
                className={classes.userIcon}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircleIcon htmlColor="gainsboro" />
              </IconButton>
              <Typography variant="caption">{username}</Typography>
            </div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  marginTop: "49px",
                  marginLeft: "14px"
                }
              }}
            >
              <div>
                <NavbarLink
                  id="menu-user-profile"
                  route={`/auth/my-profile`}
                  label={h("profileSettingsLabel")}
                  icon={PersonIcon}
                  closeMenu={handleClose}
                />
              </div>
              <LogoutContainer
                closeMenu={handleClose}
                buttonLabel={h("logoutLabel")}
              />
            </Menu>
          </div>
        </div>
      </Toolbar>
      <div
        className={classes.alertContainer}
        style={{
          height: isOnline ? 0 : 30
        }}
      >
        <ErrorIcon
          style={{
            height: isOnline ? 0 : "auto"
          }}
          className={classes.connectionAlertContent}
        />
        <Typography
          className={clsx(
            classes.connectionAlertContent,
            classes.connectionAlertText
          )}
          component="p"
          style={{
            opacity: isOnline ? 0 : 1
          }}
        >
          {h("offLine")}
        </Typography>
      </div>
    </AppBar>
  );
};

const mapStateToProps = ({
  router: {
    location: { pathname: route }
  },
  connectivity: { isOnline },
  authentication: { username }
}: mainRootState) => ({
  username: username
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" "),
  route,
  isOnline
});

export default withStyles(styles)(
  connect(mapStateToProps, () => ({}))(AppHeader)
);
