import { useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { IRowImage } from "../../../redux/types";
import clsx from "clsx";
import ImageIcon from "@material-ui/icons/Image";
import BlurOffIcon from "@material-ui/icons/BlurOff";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTranslate } from "../../../services/appLanguageService";
import Tooltip from "@material-ui/core/Tooltip";

const imageViewStyles = (theme: Theme) =>
  createStyles({
    loader: {
      background: "#aeaeae",
      display: "flex",
      flexDirection: "column",
      "& .placeholder-icon": {
        width: 28,
        height: 28
      }
    },
    loaderIconContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    img: {
      maxWidth: "100%",
      height: "100%",
      maxInlineSize: "100%",
      blockSize: "auto"
    },
    imageContainer: {
      position: "relative",
      display: "inline-block",
      "&:hover $tooltipContainer": {
        display: "block"
      }
    },
    tooltipContainer: {
      display: "none",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "4px 8px",
      borderRadius: "4px"
    },
    tooltipText: {
      display: "block"
    }
  });

export interface ImageViewProps extends WithStyles<typeof imageViewStyles> {
  className?: string;
  loaderClassName?: string;
  src: string;
  isLoading: boolean;
  onInvoke?: () => void;
}

const ImageView = withStyles(imageViewStyles)((props: ImageViewProps) => {
  const { classes, className, loaderClassName, src, isLoading, onInvoke } =
    props;
  const t3 = useTranslate("TimesheetApprovalPage");

  return isLoading ? (
    <div
      className={clsx(classes.loader, className, loaderClassName)}
      onClick={onInvoke}
    >
      <div className={classes.loaderIconContainer}>
        <ImageIcon className={"placeholder-icon"} />
      </div>
      <LinearProgress />
    </div>
  ) : (
    <a href={src} target="_blank" rel="noopener noreferrer">
      <div className={classes.imageContainer}>
        <img
          className={clsx(className, classes.img)}
          src={src}
          alt=""
          onClick={onInvoke}
        />
        <Tooltip title={t3("openImageNewTab")}>
          <div className={classes.tooltipContainer}>
            <span className={classes.tooltipText}>{t3("openImageNewTab")}</span>
          </div>
        </Tooltip>
      </div>
    </a>
  );
});

const imagesViewStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },

    mainImageContainers: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-start",
      alignItems: "center"
    },
    mainImage: {
      height: "calc(100VH - 384px)",
      position: "relative"
    },
    actionButton: {
      marginLeft: -theme.spacing(0.5),
      marginRight: -theme.spacing(0.5)
    },
    activeImage: {
      border: "1px solid #aeaeae"
    },
    noImageContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100VH - 384px)"
    },
    mainImgSkeletonWidth: {
      width: 296,
      "& .placeholder-icon": {
        width: 48,
        height: 48
      }
    },
    classificationBox: {
      position: "absolute",
      top: 8,
      right: 8,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "4px 8px",
      borderRadius: "4px"
    },
    imageCount: {
      position: "absolute",
      bottom: 8,
      right: 8,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "4px 8px",
      borderRadius: "4px"
    }
  });

export interface ImagesViewProps extends WithStyles<typeof imagesViewStyles> {
  defaultIndex: number;
  allImages: IRowImage[];
  populateImageDetails: (img: IRowImage) => Promise<IRowImage>;
  onImageChange: (img: IRowImage) => void;
}

const ImagesView = withStyles(imagesViewStyles)((props: ImagesViewProps) => {
  const {
    classes,
    defaultIndex,
    allImages,
    populateImageDetails,
    onImageChange
  } = props;
  const [isImgsLoading, setImgsLoading] = useState<Record<number, boolean>>({});
  const [currentIndex, setCurrentIndex] = useState(-1);
  const imgDetails = allImages[currentIndex];
  const t = useTranslate("TimesheetApprovalPage");
  const t2 = useTranslate("ImageClassificationLabel");

  const changeImage = (imageIndex: number) => () => {
    setCurrentIndex(imageIndex);
    onImageChange(allImages[imageIndex]);
  };

  useEffect(() => {
    setCurrentIndex(defaultIndex);
    onImageChange(allImages[defaultIndex]);
  }, [defaultIndex, allImages, onImageChange]);

  useEffect(() => {
    allImages.forEach(async (img, imageIndex) => {
      setImgsLoading((flags) => ({ ...flags, [imageIndex]: true }));
      if (img && !img.imageStr) {
        try {
          img = await populateImageDetails(img);
        } catch (e) {}
      }
      setImgsLoading((flags) => ({ ...flags, [imageIndex]: false }));
    });
  }, [allImages, populateImageDetails]);

  const getImageCountText = (currentIndex: number, totalImages: number) => {
    const imageCount = t("imageCount");
    return imageCount
      .replace("{current}", (currentIndex + 1).toString())
      .replace("{total}", totalImages.toString());
  };

  const largerIconSize = "2em";

  const LargeChevronLeftIcon = withStyles({
    root: {
      fontSize: largerIconSize
    }
  })(ChevronLeftIcon);

  const LargeChevronRightIcon = withStyles({
    root: {
      fontSize: largerIconSize
    }
  })(ChevronRightIcon);

  return (
    <div className={classes.root}>
      <div className={classes.mainImageContainers}>
        <IconButton
          color="primary"
          className={classes.actionButton}
          onClick={changeImage(currentIndex - 1)}
          disabled={!allImages[currentIndex - 1]}
        >
          <LargeChevronLeftIcon />
        </IconButton>
        {imgDetails ? (
          <div className={classes.mainImage}>
            <ImageView
              className={clsx(classes.mainImage, classes.activeImage)}
              loaderClassName={classes.mainImgSkeletonWidth}
              isLoading={isImgsLoading[currentIndex]}
              src={imgDetails?.imageStr}
            />
            {imgDetails.classification && (
              <div className={classes.classificationBox}>
                {" "}
                {t2(imgDetails.classification)}
              </div>
            )}
            <Typography variant="body2" className={classes.imageCount}>
              {getImageCountText(currentIndex, allImages.length)}
            </Typography>
          </div>
        ) : (
          <div
            className={clsx(
              classes.activeImage,
              classes.mainImgSkeletonWidth,
              classes.noImageContainer
            )}
          >
            <BlurOffIcon className="placeholder-icon" />
            <Typography variant="h6">{t("noImage")}</Typography>
          </div>
        )}
        <IconButton
          color="primary"
          className={classes.actionButton}
          onClick={changeImage(currentIndex + 1)}
          disabled={!allImages[currentIndex + 1]}
        >
          <LargeChevronRightIcon />
        </IconButton>
      </div>
    </div>
  );
});
export default ImagesView;
