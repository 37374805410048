import store, { history } from "../redux/store";
import { resolveTokenRenewalRequests } from "./fetchWrapper";
import { DomainResponse } from "../redux/types";
import { showGlobalSnackbar, decomposeUrlHash } from "../helpers/globalHelper";
import {
  domainAPI,
  fetchAllowedPaths,
  getMyUserInfoAPI,
  loginAdhocAPI,
  loginAPI,
  logoutAPI
} from "./api-declaration";
import { AUTHENTICATING } from "../redux/reducers/authentication/actionTypes";
import { authSuccess } from "../redux/reducers/authentication/actions";
import { languagesObject, resetLocale, setLocale } from "./appLanguageService";
import {
  clearUserFromStorage,
  getFromSessionStore,
  restoreSessionUser,
  setDomainInfo,
  setMasterUserAccess,
  setSessionStore,
  setUserInStorage
} from "./storageService";
import { AppUrlStrings } from "../strings/AppUrlStrings";
import { getAdhocSearchString } from "../helpers/tabsHelper";
const packageJson = require("./../../package.json");
const ALWAYS_ALLOWED_API_USAGE = ["auth/users/set_password"];

function getDomainInfo(domain: string) {
  return new Promise<DomainResponse>(async (resolve, reject) => {
    getDomainAPI(process.env.REACT_APP_DOMAIN_LOOKUP_URL, domain)
      .then((response) => {
        resolve(response);
      })
      .catch(async () => {
        console.warn(
          "First domain URL lookup failed, retrying with secondary lookup URL."
        );
        try {
          const response = await getDomainAPI(
            process.env.REACT_APP_SECONDARY_DOMAIN_LOOKUP_URL,
            domain
          );
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
  });
}

async function getDomainAPI(domainURL: string | undefined, domain: string) {
  try {
    const response = await domainAPI(domainURL as string, domain);
    setDomainInfo(response);
    setLocale(response.language);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function authenticate(
  username: string,
  password: string,
  domainId: string
) {
  try {
    const response = await loginAPI({
      username,
      password,
      client_type: "WEB",
      client_version: packageJson.version
    });

    const selectedLanguage = languagesObject[response.user.language];
    setLocale(selectedLanguage);
    setMasterUserAccess(response.user.id, domainId);
    setUserInStorage(
      0,
      username,
      response.user.person,
      response.refresh,
      response.access,
      response.domain.name,
      response.user.is_superuser,
      selectedLanguage
    );
    window.allowedPaths = await setAllowedPaths();

    showGlobalSnackbar("Login successful", "success"); // TODO: Use language translation
    resolveTokenRenewalRequests(); // run errored requests for session expiry login
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function setAdhocUser(user_id: number) {
  const response = await loginAdhocAPI({
    user_id,
    client_type: "WEB",
    client_version: packageJson.version
  });

  const selectedLanguage = languagesObject[response.user.language];
  setUserInStorage(
    user_id,
    response.user.username,
    response.user.person,
    response.refresh,
    response.access,
    response.domain.name,
    response.user.is_superuser,
    selectedLanguage
  );
  window.sessionUserId = user_id;
  await setAllowedPaths();
  window.sessionUserId = 0;
  window.open(`#/?${getAdhocSearchString(user_id)}`);
  return response;
}

async function loadAppUserAccess() {
  const { hashPath, urlSearchParams } = decomposeUrlHash();
  const urlAccessToken = urlSearchParams.get(AppUrlStrings.TEMP_ACCESS_TOKEN);
  const urlDomainId = urlSearchParams.get(AppUrlStrings.TEMP_ACCESS_DOMAIN);

  if (urlAccessToken && urlDomainId) {
    clearStorage();
    store.dispatch({
      type: AUTHENTICATING,
      payload: {
        authenticating: true
      }
    });
    try {
      window.sessionUserId = 0;
      window.localStorage.setItem("[0]access_token", urlAccessToken);
      await getDomainInfo(urlDomainId);
      const {
        user: { username, id: userId, is_superuser, person },
        domain
      } = await getMyUserInfoAPI();

      setMasterUserAccess(userId, urlDomainId);
      setUserInStorage(
        0,
        username,
        person,
        "",
        urlAccessToken,
        domain.name,
        is_superuser,
        undefined
      );
      window.allowedPaths = await setAllowedPaths();

      store.dispatch(authSuccess(username, urlDomainId));
      history.replace({ pathname: hashPath });
    } catch (e) {
      console.warn("Error: ", e);
    }
  } else {
    restoreSessionUser(urlSearchParams);
    const username = getFromSessionStore("username");
    const localDomainId = localStorage.getItem("domain_id");

    if (getFromSessionStore("access_token") && username && localDomainId) {
      store.dispatch(authSuccess(username, localDomainId));
    }
  }
}

function clearStorage() {
  resetLocale();
  clearUserFromStorage();
}

function logout() {
  return new Promise<void>(async (resolve) => {
    try {
      const refresh = getFromSessionStore("refresh_token");
      if (refresh) {
        await logoutAPI({
          refresh
        });
      }
    } catch (error) {
      console.log(error);
    }
    clearStorage();
    history.replace("/");
    resolve();
  });
}

async function setAllowedPaths() {
  let allowedPaths = (await fetchAllowedPaths()) || [];
  allowedPaths = allowedPaths.concat(ALWAYS_ALLOWED_API_USAGE);
  setSessionStore("allowed_paths", JSON.stringify(allowedPaths));
  return allowedPaths;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDomainInfo,
  authenticate,
  setAdhocUser,
  loadAppUserAccess,
  logout,
  clearStorage
};
