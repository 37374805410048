import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  WithStyles
} from "@material-ui/core";
import { Done as DoneIcon, Clear as ClearIcon } from "@material-ui/icons";
import { fetchPatch } from "../services/fetchWrapper";
import { restUrl } from "../services/api-declaration";
import { useTranslate } from "../services/appLanguageService";
import * as Yup from "yup";
import { sanitizePrice } from "../helpers/globalHelper";

const styles = (theme: any) =>
  createStyles({
    readOnly: {
      paddingBottom: 2,
      borderBottom: "dashed 1px #0088cc",
      cursor: "pointer"
    },
    addLabel: {
      color: "grey",
      fontStyle: "italic",
      cursor: "pointer"
    },
    icon: {
      fontSize: "20px"
    }
  });

interface EditableTextFieldProps extends WithStyles<typeof styles> {
  field: string;
  initValue: string | number | null;
  url: string;
  updateResourceState: (newValue: any) => void;
  number?: boolean;
  validation?: any;
  isPrice?: boolean;
}

const EditableTextField: React.FC<EditableTextFieldProps> = (props) => {
  const { classes, isPrice } = props;

  const [value, setValue] = useState<string | number | null>(props.initValue);
  const [edit, setEdit] = useState<boolean>(false);
  const [formValidation, setFormValidation] = useState<{
    error: boolean;
    errorText: string;
  }>({ error: false, errorText: "" });
  const [loading, setLoading] = useState(false);
  const t = useTranslate("EditableFieldComponent");

  const updateResource = async () => {
    setLoading(true);
    const response = await fetchPatch(restUrl(props.url), {
      [props.field]: value
    });

    setLoading(false);
    props.updateResourceState(response);
  };

  const validate = (_value: string) => {
    if (props.validation) {
      const schema = Yup.object().shape({
        [props.field]: props.validation
      });
      schema
        .validate({ [props.field]: _value })
        .then((valid: any) => {
          setFormValidation({
            error: false,
            errorText: ""
          });
        })
        .catch((e: any) => {
          setFormValidation({
            error: true,
            errorText: e.errors.join()
          });
        });
    }
  };

  const empty = value === null || value === "";

  const displayedValue =
    props.number && !empty ? (+value).toLocaleString() : value;

  return loading ? (
    <CircularProgress size={18} />
  ) : !edit ? (
    <span
      data-cy={`${props.field}-field`}
      className={!empty ? classes.readOnly : classes.addLabel}
      onClick={() => setEdit(true)}
    >
      {!empty ? displayedValue : <span>{t("addLabel")}...</span>}
    </span>
  ) : (
    <TextField
      data-cy={`${props.field}-field`}
      autoFocus
      size="small"
      value={value}
      onChange={(event) => {
        const sanitizedValue = isPrice
          ? sanitizePrice(event.target.value)
          : event.target.value;
        validate(sanitizedValue);
        setValue(sanitizedValue);
      }}
      inputProps={{
        style: {
          height: 15,
          fontSize: 14
        }
      }}
      error={formValidation && formValidation.error}
      helperText={formValidation && formValidation.errorText}
      onKeyPress={(event) => {
        if (event.code === "Enter" && !formValidation.error) {
          updateResource();
          setEdit(false);
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-cy={`${props.field}-save-button`}
              size="small"
              aria-label="Save"
              onClick={() => {
                if (!formValidation.error) {
                  updateResource();
                  setEdit(false);
                }
              }}
            >
              <DoneIcon className={classes.icon} style={{ color: "#006400" }} />
            </IconButton>
            <IconButton
              size="small"
              aria-label="Reset"
              onClick={() => {
                setEdit(false);
                setValue(props.initValue);
                setFormValidation({
                  error: false,
                  errorText: ""
                });
              }}
            >
              <ClearIcon
                className={classes.icon}
                style={{ color: "Crimson" }}
              />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default withStyles(styles)(EditableTextField);
