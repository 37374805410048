import { ProspectsState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: ProspectsState = {
  prospects: {
    prospects: [],
    next: null,
    previous: null,
    count: 0
  },
  prospect: {
    prospect: {
      id: -1,
      status: "DRAFT",
      geo_polygons: null,
      customer_object_type: "PROPERTY_USER",
      passcard_type: "PASSCARD",
      report_interval: "MONTHLY",
      complexity: "MEDIUM",
      vulnerability: "MEDIUM",
      task_options: [],
      persons: [],
      created_at: "",
      updated_at: "",
      customer_name: "",
      customer_attention: "",
      customer_organisation_number: "",
      customer_notes: "",
      customer_object_name: "",
      work_description: "",
      task_notes: "",
      weekday_opening: "07:00",
      weekday_closing: "16:00",
      saturday_opening: "07:00",
      saturday_closing: "16:00",
      sunday_opening: "07:00",
      sunday_closing: "16:00",
      special: "",
      risk_description: "",
      oca: -1,
      businessarea: -1,
      customer: null,
      customer_location: null,
      customer_object_location: null
    }
  }
};

function reducer(state = initialState, action: Action<any>): ProspectsState {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PROSPECTS):
      return {
        ...state,
        prospects: {
          prospects: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PROSPECT):
      return {
        ...state,
        prospect: {
          ...state.prospect,
          prospect: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.PROSPECT):
      return {
        ...state,
        prospects: {
          ...state.prospects,
          prospects: [...state.prospects.prospects, action.payload]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.PROSPECT
    ):
      return {
        ...state,
        prospects: {
          ...state.prospects,
          prospects: [
            ...state.prospects.prospects.map((prospect) =>
              prospect.id === action.payload.id ? action.payload : prospect
            )
          ]
        },
        prospect: {
          ...state.prospect,
          prospect: action.payload
        }
      };

    default:
      return state;
  }
}

export default reducer;
