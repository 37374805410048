import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import * as types from "../../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import ParentDistrictLinkContainer from "../../../containers/companies/districts/ParentDistrictLinkContainer";
import { useTranslate } from "../../../services/appLanguageService";
import MapView from "../../contracts/tasks/Map";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../../redux/types";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface DistrictProps extends WithStyles<typeof styles>, TabComponentProps {
  district: types.District;
  parentDistrict?: types.District;
}

const District: React.FC<DistrictProps> = (props) => {
  const { classes, district, tabId } = props;
  const t = useTranslate("DistrictPage");

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    store.dispatch(setTitle(district.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district, tabId]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">{district.name}</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" className={classes.bold}>
                    {t("codeLabel")}
                  </TableCell>
                  <TableCell align="right">{district.code}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("parentDistrictLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {district.parent_district ? (
                      <ParentDistrictLinkContainer
                        parentdistrictId={district.parent_district}
                        {...mapTabRouteProps(props)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell align="left" className={classes.bold}>
                    {t("notesLabel")}
                  </TableCell>
                  <TableCell align="right">{district.notes}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(district.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(district.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MapView
            geo_polygons={district.geo_polygons}
            editable={false}
            resourceId={district.id}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(District);
