import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  ContractingProfile,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import ContractingProfileView from "../../../components/contracts/contractingProfiles/ContractingProfile";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface ContractingProfileContainerProps
  extends TabComponentProps<"contractingprofileId"> {
  contractingprofile: ContractingProfile;
  loadingContractingProfile: boolean;
  resetContractingProfile: () => void;
  loadContractingProfile: (contractingprofileId: number) => void;
}

const ContractingProfileContainer: React.FC<
  ContractingProfileContainerProps
> = (props) => {
  const {
    resetContractingProfile,
    loadingContractingProfile,
    loadContractingProfile,
    contractingprofile,
    routeParams: { contractingprofileId }
  } = props;
  useEffect(
    () => loadContractingProfile(contractingprofileId),
    [loadContractingProfile, contractingprofileId]
  );

  useEffect(() => () => resetContractingProfile(), [resetContractingProfile]);
  return (
    <div>
      <ViewActionButtons
        resourceId={contractingprofileId}
        resource={ReduxActionStrings.CONTRACTINGPROFILE}
        url="contracts/contractingprofiles"
      />
      {loadingContractingProfile ? (
        <LoadingSpinnerPaper />
      ) : (
        <ContractingProfileView
          contractingprofile={contractingprofile}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadContractingProfile: (contractingprofileId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CONTRACTINGPROFILE,
        `contracts/contractingprofiles/${contractingprofileId}/`
      )
    );
  },
  resetContractingProfile: () =>
    dispatch(LoadingBooleansActions.ResetContractingProfile(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  contractingprofile:
    state.tabStates[ownProps.tabId].contractingProfiles.contractingProfile
      .contractingProfile,
  loadingContractingProfile:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingContractingProfile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractingProfileContainer);
