import React from "react";
import {
  WithStyles,
  TableBody,
  TableCell,
  Table,
  Typography
} from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { ErrorPayloadType } from "../../redux/types";

const styles = (theme: any) =>
  createStyles({
    payloadContainer: {
      marginTop: 20,
      whiteSpace: "pre-wrap",
      borderRadius: 5
    },
    errorBox: {
      backgroundColor: "#EEE",
      fontFamily: "Monospace",
      fontSize: 12,
      padding: 10
    },
    errorDetails: {
      backgroundColor: "#ffc",
      padding: 20
    },
    tableCell: {
      borderBottom: 0
    },
    leftCell: {
      width: "150px",
      textAlign: "right"
    }
  });

const highlightText = (text: string, highlight: string) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part === highlight
              ? { fontWeight: "bold", fontSize: 14, fontFamily: "Sans-serif" }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
};

interface ErrorPayloadProps extends WithStyles<typeof styles> {
  payload: ErrorPayloadType;
}

const ErrorPayload: React.FC<ErrorPayloadProps> = (props) => {
  const t = useTranslate("EventlogPage");
  const { classes, payload } = props;

  return (
    <div className={classes.payloadContainer}>
      <div className={classes.errorDetails}>
        <Typography variant="h6">
          {payload.e_str.replaceAll('"', "")}
        </Typography>
        <Table size="small">
          <TableBody>
            <TableCell className={`${classes.tableCell} ${classes.leftCell}`}>
              <b>{t("requestMethodLabel")}</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {payload.method}
            </TableCell>
          </TableBody>
          <TableBody>
            <TableCell className={`${classes.tableCell} ${classes.leftCell}`}>
              <b>{t("pathLabel")}</b>
            </TableCell>
            <TableCell className={classes.tableCell}>{payload.path}</TableCell>
          </TableBody>
          <TableBody>
            <TableCell className={`${classes.tableCell} ${classes.leftCell}`}>
              <b>{t("tenantLabel")}</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {payload.tenant_info}
            </TableCell>
          </TableBody>
        </Table>
      </div>
      <div className={classes.errorBox}>
        {payload.trace.split("\n").map((row, index) => (
          <div key={index}>{highlightText(row, "Traceback")}</div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(ErrorPayload);
