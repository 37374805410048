import React from "react";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { useTranslate } from "../services/appLanguageService";
import { FieldProps, FormikProps, FormikValues } from "formik";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

interface ExtendedFieldProps extends FieldProps {
  name: string;
}

export interface FTimePickerProps {
  label?: string;
  field: ExtendedFieldProps;
  form: FormikProps<FormikValues>;
  fullWidth?: boolean;
  margin?: "none" | "dense" | "normal";
  autoOk?: boolean;
  inputVariant?: "filled" | "outlined" | "standard";
}

const FTimePicker: React.FC<FTimePickerProps> = (props) => {
  const {
    label,
    field,
    form: { touched, errors, values, setFieldValue },
    ...other
  } = props;

  const t = useTranslate("TimePicker");
  const errorText = errors[field.name];
  const hasError = touched[field.name] && errorText !== undefined;
  return (
    <KeyboardTimePicker
      cancelLabel={t("cancelLabel")}
      label={label}
      ampm={false}
      error={hasError}
      helperText={hasError ? errorText : ""}
      onChange={(value) => setFieldValue(field.name, value)}
      value={
        values[field.name] && typeof values[field.name] === "string"
          ? timeToDate(values[field.name])
          : values[field.name]
      }
      keyboardIcon={<QueryBuilderIcon />}
      {...other}
    />
  );
};

const timeToDate = (time: string) => {
  if (
    typeof time === "string" &&
    time !== "" &&
    time.search(/\d\d:\d\d/) !== -1
  ) {
    const hour = parseInt(time.slice(0, 2));
    const minute = parseInt(time.slice(3, 5));
    let date = new Date();
    return date.setHours(hour, minute);
  }
  return new Date();
};

export default FTimePicker;
