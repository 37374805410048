import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../redux/types";
import EmailView from "./EmailView";
import MailIcon from "@material-ui/icons/MailOutline";
import Emails from "./Emails";

const EmailsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["services/emails"],
    requireSuperuser: true,
    routes: [
      {
        path: "/services/emails",
        label: t("emailsNavbarLabel"),
        component: Emails,
        icon: MailIcon,
        highlightClass: NavItemClassEnum.EMAILS,
        navId: NavItemClassEnum.EMAILS
      },
      {
        path: "/services/emails/:id",
        parent: "/services/emails",
        label: t("emailLabel"),
        component: EmailView,
        highlightClass: NavItemClassEnum.EMAILS
      }
    ]
  }
];

export default EmailsRouter;
