import { VERIFICATION_UPDATED_AT } from "./actionTypes";
import { Action, VerificationUpdatedAtType } from "../../types";

const initialState: VerificationUpdatedAtType = Date.now();

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case VERIFICATION_UPDATED_AT:
      return Date.now();

    default:
      return state;
  }
}

export default reducer;
