import React, { useState, useEffect } from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Badge
} from "@material-ui/core";
import NavbarLink from "./NavbarLink";
import LogoutContainer from "../containers/LogoutContainer";
import { useTranslate } from "../services/appLanguageService";
import {
  AppStaticRoutesMap,
  AppSideNavRoutesMap,
  AppRouterDec,
  AppRoutesStatic
} from "./AppRouter";
import {
  makeRoutePathStatic,
  populateAndFilterNav
} from "../helpers/routesHelper";
import {
  LayoutRoute,
  VerificationUpdatedAtType,
  NavRoute
} from "../redux/types";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getNavbarDrawerItems } from "../strings/NavbarDrawerStrings";
import { getPartlyDeclinedCountAPI, getVerificationCountAPI } from "../services/api-declaration";

const drawerWidth = 240;

const styles = (theme: any) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    doubleNested: {
      paddingLeft: theme.spacing(6)
    },
    logoutItem: {
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    boldText: { fontWeight: "bold" },
    activeItem: { background: "#e3e3e3" },
    parentBadge: { margin: `0 ${theme.spacing(2)}px` }
  });

interface NavbarDrawerProps extends WithStyles<typeof styles> {
  selectedTab?: LayoutRoute;
  verificationUpdatedAt: VerificationUpdatedAtType;
}

const NavbarDrawer: React.FC<NavbarDrawerProps> = (props) => {
  const { classes, selectedTab } = props;
  const [expansion, setExpansion] = useState("");
  const [approvalsCount, setApprovalsCount] = useState(0);
  const [partlyDeclinedCount, setPartlyDeclinedCount] = useState(0);
  const [language, setLanguage] = React.useState(
    localStorage.getItem("master_language")
  );
  const t = useTranslate("RouteLabels");
  const [navbarDrawerItems, setNavbarDrawerItems] = useState<
    NavRoute<LayoutRoute>[]
  >(populateAndFilterNav(AppSideNavRoutesMap, getNavbarDrawerItems(t)));

  const selectedLanguage = localStorage.getItem("master_language");

  useEffect(() => {
    if (selectedLanguage !== language) {
      console.log("Changed language");
      setLanguage(selectedLanguage);
      const appRoutes: Array<{
        apiUsage: string[];
        routes: LayoutRoute[];
      }> = AppRouterDec(t) as any[];

      appRoutes.forEach(({ apiUsage, routes }) => {
        routes.forEach((r) => {
          r.apiUsage = apiUsage;
          r.staticPath = makeRoutePathStatic(r.path);
          if (r.parent) {
            r.staticParent = makeRoutePathStatic(r.parent);
          }
          AppRoutesStatic.push(r);
          AppStaticRoutesMap.set(r.staticPath, r);
          if (r.navId) {
            AppSideNavRoutesMap.set(r.navId, r);
          }
          return r;
        });
      });
      setNavbarDrawerItems(
        populateAndFilterNav(AppSideNavRoutesMap, getNavbarDrawerItems(t))
      );
    }
  }, [language, selectedLanguage, t]);

  const navbarItemClick = (navItemId: string) => {
    setExpansion((expand) => (expand === navItemId ? "" : navItemId));
  };

  useEffect(() => {
    if (selectedTab) {
      const selectedGroup = navbarDrawerItems.find(({ highlightClasses }) =>
        highlightClasses.includes(selectedTab.highlightClass as string)
      );
      if (selectedGroup) {
        setExpansion(selectedGroup.navId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    let alive = true;
    let handle: number | undefined = undefined;
    const getCounts = () => {
      void Promise.all([getPartlyDeclinedCountAPI(), getVerificationCountAPI()])
        .then(([partlyDeclined, verificationCount]) => {
          if (alive) {
            setPartlyDeclinedCount(partlyDeclined)
            setApprovalsCount(verificationCount.count)
            handle = window.setTimeout(() => {
              getCounts()
            }, 60000)
          }
        })
    }
    getCounts()
    return () => {
      alive = false;
      window.clearTimeout(handle)
    }
  }, []);

  const highlightClass = selectedTab?.highlightClass || "-----";
  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {navbarDrawerItems.map((navbarItem, idx) =>
          navbarItem.children.length ? (
            [
              <ListItem
                key={navbarItem.navId}
                id={navbarItem.navId}
                button
                onClick={() => navbarItemClick(navbarItem.navId)}
                className={
                  navbarItem.highlightClasses.includes(highlightClass)
                    ? classes.activeItem
                    : ""
                }
              >
                <ListItemIcon>
                  {(navbarItem.label === t("workNavbarPrimaryLabel") &&
                    approvalsCount > 0) ||
                  (navbarItem.label === t("ordersNavbarRouteInstancesLabel") &&
                    partlyDeclinedCount > 0) ? (
                    <Badge
                      color="secondary"
                      variant="dot"
                      overlap="rectangular"
                    >
                      <navbarItem.icon />
                    </Badge>
                  ) : (
                    <navbarItem.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={navbarItem.navLabel || navbarItem.label}
                  classes={{
                    primary: navbarItem.highlightClasses.includes(
                      highlightClass
                    )
                      ? classes.boldText
                      : ""
                  }}
                />
                {expansion === navbarItem.navId ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>,
              <Collapse
                key={idx}
                in={expansion === navbarItem.navId}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {navbarItem.children.map((childItem) => (
                    <NavbarLink
                      key={childItem.navId}
                      id={childItem.navId as string}
                      className={classes.nested}
                      route={childItem.path}
                      label={childItem.navLabel || childItem.label}
                      icon={childItem.icon}
                      approvalsCount={approvalsCount}
                      partlyDeclinedCount={partlyDeclinedCount}
                      isSelected={highlightClass === childItem.navId}
                    />
                  ))}
                </List>
              </Collapse>
            ]
          ) : (
            <NavbarLink
              key={navbarItem.navId}
              id={navbarItem.navId}
              route={navbarItem.path}
              label={navbarItem.navLabel || navbarItem.label}
              icon={navbarItem.icon}
              isSelected={highlightClass === navbarItem.navId}
            />
          )
        )}
      </List>
      <div className={classes.logoutItem}>
        <Divider />
        <List>
          <LogoutContainer buttonLabel={t("logoutButtonLabel")} />
        </List>
      </div>
    </div>
  );
};

export default withStyles(styles)(NavbarDrawer);
