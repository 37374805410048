import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, Tabs, Tab, Paper, Box } from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import CustomerInvoices from "./customerInvoices/CustomerInvoices";
import ContractorInvoices from "./contractorInvoices/ContractorInvoices";
import * as Router from "react-router-dom";
import { TabComponentProps } from "../../redux/types";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "inline-block"
    }
  });

interface InvoicesProps extends WithStyles<typeof styles>, TabComponentProps {}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Invoices: React.FC<InvoicesProps> = (props) => {
  const { classes, subTabIndex } = props;
  const t = useTranslate("InvoicesView");

  const [selectedTab, setSelectedTab] = useState(subTabIndex || 0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const linkDefinitions = [
    {
      label: t("customerInvoicesHeadingText"),
      route: "/invoices/customerinvoices"
    },
    {
      label: t("contractorInvoicesHeadingText"),
      route: "/invoices/contractorinvoices"
    }
  ];

  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          {linkDefinitions.map((linkDefinition, index) => (
            <Tab
              key={index}
              label={linkDefinition.label}
              component={React.forwardRef<
                HTMLAnchorElement,
                Partial<Router.LinkProps>
              >((props, ref) => (
                <Router.Link
                  to={`${linkDefinition.route}?same_tab=true`}
                  {...props}
                  ref={ref as any}
                />
              ))}
            />
          ))}
        </Tabs>
      </Paper>
      <TabPanel value={selectedTab} index={0}>
        <CustomerInvoices />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <ContractorInvoices />
      </TabPanel>
    </>
  );
};

export default withStyles(styles)(Invoices);
