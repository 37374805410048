import React, { useEffect } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid
} from "@material-ui/core";
import * as types from "../../redux/types";
import { niceDate } from "../FormatHelpers";
import { useTranslate } from "../../services/appLanguageService";
import ImageContainer from "../../containers/ImageContainer";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../redux/types";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    }
  });

interface TemplateImageProps
  extends WithStyles<typeof styles>,
    TabComponentProps {
  templateImage: types.TemplateImage;
}

const TemplateImage: React.FC<TemplateImageProps> = (props) => {
  const { classes, templateImage, tabId } = props;
  const t = useTranslate("TemplateImagePage");
  useEffect(() => {
    store.dispatch(setTitle(templateImage.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateImage]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper}>
            <ImageContainer
              src={templateImage.image}
              alt={templateImage.name}
            />
            <List>
              <ListItem>
                <ListItemText
                  primary={t("nameLabel")}
                  secondary={templateImage.name}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t("notesLabel")}
                  secondary={templateImage.notes}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t("createdAtLabel")}
                  secondary={niceDate(templateImage.created_at)}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t("updatedAtLabel")}
                  secondary={niceDate(templateImage.updated_at)}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(TemplateImage);
