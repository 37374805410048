import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Prospect } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../../redux/strings";
import CreateProspectButtonContainer from "./CreateProspectButtonContainer";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface ProspectsContainerProps {
  prospects: Prospect[];
  count: number;
  loadingProspects: boolean;
  resetProspects: () => void;
  loadProspects: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string
  ) => void;
}

const ProspectsContainer: React.FC<ProspectsContainerProps> = (props) => {
  const { loadProspects, prospects, loadingProspects, resetProspects } = props;
  const page = 1;
  const pageSize = 20;

  useEffect(() => loadProspects(page, pageSize), [loadProspects]);
  useEffect(() => () => resetProspects(), [resetProspects]);

  const columnDefinitions = [{ name: "customer_object_name", type: "link" }];

  return (
    <>
      <CreateProspectButtonContainer {...mapTabRouteProps(props)} />
      <ResourcesTableContainer
        resources={prospects}
        resourceName={"Prospect"}
        count={props.count}
        loadingResources={loadingProspects}
        showNewButton={false}
        routeUrl={"contracts/prospects"}
        columnDefinitions={columnDefinitions}
        resetResources={resetProspects}
        loadResources={loadProspects}
        defaultPageSize={pageSize}
        filterDefinitions={[]}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadProspects: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__customer_object_name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__customer_object_name=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PROSPECTS,
        `contracts/prospects/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}`
      )
    );
  },
  resetProspects: () =>
    dispatch(LoadingBooleansActions.ResetProspects(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  prospects: state.tabStates[ownProps.tabId].prospects.prospects.prospects,
  count: state.tabStates[ownProps.tabId].prospects.prospects.count,
  loadingProspects:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingProspects
});

export default connect(mapStateToProps, mapDispatchToProps)(ProspectsContainer);
