import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  WithStyles
} from "@material-ui/core";
import { useTranslate } from "../services/appLanguageService";
import { SelectFieldOption } from "../redux/types";
import {
  Done as DoneIcon,
  Clear as ClearIcon,
  Edit as EditIcon
} from "@material-ui/icons";
import { restUrl } from "../services/api-declaration";
import { fetchPatch } from "../services/fetchWrapper";
import ResourceLink from "./links/ResourceLink";
import { Autocomplete } from "@material-ui/lab";

const styles = (theme: any) =>
  createStyles({
    addLabel: {
      color: "grey",
      fontStyle: "italic",
      cursor: "pointer"
    },
    icon: {
      fontSize: "20px"
    },
    select: {
      margin: 0,
      padding: 0
    }
  });

interface EditableAutoCompleteSelectProps extends WithStyles<typeof styles> {
  field: string;
  options: SelectFieldOption[];
  label: string;
  url: string;
  resourceUrl: string;
  updateResourceState: (newValue: any) => void;
  initValue: number | null;
}

const EditableAutoCompleteSelect: React.FC<EditableAutoCompleteSelectProps> = (
  props
) => {
  const { classes, options, label } = props;

  const t = useTranslate("EditableFieldComponent");
  const [edit, setEdit] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number | null>(
    props.initValue
  );
  const [loading, setLoading] = useState(false);

  const updateResource = async () => {
    setLoading(true);
    const response = await fetchPatch(restUrl(props.url), {
      [props.field]: selectedValue
    });

    setLoading(false);
    props.updateResourceState(response);
  };

  const generateLabel = () => {
    const currentLabel =
      options.find((option) => option.value === selectedValue)?.label || null;

    if (selectedValue === null || currentLabel === null) {
      return (
        <span
          data-cy={`${props.field}-edit`}
          className={selectedValue === null ? classes.addLabel : ""}
          onClick={() => setEdit(true)}
        >
          {t("addLabel")}...
        </span>
      );
    } else {
      return (
        <>
          <IconButton
            size="small"
            aria-label="Edit"
            data-cy={`${props.field}-edit`}
            onClick={() => setEdit(true)}
          >
            <EditIcon className={classes.icon} />
          </IconButton>
          <ResourceLink
            url={props.resourceUrl}
            id={selectedValue}
            label={currentLabel}
          />
        </>
      );
    }
  };

  return loading ? (
    <CircularProgress size={18} />
  ) : !edit ? (
    generateLabel()
  ) : (
    <Grid container>
      <Grid item xs={10} sm={10}>
        <Autocomplete
          data-cy={`${props.field}-field`}
          className={classes.select}
          value={
            options.find((option) => option.value === selectedValue) || {
              label: "",
              value: 0
            }
          }
          onChange={(event: any, selectedOption: SelectFieldOption | null) => {
            setSelectedValue(selectedOption?.value || null);
          }}
          options={options}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      </Grid>
      <Grid item xs={2} sm={2}>
        <IconButton
          data-cy={`${props.field}-save`}
          size="small"
          aria-label="Save"
          onClick={() => {
            updateResource();
            setEdit(false);
          }}
        >
          <DoneIcon className={classes.icon} style={{ color: "#006400" }} />
        </IconButton>
        <IconButton
          size="small"
          aria-label="Reset"
          onClick={() => {
            setEdit(false);
            setSelectedValue(props.initValue);
          }}
        >
          <ClearIcon className={classes.icon} style={{ color: "Crimson" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(EditableAutoCompleteSelect);
