import {
  IApprovalCorrectionsKey,
  IDataCarryKey,
  IPackageType
} from "../redux/types";

export const CONFLICTED_EDIT_FIELDS: IApprovalCorrectionsKey[] = [
  "start_time",
  "end_time"
];
export const APPROVAL_EDIT_FIELDS: IApprovalCorrectionsKey[] = [
  "start_time",
  "end_time",
  "materials",
  "service",
  "reported_service_category"
];

export const ALL_EDIT_FIELDS: IApprovalCorrectionsKey[] = [
  "start_time",
  "end_time",
  "task",
  "materials",
  "reported_longitude",
  "reported_latitude",
  "service",
  "accessories",
  "accompanying_people",
  "servicecategory",
  "reported_service_category"
];

export const APPROVAL_PRIOTITY: IDataCarryKey[] = [
  // "lowest"
  "corrections",
  "contractor_payables",
  "customer_billables"
  // "highest"
];

export const PACKAGE_ENUM: Record<IDataCarryKey, IPackageType> = {
  corrections: "",
  contractor_payables: "contractor",
  customer_billables: "customer"
};
