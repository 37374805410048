import React from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Input,
  WithStyles,
  Grid,
  IconButton,
  Tooltip
} from "@material-ui/core";

import UploadIcon from "@material-ui/icons/CloudUpload";
import ImageContainer from "../../containers/ImageContainer";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {
  ConfirmationDialogServiceProvider,
  useConfirmationDialog
} from "../confirmationDialog/ConfirmationDialogServiceProvider";
import { useTranslate } from "../../services/appLanguageService";
import LoadingSpinner from "../LoadingSpinner";

const styles = (theme: any) =>
  createStyles({
    saveButton: {
      float: "right",
      margin: 10
    }
  });

type FileEvent = {
  files: FileList;
  value: string;
};

interface SimpleImageUploadProps extends WithStyles<typeof styles> {
  handleImageSave: (image: string) => void;
  handleImageDelete: () => void;
  image: string | null;
  alt?: string;
  isLoading: boolean;
}

const SimpleImageUpload: React.FC<SimpleImageUploadProps> = (props) => {
  const { classes, handleImageSave, handleImageDelete, image, alt } = props;

  const t = useTranslate("SimpleImageUploadComponent");

  const fileSelector = React.useRef<HTMLInputElement>();

  const tobase64Str = (file: File | null | undefined) =>
    new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error: any) => reject(error);
    });

  const handleFileSelector = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileEvent = e.target as FileEvent;
    if (!fileEvent.files.length) {
      return;
    }
    const file = fileEvent.files[0];
    const imgstr: any = await tobase64Str(file);
    handleImageSave(imgstr);

    fileEvent.value = "";
  };

  const confirmationDialog = useConfirmationDialog();

  const handeDelete = async () => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("confirmationDescription")
    });
    handleImageDelete();
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={10}>
        {props.isLoading ? (
          <LoadingSpinner />
        ) : (
          image && <ImageContainer src={image} alt={alt} type="medium_image" />
        )}
      </Grid>
      <Grid item xs={12} sm={2}>
        <IconButton
          size="small"
          onClick={() => {
            fileSelector.current && fileSelector.current.click();
          }}
          className={classes.saveButton}
        >
          <Tooltip
            title={image ? t("replaceImageTooltip") : t("addImageTooltip")}
          >
            <UploadIcon />
          </Tooltip>
        </IconButton>
        <Input
          type="file"
          onChange={handleFileSelector}
          inputRef={fileSelector}
          style={{ display: "none" }}
        />
        {image && (
          <IconButton
            onClick={handeDelete}
            className={classes.saveButton}
            size="small"
          >
            <Tooltip title={t("deleteTooltip")}>
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

// export default withStyles(styles)(SimpleImageUpload);
export default withStyles(styles)((props: SimpleImageUploadProps) => (
  <ConfirmationDialogServiceProvider>
    <SimpleImageUpload {...props} />
  </ConfirmationDialogServiceProvider>
));
