import React, { useCallback, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  Grid,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import { TextField, Select } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  ContractingProfileForm,
  ContractingProfile,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import { EnumStrings } from "../../../redux/strings";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import Editor from "@monaco-editor/react";
import { useTranslate } from "../../../services/appLanguageService";
import { getFilteredCompaniesAPI } from "../../../services/api-declaration";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";

const currencyOptions = [
  EnumStrings.SEK,
  EnumStrings.NOK,
  EnumStrings.DKK,
  EnumStrings.EUR,
  EnumStrings.USD
];

const localeOptions = [EnumStrings.EN, EnumStrings.SV];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    currency: Yup.string()
      .oneOf(currencyOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    locale: Yup.string()
      .oneOf(localeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    notes: Yup.string().nullable().notRequired(),
    contracting_css: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    customer_contract_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    contractor_contract_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    customer_report_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    contractor_report_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    from_email: Yup.string()
      .min(1, t("minLengthError"))
      .max(150, t("max150LengthError"))
      .email(t("emailInvalidError"))
      .nullable()
      .notRequired(),
    bcc_email: Yup.string()
      .min(1, t("minLengthError"))
      .max(150, t("max150LengthError"))
      .email(t("emailInvalidError"))
      .nullable()
      .notRequired(),
    contract_email_body_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    contract_email_subject_jinja: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    default_mail_message_text: Yup.string()
      .min(1, t("minLengthError"))
      .nullable()
      .notRequired(),
    contracting_company: Yup.number()
      .positive(t("requiredError"))
      .required(t("requiredError"))
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    },
    editor: {
      border: "1px solid #E4E4E4",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    editorLabel: {
      marginTop: theme.spacing(5)
    },
    root: {
      flexGrow: 1
    }
  });

interface NewContractingProfileProps
  extends FormikSubmitDispatchProps<ContractingProfileForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  contractingProfile?: ContractingProfile;
  companyOptions: SelectFieldOption[];
  customerContractOptions?: SelectFieldOption[];
  contractorContractOptions?: SelectFieldOption[];
  handleSaveAndCreatePDF?: (
    contractingProfileId: number,
    contractingId: number,
    jinjaTemplate: string,
    contracting_css: string,
    type: string
  ) => void;
  loadCompanies: (searchTerm?: string) => void;
  loadCustomerContracts?: (searchTerm?: string) => void;
  loadContractorContracts?: (searchTerm?: string) => void;
}

const NewEditContractingProfile: React.FC<NewContractingProfileProps> = (
  props
) => {
  const {
    classes,
    contractingProfile,
    companyOptions,
    customerContractOptions,
    contractorContractOptions,
    handleSaveAndCreatePDF,
    loadCompanies,
    loadCustomerContracts,
    loadContractorContracts,
    tabId
  } = props;
  const t = useTranslate("ContractingProfilePage");
  const t3 = useTranslate("ValidationErrorMessages");
  const [contractSignerOptions, setContractSignerOptions] = useState<
    SelectFieldOption[]
  >([]);
  let initialValues: ContractingProfile | ContractingProfileForm;
  if (contractingProfile && (contractingProfile as ContractingProfile).id) {
    initialValues = {
      ...contractingProfile
    };
  } else {
    initialValues = {
      id: -1,
      currency: "SEK",
      locale: "SV",
      name: "",
      notes: null,
      contracting_css: null,
      customer_contract_jinja: null,
      contractor_contract_jinja: null,
      from_email: null,
      bcc_email: null,
      contract_email_body_jinja: null,
      contract_email_subject_jinja: null,
      default_mail_message_text: null,
      default_contract_signer: null,
      contracting_company: -1
    };
  }

  const getFilterCompanies = async () => {
    try {
      const response = await getFilteredCompaniesAPI();
      const options = response.results.length
        ? response.results.map((option) => {
            return {
              label: option.name,
              value: option.id
            };
          })
        : [];
      setContractSignerOptions(options);
    } catch (e) {
      console.warn(e);
    }
  };
  const initCb = useCallback(getFilterCompanies, []);
  useEffect(() => {
    initCb();
  }, [initCb]);
  useEffect(() => {
    if (contractingProfile) {
      store.dispatch(setTitle(contractingProfile.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractingProfile]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/contracts/contractingprofiles${
                contractingProfile ? `/${contractingProfile.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {contractingProfile
            ? t("editContractingProfileHeading")
            : t("newContractingProfileHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={props.handleSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="contractingprofile-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-contracting_company"
              >
                {t("contractingCompanyLabel")}
              </InputLabel>
              <Field
                id="form-contracting_company"
                type="text"
                name="contracting_company"
                label={t("contractingCompanyLabel")}
                placeholder={t("contractingCompanyLabel")}
                value={
                  companyOptions.find(
                    (company) =>
                      props.values.contracting_company === company.value
                  )
                    ? companyOptions.find(
                        (company) =>
                          props.values.contracting_company === company.value
                      )
                    : null
                }
                options={companyOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                loadOptions={loadCompanies}
              />

              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-contracting_company"
              >
                {t("defaultContractSigner")}
              </InputLabel>
              <Field
                id="form-default_contract_signer"
                type="text"
                name="default_contract_signer"
                label={t("defaultContractSigner")}
                placeholder={t("defaultContractSigner")}
                value={
                  contractSignerOptions &&
                  contractSignerOptions.find(
                    (signer) =>
                      props.values.default_contract_signer === signer.value
                  )
                    ? contractSignerOptions.find(
                        (symbol) =>
                          props.values.default_contract_signer === symbol.value
                      )
                    : null
                }
                options={contractSignerOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
              />

              {/* Currency */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="contractingprofile-form-currency"
              >
                {t("currencyLabel")}
              </InputLabel>
              <Field
                id="contractingprofile-form-currency"
                name="currency"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {currencyOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>

              {/* Locale */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="contractingprofile-form-locale"
              >
                {t("localeLabel")}
              </InputLabel>
              <Field
                id="contractingprofile-form-locale"
                name="locale"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {localeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>

              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-contractingprofile-form-contracting_css"
              >
                {t("contractingCssLabel")}
              </InputLabel>
              <Field
                id="new-contractingprofile-form-contracting_css"
                type="hidden"
                name="contracting_css"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="css"
                  options={{
                    extraEditorClassName:
                      "new-contractingprofile-form-contracting_css"
                  }}
                  value={initialValues.contracting_css || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("contracting_css", value);
                    }
                  }}
                />
              </div>

              <InputLabel
                className={classes.editorLabel}
                htmlFor="new-contractingprofile-form-customer_contract_jinja"
              >
                {t("customerContractJinjaLabel")}
              </InputLabel>
              <Field
                id="new-contractingprofile-form-customer_contract_jinja"
                type="hidden"
                name="customer_contract_jinja"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="html"
                  options={{
                    extraEditorClassName:
                      "new-contractingprofile-form-customer_contract_jinja"
                  }}
                  value={initialValues.customer_contract_jinja || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("customer_contract_jinja", value);
                    }
                  }}
                />
              </div>
              {contractingProfile &&
              customerContractOptions &&
              loadCustomerContracts &&
              typeof handleSaveAndCreatePDF !== "undefined" ? (
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                      <Field
                        id="form-customer_contract"
                        type="text"
                        name="customer_contract"
                        label={t("customerContractsLabel")}
                        placeholder={t("customerContractsLabel")}
                        options={customerContractOptions}
                        component={AutoCompleteSelect}
                        fullWidth
                        margin="normal"
                        loadOptions={loadCustomerContracts}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        id="contractingprofile-form-customer_contract_jinja-button"
                        type="button"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          if (
                            props.values.customer_contract_jinja &&
                            props.values.contracting_css &&
                            props.values.customer_contract &&
                            props.values.customer_contract !== -1
                          ) {
                            handleSaveAndCreatePDF(
                              contractingProfile.id,
                              props.values.customer_contract,
                              props.values.customer_contract_jinja,
                              props.values.contracting_css,
                              EnumStrings.CUSTOMER
                            );
                          }
                        }}
                      >
                        {t("previewButtonLabel")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                ""
              )}

              <InputLabel
                className={classes.editorLabel}
                htmlFor="new-contractingprofile-form-contractor_contract_jinja"
              >
                {t("contractorContractJinjaLabel")}
              </InputLabel>
              <Field
                id="new-contractingprofile-form-contractor_contract_jinja"
                type="hidden"
                name="contractor_contract_jinja"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="html"
                  options={{
                    extraEditorClassName:
                      "new-contractingprofile-form-contractor_contract_jinja"
                  }}
                  value={initialValues.contractor_contract_jinja || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("contractor_contract_jinja", value);
                    }
                  }}
                />
              </div>

              {contractingProfile &&
              contractorContractOptions &&
              loadContractorContracts &&
              typeof handleSaveAndCreatePDF !== "undefined" ? (
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                      <Field
                        id="form-contractor_contract"
                        type="text"
                        name="contractor_contract"
                        label={t("contractorContractsLabel")}
                        placeholder={t("contractorContractsLabel")}
                        options={contractorContractOptions}
                        component={AutoCompleteSelect}
                        fullWidth
                        margin="normal"
                        loadOptions={loadContractorContracts}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        id="contractingprofile-form-contractor_contract_jinja-button"
                        type="button"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          if (
                            props.values.contractor_contract_jinja &&
                            props.values.contracting_css &&
                            props.values.contractor_contract &&
                            props.values.contractor_contract !== -1
                          ) {
                            handleSaveAndCreatePDF(
                              contractingProfile.id,
                              props.values.contractor_contract,
                              props.values.contractor_contract_jinja,
                              props.values.contracting_css,
                              EnumStrings.CONTRACTOR
                            );
                          }
                        }}
                      >
                        {t("previewButtonLabel")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                ""
              )}

              <Field
                id="contractingprofile-form-from_email"
                type="text"
                name="from_email"
                label={t("fromEmailLabel")}
                placeholder={t("fromEmailLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="contractingprofile-form-bcc_email"
                type="text"
                name="bcc_email"
                label={t("bccEmailLabel")}
                placeholder={t("bccEmailLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="contractingprofile-form-contract_email_subject_jinja"
                type="text"
                name="contract_email_subject_jinja"
                label={t("contractEmailSubjectJinjaLabel")}
                placeholder={t("contractEmailSubjectJinjaLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <InputLabel
                className={classes.editorLabel}
                htmlFor="new-contractingprofile-form-contract_email_body_jinja"
              >
                {t("contractEmailBodyJinjaLabel")}
              </InputLabel>
              <Field
                id="new-contractingprofile-form-contract_email_body_jinja"
                type="hidden"
                name="contract_email_body_jinja"
              />
              <div className={classes.editor}>
                <Editor
                  height="300px"
                  theme="vs"
                  language="html"
                  options={{
                    extraEditorClassName:
                      "new-contractingprofile-form-contract_email_body_jinja"
                  }}
                  value={initialValues.contract_email_body_jinja || ""}
                  onChange={(value, ev) => {
                    if (value) {
                      props.setFieldValue("contract_email_body_jinja", value);
                    }
                  }}
                />
              </div>

              <Field
                id="new-contractingprofile-form-default_mail_message_text"
                type="text"
                name="default_mail_message_text"
                label={t("defaultMailMessageTextLabel")}
                placeholder={t("defaultMailMessageTextLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="6"
                fullWidth
              />

              <Field
                id="new-contractingprofile-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-contractingprofile-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {contractingProfile
                  ? t("saveButtonLabel")
                  : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditContractingProfile);
