import React from "react";
import { WithStyles, TextField, Grid, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { useTranslate } from "../../../services/appLanguageService";
import { LocationForm, LocationType } from "../../../redux/types";
import { FormikProps } from "formik";

const autocompleteService = { current: null };

const styles = (theme: any) =>
  createStyles({
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2)
    }
  });

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

interface AutocompletePlacesProps extends WithStyles<typeof styles> {
  location: LocationType | LocationForm | null;
  formikProps?: FormikProps<any>;
  fieldName?: string;
  suggestDistrict?: (latitude: number, longitude: number) => number | null;
}

const AutocompletePlaces: React.FC<AutocompletePlacesProps> = (props) => {
  const { classes, location, formikProps, fieldName } = props;

  const [inputValue, setInputValue] = React.useState("");
  const [autocompleteInputValue, setAutocompleteInputValue] =
    React.useState("");
  const [options, setOptions] = React.useState<PlaceType[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setAutocompleteInputValue(event.target.value);
  };

  const t = useTranslate("LocationComponent");

  const fetch = React.useMemo(
    () =>
      throttle((input: any, callback: any) => {
        (autocompleteService.current as any).getPlacePredictions(
          input,
          callback
        );
      }, 200),
    []
  );

  const getComponent = (result: google.maps.GeocoderResult, type: string) =>
    (result.address_components.find((component) =>
      component.types.includes(type)
    ) as google.maps.GeocoderAddressComponent)
      ? (
          result.address_components.find((component) =>
            component.types.includes(type)
          ) as google.maps.GeocoderAddressComponent
        ).long_name
      : null;

  const getAndStorePosition = (place_id: string) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      { placeId: place_id },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (
          status === "OK" &&
          results[0] &&
          typeof formikProps !== "undefined"
        ) {
          const address = getComponent(results[0], "route") || "",
            streetNumber = getComponent(results[0], "street_number") || "",
            postalCode = getComponent(results[0], "postal_code") || "",
            postalTown = getComponent(results[0], "postal_town") || "",
            country = getComponent(results[0], "country") || "";

          const latitude = +results[0].geometry.location.lat().toFixed(7),
            longitude = +results[0].geometry.location.lng().toFixed(7);

          let districtId = null;

          if (props.suggestDistrict) {
            districtId = props.suggestDistrict(latitude, longitude);
          } else if (formikProps.values[props.fieldName || "location"]) {
            districtId =
              formikProps.values[props.fieldName || "location"].district;
          }

          formikProps.setFieldValue(props.fieldName || "location", {
            ...formikProps.values[props.fieldName || "location"],
            full_address: `${address} ${streetNumber} ${postalCode} ${postalTown}`,
            address: `${address} ${streetNumber}`,
            city: postalTown,
            zip_code: postalCode,
            country: country,
            latitude: latitude,
            longitude: longitude,
            district: districtId
          });
        }
      }
    );
  };

  React.useEffect(() => {
    if (formikProps && formikProps.values[fieldName || "location"]) {
      const tempLocation = formikProps.values[fieldName || "location"];
      setAutocompleteInputValue(
        tempLocation.full_address
          ? `${tempLocation.address || ""}, ${tempLocation.city || ""}, ${
              tempLocation.country || ""
            }`
          : ""
      );
    }
  }, [location, setAutocompleteInputValue, formikProps, fieldName]);

  React.useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      (window as any).google &&
      (window as any).google.maps.places
    ) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      id={`google-maps-search${`-${props.fieldName}` || ""}`}
      cy-data={`google-maps-search${`-${props.fieldName}` || ""}`}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoSelect
      autoComplete
      includeInputInList
      inputValue={autocompleteInputValue}
      // disableOpenOnFocus
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
      onChange={(event: object, value: any) => {
        if (value) {
          getAndStorePosition(value.place_id);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("searchAddressLabel")}
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length
          ])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default withStyles(styles)(AutocompletePlaces);
