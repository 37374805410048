import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, District } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../../redux/strings";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface DistrictsContainerProps {
  districts: District[];
  count: number;
  loadingDistricts: boolean;
  districtIds?: number[];
  resetDistricts: () => void;
  loadDistricts: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  showNewButton?: boolean;
  showResourceButton?: boolean;
  customHeader?: string;
}

const DistrictsContainer: React.FC<DistrictsContainerProps> = (props) => {
  const {
    districtIds,
    loadDistricts,
    resetDistricts,
    loadingDistricts,
    districts
  } = props;

  const page = 1;
  const pageSize = 20;

  let filter = "";

  if (districtIds) {
    const filterStr =
      districtIds.length !== 1 ? "filter__id__in" : "filter__id";
    const districtIdsStr = districtIds.length ? districtIds.toString() : "";

    filter = `&${filterStr}=${districtIdsStr}`;
  }

  useEffect(
    () => loadDistricts(page, pageSize, undefined, undefined, filter),
    [filter, loadDistricts]
  );
  useEffect(() => () => resetDistricts(), [resetDistricts]);

  const columnDefinitions = [{ name: "name", type: "link" }];

  const showNewButton =
    (typeof props.showNewButton !== "undefined" && props.showNewButton) ||
    typeof props.showNewButton === "undefined";

  return (
    <ResourcesTableContainer
      resources={districts}
      resourceName={"District"}
      count={props.count}
      loadingResources={loadingDistricts}
      routeUrl={"companies/districts"}
      columnDefinitions={columnDefinitions}
      resetResources={resetDistricts}
      loadResources={loadDistricts}
      customHeader={props.customHeader}
      showNewButton={showNewButton}
      filterDefinitions={[]}
      customFilter={filter}
      defaultPageSize={pageSize}
      resourcesButtons={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadDistricts: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__name=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.DISTRICTS,
        `companies/districts/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  resetDistricts: () =>
    dispatch(LoadingBooleansActions.ResetDistricts(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  districts: state.tabStates[ownProps.tabId].districts.districts.results,
  count: state.tabStates[ownProps.tabId].districts.districts.count,
  loadingDistricts:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingDistricts
});

export default connect(mapStateToProps, mapDispatchToProps)(DistrictsContainer);
