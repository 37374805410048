import { combineReducers } from "redux";
import { Action, mainRootState } from "../types";
import { RESET_STATE } from "./actionTypes";
import authentication from "./authentication/reducer";
import snackbar from "./snackbar/reducer";
import connectivity from "./connectivity/reducer";
import fetchWrapper from "./fetchWrapper/reducer";
import tabSelection from "./tabRouter/tabSelection/reducer";
import tabSystem from "./tabRouter/tabSystem/reducer";
import tabTitle from "./tabRouter/tabTitle/reducer";
import verificationUpdatedAt from "./verificationCount/reducer";
import { connectRouter } from "connected-react-router";
import { History } from "history";

const appReducer = (history: History) =>
  combineReducers({
    tabStates: tabSystem,
    router: connectRouter(history),
    authentication,
    connectivity,
    tabSelection,
    tabTitle,
    verificationUpdatedAt,
    snackbar,
    fetchWrapper
  });

const rootReducer =
  (history: History) =>
  (state: mainRootState | undefined, action: Action<any>) => {
    switch (action.type) {
      case RESET_STATE:
        return appReducer(history)(undefined, action);
      default:
        return appReducer(history)(state, action);
    }
  };

export default rootReducer;
