import { createSuccessActionType } from "../../actionTypes";
import { RoutePlansState, Action, RoutePlan, Company } from "../../../types";
import { RestStrings, ReduxActionStrings } from "../../../strings";
import * as LoadingBooleansActions from "../../loadingBooleans/actionTypes";

const initialState: RoutePlansState = {
  routeplans: [],
  routeplanOptions: [],
  routeParticipantOptions: [],
  count: 0,
  next: null,
  previous: null,
  routeplan: {
    routeplan: {
      id: -1,
      created_at: "",
      updated_at: "",
      rows: [],
      name: "",
      status: "DRAFT" as RoutePlan["status"],
      oca: -1,
      servicecategories: [],
      participants: []
    },
    routeSegmentsTasks: [],
    routeTimesheets: []
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTEPLANS
    ):
      return {
        ...state,
        routeplans: action.payload.results,
        count: action.payload.count
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTEPLAN_OPTIONS
    ):
      return {
        ...state,
        routeplanOptions: action.payload.results.map(
          (routePlan: RoutePlan) => ({
            label: routePlan.name,
            value: routePlan.id
          })
        )
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTE_PARTICIPANT_OPTIONS
    ):
      return {
        ...state,
        routeParticipantOptions: action.payload.results.map(
          (routeParticipant: Company) => ({
            label: routeParticipant.name,
            value: routeParticipant.id
          })
        )
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.ROUTEPLANS
    ):
      return {
        ...state,
        routeplans: [...state.routeplans, action.payload]
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.ROUTEPLAN):
      return action.payload !== undefined
        ? {
            ...state,
            routeplan: {
              ...state.routeplan,
              routeplan: action.payload
            }
          }
        : state;

    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.ROUTEPLAN
    ):
      return {
        ...state,
        routeplans: state.routeplans.filter(
          (routeplan: RoutePlan) => routeplan.id !== parseInt(action.payload.id)
        )
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTESEGMENTS_TASKS
    ):
      return {
        ...state,
        routeplan: {
          ...state.routeplan,
          routeSegmentsTasks: action.payload.results
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTETIMESHEETS_TASKS
    ):
      return {
        ...state,
        routeplan: {
          ...state.routeplan,
          routeTimesheets: action.payload.results
        }
      };
    case LoadingBooleansActions.RESET_ROUTEPLAN:
      return {
        ...state,
        routeplan: initialState.routeplan
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.ROUTEPARTICIPANT
    ):
      return {
        ...state,
        routeplan: {
          ...state.routeplan,
          routeplan: {
            ...state.routeplan.routeplan,
            participants: [
              ...state.routeplan.routeplan.participants,
              action.payload.id
            ]
          }
        }
      };
    default:
      return state;
  }
}

export default reducer;
