import React from "react";
import {
  WithStyles,
  TableBody,
  TableCell,
  Table,
  Typography,
  TableRow,
  Link
} from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { ErrorReportPayloadType } from "../../redux/types";
import { Link as RouterLink } from "react-router-dom";
import { niceDate } from "../FormatHelpers";

const styles = (theme: any) =>
  createStyles({
    payloadContainer: {
      marginTop: 20,
      whiteSpace: "pre-wrap",
      borderRadius: 5
    },
    errorBox: {
      backgroundColor: "#EEE",
      fontFamily: "Monospace",
      fontSize: 12,
      padding: 10
    },
    errorDetails: {
      backgroundColor: "#fcc",
      padding: 20
    },
    tableCell: {
      borderBottom: 0
    },
    leftCell: {
      width: "150px",
      textAlign: "right"
    }
  });

const highlightText = (text: string, highlight: string) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part === highlight
              ? { fontWeight: "bold", fontSize: 14, fontFamily: "Sans-serif" }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
};

interface ErrorReportPayloadProps extends WithStyles<typeof styles> {
  payload: ErrorReportPayloadType;
}

const ErrorReportPayload: React.FC<ErrorReportPayloadProps> = (props) => {
  const t = useTranslate("EventlogPage");
  const { classes, payload } = props;

  return (
    <div className={classes.payloadContainer}>
      <div className={classes.errorDetails}>
        <Typography variant="h6">{payload.summary}</Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("nameLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.data.error.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("messageLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.data.error.message}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("fatalLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.data.error.fatal ? t("yesLabel") : t("noLabel")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("companyLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Link
                  component={RouterLink}
                  to={`/companies/companies/${payload.data.user.company.id}`}
                >
                  {payload.data.user.company.name}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("engUserLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Link
                  component={RouterLink}
                  to={`/auth/persons/${payload.data.user.person.id}`}
                >
                  {`${payload.data.user.person.first_name} ${payload.data.user.person.last_name}`}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("engTimestampLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {niceDate(
                  new Date(payload.data.error.timestamp).toISOString(),
                  true,
                  true
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("domainLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.data.user.domain.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("appVersionLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.origin.version}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("isTestingLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.origin.platform.isTesting
                  ? t("yesLabel")
                  : t("noLabel")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("systemLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.origin.platform.systemName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("osVersionLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.origin.platform.osVersion}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("reactNativeVersionLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.origin.platform.reactNativeVersion.major}.
                {payload.origin.platform.reactNativeVersion.minor}.
                {payload.origin.platform.reactNativeVersion.patch}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("interfaceIdiomLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.origin.platform.interfaceIdiom}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCell}`}>
                <b>{t("forceTouchLabel")}</b>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {payload.origin.platform.forceTouchAvailable
                  ? t("yesLabel")
                  : t("noLabel")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={classes.errorBox}>
        {payload.data.error.stack.split("\n").map((row, index) => (
          <div key={index}>{highlightText(row, " at ")}</div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(ErrorReportPayload);
