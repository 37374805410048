import { ContractingProfilesState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: ContractingProfilesState = {
  contractingProfiles: {
    contractingProfiles: [],
    next: null,
    previous: null,
    count: 0
  },
  contractingProfile: {
    contractingProfile: {
      id: -1,
      created_at: "",
      updated_at: "",
      currency: "SEK",
      locale: "SV",
      name: "",
      notes: "",
      contracting_css: "",
      customer_contract_jinja: "",
      contractor_contract_jinja: "",
      from_email: "",
      bcc_email: "",
      contract_email_body_jinja: "",
      contract_email_subject_jinja: "",
      default_mail_message_text: "",
      default_contract_signer: null,
      contracting_company: -1
    },
    customerContracts: [],
    contractorContracts: []
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTINGPROFILES
    ):
      return {
        ...state,
        contractingProfiles: {
          ...state.contractingProfiles,
          contractingProfiles: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTINGPROFILE
    ):
      return {
        ...state,
        contractingProfile: {
          ...state.contractingProfile,
          contractingProfile: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.CONTRACTINGPROFILE
    ):
      return {
        ...state,
        contractingProfiles: {
          ...state.contractingProfiles,
          contractingProfiles: [
            ...state.contractingProfiles.contractingProfiles,
            action.payload
          ]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.CONTRACTINGPROFILE
    ):
      return {
        ...state,
        contractingProfiles: {
          ...state.contractingProfiles,
          contractingProfiles: [
            ...state.contractingProfiles.contractingProfiles.map(
              (contractingProfile) =>
                contractingProfile.id === action.payload.id
                  ? action.payload
                  : contractingProfile
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CUSTOMER_CONTRACTS
    ):
      return {
        ...state,
        contractingProfile: {
          ...state.contractingProfile,
          customerContracts: action.payload.results
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTOR_CONTRACTS
    ):
      return {
        ...state,
        contractingProfile: {
          ...state.contractingProfile,
          contractorContracts: action.payload.results
        }
      };

    default:
      return state;
  }
}

export default reducer;
