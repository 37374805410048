import { TextField, TextFieldProps } from "@material-ui/core";
import { useField } from "formik";
import { useState } from "react";

export const BlurTextField = ({
  name,
  ...props
}: TextFieldProps & { name: string }) => {
  const [{ onChange, onBlur, value, ...field }, meta, helpers] =
    useField<string>(name);
  const [currentValue, setCurrentValue] = useState<string>(value);
  return (
    <TextField
      {...props}
      {...field}
      value={currentValue}
      onChange={(e) => {
        setCurrentValue(e.currentTarget.value);
      }}
      onBlur={() => {
        if (!meta.touched) {
          helpers.setTouched(true);
        }
        helpers.setValue(currentValue, true);
      }}
      error={!!meta.error}
      helperText={meta.error}
      onKeyDown={(e: { key: string; preventDefault: () => void }) =>
        e.key === "Enter" && e.preventDefault()
      }
    />
  );
};
