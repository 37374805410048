import {
  IApprovalCorrections,
  IApprovalCorrectionsKey,
  ICorrectionsCarry,
  IDataCarryKey,
  RowApprovalModificationsPayload
} from "../../../redux/types";
import {
  createWorkApprovalAPI,
  updateWorkApprovalAPI
} from "../../../services/api-declaration";
import WorkApproval from "./WorkApproval";

class CorrectionsCarry {
  public editMode: ICorrectionsCarry["editMode"];
  public dataCarry: ICorrectionsCarry["dataCarry"];
  public corrections: ICorrectionsCarry["corrections"];
  public workApproval: WorkApproval;

  constructor(workApproval: WorkApproval) {
    const { corrections, contractor_payables, customer_billables } =
      workApproval;
    this.workApproval = workApproval;

    this.editMode = "corrections";
    this.dataCarry = {
      corrections: workApproval.copyDataCarryValues(
        {} as IApprovalCorrections,
        corrections
      ),
      contractor_payables: workApproval.copyDataCarryValues(
        {} as IApprovalCorrections,
        contractor_payables
      ),
      customer_billables: workApproval.copyDataCarryValues(
        {} as IApprovalCorrections,
        customer_billables
      )
    };
    this.corrections = workApproval.getCorrectionsOrRowData(
      this.dataCarry,
      this.editMode
    );
  }

  public editModeChanged(editMode: IDataCarryKey) {
    this.editMode = editMode;
    this.corrections = this.workApproval.getCorrectionsOrRowData(
      this.dataCarry,
      editMode
    );
    return this;
  }

  public correctionChanged(fieldName: IApprovalCorrectionsKey, value: any) {
    (this.dataCarry[this.editMode][fieldName] as any) = value;
    (this.corrections[fieldName] as any) = value;

    if (!this.workApproval.hasCorrectionValue(value, fieldName)) {
      delete this.dataCarry[this.editMode][fieldName];
      delete this.corrections[fieldName];
    }

    this.corrections = {
      ...this.corrections,
      [fieldName]: this.dataCarry[this.editMode][fieldName]
    };
    return this;
  }

  public resetChanges() {
    this.dataCarry = { ...this.dataCarry, [this.editMode]: {} };
    this.corrections = this.workApproval.getCorrectionsOrRowData(
      this.dataCarry,
      this.editMode
    );
    return this;
  }

  public resetField(fieldName: IApprovalCorrectionsKey) {
    const clone = { ...this.dataCarry[this.editMode] };
    delete clone[fieldName];

    this.dataCarry = {
      ...this.dataCarry,
      [this.editMode]: clone
    };
    this.corrections = this.workApproval.getCorrectionsOrRowData(
      this.dataCarry,
      this.editMode
    );
    return this;
  }

  public getSubmittedReadables() {
    return this.workApproval.getCorrectionsByPriority(this.dataCarry);
  }

  public async saveWorkApproval() {
    let isFilled = false;
    const getNonEmptyOrNull = (key: IDataCarryKey) => {
      const hasChanges = Object.keys(this.dataCarry[key]).length !== 0;
      isFilled = isFilled || hasChanges;
      return hasChanges ? this.dataCarry[key] : (null as any);
    };

    const rowApproval: RowApprovalModificationsPayload = {
      corrections: getNonEmptyOrNull("corrections"),
      contractor_payables: getNonEmptyOrNull("contractor_payables"),
      customer_billables: getNonEmptyOrNull("customer_billables")
    };

    if (!this.workApproval.id) {
      if (isFilled) {
        return await createWorkApprovalAPI({
          ...rowApproval,
          submittedwork: this.workApproval.submittedwork.id
        });
      }
    } else {
      return await updateWorkApprovalAPI(this.workApproval.id, rowApproval);
    }
  }
}

export default CorrectionsCarry;
