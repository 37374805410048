import React, { useEffect, useRef, useState } from "react";
import { createStyles, makeStyles, Paper, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { getCompanyNamesAPI, getPersonsAPI } from "../services/api-declaration";
import { ensureMapScript } from "./contracts/tasks/maps/MapHelpers";
import {
  addPositionListener,
  PositionMessage,
  useChannel
} from "./ChannelContext";
import { useTranslate } from "../services/appLanguageService";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "calc(100vh - 150px)",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    mapContainer: {
      flexBasis: "70%"
    },
    usersContainer: {
      flexBasis: "30%",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      overflow: "auto"
    },
    personLabel: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
      cursor: "pointer",
      "& > div": {
        marginLeft: theme.spacing(1)
      }
    }
  })
);

export const COLOR_PALETTE = [
  "#feb236",
  "#43b6c1",
  "#82b74b",
  "#f7786b",
  "#3671f5",
  "#d6ba1d",
  "#ff7b25",
  "#e60000",
  "#9124b5"
];

function contrastColor(hexcolor: string) {
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

const customMarkerConfig = (color: string): google.maps.ReadonlySymbol => ({
  fillColor: color,
  fillOpacity: 1,
  scale: 1.4,
  path: `M 12.6 35.1 c -0.2 0.6 -0.8 1 -1.5 1 c -0.7 0 -1.2 -0.4 -1.5 -1 C 6.4 26.8 0 16.2 0 11.1 C 0 5 5 0 11.1 0 c 6.1 0 11.1 5 11.1 11.1 C 22.2 16.2 15.7 26.7 12.6 35.1`,
  strokeColor: color,
  anchor: new google.maps.Point(11.1, 36.1),
  labelOrigin: new google.maps.Point(11.1, 11)
});

const hashColor = (userId: number, companyId: number) =>
  COLOR_PALETTE[((companyId << 5) + userId) % COLOR_PALETTE.length];

type PersonMarker = {
  userId: number;
  companyId: number;
  marker: google.maps.Marker;
  circle: google.maps.Circle;
  timestamp: number;
};

type LiveViewProps = {};

const locationAge = (timestamp: number): [number, string] => {
  const now = Date.now();
  const diffSec = Math.round(now / 1000 - timestamp);
  if (diffSec < 60) {
    return [diffSec, "lessThanMinute"];
  } else if (diffSec < 300) {
    return [diffSec, "lessThanFiveMinutes"];
  } else if (diffSec < 600) {
    return [diffSec, "lessThanTenMinutes"];
  } else if (diffSec < 3600) {
    return [diffSec, "lessThanHour"];
  } else if (diffSec < 43200) {
    return [diffSec, "overAnHour"];
  } else {
    return [diffSec, "old"];
  }
};

const LiveView: React.FC<LiveViewProps> = (_props) => {
  const classes = useStyles();
  const mapRef = useRef<google.maps.Map>();
  const markersRef = useRef<PersonMarker[]>([]);
  const userNameRef = useRef<Map<number, string>>(new Map());
  const [, setRefresh] = useState(false);
  const channel = useChannel();
  const t = useTranslate("LiveView");

  useEffect(() => {
    if (channel) {
      const onPosition = (m: PositionMessage) => {
        const marker = markersRef.current.find((p) => m.user_id === p.userId);
        if (marker) {
          marker.marker.setPosition(
            new google.maps.LatLng(m.latitude, m.longitude)
          );
          marker.circle.setRadius(m.accuracy);
          marker.timestamp = m.timestamp;
        } else {
          void getPersonsAPI({
            filter__user__in: [m.user_id]
          })
            .then(
              async (response) =>
                [
                  response.results,
                  (
                    await getCompanyNamesAPI({
                      id__in: response.results.map((p) => p.company!)
                    })
                  ).results
                ] as const
            )
            .then(([persons, companies]) => {
              if (!markersRef.current.some((p) => m.user_id === p.userId)) {
                persons.forEach((p) => {
                  const company = companies.find((c) => c.id === p.company);
                  userNameRef.current.set(
                    p.user!,
                    `${p.first_name} ${p.last_name} (${company?.name ?? "n/a"})`
                  );
                });
                const initials =
                  userNameRef.current
                    .get(m.user_id)
                    ?.split(" ", 2)
                    .map((s) => s[0])
                    .join("") ?? "n/a";
                const color = hashColor(m.user_id, m.company_id);
                const map = mapRef.current;
                const marker = new google.maps.Marker({
                  map,
                  position: new google.maps.LatLng(m.latitude, m.longitude),
                  icon: customMarkerConfig(color),
                  label: {
                    text: initials,
                    color: contrastColor(color)
                  }
                });
                const circle = new google.maps.Circle({
                  map,
                  strokeColor: color,
                  strokeOpacity: 0.55,
                  strokeWeight: 2,
                  fillColor: color,
                  fillOpacity: 0.15,
                  radius: m.accuracy
                });
                circle.bindTo("center", marker, "position");
                if (!markersRef.current.some((p) => m.user_id === p.userId)) {
                  markersRef.current.push({
                    userId: m.user_id,
                    companyId: m.company_id,
                    marker,
                    circle,
                    timestamp: m.timestamp
                  });
                  setRefresh((r) => !r);
                }
              }
            });
        }
      };
      const unregister = addPositionListener(channel, onPosition);
      return unregister;
    }
  }, [channel]);

  useEffect(() => {
    let handle: number | undefined;
    const rerender = () => {
      if (markersRef.current.length > 0) {
        setRefresh((r) => !r);
      }
      handle = window.setTimeout(rerender, 5000);
    };
    rerender();
    return () => window.clearTimeout(handle);
  }, []);

  const onNameClick = (userId: number) => {
    const pos = markersRef.current
      .find((m) => m.userId === userId)
      ?.marker.getPosition();
    if (pos) {
      mapRef.current?.setCenter(pos);
    }
  };

  const onRef = (r: HTMLDivElement | null): void => {
    if (r && !mapRef.current) {
      ensureMapScript().then(() => {
        const map = new window.google.maps.Map(r, {
          zoom: 14,
          center: new window.google.maps.LatLng(57.6916305668, 11.974412769)
        });
        markersRef.current.forEach((m) => {
          m.marker.setMap(map);
          m.circle.setMap(map);
        });
        mapRef.current = map;
      });
    }
  };

  return (
    <Paper className={classes.root}>
      <div ref={onRef} className={classes.mapContainer}></div>
      <div className={classes.usersContainer}>
        <Typography variant="h6">{t("driverListHeader")}</Typography>
        {!markersRef.current.length && (
          <div>
            <Typography>{t("driverListEmpty")}</Typography>
          </div>
        )}
        {markersRef.current.map((m) => {
          const [age, desc] = locationAge(m.timestamp);
          return (
            <div
              key={m.userId}
              className={classes.personLabel}
              onClick={() => onNameClick(m.userId)}
            >
              <FiberManualRecord htmlColor={hashColor(m.userId, m.companyId)} />{" "}
              <div>
                <Typography>
                  {userNameRef.current.get(m.userId) ?? t("noName")}
                </Typography>
                <Typography variant="subtitle2" title={`${age} sec`}>
                  {t(desc)}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </Paper>
  );
};

export default LiveView;
