import { LayoutRouteUsage } from "../../../redux/types";
import CooperationContainer from "./CooperationContainer";
import CooperationEditFormContainer from "./CooperationEditFormContainer";
import CooperationsContainer from "./CooperationsContainer";

const CooperationRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/cooperations"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/cooperations",
        label: t("contractNavbarCooperationsLabel"),
        component: CooperationsContainer
      },
      {
        path: "/contracts/cooperations/:cooperationId",
        parent: "/contracts/cooperations",
        label: t("cooperationLabel"),
        component: CooperationContainer
      },
    //   {
    //     path: "/contracts/cooperations/new",
    //     parent: "/contracts/cooperations",
    //     label: t("newNavbarLabel"),
    //     component: undefined
    //   },
      {
        path: "/contracts/cooperations/:cooperationId/edit",
        parent: "/contracts/cooperations/:cooperationId",
        label: t("editCooperationLabel"),
        component: CooperationEditFormContainer
      }
    ]
  }
];

export default CooperationRouter;
