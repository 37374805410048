import React, { useCallback, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  Collapse
} from "@material-ui/core";
import { TextField, Select, CheckboxWithLabel } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  PersonForm,
  Person,
  SelectFieldOption,
  ImageRow,
  TabComponentProps
} from "../../redux/types";
import { EnumStrings } from "../../redux/strings";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  AutoCompleteSelect,
  AutoCompleteSelectMulti
} from "../AutoCompleteSelect";
import { useTranslate } from "../../services/appLanguageService";
import ImageUploadComponent from "../imageUploadComponent/ImageUploadComponent";
import { getImageByURL } from "../../services/imageHelperService";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";

const statusOptions = [
  EnumStrings.ACTIVE,
  EnumStrings.ARCHIVED,
  EnumStrings.LOCKED,
  EnumStrings.DRAFT
];

const titleOptions = [
  EnumStrings.CONTACT,
  EnumStrings.DRIVER,
  EnumStrings.CONFIRMED_BUYER,
  EnumStrings.TEAM_LEADER,
  EnumStrings.ADMINISTRATIVE_CONTACT,
  EnumStrings.DRIVER_AND_TEAM_LEADER,
  EnumStrings.ADMINISTRATIVE_CONTACT_AND_CONFIRMED_BUYER
];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    status: Yup.string()
      .oneOf(statusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    title: Yup.string()
      .oneOf(titleOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    first_name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    last_name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    phone1: Yup.string()
      .min(1, t("minLengthError"))
      .max(15, t("max15LengthError"))
      .matches(/^[0-9\s\-+()]*$/, t("invalidPhoneNumberError"))
      .nullable()
      .notRequired(),
    phone2: Yup.string()
      .min(1, t("minLengthError"))
      .max(15, t("max15LengthError"))
      .matches(/^[0-9\s\-+()]*$/, t("invalidPhoneNumberError"))
      .nullable()
      .notRequired(),
    email: Yup.string()
      .min(1, t("minLengthError"))
      .max(150, t("max150LengthError"))
      .email(t("emailInvalidError"))
      .nullable()
      .notRequired(),
    personal_code: Yup.string()
      .min(1, t("minLengthError"))
      .max(30, t("max30LengthError"))
      .matches(/^[\d-\s]+$/, t("invalidPersonalCodeNumberError"))
      .nullable()
      .notRequired(),
    notes: Yup.string().nullable().notRequired(),
    is_key_person: Yup.boolean().required(),
    user: Yup.number().nullable().notRequired(),
    company: Yup.number().moreThan(0, t("requiredError")).required(t("requiredError"))
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    autoCompleteSelect: {
      marginBottom: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    },
    imageLabel: {
      marginTop: theme.spacing(3)
    }
  });

interface NewPersonProps
  extends FormikSubmitDispatchProps<PersonForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  person?: Person;
  companyOptions: SelectFieldOption[];
  customerobjectOptions: SelectFieldOption[];
  loadCustomerobjects: (companyId: number | null) => void;
  loadCompanies: (searchTerm?: string) => void;
}

const NewEditPerson: React.FC<NewPersonProps> = (props) => {
  const {
    classes,
    person,
    companyOptions,
    customerobjectOptions,
    loadCustomerobjects,
    loadCompanies,
    tabId
  } = props;
  const t = useTranslate("PersonPage");
  const t2 = useTranslate("Persons");
  const t3 = useTranslate("ValidationErrorMessages");
  const [images, setImages] = useState<ImageRow[]>([]);
  let initialValues: Person | PersonForm;
  if (person && (person as Person).id) {
    initialValues = {
      ...person
    };
  } else {
    initialValues = {
      id: -1,
      status: "ACTIVE",
      title: "CONTACT",
      first_name: "",
      last_name: "",
      phone1: "",
      phone2: "",
      email: "",
      personal_code: "",
      is_key_person: false,
      image: null,
      notes: "",
      user: null,
      company: null,
      customerobjects: []
    };
  }

  const handleFormSubmit = (
    values: PersonForm,
    actions: FormikHelpers<PersonForm>
  ) => {
    let checkedValues: any;
    const imageStr = images.length ? images[0].imageStr : null;

    if (!imageStr || (imageStr && imageStr.includes("base64"))) {
      checkedValues = { ...values, image: imageStr };
    } else {
      const { image, ...other } = values;
      checkedValues = other;
    }
    props.handleSubmit(checkedValues, actions);
  };

  const getImage = async () => {
    if (person && person.image) {
      const imageStr = await getImageByURL(person.image);
      setImages([
        {
          imageStr
        }
      ]);
    }
  };
  const getImageCb = useCallback(getImage, [person]);
  useEffect(() => {
    getImageCb();
  }, [person, getImageCb]);
  useEffect(() => {
    if (person) {
      store.dispatch(
        setTitle(`${person.first_name} ${person.last_name}`, tabId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/auth/persons${person ? `/${person.id}` : ""}?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {person ? t("editPersonHeading") : t("newPersonHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={handleFormSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                inputProps={{
                  "data-cy": `first-name-field-input`
                }}
                type="text"
                name="first_name"
                label={t("firstNameLabel")}
                placeholder={t("firstNameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                data-cy="last-name-field"
                type="text"
                name="last_name"
                label={t("lastNameLabel")}
                placeholder={t("lastNameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                data-cy="phone1-field"
                type="text"
                name="phone1"
                label={t("phone1Label")}
                placeholder={t("phone1Label")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                data-cy="phone2-field"
                type="text"
                name="phone2"
                label={t("phone2Label")}
                placeholder={t("phone2Label")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                data-cy="email-field"
                type="text"
                name="email"
                label={t("emailLabel")}
                placeholder={t("emailLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                data-cy="personal-code-field"
                type="text"
                name="personal_code"
                label={t("personalCodeLabel")}
                placeholder={t("personalCodeLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              {/* Status type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-person-form-status"
              >
                {t("statusLabel")}
              </InputLabel>
              <Field
                id="new-person-form-status"
                data-cy="status-field"
                name="status"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              {/* Title */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="person-form-title"
              >
                {t("titleLabel")}
              </InputLabel>
              <Field
                id="person-form-title"
                data-cy="person-title-field"
                name="title"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {titleOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              <Field
                data-cy="is-key-person-field"
                name="is_key_person"
                type="checkbox"
                Label={{ label: t("isKeyPersonLabel") }}
                component={CheckboxWithLabel}
              />

              <InputLabel className={classes.imageLabel}>
                {t("imageLabel")}
              </InputLabel>
              <ImageUploadComponent
                images={images}
                onImageUpdate={setImages}
                singleImageMode
              />

              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-person-company"
              >
                {t("companyLabel")}
              </InputLabel>
              <Field
                id="form-person-company"
                type="text"
                name="company"
                label={t("companyLabel")}
                placeholder={t("companyLabel")}
                value={
                  companyOptions.find(
                    (company) => props.values.company === company.value
                  )
                    ? companyOptions.find(
                        (company) => props.values.company === company.value
                      )
                    : null
                }
                options={companyOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                customHandleChange={loadCustomerobjects}
                loadOptions={loadCompanies}
              />
              <Collapse
                in={
                  props.values.company !== -1 &&
                  customerobjectOptions.length > 0
                }
              >
                <Field
                  type="text"
                  name="customerobjects"
                  placeholder={t("customerobjectsLabel")}
                  TextFieldProps={{
                    label: t("customerobjectsLabel"),
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true
                    }
                  }}
                  value={
                    props.values.customerobjects
                      ? (props.values.customerobjects.map((customerobject) =>
                          customerobjectOptions.find(
                            (customerobjectOption) =>
                              customerobject === customerobjectOption.value
                          )
                        ) as SelectFieldOption[])
                      : props.values.customerobjects
                  }
                  options={customerobjectOptions}
                  component={AutoCompleteSelectMulti}
                  margin="normal"
                  fullWidth
                  isMulti
                />
              </Collapse>

              <Field
                data-cy="notes-field"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                data-cy="form-submit-btn"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {person ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditPerson);
