import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { makeStyles, emphasize } from "@material-ui/core/styles";
import { Formik, Field, FormikHelpers } from "formik";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  Dialog,
  IconButton,
  Tooltip,
  Grid,
  InputLabel
} from "@material-ui/core";
import Select from "react-select";
import { Close } from "@material-ui/icons";
import { TextField } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  CopyContractForm,
  SelectFieldOption,
  Contract
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { components } from "../../AutoCompleteSelect";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { dashDateFormat, formatDashDate } from "../../FormatHelpers";

const customStyles = {
  menuList: (base: any) => ({
    ...base,
    height: "90%",
    minHeight: 150
  })
};
const useStyles = makeStyles((theme: any) =>
  createStyles({
    input: {
      display: "flex",
      padding: 7,
      height: "auto"
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden"
    },
    chip: {
      margin: theme.spacing(0.5, 0.25)
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      )
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2)
    },
    singleValue: {
      fontSize: 14
    },
    placeholder: {
      position: "absolute",
      left: 10,
      bottom: 14,
      fontSize: 14
    },
    paper: {
      position: "absolute",
      zIndex: 100,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      minWidth: 280,
      height: "90%",
      minHeight: 150,
      wordWrap: "break-word"
    },
    select: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0)
    }
  })
);

const styles = (theme: any) =>
  createStyles({
    dialogPaper: {
      width: "600px",
      padding: theme.spacing(3.5)
    },
    heading: {
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize"
    },
    submitButton: {
      marginTop: theme.spacing(2.5),
      marginLeft: theme.spacing(1.25)
    },
    priceUnitRow: {
      display: "flex",
      alignItems: "baseline"
    },
    unit: {
      fontSize: 18,
      margin: `0 ${theme.spacing(1)}px`
    },
    modalHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    selectLabel: { marginTop: theme.spacing(2.5) },
    addonContainer: { marginTop: theme.spacing(1.5) },
    rightContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    headingContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    fcLabel: { marginRight: 0 },
    font12: { fontSize: "12px" },
    eventDatesHeading: {
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      fontWeight: "bold"
    },
    addBtn: {
      padding: theme.spacing(0.5),
      transition: "0.5s all ease",
      "&:hover": { color: "#28a745" }
    },
    list: {
      maxHeight: "300px",
      overflowY: "scroll"
    },
    dateList: {
      display: "flex",
      alignItems: "center"
    },
    dateOptions: {
      display: "flex",
      alignItems: "flex-end"
    },
    dateOptionDel: {
      color: "#757575",
      transition: "0.5s all ease",
      "&:hover": {
        color: "#f24c57"
      }
    },
    btnContainer: {
      display: "flex",
      justifyContent: "right",
    },
    indexBtnContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    terms: { minHeight: "150px" },
    hide: { display: "none" },
    formControl: { margin: `${theme.spacing(1.5)}px 0` },
    lockIcon: { marginLeft: theme.spacing(1) },
    clickable: { pointerEvents: "all" },
    nonClickable: { pointerEvents: "none" },
    fieldCell: { marginTop: "10px" }
  });

interface EditDialogProps
  extends FormikSubmitDispatchProps<CopyContractForm>,
    WithStyles<typeof styles> {
  contractId: number;
  open: boolean;
  contractName?: string;
  contract?: Contract;
  handleDialog: () => void;
  companies: SelectFieldOption[];
  totalCompanyCount: number;
  loadCompanies: (page: number, pageSize: number, searchStr?: string) => void;
}

const CopyContractDialog: React.FC<EditDialogProps> = (props) => {
  const {
    classes,
    open,
    contractId,
    handleDialog,
    contractName,
    loadCompanies,
    companies,
    contract,
  } = props;

  const now = new Date();
  const endDate = new Date();
  endDate.setFullYear(new Date().getFullYear() + 1);

  let initialValues = {
    id: contractId,
    name: `Copy ${contractName}`,
    company_id: -1,
    valid_from: formatDashDate(now.toString()),
    valid_to: formatDashDate(
      contract &&
        contract.valid_to !== null &&
        new Date(contract.valid_to) >= now
        ? contract.valid_to
        : endDate.toString()
    ),
    price_factors_by_type: {
      hourly_price_factor: 0,
      event_price_factor: 0,
      unit_price_factor: 0
    },
    price_factors: {
      materials_price_factor: 0,
      accessories_price_factor: 0,
      supplements_price_factor: 0,
      addons_price_factor: 0,
      services_price_factor: 0,
      servicepackages_price_factor: 0
    }
  };

  const selectClasses = useStyles();
  const [copyContractFormData, setCopyContractFormData] =
    useState<CopyContractForm>(initialValues);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const t = useTranslate("ContractPage");

  const handleSubmit = (
    values: CopyContractForm,
    actions: FormikHelpers<CopyContractForm>
  ) => {
    props.handleSubmit(values, actions);
    handleDialog();
  };

  const handleDropDownChange = (
    option: SelectFieldOption,
    setFieldValue: (fieldName: string, value: number) => void,
    fieldName: string
  ) => {
    setFieldValue(fieldName, option.value);
  };

  const handleDateFieldChange = (
    _value: Date,
    setFieldValue: (fieldName: string, value: string) => void,
    fieldName: string
  ) => {
    if (_value) {
      if (_value.toString() !== "Invalid Date") {
        if (fieldName === "valid_from") {
          setStartDateError(false);
        } else {
          setEndDateError(false);
        }
        let value = formatDashDate(_value.toString());
        setFieldValue(fieldName, value);
      } else {
        if (fieldName === "valid_from") {
          setStartDateError(true);
        } else {
          setEndDateError(true);
        }
      }
    }
  };

  useEffect(() => {
    loadCompanies(1, 10000);
  }, [loadCompanies]);

  if (!companies.length) {
    return null
  }

  return (
    <div>
      <Dialog onClose={handleDialog} aria-labelledby="edit-dialog" open={open}>
        <Paper className={classes.dialogPaper}>
          <div className={classes.modalHeader}>
            <Typography component="h1" variant="h5">
              {t("copyContractHeading")}
            </Typography>
            <Tooltip title="Close" placement="left" arrow>
              <IconButton
                onClick={() => {
                  setCopyContractFormData(initialValues);
                  handleDialog();
                }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </div>
          <Formik
            initialValues={copyContractFormData}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <Field
                  id="form-contract-name"
                  type="text"
                  name="name"
                  label={t("nameLabel")}
                  placeholder={t("nameLabel")}
                  component={TextField}
                  margin="normal"
                  fullWidth
                />
                <Grid container spacing={2} direction="row">
                  <Grid item xs={6} className={classes.fieldCell}>
                    <InputLabel>{t("companyLabel")}</InputLabel>
                    <Select
                      id="form-company"
                      type="text"
                      label={t("companyLabel")}
                      options={companies}
                      components={components}
                      classes={selectClasses}
                      onChange={(e: any) =>
                        handleDropDownChange(
                          e,
                          props.setFieldValue,
                          "company_id"
                        )
                      }
                      styles={customStyles}
                      defaultValue={companies.filter(
                        (c) => c.value === contract?.company
                      )}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.fieldCell}>
                    <InputLabel>{t("companyTypeLabel")}</InputLabel>
                    <Typography className={classes.fieldCell}>
                      {contract?.company_type}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={6}>
                    <Field
                      data-cy={`valid_from`}
                      label={t("startDateLabel")}
                      placeholder={"20YY-MM-DD"}
                      value={props.values.valid_from}
                      onChange={(_val: Date) =>
                        handleDateFieldChange(
                          _val,
                          props.setFieldValue,
                          "valid_from"
                        )
                      }
                      component={KeyboardDatePicker}
                      format={dashDateFormat}
                      margin="normal"
                      fullWidth
                      error={startDateError}
                      helperText={startDateError ? "Wrong Date" : undefined}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      data-cy={`valid_to`}
                      label={t("endDateLabel")}
                      placeholder={"20YY-MM-DD"}
                      value={props.values.valid_to}
                      onChange={(_val: Date) =>
                        handleDateFieldChange(
                          _val,
                          props.setFieldValue,
                          "valid_to"
                        )
                      }
                      component={KeyboardDatePicker}
                      format={dashDateFormat}
                      margin="normal"
                      fullWidth
                      error={endDateError}
                      helperText={endDateError ? "Wrong Date" : undefined}
                    />
                  </Grid>
                </Grid>
                <div className={classes.btnContainer}>
                  <div>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDialog()}
                      className={classes.submitButton}
                    >
                      {t("cancelLabel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                    >
                      {t("submitButtonLabel")}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Paper>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(CopyContractDialog);
