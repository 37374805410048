import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import ImageIcon from "@material-ui/icons/Image";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { CircularProgress, IconButton } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IRowImage } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    dialogData: {
      textAlign: "center",
      position: "relative",
      background: "#424242",
      height: "100%"
    },
    dialogImg: {
      maxWidth: "100%",
      maxHeight: "100%",
      maxInlineSize: "100%",
      blockSize: "auto"
    },
    imageIconDiv: {},
    imageIcon: {
      color: "#fff",
      fontSize: "50px"
    },
    radio: {
      left: 0,
      right: 0,
      bottom: "10px",
      overflow: "scroll",
      textAlign: "center",
      position: "absolute",
      borderRadius: theme.spacing(0.5),
      justifyContent: "start !important",
      margin: `0 ${theme.spacing(2.5)}px`,
      background: "rgba(255, 255, 255, 0.5)"
    },
    container: {
      flex: 1,
      display: "flex",
      justifyContent: "center"
    },
    actionIcons: {
      top: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      position: "absolute"
    },
    flexDiv: {
      display: "flex",
      height: "100%",
      justifyContent: "space-between",
      alignItems: "center"
    },
    iconBtn: {
      margin: `0 ${theme.spacing(2)}px`,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      "&:hover": {
        backgroundColor: "#fff"
      }
    },
    thinDivider: {
      width: "1px",
      height: "30px",
      marginTop: "6px",
      background: "#424242"
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    flexRadio: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    imgDesc: {
      zIndex: 1,
      top: "25px",
      left: "-4px",
      color: "#fff",
      position: "absolute",
      borderRadius: theme.spacing(0.5),
      background: "rgba(0, 0, 0, 0.6)",
      padding: `${theme.spacing(1.25)}px ${theme.spacing(3)}px`,
      "&:hover": {
        background: "rgb(0, 0, 0)"
      }
    },
    notesDiv: { top: "70px" },
    thickDivider: { width: "3px" }
  })
);
interface ImageSliderProps {
  defaultIndex: number;
  allImages: IRowImage[];
  populateImageDetails: (img: IRowImage) => Promise<IRowImage>;
  onImageChange: (img: IRowImage) => void;
}
const scrollTo = (slideIndex: number) => {
  const id = `slide-${slideIndex}`;
  const element = document.getElementById(id);
  // const htmlElement = document.getElementById(id);
  if (element) {
    // element.scrollIntoView({ behavior: "smooth", block: "end" });
    // setTimeout(
    //   () =>
    //     htmlElement.scrollBy({
    //       top: 16,
    //       behavior: "smooth"
    //     }),
    //   1000
    // );
  }
};
const ImageSlider: React.FC<ImageSliderProps> = (props) => {
  const classes = useStyles();
  const { allImages, defaultIndex, onImageChange, populateImageDetails } =
    props;
  const t = useTranslate("TimesheetApprovalPage");
  const [imgDetails, setImgDetails] = useState<IRowImage>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const next = () => {
    setCurrentIndex((idx) => (idx + 1) % allImages.length);
  };
  const previous = () => {
    setCurrentIndex((idx) => (allImages.length + (idx - 1)) % allImages.length);
  };
  const renderRadioButtons = (img: IRowImage, index: number) => {
    return (
      <div key={index} className={classes.flexRadio} id={`slide-${index}`}>
        <Radio
          value={img}
          color="primary"
          onChange={() => setCurrentIndex(index)}
          checked={index === currentIndex}
        />
        {allImages.length > 1 && index !== allImages.length - 1 && (
          <div className={clsx(classes.thinDivider)}></div>
        )}
      </div>
    );
  };
  const changeImage = async () => {
    setIsLoading(true);
    let img = allImages[currentIndex];
    if (img && !img.imageStr) {
      try {
        img = await populateImageDetails(img);
      } catch (e) {}
    }
    onImageChange(img);
    setImgDetails(img);
    setIsLoading(false);
    if (currentIndex !== -1) {
      scrollTo(currentIndex);
    }
  };
  const changeImgCb = useCallback(
    changeImage,
    // eslint-disable-next-line
    [
      allImages,
      currentIndex,
      onImageChange,
      populateImageDetails,
      setImgDetails
    ]
  );
  useEffect(() => {
    changeImgCb();
  }, [changeImgCb]);
  useEffect(() => {
    setCurrentIndex(defaultIndex);
  }, [defaultIndex, allImages]);

  return (
    <>
      <div className={clsx(classes.dialogData, classes.flexCenter)}>
        {isLoading ? (
          <CircularProgress className={classes.imageIcon} />
        ) : imgDetails?.imageStr ? (
          <img className={classes.dialogImg} src={imgDetails.imageStr} alt="" />
        ) : (
          <div className={clsx(classes.imageIconDiv, classes.flexCenter)}>
            <ImageIcon className={classes.imageIcon} />
          </div>
        )}
      </div>
      <div className={classes.actionIcons}>
        {imgDetails?.title && (
          <div className={classes.imgDesc}>
            {t("titleHeading")}: <span>{imgDetails.title}</span>
          </div>
        )}
        {allImages.length > 1 && (
          <>
            <div className={classes.flexDiv}>
              <IconButton
                color="primary"
                onClick={previous}
                className={classes.iconBtn}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={next}
                color="primary"
                className={classes.iconBtn}
              >
                <ChevronRight />
              </IconButton>
            </div>

            <div className={clsx(classes.radio, classes.flexRadio)}>
              <div className={classes.container}>
                {allImages.length && allImages.map(renderRadioButtons)}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ImageSlider;
