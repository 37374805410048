/**
 * CONSTANTS
 */

export const APP_DATE_FORMAT: string = "yyyy-MM-dd";
export const APP_TIME_FORMAT_EN: string = "hh:mm A";
export const APP_TIME_FORMAT_SE: string = "HH:mm";
export const APP_DATE_TIME_FORMAT_EN = "yyyy-MM-dd hh:mm A";
export const APP_DATE_TIME_FORMAT_SE = "yyyy-MM-dd HH:mm";

/**
 * Send date and time for the respective parameters only
 * @param date {String}
 * @param time  {Stirng} Send time only
 */
export const mergeDateAndTime = (date: string, time: string): string => {
  const dateDate = new Date(date);
  const timeDate = new Date(time);
  const pureDate = new Date(dateDate.toDateString());
  const pureTime = new Date(
    timeDate.getTime() - new Date(timeDate.toDateString()).getTime()
  );

  return new Date(pureDate.getTime() + pureTime.getTime()).toISOString();
};

export function breakObjectReference<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj));
}
