import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Paper, CircularProgress, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3)
    },
    spinner: {
      margin: theme.spacing(3)
    }
  })
);

const LoadingSpinnerPaper = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={3}>
          <CircularProgress className={classes.spinner} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoadingSpinnerPaper;
