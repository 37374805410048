export const NavItemClassEnum = {
  DASHBOARD: "navbar-main-dashboard",
  ORDERS: "navbar-routes-orders",
  LIVEVIEW: "navbar-live-view",
  WORKLOG: "navbar-work-log",
  AFTERREGISTRATEWORK: "navbar-after-registrations",
  WORKVERIFICATION: "navbar-work-verification",
  HISTORY: "navbar-work-history",
  REPORTS: "navbar-reports",
  CONTRACTORREPORTS: "navbar-contractor-reports",
  INVOICES: "navbar-invoice-administration",
  EMAILS: "navbar-emails",
  COMPANIES: "navbar-companies-companies",
  CUSTOMEROBJECTS: "navbar-contracts-customerobjects",
  CONTRACTS: "navbar-contracts-contracts",
  SERVICESANDPRODUCTS: "navbar-products",
  PERSONS: "navbar-persons",
  PASSCARDS: "navbar-companies-passcards",
  MATERIALDEPOTS: "navbar-companies-material-depots",
  DISTRICTS: "navbar-companies-districts",
  WEATHERAREAS: "navbar-companies-weather-areas",
  HOLIDAYS: "navbar-options-holidays",
  SYSTEMHEALTH: "navbar-options-system-health",
  USERHISTORY: "navbar-options-user-history",
  IMAGEDATABASE: "navbar-options-images",
  INVOICINGPROFILES: "navbar-invoices-invoicing-profiles",
  CONTRACTINGPROFILES: "navbar-contracts-contracting-profiles",
  TEMPLATEIMAGES: "navbar-services-template-images",
  NOTIFICATIONPROFILES: "navbar-notificaton-profiles",
  EVENTS: "navbar-events"
};

export const InvoicesSubIndexesEnum = {
  CUSTOMER: 0,
  CONTRACTOR: 1
};
