import {
  TimeSheet,
  ImageRow,
  GetRowImagesQuery,
  TimesheetRowImage
} from "../../redux/types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getRowImages } from "../../services/timesheetService";
import { getImageByURL } from "../../services/imageHelperService";

const useTimesheetRowImages = (
  rowId: number | undefined | "",
  timesheet: TimeSheet | null,
  defaultImages: ImageRow[] = []
): [ImageRow[], Dispatch<SetStateAction<ImageRow[]>>] => {
  const [images, setImages] = useState<ImageRow[]>(defaultImages);
  const loadImages = async () => {
    if (rowId) {
      const filters: GetRowImagesQuery = {
        filter__timesheet_id: timesheet !== null ? timesheet.id : undefined,
        filter__row_id: rowId
      };
      const images = await getRowImages(filters);
      const arr: ImageRow[] = [];
      images.forEach(async (img: TimesheetRowImage) => {
        if (img.image != null) {
          const currentImage: ImageRow = {
            imageStr: await getImageByURL(img.image),
            id: img.id,
            notes: img.notes || undefined,
            newNotes: img.notes || undefined,
            user: img.user
          };
          arr.push(currentImage);
        }
      });
      setImages(arr);
    }
  };
  const reloadImages = () => {
    if (rowId !== undefined && !isNaN(Number(rowId))) {
      loadImages();
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reloadImages, [rowId]);
  return [images, setImages];
};

export default useTimesheetRowImages;
