import React, { useEffect } from "react";
import { WithStyles, Paper, Typography, Button } from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { TabComponentProps } from "../../redux/types";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20
    },
    container: {
      display: "flex",
      gap: theme.spacing(4),
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(4)
    },
    button: {
      marginTop: theme.spacing(4)
    }
  });

interface ErrorPageProps extends TabComponentProps, WithStyles<typeof styles> {}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const t = useTranslate("TabRouter");
  const u = useTranslate("RouteLabels");
  const { classes, actualPath, tabId } = props;

  useEffect(() => {
    store.dispatch(setTitle(actualPath, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualPath]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.container}>
        <Typography variant="h3">:(</Typography>
        <div>
          <Typography variant="h6">{t("notFoundTitle")}</Typography>
          <Typography variant="body2">{t("notFoundMessage")}</Typography>
          <Button
            variant="contained"
            color="primary"
            href="#/"
            className={classes.button}
          >
            {u("dashboardLabel")}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(ErrorPage);
