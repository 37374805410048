import React, { useState, useCallback, useEffect } from "react";
import store, { history } from "../../redux/store";
import { useTranslate } from "../../services/appLanguageService";
import {
  getNotificationProfileAPI,
  deleteNotificationSettingAPI,
  getNotificationSettingsByProfileIdAPI
} from "../../services/api-declaration";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import {
  INotificationProfile,
  INotificationSettingDisplay,
  TabComponentProps
} from "../../redux/types";
import {
  useConfirmationDialog,
  ConfirmationDialogServiceProvider
} from "../confirmationDialog/ConfirmationDialogServiceProvider";
import {
  makeStyles,
  createStyles,
  Button,
  Box,
  Paper
} from "@material-ui/core";
import {
  NavigateBefore as NavigateBeforeIcon,
  Add as AddIcon,
  Edit as EditIcon
} from "@material-ui/icons";
import NotificationSettingsTable from "./NotificationSettingsTable";
import DeleteButtonContainer from "../../containers/DeleteButtonContainer";
import { ReduxActionStrings } from "../../redux/strings";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface NotificationSettingsViewProps extends TabComponentProps<"id"> {}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    navButtonContainer: {
      marginBottom: theme.spacing(2.5),
      display: "inline-block"
    },
    navButton: {
      marginRight: theme.spacing(1)
    },
    tableContainer: {
      marginRight: theme.spacing(2),
      height: "100%",
      marginBottom: theme.spacing(2)
    },
    tablePaper: {
      width: "100%",
      height: "100%"
    },
    marginRightIcon: {
      marginRight: 5
    },
    screen: {
      height: "calc(100vh - 170px)"
    },
    subScreen: {
      height: "100%"
    },
    editIcon: {
      fontSize: 16,
      marginRight: 5
    }
  })
);

const NotificationSettingsView: React.FC<NotificationSettingsViewProps> = (
  props
) => {
  const {
    routeParams: { id: notificationProfileId },
    tabId
  } = props;
  const t = useTranslate("NotificationSettingsPage");
  const classes = useStyles();

  const [notificationProfile, setNotificationProfile] =
    useState<INotificationProfile>();
  const [notificationSettings, setNotificationSettings] = useState<
    INotificationSettingDisplay[]
  >([]);

  const confirm = useConfirmationDialog();

  const initNotificationSettings = async () => {
    try {
      const response: INotificationProfile = await getNotificationProfileAPI(
        notificationProfileId
      );
      setNotificationProfile(response);
      try {
        const currentNotificationSettings =
          await getNotificationSettingsByProfileIdAPI(notificationProfileId);
        setNotificationSettings(currentNotificationSettings.results);
      } catch (err) {
        console.warn(err);
      }
    } catch (e: any) {
      showGlobalSnackbar(
        e.response_data && e.response_data.detail
          ? e.response_data.detail
          : e.message,
        "error"
      );
    }
  };

  const goBack = () => {
    history.replace("/notification-profiles?same_tab=true");
  };

  const addNotificationSetting = (notificationProfileId: number) => () => {
    history.replace(`/notification-profiles/${notificationProfileId}/save`);
  };

  const saveNotificationSetting =
    (
      notificationProfileId: number,
      notificationSetting: INotificationSettingDisplay
    ) =>
    () => {
      history.replace(
        `/notification-profiles/${notificationProfileId}/save` +
          (notificationSetting.id !== undefined
            ? `/${notificationSetting.id}/`
            : "")
      );
    };

  const deleteRow =
    (notificationSetting: INotificationSettingDisplay) => async () => {
      if (!notificationProfile) return;
      try {
        await confirm({
          title: t("confirmationHeading"),
          description: `${t("deleteConfirmationText")} ${
            notificationSetting.id
          }`
        });
        await deleteNotificationSettingAPI(notificationSetting.id);
        initNotificationSettings();
      } catch (e) {
        console.warn(e);
      }
    };

  const navigateToEdit = (id: number) => () => {
    history.replace(`/notification-profiles/${id}/edit`);
  };

  const onRowUpdate = (nSettings: INotificationSettingDisplay[]) => {
    setNotificationSettings(nSettings);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const init = useCallback(initNotificationSettings, []);

  useEffect(() => {
    init();
  }, [init]);
  useEffect(() => {
    if (notificationProfile) {
      store.dispatch(setTitle(notificationProfile.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationProfile]);

  return (
    <div className={classes.root}>
      <div className={classes.navButtonContainer}>
        <Button
          variant="contained"
          data-cy="notification-navigate-prev"
          color="primary"
          centerRipple
          onClick={goBack}
          className={classes.navButton}
        >
          <NavigateBeforeIcon /> {t("navigateBeforeButtonText")}
        </Button>
        {notificationProfile && [
          <Button
            centerRipple
            className={classes.navButton}
            color="primary"
            data-cy="notification-setting-add"
            key={0}
            onClick={addNotificationSetting(notificationProfile.id)}
            variant="contained"
          >
            <AddIcon className={classes.marginRightIcon} />
            {t("addNotificationSettingLabel")}
          </Button>,
          <Button
            centerRipple
            className={classes.navButton}
            color="primary"
            data-cy="notification-setting-edit"
            key={1}
            onClick={navigateToEdit(notificationProfile.id)}
            variant="contained"
          >
            <EditIcon className={classes.editIcon} />{" "}
            {t("editNotificationProfileLabel")}
          </Button>,
          <DeleteButtonContainer
            key={2}
            resourceId={notificationProfile.id}
            resource={ReduxActionStrings.NOTIFICATIONSPROFILE}
            url={"services/notificationsprofiles"}
            customRedirect={"notification-profiles"}
            {...mapTabRouteProps(props)}
          />
        ]}
      </div>
      <Box className={classes.tableContainer} width="100%">
        {notificationProfile && (
          <Paper className={`${classes.tablePaper}`}>
            <NotificationSettingsTable
              dataSource={notificationSettings}
              deleteNotificationSetting={deleteRow}
              onRowUpdate={onRowUpdate}
              saveNotificationSetting={saveNotificationSetting}
              notificationProfileId={notificationProfile.id}
            />
          </Paper>
        )}
      </Box>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: NotificationSettingsViewProps) => (
  <ConfirmationDialogServiceProvider>
    <NotificationSettingsView {...props} />
  </ConfirmationDialogServiceProvider>
);
