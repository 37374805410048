import React, { useEffect, useState, useRef } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, TextField, Grid } from "@material-ui/core";
import AutocompletePlaces from "./AutocompletePlaces";
import { ensureMapScript } from "../../contracts/tasks/maps/MapHelpers";
import {
  District,
  Feature,
  LocationForm,
  LocationType,
  SelectFieldOption
} from "../../../redux/types";
import { Autocomplete } from "@material-ui/lab";
import { FormikProps } from "formik";
import { getDistrictsAPI } from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";

const styles = (theme: any) =>
  createStyles({
    container: {
      marginTop: 16
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    searchBar: {
      marginBottom: 20
    },
    lowercase: {
      textTransform: "none",
      marginBottom: 2
    }
  });

interface MapProps extends WithStyles<typeof styles> {
  location: LocationType | LocationForm | null;
  secondLocation?: LocationType | LocationForm | null;
  editable: boolean;
  formikProps?: FormikProps<any>;
  fieldName?: string;
  mapHeight?: number;
}

const Map: React.FC<MapProps> = (props) => {
  const {
    classes,
    location,
    secondLocation,
    formikProps,
    editable,
    fieldName
  } = props;

  const [mapInstance, setMapInstance] = useState<google.maps.Map | undefined>(
    undefined
  );
  const mapDiv = useRef<HTMLDivElement>(null);
  const [districts, setDistricts] = useState<District[]>([]);
  const [districtValue, setDistrictValue] = useState<SelectFieldOption>({
    label: "",
    value: 0
  });
  const [addresseeValue, setAddresseeValue] = useState<string>("");
  const t = useTranslate("LocationComponent");

  const initMap = () => {
    ensureMapScript()
      .then(createGoogleMap)
      .catch((error) => console.log("Error in initializing map: ", error));
  };

  const createGoogleMap = () => {
    if (window.google && mapDiv.current !== null) {
      const googleMap: google.maps.Map = new window.google.maps.Map(
        mapDiv.current,
        {
          zoom: 14,
          center: {
            lat: 57.7,
            lng: 11.96
          }
        }
      );

      setMapInstance(googleMap);
    }
  };

  /**
   * Load the geographic objects from the database (stored as geojson) into the map
   *
   */
  const loadPosition = () => {
    if (mapInstance && location && location.latitude && location.longitude) {
      mapInstance.data.forEach((feature) => {
        mapInstance.data.remove(feature);
      });

      const latLng = new window.google.maps.LatLng(
        location.latitude,
        location.longitude
      );
      const pointFeature = new window.google.maps.Data.Feature({
        geometry: new window.google.maps.Data.Point(latLng)
      });
      mapInstance.data.overrideStyle(pointFeature, {
        icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
      });

      mapInstance.setCenter(latLng);
      mapInstance.data.add(pointFeature);

      if (
        secondLocation &&
        secondLocation.latitude > 0.0 &&
        secondLocation.longitude > 0.0
      ) {
        const secondLatLng = new window.google.maps.LatLng(
          secondLocation.latitude,
          secondLocation.longitude
        );

        const secondPointFeature = new window.google.maps.Data.Feature({
          geometry: new window.google.maps.Data.Point(secondLatLng)
        });
        mapInstance.data.overrideStyle(secondPointFeature, {
          icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
        });
        mapInstance.data.add(secondPointFeature);
        mapInstance.fitBounds(
          new google.maps.LatLngBounds(latLng).extend(secondLatLng)
        );
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initMap, [window.google]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadPosition, [mapInstance, location]);

  const suggestDistrict = (
    latitude: number,
    longitude: number
  ): number | null => {
    if (districts.length > 0) {
      const latLng = new window.google.maps.LatLng(latitude, longitude);

      for (const district of districts) {
        if (district.geo_polygons) {
          const features = district.geo_polygons.features;

          for (const feature of features) {
            if (feature.geometry && feature.geometry.type === "Polygon") {
              const polygon = createPolygonFromFeature(feature);

              if (
                window.google.maps.geometry.poly.containsLocation(
                  latLng,
                  polygon
                )
              ) {
                return district.id;
              }
            }
          }
        }
      }
    }
    return null;
  };

  const createPolygonFromFeature = (feature: Feature) => {
    const coordinates = feature.geometry.coordinates;

    if (coordinates.length > 1) {
      let pathArray: google.maps.LatLng[][] = [];

      coordinates.forEach((path) => {
        const latLngpath = path.map(
          (coordinate) =>
            new window.google.maps.LatLng(coordinate[1], coordinate[0])
        );
        pathArray.push(latLngpath.slice(0, -1));
      });

      return new window.google.maps.Polygon({
        paths: pathArray
      });
    } else {
      let latLngArray: google.maps.LatLng[] = [];

      coordinates[0].forEach((coordinate) =>
        latLngArray.push(
          new window.google.maps.LatLng(coordinate[1], coordinate[0])
        )
      );

      return new window.google.maps.Polygon({
        paths: latLngArray.slice(0, -1)
      });
    }
  };

  useEffect(() => {
    const loadDistricts = async () => {
      const response = await getDistrictsAPI();
      setDistricts(response.results);
    };

    loadDistricts();
  }, []);

  useEffect(() => {
    if (formikProps) {
      const districtId = formikProps.values[fieldName || "location"]
        ? formikProps.values[fieldName || "location"].district
        : null;
      const district = districts.find((district) => district.id === districtId);

      if (district) {
        setDistrictValue({ value: district.id, label: district.name });
      }

      if (formikProps.values[fieldName || "location"]?.addressee) {
        setAddresseeValue(
          formikProps.values[fieldName || "location"].addressee
        );
      }
    }
  }, [districts, formikProps, fieldName]);

  return (
    <>
      {editable && (
        <div className={classes.searchBar}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={5}>
              <AutocompletePlaces
                location={location}
                formikProps={formikProps}
                fieldName={fieldName}
                suggestDistrict={suggestDistrict}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {districts.length > 0 && location && formikProps && (
                <Autocomplete
                  id="districts-select"
                  value={districtValue}
                  onChange={(
                    event: any,
                    newDistrict: SelectFieldOption | null
                  ) => {
                    if (formikProps) {
                      formikProps.setFieldValue(fieldName || "location", {
                        ...formikProps.values[fieldName || "location"],
                        district: newDistrict ? newDistrict.value : null
                      });

                      if (newDistrict) {
                        setDistrictValue(newDistrict);
                      }
                    }
                  }}
                  options={districts.map((district) => ({
                    label: district.name,
                    value: district.id
                  }))}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("districtLabel")}
                      variant="outlined"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              {location && formikProps && (
                <TextField
                  fullWidth
                  label={t("addresseeLabel")}
                  variant="outlined"
                  value={addresseeValue}
                  onBlur={() =>
                    formikProps.setFieldValue(fieldName || "location", {
                      ...formikProps.values[fieldName || "location"],
                      addressee: addresseeValue
                    })
                  }
                  onChange={(event: { target: { value: string } }) =>
                    setAddresseeValue(event.target.value)
                  }
                />
              )}
            </Grid>
          </Grid>
        </div>
      )}
      <div
        ref={mapDiv}
        style={{ width: "100%", height: `${props.mapHeight || 400}px` }}
      />
    </>
  );
};

export default withStyles(styles)(Map);
