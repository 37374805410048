import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Avatar
} from "@material-ui/core";
import * as types from "../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../FormatHelpers";
import PermissionsContainer from "../../containers/groups/PermissionsContainer";
import GroupsContainer from "../../containers/groups/GroupsContainer";
import { useTranslate } from "../../services/appLanguageService";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../redux/types";
import { mapTabRouteProps } from "../../helpers/routesHelper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    large: {
      width: theme.spacing(9),
      height: theme.spacing(9)
    }
  });

interface GroupProps extends WithStyles<typeof styles>, TabComponentProps {
  group: types.Group;
}

const Group: React.FC<GroupProps> = (props) => {
  const { classes, group, tabId } = props;
  const t = useTranslate("GroupPage");
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    store.dispatch(setTitle(group.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            {group.avatar && (
              <Avatar src={group.avatar} className={classes.large} />
            )}
            <List>
              <ListItem>
                <ListItemText primary={t("nameLabel")} secondary={group.name} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t("editableLabel")}
                  secondary={group.editable ? t("yesLabel") : t("noLabel")}
                />
              </ListItem>
            </List>
          </Paper>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  <ListItemText
                    primary={t("notesLabel")}
                    secondary={group.notes}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("createdAtLabel")}
                    secondary={niceDate(group.created_at)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("updatedAtLabel")}
                    secondary={niceDate(group.updated_at)}
                  />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          {group.sub_groups ? (
            <GroupsContainer
              customHeader={t("subGroupHeader")}
              showNewButton={false}
              groupIds={group.sub_groups}
              {...mapTabRouteProps(props)}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <PermissionsContainer
            editable={group.editable}
            permissionIds={group.permissions}
            permissionsHolder={group}
            {...mapTabRouteProps(props)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Group);
