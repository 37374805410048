import { FC, useState } from "react";
import {
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Table,
  TableRow,
  TableCell,
  IconButton,
  Slide,
  Paper,
  TableContainer,
  Typography
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  IApprovalDictionaries,
  IApprovalSelectionStats,
  IApprovalSubmittedRow,
  IApprovalSummaryDialogData
} from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import clsx from "clsx";
import { pink } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import TimesheetApprovals from "./TimesheetApprovals";
import { formatDate, getTimeFromDate } from "../FormatHelpers";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    verticalDivider: {
      width: 1,
      margin: "0 15px",
      background: "#cccccc",
      height: theme.spacing(4)
    },
    secondaryHeading: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(15)
    },
    flexView: {
      flex: 1,
      display: "flex",
      alignItems: "center"
    },
    closeIcon: {
      color: pink[500],
      position: "absolute",
      right: 0,
      top: 0
    },
    tableRowAlign: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      "& > *": {
        height: "60px"
      }
    },
    bold: {
      fontWeight: "bold"
    },
    slideOverlay: {
      top: 0,
      right: 0,
      zIndex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      backgroundColor: "rgba(117, 117, 117, 0.4)"
    },
    flexBox: {
      display: "flex",
      alignItems: "center"
    },
    col: {
      display: "flex",
      flexDirection: "column",
      width: "80%",
      justifyContent: "center",
      alignItems: "center",
      height: "inherit"
    },
    summaryTitle: { fontWeight: "bolder" },
    alignCenter: { alignItems: "center" },
    slideContainer: { width: "50%", marginTop: "10px" },
    spaceBtw: {
      justifyContent: "space-between",
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5)
    },
    over: { overflow: "hidden" }
  })
);
interface TimesheetApprovalSummaryProps {
  data: IApprovalSummaryDialogData;
  selectionStats: IApprovalSelectionStats | undefined;
  closeDialog: () => void;
  dictionaries: IApprovalDictionaries;
  verifySummary: () => Promise<void>;
  handleVerificationStatus: (verifying: boolean) => void;
  businessAreaNames: Map<number, string>;
}
const TimesheetApprovalsSummary: FC<TimesheetApprovalSummaryProps> = (
  props
) => {
  const classes = useStyles();
  const {
    data: { approvalSummaries, approveTime },
    closeDialog,
    dictionaries,
    selectionStats,
    verifySummary,
    handleVerificationStatus,
    businessAreaNames
  } = props;
  const t = useTranslate("TimesheetApprovalPage");
  const v = useTranslate("TimeSheetRowsAndApprovals");
  const s = useTranslate("TimeSheetRowsAndApprovalsSummary");

  const [activeRow, setActiveRow] = useState(false);
  const [selectedRow, setSelectedRow] = useState<IApprovalSubmittedRow>();

  const toggleSummaryRowDetails = (row: IApprovalSubmittedRow) => {
    setSelectedRow(row);
    setActiveRow(true);
  };
  return (
    <>
      <div className={activeRow ? classes.over : ""}>
        <DialogTitle id="alert-dialog-title" className={classes.summaryTitle}>
          {"Summary"}
        </DialogTitle>
        <DialogContent>
          <Slide direction="left" in={activeRow} mountOnEnter unmountOnExit>
            <div className={clsx(classes.slideOverlay)}>
              {selectedRow && (
                <div className={classes.col}>
                  <TableContainer
                    component={Paper}
                    className={classes.slideContainer}
                  >
                    <Table
                      style={{ display: "flex" }}
                      aria-label="simple table"
                    >
                      <TableRow className={classes.tableRowAlign}>
                        <TableCell className={classes.bold} align="left">
                          {s("statusLabel")}
                        </TableCell>
                        <TableCell className={classes.bold} align="left">
                          {s("AssignmentLabel")}
                        </TableCell>
                        <TableCell className={classes.bold} align="left">
                          {s("contractorLabel")}
                        </TableCell>
                        <TableCell className={classes.bold} align="left">
                          {s("registeredByLabel")}
                        </TableCell>
                        <TableCell className={classes.bold} align="left">
                          {s("registerIdLabel")}
                        </TableCell>
                        <TableCell className={classes.bold} align="left">
                          {s("startTimeLabel")}
                        </TableCell>
                        <TableCell className={classes.bold} align="left">
                          {s("endTimeLabel")}
                        </TableCell>
                        <TableCell className={classes.bold} align="left">
                          {s("totalTimeLabel")}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRowAlign}>
                        <TableCell align="right">{s("statusText")}</TableCell>
                        <TableCell align="right">{selectedRow.title}</TableCell>
                        <TableCell align="right">
                          {selectedRow.participant.contractor_name}
                        </TableCell>
                        <TableCell align="right">
                          {selectedRow.participant.person_name}
                        </TableCell>
                        <TableCell align="right">{"-"}</TableCell>
                        <TableCell align="right">
                          {getTimeFromDate(selectedRow.readables.start_time)}
                          <br />
                          {formatDate(selectedRow.readables.start_time)}
                        </TableCell>
                        <TableCell align="right">
                          {getTimeFromDate(selectedRow.readables.end_time)}
                          <br />
                          {formatDate(selectedRow.readables.end_time)}
                        </TableCell>
                        <TableCell align="right">
                          {selectedRow.total_time}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                  <TableContainer
                    component={Paper}
                    className={classes.slideContainer}
                  >
                    <Table
                      style={{ display: "flex" }}
                      aria-label="simple table"
                    >
                      <TableRow
                        className={clsx(classes.tableRowAlign, classes.bold)}
                      >
                        <TableCell className={classes.bold} align="left">
                          {s("verifiedByLabel")}
                        </TableCell>
                        <TableCell className={classes.bold} align="left">
                          {s("verifiedDateLabel")}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRowAlign}>
                        <TableCell align="right">{"WorkPrecision"}</TableCell>
                        <TableCell align="right">{approveTime}</TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </div>
              )}
              <div>
                <IconButton
                  onClick={() => setActiveRow(false)}
                  className={classes.closeIcon}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </Slide>
          <TimesheetApprovals
            approvalSummaries={approvalSummaries}
            SummaryViewMode={"DIALOG"}
            dictionaries={dictionaries}
            toggleSummaryRowDetails={toggleSummaryRowDetails}
            submitRowDeleted={() => {}}
            submitRowModified={() => {}}
            updateApprovalSelection={() => {}}
            clearApprovalSelection={() => {}}
            defaultExpanded={undefined}
            businessAreaNames={businessAreaNames}
          />
        </DialogContent>
        <DialogActions className={classes.spaceBtw}>
          {selectionStats && (
            <div className={classes.flexBox}>
              <div className={clsx(classes.flexView, classes.alignCenter)}>
                <div>
                  <div>{v("startLabel")}</div>
                  <Typography className={classes.secondaryHeading}>
                    {getTimeFromDate(selectionStats.start_time)}
                    <br />
                    {formatDate(selectionStats.start_time)}
                  </Typography>
                </div>
                <span className={classes.verticalDivider}></span>
                <div>
                  <div>{v("endLabel")}</div>
                  <Typography className={classes.secondaryHeading}>
                    {getTimeFromDate(selectionStats.end_time)}
                    <br />
                    {formatDate(selectionStats.end_time)}
                  </Typography>
                </div>
                <span className={classes.verticalDivider}></span>
                <div>
                  <div>{t("totalHeading")}</div>
                  <Typography className={classes.secondaryHeading}>
                    {selectionStats.formattedTotalTime}
                    <br />
                    &nbsp;
                  </Typography>
                </div>
              </div>
            </div>
          )}
          <div>
            <Button
              id="approval-summary-cancel"
              onClick={() => closeDialog()}
              color="secondary"
            >
              {s("closeButtonLabel")}
            </Button>
            <Button
              id="approval-summary-confirm"
              onClick={() => {
                handleVerificationStatus(true);
                verifySummary();
                closeDialog();
              }}
              color="primary"
            >
              {s("successButtonLabel")}
            </Button>
          </div>
        </DialogActions>
      </div>
    </>
  );
};

export default TimesheetApprovalsSummary;
