import React, { useEffect } from "react";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { CssBaseline } from "@material-ui/core";
import { connect } from "react-redux";
import { mainRootState } from "../redux/types";
import TabRouterContainer from "../containers/TabRouterContainer";
import clsx from "clsx";
import Navbar from "./Navbar";
import { useTranslate } from "../services/appLanguageService";
import { useChannel, addNotificationListener } from "./ChannelContext";
import { showGlobalSnackbar } from "../helpers/globalHelper";

const drawerWidth = 260;

const styles = (theme: any) =>
  createStyles({
    root: {
      display: "flex"
    },
    toolbar: theme.mixins.toolbar,
    toolbarSpacing: {
      [theme.breakpoints.up("md")]: {
        minHeight: "44px"
      },
      [theme.breakpoints.down("md")]: {
        minHeight: "44px"
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "44px"
      },
      [theme.breakpoints.down("xs")]: {
        minHeight: "64px"
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      transition: "0.5s ease margin",
      padding: 20,
      [theme.breakpoints.down(600)]: {
        padding: 0,
        width: "100%"
      },
      [theme.breakpoints.up("sm")]: {
        flexGrow: 1,
        transition: "0.5s ease margin",
        padding: 20
      }
    }
  });

interface LayoutProps extends WithStyles<typeof styles> {
  isOnline: boolean;
}

const useNotifications = (): void => {
  const tPermissions = useTranslate("Permissions");
  const tNotification = useTranslate("NotificationSnackbar");
  const channel = useChannel();
  const typeLabel = tNotification("type");
  const notAvailable = tNotification("notAvailable");

  useEffect(() => {
    if (channel) {
      const unregister = addNotificationListener(channel, (m) => {
        const message = `${typeLabel}: ${tPermissions(
          m.event_type.toLowerCase()
        )} - ${m.message ?? notAvailable}`;
        showGlobalSnackbar(message, "default");
      });
      return unregister;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);
};

const Layout: React.FC<LayoutProps> = (props) => {
  const { classes, isOnline } = props;
  useNotifications();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navbar />
      <main
        className={classes.content}
        style={{ marginTop: isOnline ? 0 : 20, width: "80%" }}
      >
        <div className={clsx(classes.toolbar, classes.toolbarSpacing)} />
        <TabRouterContainer tabTitle={{ instanceId: -1, instanceTitle: "" }} />
      </main>
    </div>
  );
};

export default withStyles(styles)(
  connect(({ connectivity: { isOnline } }: mainRootState) => ({
    isOnline
  }))(Layout)
);
