import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Company, mainRootState, Term } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import _ from "lodash";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface TermsContainerProps {
  terms: Term[];
  count: number;
  loadingTerms: boolean;
  resetTerms: () => void;
  loadTerms: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string
  ) => void;
  loadCompanies: (companyIds: number[]) => void;
  companies: Company[];
}

const TermsContainer: React.FC<TermsContainerProps> = (props) => {
  const {
    loadTerms,
    terms,
    loadingTerms,
    resetTerms,
    loadCompanies,
    companies
  } = props;
  const page = 1;
  const pageSize = 20;

  useEffect(() => {
    const companyIds = _.uniq(terms.map((term) => term.owner || -1)).filter(
      (companyId) => companyId !== -1
    );
    loadCompanies(companyIds);
  }, [loadCompanies, terms]);
  useEffect(() => loadTerms(page, pageSize), [loadTerms]);
  useEffect(() => () => resetTerms(), [resetTerms]);

  const columnDefinitions = [
    { name: "name", type: "link" },
    { name: "status", type: "enum" },
    {
      name: "owner",
      type: "foreignLink",
      foreignData: {
        data: companies,
        visibleColumns: ["name"],
        url: "companies/companies"
      }
    },
    { name: "description", type: "enum" }
  ];

  return (
    <ResourcesTableContainer
      resources={terms}
      resourceName={"Term"}
      count={props.count}
      loadingResources={loadingTerms}
      routeUrl={"contracts/terms"}
      columnDefinitions={columnDefinitions}
      resetResources={resetTerms}
      loadResources={loadTerms}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadTerms: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TERMS,
        `contracts/terms/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}`
      )
    );
  },
  loadCompanies: (companyIds: number[]) => {
    if (companyIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COMPANIES,
          `companies/companies/?include=["id","name"]&filter__id__in=[${companyIds}]`
        )
      );
    }
  },
  resetTerms: () => dispatch(LoadingBooleansActions.ResetTerms(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  companies: state.tabStates[ownProps.tabId].companies.companies.companies,
  terms: state.tabStates[ownProps.tabId].terms.terms.terms,
  count: state.tabStates[ownProps.tabId].terms.terms.count,
  loadingTerms: state.tabStates[ownProps.tabId].loadingBooleans.loadingTerms
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsContainer);
