import {
  IPackageType,
  IPackageData,
  IPackageOptions,
  IPackageOption,
  IAddServicePackagePayload
} from "../../../redux/types";
import {
  patchPackageEventAddSubmittedWorkAPI,
  patchPackageEventRemoveSubmittedWorkAPI,
  postServicePackageEventAPI
} from "../../../services/api-declaration";

class PackageCarry {
  private routeInstanceId: number | undefined;
  public submitId: number;
  private previous: IPackageData;
  public current: IPackageData;
  public packageOptions: IPackageOptions;

  constructor(
    routeInstanceId: number | undefined,
    submitId: number,
    packageData: IPackageData,
    packageOptions: IPackageOptions
  ) {
    this.routeInstanceId = routeInstanceId;
    this.submitId = submitId;
    this.previous = { ...packageData };
    this.current = { ...packageData };
    this.packageOptions = packageOptions;
  }

  public setCurrentPackage(
    packageType: IPackageType,
    packageInfo: IPackageOption | undefined
  ) {
    this.current = {
      ...this.current,
      [packageType]: packageInfo
    };
  }

  // public async fetchPackageOptions() {
  //   if (this.packageOptions.applicable) {
  //     return false;
  //   }
  //   this.packageOptions = await fetchPackageOptionsData(this.submitId);
  //   return true;
  // }

  public async createPackage(
    packageType: IPackageType,
    { event_id, title }: IPackageOption
  ) {
    const payload: IAddServicePackagePayload = {
      title,
      package: event_id,
      contractor_submitted: packageType === "contractor" ? [this.submitId] : [],
      customer_submitted: packageType === "customer" ? [this.submitId] : [],
      default_for_route: this.routeInstanceId
    };
    try {
      const response = await postServicePackageEventAPI(payload);
      if (response) {
        const data: IPackageOption = {
          event_id: response.id,
          title: response.title
        };
        if (this.packageOptions.applicable) {
          this.packageOptions.applicable[packageType].push(data);
        }
        this.setCurrentPackage(packageType, data);
        this.previous = { ...this.current };
        return true;
      }
    } catch (e) {
      console.warn(e);
    }
    return false;
  }
  public async savePackages() {
    const submitted_work_ids = [this.submitId];

    const contractor_old_id = this.previous.contractor?.event_id;
    const contractor_new_id = this.current.contractor?.event_id;
    if (contractor_new_id !== contractor_old_id) {
      if (contractor_new_id) {
        await patchPackageEventAddSubmittedWorkAPI(contractor_new_id, {
          submitted_work_ids,
          for_customer: false,
          for_contractor: true
        });
      } else if (contractor_old_id) {
        await patchPackageEventRemoveSubmittedWorkAPI(contractor_old_id, {
          submitted_work_ids,
          for_customer: false,
          for_contractor: true
        });
      }
    }

    const customer_old_id = this.previous.customer?.event_id;
    const customer_new_id = this.current.customer?.event_id;
    if (customer_new_id !== customer_old_id) {
      if (customer_new_id) {
        await patchPackageEventAddSubmittedWorkAPI(customer_new_id, {
          submitted_work_ids,
          for_customer: true,
          for_contractor: false
        });
      } else if (customer_old_id) {
        await patchPackageEventRemoveSubmittedWorkAPI(customer_old_id, {
          submitted_work_ids,
          for_customer: true,
          for_contractor: false
        });
      }
    }
    this.previous = { ...this.current };
  }
}

export default PackageCarry;
