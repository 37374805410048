import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  FormControlLabel,
  Link
} from "@material-ui/core";
import TaskContractorCooperationsContainer from "../../../containers/contracts/tasks/TaskContractorCooperationsContainer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as types from "../../../redux/types";
import MapContainer from "../../../containers/contracts/tasks/MapContainer";
import { useTranslate } from "../../../services/appLanguageService";
import { niceDate } from "../../FormatHelpers";
import ResourceLink from "../../links/ResourceLink";
import {
  ConfirmationDialogServiceProvider,
  useConfirmationDialog
} from "../../confirmationDialog/ConfirmationDialogServiceProvider";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../../redux/types";
import { mapTabRouteProps } from "../../../helpers/routesHelper";
import { getContractNamesAPI } from "../../../services/api-declaration";
import { Link as RouterLink } from "react-router-dom";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    },
    formControlLabel: {
      fontSize: 14
    }
  });

interface TaskProps extends WithStyles<typeof styles>, TabComponentProps {
  task: types.Task;
  customerObject: types.Customerobject;
  businessArea: types.BusinessArea;
  taskoptions: types.TaskOption[];
  updateTaskoptionsForTask: (taskId: number, newTaskOptions: number[]) => void;
}

const Task: React.FC<TaskProps> = (props) => {
  const {
    classes,
    task,
    customerObject,
    businessArea,
    taskoptions,
    updateTaskoptionsForTask,
    tabId
  } = props;
  const t = useTranslate("TaskPage");
  const t2 = useTranslate("Tasks");

  const [contracts, setContracts] = useState<{ path: string; name: string }[]>(
    []
  );
  const [expanded, setExpanded] = useState(false);
  const confirmationDialog = useConfirmationDialog();

  const handleTaskoptionUpdate = async (
    checked: boolean,
    taskOption: types.TaskOption
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: `${t("confirmationDescription")} ${
        checked ? t("turnOnLabel") : t("turnOffLabel")
      } ${taskOption.name}?`
    });
    if (checked) {
      updateTaskoptionsForTask(task.id, [...task.task_options, taskOption.id]);
    } else {
      updateTaskoptionsForTask(
        task.id,
        task.task_options.filter((task_option) => task_option !== taskOption.id)
      );
    }
  };

  useEffect(() => {
    store.dispatch(setTitle(task.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    if (task.customer_contracts.length) {
      let alive = true;
      void getContractNamesAPI({ id__in: task.customer_contracts }).then(
        ({ results }) => {
          alive &&
            setContracts(
              results.map((c) => ({
                path: `/contracts/contracts/${c.id}`,
                name: c.name
              }))
            );
        }
      );
      return () => {
        alive = false;
      };
    } else {
      setContracts([]);
    }
  }, [task.customer_contracts]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">{task.name}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">{t2(task.status)}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("customerObjectLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <ResourceLink
                      label={customerObject.name}
                      id={customerObject.id}
                      url="contracts/customerobjects"
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("businessAreaLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <ResourceLink
                      label={businessArea.name}
                      id={businessArea.id}
                      url="contracts/businessareas"
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell align="left" className={classes.bold}>
                    {t("optionsLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {taskoptions.map((taskoption) => (
                      <FormControlLabel
                        key={taskoption.id}
                        control={
                          <Checkbox
                            checked={task.task_options.includes(taskoption.id)}
                            size="small"
                            onChange={(event: any) => {
                              handleTaskoptionUpdate(
                                event.target.checked,
                                taskoption
                              );
                            }}
                          />
                        }
                        label={
                          <Typography className={classes.formControlLabel}>
                            {taskoption.name}
                          </Typography>
                        }
                      />
                    ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("complexityLabel")}
                    </TableCell>
                    <TableCell align="right">{t2(task.complexity)}</TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("vulnerabilityLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {t2(task.vulnerability)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("reportIntervalLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {t2(task.report_interval)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className={classes.bold}>
                      {t("contractLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {contracts.map(({ path, name }) => (
                        <React.Fragment key={path}>
                          <Link component={RouterLink} to={path}>
                            {name}
                          </Link>
                        </React.Fragment>
                      ))}
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("defaultTransportLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {`${task.default_transport} MIN`}
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell align="left" className={classes.bold}>
                      {t("spoilageCoefficientLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {`${task.spoilage_coefficient} %`}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className={classes.bold}>
                      {t("accountingReferenceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {task.accounting_reference}
                    </TableCell>
                  </TableRow>
                  <TableRow key={7}>
                    <TableCell align="left" className={classes.bold}>
                      {t("productPackageFieldLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {task.product_package ? t("yesLabel") : t("noLabel")}
                    </TableCell>
                  </TableRow>
                  <TableRow key={8}>
                    <TableCell align="left" className={classes.bold}>
                      {t("materialDepotFieldLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {task.material_depot ? t("yesLabel") : t("noLabel")}
                    </TableCell>
                  </TableRow>
                  <TableRow key={9}>
                    <TableCell align="left" className={classes.bold}>
                      {t("workingInstructionsLabel")}
                    </TableCell>
                    <TableCell align="right">{task.work_description}</TableCell>
                  </TableRow>
                  <TableRow key={10}>
                    <TableCell align="left" className={classes.bold}>
                      {t("riskDescriptionLabel")}
                    </TableCell>
                    <TableCell align="right">{task.risk_description}</TableCell>
                  </TableRow>
                  <TableRow key={11}>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">{task.notes}</TableCell>
                  </TableRow>
                  <TableRow key={12}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(task.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={13}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(task.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          {task.contractor_cooperations.length > 0 && (
            <TaskContractorCooperationsContainer
              contractor_cooperations={task.contractor_cooperations}
              {...mapTabRouteProps(props)}
            />
          )}
        </Grid>
      </Grid>
      <MapContainer
        geo_polygons={task.geo_polygons}
        editable={true}
        taskId={task.id}
        isInDialog={false}
        defaultLongitude={customerObject.location?.longitude || undefined}
        defaultLatitude={customerObject.location?.latitude || undefined}
        {...mapTabRouteProps(props)}
      />
    </div>
  );
};

export default withStyles(styles)((props: TaskProps) => (
  <ConfirmationDialogServiceProvider>
    <Task {...props} />
  </ConfirmationDialogServiceProvider>
));
