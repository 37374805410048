import React, { createContext, useContext } from "react";
import { ConfirmationDialogOptions } from "../../redux/types";
import ConfirmationDialog from "./ConfirmationDialog";

const ConfirmationServiceContext = createContext<
  (options: ConfirmationDialogOptions) => Promise<void>
>(Promise.reject);
export const ConfirmationDialogServiceProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [
    confirmationState,
    setConfirmationState
  ] = React.useState<ConfirmationDialogOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void,
    reject: () => void
  }>();

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSuccess = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  const openDialog = (options: ConfirmationDialogOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openDialog}
        children={children}
      />
      <ConfirmationDialog
        onSuccess={handleSuccess}
        onClose={handleClose}
        open={Boolean(confirmationState)}
        {...confirmationState}
      />
    </>
  );
};

export const useConfirmationDialog = () =>
  useContext(ConfirmationServiceContext);
