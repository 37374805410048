import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  DistrictForm,
  District,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import { useTranslate } from "../../../services/appLanguageService";
import MapView from "../../../components/contracts/tasks/Map";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    code: Yup.string()
      .min(1, t("minLengthError"))
      .max(5, t("max5LengthError"))
      .nullable()
      .notRequired(),
    notes: Yup.string().nullable().notRequired(),
    parent_district: Yup.number().nullable().notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    }
  });

interface NewDistrictProps
  extends FormikSubmitDispatchProps<DistrictForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  district?: District;
  parentDistrictOptions: SelectFieldOption[];
  loadDistricts: (searchTerm?: string) => void;
}

const NewEditDistrict: React.FC<NewDistrictProps> = (props) => {
  const { classes, district, parentDistrictOptions, loadDistricts, tabId } =
    props;
  const t = useTranslate("DistrictPage");
  const t3 = useTranslate("ValidationErrorMessages");
  let initialValues: District | DistrictForm;
  if (district && (district as District).id) {
    initialValues = {
      ...district
    };
  } else {
    initialValues = {
      id: -1,
      name: "",
      code: "",
      notes: "",
      geo_polygons: null,
      parent_district: -1
    };
  }

  const filteredParentDistrictOptions = district
    ? parentDistrictOptions.filter(
        (parentDistrict) => parentDistrict.value !== district.id
      )
    : parentDistrictOptions;

  useEffect(() => {
    if (district) {
      store.dispatch(setTitle(district.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/companies/districts${
                district ? `/${district.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {district ? t("editDistrictHeading") : t("newDistrictHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={props.handleSubmit}
        >
          {(formikProps) => (
            <form
              className={classes.form}
              onSubmit={formikProps.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="district-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="district-form-code"
                type="text"
                name="code"
                label={t("codeLabel")}
                placeholder={t("codeLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-parent_district"
              >
                {t("parentDistrictLabel")}
              </InputLabel>
              <Field
                id="form-parent_district"
                type="text"
                name="parent_district"
                label={t("parentDistrictLabel")}
                placeholder={t("parentDistrictLabel")}
                value={
                  filteredParentDistrictOptions.find(
                    (parentDistrict) =>
                      formikProps.values.parent_district ===
                      parentDistrict.value
                  )
                    ? filteredParentDistrictOptions.find(
                        (parentDistrict) =>
                          formikProps.values.parent_district ===
                          parentDistrict.value
                      )
                    : null
                }
                options={filteredParentDistrictOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                loadOptions={loadDistricts}
              />

              <Field
                id="new-district-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <MapView
                geo_polygons={district ? district.geo_polygons : null}
                editable={true}
                formikProps={formikProps}
                forDistrict={true}
              />

              <Button
                id="new-district-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {district ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditDistrict);
