import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Group, TabComponentProps } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import GroupView from "../../components/groups/Group";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import LoadingSpinnerPaper from "../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface GroupContainerProps extends TabComponentProps<"groupId"> {
  group: Group;
  loadingGroup: boolean;
  resetGroup: () => void;
  loadGroup: (groupId: number) => void;
}

const GroupContainer: React.FC<GroupContainerProps> = (props) => {
  const {
    resetGroup,
    loadingGroup,
    loadGroup,
    group,
    routeParams: { groupId }
  } = props;
  useEffect(() => loadGroup(groupId), [loadGroup, groupId]);
  useEffect(() => () => resetGroup(), [resetGroup]);
  return (
    <div>
      <ViewActionButtons
        resourceId={groupId}
        resource={ReduxActionStrings.GROUP}
        url="auth/groups"
      />
      {loadingGroup ? (
        <LoadingSpinnerPaper />
      ) : (
        <GroupView group={group} {...mapTabRouteProps(props)} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadGroup: (groupId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "GROUP",
        `auth/groups/${groupId}/`
      )
    );
  },
  resetGroup: () => dispatch(LoadingBooleansActions.ResetGroup(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  group: state.tabStates[ownProps.tabId].groups.group.group,
  loadingGroup: state.tabStates[ownProps.tabId].loadingBooleans.loadingGroup
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupContainer);
