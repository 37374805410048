import { SubmittedWork, Task } from "../../../redux/types";
import { getTasksByFilterIds } from "../../../services/api-declaration";
import Dictionary from "./Dictionary";

class TaskDictionary extends Dictionary<Task> {
  public getName(taskId: number) {
    return this.forDisplay(taskId).name;
  }

  public collectFromEnteries(
    corrections: Array<{
      task: SubmittedWork["task"];
    }>
  ) {
    corrections.forEach((correction) => {
      if (correction?.task) {
        this.collectId(correction.task);
      }
    });
  }

  public async fetchData() {
    const taskIds = this.getIdsToSync();
    if (taskIds.length !== 0) {
      const { results: tasks } = await getTasksByFilterIds(taskIds);
      tasks.forEach((task) => {
        this.set(task.id, task);
      });
      this.idsToSync.clear();
      return true;
    }
    return false;
  }
}

export default TaskDictionary;
