import { SingleSelect } from "react-select-material-ui";
import { ColumnDefinition } from "../../../redux/types";
import { WithStyles, Checkbox, TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslate } from "../../../services/appLanguageService";
import { format } from "date-fns";
import validate from "./validate";
import { snakeToCamel } from "../../FormatHelpers";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import TextFieldResourceComponent from "./TextFieldResourceComponent";

const styles = (theme: any) =>
  createStyles({
    logo: {
      maxWidth: 100,
      maxHeight: 30
    },
    joinedTextfield: {
      display: "inline"
    },
    textfield: {
      display: "inline",
      width: "150%"
    },
    select: {
      minWidth: "100px"
    }
  });

function emptyObject(obj: object) {
  for (const i in obj) return false;
  return true;
}

interface TableForm {
  [key: string]: any;
}

interface TableFormComponentProps extends WithStyles<typeof styles> {
  columnDefinition: ColumnDefinition;
  joinColumnDefinition?: ColumnDefinition;
  handleForm: (data: TableForm) => void;
  formData: TableForm;
  type: string;
  pluralName: string;
  handleFocusedField?: (field: string) => void;
  focusedField?: string;
  handleFormValidation: (
    field: string,
    validation: { error: boolean; errorText: string }
  ) => void;
  formValidation: { error: boolean; errorText: string };
  joinedFormValidation?: { error: boolean; errorText: string };
  altFieldType?: string;
}

const TableFormComponent: React.FC<TableFormComponentProps> = (props) => {
  const {
    columnDefinition,
    handleForm,
    formData,
    type,
    classes,
    pluralName,
    handleFocusedField,
    focusedField,
    handleFormValidation,
    formValidation,
    joinColumnDefinition,
    joinedFormValidation
  } = props;

  const t = useTranslate(`${pluralName}Page`);
  if (
    (emptyObject(formData) && type === "EDIT") ||
    !columnDefinition ||
    (columnDefinition && columnDefinition.name === "id") ||
    columnDefinition.type === "hidden"
  ) {
    return <></>;
  }

  const disabled =
    typeof columnDefinition.formDependency !== "undefined" &&
    formData[columnDefinition.formDependency] === undefined;

  const formType =
    columnDefinition.altEditableForm &&
    props.altFieldType === columnDefinition.name
      ? columnDefinition.altEditableForm
      : columnDefinition.editableForm;

  let selectLabel = t(`${snakeToCamel(columnDefinition.name)}Label`);

  if (columnDefinition.altOptionLabel) {
    selectLabel = columnDefinition.altOptionLabel;
  } else if (columnDefinition.customOptionLabel) {
    selectLabel = columnDefinition.customOptionLabel;
  }

  if (formType === "select" && columnDefinition.options) {
    return (
      <SingleSelect
        className={classes.select}
        data-cy={`${pluralName}-select-${columnDefinition.name}`}
        value={formData[columnDefinition.name]}
        options={columnDefinition.options || []}
        disabled={disabled}
        error={formValidation && formValidation.error}
        helperText={formValidation && formValidation.errorText}
        placeholder={selectLabel}
        onChange={(value) => {
          validate(
            { [columnDefinition.name]: value },
            columnDefinition,
            handleFormValidation
          );
          if (columnDefinition.customSelect) {
            columnDefinition.customSelect(value);
          }
          handleForm({ ...formData, [columnDefinition.name]: value });
        }}
        SelectProps={{
          menuPortalTarget: document.body,
          styles: {
            menuPortal: (base: any) => ({
              ...base,
              zIndex: 9999,
              width: 260
            }),
            menu: (base: any) => ({
              ...base,
              borderRadius: 0,
              wordWrap: "break-word",
              fontSize: 15
            })
          }
        }}
      />
    );
  } else if (formType === "text" && columnDefinition.type === "joined") {
    return (
      <>
        <TextField
          className={classes.joinedTextfield}
          data-cy={`${pluralName}-joined_1-${columnDefinition.name}`}
          value={formData[columnDefinition.name] || ""}
          onChange={(event) => {
            validate(
              { [columnDefinition.name]: event.target.value },
              columnDefinition,
              handleFormValidation
            );
            handleForm({
              ...formData,
              [columnDefinition.name]: event.target.value
            });
          }}
          placeholder={t(`${snakeToCamel(columnDefinition.name)}Label`)}
          InputProps={{
            style: {
              fontSize: 14,
              width: "100%",
              marginRight: "2%"
            }
          }}
          onFocus={() => {
            if (handleFocusedField) {
              handleFocusedField(columnDefinition.name);
            }
          }}
          error={formValidation && formValidation.error}
          helperText={formValidation && formValidation.errorText}
          autoFocus={
            typeof focusedField !== "undefined" &&
            focusedField === columnDefinition.name
          }
        />
        <TextField
          className={classes.joinedTextfield}
          data-cy={`${pluralName}-joined_2-${columnDefinition.name}`}
          value={
            columnDefinition.joined && formData[columnDefinition.joined]
              ? formData[columnDefinition.joined]
              : ""
          }
          error={
            joinedFormValidation &&
            typeof joinedFormValidation.error !== "undefined"
              ? joinedFormValidation.error
              : false
          }
          helperText={joinedFormValidation && joinedFormValidation.errorText}
          placeholder={
            columnDefinition.joined
              ? t(`${snakeToCamel(columnDefinition.joined)}Label`)
              : ""
          }
          onChange={(event) => {
            if (joinColumnDefinition && columnDefinition.joined) {
              validate(
                { [columnDefinition.joined]: event.target.value },
                joinColumnDefinition,
                handleFormValidation
              );
            }

            handleForm({
              ...formData,
              [columnDefinition.joined || columnDefinition.name]:
                event.target.value
            });
          }}
          InputProps={{
            style: {
              fontSize: 14,
              width: "100%"
            }
          }}
          onFocus={() => {
            if (handleFocusedField) {
              handleFocusedField(columnDefinition.name + "_2");
            }
          }}
          autoFocus={
            typeof focusedField !== "undefined" &&
            focusedField === columnDefinition.name + "_2"
          }
        />
      </>
    );
  } else if (formType === "date") {
    return (
      <DatePicker
        format="yyyy-MM-dd"
        id={`${pluralName}-datepicker-${columnDefinition.name}`}
        data-cy={`${pluralName}-datepicker-${columnDefinition.name}`}
        value={formData[columnDefinition.name] || null}
        onChange={(event) => {
          validate(
            { [columnDefinition.name]: event },
            columnDefinition,
            handleFormValidation
          );
          handleForm({
            ...formData,
            [columnDefinition.name]: event ? format(event, "yyyy-MM-dd") : event
          });
        }}
        error={formValidation && formValidation.error}
        helperText={formValidation && formValidation.errorText}
        clearable
        InputProps={{
          style: {
            fontSize: 13
          }
        }}
      />
    );
  } else if (formType === "checkbox") {
    return (
      <Checkbox
        value={formData[columnDefinition.name]}
        checked={formData[columnDefinition.name]}
        data-cy={`${pluralName}-checkbox-${columnDefinition.name}`}
        onChange={(event) =>
          handleForm({
            ...formData,
            [columnDefinition.name]: !formData[columnDefinition.name]
          })
        }
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    );
  } else if (formType === "star") {
    return (
      <Checkbox
        icon={<StarBorderIcon />}
        checkedIcon={<StarIcon />}
        value={formData[columnDefinition.name]}
        checked={formData[columnDefinition.name]}
        data-cy={`${pluralName}-checkbox-${columnDefinition.name}`}
        onChange={(event) =>
          handleForm({
            ...formData,
            [columnDefinition.name]: !formData[columnDefinition.name]
          })
        }
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    );
  } else if (formType === "text") {
    return (
      <TextFieldResourceComponent
        className={classes.textfield}
        data-cy={`${pluralName}-text-${columnDefinition.name}`}
        fieldValueData={formData[columnDefinition.name] || ""}
        onChange={(event) => {
          validate(
            { [columnDefinition.name]: event.target.value },
            columnDefinition,
            handleFormValidation
          );
          if (handleFocusedField) {
            handleForm({
              ...formData,
              [columnDefinition.name]: event.target.value
            });
          }
        }}
        onBlur={(event) => {
          if (!handleFocusedField) {
            handleForm({
              ...formData,
              [columnDefinition.name]: event.target.value
            });
          }
        }}
        placeholder={t(`${snakeToCamel(columnDefinition.name)}Label`)}
        InputProps={{
          style: {
            fontSize: 14
            //width: "100%"
          }
        }}
        error={formValidation && formValidation.error}
        helperText={formValidation && formValidation.errorText}
        onFocus={() => {
          if (handleFocusedField) {
            handleFocusedField(columnDefinition.name);
          }
        }}
        autoFocus={
          typeof focusedField !== "undefined" &&
          focusedField === columnDefinition.name
        }
      />
    );
  }

  return <></>;
};

export default withStyles(styles)(TableFormComponent);
