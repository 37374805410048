import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Task,
  Customerobject,
  BusinessArea,
  TaskOption,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import TaskView from "../../../components/contracts/tasks/Task";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface TaskContainerProps extends TabComponentProps<"taskId"> {
  task: Task;
  customerObject: Customerobject;
  loadingTask: boolean;
  resetTask: () => void;
  loadTask: (taskId: number) => void;
  loadCustomerObject: (customerObjectId: number) => void;
  loadBusinessArea: (businessAreaId: number) => void;
  businessArea: BusinessArea;
  loadTaskoptions: (businessAreaId: number) => void;
  taskoptions: TaskOption[];
  updateTaskoptionsForTask: (taskId: number, newTaskOptions: number[]) => void;
}

const TaskContainer: React.FC<TaskContainerProps> = (props) => {
  const {
    loadTask,
    loadingTask,
    resetTask,
    task,
    loadCustomerObject,
    customerObject,
    loadBusinessArea,
    businessArea,
    loadTaskoptions,
    taskoptions,
    updateTaskoptionsForTask,
    routeParams: { taskId }
  } = props;
  useEffect(() => loadTask(taskId), [loadTask, taskId]);
  useEffect(
    () => loadTaskoptions(task.businessarea),
    [loadTaskoptions, task.businessarea]
  );
  useEffect(
    () => loadCustomerObject(task.customerobject),
    [loadCustomerObject, task.customerobject]
  );
  useEffect(
    () => loadBusinessArea(task.businessarea),
    [loadBusinessArea, task.businessarea]
  );
  useEffect(() => () => resetTask(), [resetTask]);
  return (
    <div>
      <ViewActionButtons
        resourceId={taskId}
        resource={ReduxActionStrings.TASK}
        url="contracts/tasks"
        backUrl={`contracts/customerobjects/${task.customerobject}`}
      />
      {loadingTask ? (
        <LoadingSpinnerPaper />
      ) : (
        <TaskView
          task={task}
          customerObject={customerObject}
          businessArea={businessArea}
          taskoptions={taskoptions}
          updateTaskoptionsForTask={updateTaskoptionsForTask}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadTask: (taskId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TASK,
        `contracts/tasks/${taskId}/`
      )
    );
  },
  resetTask: () => dispatch(LoadingBooleansActions.ResetTask(ownProps.tabId)),
  loadCustomerObject: (customerObjectId: number) => {
    if (customerObjectId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.TASK_CUSTOMEROBJECT,
          `contracts/customerobjects/${customerObjectId}/`
        )
      );
    }
  },
  loadBusinessArea: (businessAreaId: number) => {
    if (businessAreaId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.TASK_BUSINESSAREA,
          `contracts/businessareas/${businessAreaId}/`
        )
      );
    }
  },
  loadTaskoptions: (businessAreaId: number) => {
    if (businessAreaId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.TASKOPTIONS,
          `contracts/taskoptions/?filter__businessarea=${businessAreaId}`
        )
      );
    }
  },
  updateTaskoptionsForTask: (taskId: number, newTaskOptions: number[]) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.TASK,
        `contracts/tasks/${taskId}/`,
        { task_options: newTaskOptions }
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  task: state.tabStates[ownProps.tabId].tasks.task.task,
  customerObject: state.tabStates[ownProps.tabId].tasks.task.taskCustomerobject,
  loadingTask: state.tabStates[ownProps.tabId].loadingBooleans.loadingTask,
  taskoptions: state.tabStates[ownProps.tabId].taskOptions.taskOptions.results,
  businessArea: state.tabStates[ownProps.tabId].tasks.task.taskBusinessArea
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskContainer);
