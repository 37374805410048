import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    spinner: {
      margin: theme.spacing(3)
    }
  })
);

const LoadingSpinner = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}>
        <CircularProgress className={classes.spinner} />
      </Grid>
    </Grid>
  );
};

export default LoadingSpinner;
