import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  TermForm,
  Term,
  mainRootState,
  SelectFieldOption
} from "../../../redux/types";
import TermNewForm from "../../../components/contracts/terms/TermNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewTermContainerProps extends FormikSubmitDispatchProps<TermForm> {
  loadCompanies: (searchTerm?: string) => void;
  ownerOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Term | TermForm> {
  loadCompanies: (searchTerm?: string) => void;
}

const NewTermContainer: React.FC<NewTermContainerProps> = (props) => {
  const { loadCompanies } = props;

  useEffect(() => loadCompanies(), [loadCompanies]);
  return (
    <TermNewForm
      ownerOptions={props.ownerOptions}
      handleSubmit={props.handleSubmit}
      loadCompanies={props.loadCompanies}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<TermForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.TERM,
        "contracts/terms/",
        checkedNullValues as Term,
        "/contracts/terms?same_tab=true"
      )
    );
  },
  loadCompanies: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?filter__status=ACTIVE&page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  ownerOptions: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  }))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTermContainer);
