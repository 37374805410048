import { REST_REQUEST, REST_REQUEST_WITH_DATA } from "./restActionTypes";
import {
  createSuccessActionType,
  createFailureActionType,
  createResetResourceType
} from "../reducers/actionTypes";

export function restRequest(
  tabId: number,
  restType: string,
  actionType: string,
  url: string,
  redirect?: string,
  redirectWithId?: string,
  id?: number,
  callback?: (response: any) => void
) {
  return {
    tabId,
    type: REST_REQUEST,
    payload: {
      restType,
      actionType,
      url,
      redirect,
      redirectWithId,
      id,
      callback
    }
  };
}

export function restRequestWithData<T>(
  tabId: number,
  restType: string,
  actionType: string,
  url: string,
  data: T,
  redirect?: string,
  redirectWithId?: string,
  id?: number,
  callback?: (response: any) => void,
  failCallback?: (response: any) => void
) {
  return {
    tabId,
    type: REST_REQUEST_WITH_DATA,
    payload: {
      restType,
      actionType,
      url,
      data,
      redirect,
      redirectWithId,
      id,
      callback,
      failCallback
    }
  };
}

export function restSuccess<T>(
  tabId: number,
  restType: string,
  actionType: string,
  data: T
) {
  return {
    tabId,
    type: createSuccessActionType(restType, actionType),
    payload: data
  };
}

export function restFailure(
  tabId: number,
  restType: string,
  actionType: string,
  message: string,
  body?: any,
  failCallback?: (response: any) => void
) {
  return {
    tabId,
    type: createFailureActionType(restType, actionType),
    payload: {
      message,
      body,
      failCallback
    }
  };
}

export function resetResource(tabId: number, actiontype: string) {
  return {
    tabId,
    type: createResetResourceType(actiontype)
  };
}
