import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Group } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";
import ResourcesTableContainer from "../../components/resourcetable/ResourcesTableContainer";

interface GroupsContainerProps {
  groups: Group[];
  count: number;
  loadingGroups: boolean;
  groupIds?: number[];
  resetGroups: () => void;
  loadGroups: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  showNewButton?: boolean;
  customHeader?: string;
}

const GroupsContainer: React.FC<GroupsContainerProps> = (props) => {
  const { groupIds, loadGroups, resetGroups, loadingGroups, groups } = props;

  const page = 1;
  const pageSize = 20;

  let filter = "";

  if (groupIds) {
    const filterStr = groupIds.length !== 1 ? "filter__id__in" : "filter__id";
    const groupIdsStr = groupIds.length ? groupIds.toString() : "";

    filter = `&${filterStr}=${groupIdsStr}`;
  }

  useEffect(
    () => loadGroups(page, pageSize, undefined, undefined, filter),
    [filter, loadGroups]
  );
  useEffect(() => () => resetGroups(), [resetGroups]);

  const columnDefinitions = [
    { name: "avatar", type: "roundThumbnail" },
    { name: "name", type: "link" }
  ];

  const showNewButton =
    (typeof props.showNewButton !== "undefined" && props.showNewButton) ||
    typeof props.showNewButton === "undefined";

  return (
    <ResourcesTableContainer
      resources={groups}
      resourceName={"Group"}
      count={props.count}
      loadingResources={loadingGroups}
      routeUrl={"auth/groups"}
      columnDefinitions={columnDefinitions}
      resetResources={resetGroups}
      loadResources={loadGroups}
      showNewButton={showNewButton}
      customFilter={filter}
      customHeader={props.customHeader}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadGroups: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.GROUPS,
        `auth/groups/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  resetGroups: () =>
    dispatch(LoadingBooleansActions.ResetGroups(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  groups: state.tabStates[ownProps.tabId].groups.groups.results,
  count: state.tabStates[ownProps.tabId].groups.groups.count,
  loadingGroups: state.tabStates[ownProps.tabId].loadingBooleans.loadingGroups
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
