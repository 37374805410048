/* eslint-disable import/no-anonymous-default-export */
import { put, takeEvery } from "redux-saga/effects";
import {
  REST_REQUEST,
  REST_REQUEST_WITH_DATA,
  RestRequest,
  RestRequestWithData
} from "./restActionTypes";
import * as RestActions from "./restActions";
import { fetchWithBody, fetchSansBody } from "../../services/fetchWrapper";
import { push } from "connected-react-router";

export function* restRequestSaga() {
  yield takeEvery(REST_REQUEST, restRequest);
}

const cacheBustUrlCheck = /^auth\/persons\/\d+\/$/;

function* restRequest(action: RestRequest): any {
  //TODO: Change it to correct return type
  const {
    payload: { restType, url, redirect, redirectWithId, id }
  } = action;
  try {
    const cache = cacheBustUrlCheck.test(url) ? "no-cache" : undefined;
    const response = yield fetchSansBody(restType, url, cache);
    yield put(
      RestActions.restSuccess(
        action.tabId,
        action.payload.restType,
        action.payload.actionType,
        id ? { ...response, id: id } : response
        // TODO: Vad gör koden nedan?
        // callback ? callback(response) : response
      )
    );
    if (redirect) {
      yield put(push(redirect));
    }
    if (redirectWithId && response && response.id) {
      yield put(push(`${redirectWithId}${response.id}`));
    }
  } catch (e: any) {
    yield put(
      RestActions.restFailure(
        action.tabId,
        action.payload.restType,
        action.payload.actionType,
        e.message
      )
    );
  }
}

export function* restRequestWithDataSaga() {
  yield takeEvery(REST_REQUEST_WITH_DATA, restRequestWithData);
}

function* restRequestWithData(action: RestRequestWithData<any>): any {
  const {
    payload: {
      restType,
      url,
      data,
      redirect,
      redirectWithId,
      id,
      callback,
      failCallback
    }
  } = action;
  try {
    const response = yield fetchWithBody(restType, url, data);
    const responseWithId = id ? { ...response, id: id } : response;
    yield put(
      RestActions.restSuccess(
        action.tabId,
        action.payload.restType,
        action.payload.actionType,
        callback ? callback(responseWithId) : responseWithId
      )
    );
    if (redirect) {
      yield put(push(redirect));
    }

    if (
      redirectWithId &&
      responseWithId &&
      responseWithId.id &&
      redirectWithId.search(":") === -1
    ) {
      if (redirectWithId.indexOf("?same_tab=true") !== -1) {
        yield put(
          push(
            `${redirectWithId.split("?")[0]}${responseWithId.id}?same_tab=true`
          )
        );
      } else {
        yield put(push(`${redirectWithId}${responseWithId.id}`));
      }
    } else if (
      redirectWithId &&
      responseWithId &&
      redirectWithId.search(":") !== -1
    ) {
      let obj = responseWithId;
      const matches = redirectWithId.match(/:[\w.]+/);
      if (matches) {
        const properties = matches[0].substring(1).split(".");
        for (let i = 0; i < properties.length; i++) {
          obj = obj[properties[i]];
        }
      }

      yield put(push(`${redirectWithId.replace(/:[\w.]+/, obj)}`));
    }
  } catch (e: any) {
    yield put(
      RestActions.restFailure(
        action.tabId,
        action.payload.restType,
        action.payload.actionType,
        e.message,
        e?.response_data || undefined,
        failCallback ? failCallback(id) : id
      )
    );
  }
}

export default [restRequestSaga(), restRequestWithDataSaga()];
