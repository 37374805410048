import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import DeleteResourceButton from "../components/DeleteResourceButton";
import * as RestActions from "../redux/sagas/restActions";
import { RestStrings } from "../redux/strings";
import { useTranslate } from "../services/appLanguageService";
import {
  useConfirmationDialog,
  ConfirmationDialogServiceProvider
} from "../components/confirmationDialog/ConfirmationDialogServiceProvider";

interface DeleteRouteButtonContainerContainerProps {
  handleClick: (
    resourceId: number,
    resource: string,
    url: string,
    customRedirect?: string,
    backendUrl?: string,
    noRedirect?: boolean
  ) => void;
  resourceId: number;
  resource: string;
  url: string;
  customLabel?: string;
  customRedirect?: string;
  backendUrl?: string;
  smallButton?: boolean;
  noRedirect?: boolean;
}

const DeleteButtonContainer: React.FC<
  DeleteRouteButtonContainerContainerProps
> = (props) => {
  const t = useTranslate("DeleteButtonContainer");

  const label =
    typeof props.customLabel === "undefined"
      ? t("removeButtonLabel")
      : props.customLabel;
  const confirmationDialog = useConfirmationDialog();
  const handleBtnClick = async () => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("confirmationDescription")
    });
    props.handleClick(
      props.resourceId,
      props.resource,
      props.url,
      props.customRedirect,
      props.backendUrl,
      props.noRedirect
    );
  };
  return (
    <DeleteResourceButton
      id="resource-delete-resource"
      label={label}
      handleClick={handleBtnClick}
      resourceId={props.resourceId}
      resource={props.resource}
      url={props.url}
      backendUrl={props.backendUrl}
      customRedirect={props.customRedirect}
      smallButton={props.smallButton}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  handleClick: (
    resourceId: number,
    resource: string,
    url: string,
    customRedirect?: string,
    backendUrl?: string,
    noRedirect?: boolean
  ) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        resource,
        `${backendUrl || url}/${resourceId}/`,
        noRedirect
          ? undefined
          : `/${customRedirect ? customRedirect : url}?same_tab=true`,
        undefined,
        resourceId
      )
    );
  }
});

export default connect(
  null,
  mapDispatchToProps
)((props: DeleteRouteButtonContainerContainerProps) => (
  <ConfirmationDialogServiceProvider>
    <DeleteButtonContainer {...props} />
  </ConfirmationDialogServiceProvider>
));
