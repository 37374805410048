import React from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, Button } from "@material-ui/core";
import { Link, LinkProps } from "react-router-dom";
import DeleteButtonContainer from "../containers/DeleteButtonContainer";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslate } from "../services/appLanguageService";
import { mapTabRouteProps } from "../helpers/routesHelper";

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      marginTop: 20
    },
    btnSpacing: {
      minWidth: "auto",
      marginLeft: 20,
      justifyContent: "spaceBetween"
    },
    btnBack: {
      minWidth: "auto",
      [theme.breakpoints.down("sm")]: {
        minWidth: 220
      }
    },
    deleteBtn: {
      minWidth: "auto",
      marginLeft: 20,
      [theme.breakpoints.down("sm")]: {
        minWidth: 130
      }
    },
    editButton: {
      marginLeft: 20
    },
    editIcon: {
      fontSize: theme.spacing(2.5),
      marginRight: theme.spacing(0.5)
    },
    btnContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        overflowX: "auto",
        width: "100%"
      }
    }
  });

interface ViewActionButtonsProps extends WithStyles<typeof styles> {
  resourceId: number;
  resource: string;
  url: string;
  backendUrl?: string;
  backUrl?: string;
  hideEdit?: boolean;
  customBackLabel?: string;
}

const ViewActionButtons: React.FC<ViewActionButtonsProps> = (props) => {
  const { classes, resourceId, resource, url, backendUrl } = props;
  const t = useTranslate("ViewActionButtons");
  const lcResource = resource.toLowerCase();

  const backUrl = typeof props.backUrl !== "undefined" ? props.backUrl : url;

  return (
    <div className={classes.btnContainer}>
      <Button
        className={classes.btnBack}
        id={`${lcResource}-to-${lcResource}s`}
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/${backUrl}?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon />{" "}
        {props.customBackLabel
          ? props.customBackLabel
          : t(lcResource + "NavigationButtonLabel")}
      </Button>
      {!(typeof props.hideEdit !== "undefined" && props.hideEdit) && (
        <Button
          id={`${lcResource}-edit-${lcResource}`}
          data-cy={`${lcResource}-edit-${lcResource}`}
          className={classes.btnSpacing}
          variant="contained"
          color="primary"
          component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
            (props, ref) => (
              <Link
                to={`/${url}/${resourceId}/edit`}
                {...props}
                ref={ref as any}
              />
            )
          )}
        >
          <EditIcon className={classes.editIcon} />
          {t("editButtonLabel")}
        </Button>
      )}
      <div className={classes.deleteBtn}>
        <DeleteButtonContainer
          resourceId={resourceId}
          resource={resource}
          url={url}
          backendUrl={backendUrl}
          {...mapTabRouteProps(props)}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(ViewActionButtons);
