import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  FormHelperText,
  TextField,
  CircularProgress,
  Typography
} from "@material-ui/core";
import {
  getBaseContractItemsAPI,
  getContractTasksAPI
} from "../../../services/api-declaration";
import { IContractSummaryType, ServiceCategory } from "../../../redux/types";
import { copyContractItemAPI } from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import * as Yup from "yup";
import { ValidationError } from "yup";

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    }
  })
);

interface CopyContractItemDialogProps {
  contractId: number;
  handleClose: () => void;
  onCopy: (itemId: number, type: IContractSummaryType) => void;
  itemType: IContractSummaryType;
  itemId: number;
  initialValues: {
    name: string,
    price: number,
  }
  serviceCategories: ServiceCategory[];
  businessAreaNames: Map<number, string>;
}

const CopyContractItemDialog: React.FC<CopyContractItemDialogProps> = ({
  handleClose,
  onCopy,
  contractId,
  itemType,
  itemId,
  initialValues,
  serviceCategories,
  businessAreaNames
}) => {
  const [tasks, setTasks] = useState<
    Awaited<ReturnType<typeof getContractTasksAPI>>["results"]
  >([]);
  const [baseItems, setBaseItems] = useState<
    Awaited<ReturnType<typeof getBaseContractItemsAPI>>
  >([]);
  const [state, setState] = useState<{
    task: number;
    serviceCategoryId: number;
    name: string;
    price: string;
  }>({
    task: 0,
    serviceCategoryId: 0,
    name: initialValues.name,
    price: initialValues.price.toString(),
  });
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {}
  );
  const [loading, setLoading] = useState(false);
  const classes = useModalStyles();
  const t = useTranslate("ContractPage");

  const baseCategoryIds = baseItems?.flatMap((b) => b.categories);
  const serviceCategoryOptions = serviceCategories.filter((serviceCategory) =>
    baseCategoryIds?.includes(serviceCategory.id)
  );

  useEffect(() => {
    let alive = true;
    void Promise.all([
      getContractTasksAPI({ customer_contracts: contractId }),
      getBaseContractItemsAPI(contractId, { item_id: itemId })
    ]).then(([{ results: tasks }, baseItems]) => {
      if (alive) {
        setTasks(tasks);
        setBaseItems(baseItems);
      }
    });
    return () => {
      alive = false;
    };
  }, [contractId, itemId]);

  const schema = Yup.object().shape({
    task: Yup.number(),
    serviceCategoryId: Yup.number(),
    name: Yup.string()
      .required(t("fieldRequired"))
      .max(255, t("fieldLengthExceeded")),
    price: Yup.string()
      .required(t("fieldRequired"))
      .matches(/^\d+$/, t("fieldDigitOnly"))
  });

  const validateFields = (...fields: (keyof typeof state)[]): void => {
    let next = { ...errors };
    void Promise.all(
      fields.map((field) =>
        schema
          .validateAt(field, state)
          .then(() => field in next && delete next[field])
          .catch(
            (err: ValidationError) => (next[field] = err.errors.join(", "))
          )
      )
    ).then(() => setErrors(next));
  };

  const disabled = !state.name || !state.price || !!Object.keys(errors).length;

  const handleCreate = () => {
    if (state && !disabled) {
      setLoading(true);
      void copyContractItemAPI(contractId, {
        type: itemType,
        task_id: state.task || null,
        service_category_id: state.serviceCategoryId || null,
        item_id: itemId,
        name: state.name,
        price: parseInt(state.price)
      })
        .then((r) => {
          onCopy(r.id, r.type);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (state && disabled) {
      validateFields(...(Object.keys(state) as (keyof typeof state)[]));
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
    >
      <DialogTitle>{t("copyContractItemLabel")}</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <InputLabel>{t("serviceCategoriesLabel")}</InputLabel>
          <Select
            value={state.serviceCategoryId}
            onChange={(e) =>
              setState({
                ...state,
                serviceCategoryId: e.target.value as number
              })
            }
            onBlur={() => validateFields("serviceCategoryId")}
            error={!!errors["serviceCategoryId"]}
            disabled={serviceCategoryOptions.length <= 1}
            fullWidth
          >
            <MenuItem value={0}>{t("allLabel")}</MenuItem>
            {serviceCategoryOptions.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {!!errors["serviceCategoryId"] && (
            <FormHelperText error>{errors["serviceCategoryId"]}</FormHelperText>
          )}
        </div>

        <div>
          <InputLabel>{t("tasksLabel")}</InputLabel>
          <Select
            value={state.task}
            onChange={(e) =>
              setState({ ...state, task: e.target.value as number })
            }
            onBlur={() => validateFields("task")}
            error={!!errors["task"]}
            disabled={tasks.length <= 1}
            fullWidth
          >
            <MenuItem value={0}>{t("allLabel")}</MenuItem>
            {tasks.map(({ id, name, businessarea }) => (
              <MenuItem key={id} value={id}>
                <div>
                  <Typography component="div" variant="body1">
                    {name}
                  </Typography>
                  <Typography component="div" variant="subtitle2">
                    {businessAreaNames.get(businessarea) ?? ""}
                  </Typography>
                </div>
              </MenuItem>
            ))}
          </Select>
          {!!errors["task"] && (
            <FormHelperText error>{errors["task"]}</FormHelperText>
          )}
        </div>

        <div>
          <InputLabel htmlFor="name">{t("nameLabel")}</InputLabel>
          <TextField
            name="name"
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
            onBlur={() => validateFields("name")}
            error={!!errors["name"]}
            helperText={errors["name"]}
            fullWidth
          />
        </div>

        <div>
          <InputLabel htmlFor="price">{t("priceLabel")}</InputLabel>
          <TextField
            name="price"
            value={state.price}
            onChange={(e) => setState({ ...state, price: e.target.value })}
            onBlur={() => validateFields("price")}
            error={!!errors["price"]}
            helperText={errors["price"]}
            fullWidth
          />
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          aria-label="cancel"
          onClick={handleClose}
          color="primary"
        >
          {t("cancelLabel")}
        </Button>
        <Button
          variant="contained"
          aria-label="add"
          startIcon={loading ? <CircularProgress size={20} /> : undefined}
          onClick={() => handleCreate()}
          disabled={loading}
          color="primary"
        >
          {t("copyLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyContractItemDialog;
