import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  BusinessArea,
  ContractingProfile,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import BusinessAreaView from "../../../components/contracts/businessAreas/BusinessArea";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface BusinessAreaContainerProps
  extends TabComponentProps<"businessareaId"> {
  businessarea: BusinessArea;
  contractingProfile: ContractingProfile;
  loadingBusinessArea: boolean;
  resetBusinessArea: () => void;
  loadBusinessArea: (businessareaId: number) => void;
  loadContractingProfile: (contractingProfileId: number | null) => void;
}

const BusinessAreaContainer: React.FC<BusinessAreaContainerProps> = (props) => {
  const {
    resetBusinessArea,
    loadingBusinessArea,
    loadBusinessArea,
    loadContractingProfile,
    businessarea,
    contractingProfile,
    routeParams: { businessareaId }
  } = props;
  useEffect(
    () => loadBusinessArea(businessareaId),
    [loadBusinessArea, businessareaId]
  );
  useEffect(
    () => loadContractingProfile(businessarea.contractingprofile),
    [loadContractingProfile, businessarea.contractingprofile]
  );

  useEffect(() => () => resetBusinessArea(), [resetBusinessArea]);
  return (
    <div>
      <ViewActionButtons
        resourceId={businessareaId}
        resource={ReduxActionStrings.BUSINESSAREA}
        url="contracts/businessareas"
      />
      {loadingBusinessArea ? (
        <LoadingSpinnerPaper />
      ) : (
        <BusinessAreaView
          businessarea={businessarea}
          contractingProfile={contractingProfile}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadBusinessArea: (businessareaId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.BUSINESSAREA,
        `contracts/businessareas/${businessareaId}/`
      )
    );
  },
  loadContractingProfile: (contractingProfileId: number | null) => {
    if (contractingProfileId) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.CONTRACTINGPROFILE,
          `contracts/contractingprofiles/${contractingProfileId}/`
        )
      );
    }
  },
  resetBusinessArea: () =>
    dispatch(LoadingBooleansActions.ResetBusinessArea(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  businessarea:
    state.tabStates[ownProps.tabId].businessAreas.businessArea.businessArea,
  contractingProfile:
    state.tabStates[ownProps.tabId].contractingProfiles.contractingProfile
      .contractingProfile,
  loadingBusinessArea:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingBusinessArea
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessAreaContainer);
