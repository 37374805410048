import React from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {
  Cooperation,
  Task,
  SingnedCooperationsDialogData
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { ConfirmationDialogServiceProvider } from "../../confirmationDialog/ConfirmationDialogServiceProvider";

const styles = (theme: any) =>
  createStyles({
    table: {
      minWidth: 500
    }
  });

interface SignedCooperationsDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  handleClose: () => void;
  dialogData: SingnedCooperationsDialogData;
  removeSignedCooperation: (cooperationId: number) => void;
  handleDialogData: (dialogData: SingnedCooperationsDialogData) => void;
  signedCooperations: Cooperation[];
}

const SignedCooperationsDialog: React.FC<SignedCooperationsDialogProps> = (
  props
) => {
  const { handleClose, dialogData, classes, signedCooperations } = props;
  const t = useTranslate("ActiveCustomerobjectsPage");

  return (
    <Dialog
      data-cy="unsigned-cooperations-dialog"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {t("selectBusinessAreaLabel")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("multipleTasksToSelectDialog")}
        </DialogContentText>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">{t("businessareaLabel")}</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dialogData.tasks.map((task, index) => {
              return (
                <TableRow key={task.name}>
                  <TableCell align="left">{task.name}</TableCell>
                  <TableCell align="left">
                    <IconButton
                      data-cy={task.name}
                      aria-label="delete"
                      onClick={() => {
                        props.handleDialogData({
                          ...dialogData,
                          tasks: dialogData.tasks.filter(
                            (filteredTask: Task) => filteredTask.id !== task.id
                          )
                        });

                        const signedCooperation = signedCooperations.find(
                          (cooperation) => cooperation.task === task.id
                        );
                        if (signedCooperation && signedCooperation.id) {
                          props.removeSignedCooperation(signedCooperation.id);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button data-cy="ok-button" onClick={handleClose} color="primary">
          {t("okButtonLabel")}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t("closeButtonLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)((props: SignedCooperationsDialogProps) => (
  <ConfirmationDialogServiceProvider>
    <SignedCooperationsDialog {...props} />
  </ConfirmationDialogServiceProvider>
));
