import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  ProjectForm,
  Project,
  mainRootState,
  SelectFieldOption
} from "../../../redux/types";
import ProjectNewForm from "../../../components/contracts/projects/ProjectNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewProjectContainerProps
  extends FormikSubmitDispatchProps<ProjectForm> {
  loadBusinessAreas: (searchTerm?: string) => void;
  businessAreaOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Project | ProjectForm> {
  loadBusinessAreas: (searchTerm?: string) => void;
}

const NewProjectContainer: React.FC<NewProjectContainerProps> = (props) => {
  const { loadBusinessAreas } = props;

  useEffect(() => loadBusinessAreas(), [loadBusinessAreas]);
  return (
    <ProjectNewForm
      businessAreaOptions={props.businessAreaOptions}
      handleSubmit={props.handleSubmit}
      loadBusinessAreas={props.loadBusinessAreas}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<ProjectForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.PROJECT,
        "contracts/projects/",
        checkedNullValues as Project,
        "/contracts/projects?same_tab=true"
      )
    );
  },
  loadBusinessAreas: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.BUSINESSAREAS,
        `contracts/businessareas/?page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  businessAreaOptions: state.tabStates[
    ownProps.tabId
  ].businessAreas.businessAreas.results.map((businessArea) => ({
    label: businessArea.name,
    value: businessArea.id
  }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewProjectContainer);
