import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Button,
  Grid,
  InputLabel,
  TextField,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import {
  SelectFieldOption,
  PatchInvoiceStatusPayload,
  IDataCarryKey,
  PaginationFilterQuery,
  BulkInvoiceStatusUpdatePayload,
  BulkInvoiceStatusReturn,
  InvoiceFilterQuery,
  Invoice,
  OrderFilterType
} from "../../redux/types";
import { getDate } from "../FormatHelpers";
import { ConfirmationDialogServiceProvider } from "../confirmationDialog/ConfirmationDialogServiceProvider";
import { useTranslate } from "../../services/appLanguageService";
import InvoiceDetailTable from "./invoicesDetailTable/InvoicesDetailTable";
import LoadingSpinnerPaper from "../LoadingSpinnerPaper";
import {
  contractorBulkSendEmailAPI,
  customerBulkSendEmailAPI,
  getCompanyNamesAPI,
  getDistrictsAPI,
  getInvoicesAPI
} from "../../services/api-declaration";
import { Add, Search } from "@material-ui/icons";
import CreateInvoiceDialog from "./invoice/CreateInvoiceDialog";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { isValid, sub } from "date-fns";
import { APP_DATE_FORMAT } from "../../helpers/timesheetHelperService";
import FilterListIcon from "@material-ui/icons/FilterList";

const styles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2.5)
  },
  pageHeader: {
    padding: 0,
    paddingBottom: theme.spacing(1.5)
  },
  headerActions: {
    display: "flex",
    alignItems: "start",
    justifyContent: "flex-end",
    marginRight: theme.spacing(3),
    "& > *:last-child": {
      marginLeft: theme.spacing(2)
    }
  },
  mt_20: {
    marginTop: theme.spacing(2.5)
  },
  paperHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseLine",
    marginBottom: theme.spacing(1.5)
  },
  picker: {
    marginRight: theme.spacing(1)
  },
  pickerLabel: {
    fontSize: 12,
    color: "#43a047",
    marginTop: 10
  },
  select: {
    marginTop: 10
  },
  titleRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "#43a047"
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  flex: {
    display: "flex"
  }
}));

interface BaseInvoicesComponentProps {
  updateInvoiceStatus: (
    id: number,
    payload: PatchInvoiceStatusPayload
  ) => Promise<Invoice>;
  disableInvoiceWorkApprovalEditMode: IDataCarryKey;
  bulkInvoiceStatusUpdate: (
    payload: BulkInvoiceStatusUpdatePayload
  ) => Promise<BulkInvoiceStatusReturn>;
  bulkSendEmailUpdate:
    | typeof customerBulkSendEmailAPI
    | typeof contractorBulkSendEmailAPI;
  invoiceEntityLabel: string;
  companyType: "CUSTOMER" | "CONTRACTOR";
}

const defaultStartDate = sub(new Date(), { months: 1 });
const defaultEndDate = new Date();
const DEFAULT_PAGE_SIZE = 25;

const INVOICESSTATUSES = ["DRAFT", "READY", "EXPORTED", "CREDITED"];

const DEFAULT_QUERY: InvoiceFilterQuery = {
  filter__company: -1,
  filter__status__in: `[${INVOICESSTATUSES.join(",")}]`,
  page: 1,
  page_size: DEFAULT_PAGE_SIZE,
  filter__invoice_date__gte: getDate(defaultStartDate),
  filter__invoice_date__lte: getDate(defaultEndDate),
  order__invoice_date: "desc"
};

const BaseInvoicesComponent = ({
  bulkInvoiceStatusUpdate,
  bulkSendEmailUpdate,
  invoiceEntityLabel,
  companyType
}: BaseInvoicesComponentProps) => {
  const classes = styles();
  const t = useTranslate("InvoicesView");
  const t2 = useTranslate("Invoices");
  const queryTimerRef = useRef<number>();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState<InvoiceFilterQuery>(() => ({
    ...DEFAULT_QUERY
  }));
  const [searchError, setSearchError] = useState<string>();
  const [companyOptions, setCompanyOptions] = useState<SelectFieldOption[]>([]);
  const [districtFilterOptions, setDistrictFilterOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [modal, setModal] = useState<{ m: "create" }>();

  const statusOptions = INVOICESSTATUSES.map((status) => ({
    label: t2(status),
    value: status
  }));

  const orderFilters: OrderFilterType[] = [
    "order__invoice_date",
    "order__number",
    "order__status"
  ];

  const handlePaginateQuery = (paginationDetail: PaginationFilterQuery) => {
    setQuery((_query) => ({ ..._query, ...paginationDetail }));
  };

  const refreshInvoices = () => {
    setRefresh((r) => !r);
  };

  const handleBulkInvoiceStatusUpdate = async (
    payload: BulkInvoiceStatusUpdatePayload
  ) => {
    const response = await bulkInvoiceStatusUpdate(payload);
    refreshInvoices();
    return response;
  };

  const handleBulkSendEmail = async (
    payload: Parameters<typeof bulkSendEmailUpdate>[0]
  ) => {
    await bulkSendEmailUpdate(payload);
    refreshInvoices();
  };

  useEffect(() => {
    const is_valid =
      (!query.filter__invoice_date__gte ||
        isValid(new Date(query.filter__invoice_date__gte))) &&
      (!query.filter__invoice_date__lte ||
        isValid(new Date(query.filter__invoice_date__lte)));
    if (is_valid) {
      let alive = true;
      setIsLoading(true);
      if (queryTimerRef.current) {
        window.clearTimeout(queryTimerRef.current);
      }
      queryTimerRef.current = window.setTimeout(() => {
        void getInvoicesAPI(
          companyType === "CUSTOMER"
            ? "customerinvoices"
            : "contractorinvoices",
          query
        ).then(({ results: invoices, count }) => {
          if (alive) {
            setInvoices(invoices);
            setInvoicesCount(count);
            setIsLoading(false);
          }
        });
      }, 700);
      return () => {
        alive = false;
      };
    } else {
      if (queryTimerRef.current) {
        window.clearTimeout(queryTimerRef.current);
        queryTimerRef.current = undefined;
      }
      setInvoices([]);
      setInvoicesCount(0);
    }
  }, [companyType, query, refresh]);

  useEffect(() => {
    let alive = true;
    void Promise.all([
      getCompanyNamesAPI({ company_type: companyType }),
      getDistrictsAPI()
    ]).then(([{ results: companyNames }, { results: districts }]) => {
      if (alive) {
        setCompanyOptions(
          companyNames.map((cn) => ({ label: cn.name, value: cn.id }))
        );
        setDistrictFilterOptions(
          districts.map((d) => ({ label: d.name, value: d.id }))
        );
      }
    });
    return () => {
      alive = false;
    };
  }, [companyType]);

  const changeSorting = (orderByColumn: OrderFilterType) => {
    orderFilters
      .filter((f) => f !== orderByColumn)
      .forEach((f) =>
        setQuery((q) => {
          delete q[f];
          return { ...q };
        })
      );

    if (!query[orderByColumn]) {
      setQuery((q) => ({ ...q, [orderByColumn]: "desc" }));
    } else if (query[orderByColumn] === "desc") {
      setQuery((q) => ({ ...q, [orderByColumn]: "asc" }));
    } else if (query[orderByColumn] === "asc") {
      setQuery((q) => {
        delete q[orderByColumn];
        return { ...q };
      });
    }
  };

  const changeStatus = (newStatus: string | null) => {
    const newStatuses = newStatus ? [newStatus] : INVOICESSTATUSES;
    const preparedStatuses =
      query.filter__status__in?.indexOf("CANCELLED") !== -1
        ? [...newStatuses, "CANCELLED"]
        : newStatuses;
    setQuery((s) => ({
      ...s,
      filter__status__in: `[${[preparedStatuses].join(",")}]`
    }));
  };

  const addStatus = (newStatus: string) => {
    const prevValues = query.filter__status__in?.slice(1, -1).split(",") || [];
    setQuery((s) =>
      !prevValues.includes(newStatus)
        ? {
            ...s,
            filter__status__in: `[${[...prevValues, newStatus].join(",")}]`
          }
        : s
    );
  };
  const removeStatus = (newStatus: string) => {
    const prevValues = query.filter__status__in?.slice(1, -1).split(",") || [];
    const newValues = prevValues.filter((status) => status !== newStatus);
    if (newValues.length > 0) {
      setQuery((s) =>
        prevValues.includes(newStatus)
          ? {
              ...s,
              filter__status__in: `[${newValues.join(",")}]`
            }
          : s
      );
    } else {
      const { filter__status__in, ...newInvoiceFilterQuery } = query;
      setQuery(newInvoiceFilterQuery);
    }
  };
  const handleCancelled = (show: boolean) => {
    if (show) {
      addStatus("CANCELLED");
    } else {
      removeStatus("CANCELLED");
    }
  };

  const getStatusOption = () => {
    if (query?.filter__status__in) {
      const statuses = query.filter__status__in.slice(1, -1).split(",");
      if (statuses.length === 1 && statuses[0] !== "CANCELLED") {
        return { label: t2(statuses[0]), value: statuses[0] };
      } else if (statuses.length === 2) {
        const normalStatus = statuses.find((s) => s !== "CANCELLED");
        return { label: t2(normalStatus), value: normalStatus || "" };
      }
    }
    return null;
  };

  const onSearchChanged = (value: string | undefined) => {
    const is_number = value && !/\D/.test(value);
    setSearchError(value && !is_number ? t("fieldNotANumberError") : undefined);
    setQuery((q) => {
      if (is_number) {
        return { ...q, filter__number: value };
      } else {
        delete q.filter__number;
        return { ...q };
      }
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.mt_20}>
        <Accordion>
          <AccordionSummary>
            <div className={classes.titleRow}>
              <Typography component="div" className={`${classes.flex}`}>
                <FilterListIcon className={classes.leftIcon} /> Filter
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setQuery({
                    filter__status__in: `[${INVOICESSTATUSES.join(",")}]`,
                    page: 1,
                    page_size: DEFAULT_PAGE_SIZE
                  });
                }}
              >
                {t("clearButton")}
              </Button>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <InputLabel className={classes.pickerLabel}>
                  {companyType === "CUSTOMER"
                    ? t("customerFilterLabel")
                    : t("contractorFilterLabel")}
                </InputLabel>
                <Autocomplete
                  className={classes.select}
                  value={
                    companyOptions.find(
                      (companyOption) =>
                        companyOption.value === query.filter__company
                    ) || { label: "", value: -1 }
                  }
                  onChange={(
                    event: any,
                    newCompany: SelectFieldOption | null
                  ) => {
                    if (newCompany === null) {
                      setQuery((q) => {
                        const { filter__company, ...newQuery } = q;
                        return newQuery;
                      });
                    } else {
                      setQuery((tempQuery) => ({
                        ...tempQuery,
                        filter__company: newCompany?.value
                      }));
                    }
                  }}
                  options={companyOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        companyType === "CUSTOMER"
                          ? t("customerFilterLabel")
                          : t("contractorFilterLabel")
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel className={classes.pickerLabel}>
                  {t("statusLabel")}
                </InputLabel>
                <Autocomplete
                  className={classes.select}
                  value={getStatusOption()}
                  onChange={(
                    event: any,
                    newStatus: { label: string; value: string } | null
                  ) => {
                    changeStatus(newStatus?.value || null);
                  }}
                  options={statusOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("statusLabel")} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel className={classes.pickerLabel}>
                  {t("districtLabel")}
                </InputLabel>
                <Autocomplete
                  className={classes.select}
                  value={
                    districtFilterOptions.find(
                      (districtFilterOption) =>
                        districtFilterOption.value === query.filter__district
                    ) || { label: "", value: -1 }
                  }
                  onChange={(
                    event: any,
                    newIr: { label: string; value: number } | null
                  ) => {
                    if (newIr === null) {
                      setQuery((q) => {
                        const { filter__district, ...newQuery } = q;
                        return newQuery;
                      });
                    } else {
                      setQuery((tempQuery) => ({
                        ...tempQuery,
                        filter__district: newIr?.value
                      }));
                    }
                  }}
                  options={districtFilterOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("districtLabel")} />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <InputLabel className={classes.pickerLabel}>
                  {t("startDateFilterLabel")}
                </InputLabel>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  clearable
                  animateYearScrolling
                  className={classes.picker}
                  format={APP_DATE_FORMAT}
                  value={query.filter__invoice_date__gte || null}
                  onChange={(_date, value) => {
                    setQuery((q) => ({
                      ...q,
                      filter__invoice_date__gte: value ?? ""
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel className={classes.pickerLabel}>
                  {t("endDateFilterLabel")}
                </InputLabel>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  clearable
                  animateYearScrolling
                  className={classes.picker}
                  format={APP_DATE_FORMAT}
                  value={query.filter__invoice_date__lte || null}
                  onChange={(_date, value) => {
                    setQuery((q) => ({
                      ...q,
                      filter__invoice_date__lte: value ?? ""
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel className={classes.pickerLabel}>
                  {t("showCancelledLabel")}
                </InputLabel>
                <Checkbox
                  size="small"
                  checked={query.filter__status__in?.includes("CANCELLED")}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleCancelled(event.target.checked);
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <Typography
            component="h1"
            variant="h6"
            className={classes.pageHeader}
          >
            {companyType === "CUSTOMER"
              ? t("customerPageTitle")
              : t("contractorPageTitle")}
          </Typography>
          <div className={classes.headerActions}>
            <TextField
              InputProps={{
                startAdornment: <Search />
              }}
              label={t("searchFilterLabel")}
              value={query.filter__number ?? ""}
              onChange={(e) => onSearchChanged(e.target.value)}
              error={!!searchError}
              helperText={searchError || <>&nbsp;</>}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => setModal({ m: "create" })}
            >
              {t("newInvoiceLabel")}
            </Button>
          </div>
        </div>
        {!isLoading ? (
          <InvoiceDetailTable
            bulkInvoiceStatusUpdate={handleBulkInvoiceStatusUpdate}
            bulkSendInvoiceEmail={handleBulkSendEmail}
            handlePaginateQuery={handlePaginateQuery}
            invoices={invoices}
            query={query}
            invoiceEntityLabel={invoiceEntityLabel}
            count={invoicesCount}
            invoiceType={
              companyType === "CUSTOMER"
                ? "customerinvoices"
                : "contractorinvoices"
            }
            changeSorting={changeSorting}
          />
        ) : (
          <LoadingSpinnerPaper />
        )}
      </Paper>
      {modal?.m === "create" && (
        <CreateInvoiceDialog
          invoiceType={
            companyType === "CUSTOMER"
              ? "customerinvoices"
              : "contractorinvoices"
          }
          onAdd={() => {
            refreshInvoices();
            setModal(undefined);
          }}
          onClose={() => setModal(undefined)}
        />
      )}
    </div>
  );
};

export default function BaseInvoices(props: BaseInvoicesComponentProps) {
  return (
    <ConfirmationDialogServiceProvider>
      <BaseInvoicesComponent {...props} />
    </ConfirmationDialogServiceProvider>
  );
}
