import React, { useState } from "react";
import { TableRow, TableCell, Checkbox, IconButton } from "@material-ui/core";
import { Group, User } from "../../redux/types";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import { useTranslate } from "../../services/appLanguageService";

interface PermissionsTableRowProps {
  permission: {
    categoryObject: string;
    access_rights: {
      [key: string]: {
        id: number;
        used: boolean;
      };
    };
  };
  permissionIds: number[];
  permissionsHolder: Group | User;
  handleOnChange: (values: any) => void;
  handleOnDelete: (values: any) => void;
  editable: boolean;
}

const PermissionsTableRow: React.FC<PermissionsTableRowProps> = (props) => {
  const t = useTranslate("Permissions");

  const [category, object] = props.permission.categoryObject.split("--");

  const [checkbox, setCheckbox] = useState(
    props.permission.access_rights.EDIT && props.permission.access_rights.EDIT.used
  );

  return (
    <TableRow>
      <TableCell align="center">
        {props.editable ? (
          <IconButton
            id={`permission-${props.permission.categoryObject}-delete`}
            color="secondary"
            onClick={() => {
              let permissionIdsToRemove = [];

              for (const accessRight in props.permission.access_rights) {
                if (props.permission.access_rights[accessRight].used) {
                  permissionIdsToRemove.push(
                    props.permission.access_rights[accessRight].id
                  );
                }
              }

              props.handleOnDelete({
                permissionIdsToRemove: permissionIdsToRemove,
                permissions: props.permissionIds,
                permissionsHolder: props.permissionsHolder
              });
            }}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
        ) : (
          ""
        )}
      </TableCell>
      <TableCell align="left">{t(category)}</TableCell>
      <TableCell align="left">{t(object)}</TableCell>
      <TableCell align="center" padding="checkbox">
        {props.editable ? (
          <Checkbox
            id={`permission-${props.permission.categoryObject}-checkbox`}
            checked={checkbox}
            onClick={() => {
              setCheckbox(!checkbox);
              props.handleOnChange({
                permission_id: props.permission.access_rights.EDIT.id,
                permissions: props.permissionIds,
                permissionsHolder: props.permissionsHolder
              });
            }}
          />
        ) : checkbox ? (
          <DoneIcon />
        ) : (
          ""
        )}
      </TableCell>
    </TableRow>
  );
};

export default PermissionsTableRow;
