import { InvoicingProfile } from "../redux/types";
import {
  getContractorInvoiceProfileTestPdfAPI,
  getCustomerInvoiceProfileTestPdfAPI
} from "./api-declaration";
import { fetchPatch } from "./fetchWrapper";

export async function getContractorInvoiceProfileTestPdf(
  id: number,
  contractorInvoiceId: number
): Promise<string> {
  try {
    const invoicePDF: Blob = await getContractorInvoiceProfileTestPdfAPI(
      id,
      contractorInvoiceId
    );
    return Promise.resolve(window.URL.createObjectURL(invoicePDF));
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getCustomerInvoiceProfileTestPdf(
  id: number,
  customerInvoiceId: number
): Promise<string> {
  try {
    const invoicePDF: Blob = await getCustomerInvoiceProfileTestPdfAPI(
      id,
      customerInvoiceId
    );
    return Promise.resolve(window.URL.createObjectURL(invoicePDF));
  } catch (e) {
    return Promise.reject(e);
  }
}

export const patchInvoiceProfile = async (
  id: number,
  values: object
): Promise<InvoicingProfile> => {
  try {
    const response: InvoicingProfile = await fetchPatch(
      `${localStorage.getItem("baseURL")}invoices/invoicingprofiles/${id}/`,
      values
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
