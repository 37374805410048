import React, { useEffect, useState, useRef } from "react";
import {
  IconButton,
  Button,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  WithStyles,
  TableContainer
} from "@material-ui/core";
import {
  NoteAdd as NoteAddIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@material-ui/icons";
import { createStyles } from "@material-ui/core/styles";
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  FormikProps
} from "formik";
import {
  Task,
  RoutePlan,
  FormikSubmitDispatchProps,
  SelectFieldOption,
  RouteParticipantForm,
  RouteSegment,
  RouteInstanceDeleteDialogOptions,
  RouteInstanceProgress,
  RouteInstance,
  RouteInstanceForm,
  Service,
  FeatureCollection
} from "../../../redux/types";
import GoogleMaps, { MarkersRef } from "../../googleMaps/GoogleMaps";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";
import { DialogComponent } from "../../confirmationDialog/RouteInstanceDialog";
import { useTranslate } from "../../../services/appLanguageService";
import { useMapAndTableHoverEventState } from "../../../helpers/mapsHelperService";
import RouteSegmentMapDialog from "./RouteSegmentMapDialog";
import { generateRouteSegmentRowId } from "../../../helpers/routesHelper";
import RouteSegmentsTableRow from "./RouteSegmentsTableRow";
import {
  createContractCooperationAPI,
  getActiveContractIdsByCompanyIdAPI,
  getContractCooperationsAPI,
  getSearchServiceCategoryOptionsAPI,
  getSearchTaskOptionsAPI,
  getServicesAPI
} from "../../../services/api-declaration";

const styles = (theme: any) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down("sm")]: {
        padding: 1,
        whiteSpace: "nowrap",
        overflowY: "scroll"
      }
    },
    table: {
      minWidth: 800
    },
    participantNumber: {
      width: 30
    },
    textField: {
      width: 270,
      marginRight: theme.spacing(2)
    },
    mapToggleBtnContainer: {
      marginTop: theme.spacing(2.5),
      display: "flex",
      justifyContent: "flex-end"
    },
    mapContainer: {
      flex: "500px",
      minHeight: 500,
      transition: "all 1s cubic-bezier(0.25, 0.8, 0.25, 1)",
      marginTop: theme.spacing(2.5)
    },
    map: {
      height: "50rem"
    },
    segmentsFormContainer: {},
    mapSegmentsContainer: {
      display: "flex",
      flexWrap: "nowrap"
    },
    segments: {
      marginTop: theme.spacing(2.5),
      flex: "300px",
      minHeight: 500,
      transition: "all 1s cubic-bezier(0.25, 0.8, 0.25, 1)",
      flexWrap: "wrap"
    },
    hideElement: {
      display: "none"
    },
    toggleMap: {
      fontSize: theme.spacing(2.5),
      marginRight: theme.spacing(0.5)
    },
    addNoteCell: {
      border: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    disabledPaper: { backgroundColor: "whitesmoke" },
    disabled: { pointerEvents: "none" },
    bold: {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 5
      }
    },
    errorMessage: {
      color: "red"
    }
  });

interface RoutePlanSegmentsProps
  extends FormikSubmitDispatchProps<any>,
    WithStyles<typeof styles> {
  contractorCompanies: SelectFieldOption[];
  formikProps: FormikProps<RouteInstanceForm>;
  isDispatched: boolean;
  segmentRowIds: number[] | undefined;
  readOnlyMode?: boolean;
  participantsStatus: RouteInstanceProgress[];
  route?: RouteInstance | RoutePlan;
  rowErrorIds: number[];
  clearErrorMessages: () => void;
}

const RoutePlanSegments: React.FC<RoutePlanSegmentsProps> = (props) => {
  const defaultProps = {
    readOnlyMode: false
  };
  const {
    classes,
    contractorCompanies,
    formikProps,
    isDispatched,
    segmentRowIds,
    readOnlyMode,
    participantsStatus,
    route
  } = Object.assign(defaultProps, props);
  const t = useTranslate("NewRouteInstancePage");
  const d = useTranslate("RouteInstanceDialog");

  const initialDialogValues: RouteInstanceDeleteDialogOptions = {
    show: false,
    description: "",
    rowIndex: -1
  };
  const [dialogContent, setDialogContent] =
    useState<RouteInstanceDeleteDialogOptions>(initialDialogValues);
  const [isMap, setIsMap] = useState<boolean>(false);
  const [disableDrag, setDisableDrag] = useState<boolean>(true);
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const markersRef = useRef<MarkersRef[] | null>(null);
  const [, onHoverRowIn, onHoverRowOut, onMarkerIn, onMarkerOut] =
    useMapAndTableHoverEventState<Task>(
      markersRef.current || [],
      "id",
      !isMap || isDragActive
    );
  const [taskOptions, setTaskOptions] = useState<
    { value: number; label: string; businessAreaName: string }[]
  >([]);
  const [allServiceCategoryOptions, setAllServiceCategoryOptions] = useState<
    SelectFieldOption[]
  >([]);

  const [mapDialogData, setMapDialogData] = useState<{
    row: RouteSegment | null;
    geo_polygons: FeatureCollection | null;
    open: boolean;
    arrHelpers: FieldArrayRenderProps | null;
    index: number | null;
    editable: boolean;
  }>({
    row: null,
    geo_polygons: null,
    open: false,
    arrHelpers: null,
    index: null,
    editable: false
  });

  const [contractorLookup, setContractorLookup] = useState<{
    [contractorId: number]: {
      contractId: number;
      services: Service[];
      cooperationTaskIds: Set<number>;
    };
  }>({});
  // This creates a comparible effect dependency for a list of ids
  const contractorKey = formikProps.values.participants
    .map((p) => p.contractor)
    .join("_");
  useEffect(() => {
    let alive = true;
    const contractors = formikProps.values.participants
      .map((p) => p.contractor)
      .filter((p) => p !== -1);
    void Promise.all(
      contractors.map((companyId) =>
        getActiveContractIdsByCompanyIdAPI(companyId).then(
          async ({ results }) =>
            [
              companyId,
              {
                contractId: results[0].id,
                services: await getServicesAPI({
                  contract: results[0].id
                }).then((r) => r.results),
                cooperationTaskIds: await getContractCooperationsAPI({
                  contract_id: results[0].id
                }).then((r) => new Set(r.results.map((r) => r.task)))
              }
            ] as const
        )
      )
    ).then(
      (entries) => alive && setContractorLookup(Object.fromEntries(entries))
    );
    return () => {
      alive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractorKey]);

  const handleClose = () =>
    setMapDialogData({
      ...mapDialogData,
      open: false
    });

  const toggleTasksMap = () => {
    setIsMap((toggle) => !toggle);
  };
  const setMarkerRef = (refs: MarkersRef[]) => {
    markersRef.current = refs;
  };
  const reorder = (list: RouteSegment[], start: number, end: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(start, 1);
    result.splice(end, 0, removed);
    return result;
  };
  const reorderSegments = ({
    source: { index: source },
    destination: drop
  }: DropResult) => {
    toggleDragStatus();
    if (!drop || drop.index === source) return;
    const { index: destination } = drop;
    const segments = reorder(formikProps.values.rows, source, destination);
    formikProps.setValues({
      ...formikProps.values,
      rows: segments
    });
    props.clearErrorMessages();
  };
  const toggleDrag = () => setDisableDrag((_drag) => !_drag);
  const toggleDragStatus = () => setIsDragActive((_drag) => !_drag);
  const showDialogWithData =
    (rowId: number, index: number, arrHelpers: FieldArrayRenderProps) => () => {
      const taskName: string | undefined = taskOptions.find(
        (task) => task.value === formikProps.values.rows[index]?.task
      )?.label;
      setDialogContent({
        show: !dialogContent.show,
        rowIndex: index,
        arrayHelpers: arrHelpers,
        description:
          isDispatched && segmentRowIds && segmentRowIds.includes(rowId)
            ? `${d("lockedRowDescription1")}${
                taskName ? ` ${taskName} ` : ""
              }${d("lockedRowDescription")}`
            : `${d("unLockedRowDescription")}${taskName ? ` ${taskName}` : ""}?`
      });
    };
  const onCloseDialog = () => setDialogContent(initialDialogValues);
  const onDeleteDialogSuccess = () => {
    if (dialogContent.arrayHelpers) {
      dialogContent.arrayHelpers.remove(dialogContent.rowIndex);
      onCloseDialog();
      props.clearErrorMessages();
    }
  };

  const handleMapDialog = (
    row: RouteSegment,
    geo_polygons: FeatureCollection | null,
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => {
    setMapDialogData({
      row: row,
      geo_polygons: geo_polygons,
      open: true,
      arrHelpers: arrayHelpers,
      index: index,
      editable: !(
        readOnlyMode ||
        (isDispatched && segmentRowIds && segmentRowIds.includes(row.row_id))
      )
    });
  };

  const availableParticipantsIds = new Set(
    formikProps.values.participants.map(({ participant_id }) => participant_id)
  );
  const segmentParticipantIds = formikProps.values.rows.flatMap(
    (r) => r.participants
  );
  if (
    segmentParticipantIds.length > 0 &&
    segmentParticipantIds.some((id) => !availableParticipantsIds.has(id))
  ) {
    formikProps.values.rows.forEach((segment, index) =>
      formikProps.setFieldValue(
        `rows.${index}.participants`,
        segment.participants.filter((participant) =>
          availableParticipantsIds.has(participant)
        )
      )
    );
  }

  useEffect(() => {
    let alive = true;
    void getSearchTaskOptionsAPI().then(
      (response) =>
        alive &&
        setTaskOptions(
          response.map((task) => ({
            label: task.name,
            value: task.id,
            businessAreaName: task.businessAreaName
          }))
        )
    );
    return () => {
      alive = true;
    };
  }, []);

  useEffect(() => {
    let alive = true;
    (async () => {
      const serviceCategoriesResponse =
        await getSearchServiceCategoryOptionsAPI("");
      if (alive) {
        setAllServiceCategoryOptions(
          serviceCategoriesResponse.results.map((servicecategory) => ({
            label: servicecategory.name,
            value: servicecategory.id
          }))
        );
      }
    })();

    return () => {
      alive = false;
    };
  }, []);

  const addCooperation = async (companyId: number, taskId: number) => {
    const contractId = contractorLookup[companyId]?.contractId;
    if (contractId) {
      await createContractCooperationAPI({ taskId, contractId });
      const cooperationTaskIds =
        contractorLookup[companyId]?.cooperationTaskIds ?? new Set();
      cooperationTaskIds.add(taskId);
      setContractorLookup((l) => ({
        ...l,
        [companyId]: {
          ...l[companyId],
          cooperationTaskIds: cooperationTaskIds
        }
      }));
    }
  };

  return (
    <DragDropContext onDragEnd={reorderSegments} onDragStart={toggleDragStatus}>
      <div className={classes.mapToggleBtnContainer}>
        <Button
          variant="outlined"
          color="primary"
          onClick={toggleTasksMap}
          disabled={Boolean(!formikProps.values.rows.length) || true}
        >
          {isMap ? (
            <VisibilityOffIcon className={classes.toggleMap} />
          ) : (
            <VisibilityIcon className={classes.toggleMap} />
          )}
          {t("toggleMapButtonText")}
        </Button>
      </div>
      <div className={classes.mapSegmentsContainer}>
        <Paper
          className={`${classes.paper} ${classes.segments} ${
            isMap ? classes.segmentsFormContainer : ""
          }  ${!readOnlyMode && isDispatched && classes.disabledPaper}`}
          style={isMap ? { marginRight: "1.3rem" } : { marginRight: 0 }}
        >
          <Typography component="h1" variant="h6" className={classes.bold}>
            {t("routesegmentsLabel")}
          </Typography>
          <TableContainer>
            <Table
              className={`${isMap ? "" : classes.table} ${
                readOnlyMode && classes.disabled
              }`}
            >
              <TableHead>
                {isMap ? (
                  <TableRow>
                    <TableCell
                      className={classes.participantNumber}
                      align="center"
                    >
                      {t("numberLabel")}
                    </TableCell>
                    <TableCell>{t("taskLabel")}</TableCell>
                    {!readOnlyMode && <TableCell />}
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>{t("routeSegmentParticipantsLabel")}</TableCell>
                    <TableCell className={classes.textField}>
                      {t("taskLabel")}
                    </TableCell>
                    <TableCell className={classes.textField}>
                      {t("serviceCategoryLabel")}
                    </TableCell>
                    <TableCell>{t("instructionsLabel")}</TableCell>
                    <TableCell>{t("mapLabel")}</TableCell>
                    {!readOnlyMode && (
                      <>
                        <TableCell
                          className={classes.participantNumber}
                          align="center"
                        >
                          {t("removeLabel")}
                        </TableCell>
                        <TableCell />
                      </>
                    )}
                  </TableRow>
                )}
              </TableHead>
              <FieldArray name="rows">
                {(arrayHelpers: FieldArrayRenderProps) => (
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {formikProps.values.rows.map((row, index) => {
                          const rowStatus = participantsStatus.find(
                            (item) => item.row_id === row.row_id
                          );
                          const serviceCategorySet = new Set(
                            formikProps.values.participants
                              .filter((p) =>
                                row.participants.includes(p.participant_id)
                              )
                              .flatMap(
                                (p) =>
                                  contractorLookup[p.contractor]?.services.find(
                                    (s) => s.id === p.service
                                  )?.categories ?? []
                              )
                          );
                          const serviceCategoryOptions =
                            allServiceCategoryOptions.filter((c) =>
                              serviceCategorySet.has(c.value)
                            );

                          return (
                            <Draggable
                              key={`${row.row_id}`}
                              isDragDisabled={disableDrag}
                              draggableId={`${row.row_id}`}
                              index={index}
                            >
                              {(provided) => (
                                <RouteSegmentsTableRow
                                  formikProps={formikProps}
                                  readOnlyMode={readOnlyMode}
                                  isDispatched={isDispatched}
                                  provided={provided}
                                  route={route}
                                  index={index}
                                  segmentRowIds={segmentRowIds}
                                  isMap={isMap}
                                  arrayHelpers={arrayHelpers}
                                  onHoverRowIn={onHoverRowIn}
                                  onHoverRowOut={onHoverRowOut}
                                  rowStatus={rowStatus}
                                  contractorCompanies={contractorCompanies}
                                  row={row}
                                  showDialogWithData={showDialogWithData}
                                  toggleDrag={toggleDrag}
                                  handleMapDialog={handleMapDialog}
                                  taskOptions={taskOptions}
                                  contractorTaskIdsLookup={(
                                    companyId: number
                                  ) =>
                                    contractorLookup[companyId]
                                      ?.cooperationTaskIds
                                  }
                                  addCooperation={addCooperation}
                                  serviceCategoryOptions={
                                    serviceCategoryOptions
                                  }
                                  error={props.rowErrorIds?.includes(index)}
                                />
                              )}
                            </Draggable>
                          );
                        })}
                        <TableRow
                          className={
                            readOnlyMode || isMap ? classes.hideElement : ""
                          }
                        >
                          <TableCell className={classes.addNoteCell}>
                            <IconButton
                              id={`routeplan-form-routesegment-new`}
                              color="primary"
                              onClick={() => {
                                const previousServiceCategories =
                                  formikProps.values.rows[
                                    formikProps.values.rows.length - 1
                                  ]?.servicecategories;

                                arrayHelpers.push({
                                  row_id: generateRouteSegmentRowId(
                                    formikProps.values.rows
                                  ),
                                  instructions: "",
                                  notes: null,
                                  task: -1,
                                  geo_polygons: null,
                                  participants: [
                                    ...formikProps.values.participants.map(
                                      (participant: RouteParticipantForm) =>
                                        participant.participant_id
                                    )
                                  ],
                                  servicecategories: previousServiceCategories
                                });
                              }}
                            >
                              {!isDragActive && (
                                <NoteAddIcon fontSize="large" />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                )}
              </FieldArray>
            </Table>
          </TableContainer>
          <ErrorMessage name="rows">
            {(msg) => (
              <div className={classes.errorMessage}>
                {typeof msg === "string" && msg}
              </div>
            )}
          </ErrorMessage>
        </Paper>
        <Paper
          className={classes.mapContainer}
          style={
            isMap
              ? {}
              : {
                  flex: 0
                }
          }
        >
          <GoogleMaps
            id="routeplanMap"
            markers={[]}
            options={{
              maxZoom: 18
            }}
            className={classes.map}
            onMarkerMouseover={onMarkerIn}
            onMarkerMouseout={onMarkerOut}
            markerRefs={setMarkerRef}
          />
        </Paper>
        <DialogComponent
          show={dialogContent.show}
          title={d("segmentDialogTitle")}
          description={dialogContent.description}
          successButtonLabel={d("removeButtonLabel")}
          closeButtonLabel={d("closeButtonLabel")}
          onClose={onCloseDialog}
          onSuccess={onDeleteDialogSuccess}
          dialogType="Remove"
          dispatchError={null}
        />
        <RouteSegmentMapDialog
          handleClose={handleClose}
          mapDialogData={mapDialogData}
        />
      </div>
    </DragDropContext>
  );
};
export default withStyles(styles)(RoutePlanSegments);
