import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  Dialog,
  CircularProgress,
  makeStyles,
  createStyles,
  Card,
} from "@material-ui/core";
import {
    IContractSummaryItem,
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import {
  sortContractItemsAPI
} from "../../../services/api-declaration";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

const useModalStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    },
    dialog: {
      height: "100%",
    },
    list: {
      backgroundColor: "#ccc",
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    item: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: "#fff",
    }
  })
);

interface ReorderContractItemsDialogProps {
  contractItems: IContractSummaryItem[];
  saveSortOrder: (
    payload: Parameters<typeof sortContractItemsAPI>[1]
  ) => Promise<void>;
  onClose: () => void;
}

const ReorderContractItemsDialog: React.FC<ReorderContractItemsDialogProps> = ({
  contractItems,
  saveSortOrder,
  onClose
}) => {
  const [visibleContractItems, setVisibleContractItems] = useState(() => {
    const groups = new Map<string, { key: string, name: string, sort_order: number, items: IContractSummaryItem[] }>()

    contractItems.forEach(ci => {
      if (!ci.display) {
        return;
      }
      let groupKey = `${ci.type}_${ci.base_id}`
      const group = groups.get(groupKey)
      if (group) {
        group.items.push(ci)
      } else {
        groups.set(groupKey, { key: groupKey, name: ci.base_name, sort_order: ci.sort_order, items: [ci] })
      }
    })

    return Array.from(groups.values()).sort((a, b) => a.sort_order - b.sort_order)
  })
  const [loading, setLoading] = useState(false);

  const classes = useModalStyles();
  const t = useTranslate("ContractPage");
  const v = useTranslate("ContractSummaryTypes");

  const reorderSegments = (result: DropResult, _provided: ResponderProvided) => {
    console.log("drop result", result)
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const cis = [...visibleContractItems]
    const [removed] = cis.splice(startIndex, 1);
    cis.splice(endIndex, 0, removed);
    setVisibleContractItems(cis)
  }

  const saveOrder = () => {
    setLoading(true)
    const payload = visibleContractItems
      .flatMap((group, index) => group.items.map((ci) => [ci, index + 1] as const))
      .filter(([ci, sort_order]) => ci.sort_order !== sort_order)
      .map(([ci, sort_order]) => ({
        type: ci.type,
        item_id: ci.id,
        sort_order
      }))
    void saveSortOrder(payload)
      .then(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <Dialog open={true} onClose={() => onClose()} fullWidth>
      <DialogTitle>{t("sortContractItemHeader")}</DialogTitle>
      <DragDropContext onDragEnd={reorderSegments}>
        <Droppable droppableId="items">
          {(provided, _snapshot) => (
          <div className={classes.list} {...provided.droppableProps} ref={provided.innerRef}>
            {visibleContractItems.map((ci, index) => {
              return <Draggable key={ci.key} draggableId={ci.key} index={index}>
                {(provided, _snapshot) => (
                  <Card
                    ref={provided.innerRef}
                    className={classes.item}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    [{v(ci.items[0].type)}] {ci.name}
                  </Card>
                )}
              </Draggable>
            })}
            {provided.placeholder}
          </div>
          )}
        </Droppable>
      </DragDropContext>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          aria-label="cancel"
          onClick={() => onClose()}
          color="primary"
        >
          {t("cancelLabel")}
        </Button>
        <Button
          variant="contained"
          aria-label="add"
          startIcon={loading ? <CircularProgress size={20} /> : undefined}
          onClick={() => saveOrder()}
          disabled={loading}
          color="primary"
        >
          {t("saveLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReorderContractItemsDialog;

