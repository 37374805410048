import React, { useState, useEffect } from "react";
import { Alert } from "@material-ui/lab";
import { Paper, Typography, Button, Grow, Link } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Formik, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import {
  FormikSubmitDispatchProps,
  ForgetPasswordSubmitValues
} from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import { history } from "../../redux/store";
import { sendResetPasswordEmailAPI } from "../../services/api-declaration";
import authenticationService from "../../services/authenticationService";
import { getFromSessionStore } from "../../services/storageService";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    paper: {
      width: 500,
      padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`
    },
    form: {
      width: "100%"
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    info: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3)
    },
    checkboxField: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    checkboxFieldLabel: {
      fontWeight: 600,
      color: "gray"
    },
    flexDiv: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 0"
    },
    descriptionText: { margin: `${theme.spacing(1.5)}px 0` }
  })
);

interface ForgottenPasswordProps
  extends FormikSubmitDispatchProps<ForgetPasswordSubmitValues> {}

export default function ForgottenPassword(props: ForgottenPasswordProps) {
  const [fade, setFade] = useState<boolean>(false);
  const classes = useStyles();
  const [userDetails, setUserDetails] = useState<ForgetPasswordSubmitValues>({
    domain: "",
    username: ""
  });
  const t = useTranslate("LoginPage");
  const t2 = useTranslate("ForgetPasswordPage");
  const [showInfoAlert, setShowInfoAlert] = useState(false);

  const ValidationSchema = Yup.object().shape({
    domain: Yup.string().min(1).max(255).required(t("fieldRequired")),
    username: Yup.string().min(1).max(255).required(t("fieldRequired"))
  });

  const initLogin = () => {
    const rememberUser = getFromSessionStore("rememberUser");
    if (rememberUser) {
      const { domain, username } = JSON.parse(rememberUser);
      setUserDetails({
        domain,
        username
      });
    }
  };

  const handleSend = async (
    ev: React.FormEvent<HTMLFormElement>,
    values: ForgetPasswordSubmitValues
  ) => {
    ev.preventDefault();
    try {
      if (values.domain && values.username) {
        const info = await authenticationService.getDomainInfo(values.domain);
        console.log("info ", info);
        const response = await sendResetPasswordEmailAPI(values.username);
        console.log("response ", response);
      }
    } catch (e) {
      console.warn(e);
    }
    setShowInfoAlert(true);
  };

  useEffect(initLogin, []);
  useEffect(() => {
    setTimeout(() => setFade(true), 100);
  }, []);

  return (
    <div className={classes.root}>
      <Grow timeout={3000} in={fade}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4">
            {t2("pageHeading")}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            className={classes.descriptionText}
          >
            {t2("pageDescription")}
          </Typography>
          <Typography
            component="h1"
            variant="subtitle2"
            className={classes.info}
          >
            {t("formInfo")}
          </Typography>

          <Formik
            initialValues={userDetails}
            enableReinitialize
            validationSchema={ValidationSchema}
            onSubmit={() => {}}
          >
            {(props) => {
              return (
                <form
                  className={classes.form}
                  onSubmit={(ev) => handleSend(ev, props.values)}
                  autoComplete="off"
                >
                  {showInfoAlert ? (
                    <Alert severity="info">{t2("infoBox")}</Alert>
                  ) : (
                    <>
                      <Field
                        id="login-domain"
                        type="text"
                        min="1"
                        max="255"
                        autoFocus
                        name="domain"
                        label={t("domainFieldLabel")}
                        placeholder={t("domainFieldPlaceholder")}
                        component={TextField}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                      />
                      <Field
                        id="login-username"
                        type="text"
                        min="1"
                        max="255"
                        name="username"
                        label={t("usernameFieldLabel")}
                        placeholder={t("usernameFieldPlaceholder")}
                        component={TextField}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                      />
                      <Button
                        id="login-submit"
                        type="submit"
                        size="large"
                        fullWidth
                        variant="text"
                        color="primary"
                        className={classes.submit}
                      >
                        {t2("sendButton")}
                      </Button>
                    </>
                  )}
                  <div className={classes.flexDiv}>
                    <Link
                      component="button"
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      {t2("navigateToLoginLabel")}
                    </Link>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Paper>
      </Grow>
    </div>
  );
}
