import React from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, Link } from "@material-ui/core";
import { Person } from "../redux/types";
import * as Router from "react-router-dom";

const styles = (theme: any) =>
  createStyles({
    contact: {
      borderLeft: "1px solid #212121",
      marginLeft: 7,
      paddingLeft: 7
    }
  });

interface EditableNotesProps extends WithStyles<typeof styles> {
  contactPerson: Person;
}

const EditableNotes: React.FC<EditableNotesProps> = (props) => {
  const { classes, contactPerson } = props;

  return (
    <>
      <Link
        color="inherit"
        component={React.forwardRef<
          HTMLAnchorElement,
          Partial<Router.LinkProps>
        >((props, ref) => (
          <Router.Link
            to={`/auth/persons/${contactPerson.id}`}
            {...props}
            ref={ref as any}
          />
        ))}
      >
        {`${contactPerson.first_name} ${contactPerson.last_name || ""}`}
      </Link>
      {contactPerson && contactPerson.phone1 && (
        <span className={classes.contact}>{contactPerson.phone1}</span>
      )}
      {contactPerson && contactPerson.email && (
        <Link
          className={classes.contact}
          color="inherit"
          href={`mailto:${contactPerson.email}`}
        >
          {`${contactPerson.email}`}
        </Link>
      )}
    </>
  );
};

export default withStyles(styles)(EditableNotes);
