import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import * as types from "../../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import { useTranslate } from "../../../services/appLanguageService";
import ResourceLink from "../../links/ResourceLink";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../../redux/types";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface CooperationProps
  extends WithStyles<typeof styles>,
    TabComponentProps {
  cooperation: types.Cooperation;
  task: types.Task | null;
  contract: types.Contract | null;
  person: types.Person | null;
  user: types.User | null;
}

const Cooperation: React.FC<CooperationProps> = (props) => {
  const { classes, cooperation, task, contract, person, user, tabId } = props;
  const t = useTranslate("CooperationPage");

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    store.dispatch(setTitle(cooperation.title, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cooperation]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("titleLabel")}
                  </TableCell>
                  <TableCell align="right">{cooperation.title}</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contract && (
                      <ResourceLink
                        label={contract.name}
                        id={contract.id}
                        url="contracts/contracts"
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("taskLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {task && (
                      <ResourceLink
                        label={task.name}
                        id={task.id}
                        url="contracts/tasks"
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("personLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {person && (
                      <ResourceLink
                        label={`${person.first_name} ${person.last_name}`}
                        id={person.id}
                        url="auth/persons"
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("acceptedByUserLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {user && (
                      <ResourceLink
                        label={user.username}
                        id={user.id}
                        url="auth/users"
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("acceptedTsLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {cooperation.accepted_ts &&
                      niceDate(cooperation.accepted_ts)}
                  </TableCell>
                </TableRow>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("priorityLabel")}
                  </TableCell>
                  <TableCell align="right">{cooperation.priority}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(cooperation.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(cooperation.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Cooperation);
