import {
  getContractorTimesheetsAPI,
  getCopyofTimesheetAPI,
  addTimesheetAPI,
  updateTimesheetRowsAPI,
  submitTimesheetAPI,
  getTimesheetOptionsForApprovalAPI,
  getContractorTimesheetAPI,
  createRowImageAPI,
  deleteRowImageAPI,
  updateRowImageAPI,
  getRowImagesAPI,
  getAllTimesheets,
  getTimesheetsAPI,
  getContractorFilteredTimesheetsAPI
} from "./api-declaration";
import {
  TimeSheetForm,
  TimeSheetEditForm,
  TimeSheet,
  TimeSheetRowsEditPayload,
  ImageRow,
  TimeSheetRow,
  UpdateRowImagePayload,
  TimesheetRowImageResponse,
  GetRowImagesQuery,
  TimesheetRowImage,
  TimesheetQuery
} from "../redux/types";

export async function getUserAssociatedTimesheets() {
  try {
    if (window.allowedPaths.includes("timesheets/timesheets")) {
      return Promise.resolve(await getAllTimesheets());
    } else {
      return Promise.resolve(await getTimesheets());
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getFilteredTimesheets(query: TimesheetQuery) {
  try {
    if (window.allowedPaths.includes("timesheets/timesheets")) {
      return Promise.resolve(await getTimesheetsAPI(query));
    } else {
      return Promise.resolve(await getContractorFilteredTimesheetsAPI(query));
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getTimesheets() {
  try {
    return Promise.resolve(await getContractorTimesheetsAPI());
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getTimesheet(id: number) {
  try {
    return Promise.resolve(await getContractorTimesheetAPI(id));
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getTimesheetOptionsForApproval = getTimesheetOptionsForApprovalAPI;

export async function addTimesheet(timesheet: TimeSheetForm) {
  try {
    return Promise.resolve(await addTimesheetAPI(timesheet));
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function saveTimesheetRows(timesheet: TimeSheet) {
  try {
    if (!timesheet.manually_entered && !timesheet.submitted) {
      throw Error("not allowed to change app timesheet");
    }
    return Promise.resolve(
      await updateTimesheetRowsAPI(timesheet.id, {
        oca: timesheet.oca,
        rows: timesheet.rows
      })
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function makeDraftRows(
  id: number,
  payload: TimeSheetRowsEditPayload
) {
  try {
    return Promise.resolve(await getCopyofTimesheetAPI(id, payload));
  } catch (e) {
    return Promise.reject({ detail: "Couldn't make rows editable." });
  }
}

export async function editTimesheetForm(timesheet: TimeSheetEditForm) {
  try {
    if (!timesheet.manually_entered && !timesheet.submitted) {
      throw Error("not allowed to change app timesheet");
    }
    if (timesheet.submitted && timesheet.oca !== undefined) {
      const copy: TimeSheetEditForm = await getCopyofTimesheetAPI(
        timesheet.id,
        {
          rows: [],
          oca: timesheet.oca
        }
      );
      timesheet.oca = copy.oca;
    }
    return Promise.resolve(await submitTimesheetAPI(timesheet));
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function submitTimesheet(id: number, oca: number) {
  try {
    return Promise.resolve(
      await submitTimesheetAPI({
        id,
        submitted: true,
        oca
      } as TimeSheetEditForm)
    );
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getRowImages = async (filter: GetRowImagesQuery) => {
  try {
    const imagesResponse = await getRowImagesAPI(filter);
    return Promise.resolve(imagesResponse.results);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveImages = async (
  images: ImageRow[],
  row: TimeSheetRow,
  timesheet_id: number
) => {
  try {
    const promises: Promise<
      TimesheetRowImageResponse | TimesheetRowImage | void
    >[] = [];
    images.forEach((img) => {
      const payloadData: UpdateRowImagePayload = {
        timesheet_id,
        row_id: row.row_id,
        notes: img.newNotes,
        image: img.imageStr,
        user: img.user
      };
      if (img.id !== undefined && /^blob/.test(img.imageStr)) {
        delete payloadData.image;
      }
      if (img.id === undefined) {
        promises.push(createRowImageAPI(payloadData));
      } else if (img.toDelete) {
        promises.push(deleteRowImageAPI(img.id as number));
      } else if (!/^blob/.test(img.imageStr) || img.notes !== img.newNotes) {
        promises.push(updateRowImageAPI(img.id as number, payloadData));
      }
    });
    return Promise.all(promises);
  } catch (e) {
    return Promise.reject(e);
  }
};
