import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Contract,
  Company,
  Task,
  FilterDefinition,
  ResourcesButtonDefinition,
  SelectFieldOption
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import {
  EnumStrings,
  ReduxActionStrings,
  RestStrings
} from "../../../redux/strings";
import _ from "lodash";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";
import { getCompanyOptionsAPI } from "../../../services/api-declaration";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useTranslate } from "../../../services/appLanguageService";

interface ContractsContainerProps {
  count: number;
  contracts: Contract[];
  companies: Company[];
  tasks: Task[];
  loadingContracts: boolean;
  resetContracts: () => void;
  loadCompanies: (companyIds: number[]) => void;
  loadTasks: (taskIds: number[]) => void;
  loadContracts: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => void;
}

const ContractsContainer: React.FC<ContractsContainerProps> = (props) => {
  const {
    loadContracts,
    loadingContracts,
    loadCompanies,
    resetContracts,
    loadTasks,
    contracts,
    companies,
    tasks,
    count
  } = props;
  const page = 1;
  const pageSize = 20;
  const t = useTranslate("ContractsPage");

  const [filteredCompanyValue, setFilteredCompanyValue] =
    useState<SelectFieldOption>({
      label: "",
      value: 0
    });
  const [companyOptions, setCompanyOptions] = useState<SelectFieldOption[]>([]);

  const filter =
    filteredCompanyValue.value !== 0
      ? `&filter__company=${filteredCompanyValue.value}`
      : "";

  useEffect(() => {
    let isAlive = true;

    getCompanyOptionsAPI().then(
      (companiesResponse) =>
        isAlive &&
        setCompanyOptions(
          companiesResponse.results.map((company) => ({
            label: company.name,
            value: company.id
          }))
        )
    );
    return () => {
      isAlive = false;
    };
  }, []);

  const autocompleteSelect = (
    <Autocomplete
      value={filteredCompanyValue}
      onChange={(event: any, newCompany: SelectFieldOption | null) => {
        setFilteredCompanyValue(newCompany || { label: "", value: 0 });
      }}
      options={companyOptions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label={t("filterCompanyLabel")} />
      )}
    />
  );

  useEffect(
    () =>
      loadContracts(page, pageSize, undefined, undefined, filter, [
        EnumStrings.CUSTOMER,
        EnumStrings.CONTRACTOR
      ]),
    [loadContracts, filter]
  );
  useEffect(() => {
    const companyIds = _.uniq(
      contracts.map((contract) => contract.company || -1)
    ).filter((companyId) => companyId !== -1);
    loadCompanies(companyIds);
  }, [loadCompanies, contracts]);
  useEffect(() => {
    const taskIds = _.uniq(
      contracts.map((contract) => contract.tasks || []).flat()
    );
    loadTasks(taskIds);
  }, [loadTasks, contracts]);
  useEffect(() => () => resetContracts(), [resetContracts]);

  const columnDefinitions = [
    { name: "name", type: "link", sortable: true },
    {
      name: "company",
      type: "foreignLink",
      foreignData: {
        data: companies,
        visibleColumns: ["name"],
        url: "companies/companies"
      }
    },
    {
      name: "tasks",
      type: "multipleLinks",
      multipleData: {
        data: tasks,
        visibleColumns: ["name"],
        url: "contracts/tasks"
      }
    },
    { name: "accept_status", type: "enum" },
    { name: "status", type: "enum" }
  ];

  const filterDefinitions: FilterDefinition[] = [
    {
      name: "customer_contracts",
      flag: EnumStrings.CUSTOMER,
      selectedByDefualt: true
    },
    {
      name: "contractor_contracts",
      flag: EnumStrings.CONTRACTOR,
      selectedByDefualt: true
    },
    {
      name: "material_supplier",
      flag: EnumStrings.MATERIAL_SUPPLIER,
      selectedByDefualt: false
    },
    {
      name: "draft",
      flag: EnumStrings.DRAFT,
      selectedByDefualt: false
    },
    {
      name: "archived",
      flag: EnumStrings.ARCHIVED,
      selectedByDefualt: false
    }
  ];

  const resourcesButtons: ResourcesButtonDefinition[] = [
    {
      name: "terms",
      url: "contracts/terms"
    },
    {
      name: "projects",
      url: "contracts/projects"
    },
    {
      name: "businessAreas",
      url: "contracts/businessareas"
    }
  ];

  return (
    <ResourcesTableContainer
      resources={contracts}
      resourceName={"Contract"}
      count={count}
      loadingResources={loadingContracts}
      routeUrl={"contracts/contracts"}
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      resetResources={resetContracts}
      loadResources={loadContracts}
      defaultPageSize={pageSize}
      resourcesButtons={resourcesButtons}
      customFilter={filter}
      customHeaderComponent={autocompleteSelect}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadContracts: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => {
    const selectableTypes = [
      EnumStrings.CUSTOMER,
      EnumStrings.CONTRACTOR,
      EnumStrings.MATERIAL_SUPPLIER
    ];

    const selectableStatuses = [EnumStrings.ARCHIVED, EnumStrings.DRAFT];

    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    const typesFilter = `&filter__company_type__in=[OTHER,DOMAIN_OWNER${
      flags && flags.filter((flag) => selectableTypes.includes(flag)).length > 0
        ? `,${flags
            .filter((flag) => selectableTypes.includes(flag))
            .toString()}`
        : ""
    }]`;

    const statusFilter = `&filter__status__in=[ACTIVE,LOCKED,FLAGGED${
      flags &&
      flags.filter((flag) => selectableStatuses.includes(flag)).length > 0
        ? `,${flags
            .filter((flag) => selectableStatuses.includes(flag))
            .toString()}`
        : ""
    }]`;

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CONTRACTS,
        `contracts/contracts/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${typesFilter}${statusFilter}${filter}`
      )
    );
  },
  loadCompanies: (companyIds: number[]) => {
    if (companyIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COMPANIES,
          `companies/companies/?filter__id__in=[${companyIds}]`
        )
      );
    }
  },
  loadTasks: (taskIds: number[]) => {
    if (taskIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.TASKS,
          `contracts/tasks/?filter__id__in=[${taskIds}]`
        )
      );
    }
  },
  resetContracts: () =>
    dispatch(LoadingBooleansActions.ResetContracts(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  contracts: state.tabStates[ownProps.tabId].contracts.contracts.contracts,
  companies: state.tabStates[ownProps.tabId].companies.companies.companies,
  tasks: state.tabStates[ownProps.tabId].tasks.tasks,
  count: state.tabStates[ownProps.tabId].contracts.contracts.count,
  loadingContracts:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingContracts
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractsContainer);
