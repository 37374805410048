import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import { HistoryRow } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";

const TransactionFooterStyles = (theme: Theme) =>
  createStyles({
    root: {},
    statusInvoiced: {
      backgroundColor: "#d1cfa5",
    },
    detailsTitleBack: {
      backgroundColor: "rgba(0, 0, 0, 0.01)",
    },
    rectangle: {
      border: "1px solid #cdcdcd",
      fontWeight: 700,
      paddingTop: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.5),
    },
    detailsGrid: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    columnBox: {
      display: "flex",
      flexDirection: "column",
    },
    detailsGrid2: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: "auto 1.2fr auto 1fr",
      columnGap: "2%",
    },
    boldText: {
      fontWeight: 700,
    },
  });

export interface TransactionFooterProps
  extends WithStyles<typeof TransactionFooterStyles> {
  historyRow: HistoryRow;
}

const TransactionFooter = withStyles(TransactionFooterStyles)(
  (props: TransactionFooterProps) => {
    const { classes, historyRow } = props;
    const {
      customer_company_name,
      customer_invoices,
      contractor_company_name,
      contractor_invoices,
      registered_by,
      verified_by,
      verified_at,
    } = historyRow;
    const t = useTranslate("HistoryTransactionsDialog");

    return (
      <div className={classes.root}>
        <div className={clsx(classes.rectangle, classes.statusInvoiced)}>
          {t("transactionStatusLabel")}
        </div>
        <div className={classes.detailsGrid}>
          <div className={classes.columnBox}>
            <div className={classes.boldText}>{t("customerLabel")}</div>
            <div>{customer_company_name}</div>
          </div>
          <div className={classes.columnBox}>
            <div className={classes.boldText}>{t("invoiceLabel")}</div>
            <div>
              {customer_invoices
                .map(({ invoice_id }) => invoice_id.toString())
                .join(", ")}
            </div>
          </div>
          <div className={classes.columnBox}>
            <div className={classes.boldText}>{t("dateLabel")}</div>
            <div>
              {customer_invoices
                .map(({ invoice_date }) => invoice_date)
                .join(", ")}
            </div>
          </div>

          <div>{/* dummy space */}</div>

          <div className={classes.columnBox}>
            <div className={classes.boldText}>{t("contractorLabel")}</div>
            <div>{contractor_company_name}</div>
          </div>
          <div className={classes.columnBox}>
            <div className={classes.boldText}>{t("invoiceLabel")}</div>
            <div>
              {contractor_invoices
                .map(({ invoice_id }) => invoice_id.toString())
                .join(", ")}
            </div>
          </div>
          <div className={classes.columnBox}>
            <div className={classes.boldText}>{t("dateLabel")}</div>
            <div>
              {contractor_invoices
                .map(({ invoice_date }) => invoice_date)
                .join(", ")}
            </div>
          </div>
        </div>

        <div className={clsx(classes.rectangle, classes.detailsTitleBack)}>
          {t("detailTitle")}
        </div>
        <div className={clsx(classes.detailsGrid2)}>
          <div className={classes.boldText}>{t("regByLabel")}</div>
          <div>{registered_by}</div>

          <div className={classes.boldText}>{t("verifiedByLabel")}</div>
          <div>{verified_by}</div>

          <div />
          <div />

          <div className={classes.boldText}>{t("verifiedTimeLabel")}</div>
          <div>{verified_at}</div>
        </div>
      </div>
    );
  }
);

export default TransactionFooter;
