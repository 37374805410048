import { LayoutRouteUsage } from "../../../redux/types";
import Baseservice from "../../../components/contracts/services/Baseservice";
import BaseItems from "../../../components/contracts/BaseItems";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ContractItemContainer from "./ContractItemContainer";

const ServicesRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/baseservices"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/baseservices",
        label: t("contractNavbarBaseServicesLabel"),
        component: BaseItems,
        subTabIndex: 0,
        icon: LocalShippingIcon,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS,
        navId: NavItemClassEnum.SERVICESANDPRODUCTS,
        navLabel: t("productsNavbarPrimaryLabel")
      },
      {
        path: "/contracts/baseservices/:baseServiceId",
        parent: "/contracts/baseservices",
        label: t("baseServiceLabel"),
        component: Baseservice,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/:contractId/services/:contractItemId",
        parent: "/contracts/services",
        label: t("serviceLabel"),
        component: ContractItemContainer,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/baseitems",
        label: t("baseItemsLabel"),
        component: BaseItems, // need props
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      }
    ]
  }
];

export default ServicesRouter;
