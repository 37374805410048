import React, { useEffect, useState } from "react";
import { Paper, Typography, createStyles, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { addDays, format } from "date-fns";
import { Invoice, LocationForm } from "../../../redux/types";
import { getPersonAPI } from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import ImageContainer from "../../../containers/ImageContainer";
import { EditableCommiter } from "../../EditableCommiter";
import { niceAmount } from "../../FormatHelpers";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { markdownToDraft, draftToMarkdown } from "markdown-draft-js";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      "& > div": {
        display: "flex",
        marginBottom: theme.spacing(2)
      },
      "& .header > div:first-child": {
        height: "100px"
      },
      "& .header > div:last-child": {
        marginLeft: "auto",
        width: "200px"
      },
      "& .column": {
        flexGrow: 1
      },
      "& .column > div": {
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
      },
      "& .column--header": {
        height: "60px",
        marginBottom: theme.spacing(2),
        borderBottom: "solid 1px black",
        display: "flex"
      },
      "& .column--header > h6": {
        marginTop: "auto",
        marginBottom: theme.spacing(2),
        fontWeight: "bold"
      }
    },
    boldText: {
      fontWeight: "bold"
    },
    labeledField: {
      display: "flex",
      justifyContent: "space-between",
      "& > div:first-child": {
        fontWeight: "bold"
      },
      "& > div": theme.typography.body1
    },
    editable: {
      justifyContent: "space-between",
      flexGrow: 1
    },
    editor: {
      border: "1px solid lightgray",
      borderRadius: "5px",
      height: "100px"
    }
  })
);

const LabeledField: React.FC<{ label: React.ReactNode }> = ({
  label,
  children
}) => {
  const classes = useStyles();
  return (
    <div className={classes.labeledField}>
      <div>{label}</div>
      <div>{children}</div>
    </div>
  );
};

const AddressDisplay: React.FC<
  { name?: string; address: string } & LocationForm
> = ({ name, addressee, address, zip_code, city, extra_address }) => {
  return (
    <>
      <Typography>{addressee || name || ""}</Typography>
      <Typography>{address}</Typography>
      {extra_address && <Typography>{extra_address ?? ""}</Typography>}
      <Typography>
        {zip_code ?? ""} {city ?? ""}
      </Typography>
    </>
  );
};

type PatchInvoiceF = (
  patch: Partial<Exclude<Invoice, "id" | "oca">>
) => Promise<void>;

type InvoiceHeaderProps = {
  invoiceNumber: string;
  paymentTerms: Invoice["payment_terms"];
  invoiceDate: Invoice["invoice_date"];
  invoiceDueDate: Invoice["due_date"];
  senderInfo: Invoice["details"]["sender_info"];
  recipientInfo: Invoice["details"]["recipient_info"];
  summary: Pick<
    Invoice["summary"],
    "total" | "vat" | "rounding" | "to_pay" | "delivery_location"
  >;
  reference: Invoice["reference"];
  patchInvoice: PatchInvoiceF;
  canEdit: boolean;
};

const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  invoiceNumber,
  paymentTerms,
  invoiceDate,
  invoiceDueDate,
  senderInfo,
  recipientInfo,
  summary,
  reference,
  patchInvoice,
  canEdit
}) => {
  const [contactPersonName, setContactPersonName] = useState("");
  const classes = useStyles();
  const t = useTranslate("InvoiceView");

  const contactPersonId = senderInfo.company.contact_person;
  useEffect(() => {
    if (contactPersonId) {
      let alive = true;
      void getPersonAPI(contactPersonId).then(
        (person) =>
          alive &&
          setContactPersonName(`${person.first_name} ${person.last_name}`)
      );
      return () => {
        alive = false;
      };
    }
  }, [contactPersonId]);

  const dueDateChanged = async (date: Date | null) => {
    if (date != null && canEdit) {
      await patchInvoice({
        due_date: format(date, "yyyy-MM-dd")
      });
    }
  };

  const invoiceDateChanged = async (date: Date | null) => {
    if (date != null && canEdit) {
      await patchInvoice({
        invoice_date: format(date, "yyyy-MM-dd"),
        due_date: format(addDays(date, paymentTerms || 30), "yyyy-MM-dd")
      });
    }
  };

  const referenceChanged = async (ref: string) => {
    if (ref !== null) {
      await patchInvoice({
        reference: ref || null
      });
    }
  };

  return (
    <Paper className={classes.header}>
      <div className="header">
        <div>
          <ImageContainer src={recipientInfo.company.logo} type="logo" />
        </div>

        <div>
          <Typography variant="h5">
            {t("invoiceNumberLabel")} {invoiceNumber}
          </Typography>
        </div>
      </div>

      <div className="columns">
        <div className="column" style={{ flexBasis: "0" }}>
          <div className={"column--header"}>
            <Typography variant="h6">{t("senderInfoLabel")}</Typography>
          </div>
          <div>
            {!!senderInfo.invoice_location?.address ? (
              <AddressDisplay
                {...senderInfo.invoice_location}
                name={senderInfo.company.name}
                address={senderInfo.invoice_location.address}
              />
            ) : (
              <Typography>{t("missingInvoiceAddress")}</Typography>
            )}
          </div>
          <div>
            <LabeledField label={t("orgNrLabel")}>
              {senderInfo.company.organisation_number ?? t("notAvailableText")}
            </LabeledField>
            <LabeledField label={t("vatNumberLabel")}>
              {senderInfo.company.vat_registration_number ??
                t("notAvailableText")}
            </LabeledField>
            <LabeledField label={t("ourReferenceLabel")}>
              {contactPersonName || t("notAvailableText")}
            </LabeledField>
          </div>
        </div>

        <div className="column" style={{ flexBasis: "0" }}>
          <div className={"column--header"}>
            <Typography variant="h6">{t("recipientInfoLabel")}</Typography>
          </div>

          <div>
            {recipientInfo.invoice_location?.address ? (
              <AddressDisplay
                {...recipientInfo.invoice_location}
                name={recipientInfo.company.name}
                address={recipientInfo.invoice_location.address}
              />
            ) : (
              <Typography>{t("notAvailableText")}</Typography>
            )}
          </div>

          <div>
            <Typography>
              <div className={classes.boldText}>
                {t("deliveryAddressLabel")}
              </div>
            </Typography>
            {summary.delivery_location?.address ? (
              <AddressDisplay
                {...summary.delivery_location}
                address={summary.delivery_location.address}
              />
            ) : (
              <Typography>{t("deliveryAddressFallback")}</Typography>
            )}
          </div>

          <div>
            <Typography>
              <div className={classes.boldText}>{t("referenceLabel")}</div>
            </Typography>
            <EditableCommiter
              display={<ReactMarkdown children={reference ?? ""} />}
              value={EditorState.createWithContent(
                convertFromRaw(markdownToDraft(reference ?? ""))
              )}
              onCommit={(editorState) =>
                referenceChanged(
                  draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
                )
              }
              className={classes.editable}
            >
              {(value, onChange) => (
                <Editor
                  toolbar={{
                    options: ["inline"],
                    inline: {
                      options: ["bold", "italic"]
                    }
                  }}
                  editorState={value}
                  onEditorStateChange={onChange}
                  editorClassName={classes.editor}
                />
              )}
            </EditableCommiter>
          </div>
        </div>

        <div className="column" style={{ flexBasis: "0" }}>
          <div className={"column--header"} />

          <div>
            {canEdit ? (
              <>
                <EditableCommiter
                  display={
                    <LabeledField label={t("dateLabel")}>
                      {invoiceDate}
                    </LabeledField>
                  }
                  value={new Date(invoiceDate)}
                  onCommit={invoiceDateChanged}
                >
                  {(value, onChange) => (
                    <KeyboardDatePicker
                      label={t("dateLabel")}
                      format="yyyy-MM-dd"
                      value={value}
                      onChange={(d) => d !== null && onChange(d)}
                    />
                  )}
                </EditableCommiter>
                <EditableCommiter
                  display={
                    <LabeledField label={t("dueDateLabel")}>
                      {invoiceDueDate}
                    </LabeledField>
                  }
                  value={new Date(invoiceDueDate)}
                  onCommit={dueDateChanged}
                >
                  {(value, onChange) => (
                    <KeyboardDatePicker
                      label={t("dueDateLabel")}
                      format="yyyy-MM-dd"
                      value={value}
                      onChange={(d) => d !== null && onChange(d)}
                    />
                  )}
                </EditableCommiter>
              </>
            ) : (
              <>
                <LabeledField label={t("dateLabel")}>
                  {invoiceDate}
                </LabeledField>
                <LabeledField label={t("dueDateLabel")}>
                  {invoiceDueDate}
                </LabeledField>
              </>
            )}
            <LabeledField label={t("paymentTermsLabel")}>
              {paymentTerms} days net
            </LabeledField>
            <LabeledField label={t("defaultInterestsLabel")}>9%</LabeledField>
          </div>

          <div>
            <LabeledField label={t("totalLabel")}>
              {niceAmount(summary.total)}
            </LabeledField>
            <LabeledField label={t("vatLabel")}>
              {niceAmount(summary.vat)}
            </LabeledField>
            <LabeledField label={t("roundingLabel")}>
              {niceAmount(summary.rounding)}
            </LabeledField>
            <LabeledField label={t("toPayLabel")}>
              {niceAmount(summary.to_pay)}
            </LabeledField>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default InvoiceHeader;
