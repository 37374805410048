import store from "../redux/store";
import { LayoutRoute } from "../redux/types";
import { AppUrlStrings } from "../strings/AppUrlStrings";
import {
  buildRouteParams,
  convertToUrlSearch,
  extractURLSearch
} from "./routesHelper";

let tabRoutes: LayoutRoute[] = [];

const rectifyIndex = ({ length }: { length: number }, tabIndex: number) =>
  tabIndex < length ? tabIndex : length - 1;

let tabIdCount = 0;
export const addTabRoute = ({
  newTabRoute,
  currentTabIndex,
  actualPath,
  ids,
  shouldSameTab,
  deletedTabId
}: {
  newTabRoute: LayoutRoute;
  currentTabIndex: number;
  actualPath: string;
  ids: string[];
  shouldSameTab: boolean;
  deletedTabId: number;
}) => {
  let newTabIndex = tabRoutes.length;
  let tabId = 1;
  if (shouldSameTab) {
    tabId = deletedTabId;
    newTabIndex = currentTabIndex;
  } else {
    tabId = ++tabIdCount;
  }
  store.dispatch({ type: "ADD_TAB", tabId });
  tabRoutes.splice(newTabIndex, 0, {
    ...newTabRoute,
    tabId,
    actualPath,
    routeParams: buildRouteParams(newTabRoute.path, ids),
    instanceTitle: "",
    urlQueryParams: {}
  });
  return rectifyIndex(tabRoutes, newTabIndex);
};

export const getTabRoutes = () => tabRoutes;

export const getRectifiedTab = (id: number) =>
  tabRoutes[rectifyIndex(tabRoutes, id)];

export const getTabByIndex = (tabIndex: number) => tabRoutes[tabIndex];

export const deleteAllTab = () => {
  tabRoutes.forEach(({ tabId }) =>
    setTimeout(() => {
      store.dispatch({ type: "DELETE_TAB", tabId });
    })
  );
  tabRoutes = [];
};

export const getTabIndex = (id: number) =>
  tabRoutes.findIndex(({ tabId }) => id === tabId);

export const getTabIndexByPath = (pathname: string) =>
  tabRoutes.findIndex(({ actualPath }) => actualPath === pathname);

export const updateTabRoute = (
  tabIndex: number,
  extraProps?: Partial<LayoutRoute>
) => (tabRoutes[tabIndex] = { ...tabRoutes[tabIndex], ...extraProps });

export const removeTabById = (tabId: number) => {
  tabRoutes.splice(getTabIndex(tabId), 1);
};

export const updateURLQueryParams = (
  urlSearchParams: URLSearchParams,
  tabIndex: number
) => {
  if (
    String(convertToUrlSearch(tabRoutes[tabIndex])) !== String(urlSearchParams)
  ) {
    const urlQueryParams = addDefaultUrlParams(
      extractURLSearch(urlSearchParams)
    );
    tabRoutes[tabIndex] = {
      ...tabRoutes[tabIndex],
      urlQueryParams
    };
  }
};

export const addDefaultUrlParams = (
  urlQueryParams: LayoutRoute["urlQueryParams"]
) => {
  if (window.sessionUserId) {
    urlQueryParams[AppUrlStrings.ADHOC_USER] = String(window.sessionUserId);
  }
  return urlQueryParams;
};

export const handleDefaultUrlSearch = (urlSearchParams: URLSearchParams) => {
  if (urlSearchParams.has(AppUrlStrings.ADHOC_USER)) {
    if (!window.sessionUserId) {
      urlSearchParams.delete(AppUrlStrings.ADHOC_USER);
      return true;
    }
  } else if (window.sessionUserId) {
    urlSearchParams.append(
      AppUrlStrings.ADHOC_USER,
      String(window.sessionUserId)
    );
    return true;
  }
  return false;
};

export const getAdhocSearchString = (userId: number) =>
  `${AppUrlStrings.ADHOC_USER}=${userId}`;
