import React, { createContext, useContext } from "react";
import TimesheetApprovalDialog, { TimesheetApprovalDialogProps } from "./timesheetApprovalDialog";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";

type ApprovalDialogProps = Partial<DialogProps>;

export interface DialogServiceState extends ApprovalDialogProps {
    children?: React.ReactNode;
    dialogData?: TimesheetApprovalDialogProps;
}

const defaultValues: DialogServiceState = {
    open: false
};

const ConfirmationServiceContext = createContext<(dialogProps: DialogProps, dialogData: TimesheetApprovalDialogProps) => Promise<void>>(Promise.reject);

export const TimesheetApprovalDialogServiceProvider = ({ children }: { children: React.ReactNode }) => {
    const [dialogState, setDialogState] = React.useState<DialogServiceState>(defaultValues);

    const awaitingPromiseRef = React.useRef<{ resolve: (result: any) => void, reject: () => void }>();

    const handleClose = () => {
        setDialogState(defaultValues);
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }
    };

    const handleSuccess = (result: any) => {
        setDialogState(defaultValues);
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(result);
        }
    };

    const openDialog = (dialogProps: ApprovalDialogProps, dialogData: TimesheetApprovalDialogProps) => {
        setDialogState({ ...dialogProps, open: true, dialogData });
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };
    return (
        <>
            <ConfirmationServiceContext.Provider
                value={openDialog}
                children={children}
            />
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={Boolean(dialogState.open)}
                maxWidth={"md"}
            >
                {dialogState.dialogData && dialogState.open ?
                    <TimesheetApprovalDialog
                        {...dialogState.dialogData}
                        onClose={handleClose}
                        onSuccess={handleSuccess}
                    /> : <></>}
            </Dialog>
        </>
    );
};

export const useTimeSheetApprovalDialog = () => useContext(ConfirmationServiceContext);
