import React, { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "../../services/appLanguageService";

interface ConfirmationDialogProps {
  onSuccess: () => void;
  onClose: () => void;
  open: boolean;
  title?: string;
  description?: string;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    parent: {
      margin: theme.spacing(2)
    }
  })
);

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const { open, title, description, onClose, onSuccess } = props;
  const classes = useStyles();
  const t = useTranslate("ConfirmationDialog");
  return (
    <>
      {open && description && (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.parent}>
            <DialogTitle id="alert-dialog-title">
              {title || "Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {description}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                color="primary"
                data-cy="confirmation-dialog-cancel-btn"
              >
                {t("cancelLabel")}
              </Button>
              <Button
                onClick={onSuccess}
                color="secondary"
                data-cy="confirmation-dialog-confirm-btn"
                autoFocus
              >
                {t("confirmationLabel")}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ConfirmationDialog;
