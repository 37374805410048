import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Passcard, Company } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import {
  EnumStrings,
  ReduxActionStrings,
  RestStrings
} from "../../../redux/strings";
import { useTranslate } from "../../../services/appLanguageService";
import * as Yup from "yup";
import _ from "lodash";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface PasscardsContainerProps {
  passcards: Passcard[];
  count: number;
  loadingPasscards: boolean;
  passcardIds?: number[];
  resetPasscards: () => void;
  loadPasscards: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  showNewButton?: boolean;
  companies: Company[];
  addPasscard: (
    newData: any,
    owningId?: number,
    secondOwningId?: number
  ) => void;
  updatePasscard: (passcardId: number, newData: any) => void;
  deletePasscard: (passcardId: number) => void;
  loadCompanies: (companyIds: number[]) => void;
  companyId?: number;
  customerobjectId?: number;
  editable?: boolean;
}

const passcardTypes = [
  EnumStrings.KEY,
  EnumStrings.CODE,
  EnumStrings.PASSCARD,
  EnumStrings.TAG
];

const PasscardsContainer: React.FC<PasscardsContainerProps> = (props) => {
  const {
    passcardIds,
    loadPasscards,
    resetPasscards,
    loadingPasscards,
    passcards,
    companies,
    loadCompanies,
    editable,
    companyId,
    customerobjectId
  } = props;

  const page = 1;
  const pageSize = 20;

  let filter = "";
  const t3 = useTranslate("ValidationErrorMessages");
  if (passcardIds) {
    const filterStr =
      passcardIds.length !== 1 ? "filter__id__in" : "filter__id";
    const passcardIdsStr = passcardIds.length ? passcardIds.toString() : "";

    filter = `&${filterStr}=${passcardIdsStr}`;
  }

  const t2 = useTranslate("Passcards");

  useEffect(
    () => loadPasscards(page, pageSize, undefined, undefined, filter),
    [filter, loadPasscards]
  );
  useEffect(() => {
    const companyIds = _.uniq(
      passcards.map((passcard) => passcard.contractor_company || -1)
    ).filter((companyId) => companyId !== -1);
    loadCompanies(companyIds);
  }, [loadCompanies, passcards]);
  useEffect(() => () => resetPasscards(), [resetPasscards]);

  const columnDefinitions = [
    {
      name: "serial_number",
      type: "link",
      editableForm: "text",
      validation: Yup.string()
        .min(1, t3("minLengthError"))
        .max(100, t3("max100LengthError"))
        .required(t3("requiredError"))
    },
    {
      name: "passcard_type",
      type: "enum",
      editableForm: "select",
      options: passcardTypes.map((passcardType) => ({
        label: t2(passcardType),
        value: passcardType
      })),
      validation: Yup.string()
        .oneOf(passcardTypes, t3("notValidEnumValueError"))
        .required(t3("requiredError"))
    },
    {
      name: "contractor_company",
      type: "foreignLink",
      foreignData: {
        data: companies,
        visibleColumns: ["name"],
        url: "companies/companies"
      },
      editableForm: "select",
      options: companies.map((company) => ({
        label: company.name,
        value: company.id
      })),
      validation: Yup.number()
        .positive(t3("requiredError"))
        .required(t3("requiredError"))
    }
  ];

  const showNewButton =
    (typeof props.showNewButton !== "undefined" && props.showNewButton) ||
    typeof props.showNewButton === "undefined";

  return (
    <ResourcesTableContainer
      resources={passcards}
      resourceName={"Passcard"}
      count={props.count}
      loadingResources={loadingPasscards}
      routeUrl={"companies/passcards"}
      columnDefinitions={columnDefinitions}
      resetResources={resetPasscards}
      loadResources={loadPasscards}
      showNewButton={showNewButton}
      customFilter={filter}
      defaultPageSize={pageSize}
      addResource={editable ? props.addPasscard : undefined}
      updateResource={editable ? props.updatePasscard : undefined}
      deleteResource={editable ? props.deletePasscard : undefined}
      filterDefinitions={[]}
      owningId={companyId}
      secondOwningId={customerobjectId}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadPasscards: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__serial_number__contains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__serial_number=${orderingStr}`
        : "&order__serial_number=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PASSCARDS,
        `companies/passcards/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  loadCompanies: (companyIds: number[]) => {
    if (companyIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.COMPANIES,
          `companies/companies/?include=["id","name"]&filter__id__in=[${companyIds}]`
        )
      );
    }
  },
  addPasscard: (newData: any, owningId?: number, secondOwningId?: number) => {
    if (owningId) {
      dispatch(
        RestActions.restRequestWithData(
          ownProps.tabId,
          RestStrings.POST,
          ReduxActionStrings.PASSCARD,
          "companies/passcards/",
          {
            status: EnumStrings.ACTIVE,
            passcard_type: newData.passcard_type,
            serial_number: newData.serial_number,
            notes: null,
            code: "",
            key_number: null,
            in_date: null,
            out_date: null,
            owner_company: owningId,
            contractor_company: newData.contractor_company,
            contractor_person: null,
            customerobjects: secondOwningId ? [secondOwningId] : []
          }
        )
      );
    }
  },
  updatePasscard: (passcardId: number, newData: any) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.PASSCARD,
        `companies/passcards/${passcardId}/`,
        {
          passcard_type: newData.passcard_type,
          serial_number: newData.serial_number,
          contractor_company: newData.contractor_company
        }
      )
    );
  },
  deletePasscard: (passcardId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.PASSCARD,
        `companies/passcards/${passcardId}/`,
        undefined,
        undefined,
        passcardId
      )
    );
  },
  resetPasscards: () =>
    dispatch(LoadingBooleansActions.ResetPasscards(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  passcards: state.tabStates[ownProps.tabId].passcards.passcards.passcards,
  count: state.tabStates[ownProps.tabId].passcards.passcards.count,
  loadingPasscards:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingPasscards,
  companies: state.tabStates[ownProps.tabId].companies.companies.companies
});

export default connect(mapStateToProps, mapDispatchToProps)(PasscardsContainer);
