import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  LoginSubmitValues,
  mainRootState
} from "../redux/types";
import Login from "../components/authentication/Login";
import * as AuthenticationActions from "../redux/reducers/authentication/actions";
import { useTranslate } from "../services/appLanguageService";

interface LoginContainerProps
  extends FormikSubmitDispatchProps<LoginSubmitValues> {
  authenticating: boolean;
  authenticated: boolean;
  isLoginError: boolean;
}

const LoginContainer: React.FC<LoginContainerProps> = (props) => {
  const h = useTranslate("AppHeader");
  React.useEffect(() => {
    document.title = h("workPrecisionHeading");
  }, [h]);
  return (
    <Login
      handleSubmit={props.handleSubmit}
      authenticated={props.authenticated}
      isLoginError={props.isLoginError}
      authenticating={props.authenticating}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch
): FormikSubmitDispatchProps<LoginSubmitValues> => ({
  handleSubmit: (values, actions) => {
    actions.setSubmitting(false);
    dispatch(
      AuthenticationActions.authenticate(
        values.domain,
        values.username,
        values.password,
        values.rememberMe
      )
    );
  }
});

const mapStateToProps = (state: mainRootState) => ({
  authenticated: state.authentication.authenticated,
  isLoginError: state.authentication.isLoginError,
  authenticating: state.authentication.authenticating
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
