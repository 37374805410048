import { FeatureCollection, SquareMeters } from "../../../../redux/types";
import featureTypes from "./featureTypes";

/**
 * Center on all features of the map
 *
 */
export function fitToBounds(map: google.maps.Map) {
  let nrOfFeatures = 0;

  map.data.forEach((feature) => {
    nrOfFeatures++;
  });
  if (nrOfFeatures > 0) {
    let bounds = getFeaturesBounds(map, new window.google.maps.LatLngBounds());
    map.fitBounds(bounds);
  }
}

/**
 * Get the bounds of all features on the map
 */
export function getFeaturesBounds(
  map: google.maps.Map,
  bounds: google.maps.LatLngBounds
): google.maps.LatLngBounds {
  if (map) {
    map.data.forEach((feature) => {
      let geometry = feature.getGeometry();
      if (geometry.getType() === "Polygon") {
        let coordinates = (geometry as google.maps.Data.Polygon)
          .getArray()[0]
          .getArray();

        coordinates.forEach((c) => bounds.extend(c));
      } else if (geometry.getType() === "LineString") {
        let coordinates = (geometry as google.maps.Data.LineString).getArray();
        coordinates.forEach((c) => bounds.extend(c));
      }
    });
  }
  return bounds;
}

/**
 * Checks if an outer feature completely contains an inner feature
 * Both features must be polygons
 * Used to check if a polygon is going to be used as a hole inside another polygon
 */
export function containsPolygon(
  outerFeature: google.maps.Data.Feature,
  innerFeature: google.maps.Data.Feature
): boolean {
  let contains = true;
  const polygon = new window.google.maps.Polygon({
    paths: (outerFeature.getGeometry() as google.maps.Data.Polygon)
      .getArray()[0]
      .getArray()
  });

  innerFeature.getGeometry().forEachLatLng((latLng: google.maps.LatLng) => {
    contains =
      window.google.maps.geometry.poly.containsLocation(latLng, polygon) &&
      contains;
  });

  return contains;
}

/**
 * Draws a hole (innerFeature) inside a polygon (outerFeature)
 */
export function drawPolygonInside(
  outerFeature: google.maps.Data.Feature,
  innerFeature: google.maps.Data.Feature
) {
  // Creates a new polygon and sets its outer and inner polygon
  let linearRings = (
    outerFeature.getGeometry() as google.maps.Data.Polygon
  ).getArray();
  const newLinearRing = (
    innerFeature.getGeometry() as google.maps.Data.Polygon
  ).getArray()[0];

  linearRings.push(newLinearRing);

  const newPolygon = new window.google.maps.Data.Polygon(linearRings);

  outerFeature.setGeometry(newPolygon);
}

export async function ensureMapScript() {
  return new Promise<void>((resolve) => {
    if (document.getElementById("google-map-script")) {
      return resolve();
    }
    const script = document.createElement("script");
    script.id = "google-map-script";
    script.type = "text/javascript";
    script.src = `https://maps.google.com/maps/api/js?key=AIzaSyDid_ORSZwtWwpbOzXqEEfYWkMnJG0U3vA&libraries=geometry,places`;
    script.addEventListener("load", () => resolve());
    document.body.appendChild(script);
  });
}

export function newFeature(
  type: string,
  map: google.maps.Map,
  currentGroupRef: React.MutableRefObject<string>
) {
  map.data.forEach(function (currentFeature) {
    if (currentFeature.getProperty("editable") === true) {
      removeEditableFeature(map, currentFeature);
    }
  });
  styleFeatures(map);
  drawFeature(map, currentGroupRef, type);
}

export function drawFeature(
  map: google.maps.Map,
  currentGroupRef: React.MutableRefObject<string>,
  type: string
) {
  if (featureTypes[type].drawingMode === "Polygon") {
    map.data.setDrawingMode("Polygon");
  } else if (featureTypes[type].drawingMode === "LineString") {
    map.data.setDrawingMode("LineString");
  } else if (featureTypes[type].drawingMode === "Point") {
    map.data.setDrawingMode("Point");
  }

  currentGroupRef.current = type;
  featureTypes[type].style.editable = true;

  map.data.setStyle(featureTypes[type].style);
}

/**
 * Remove all arrows
 */
export function removeAllArrows(map: google.maps.Map) {
  map.data.forEach((feature) => {
    if (feature.getProperty("group") === "directionArrow") {
      map.data.remove(feature);
    }
  });
}

/**
 * Update all arrows' angles on the directions
 */
export function updateArrows(map: google.maps.Map) {
  removeAllArrows(map);

  /**
   * Draw a arrow connected to a certain direction-linestring
   */
  map.data.forEach((feature) => {
    let type: any = {
      exit: "directionArrowExit",
      entrance: "directionArrowEntrance",
      direction: "directionArrow"
    };

    if (type[feature.getProperty("group")]) {
      const linestring = feature.getGeometry() as google.maps.Data.LineString;
      if (linestring.getLength() < 2){
        return;
      }
      const arrowLatLng = linestring.getAt(linestring.getLength() - 1);
      const secondLatLng = linestring.getAt(linestring.getLength() - 2);
      const heading = window.google.maps.geometry.spherical.computeHeading(
        secondLatLng,
        arrowLatLng
      );
      const arrowLength = 6;
      const leftPoint = window.google.maps.geometry.spherical.computeOffset(
        arrowLatLng,
        arrowLength,
        heading - 160
      );
      const rightPoint = window.google.maps.geometry.spherical.computeOffset(
        arrowLatLng,
        arrowLength,
        heading + 160
      );
      // const centerPoint = window.google.maps.geometry.spherical.computeOffset(
      //   rightPoint,
      //   6.9,
      //   heading - 90
      // );
      const arrowLines = new window.google.maps.Data.LineString([
        leftPoint,
        arrowLatLng,
        rightPoint
      ]);

      let newArrow = map.data.add(
        new window.google.maps.Data.Feature({
          geometry: arrowLines,
          properties: { group: "directionArrow" }
        })
      );
      map.data.overrideStyle(
        newArrow,
        featureTypes[type[feature.getProperty("group")]].style
      );
    }
  });
}

export function stylePoint(
  map: google.maps.Map,
  feature: google.maps.Data.Feature
) {
  map.data.forEach((currentFeature) => {
    if (currentFeature === feature) {
      const style = {
        icon: {
          path: window.google.maps.SymbolPath.BACKWARD_OPEN_ARROW,
          scale: 6,
          fillOpacity: 0.5,
          strokeWeight: 2.5,
          fillColor:
            feature.getProperty("group") === "direction"
              ? "#4db547"
              : "#eb4435",
          strokeColor:
            feature.getProperty("group") === "direction" ? "#4db547" : "#eb4435"
        },
        zIndex: 3
      };

      map.data.overrideStyle(currentFeature, style);
      map.data.overrideStyle(currentFeature, {
        editable: false
      });
    }
  });
}

export function styleFeatures(map: google.maps.Map) {
  map.data.forEach((feature) => {
    const type = feature.getProperty("group");
    const editable = feature.getProperty("editable");
    if (
      (type !== "directionArrow" || type !== "entrance" || type !== "exit") &&
      featureTypes[type]
    ) {
      map.data.overrideStyle(feature, featureTypes[type].style);
      map.data.overrideStyle(feature, { editable: editable });
    }
  });
  updateArrows(map);
}

export function loadGeoJson(
  map: google.maps.Map,
  geoPolygons: FeatureCollection
) {
  map.data.addGeoJson(geoPolygons);

  map.data.forEach((feature) => {
    feature.setProperty("editable", false);

    if (feature.getGeometry().getType() === "MultiLineString") {
      const geometry = feature.getGeometry();

      (geometry as google.maps.Data.MultiLineString)
        .getArray()
        .forEach((item, index) => {
          map.data.add(
            new window.google.maps.Data.Feature({
              geometry: item,
              properties: { group: "direction", editable: false }
            })
          );
        });
      map.data.remove(feature);
    }

    if (
      feature.getProperty("group") === "entrance" ||
      feature.getProperty("group") === "exit"
    ) {
      stylePoint(map, feature);
    }
  });

  styleFeatures(map);
}

/**
 * Check whether a vertex has been clicked for a polygon,
 * if so, the vertex will be removed
 */
export function removeVertexFromPolygon(
  map: google.maps.Map,
  feature: google.maps.Data.Feature,
  clickedLatLng: google.maps.LatLng
): boolean {
  const polygon = feature.getGeometry() as google.maps.Data.Polygon;
  let newLinearRings: google.maps.Data.LinearRing[] = [];
  let vertexRemoved = false;

  polygon.getArray().forEach((linearRing) => {
    let latLngArray: google.maps.LatLng[] = [];

    linearRing.forEachLatLng((latLng) => {
      if (clickedLatLng.equals(latLng)) {
        vertexRemoved = true;
      } else {
        latLngArray.push(latLng);
      }
    });

    if (vertexRemoved && latLngArray.length > 2) {
      newLinearRings.push(new window.google.maps.Data.LinearRing(latLngArray));
    } else if (latLngArray.length > 2) {
      newLinearRings.push(linearRing);
    }
  });

  if (vertexRemoved && newLinearRings.length > 0) {
    feature.setGeometry(new window.google.maps.Data.Polygon(newLinearRings));
  } else if (newLinearRings.length === 0) {
    map.data.remove(feature);
  }

  return vertexRemoved;
}

/**
 * Check whether a vertex has been clicked for a Linestring,
 * if so, the vertex will be removed
 */
export function removeVertexFromLineString(
  map: google.maps.Map,
  feature: google.maps.Data.Feature,
  clickedLatLng: google.maps.LatLng
): boolean {
  const lineString = feature.getGeometry();
  let latLngArray: google.maps.LatLng[] = [],
    vertexRemoved = false;

  lineString.forEachLatLng(function (latLng) {
    if (clickedLatLng.equals(latLng)) {
      vertexRemoved = true;
    } else {
      latLngArray.push(latLng);
    }
  });

  if (vertexRemoved && latLngArray.length > 1) {
    feature.setGeometry(new window.google.maps.Data.LineString(latLngArray));
  } else if (latLngArray.length <= 1) {
    map.data.remove(feature);
  }
  return vertexRemoved;
}

export function removeEditableFeature(
  map: google.maps.Map,
  currentFeature: google.maps.Data.Feature
) {
  currentFeature.setProperty("editable", false);
  map.data.overrideStyle(currentFeature, {
    editable: false,
    draggable: false
  });
  updateArrows(map);
}

/**
 * Calculates the area for a feature (polygon)
 */
export function computeAreaForFeature(
  feature: google.maps.Data.Feature
): number {
  let area: number = 0;
  let areas = [];
  const polygon = feature.getGeometry() as google.maps.Data.Polygon;

  // Computes areas for all linearrings for the polygons
  for (let i = 0, length = polygon.getLength(); i < length; i++) {
    const tempArea = window.google.maps.geometry.spherical.computeArea(
      polygon.getAt(i).getArray()
    );
    areas.push(tempArea);
  }

  // Removes the first area (the area for the outer polygon)
  const firstArea = areas.shift();
  area = firstArea ? firstArea : area;

  // If there are holes in polygon, they are subtracted on the first area
  if (areas.length > 0) {
    area -= areas.reduce(function (total, num) {
      return total + num;
    });
  }
  return Math.round(area);
}

/**
 * Calculate areas for all Polygons, and display the area for each category
 */
export function calculateSquareMeters(map: google.maps.Map): SquareMeters {
  let tempSquareMeters: SquareMeters = {
    area: 0,
    dumpsite: 0,
    priority: 0,
    manual: 0
  };
  map.data.forEach((feature) => {
    if (feature.getGeometry().getType() === "Polygon") {
      const type = feature.getProperty("group");
      tempSquareMeters[type] += computeAreaForFeature(feature);
    }
  });

  return tempSquareMeters;
}
