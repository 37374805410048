import { DistrictsState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: DistrictsState = {
  districts: {
    results: [],
    next: null,
    previous: null,
    count: 0
  },
  district: {
    district: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      code: "",
      notes: "",
      geo_polygons: null,
      parent_district: -1
    },
    parent_district: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      code: "",
      notes: "",
      geo_polygons: null,
      parent_district: -1
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.DISTRICTS):
      return {
        ...state,
        districts: {
          ...state.districts,
          results: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.DISTRICT):
      return {
        ...state,
        district: {
          ...state.district,
          district: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.DISTRICT):
      return {
        ...state,
        districts: {
          ...state.districts,
          districts: [...state.districts.results, action.payload]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.DISTRICT
    ):
      return {
        ...state,
        districts: {
          ...state.districts,
          districts: [
            ...state.districts.results.map((district) =>
              district.id === action.payload.id ? action.payload : district
            )
          ]
        },
        district: {
          ...state.district,
          district: action.payload
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PARENT_DISTRICT
    ):
      return {
        ...state,
        district: {
          ...state.district,
          parent_district: action.payload
        }
      };

    default:
      return state;
  }
}

export default reducer;
