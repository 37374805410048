import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button, Chip, Badge } from "@material-ui/core";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import { TimesheetRowsGroup } from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";

interface UnapprovedWorkAddProps {
  groups: TimesheetRowsGroup[];
  styles?: object;
  createGroup: () => void;
  moveIntoGroup: (group_id: number) => () => void;
  badgeCount: (group: TimesheetRowsGroup) => JSX.Element;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    btn: {
      marginBottom: theme.spacing(1.5)
    },
    groupContainer: {
      display: "flex",
      borderRadius: "5px",
      alignItems: "center",
      backgroundColor: "#bababa",
      justifyContent: "space-between",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    moveButton: {
      "&:hover": {
        color: "#4056b5"
      }
    }
  })
);

const UnapprovedWorkAdd: React.FC<UnapprovedWorkAddProps> = (props) => {
  const classes = useStyles({});
  const t = useTranslate("UnplannedWorkAdd");
  const { groups, createGroup, moveIntoGroup, badgeCount } = props;

  return (
    <div className={classes.root}>
      <Button
        className={classes.btn}
        color="primary"
        variant="outlined"
        onClick={createGroup}
      >
        {t("moveToNewGroup")}
      </Button>
      {groups.map((group, index) => (
        <div key={index} className={classes.groupContainer}>
          <Badge badgeContent={badgeCount(group)} color="primary">
            <Chip
              icon={<VerticalSplitIcon />}
              label={`Group ${group.group_id}`}
            />
          </Badge>
          <Button
            size="small"
            variant="outlined"
            className={classes.moveButton}
            onClick={moveIntoGroup(group.group_id)}
          >
            {t("moveIntoGroup")}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default UnapprovedWorkAdd;
