import { Action, AuthenticationState } from "../../types";
import {
  AUTHENTICATION_SUCCESS,
  UNAUTHORIZED,
  AUTHENTICATION_FAILURE,
  RESET_LOGIN_ERROR,
  AUTHENTICATING,
  LOGEDOUT,
} from "./actionTypes";

const initialState: AuthenticationState = {
  domain: "",
  username: "",
  authenticated: false,
  authorizationDialog: false,
  isLoginError: false,
  authenticating: false,
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        domain: action.payload.domain,
        username: action.payload.username,
        authenticated: true,
        authorizationDialog: false,
        isLoginError: false,
        authenticating: false,
      };
    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isLoginError: true,
        authenticating: false,
        username: "",
      };
    case RESET_LOGIN_ERROR:
      return {
        ...state,
        isLoginError: false,
        authenticating: false,
      };
    case UNAUTHORIZED:
      return {
        ...state,
        authorizationDialog: true,
        authenticating: false,
      };
    case LOGEDOUT:
      return {
        ...state,
        domain: "",
        username: "",
        authenticated: false,
        authorizationDialog: false,
        isLoginError: false,
        authenticating: false,
      };
    case AUTHENTICATING:
      return {
        ...state,
        authenticating: action.payload.authenticating,
      };
    default:
      return state;
  }
}

export default reducer;
