import { LayoutRouteUsage } from "../../../redux/types";
import ProjectsContainer from "./ProjectsContainer";
import ProjectNewFormContainer from "./ProjectNewFormContainer";
import ProjectContainer from "./ProjectContainer";
import ProjectEditFormContainer from "./ProjectEditFormContainer";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { Beenhere } from "@material-ui/icons";

const ProjectsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/projects"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/projects",
        label: t("contractNavbarProjectsLabel"),
        component: ProjectsContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
        icon: Beenhere,
      },
      {
        path: "/contracts/projects/new",
        parent: "/contracts/projects",
        label: t("newProjectLabel"),
        component: ProjectNewFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
      {
        path: "/contracts/projects/:projectId",
        parent: "/contracts/projects",
        label: t("projectLabel"),
        component: ProjectContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
      {
        path: "/contracts/projects/:projectId/edit",
        parent: "/contracts/projects/:projectId",
        label: t("editProjectLabel"),
        component: ProjectEditFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
    ],
  },
];

export default ProjectsRouter;
