import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, InvoicingProfile } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { RestStrings } from "../../../redux/strings";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface InvoicingProfilesContainerProps {
  invoicingprofiles: InvoicingProfile[];
  count: number;
  loadingInvoicingProfiles: boolean;
  resetInvoicingProfiles: () => void;
  loadInvoicingProfiles: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string
  ) => void;
}

const InvoicingProfilesContainer: React.FC<InvoicingProfilesContainerProps> = (
  props
) => {
  const {
    loadInvoicingProfiles,
    invoicingprofiles,
    loadingInvoicingProfiles,
    resetInvoicingProfiles
  } = props;
  const page = 1;
  const pageSize = 20;

  useEffect(
    () => loadInvoicingProfiles(page, pageSize),
    [loadInvoicingProfiles]
  );
  useEffect(() => () => resetInvoicingProfiles(), [resetInvoicingProfiles]);

  const columnDefinitions = [{ name: "name", type: "link" }];

  return (
    <ResourcesTableContainer
      resources={invoicingprofiles}
      resourceName={"InvoicingProfile"}
      count={props.count}
      loadingResources={loadingInvoicingProfiles}
      routeUrl={"invoices/invoicingprofiles"}
      columnDefinitions={columnDefinitions}
      resetResources={resetInvoicingProfiles}
      loadResources={loadInvoicingProfiles}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadInvoicingProfiles: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__name=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "INVOICINGPROFILES",
        `invoices/invoicingprofiles/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}`
      )
    );
  },
  resetInvoicingProfiles: () =>
    dispatch(LoadingBooleansActions.ResetInvoicingProfiles(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  invoicingprofiles:
    state.tabStates[ownProps.tabId].invoicingProfiles.invoicingProfiles
      .invoicingProfiles,
  count:
    state.tabStates[ownProps.tabId].invoicingProfiles.invoicingProfiles.count,
  loadingInvoicingProfiles:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingInvoicingProfiles
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicingProfilesContainer);
