import React, { FC } from "react";
import { Paper, Button, Typography } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { history } from "../../redux/store";
import { Formik, Field, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { INotificationProfileForm } from "../../redux/types";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import { saveNotificationProfileAPI } from "../../services/api-declaration";
import { useTranslate } from "../../services/appLanguageService";

interface NotificationProfileFormProps {}

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(t("requiredError"))
  });

const useStyles = makeStyles((theme: any) =>
  createStyles({
    navButton: {
      marginBottom: theme.spacing(2.5)
    },
    paper: {
      padding: theme.spacing(2.5)
    },
    form: {
      width: "100%"
    },
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5)
    },
    saveBtn: { marginTop: theme.spacing(2) }
  })
);

const defaultValues = {
  name: "",
  notes: ""
};

const AddNewNotificationProfile: FC<NotificationProfileFormProps> = (props) => {
  const t = useTranslate("NotificationProfile");
  const u = useTranslate("Utility");
  const classes = useStyles();

  const goBack = () => {
    history.replace("/notification-profiles?same_tab=true");
  };

  const handleSubmit = async (
    values: INotificationProfileForm,
    actions: FormikHelpers<INotificationProfileForm>
  ) => {
    const formValues: INotificationProfileForm = { ...values };
    try {
      const response = await saveNotificationProfileAPI(formValues);
      if (response) {
        goBack();
        showGlobalSnackbar(u("addedSuccessfullyMessage"), "success");
      }
    } catch (e: any) {
      showGlobalSnackbar(e.message, "error");
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        centerRipple
        onClick={goBack}
        className={classes.navButton}
      >
        <NavigateBeforeIcon /> {"Notification Profiles"}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {t("addNewHeading")}
        </Typography>
        <Formik
          initialValues={defaultValues}
          validationSchema={ValidationSchema(t)}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                autoFocus
                data-cy="profile-name"
                id="new-profile-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              <Field
                data-cy="profile-notes"
                id="new-profile-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              <Button
                id="new-profile-submit"
                data-cy="profile-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.saveBtn}
                disabled={props.values.name === ""}
              >
                {t("saveButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default AddNewNotificationProfile;
