import { UsersState, Action, User } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { RestStrings, ReduxActionStrings } from "../../strings";

const initialState: UsersState = {
  users: {
    users: [],
    next: null,
    previous: null,
    count: 0
  },
  user: {
    user: {
      id: -1,
      username: "",
      email: "",
      status: "ACTIVE",
      language: "",
      is_superuser: false,
      is_staff: false,
      avatar: null,
      created_at: "",
      updated_at: "",
      last_login: "",
      accepted_terms_date: "",
      accepted_from_ip: "",
      permissions: [],
      groups: [],
      notifications_profiles: [],
      districts: [],
      person: -1
    },
    paramsUser: {
      id: -1,
      username: "",
      email: "",
      status: "ACTIVE",
      language: "",
      is_superuser: false,
      is_staff: false,
      avatar: null,
      created_at: "",
      updated_at: "",
      last_login: "",
      accepted_terms_date: "",
      accepted_from_ip: "",
      permissions: [],
      groups: [],
      notifications_profiles: [],
      districts: [],
      person: -1
    },
    permissions: [],
    groups: [],
    notificationsProfiles: [],
    districts: []
  }
};

export default function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.USERS):
      return {
        ...state,
        users: {
          users: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PARAMS_USER
    ):
      return {
        ...state,
        user: {
          ...state.user,
          paramsUser: action.payload
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.USER):
      return {
        ...state,
        user: {
          ...state.user,
          user: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.USER):
      return {
        ...state,
        users: {
          ...state.users,
          users: [...state.users.users, action.payload]
        },
        user: {
          ...state.user,
          user: action.payload
        }
      };
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.USER):
      return {
        ...state,
        users: {
          ...state.users,
          users: [
            ...state.users.users.map((user) =>
              user.id === action.payload.id ? action.payload : user
            )
          ]
        },
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            permissions: action.payload.permissions
          }
        }
      };

    case createSuccessActionType(RestStrings.DELETE, ReduxActionStrings.USER):
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.filter(
            (user: User) => user.id !== parseInt(action.payload.id)
          )
        },
        user: {
          ...state.user,
          user: {
            id: -1,
            username: "",
            email: "",
            status: "ACTIVE",
            language: "",
            is_superuser: false,
            is_staff: false,
            avatar: null,
            created_at: "",
            updated_at: "",
            last_login: "",
            accepted_terms_date: "",
            accepted_from_ip: "",
            permissions: [],
            groups: [],
            person: -1,
            notifications_profiles: []
          }
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PERMISSIONS
    ):
      return {
        ...state,
        user: {
          ...state.user,
          permissions: action.payload
        }
      };

    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.PERMISSION
    ):
      return {
        ...state,
        user: {
          ...state.user,
          permissions: [
            ...state.user.permissions.map((permission) =>
              permission.id === action.payload.id ? action.payload : permission
            )
          ]
        }
      };

    default:
      return state;
  }
}
