import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Project,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import ProjectView from "../../../components/contracts/projects/Project";
import { RestStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface ProjectContainerProps extends TabComponentProps<"projectId"> {
  project: Project;
  loadingProject: boolean;
  resetProject: () => void;
  loadProject: (projectId: number) => void;
}

const ProjectContainer: React.FC<ProjectContainerProps> = (props) => {
  const {
    resetProject,
    loadingProject,
    loadProject,
    project,
    routeParams: { projectId }
  } = props;
  useEffect(() => loadProject(projectId), [loadProject, projectId]);

  useEffect(() => () => resetProject(), [resetProject]);
  return (
    <div>
      {loadingProject ? (
        <LoadingSpinnerPaper />
      ) : (
        <ProjectView project={project} {...mapTabRouteProps(props)} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadProject: (projectId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "PROJECT",
        `contracts/projects/${projectId}/`
      )
    );
  },
  resetProject: () =>
    dispatch(LoadingBooleansActions.ResetProject(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  project: state.tabStates[ownProps.tabId].projects.project.project,
  loadingProject: state.tabStates[ownProps.tabId].loadingBooleans.loadingProject
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer);
