import { PersonsState, Action, Person } from "../../types";
import {
  createResetResourceType,
  createSuccessActionType
} from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: PersonsState = {
  persons: {
    persons: [],
    next: null,
    previous: null,
    count: 0
  },
  companies: [],
  person: {
    person: {
      id: -1,
      created_at: "",
      updated_at: "",
      status: "ACTIVE",
      title: "CONTACT",
      first_name: "",
      last_name: "",
      phone1: "",
      phone2: "",
      email: "",
      personal_code: "",
      is_key_person: false,
      image: null,
      notes: "",
      user: null,
      company: null,
      customerobjects: []
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PERSONS):
      return {
        ...state,
        persons: {
          ...state.persons,
          persons: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PERSON):
      return {
        ...state,
        person: {
          person: action.payload
        }
      };
    case createResetResourceType(ReduxActionStrings.PERSON):
      return {
        ...state,
        person: {
          person: {
            id: -1,
            created_at: "",
            updated_at: "",
            status: "ACTIVE",
            title: "CONTACT",
            first_name: "",
            last_name: "",
            phone1: "",
            phone2: "",
            email: "",
            personal_code: "",
            is_key_person: false,
            image: null,
            notes: "",
            user: null,
            company: null,
            customerobjects: []
          }
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.PERSON):
      return {
        ...state,
        persons: {
          ...state.persons,
          persons: [...state.persons.persons, action.payload]
        }
      };
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.PERSON):
      return {
        ...state,
        persons: {
          ...state.persons,
          persons: [
            ...state.persons.persons.map((person) =>
              person.id === action.payload.id ? action.payload : person
            )
          ]
        }
      };
    case createSuccessActionType(RestStrings.DELETE, ReduxActionStrings.PERSON):
      return {
        ...state,
        persons: {
          ...state.persons,
          persons: [
            ...state.persons.persons.filter(
              (person: Person) => person.id !== parseInt(action.payload.id)
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PERSON_COMPANIES
    ):
      return {
        ...state,
        companies: action.payload.results
      };

    default:
      return state;
  }
}

export default reducer;
