import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  TabComponentProps,
  WeatherArea
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import WeatherAreaView from "../../../components/companies/weatherAreas/WeatherArea";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface WeatherAreaContainerProps extends TabComponentProps<"weatherAreaId"> {
  weatherArea: WeatherArea;
  loadingWeatherArea: boolean;
  resetWeatherArea: () => void;
  loadWeatherArea: (weatherAreaId: number) => void;
}

const WeatherAreaContainer: React.FC<WeatherAreaContainerProps> = (props) => {
  const {
    resetWeatherArea,
    loadingWeatherArea,
    loadWeatherArea,
    weatherArea,
    routeParams: { weatherAreaId }
  } = props;
  useEffect(
    () => loadWeatherArea(weatherAreaId),
    [loadWeatherArea, weatherAreaId]
  );

  useEffect(() => () => resetWeatherArea(), [resetWeatherArea]);
  return (
    <div>
      <ViewActionButtons
        resourceId={weatherAreaId}
        resource={ReduxActionStrings.WEATHERAREA}
        url="companies/weatherareas"
      />
      {loadingWeatherArea ? (
        <LoadingSpinnerPaper />
      ) : (
        <WeatherAreaView
          weatherArea={weatherArea}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadWeatherArea: (weatherAreaId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "WEATHERAREA",
        `companies/weatherareas/${weatherAreaId}/`
      )
    );
  },
  resetWeatherArea: () =>
    dispatch(LoadingBooleansActions.ResetWeatherArea(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  weatherArea:
    state.tabStates[ownProps.tabId].weatherAreas.weatherArea.weatherArea,
  loadingWeatherArea:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingWeatherArea
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeatherAreaContainer);
