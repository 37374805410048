import { TemplateImagesState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: TemplateImagesState = {
  templateImages: {
    templateImages: [],
    next: null,
    previous: null,
    count: 0
  },
  templateImage: {
    templateImage: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      image: null,
      notes: ""
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TEMPLATEIMAGES
    ):
      return {
        ...state,
        templateImages: {
          templateImages: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TEMPLATEIMAGE
    ):
      return {
        ...state,
        templateImage: {
          templateImage: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.TEMPLATEIMAGE
    ):
      return {
        ...state,
        templateImages: {
          ...state.templateImages,
          templateImages: [
            ...state.templateImages.templateImages,
            action.payload
          ]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.TEMPLATEIMAGE
    ):
      return {
        ...state,
        templateImages: {
          ...state.templateImages,
          templateImages: [
            ...state.templateImages.templateImages.map((templateImage) =>
              templateImage.id === action.payload.id
                ? action.payload
                : templateImage
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
