import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  Collapse,
  Grid
} from "@material-ui/core";
import { TextField, Select, CheckboxWithLabel } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  CustomerobjectForm,
  Customerobject,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import { EnumStrings } from "../../../redux/strings";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  AutoCompleteSelect,
  AutoCompleteSelectMulti,
  AutoCompleteSelectMultiNEW
} from "../../AutoCompleteSelect";
import TimePicker from "../../TimePicker";
import { useTranslate } from "../../../services/appLanguageService";
import LocationMap from "../../companies/locations/LocationMap";
import { getSearchPasscardOptionsAPI } from "../../../services/api-declaration";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { getCompanyNamesAPI } from "../../../services/api-declaration";

const customerobjectTypeOptions = [
  EnumStrings.PROPERTY_USER,
  EnumStrings.PROPERTY_OWNER,
  EnumStrings.TRUSTEE
];

const statusOptions = [
  EnumStrings.ACTIVE,
  EnumStrings.ARCHIVED,
  EnumStrings.LOCKED
];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    status: Yup.string()
      .oneOf(statusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    customer: Yup.number()
      .positive(t("requiredError"))
      .required(t("requiredError")),
    contact_person: Yup.number().nullable().notRequired(),
    customer_object_type: Yup.string()
      .oneOf(customerobjectTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    notes: Yup.string().nullable().notRequired(),
    allow_photos: Yup.boolean().required(),
    accounting_reference: Yup.number().nullable(),
    invoice_name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .notRequired(),
    weekday_opening: Yup.string().nullable().notRequired(),
    weekday_closing: Yup.string().nullable().notRequired(),
    saturday_opening: Yup.string().nullable().notRequired(),
    saturday_closing: Yup.string().nullable().notRequired(),
    sunday_opening: Yup.string().nullable().notRequired(),
    sunday_closing: Yup.string().nullable().notRequired(),
    location: Yup.object().nullable().notRequired(),
    persons: Yup.array().of(Yup.number().min(1)).notRequired(),
    passcards: Yup.array().of(Yup.number().min(1)).notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    mapContainer: {
      marginTop: 20
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    }
  });

interface NewCustomerobjectProps
  extends FormikSubmitDispatchProps<CustomerobjectForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  customerobject?: Customerobject;
  personOptions: SelectFieldOption[];
  loadCustomerPersons: (companyId: number) => void;
}

const NewEditCustomerobject: React.FC<NewCustomerobjectProps> = (props) => {
  const { classes, customerobject, personOptions, loadCustomerPersons, tabId } =
    props;
  const t = useTranslate("CustomerobjectPage");
  const t2 = useTranslate("Customerobjects");
  const t3 = useTranslate("ValidationErrorMessages");
  const [companyNames, setCompanyNames] = useState<SelectFieldOption[]>([]);
  let initialValues: Customerobject | CustomerobjectForm;
  if (customerobject && (customerobject as Customerobject).id) {
    initialValues = customerobject;
  } else {
    initialValues = {
      id: -1,
      name: "",
      status: "ACTIVE",
      customer: -1,
      contact_person: null,
      customer_object_type: "PROPERTY_USER",
      passcard_type: "PASSCARD",
      notes: "",
      allow_photos: true,
      invoice_name: "",
      weekday_opening: "07:00",
      weekday_closing: "16:00",
      saturday_opening: "07:00",
      saturday_closing: "16:00",
      sunday_opening: "07:00",
      sunday_closing: "16:00",
      location: null,
      passcards: [],
      persons: [],
      tasks: [],
      invoice_location: null,
      invoice_address_enabled: false,
      accounting_reference: null
    };
  }
  useEffect(() => {
    let alive = true;

    if (customerobject) {
      store.dispatch(setTitle(customerobject.name, tabId));
    }

    const loadCompanyNames = () => {
      getCompanyNamesAPI({ company_type: "CUSTOMER" }).then((response) => {
        if (alive) {
          setCompanyNames(
            response.results.map((result) => ({
              label: result.name,
              value: result.id
            }))
          );
        }
      });
    };

    loadCompanyNames();

    return () => {
      alive = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerobject]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/contracts/customerobjects${
                customerobject ? `/${customerobject.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {customerobject
            ? t("editCustomerobjectHeading")
            : t("newCustomerobjectHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={props.handleSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="new-customerobject-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="new-customerobject-form-invoice_name"
                type="text"
                name="invoice_name"
                label={t("invoiceNameLabel")}
                placeholder={t("invoiceNameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                type="text"
                name="accounting_reference"
                label={t("accountingReferenceLabel")}
                placeholder={t("accountingReferenceLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-customerobject-company"
              >
                {t("customerLabel")}
              </InputLabel>
              <Field
                id="form-customerobject-company"
                type="text"
                name="customer"
                label={t("customerLabel")}
                placeholder={t("customerLabel")}
                value={
                  companyNames.find(
                    (company) => props.values.customer === company.value
                  )
                    ? companyNames.find(
                        (company) => props.values.customer === company.value
                      )
                    : null
                }
                options={companyNames}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
                customHandleChange={loadCustomerPersons}
              />

              <Collapse
                in={props.values.customer !== -1 && personOptions.length > 0}
              >
                <InputLabel
                  className={classes.selectLabel}
                  htmlFor="form-customerobject-contact_person"
                >
                  {t("contactPersonLabel")}
                </InputLabel>
                <Field
                  id="form-customerobject-contact_person"
                  type="text"
                  name="contact_person"
                  label={t("contactPersonLabel")}
                  placeholder={t("contactPersonLabel")}
                  value={
                    personOptions.find(
                      (person) => props.values.contact_person === person.value
                    )
                      ? personOptions.find(
                          (person) =>
                            props.values.contact_person === person.value
                        )
                      : null
                  }
                  options={personOptions}
                  component={AutoCompleteSelect}
                  margin="normal"
                  fullWidth
                />
              </Collapse>

              {/* Status type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-customerobject-form-status"
              >
                {t("statusLabel")}
              </InputLabel>
              <Field
                id="new-customerobject-form-status"
                name="status"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              {/* Customer object type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="new-customerobject-form-customer_object_type"
              >
                {t("customerObjectTypeLabel")}
              </InputLabel>
              <Field
                id="new-customerobject-form-customer_object_type"
                name="customer_object_type"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {customerobjectTypeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              <Field
                id="new-customerobject-allow_photos"
                name="allow_photos"
                Label={{ label: t("allowPhotosFieldLabel") }}
                type="checkbox"
                component={CheckboxWithLabel}
              />
              <Field
                id="new-customerobject-invoice_address_enabled"
                name="invoice_address_enabled"
                Label={{ label: t("invoiceAddressEnabledFieldLabel") }}
                type="checkbox"
                component={CheckboxWithLabel}
              />
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      id="new-customerobject-form-weekday_opening"
                      name="weekday_opening"
                      label={t("weekDayOpeningLabel")}
                      placeholder={initialValues.weekday_opening}
                      component={TimePicker}
                      format="HH:mm"
                      margin="normal"
                      fullWidth
                    />
                    <Field
                      id="new-customerobject-form-saturday_opening"
                      name="saturday_opening"
                      label={t("saturdayOpeningLabel")}
                      placeholder={initialValues.saturday_opening}
                      component={TimePicker}
                      format="HH:mm"
                      margin="normal"
                      fullWidth
                    />
                    <Field
                      id="new-customerobject-form-sunday_opening"
                      name="sunday_opening"
                      label={t("sundayOpeningLabel")}
                      placeholder={initialValues.sunday_opening}
                      component={TimePicker}
                      format="HH:mm"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      id="new-customerobject-form-weekday_closing"
                      name="weekday_closing"
                      label={t("weekDayClosingLabel")}
                      placeholder={initialValues.weekday_closing}
                      component={TimePicker}
                      format="HH:mm"
                      margin="normal"
                      fullWidth
                    />

                    <Field
                      id="new-customerobject-form-saturday_closing"
                      name="saturday_closing"
                      label={t("saturdayClosingLabel")}
                      placeholder={initialValues.saturday_closing}
                      component={TimePicker}
                      format="HH:mm"
                      margin="normal"
                      fullWidth
                    />

                    <Field
                      id="new-customerobject-form-sunday_closing"
                      name="sunday_closing"
                      label={t("sundayClosingLabel")}
                      placeholder={initialValues.sunday_closing}
                      component={TimePicker}
                      format="HH:mm"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>

              <div className={classes.mapContainer}>
                <LocationMap
                  location={props.values.location}
                  editable={true}
                  formikProps={props}
                />
              </div>

              <Collapse
                in={props.values.customer !== -1 && personOptions.length > 0}
              >
                <Field
                  id="new-customerobject-form-persons"
                  type="text"
                  name="persons"
                  placeholder={t("staffFieldsLabel")}
                  TextFieldProps={{
                    label: t("staffFieldsLabel"),
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true
                    }
                  }}
                  value={
                    props.values.persons
                      ? (props.values.persons.map((person) =>
                          personOptions.find(
                            (personOption) => person === personOption.value
                          )
                        ) as SelectFieldOption[])
                      : props.values.persons
                  }
                  options={personOptions}
                  component={AutoCompleteSelectMulti}
                  margin="normal"
                  fullWidth
                />
              </Collapse>

              <Field
                id="new-customerobject-form-passcards"
                type="text"
                name="passcards"
                placeholder={t("passcardsLabel")}
                TextFieldProps={{
                  label: t("passcardsLabel"),
                  InputLabelProps: {
                    htmlFor: "react-select-single",
                    shrink: true
                  }
                }}
                component={AutoCompleteSelectMultiNEW}
                margin="normal"
                fullWidth
                customAttribute={["serial_number"]}
                loadOptionsAPI={getSearchPasscardOptionsAPI}
                selectedIds={customerobject ? customerobject.passcards : []}
              />

              <Field
                id="new-customerobject-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-customerobject-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {customerobject ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditCustomerobject);
