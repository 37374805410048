import { LayoutRouteUsage } from "../../redux/types";
import PersonsContainer from "./PersonsContainer";
import PersonNewFormContainer from "./PersonNewFormContainer";
import PersonContainer from "./PersonContainer";
import PersonEditFormContainer from "./PersonEditFormContainer";
import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import PersonIcon from "@material-ui/icons/Person";

const PersonsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["features/persons"],
    routes: [
      {
        path: "/auth/persons",
        label: t("personsNavbarLabel"),
        component: PersonsContainer,
        icon: PersonIcon,
        highlightClass: NavItemClassEnum.PERSONS,
        navId: NavItemClassEnum.PERSONS
      },
      {
        path: "/auth/persons/new",
        parent: "/auth/persons",
        label: t("newPersonLabel"),
        component: PersonNewFormContainer,
        highlightClass: NavItemClassEnum.PERSONS
      },
      {
        path: "/auth/persons/:personId",
        parent: "/auth/persons",
        label: t("personLabel"),
        component: PersonContainer,
        highlightClass: NavItemClassEnum.PERSONS
      }
    ]
  },
  {
    apiUsage: ["auth/persons"],
    requireSuperuser: true,
    routes: [
      {
        path: "/auth/persons/:personId/edit",
        parent: "/auth/persons/:personId",
        label: t("editPersonLabel"),
        component: PersonEditFormContainer,
        highlightClass: NavItemClassEnum.PERSONS
      }
    ]
  }
];

export default PersonsRouter;
