export const SearchSuggestionsHash: Record<string, string> = {
  "/contractor/tasks": "/contracts/tasks",
  "/orders/holidays": "/holidays",
  "/services/notificationsprofiles": "/notification-profiles",
  "/contractor/projects": "/contracts/projects"
};
// const notFound = [
//   {
//     id: 2,
//     name: "Snöröjning",
//     parent_id: null,
//     parent_path: null,
//     path: "contracts/servicecategories",
//     searchBy: "sn",
//     comment: "TODO: its inside baseservice instance and business area instance",
//   },
// ];
