import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  DistrictForm,
  District,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import DistrictNewEditForm from "../../../components/companies/districts/DistrictNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditDistrictContainerProps
  extends FormikSubmitDispatchProps<DistrictForm>,
    TabComponentProps<"districtId"> {
  district: District;
  parentDistrictOptions: SelectFieldOption[];
  loadDistrict: (districtId: number) => void;
  loadDistricts: (searchTerm?: string) => void;
  loadingDistrict: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<District | DistrictForm> {
  loadDistrict: (districtId: number) => void;
  loadDistricts: (searchTerm?: string) => void;
}

const EditDistrictContainer: React.FC<EditDistrictContainerProps> = (props) => {
  const {
    loadDistrict,
    district,
    routeParams: { districtId },
    loadDistricts,
    loadingDistrict
  } = props;
  useEffect(() => loadDistrict(districtId), [loadDistrict, districtId]);
  useEffect(() => loadDistricts(), [loadDistricts]);

  return loadingDistrict ? (
    <LoadingSpinnerPaper />
  ) : (
    <DistrictNewEditForm
      district={district}
      parentDistrictOptions={props.parentDistrictOptions}
      handleSubmit={props.handleSubmit}
      loadDistricts={props.loadDistricts}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.DISTRICT,
        `companies/districts/${(checkedNullValues as District).id}/`,
        checkedNullValues,
        `/companies/districts/${
          (checkedNullValues as District).id
        }?same_tab=true`
      )
    );
  },
  loadDistrict: (districtId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.DISTRICT,
        `companies/districts/${districtId}/`
      )
    );
  },
  loadDistricts: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.DISTRICTS,
        `companies/districts/?order__name=asc&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  district: state.tabStates[ownProps.tabId].districts.district.district,
  parentDistrictOptions: state.tabStates[
    ownProps.tabId
  ].districts.districts.results.map((district) => ({
    label: district.name,
    value: district.id
  })),
  loadingDistrict:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingDistrict
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDistrictContainer);
