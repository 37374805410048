import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
  TableHead,
  Typography,
  Tooltip,
  Button,
  Grid,
  InputLabel,
  TablePagination,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Email, EmailsFilterQuery } from "../../redux/types";
import { getEmailsAPI } from "../../services/api-declaration";
import { niceDate } from "../FormatHelpers";
import { getInvoiceEmailStatusIconWithColor } from "../../helpers/invoiceHelper";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "../../services/appLanguageService";
import { ArrowDownward, ArrowUpward, SwapVert } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { APP_DATE_FORMAT } from "../../helpers/timesheetHelperService";
import FilterListIcon from "@material-ui/icons/FilterList";
import LoadingSpinnerPaper from "../LoadingSpinnerPaper";
import { set, subWeeks, isValid } from "date-fns";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => ({
  dateColumn: {
    width: 160
  },
  statusColumn: {
    width: 60
  },
  header: {
    fontWeight: "bold"
  },
  paper: {
    marginTop: 20
  },
  title: {
    padding: 20
  },
  smallIcon: {
    fontSize: "1rem"
  },
  sortable: {
    cursor: "pointer"
  },
  headerCell: {
    fontWeight: "bold",
    padding: 6,
    "&:last-child": {
      paddingRight: 10
    },
    "&:first-child": {
      paddingLeft: 15
    },
    userSelect: "none",
    "-webkit-user-select": "none"
  },
  noResults: { textAlign: "center" },
  filterContainer: {
    padding: 30
  },
  picker: {
    marginRight: theme.spacing(1)
  },
  pickerLabel: {
    fontSize: 12,
    color: "#43a047",
    marginTop: 10
  },
  addressLabel: {
    fontSize: 12,
    color: "#43a047",
    marginTop: 10,
    marginBottom: 16
  },
  select: {
    marginTop: 10
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between"
    },
    color: "#43a047"
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  flex: {
    display: "flex"
  }
}));

type COLUMNTYPE = "subject" | "date" | "to_addresses" | "status";

interface EmailsProps {}

const Emails: React.FC<EmailsProps> = (props) => {
  const t = useTranslate("EmailsPage");
  const [emails, setEmails] = useState<{ results: Email[]; count: number }>({
    count: 0,
    results: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const searchTimerRef = useRef<number>();

  const defaultStartDate = subWeeks(
    set(new Date(), { hours: 0, minutes: 0 }),
    1
  );
  const defaultEndDate = set(new Date(), { hours: 23, minutes: 59 });
  const DEFAULT_PAGE_SIZE = 200;

  const DEFAULT_QUERY: EmailsFilterQuery = {
    order__date: "desc",
    page: 1,
    page_size: DEFAULT_PAGE_SIZE,
    filter__date__lt: defaultEndDate.toISOString(),
    filter__date__gt: defaultStartDate.toISOString()
  };

  const [query, setQuery] = useState<EmailsFilterQuery>({
    ...DEFAULT_QUERY
  });

  const handlePageChange = (_: any, page: number) => {
    if (page + 1 === query.page) return;
    setQuery((query) => ({
      ...query,
      page: page + 1
    }));
  };

  useEffect(() => {
    const is_valid =
      (!query.filter__date__gt || isValid(new Date(query.filter__date__gt))) &&
      (!query.filter__date__lt || isValid(new Date(query.filter__date__lt)));

    if (is_valid) {
      let isAlive = true;
      setIsLoading(true);
      getEmailsAPI(query).then((emailsResponse) => {
        if (isAlive) {
          setEmails({
            results: emailsResponse.results,
            count: emailsResponse.count
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });

      return () => {
        isAlive = false;
      };
    }
  }, [query]);

  const columnHeaders: { field: COLUMNTYPE; label: string }[] = [
    {
      field: "subject",
      label: t("subjectLabel")
    },
    {
      field: "date",
      label: t("dateLabel")
    },
    {
      field: "to_addresses",
      label: t("toAddressesLabel")
    },
    {
      field: "status",
      label: t("statusLabel")
    }
  ];

  const changeDateSorting = () => {
    if (!query.order__date) {
      setQuery((q) => ({ ...q, order__date: "desc" }));
    } else if (query.order__date === "desc") {
      setQuery((q) => ({ ...q, order__date: "asc" }));
    } else if (query.order__date === "asc") {
      setQuery((q) => {
        const { order__date, ...newQuery } = q;
        return newQuery;
      });
    }
  };

  const generateSortIcon = () => {
    if (query.order__date === "asc") {
      return <ArrowUpward className={classes.smallIcon} />;
    } else if (query.order__date === "desc") {
      return <ArrowDownward className={classes.smallIcon} />;
    }
    return (
      <Tooltip title="Sort">
        <SwapVert className={classes.smallIcon} />
      </Tooltip>
    );
  };

  const handleSearch = (searchText: string) => {
    setSearchTerm(searchText);
    if (searchTimerRef.current) {
      window.clearTimeout(searchTimerRef.current);
    }
    searchTimerRef.current = window.setTimeout(() => {
      setQuery((tempQuery) => ({
        ...tempQuery,
        filter__to_addresses__icontains: searchText
      }));
    }, 400);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-cy="filter-expand"
          className={classes.titleRow}
        >
          <Typography className={`${classes.flex}`}>
            <FilterListIcon className={classes.leftIcon} /> Filter
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setQuery((q) => ({
                page: 1,
                page_size: DEFAULT_PAGE_SIZE,
                order__date: q.order__date
              }));
              setSearchTerm("");
            }}
          >
            {t("clearButton")}
          </Button>
        </AccordionSummary>
        <AccordionDetails data-cy="expanded-filters">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.addressLabel}>
                {t("toAddressesLabel")}
              </InputLabel>
              <TextField
                fullWidth
                value={searchTerm}
                placeholder={t("toAddressesLabel")}
                onChange={(event) => handleSearch(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        disabled={searchTerm === ""}
                        onClick={() => handleSearch("")}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel className={classes.pickerLabel}>
                {t("startLabel")}
              </InputLabel>
              <KeyboardDatePicker
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                clearable
                animateYearScrolling
                className={classes.picker}
                format={APP_DATE_FORMAT}
                value={query.filter__date__gt || null}
                onChange={(date: MaterialUiPickersDate, value) => {
                  if (value) {
                    setQuery((tempQuery) => ({
                      ...tempQuery,
                      filter__date__gt: value
                    }));
                  } else {
                    const { filter__date__gt, ...clearedQuery } = query;
                    setQuery(clearedQuery);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel className={classes.pickerLabel}>
                {t("endLabel")}
              </InputLabel>
              <KeyboardDatePicker
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                clearable
                animateYearScrolling
                className={classes.picker}
                format={APP_DATE_FORMAT}
                value={query.filter__date__lt ? query.filter__date__lt : null}
                onChange={(date: MaterialUiPickersDate, value) => {
                  if (value) {
                    setQuery((tempQuery) => ({
                      ...tempQuery,
                      filter__date__lt: value
                    }));
                  } else {
                    const { filter__date__lt, ...clearedQuery } = query;
                    setQuery(clearedQuery);
                  }
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {!isLoading ? (
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.title}>
            Email
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columnHeaders.map((column) => (
                    <TableCell
                      className={`${classes.headerCell} ${
                        column.field === "date" && classes.sortable
                      }`}
                      onClick={() =>
                        column.field === "date" && changeDateSorting()
                      }
                    >
                      {column.label}{" "}
                      {column.field === "date" && generateSortIcon()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {emails.results.map((email) => (
                  <TableRow>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/services/emails/${email.id}`}
                      >
                        {email.subject}
                      </Link>
                    </TableCell>
                    <TableCell className={classes.dateColumn}>
                      {niceDate(email.date)}
                    </TableCell>
                    <TableCell>{email.to_addresses}</TableCell>
                    <TableCell className={classes.statusColumn}>
                      {getInvoiceEmailStatusIconWithColor(email.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={emails.count ?? 0}
              rowsPerPage={query.page_size ?? DEFAULT_PAGE_SIZE}
              page={typeof query.page !== "undefined" ? query.page - 1 : 0}
              onPageChange={handlePageChange}
              rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
            />
          </TableContainer>
        </Paper>
      ) : (
        <LoadingSpinnerPaper />
      )}
    </>
  );
};

export default Emails;
