import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, InvoicingProfile } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings } from "../../redux/strings";
import ResourceLink from "../../components/links/ResourceLink";

interface InvoicingProfileLinkContainerProps {
  invoicingProfileId: number;
  invoicingProfile: InvoicingProfile;
  loadInvoicingProfile: (invoicingProfileId: number) => void;
}

const InvoicingProfileLinkContainer: React.FC<
  InvoicingProfileLinkContainerProps
> = (props) => {
  const { loadInvoicingProfile, invoicingProfileId, invoicingProfile } = props;
  useEffect(
    () => loadInvoicingProfile(invoicingProfileId),
    [loadInvoicingProfile, invoicingProfileId]
  );
  return (
    <span>
      {invoicingProfile && (
        <ResourceLink
          label={invoicingProfile.name}
          id={invoicingProfile.id}
          url="invoices/invoicingprofiles"
        />
      )}
    </span>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadInvoicingProfile: (invoicingProfileId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "INVOICINGPROFILE",
        `invoices/invoicingprofiles/${invoicingProfileId}/`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  invoicingProfile:
    state.tabStates[ownProps.tabId].invoicingProfiles.invoicingProfile
      .invoicingProfile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicingProfileLinkContainer);
