import { useState, useEffect } from "react";
import store from "../../redux/store";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Formik, Field, FormikHelpers } from "formik";
import {
  Paper,
  Typography,
  Button,
  Grow,
  Checkbox,
  Link,
  CircularProgress
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Redirect, useHistory } from "react-router-dom";
import {
  FormikSubmitDispatchProps,
  LoginSubmitValues
} from "../../redux/types";
import { createResetLoginError } from "../../redux/reducers/authentication/actions";
import { useTranslate } from "../../services/appLanguageService";
import { getFromSessionStore } from "../../services/storageService";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    paper: {
      width: 500,
      padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`
    },
    form: {
      width: "100%" // Fix IE 11 issue.
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    info: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3)
    },
    checkboxField: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    checkboxFieldLabel: {
      fontWeight: 600,
      color: "gray"
    },
    flexDiv: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px 0"
    },
    loginButtonLoader: {
      paddingLeft: "12px"
    }
  })
);

interface LoginProps extends FormikSubmitDispatchProps<LoginSubmitValues> {
  authenticating: boolean;
  authenticated: boolean;
  isLoginError: boolean;
}

export default function Login(props: LoginProps) {
  const { isLoginError } = props;
  const [fade, setFade] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setFade(true), 100);
  }, []);
  const classes = useStyles();
  const [userDetails, setUserDetails] = useState<LoginSubmitValues>({
    domain: "",
    username: "",
    password: "",
    rememberMe: false
  });
  const history = useHistory();
  const t = useTranslate("LoginPage");

  const ValidationSchema = Yup.object().shape({
    domain: Yup.string().min(1).max(255).required(t("fieldRequired")),
    username: Yup.string().min(1).max(255).required(t("fieldRequired")),
    password: Yup.string().min(1).max(255).required(t("fieldRequired")),
    rememberMe: Yup.boolean()
  });

  const initLogin = () => {
    const rememberUser = getFromSessionStore("rememberUser");
    if (rememberUser) {
      const { domain, username, rememberMe } = JSON.parse(rememberUser);
      setUserDetails({
        domain,
        username,
        rememberMe: rememberMe,
        password: ""
      });
    }
  };

  const handleLoginSubmit = (
    values: LoginSubmitValues,
    actions: FormikHelpers<LoginSubmitValues>
  ) => {
    props.handleSubmit(values, actions);
  };

  const handleFormChange = () => {
    if (isLoginError) {
      store.dispatch(createResetLoginError());
    }
  };

  useEffect(initLogin, []);

  return props.authenticated ? (
    <Redirect push to={`/${history.location.search}`} />
  ) : (
    <div className={classes.root}>
      <Grow timeout={3000} in={fade}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4">
            {t("headerLabel")}
          </Typography>
          <Typography
            component="h1"
            variant="subtitle2"
            className={classes.info}
          >
            {t("formInfo")}
          </Typography>
          {isLoginError && <Alert severity="error">{t("loginError")}</Alert>}
          <Formik
            initialValues={userDetails}
            enableReinitialize
            validationSchema={ValidationSchema}
            onSubmit={handleLoginSubmit}
            authenticating={props.authenticating}
          >
            {(formikProps) => {
              return (
                <form
                  className={classes.form}
                  onSubmit={formikProps.handleSubmit}
                  autoComplete="off"
                  onChange={handleFormChange}
                >
                  <Field
                    id="login-domain"
                    type="text"
                    min="1"
                    max="255"
                    autoFocus
                    name="domain"
                    label={t("domainFieldLabel")}
                    placeholder={t("domainFieldPlaceholder")}
                    component={TextField}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                  />
                  <Field
                    id="login-username"
                    type="text"
                    min="1"
                    max="255"
                    name="username"
                    label={t("usernameFieldLabel")}
                    placeholder={t("usernameFieldPlaceholder")}
                    component={TextField}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                  />
                  <Field
                    id="login-password"
                    type="password"
                    min="1"
                    max="255"
                    name="password"
                    label={t("passwordFieldLabel")}
                    placeholder={t("passwordFieldPlaceholder")}
                    component={TextField}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                  />
                  <div className={classes.checkboxField}>
                    <label className={classes.checkboxFieldLabel}>
                      {t("saveMe")}
                    </label>
                    <Checkbox
                      name="rememberMe"
                      value={formikProps.values.rememberMe}
                      checked={formikProps.values.rememberMe}
                      onClick={formikProps.handleChange}
                    />
                  </div>
                  <Button
                    id="login-submit"
                    type="submit"
                    size="large"
                    disabled={props.authenticating}
                    fullWidth
                    variant="text"
                    color="primary"
                    className={classes.submit}
                  >
                    {t("loginButtonLabel")}
                    {props.authenticating && (
                      <span className={classes.loginButtonLoader}>
                        <CircularProgress size="1rem" />
                      </span>
                    )}
                  </Button>
                  <div className={classes.flexDiv}>
                    <Link
                      component="button"
                      onClick={() => {
                        history.push("/forget-password");
                      }}
                    >
                      {t("navigateToForgetPasswordLable")}
                    </Link>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Paper>
      </Grow>
    </div>
  );
}
