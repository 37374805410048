import React, { useState, useEffect, useCallback } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Collapse
} from "@material-ui/core";
import {
  TextField,
  Select as FormikSelect,
  CheckboxWithLabel
} from "formik-material-ui";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  FormikSubmitDispatchProps,
  Contract,
  ContractForm,
  SelectFieldOption
} from "../../../redux/types";
import { EnumStrings } from "../../../redux/strings";
import DatePicker from "../../DatePicker";
import {
  getCompanyNamesAPI,
  getFilteredCompaniesAPI
} from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import MarkdownField from "./MarkdownField";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";

const signatureTypeOptions = [EnumStrings.ANALOG, EnumStrings.DIGITAL];

const acceptStatusOptions = [
  EnumStrings.ACCEPTED,
  EnumStrings.PENDING,
  EnumStrings.DRAFT,
  EnumStrings.DECLINED
];

const displayAsOptions = [EnumStrings.CONTRACT, EnumStrings.PENDING];

const statusOptions = [
  EnumStrings.ACTIVE,
  EnumStrings.ARCHIVED,
  EnumStrings.LOCKED,
  EnumStrings.DRAFT
];

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    display_name: Yup.string()
      .min(1, t("minLengthError"))
      .max(100, t("max100LengthError"))
      .nullable()
      .notRequired(),
    status: Yup.string()
      .oneOf(statusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    accept_status: Yup.string()
      .oneOf(acceptStatusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    signature_type: Yup.string()
      .oneOf(signatureTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    display_as: Yup.string()
      .oneOf(displayAsOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    invoice_ref: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    contract_text: Yup.string().nullable().notRequired(),
    comment: Yup.string().nullable().notRequired(),
    description: Yup.string().nullable().notRequired(),
    footnote: Yup.string().nullable().notRequired(),
    final_conditions: Yup.string().nullable().notRequired(),
    show_validity: Yup.boolean().required(),
    valid_from: Yup.date().nullable().notRequired(),
    valid_to: Yup.date()
      .min(Yup.ref("valid_from"), t("validFromLaterThanValidToError"))
      .nullable()
      .notRequired(),
    company: Yup.number()
      .positive(t("requiredError"))
      .required(t("requiredError")),
    duedays: Yup.number().positive(t("requiredError")).notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5)
    },
    m2: { marginTop: theme.spacing(2.5) },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    },
    errMessage: {
      color: "#f44336",
      fontSize: "0.75rem",
      marginTop: theme.spacing(0.5)
    },
    mutedText: { color: "#757575" }
  });

interface NewContractProps
  extends FormikSubmitDispatchProps<ContractForm>,
    WithStyles<typeof styles> {
  contract?: Contract;
  personOptions: SelectFieldOption[];
  loadCompanyPersons: (companyId: number) => void;
}

const NewEditContract: React.FC<NewContractProps> = (props) => {
  const { classes, contract, loadCompanyPersons, personOptions } = props;
  const t = useTranslate("ContractPage");
  const t2 = useTranslate("Contracts");
  const t3 = useTranslate("ValidationErrorMessages");
  const [companyOptions, setCompanyOptions] = useState<SelectFieldOption[]>([]);
  const [contractSignerOptions, setContractSignerOptions] = useState<
    SelectFieldOption[]
  >([]);
  const signatureTypeOptions = [EnumStrings.ANALOG, EnumStrings.DIGITAL];

  const acceptStatusOptions = [
    EnumStrings.ACCEPTED,
    EnumStrings.PENDING,
    EnumStrings.DRAFT,
    EnumStrings.DECLINED
  ];

  const displayAsOptions = [EnumStrings.CONTRACT, EnumStrings.PENDING];

  const statusOptions = [
    EnumStrings.ACTIVE,
    EnumStrings.ARCHIVED,
    EnumStrings.LOCKED,
    EnumStrings.DRAFT
  ];

  let initialValues: Contract | ContractForm;
  if (contract && (contract as Contract).id) {
    initialValues = {
      ...contract,
      contract_text: contract.contract_text ? contract.contract_text : "",
      invoice_ref: contract.invoice_ref ? contract.invoice_ref : "",
      comment: contract.comment ? contract.comment : "",
      description: contract.description ? contract.description : "",
      footnote: contract.footnote ? contract.footnote : "",
      final_conditions: contract.final_conditions
        ? contract.final_conditions
        : ""
    };
  } else {
    initialValues = {
      created_at: "",
      updated_at: "",
      name: "",
      display_name: "",
      status: "DRAFT",
      accept_status: "DRAFT",
      signature_type: "ANALOG",
      display_as: "CONTRACT",
      invoice_ref: "",
      company_type: "",
      contract_text: "",
      comment: "",
      description: "",
      terms: undefined,
      footnote: "",
      final_conditions: "",
      show_validity: false,
      valid_from: null,
      valid_to: null,
      accepted_by_user: "",
      services: [],
      materials: [],
      accessories: [],
      addons: [],
      supplements: [],
      servicepackages: [],
      tasks: [],
      cooperations: [],
      modifying_tasks: [],
      duedays: 30,
      company: -1,
      oca: -1,
      businessarea: -1,
      signer_name: "",
      counterpart_signer: null,
      countersigner_name: "",
      signer: null,
      invoicingperiod: "MONTHLY"
    };
  }

  const loadCompanies = async () => {
    try {
      const response = await getCompanyNamesAPI({});
      setCompanyOptions(
        response.results.map((result) => ({
          label: result.name,
          value: result.id
        }))
      );
    } catch (e) {
      console.warn(e);
    }
  };
  const loadContractSignerOptions = async () => {
    try {
      const response = await getFilteredCompaniesAPI();
      const options = response.results.length
        ? response.results.map((option) => {
            return {
              label: option.name,
              value: option.id
            };
          })
        : [];
      setContractSignerOptions(options);
    } catch (e) {
      console.warn(e);
    }
  };

  const initCb = useCallback(() => {
    loadContractSignerOptions();
    loadCompanies();
  }, []);
  useEffect(() => {
    initCb();
  }, [initCb]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/contracts/contracts${
                contract ? `/${contract.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("contractLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {contract ? t("changeContractLabel") : t("newContractLabel")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={props.handleSubmit}
          enableReinitialize
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="contract-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              <Field
                id="contract-form-display_name"
                type="text"
                name="display_name"
                label={t("displayNameLabel")}
                placeholder={t("displayNameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <InputLabel
                className={classes.selectLabel}
                htmlFor="contract-form-company"
              >
                {t("companyLabel")}
              </InputLabel>
              <Field
                id="contract-form-company"
                type="text"
                name="company"
                label={t("companyLabel")}
                placeholder={t("companyPlaceholder")}
                value={
                  companyOptions.find(
                    (companyOption) =>
                      props.values.company === companyOption.value
                  )
                    ? companyOptions.find(
                        (companyOption) =>
                          props.values.company === companyOption.value
                      )
                    : null
                }
                customHandleChange={loadCompanyPersons}
                options={companyOptions}
                component={AutoCompleteSelect}
                margin="normal"
                fullWidth
              />

              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="contract-form-signer">
                  {t("signerLabel")}
                </InputLabel>
                <Select
                  id="contract-form-signer"
                  name="signer"
                  value={props.values.signer || ""}
                  onChange={props.handleChange}
                  type="number"
                  placeholder={t("signerLabel")}
                  fullWidth
                >
                  {contractSignerOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Collapse
                in={props.values.company !== -1 && personOptions.length > 0}
              >
                <InputLabel
                  className={classes.selectLabel}
                  htmlFor="form-counterpart_signe"
                >
                  {t("counterpartSigner")}
                </InputLabel>
                <Field
                  id="form-counterpart_signer"
                  type="text"
                  name="counterpart_signer"
                  label={t("counterpartSigner")}
                  placeholder={t("counterpartSigner")}
                  value={
                    personOptions.find(
                      (personOption) =>
                        props.values.counterpart_signer === personOption.value
                    )
                      ? personOptions.find(
                          (personOption) =>
                            props.values.counterpart_signer ===
                            personOption.value
                        )
                      : null
                  }
                  options={personOptions}
                  component={AutoCompleteSelect}
                  margin="normal"
                  fullWidth
                />
              </Collapse>

              <Field
                id="contract-form-valid_from"
                name="valid_from"
                label={t("validFromLabel")}
                placeholder={initialValues.valid_from}
                component={DatePicker}
                format="yyyy-MM-dd"
                margin="normal"
                fullWidth
              />
              <Field
                id="contract-form-valid_to"
                name="valid_to"
                label={t("validToLabel")}
                placeholder={initialValues.valid_to}
                component={DatePicker}
                format="yyyy-MM-dd"
                margin="normal"
                fullWidth
              />

              {/* Show validity */}
              <Field
                id="contract-form-show_validity"
                name="show_validity"
                Label={{ label: t("showValidityLabel") }}
                type="checkbox"
                component={CheckboxWithLabel}
              />

              {/* Status */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="contract-form-status"
              >
                {t("statusLabel")}
              </InputLabel>
              <Field
                id="contract-form-status"
                name="status"
                component={FormikSelect}
                className={classes.select}
                disabled={contract && contract.id !== null}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>
              {contract && contract.id && (
                <small className={classes.mutedText}>
                  {t("statusFieldInfoMessage")}
                </small>
              )}
              {/* Signature type */}
              <InputLabel
                className={`${classes.selectLabel} ${
                  props.errors.signature_type && props.touched.signature_type
                    ? "Mui-error"
                    : ""
                }`}
                htmlFor="contract-form-signature_type"
              >
                {t("signatureTypeLabel")}
              </InputLabel>
              <Field
                id="contract-form-signature_type"
                name="signature_type"
                component={FormikSelect}
                className={`${classes.select} ${
                  props.errors.signature_type && props.touched.signature_type
                    ? "Mui-error"
                    : ""
                }`}
                fullWidth
              >
                {signatureTypeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>
              <div className={classes.errMessage}>
                <ErrorMessage name="signature_type" />
              </div>
              {/* Display as */}
              <InputLabel
                className={`${classes.selectLabel} ${
                  props.errors.display_as && props.touched.display_as
                    ? "Mui-error"
                    : ""
                }`}
                htmlFor="contract-form-display_as"
              >
                {t("displayAsLabel")}
              </InputLabel>
              <Field
                id="contract-form-display_as"
                name="display_as"
                component={FormikSelect}
                className={`${classes.select} ${
                  props.errors.display_as && props.touched.display_as
                    ? "Mui-error"
                    : ""
                }`}
                fullWidth
              >
                {displayAsOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>
              <div className={classes.errMessage}>
                <ErrorMessage name="display_as" />
              </div>

              {/* Accept status */}
              <InputLabel
                className={`${classes.selectLabel} ${
                  props.errors.accept_status && props.touched.accept_status
                    ? "Mui-error"
                    : ""
                }`}
                htmlFor="contract-form-accept_status"
              >
                {t("acceptStatusLabel")}
              </InputLabel>
              <Field
                id="contract-form-accept_status"
                name="accept_status"
                component={FormikSelect}
                className={`${classes.select} ${
                  props.errors.accept_status && props.touched.accept_status
                    ? "Mui-error"
                    : ""
                }`}
                fullWidth
              >
                {acceptStatusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>
              <div className={classes.errMessage}>
                <ErrorMessage name="accept_status" />
              </div>

              <Field
                id="contract-form-invoice_ref"
                type="text"
                name="invoice_ref"
                label={t("invoiceRefLabel")}
                placeholder={t("invoiceRefLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="duedays"
                type="text"
                name="duedays"
                label={t("duedaysLabel")}
                placeholder={t("duedaysLabel")}
                keyName={"duedays"}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <Field
                id="contract-form-comment"
                type="text"
                name="comment"
                label={t("commentLabel")}
                placeholder={t("commentLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                variant="outlined"
                fullWidth
              />

              <Field
                id="contract-form-contract_text"
                type="text"
                name="contract_text"
                label={t("contractTextLabel")}
                placeholder={t("contractTextLabel")}
                component={MarkdownField}
                margin="normal"
                fullWidth
              />

              <Field
                id="contract-form-description"
                type="text"
                name="description"
                label={t("descriptionLabel")}
                placeholder={t("descriptionLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                variant="outlined"
                fullWidth
              />

              <Field
                id="contract-form-footnote"
                type="text"
                name="footnote"
                label={t("footnoteLabel")}
                placeholder={t("footnoteLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                variant="outlined"
                fullWidth
              />

              <Field
                id="contract-form-final_conditions"
                type="text"
                name="final_conditions"
                label={t("finalConditionsLabel")}
                placeholder={t("finalConditionsLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                variant="outlined"
                fullWidth
              />

              <Button
                id="contract-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {contract ? t("saveLabel") : t("createLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEditContract);
