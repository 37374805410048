import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { BaseAddon, Contract, Addon } from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import ResourceLink from "../../links/ResourceLink";
import {
  getAddonAPI,
  getBaseAddonAPI
} from "../../../services/api-declaration";
import * as Yup from "yup";
import LockIcon from "@material-ui/icons/Lock";
import EditableDatePicker from "../../EditableDatePicker";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface AddonProps extends WithStyles<typeof styles> {
  tabId: number;
  addonId: number;
  contract: Contract | undefined;
}

const AddonView: React.FC<AddonProps> = (props) => {
  const { classes, tabId, contract, addonId } = props;
  const t = useTranslate("AddonPage");
  const t3 = useTranslate("ValidationErrorMessages");

  const [baseAddon, setBaseaddon] = useState<BaseAddon>();
  const [expanded, setExpanded] = useState(false);
  const [addon, setAddon] = useState<Addon>();
  const updateResourceState = (value: Addon) => setAddon(value);

  const patchUrl = `contracts/addons/${addonId}/`;

  useEffect(() => {
    if (addon) store.dispatch(setTitle(addon.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addon]);

  useEffect(() => {
    let alive = true;
    if (contract?.id) {
      (async () => {
        const addon = await getAddonAPI(addonId);
        const baseAddonResponse = await getBaseAddonAPI(addon.base);

        if (alive) {
          setAddon(addon);
          setBaseaddon(baseAddonResponse);
        }
      })();
    }
    return () => {
      alive = false;
    };
  }, [contract?.id, addonId]);

  const addonIsLocked =
    contract?.locked_items["addons"]?.includes(addonId) || false;

  if (!addon) return <LoadingSpinnerPaper />;
  return addon.contract !== contract?.id ? (
    <Paper className={classes.pageNotFound}>
      <Typography variant="h6">{t("pageNotFoundTitle")}</Typography>
    </Paper>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      {contract?.name}: <b>{addon.name}</b>{" "}
                      {addonIsLocked && (
                        <Tooltip title={t("serviceLockedLabel")}>
                          <LockIcon />
                        </Tooltip>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {addonIsLocked ? (
                      addon.name
                    ) : (
                      <EditableTextField
                        field="name"
                        initValue={addon.name}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .min(1, t3("minLengthError"))
                          .max(255, t3("max255LengthError"))
                          .required(t3("requiredError"))}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contract && (
                      <ResourceLink
                        label={contract.name}
                        id={contract.id}
                        url="contracts/contracts"
                      />
                    )}
                  </TableCell>
                </TableRow>
                {addon.price !== null && (
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {addonIsLocked ? (
                        addon.price
                      ) : (
                        <EditableTextField
                          isPrice
                          field="price"
                          initValue={addon.price}
                          url={patchUrl}
                          updateResourceState={updateResourceState}
                          number={true}
                          validation={Yup.number()
                            .typeError(t3("numberRequiredError"))
                            .min(0, t3("minZeroError"))
                            .max(10000000, t3("max10000000NumberError"))
                            .required(t3("requiredError"))}
                        />
                      )}{" "}
                      kr {addon.unit ? `/ ${addon.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key={5}>
                  <TableCell align="left" className={classes.bold}>
                    {t("baseAddonLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {baseAddon && (
                      <ResourceLink
                        url="contracts/baseaddons"
                        label={baseAddon.name}
                        id={baseAddon.id}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("validFromLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableDatePicker
                        field="start_date"
                        initValue={addon.start_date}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("validToLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableDatePicker
                        field="end_date"
                        initValue={addon.end_date}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={addon.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className={classes.bold}>
                      {t("displayLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="display"
                        label={t("displayLabel")}
                        initValue={addon.display}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("orderByLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="order_by"
                        initValue={addon.order_by}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(addon.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(addon.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(AddonView);
