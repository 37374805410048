import {
  Breadcrumb,
  LayoutRoute,
  NavRoute,
  SearchResponseDictionary,
  TabComponentProps
} from "../redux/types";
import { getFromSessionStore } from "../services/storageService";
import { SearchSuggestionsHash } from "../strings/SearchSuggestionsStrings";

export const makePathStaticWithParams = (path: string) => {
  const ids: string[] = [];
  let staticPath = path.replace(/\d+/g, (m) => {
    ids.push(m);
    return ":_id";
  });
  if (staticPath.length > 1) {
    staticPath = staticPath.replace(/\/$/, "");
  }
  return { ids, staticPath };
};

export const makeRoutePathStatic = (route: string) =>
  route.replace(/:[^:/]+/g, ":_id");

export const buildRouteParams = (declaredPath: string, ids: string[]) => {
  const routeParams: LayoutRoute["routeParams"] = {};
  let count = 0;
  declaredPath.replace(/:([^:/]+)/g, (str, paramName: string) => {
    routeParams[paramName] = parseInt(ids[count++]);
    return str;
  });
  return routeParams;
};

export const getBreadcrumbsByRoute = (
  allRoutes: LayoutRoute[],
  staticPath: string,
  ids: string[],
  breadcrumbs: Breadcrumb[] = []
): Breadcrumb[] => {
  const foundRoute = allRoutes.find((r) => r.staticPath === staticPath);
  if (foundRoute) {
    const { label, staticParent } = foundRoute;
    let index = 0;
    let lastId = "";
    const breadcrumb: Breadcrumb = {
      label,
      path: staticPath.replace(/:_id/g, () => (lastId = ids[index++]))
    };
    if (!label && /:_id$/.test(staticPath)) {
      breadcrumb.label = lastId;
    }
    breadcrumbs.unshift(breadcrumb);
    if (staticParent) {
      return getBreadcrumbsByRoute(allRoutes, staticParent, ids, breadcrumbs);
    }
  }
  return breadcrumbs;
};

export const validateApiUsage = ({ apiUsage }: { apiUsage?: string[] }) => {
  return (
    (apiUsage as string[]).length === 0 ||
    (apiUsage as string[]).every((path) => window.allowedPaths.includes(path))
  );
};

export const mapTabRouteProps = (
  props: Record<string, any>
): TabComponentProps => {
  const {
    tabId,
    subTabIndex,
    suspendable,
    routeParams,
    urlQueryParams,
    actualPath
  } = props as TabComponentProps;
  return {
    tabId,
    subTabIndex,
    suspendable,
    routeParams,
    urlQueryParams,
    actualPath
  };
};

export const populateAndFilterNav = (
  appRoutesIdMap: Map<string, LayoutRoute>,
  navRoutes: NavRoute<string>[]
) => {
  const isSuperuser = getFromSessionStore("is_superuser") === "true";
  if (window.allowedPaths.length === 0) {
    return [];
  }
  return navRoutes.reduce((output, navRoute) => {
    if (typeof navRoute === "string") {
      const navLayoutRoute = appRoutesIdMap.get(navRoute);
      if (navLayoutRoute && (isSuperuser || !navLayoutRoute.requireSuperuser) && validateApiUsage(navLayoutRoute)) {
        const { navId, label, icon, path, navLabel } = navLayoutRoute;
        output.push({
          navId: navId as string,
          label,
          icon,
          path,
          children: [],
          navLabel,
          highlightClasses: navId as string
        });
      }
    } else {
      const children: LayoutRoute[] = [];
      let highlightClasses = "";
      navRoute.children.forEach((routeId) => {
        const layoutRoute = appRoutesIdMap.get(routeId);
        if (layoutRoute && (isSuperuser || !layoutRoute.requireSuperuser) && validateApiUsage(layoutRoute)) {
          children.push(layoutRoute);
          highlightClasses += layoutRoute.highlightClass as string;
        }
      });
      if (children.length) {
        output.push({
          ...navRoute,
          highlightClasses,
          children
        });
      }
    }
    return output;
  }, [] as NavRoute<LayoutRoute>[]);
};

export const getSearchInfo = (
  AppStaticRoutesMap: Map<string, LayoutRoute>,
  item: SearchResponseDictionary
) => {
  const mapSearchPath = (path: string) => SearchSuggestionsHash[path] || path;
  const instRoute = AppStaticRoutesMap.get(mapSearchPath(`/${item.path}`));
  const parentRoute = AppStaticRoutesMap.get(
    mapSearchPath(`/${item.parent_path}`)
  );
  if (instRoute) {
    return {
      icon: instRoute.icon,
      path: `${instRoute.staticPath}/${item.id}`
    };
  } else if (parentRoute) {
    return {
      icon: parentRoute.icon,
      path: `${parentRoute.staticPath}/${item.parent_id}`
    };
  } else if (item.parent_path && item.parent_id) {
    console.log(
      "item search info not found but navigating with parent path",
      item
    );
    return {
      icon: "",
      path: `${item.parent_path}/${item.parent_id}`
    };
  }
};
export const transformSearchData = (
  data: SearchResponseDictionary[],
  AppStaticRoutesMap: Map<string, LayoutRoute>
) =>
  data
    .flatMap((item) => [item].concat(item.children?.slice(0, 3) || []))
    .map((item) => {
      const searchInfo = getSearchInfo(AppStaticRoutesMap, item);
      if (searchInfo) {
        return { ...item, icon: searchInfo.icon };
      } else {
        console.error("Search item not found: ", item);
      }
      return item;
    });

export const extractURLSearch = (urlSearchParams: URLSearchParams) => {
  const output: LayoutRoute["urlQueryParams"] = {};
  urlSearchParams.forEach((val, key) => {
    output[key] = val;
  });
  return output;
};

export const deleteFromUrlSearch = (
  urlSearchParams: URLSearchParams,
  params: string[]
) => {
  let isChanged = false;
  params.forEach((key) => {
    if (urlSearchParams.has(key)) {
      urlSearchParams.delete(key);
      isChanged = true;
    }
  });
  return isChanged;
};

export const convertToUrlSearch = ({
  urlQueryParams
}: {
  urlQueryParams: LayoutRoute["urlQueryParams"];
}) => new URLSearchParams(urlQueryParams as Record<string, string>);

export const generateRouteSegmentRowId = (rows: Array<{ row_id: number }>) =>
  rows.length !== 0 ? Math.max(...rows.map(({ row_id }) => row_id)) + 1 : 1;
