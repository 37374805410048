import { Group } from "@material-ui/icons";
import { LayoutRouteUsage } from "../../redux/types";
import GroupsContainer from "./GroupsContainer";
import GroupNewFormContainer from "./GroupNewFormContainer";
import GroupContainer from "./GroupContainer";
import GroupEditFormContainer from "./GroupEditFormContainer";
import { NavItemClassEnum } from "../../strings/TabRouterStrings";

const GroupsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["auth/groups"],
    requireSuperuser: true,
    routes: [
      {
        path: "/auth/groups",
        label: t("groupsNavbarLabel"),
        component: GroupsContainer,
        icon: Group,
        highlightClass: NavItemClassEnum.PERSONS,
      },
      {
        path: "/auth/groups/new",
        parent: "/auth/groups",
        label: t("newGroupLabel"),
        component: GroupNewFormContainer,
        highlightClass: NavItemClassEnum.PERSONS,
      },
      {
        path: "/auth/groups/:groupId",
        parent: "/auth/groups",
        label: t("groupLabel"),
        component: GroupContainer,
        highlightClass: NavItemClassEnum.PERSONS,
      },
      {
        path: "/auth/groups/:groupId/edit",
        parent: "/auth/groups/:groupId",
        label: t("editGroupLabel"),
        component: GroupEditFormContainer,
        highlightClass: NavItemClassEnum.PERSONS,
      },
    ],
  },
];

export default GroupsRouter;
