import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Cooperation, Contract } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../../redux/strings";
import _ from "lodash";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface CooperationsContainerProps {
  cooperations: Cooperation[];
  count: number;
  loadingCooperations: boolean;
  resetCooperations: () => void;
  loadCooperations: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string
  ) => void;
  contracts: Contract[];
  loadContracts: (contractIds: number[]) => void;
}

const CooperationsContainer: React.FC<CooperationsContainerProps> = (props) => {
  const {
    loadCooperations,
    cooperations,
    loadingCooperations,
    resetCooperations,
    contracts,
    loadContracts
  } = props;
  const page = 1;
  const pageSize = 20;

  useEffect(() => loadCooperations(page, pageSize), [loadCooperations]);
  useEffect(
    () =>
      loadContracts(
        _.uniq(cooperations.map((cooperation) => cooperation.contract))
      ),
    [loadContracts, cooperations]
  );
  useEffect(() => () => resetCooperations(), [resetCooperations]);

  const columnDefinitions = [
    { name: "title", type: "link" },
    {
      name: "contract",
      type: "foreignLink",
      foreignData: {
        data: contracts,
        visibleColumns: ["name"],
        url: "contracts/contracts"
      }
    }
  ];

  return (
    <ResourcesTableContainer
      resources={cooperations}
      resourceName={"Cooperation"}
      count={props.count}
      loadingResources={loadingCooperations}
      routeUrl={"contracts/cooperations"}
      columnDefinitions={columnDefinitions}
      resetResources={resetCooperations}
      loadResources={loadCooperations}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
      showNewButton={false}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadCooperations: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COOPERATIONS,
        `contracts/cooperations/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}`
      )
    );
  },
  loadContracts: (contractIds: number[]) => {
    if (contractIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.CONTRACTS,
          `contracts/contracts/?include=["id","name"]&filter__id__in=[${contractIds}]`
        )
      );
    }
  },
  resetCooperations: () =>
    dispatch(LoadingBooleansActions.ResetCooperations(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  cooperations:
    state.tabStates[ownProps.tabId].cooperations.cooperations.cooperations,
  contracts: state.tabStates[ownProps.tabId].contracts.contracts.contracts,
  count: state.tabStates[ownProps.tabId].cooperations.cooperations.count,
  loadingCooperations:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCooperations
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CooperationsContainer);
