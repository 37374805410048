import { ProjectsState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: ProjectsState = {
  projects: {
    projects: [],
    next: null,
    previous: null,
    count: 0
  },
  project: {
    project: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      invoice_reference: "",
      notes: "",
      businessarea: -1
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PROJECTS):
      return {
        ...state,
        projects: {
          projects: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PROJECT):
      return {
        ...state,
        project: {
          project: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.PROJECT):
      return {
        ...state,
        projects: {
          ...state.projects,
          projects: [...state.projects.projects, action.payload]
        }
      };
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.PROJECT):
      return {
        ...state,
        projects: {
          ...state.projects,
          projects: [
            ...state.projects.projects.map((project) =>
              project.id === action.payload.id ? action.payload : project
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
