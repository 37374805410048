import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import { red, green, blue } from "@material-ui/core/colors";
import {
  IDataCarry,
  IApprovalCorrectionsKey,
  IDataCarryKey
} from "../../redux/types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  correctionField: {
    backgroundColor: red[500]
  },
  contractorField: {
    backgroundColor: green[500]
  },
  customerField: {
    backgroundColor: blue[500]
  },
  editMarkerContainer: {
    display: "inline-block",
    marginLeft: theme.spacing(1)
  },
  editMarker: {
    height: 8,
    width: 8,
    marginRight: 1,
    borderRadius: 50
  }
}));

interface EditContainerProps {
  className?: string;
  dataCarry?: IDataCarry;
  disabledEditMode?: IDataCarryKey;
  fieldName?: IApprovalCorrectionsKey;
  checker?: (
    editMode: IDataCarryKey,
    fieldName?: IApprovalCorrectionsKey
  ) => boolean;
}

export const EditModeContainer: FC<EditContainerProps> = (props) => {
  const {
    fieldName,
    dataCarry,
    disabledEditMode,
    checker = (editMode, fieldName) => {
      const value = dataCarry?.[editMode][fieldName as IApprovalCorrectionsKey];

      return !!(
        (value && typeof value !== "object") ||
        (value && typeof value === "object" && Object.keys(value).length > 0)
      );
    },
    className
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.editMarkerContainer, className)}>
      {disabledEditMode !== "corrections" &&
        checker("corrections", fieldName) && (
          <div
            className={`${classes.correctionField} ${classes.editMarker}`}
          ></div>
        )}
      {disabledEditMode !== "contractor_payables" &&
        checker("contractor_payables", fieldName) && (
          <div
            className={`${classes.contractorField} ${classes.editMarker}`}
          ></div>
        )}
      {disabledEditMode !== "customer_billables" &&
        checker("customer_billables", fieldName) && (
          <div
            className={`${classes.customerField} ${classes.editMarker}`}
          ></div>
        )}
    </div>
  );
};
