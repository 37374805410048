import { DomainResponse, IDataStorageKey, LanguageState } from "../redux/types";
import { AppUrlStrings } from "../strings/AppUrlStrings";

const keyFn = (userId: number, dataKey: IDataStorageKey) =>
  `[${userId}]${dataKey}`;

export const getFromSessionStore = (dataKey: IDataStorageKey) =>
  localStorage.getItem(keyFn(window.sessionUserId, dataKey));

export const setSessionStore = (dataKey: IDataStorageKey, val: string) =>
  localStorage.setItem(keyFn(window.sessionUserId, dataKey), val);

export const removeFromSessionStore = (dataKey: IDataStorageKey) =>
  localStorage.removeItem(keyFn(window.sessionUserId, dataKey));

export function setUserInStorage(
  userId: number,
  username: string,
  person_id: number,
  refresh_token: string,
  access_token: string,
  domainName: string,
  isSuperuser: boolean,
  selectedLanguage: LanguageState | undefined
) {
  addInAllUserIds(userId);

  const getKey = keyFn.bind(null, userId);
  localStorage.setItem(getKey("refresh_token"), refresh_token);
  localStorage.setItem(getKey("access_token"), access_token);
  localStorage.setItem(getKey("domain_name"), domainName);
  localStorage.setItem(getKey("username"), username);
  localStorage.setItem(getKey("person_id"), person_id.toString());
  localStorage.setItem(getKey("is_superuser"), isSuperuser.toString());

  if (selectedLanguage) {
    localStorage.setItem("master_language", selectedLanguage);
  }
}

function clearUser(userId: number) {
  const getKey = keyFn.bind(null, userId);
  localStorage.removeItem(getKey("refresh_token"));
  localStorage.removeItem(getKey("access_token"));
  localStorage.removeItem(getKey("domain_name"));
  localStorage.removeItem(getKey("username"));
  localStorage.removeItem(getKey("person_id"));
  localStorage.removeItem(getKey("allowed_paths"));
  localStorage.removeItem(getKey("is_superuser"));
  window.allowedPaths = [];
}

export function clearUserFromStorage() {
  const isMasterUser = window.sessionUserId === 0;
  const userIds = getAllUserIds();
  if (isMasterUser) {
    userIds.forEach(clearUser);
    localStorage.removeItem("master_user_id");
    localStorage.removeItem("master_language");
    localStorage.removeItem("ws");
    localStorage.removeItem("tenant_id");
    localStorage.removeItem("baseURL");
    localStorage.removeItem("domain_id");
    localStorage.removeItem("all_user_ids");
    window.masterUserId = 0;
  } else {
    clearUser(window.sessionUserId);
    userIds.splice(userIds.indexOf(window.sessionUserId), 1);
    localStorage.setItem("all_user_ids", JSON.stringify(userIds));
  }
  window.sessionUserId = undefined as any;
}

export function setDomainInfo(domain: DomainResponse) {
  localStorage.setItem("tenant_id", domain.tenant);
  localStorage.setItem("baseURL", domain.base);
  localStorage.setItem("ws", domain.ws);
  localStorage.setItem("master_language", domain.language);
}

function getAllUserIds() {
  const userIdsStr = localStorage.getItem("all_user_ids");
  return userIdsStr ? (JSON.parse(userIdsStr) as number[]) : [];
}
function addInAllUserIds(userId: number) {
  const userIds = getAllUserIds();
  if (!userIds.includes(userId)) {
    userIds.push(userId);
  }
  localStorage.setItem("all_user_ids", JSON.stringify(userIds));
}

export function setMasterUserAccess(userId: number, domainId: string) {
  window.masterUserId = userId;
  window.sessionUserId = 0;
  localStorage.setItem("master_user_id", window.masterUserId.toString());
  localStorage.setItem("domain_id", domainId);
}

export function restoreSessionUser(urlSearchParams: URLSearchParams) {
  let userId = Number(urlSearchParams.get(AppUrlStrings.ADHOC_USER));
  if (!userId) {
    userId = Number(localStorage.getItem("master_user_id"));
    if (userId) {
      window.masterUserId = userId;
      window.sessionUserId = 0;
    }
  } else {
    window.masterUserId = 0;
    window.sessionUserId = userId;
  }

  const allowedPathsStr = getFromSessionStore("allowed_paths");
  window.allowedPaths = allowedPathsStr ? JSON.parse(allowedPathsStr) : [];
}
