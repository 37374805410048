import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer
} from "@material-ui/core";
import { Contract } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";

const styles = (theme: any) =>
  createStyles({
    tableContainer: {
      marginTop: 20
    },
    bold: {
      fontWeight: "bold"
    }
  });

interface TaskCooperationsProps extends WithStyles<typeof styles> {
  contractor_cooperations: number[];
  contractorContracts: Contract[];
}

const TaskCooperations: React.FC<TaskCooperationsProps> = (props) => {
  const { classes, contractor_cooperations, contractorContracts } = props;
  const t = useTranslate("TaskContractorCooperationsPage");
  const [pageIndex, setPageIndex] = useState(0);

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.bold} colSpan={2}>
              {t("cooperationTypographyHeading")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!contractor_cooperations?.length && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                {t("noCooperations")}
              </TableCell>
            </TableRow>
          )}
          {contractor_cooperations
            .slice(pageIndex * 10, pageIndex * 10 + 10)
            .map((contractor_cooperation, index) => {
              const taskCooperation = contractorContracts.find(
                (contractorContract) =>
                  contractorContract.id === contractor_cooperation
              ) as Contract;
              return (
                <TableRow>
                  <TableCell>
                    {taskCooperation && taskCooperation.name}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[10]}
          count={contractor_cooperations?.length ?? 0}
          rowsPerPage={10}
          page={pageIndex}
          onPageChange={(_, page) => setPageIndex(page)}
        />
      </Table>
    </TableContainer>
  );
};

export default withStyles(styles)(TaskCooperations);
