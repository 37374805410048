import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  MyTaskResponse,
  Task,
  LocationMark,
  IMapDrawings
} from "../../redux/types";
import { createStyles } from "@material-ui/styles";

import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  TableRow,
  TableCell,
  Link,
  Typography,
  Table,
  TableHead,
  TableBody,
  Box,
  useMediaQuery
} from "@material-ui/core";
import { useParams } from "react-router";
import GoogleMaps, {
  convertFeatureToLocation,
  MarkersRef
} from "../googleMaps/GoogleMaps";
import { getMyTasks } from "../../services/api-declaration";
import { ensureMapScript } from "../contracts/tasks/maps/MapHelpers";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import { useTranslate } from "../../services/appLanguageService";
import { history } from "../../redux/store";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    table: {
      minWidth: 500
    },
    pl0: {
      paddingLeft: "0 !important"
    },
    screen: {
      height: "calc(100VH - 122px)"
    },
    paper: {
      width: "100%",
      height: "100%",
      overflowY: "auto",
      padding: "0 20px 20px 20px"
    },
    mapPaper: {
      height: "100%",
      display: "flex",
      flexFlow: "column",
      padding: theme.spacing(2.5)
    },
    editButton: {
      marginLeft: theme.spacing(2.5)
    },
    mapContainer: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      minWidth: 250,
      height: "100%"
    },
    mapContainerSmall: {
      height: "80%"
    },
    tableContainer: {
      marginTop: theme.spacing(1),
      height: "100%"
    },
    descriptionBox: {
      height: theme.spacing(9),
      maxHeight: "100px",
      overflow: "auto",
      marginTop: theme.spacing(0.5),
      // display: "flex",
      // flex: "0 0 62px",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      // alignItems: "center",
      border: "1px solid #eee",
      borderTop: 0
    },
    mapFlexBasis: {
      flex: "auto"
    },
    stickyTitle: {
      top: 0,
      position: "sticky",
      background: "white",
      padding: `${theme.spacing(2)}px 0 ${theme.spacing(0.5)}px 0`
    },
    tableHeader: {
      top: theme.spacing(6.5),
      position: "sticky",
      backgroundColor: "white",
      paddingTop: theme.spacing(1)
    },
    tableRow: {
      "&:hover": {
        background: "rgba(0, 0, 0, 0.08)"
      }
    },
    tableRowHover: {
      background: "rgba(0, 0, 0, 0.08)"
    },
    activeRow: {
      backgroundColor: "rgba(0, 0, 0, 0.06)"
    },
    unActiveRow: {
      backgroundColor: "transparent"
    },
    tableRowLink: {
      cursor: "pointer"
    }
  });

interface MyTaskProps extends WithStyles<typeof styles> {}

const MyTask: React.FC<MyTaskProps> = (props) => {
  const { classes } = props;
  const params: { taskId?: string } = useParams();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [mapDrawings, setMapDrawings] = useState<IMapDrawings>();
  const [hoverMarkerId, setHoverMarkerId] = useState<number | undefined>();
  const defaultMapOptions: google.maps.MapOptions = {
    zoom: 15
    // center: { lat: 63.528814, lng: 16.039048 },
  };
  const markersRef = useRef<MarkersRef[] | null>(null);
  const t = useTranslate("MyTasksPage");
  const isSmallScreen = useMediaQuery("(max-width:1085px)");

  const initComponent = async () => {
    let response: MyTaskResponse | undefined;
    try {
      response = await getMyTasks();
    } catch (err: any) {
      showGlobalSnackbar(err.message, "error");
    }
    ensureMapScript()
      .then(() => {
        if (response) {
          const markers: LocationMark[] = [];
          response.results.forEach((task: Task, idx: number, arr: Task[]) => {
            if (task.geo_polygons) {
              markers.push({
                data: task,
                position: convertFeatureToLocation(task.geo_polygons.features),
                markerOptions: {
                  title: task.name,
                  label: {
                    text: `${idx + 1}`,
                    color: "white",
                    fontWeight: "bold"
                  }
                }
              });
            }
          });

          setMapDrawings({ markers });
          setTasks(response.results.slice(0));
        }
      })
      .catch((error) => console.log(error));
  };

  const setCoordinates = (task: Task) => {
    if (task.geo_polygons) {
      setMapDrawings({ geoPolygons: task.geo_polygons });
      setSelectedTask(task);
    }
    navigate(task.id);
  };

  const navigate = (taskId: number) => {
    history.push(`/tasks/${taskId}`);
  };

  const onMarkerClick = (mark: LocationMark) => {
    if (mark.data) {
      setCoordinates(mark.data);
    }
  };

  const onMarkerIn = (
    { data }: LocationMark<Task>,
    marker: google.maps.Marker
  ) => {
    const label = marker.getLabel() || { text: "" };
    marker.setLabel({
      ...label,
      color: "#424242"
    });
    if (data) {
      setHoverMarkerId(data.id);
    }
  };

  const onMarkerOut = (_: LocationMark<Task>, marker: google.maps.Marker) => {
    const label = marker.getLabel() || { text: "" };
    marker.setLabel({
      ...label,
      color: "white"
    });
    setHoverMarkerId(undefined);
  };

  const onHoverRowIn = (id: number) => () => {
    if (!markersRef.current) return;
    const markers = markersRef.current as MarkersRef<Task>[];
    const marker = markers.find((marker) => marker.data.id === id);

    if (marker) {
      window.google.maps.event.trigger(marker.marker, "mouseover");
    }
  };

  const onHoverRowOut = (id: number) => () => {
    if (!markersRef.current) return;
    const markers = markersRef.current as MarkersRef<Task>[];
    const marker = markers.find((marker) => marker.data.id === id);

    if (marker) {
      window.google.maps.event.trigger(marker.marker, "mouseout");
    }
  };

  const setMarkerRef = (refs: MarkersRef[]) => {
    markersRef.current = refs;
  };

  const initCb = useCallback(initComponent, []);
  useEffect(() => {
    initCb();
  }, [initCb]);
  const coordinatesCb = useCallback(setCoordinates, []);
  useEffect(() => {
    if (params.taskId) {
      const selected = tasks.find((task) => task.id === Number(params.taskId));
      if (selected) {
        coordinatesCb(selected);
      }
    }
  }, [params.taskId, coordinatesCb, tasks]);

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        className={classes.screen}
      >
        <Box
          flex="1"
          className={`${classes.mapContainer} ${
            isSmallScreen && classes.mapContainerSmall
          }`}
        >
          <Paper className={classes.mapPaper}>
            <GoogleMaps
              id="tasksMap"
              className={classes.mapFlexBasis}
              geoPolygons={mapDrawings && mapDrawings.geoPolygons}
              markers={mapDrawings && mapDrawings.markers}
              options={defaultMapOptions}
              onMarkerClick={onMarkerClick}
              onMarkerMouseover={onMarkerIn}
              onMarkerMouseout={onMarkerOut}
              markerRefs={setMarkerRef}
            />
            {selectedTask && (
              <div className={classes.descriptionBox}>
                {selectedTask.work_description
                  ? selectedTask.work_description
                  : t("taskDescription")}
              </div>
            )}
          </Paper>
        </Box>
        <Box className={classes.tableContainer}>
          <Paper className={classes.paper}>
            <Typography
              component="h1"
              variant="h6"
              className={classes.stickyTitle}
            >
              {t("myTasksHeading")}
            </Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`${classes.tableHeader} ${classes.pl0}`}
                    align="center"
                  >
                    {t("tasksNumberHeading")}
                  </TableCell>
                  <TableCell
                    className={`${classes.tableHeader} ${classes.pl0}`}
                    align="left"
                  >
                    {t("listItemNamePrimaryText")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((item: Task, idx: number) => (
                  <TableRow
                    key={item.id}
                    onMouseEnter={onHoverRowIn(item.id)}
                    onMouseLeave={onHoverRowOut(item.id)}
                    className={`${
                      item.id === hoverMarkerId ? classes.tableRowHover : ""
                    } ${
                      selectedTask && selectedTask.id === item.id
                        ? classes.activeRow
                        : ""
                    }`}
                  >
                    <TableCell align="center" className={classes.pl0}>
                      <Typography variant="h5">{idx + 1}</Typography>
                    </TableCell>
                    <TableCell
                      className={`${classes.unActiveRow} ${classes.pl0}`}
                    >
                      {item.geo_polygons &&
                      !(selectedTask && selectedTask.id === item.id) ? (
                        <Link
                          onClick={() => setCoordinates(item)}
                          className={classes.tableRowLink}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <div>{item.name}</div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default withStyles(styles)(MyTask);
