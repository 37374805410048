import { useEffect } from "react";
import { SnackbarState } from "../../redux/types";
import {
  useSnackbar,
  SnackbarKey,
  OptionsObject,
  TransitionCloseHandler
} from "notistack";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export function SnackbarCloseButton({
  snackbarKey
}: {
  snackbarKey: SnackbarKey;
}) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} size="small">
      <CloseIcon style={{ color: "#fff" }} />
    </IconButton>
  );
}

interface SnackbarOptionsResult {
  onClose?: TransitionCloseHandler;
  anchorOrigin?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "center" | "right";
    autoHideDuration: number;
    style: { whiteSpace: string };
  };
}

export const useGlobalSnackbar = (
  snackbar: SnackbarState,
  clearSnackbar: () => void
): [(message: string, options?: OptionsObject) => SnackbarKey] => {
  const { enqueueSnackbar } = useSnackbar();
  const getSnackbarOptions = (): SnackbarOptionsResult => ({
    onClose: clearSnackbar,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
      autoHideDuration: 2500,
      style: { whiteSpace: "pre-line" }
    }
  });
  const handleSnackbarChange = () => {
    if (snackbar.isSnackbar) {
      enqueueSnackbar(
        snackbar.message,
        Object.assign(getSnackbarOptions(), {
          variant: snackbar.variant,
          persist: snackbar.variant === "error",
          action:
            snackbar.variant === "error"
              ? (snackbarKey: SnackbarKey) => (
                  <SnackbarCloseButton snackbarKey={snackbarKey} />
                )
              : undefined
        })
      );
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSnackbarChange, [snackbar]);
  return [enqueueSnackbar];
};
