import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TablePagination
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ContractCooperation } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import {
  getCompanyNamesAPI,
  getContractBusinessAreasAPI,
  getContractCooperationsAPI
} from "../../../services/api-declaration";

const useCooperationStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "20px",
      marginRight: "20px"
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2)
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h6": {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
      }
    },
    bold: {
      fontWeight: "bold"
    },
    addButton: {
      marginRight: theme.spacing(2)
    }
  })
);

type ContractCooperationTableProps = {
  contractId: number;
};

export const ContractCooperationTable: React.FC<
  ContractCooperationTableProps
> = ({ contractId }) => {
  const classes = useCooperationStyles();
  const t = useTranslate("TaskTable");
  const [cooperations, setCooperations] = useState<ContractCooperation[]>();
  const [businessAreaNames, setBusinessAreaNames] =
    useState<Map<number, { name: string }>>();
  const [companyNames, setCompanyNames] =
    useState<Map<number, { name: string }>>();
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    let alive = true;
    void Promise.all([
      getContractCooperationsAPI({ contract_id: contractId }),
      getContractBusinessAreasAPI({})
    ])
      .then(async ([{ results: cooperations }, { results: businessareas }]) => {
        const { results: companies } = await getCompanyNamesAPI({
          id__in: cooperations.map((c) => c.company)
        });
        return [cooperations, businessareas, companies] as const;
      })
      .then(([cooperations, businessareas, companies]) => {
        if (alive) {
          cooperations.sort((a, b) => a.name.localeCompare(b.name));
          setCooperations(cooperations);
          setBusinessAreaNames(new Map(businessareas.map((a) => [a.id, a])));
          setCompanyNames(new Map(companies.map((c) => [c.id, c])));
        }
      });
    return () => {
      alive = false;
    };
  }, [contractId]);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">{t("cooperationHeadingText")}</Typography>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.bold}>{t("nameLabel")}</TableCell>
            <TableCell className={classes.bold}>{t("companyLabel")}</TableCell>
            <TableCell className={classes.bold}>
              {t("businessareaLabel")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cooperations
            ?.slice(pageIndex * 10, pageIndex * 10 + 10)
            .map((cooperation) => (
              <TableRow key={cooperation.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/contracts/tasks/${cooperation.task}`}
                  >
                    {cooperation.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {companyNames?.has(cooperation.company) && (
                    <Link
                      component={RouterLink}
                      to={`/companies/companies/${cooperation.company}`}
                    >
                      {companyNames.get(cooperation.company)?.name}
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  {businessAreaNames?.get(cooperation.businessarea)?.name}
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={cooperations?.length ?? 0}
              rowsPerPage={10}
              page={pageIndex}
              onPageChange={(_, page) => setPageIndex(page)}
            />
          </TableRow>
        </TableBody>
      </Table>
      {!cooperations && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};
