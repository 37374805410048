import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularProgress, IconButton, WithStyles } from "@material-ui/core";
import { Done as DoneIcon, Clear as ClearIcon } from "@material-ui/icons";
import { fetchPatch } from "../services/fetchWrapper";
import { restUrl } from "../services/api-declaration";
import { useTranslate } from "../services/appLanguageService";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const styles = (theme: any) =>
  createStyles({
    readOnly: {
      paddingBottom: 2,
      borderBottom: "dashed 1px #0088cc",
      cursor: "pointer"
    },
    addLabel: {
      color: "grey",
      fontStyle: "italic",
      cursor: "pointer"
    },
    icon: {
      fontSize: "20px"
    }
  });

interface EditableTimePickerProps extends WithStyles<typeof styles> {
  field: string;
  initValue: string | null;
  url: string;
  updateResourceState: (newValue: any) => void;
}

const EditableTimePicker: React.FC<EditableTimePickerProps> = (props) => {
  const { classes, field } = props;

  const [value, setValue] = useState<string | null>(props.initValue);
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const t = useTranslate("EditableFieldComponent");

  const updateResource = async () => {
    setLoading(true);
    const response = await fetchPatch(restUrl(props.url), {
      [props.field]: value
    });
    setLoading(false);
    props.updateResourceState(response);
  };

  const timeToDate = (time: string) => {
    if (
      typeof time === "string" &&
      time !== "" &&
      time.search(/\d\d:\d\d/) !== -1
    ) {
      const hour = parseInt(time.slice(0, 2));
      const minute = parseInt(time.slice(3, 5));
      let date = new Date();
      return date.setHours(hour, minute);
    }
    return new Date();
  };

  return loading ? (
    <CircularProgress size={18} />
  ) : !edit ? (
    <span
      data-cy={`${field}-edit`}
      className={value !== null ? classes.readOnly : classes.addLabel}
      onClick={() => setEdit(true)}
    >
      {value !== null
        ? format(timeToDate(value), "HH:mm")
        : `${t("addLabel")}...`}
    </span>
  ) : (
    <>
      <KeyboardTimePicker
        ampm={false}
        data-cy={`${field}-field`}
        value={value && typeof value === "string" ? timeToDate(value) : value}
        onChange={(value: MaterialUiPickersDate) => {
          setValue(value ? format(value, "HH:mm") : null);
        }}
        onKeyPress={(event) => {
          if (event.code === "Enter") {
            updateResource();
            setEdit(false);
          }
        }}
        InputProps={{
          style: {
            fontSize: 13
          }
        }}
      />
      <IconButton
        data-cy={`${field}-save`}
        size="small"
        aria-label="Save"
        onClick={() => {
          updateResource();
          setEdit(false);
        }}
      >
        <DoneIcon className={classes.icon} style={{ color: "#006400" }} />
      </IconButton>
      <IconButton
        size="small"
        aria-label="Reset"
        onClick={() => {
          setEdit(false);
          setValue(props.initValue);
        }}
      >
        <ClearIcon className={classes.icon} style={{ color: "Crimson" }} />
      </IconButton>
    </>
  );
};

export default withStyles(styles)(EditableTimePicker);
