import { LayoutRouteUsage } from "../../../redux/types";
import BusinessAreasContainer from "./BusinessAreasContainer";
import BusinessAreaNewFormContainer from "./BusinessAreaNewFormContainer";
import BusinessAreaContainer from "./BusinessAreaContainer";
import BusinessAreaEditFormContainer from "./BusinessAreaEditFormContainer";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { Beenhere } from "@material-ui/icons";

const BusinessAreaRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/businessareas"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/businessareas",
        label: t("contractNavbarBusinessAreasLabel"),
        component: BusinessAreasContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
        icon: Beenhere,
      },
      {
        path: "/contracts/businessareas/new",
        parent: "/contracts/businessareas",
        label: t("newBusinessAreaLabel"),
        component: BusinessAreaNewFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
      {
        path: "/contracts/businessareas/:businessareaId",
        parent: "/contracts/businessareas",
        label: t("businessAreaLabel"),
        component: BusinessAreaContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
      {
        path: "/contracts/businessareas/:businessareaId/edit",
        parent: "/contracts/businessareas/:businessareaId",
        label: t("editBusinessAreaLabel"),
        component: BusinessAreaEditFormContainer,
        highlightClass: NavItemClassEnum.CONTRACTS,
      },
    ],
  },
];

export default BusinessAreaRouter;
