import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../redux/types";
import Afterregistration from "./afterregistrations/Afterregistration";
import Afterregistrations from "./afterregistrations/Afterregistrations";
// import AddNewTimeSheet from "./AddNewTimeSheetForm";
// import EditTimeSheet from "./EditTimeSheetForm";
import TimeSheetRowSave from "./TimeSheetRowSave";
// import TimeSheetSettings from "./TimeSheetSettings";
// import TimeSheetView from "./TimeSheetView";
import TimeLapse from "@material-ui/icons/Timelapse";

const AfterRegistrateRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["timesheets/timesheets"],
    routes: [
      //   {
      //     path: "/timesheets",
      //     label: t("TimeSheetNavbarLabel"),
      //     component: TimeSheetView
      //   },
      //   {
      //     path: "/timesheets/new-timesheet",
      //     parent: "/timesheets",
      //     label: t("newNavbarLabel"),
      //     component: AddNewTimeSheet
      //   },
      //   {
      //     path: "/timesheets/:id",
      //     parent: "/timesheets",
      //     label: "",
      //     component: TimeSheetSettings
      //   },
      //   {
      //     path: "/timesheets/:id/edit",
      //     parent: "/timesheets/:id",
      //     label: t("editNavbarLabel"),
      //     component: EditTimeSheet
      //   },
      {
        path: "/after-registrations",
        label: t("TimeSheetNavbarLabel"),
        component: Afterregistrations,
        icon: TimeLapse,
        highlightClass: NavItemClassEnum.AFTERREGISTRATEWORK,
        navId: NavItemClassEnum.AFTERREGISTRATEWORK,
      },
      {
        path: "/after-registrations/:id",
        parent: "/after-registrations",
        label: t("afterregistrateWorkLabel"),
        component: Afterregistration,
        highlightClass: NavItemClassEnum.AFTERREGISTRATEWORK,
      },
      {
        path: "/timesheets/:id/save",
        parent: "/timesheets/:id",
        label: t("newRowLabel"),
        component: TimeSheetRowSave,
        highlightClass: NavItemClassEnum.AFTERREGISTRATEWORK,
      },
      {
        path: "/timesheets/:id/save/:rowId",
        parent: "/timesheets/:id",
        label: t("editRowLabel"),
        component: TimeSheetRowSave,
        highlightClass: NavItemClassEnum.AFTERREGISTRATEWORK,
      },
    ],
  },
  {
    apiUsage: ["timesheets/timesheets"],
    requireSuperuser: true,
    routes: [
      {
        path: "/after-registrations/add-new/:contractorId",
        parent: "/after-registrations",
        label: "Add New",
        component: Afterregistration,
        highlightClass: NavItemClassEnum.AFTERREGISTRATEWORK,
      },
    ],
  },
];

export default AfterRegistrateRouter;
