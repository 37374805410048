import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  WithStyles
} from "@material-ui/core";
import { useTranslate } from "../services/appLanguageService";
import { SelectFieldOption } from "../redux/types";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import LockIcon from "@material-ui/icons/Lock";
import LoadingSpinner from "./LoadingSpinner";
import { Autocomplete } from "@material-ui/lab";

const styles = (theme: any) =>
  createStyles({
    deleteIcon: {
      color: "Crimson"
    },
    disabledDeleteIcon: {
      color: "Gray"
    },
    select: {
      padding: 10
    },
    submitButton: {
      paddingTop: 20,
      paddingRight: 20,
      textAlign: "right"
    },
    deleteColumn: {
      textAlign: "right"
    },
    tableTitle: {
      paddingLeft: 20,
      paddingTop: 20
    },
    container: {
      margin: "20px 0"
    }
  });

interface AssociatedFieldProps extends WithStyles<typeof styles> {
  field: string;
  resourceIds: number[];
  options: SelectFieldOption[];
  owningId: number;
  label: string;
  addResource: (owningId: number, associatedIds: number[]) => void;
  removeResource: (owningId: number, associatedIds: number[]) => void;
  isLoading: boolean;
  title: string;
  locked?: boolean;
  atleastOneElement?: boolean;
}

const AssociatedField: React.FC<AssociatedFieldProps> = (props) => {
  const {
    classes,
    resourceIds,
    options,
    label,
    removeResource,
    owningId,
    isLoading,
    locked,
    addResource,
    atleastOneElement
  } = props;

  const t = useTranslate("EditableFieldComponent");

  const [selectedOption, setSelectedOption] =
    useState<SelectFieldOption | null>(null);

  const currentOptions = options.filter(
    (option) => !resourceIds.includes(option.value)
  );

  const deleteDisabled = resourceIds.length < 2 && atleastOneElement;

  const handleAssociate = () => {
    addResource(
      owningId,
      selectedOption ? [...resourceIds, selectedOption.value] : resourceIds
    );
    setSelectedOption(null);
  };

  const handleRemoveAssociated = (valueToRemove: number) => {
    const newValues = resourceIds.filter(
      (resourceId) => resourceId !== valueToRemove
    );

    removeResource(owningId, newValues);
  };

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" component="h6" className={classes.tableTitle}>
        {props.title}
      </Typography>
      <Grid container>
        <Grid item xs={8} sm={8}>
          <div className={classes.select}>
            <Autocomplete
              data-cy={`${props.field}-field`}
              className={classes.select}
              value={selectedOption}
              onChange={(event: any, value: SelectFieldOption | null) =>
                setSelectedOption(value)
              }
              options={currentOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} label={label} />}
            />
          </div>
        </Grid>
        <Grid item xs={4} sm={4}>
          <div className={classes.submitButton}>
            <Button
              data-cy={`${props.field}-associate`}
              size="small"
              variant="contained"
              color="primary"
              disabled={selectedOption === null}
              onClick={handleAssociate}
            >
              {t("associateLabel")}
            </Button>
          </div>
        </Grid>
      </Grid>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {options
              .filter((option) => resourceIds.includes(option.value))
              .map((option, index) => (
                <TableRow key={option.value}>
                  <TableCell>{option.label}</TableCell>
                  <TableCell className={classes.deleteColumn}>
                    {locked ? (
                      <Tooltip title={t("lockedLabel")}>
                        <LockIcon />
                      </Tooltip>
                    ) : (
                      <>
                        {deleteDisabled ? (
                          <Tooltip title={t("atLeastOneLabel")}>
                            <DeleteIcon
                              className={classes.disabledDeleteIcon}
                            />
                          </Tooltip>
                        ) : (
                          <IconButton
                            data-cy={`${props.field}-remove-${index}`}
                            size="small"
                            onClick={() => handleRemoveAssociated(option.value)}
                          >
                            <DeleteIcon className={classes.deleteIcon} />
                          </IconButton>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
};

export default withStyles(styles)(AssociatedField);
