import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, ContractingProfile } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { RestStrings } from "../../../redux/strings";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface ContractingProfilesContainerProps {
  contractingprofiles: ContractingProfile[];
  count: number;
  loadingContractingProfiles: boolean;
  resetContractingProfiles: () => void;
  loadContractingProfiles: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string
  ) => void;
}

const ContractingProfilesContainer: React.FC<
  ContractingProfilesContainerProps
> = (props) => {
  const {
    loadContractingProfiles,
    contractingprofiles,
    loadingContractingProfiles,
    resetContractingProfiles
  } = props;
  const page = 1;
  const pageSize = 20;

  useEffect(
    () => loadContractingProfiles(page, pageSize),
    [loadContractingProfiles]
  );
  useEffect(() => () => resetContractingProfiles(), [resetContractingProfiles]);

  const columnDefinitions = [{ name: "name", type: "link" }];

  return (
    <ResourcesTableContainer
      resources={contractingprofiles}
      resourceName={"ContractingProfile"}
      count={props.count}
      loadingResources={loadingContractingProfiles}
      routeUrl={"contracts/contractingprofiles"}
      columnDefinitions={columnDefinitions}
      resetResources={resetContractingProfiles}
      loadResources={loadContractingProfiles}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadContractingProfiles: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__name=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "CONTRACTINGPROFILES",
        `contracts/contractingprofiles/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}`
      )
    );
  },
  resetContractingProfiles: () =>
    dispatch(LoadingBooleansActions.ResetContractingProfiles(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  contractingprofiles:
    state.tabStates[ownProps.tabId].contractingProfiles.contractingProfiles
      .contractingProfiles,
  count:
    state.tabStates[ownProps.tabId].contractingProfiles.contractingProfiles
      .count,
  loadingContractingProfiles:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingContractingProfiles
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractingProfilesContainer);
