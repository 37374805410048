import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, TabComponentProps, Term } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import TermView from "../../../components/contracts/terms/Term";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface TermContainerProps extends TabComponentProps<"termId"> {
  term: Term;
  loadingTerm: boolean;
  resetTerm: () => void;
  loadTerm: (termId: number) => void;
}

const TermContainer: React.FC<TermContainerProps> = (props) => {
  const {
    resetTerm,
    loadingTerm,
    loadTerm,
    term,
    routeParams: { termId }
  } = props;
  useEffect(() => loadTerm(termId), [loadTerm, termId]);

  useEffect(() => () => resetTerm(), [resetTerm]);
  return (
    <div>
      <ViewActionButtons
        resourceId={termId}
        resource={ReduxActionStrings.TERM}
        url="contracts/terms"
      />
      {loadingTerm ? (
        <LoadingSpinnerPaper />
      ) : (
        <TermView term={term} {...mapTabRouteProps(props)} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadTerm: (termId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "TERM",
        `contracts/terms/${termId}/`
      )
    );
  },
  resetTerm: () => dispatch(LoadingBooleansActions.ResetTerm(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  term: state.tabStates[ownProps.tabId].terms.term.term,
  loadingTerm: state.tabStates[ownProps.tabId].loadingBooleans.loadingTerm
});

export default connect(mapStateToProps, mapDispatchToProps)(TermContainer);
