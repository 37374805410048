import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import * as types from "../../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import BusinessAreaLinkContainer from "../../../containers/links/BusinessAreaLinkContainer";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../../redux/types";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface ProjectProps extends WithStyles<typeof styles>, TabComponentProps {
  project: types.Project;
}

const Project: React.FC<ProjectProps> = (props) => {
  const { classes, project, tabId } = props;
  const t = useTranslate("ProjectPage");

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    store.dispatch(setTitle(project.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">{project.name}</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" className={classes.bold}>
                    {t("businessAreaLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {project.businessarea ? (
                      <BusinessAreaLinkContainer
                        businessAreaId={project.businessarea}
                        {...mapTabRouteProps(props)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("invoiceReferenceLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {project.invoice_reference}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("notesLabel")}
                  </TableCell>
                  <TableCell align="right">{project.notes}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(project.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(project.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Project);
