import React, { useEffect, useState } from "react";
import {
  WithStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Collapse,
  IconButton,
  Tooltip,
  Paper,
  Typography,
  TablePagination,
  TextField,
  Grid,
  InputLabel,
  Button
} from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import { niceDate } from "../FormatHelpers";
import {
  EventRow,
  EventsFilterQuery,
  Person,
  SelectFieldOption
} from "../../redux/types";
import { getEventsAPI, getPersonsAPI } from "../../services/api-declaration";
import ResourceLink from "../links/ResourceLink";
import EventDetails from "./EventDetails";
import ImportantIcon from "@material-ui/icons/PriorityHigh";
import FilterListIcon from "@material-ui/icons/FilterList";
import LoadingSpinnerPaper from "../LoadingSpinnerPaper";
import { Autocomplete } from "@material-ui/lab";
import { APP_DATE_FORMAT } from "../../helpers/timesheetHelperService";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20,
      marginTop: 10
    },
    table: {
      [theme.breakpoints.down(600)]: {
        width: "100%"
      }
    },
    boldRow: {
      "& > *": {
        color: "#000",
        fontWeight: "bold"
      }
    },
    expandedRow: {
      "& > *": {
        color: "#000",
        fontWeight: "bold",
        background: "#e3e3e3"
      }
    },
    cellPadRow: {
      "& > *": { padding: "6px" }
    },
    root: {
      "& > *": {
        position: "relative",
        borderBottom: "unset"
      }
    },
    expandButton: { width: theme.spacing(2) },
    pointer: { cursor: "pointer" },
    importantIcon: {
      color: "red"
    },
    noResults: { textAlign: "center" },
    filterContainer: {
      padding: 30
    },
    picker: {
      marginRight: theme.spacing(1)
    },
    pickerLabel: {
      fontSize: 12,
      color: "#43a047",
      marginTop: 10
    },
    select: {
      marginTop: 10
    },
    titleRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiAccordionSummary-content": {
        justifyContent: "space-between"
      },
      color: "#43a047"
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    flex: {
      display: "flex"
    }
  });

interface EventsProps extends WithStyles<typeof styles> {
  limit?: number;
  hideFilter?: boolean;
}

const Events: React.FC<EventsProps> = (props) => {
  const t = useTranslate("EventlogPage");
  const t2 = useTranslate("Events");
  const { classes, limit, hideFilter } = props;

  const [userOptions, setUserOptions] = useState<SelectFieldOption[]>([]);
  const [expandedEvent, setExpandedEvent] = useState<EventRow | undefined>(
    undefined
  );
  const [events, setEvents] = useState<{ results: EventRow[]; count: number }>({
    count: 0,
    results: []
  });
  const [persons, setPersons] = useState<Person[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const eventTypeOptions = [
    "EXCEPTION",
    "ORDER_CREATED",
    "TIMESHEET_CREATED",
    "TIMESHEET_ACCEPTED",
    "TIMESHEET_SUBMITTED",
    "TIMESHEET_VERIFIED",
    "UNKNOWN",
    "CONTRACT_ARCHIVED",
    "ERROR_REPORT"
  ].map((eventType) => ({
    label: t2(eventType),
    value: eventType
  }));

  useEffect(() => {
    (async () => {
      let alive = true;

      const [personsResponse] = await Promise.all([getPersonsAPI()]);

      if (alive) {
        const _userOptions = personsResponse.results
          .map((person) => ({
            label: `${person.first_name} ${person.last_name}`,
            value: person.user
          }))
          .filter(
            (option): option is SelectFieldOption => option.value !== null
          );

        setUserOptions(_userOptions);
      }

      return () => {
        alive = false;
      };
    })();
  }, []);

  const now = new Date();

  const DEFAULT_PAGE_SIZE = 200;

  const DEFAULT_QUERY: EventsFilterQuery = {
    page: 1,
    page_size: limit ?? DEFAULT_PAGE_SIZE,
    filter__timestamp__lt: now.toISOString(),
    filter__timestamp__gt: new Date(
      now.setDate(now.getDate() - 7)
    ).toISOString()
  };

  const [query, setQuery] = useState<EventsFilterQuery>({
    ...DEFAULT_QUERY
  });

  const handleRowExpansion = (shouldOpen: boolean, event: EventRow) => {
    if (shouldOpen) {
      setExpandedEvent(event);
    } else {
      setExpandedEvent(undefined);
    }
  };

  const handlePageChange = (_: any, page: number) => {
    if (page + 1 === query.page) return;
    setQuery((query) => ({
      ...query,
      page: page + 1
    }));
  };

  useEffect(() => {
    let alive = true;
    (async () => {
      setIsLoading(true);
      const eventsResponse = await getEventsAPI(query);

      if (alive) {
        setEvents({
          results: eventsResponse.results,
          count: eventsResponse.count
        });
      }

      const userIds = eventsResponse.results
        .map((event) => event.user)
        .filter((u): u is number => !!u);
      const uniqueUserIds = [...new Set(userIds)];
      if (uniqueUserIds.length) {
        const personsResponse = await getPersonsAPI({
          filter__user__in: uniqueUserIds
        });

        if (alive) {
          setPersons(personsResponse.results);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })();
    return () => {
      alive = false;
    };
  }, [limit, query]);

  return (
    <>
      {(typeof hideFilter === "undefined" || hideFilter === false) && (
        <Paper className={classes.filterContainer}>
          <div className={classes.titleRow}>
            <Typography className={`${classes.flex}`}>
              <FilterListIcon className={classes.leftIcon} /> Filter
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setQuery({ page: 1, page_size: limit ?? DEFAULT_PAGE_SIZE });
              }}
            >
              {t("clearButton")}
            </Button>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <InputLabel className={classes.pickerLabel}>
                {t("typeLabel")}
              </InputLabel>
              <Autocomplete
                className={classes.select}
                value={
                  eventTypeOptions.find(
                    (eventTypeOption) =>
                      eventTypeOption.value === query.filter__event_type
                  ) || { label: "", value: "" }
                }
                onChange={(
                  event: any,
                  newEventType: { label: string; value: string } | null
                ) => {
                  console.log(newEventType);
                  if (newEventType === null) {
                    setQuery((_filters) => {
                      let clearedFilters = { ..._filters };
                      delete clearedFilters.filter__event_type;
                      return clearedFilters;
                    });
                  } else {
                    setQuery((tempQuery) => ({
                      ...tempQuery,
                      filter__event_type: newEventType?.value
                    }));
                  }
                }}
                options={eventTypeOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t("typeLabel")} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel className={classes.pickerLabel}>
                {t("userLabel")}
              </InputLabel>
              <Autocomplete
                className={classes.select}
                value={
                  userOptions.find(
                    (userOption) => userOption.value === query.filter__user
                  ) || { label: "", value: 0 }
                }
                onChange={(event: any, newUser: SelectFieldOption | null) => {
                  if (!newUser || newUser?.value === -1) {
                    setQuery((_filters) => {
                      let clearedFilters = { ..._filters };
                      delete clearedFilters.filter__user;
                      return clearedFilters;
                    });
                  } else {
                    setQuery((tempQuery) => ({
                      ...tempQuery,
                      filter__user: newUser?.value
                    }));
                  }
                }}
                options={userOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t("userLabel")} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <InputLabel className={classes.pickerLabel}>
                {t("startLabel")}
              </InputLabel>
              <KeyboardDatePicker
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                clearable
                animateYearScrolling
                className={classes.picker}
                format={APP_DATE_FORMAT}
                value={
                  query.filter__timestamp__gt
                    ? query.filter__timestamp__gt
                    : null
                }
                onChange={(date: MaterialUiPickersDate) => {
                  if (date === null) {
                    setQuery((_filters) => {
                      let clearedFilters = { ..._filters };
                      delete clearedFilters.filter__timestamp__gt;
                      return clearedFilters;
                    });
                  } else {
                    setQuery((tempQuery) => ({
                      ...tempQuery,
                      filter__timestamp__gt: date.toISOString()
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputLabel className={classes.pickerLabel}>
                {t("endLabel")}
              </InputLabel>
              <KeyboardDatePicker
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                clearable
                animateYearScrolling
                className={classes.picker}
                format={APP_DATE_FORMAT}
                value={
                  query.filter__timestamp__lt
                    ? query.filter__timestamp__lt
                    : null
                }
                onChange={(date: MaterialUiPickersDate) => {
                  if (date === null) {
                    setQuery((_filters) => {
                      let clearedFilters = { ..._filters };
                      delete clearedFilters.filter__timestamp__lt;
                      return clearedFilters;
                    });
                  } else {
                    setQuery((tempQuery) => ({
                      ...tempQuery,
                      filter__timestamp__lt: date.toISOString()
                    }));
                  }
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      {!isLoading ? (
        <Paper className={classes.paper}>
          <Typography variant="h6">{t("importantEventsTitle")}</Typography>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={clsx(classes.boldRow, classes.cellPadRow)}>
                <TableCell></TableCell>
                <TableCell>{t("descriptionLabel")}</TableCell>
                <TableCell>{t("userLabel")}</TableCell>
                <TableCell>{t("timestampLabel")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.count > 0 ? (
                events.results.map((event: EventRow) => {
                  const isSummaryExpanded =
                    expandedEvent && event.id === expandedEvent.id;

                  const person = persons.find(
                    (person) => person.user === event.user
                  );

                  return (
                    <React.Fragment key={event.id}>
                      <TableRow
                        className={clsx(
                          classes.root,
                          classes.cellPadRow,
                          isSummaryExpanded && classes.expandedRow,
                          classes.pointer
                        )}
                        onClick={() =>
                          handleRowExpansion(!isSummaryExpanded, event)
                        }
                      >
                        <TableCell>
                          <IconButton aria-label="expand row" size="small">
                            {isSummaryExpanded ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>{t2(event.event_type)}</TableCell>
                        <TableCell>
                          {person && (
                            <ResourceLink
                              url="auth/persons"
                              id={person.id}
                              label={`${person.first_name} ${person.last_name}`}
                            />
                          )}
                        </TableCell>
                        <TableCell>{niceDate(event.timestamp)}</TableCell>
                        <TableCell>
                          {event.is_important && (
                            <Tooltip
                              title={t("importantTooltip")}
                              placement="right"
                            >
                              <ImportantIcon
                                className={classes.importantIcon}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.root}>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0
                          }}
                          colSpan={10}
                        >
                          <Collapse
                            in={isSummaryExpanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            {isSummaryExpanded && (
                              <EventDetails event={event} />
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className={classes.noResults}>
                    {t("noResultsLabel")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={events.count ?? 0}
            rowsPerPage={query.page_size ?? DEFAULT_PAGE_SIZE}
            page={typeof query.page !== "undefined" ? query.page - 1 : 0}
            onPageChange={handlePageChange}
            rowsPerPageOptions={[limit ?? DEFAULT_PAGE_SIZE]}
          />
        </Paper>
      ) : (
        <LoadingSpinnerPaper />
      )}
    </>
  );
};

export default withStyles(styles)(Events);
