import {
  InputAdornment,
  IconButton,
  createStyles,
  WithStyles,
  withStyles,
  TextField
} from "@material-ui/core";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";
import { useRef, useState } from "react";
import { useTranslate } from "../services/appLanguageService";

const styles = (theme: any) => createStyles({});

interface SearchFieldProps extends WithStyles<typeof styles> {
  handleSearch: (searchString: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { handleSearch } = props;

  const [searchText, setSearchText] = useState("");
  const t = useTranslate("SearchField");
  const searchTimerRef = useRef<number>();

  const handleSearchWithTimeout = (value: string) => {
    setSearchText(value);
    if (searchTimerRef.current) {
      window.clearTimeout(searchTimerRef.current);
    }
    searchTimerRef.current = window.setTimeout(() => {
      handleSearch(value);
    }, 400);
  };

  return (
    <TextField
      data-cy="search-field"
      value={searchText}
      onChange={(event: any) => handleSearchWithTimeout(event.target.value)}
      placeholder={t("searchLabel")}
      inputProps={{ "aria-label": t("searchLabel") }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              data-cy="clear-search"
              disabled={searchText === ""}
              onClick={() => handleSearchWithTimeout("")}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default withStyles(styles)(SearchField);
