import { useState, useEffect, useRef, FC, ChangeEvent } from "react";
import clsx from "clsx";
import {
  Paper,
  Button,
  Toolbar,
  TextField,
  Typography,
  TablePagination,
  CircularProgress,
  Dialog
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {
  bulkApprovalsAPI,
  getBusinessareasAPI,
  getCompanyOptionsByTypeAPI,
  getSearchCustomerobjectOptionsAPI
} from "../../services/api-declaration";
import { ConfirmationDialogServiceProvider } from "../confirmationDialog/ConfirmationDialogServiceProvider";
import {
  SelectFieldOption,
  TableFilterConfig,
  RouteInstance as RouteInstanceType,
  TabComponentProps,
  ILoadingData,
  ConflictGroup,
  IQuery,
  IApprovalSummaryQnum,
  IApprovalSummary,
  IApprovalSelectionStats,
  IApprovalDictionaries,
  IApprovableGroup,
  IApprovalSummaryDialogData
} from "../../redux/types";
import TableFilter from "../tableFilter/TableFilter";
import RouteInstance from "../routes/routeinstances/RouteInstanceEditViewActionButtons";
import { useTranslate } from "../../services/appLanguageService";
import { fetchVerificationSummaries } from "../../services/routeInstanceApprovalService";
import { TimesheetApprovalDialogServiceProvider } from "../timesheetApprovalDialog/DialogServiceProvider";
import TimesheetApprovalConflicts from "./TimesheetApprovalConflicts";
import {
  bulkPayload,
  calculateSelectionStats,
  clearApprovalSelection
} from "../../helpers/timesheetApprovalHelper";
import TimesheetApprovals from "./TimesheetApprovals";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import { VerificationUpdatedAt } from "../../redux/reducers/verificationCount/action";
import store from "../../redux/store";
import MaterialDictionary from "../../helpers/classes/verification/MaterialDictionary";
import TaskDictionary from "../../helpers/classes/verification/TaskDictionary";
import TimesheetApprovalsSummary from "./TimesheetApprovalsSummary";
import { formatDate, formatDateTime, getTimeFromDate } from "../FormatHelpers";
import { debounce } from "lodash";
import TimesheetOptionDict from "../../helpers/classes/verification/TimesheetOptionDict";
import { validateApiUsage } from "../../helpers/routesHelper";
import { getFromSessionStore } from "../../services/storageService";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paperVerify: {
      position: "sticky",
      bottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    loaderPaper: {
      textAlign: "center",
      padding: `${theme.spacing(3)}px 0`,
      margin: `${theme.spacing(3)}px 0 0 0`
    },
    verticalDivider: {
      width: 1,
      margin: "0 15px",
      background: "#cccccc",
      height: theme.spacing(4)
    },
    secondaryHeading: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(15)
    },
    loader: {
      width: "50px !important",
      height: "50px !important",
      padding: theme.spacing(0.5)
    },
    flexView: {
      flex: 1,
      display: "flex",
      alignItems: "center"
    },
    flex: {
      display: "flex",
      justifyContent: "center"
    },
    flexDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    searchIcon: {
      color: "#979797",
      marginBottom: "2px"
    },
    searchField: {
      display: "flex",
      alignItems: "flex-end",
      marginRight: theme.spacing(3)
    },
    tableMain: {
      marginTop: theme.spacing(2),
      width: "100%",
      overflow: "scroll"
    },
    root: {
      "& > *": {
        position: "relative",
        borderBottom: "unset"
      }
    },
    pagination: {
      display: "flex",
      justifyContent: "flex-end"
    },
    flexBox: {
      display: "flex",
      alignItems: "center"
    },
    unsubmittedLabel: {
      color: "#000",
      fontWeight: "bold"
    },
    activeRow: {
      backgroundColor: "#bdbdbd",
      transition: "background-color 300ms ease-in-out"
    },
    hasStatusIcon: {
      marginRight: theme.spacing(1)
    },
    alignCenter: { alignItems: "center" },
    paper: { minWidth: "min-content" },
    backButton: { marginBottom: theme.spacing(1.5) },
    selectedApproval: { marginRight: theme.spacing(1) },
    spacing: { paddingBottom: `${theme.spacing(2.5)}px !important` },
    nowrap: { whiteSpace: "nowrap" },
    approveBtn: {
      color: "white",
      backgroundColor: "#43a047",
      "&:hover": {
        fontWeight: 900,
        color: "#010203"
      }
    }
  })
);
interface TimesheetApprovalProps extends TabComponentProps {
  contractors: SelectFieldOption[];
  getContractors: () => void;
}
const TimesheetApprovalsPageFC: FC<TimesheetApprovalProps> = (props) => {
  const classes = useStyles();

  const showCustomerFilter =
    getFromSessionStore("is_superuser") === "true" ||
    validateApiUsage({ apiUsage: ["companies/companies"] });

  const {
    urlQueryParams: { route }
  } = props;
  const t = useTranslate("TimesheetApprovalPage");
  const v = useTranslate("TimeSheetRowsAndApprovals");
  const [selectionStats, setSelectionStats] =
    useState<IApprovalSelectionStats>();
  const routeInstanceId = Number(route) || undefined;
  const [contractorOptions, setContractorOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [customerOptions, setCustomerOptions] = useState<SelectFieldOption[]>(
    []
  );
  const [customerobjectOptions, setCustomerobjectOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [defaultExpanded, setDefaultExpanded] = useState(-1);
  const [summaryDialogData, setSummaryDialogData] =
    useState<IApprovalSummaryDialogData>();
  const [approvalSummaries, setApprovalSummaries] = useState<
    ILoadingData<IApprovalSummary>
  >({ data: [], isLoading: true, count: 0 });
  const approvalSummaryMapRef = useRef<Map<number, IApprovalSummary>>(
    new Map()
  );
  const [processingVerification, setProcessingVerification] = useState(false);
  const [conflictedRows, setConflictedRows] = useState<ConflictGroup[]>([]);
  const [businessAreaNames, setBusinessAreaNames] =
    useState<Map<number, string>>();
  const dictionariesRef = useRef<IApprovalDictionaries>([
    new MaterialDictionary(),
    new TaskDictionary(),
    new TimesheetOptionDict()
  ]);
  const NOW = new Date();
  const DEFAULT_TABLE_QUERY: IQuery<IApprovalSummaryQnum> = {
    filter__timesheet__contractor: -1,
    filter__timesheet__rows__routesegment__task__customerobject__customer: -1,
    filter__timesheet__rows__routesegment__task__customerobject: -1,
    filter__time__lt: NOW.toISOString(),
    filter__time__gt: new Date(NOW.setDate(NOW.getDate() - 14)).toISOString()
  };
  const DEFAULT_QUERY: IQuery<IApprovalSummaryQnum> = {
    page: 1,
    page_size: 10,
    ...DEFAULT_TABLE_QUERY
  };
  const [filterQuery, setFilterQuery] = useState<IQuery<IApprovalSummaryQnum>>({
    ...(routeInstanceId
      ? {
          filter__id: routeInstanceId
        }
      : DEFAULT_QUERY)
  });

  const customerFilter: TableFilterConfig<
    IQuery<IApprovalSummaryQnum>,
    RouteInstanceType
  > = {
    title: t("customerFieldLabel"),
    key: "filter__timesheet__rows__routesegment__task__customerobject__customer",
    type: "SELECTOR",
    options: customerOptions,
    optionLabelKey: "title",
    optionValueKey: "id"
  };

  const FILTERS: TableFilterConfig<
    IQuery<IApprovalSummaryQnum>,
    RouteInstanceType
  >[][] = [
    [
      ...(showCustomerFilter ? [customerFilter] : []),
      {
        title: t("contractorFilterLabel"),
        key: "filter__timesheet__contractor",
        type: "SELECTOR",
        options: contractorOptions,
        optionLabelKey: "title",
        optionValueKey: "id"
      },
      {
        title: t("customerobjectFieldLabel"),
        key: "filter__timesheet__rows__routesegment__task__customerobject",
        type: "SELECTOR",
        options: customerobjectOptions,
        optionLabelKey: "title",
        optionValueKey: "id"
      }
    ],
    [
      {
        title: v("startDateFieldLabel"),
        key: "filter__time__gt",
        type: "DATE"
      },
      {
        title: v("endDateFieldLabel"),
        key: "filter__time__lt",
        type: "DATE"
      }
    ]
  ];

  useEffect(() => {
    let isAlive = true;

    void Promise.all([
      getCompanyOptionsByTypeAPI(
        showCustomerFilter ? ["CONTRACTOR", "CUSTOMER"] : ["CONTRACTOR"]
      ),
      getSearchCustomerobjectOptionsAPI(),
      getBusinessareasAPI()
    ]).then(
      ([
        companyOptionsResponse,
        customerobjectOptionsResponse,
        { results: businessAreas }
      ]) => {
        if (isAlive) {
          setContractorOptions(
            companyOptionsResponse.results
              .filter((c) => c.company_type === "CONTRACTOR")
              .map((opt) => ({
                label: opt.name,
                value: opt.id
              }))
          );
          setCustomerOptions(
            companyOptionsResponse.results
              .filter((c) => c.company_type === "CUSTOMER")
              .map((opt) => ({
                label: opt.name,
                value: opt.id
              }))
          );
          setCustomerobjectOptions(
            customerobjectOptionsResponse.results.map((opt) => ({
              label: opt.name,
              value: opt.id
            }))
          );
          setBusinessAreaNames(
            new Map(businessAreas.map((ba) => [ba.id, ba.name]))
          );
        }
      }
    );

    return () => {
      isAlive = false;
    };
  }, [showCustomerFilter]);

  const handleVerificationStatus = (verifying: boolean) =>
    setProcessingVerification(verifying);

  const updateApprovalSelection = () => {
    setSelectionStats(calculateSelectionStats(approvalSummaries.data));
  };

  const handleClearApprovalSelection = (routeInstanceId: number) => {
    clearApprovalSelection(approvalSummaries.data, routeInstanceId);
    setSelectionStats(undefined);
  };

  const reloadPageData = async (
    filterQuery: IQuery<IApprovalSummaryQnum>,
    routeInstanceId?: number
  ) => {
    try {
      setSelectionStats(undefined);
      setApprovalSummaries((summary) => ({
        data: routeInstanceId ? summary.data : [],
        isLoading: !routeInstanceId,
        count: summary.count
      }));
      const result = await fetchVerificationSummaries(
        filterQuery,
        dictionariesRef.current
      );

      if (routeInstanceId) {
        setDefaultExpanded(routeInstanceId);
      }

      approvalSummaryMapRef.current = result.approvalSummaryMap;
      setConflictedRows(result.conflictGroups);
      setApprovalSummaries({
        data: result.approvalSummaries,
        isLoading: false,
        count: result.response.count
      });
    } catch (e) {
      showGlobalSnackbar("Unable to get data", "error");
      console.warn(`Unable to get summaries: ${e}`);
    }
  };

  const handleChangeSearch = debounce((fieldVal: string) => {
    setFilterQuery((filterQuery) => {
      const { filter__title__icontains: oldSearchStr, ...others } = filterQuery;
      if (fieldVal.length > 1) {
        return {
          ...others,
          filter__title__icontains: fieldVal,
          page: DEFAULT_QUERY.page
        };
      }

      return oldSearchStr !== undefined ? { ...others } : filterQuery;
    });
  }, 700);

  const handleQueryChange = (filters: IQuery<IApprovalSummaryQnum>) => {
    setFilterQuery(({ page_size }) => ({
      ...filters,
      page: DEFAULT_QUERY.page,
      page_size
    }));
  };
  const handlePageChange = (_: any, page: number) => {
    if (page + 1 === filterQuery.page) return;
    setFilterQuery((query) => ({
      ...query,
      page: page + 1
    }));
  };
  const handlePageSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterQuery((query) => ({
      ...query,
      page_size: parseInt(e.target.value),
      page: DEFAULT_QUERY.page
    }));
  };
  const verifySummary = async () => {
    try {
      const sbIds = approvalSummaries.data.flatMap(({ selectedRows }) =>
        Array.from(selectedRows.keys())
      );
      const reqBody = bulkPayload(sbIds, "set");
      const bulkResp = await bulkApprovalsAPI(reqBody);
      // if we remove reload then do empty selections from approvalSummaries
      setSelectionStats(undefined);
      store.dispatch(VerificationUpdatedAt());
      if (bulkResp) {
        reloadPageData(filterQuery);
        handleVerificationStatus(false);
      }
    } catch (e) {
      console.warn(e);
    }
  };
  const controlApprovalSaveDialog = () => {
    const data: IApprovalSummary[] = [];
    const rowGroups = new Map<number, IApprovableGroup>();

    approvalSummaries.data.forEach((approval) => {
      const selectedRows = Array.from(approval.selectedRows.values());
      if (selectedRows.length > 0) {
        selectedRows.forEach((submittedRow) => {
          let rowGroup = rowGroups.get(submittedRow.segment_row_id);
          if (!rowGroup) {
            rowGroup = {
              segment_row_id: submittedRow.segment_row_id,
              task: submittedRow.readables.task,
              rows: []
            };
            rowGroups.set(submittedRow.segment_row_id, rowGroup);
          }
          rowGroup.rows.push(submittedRow);
        });

        const val: IApprovalSummary = {
          ...approval,
          summaryRows: Array.from(rowGroups.values()).sort(
            (a, b) => a.segment_row_id - b.segment_row_id
          )
        };
        data.push(val);
        rowGroups.clear();
      }
    });
    setSummaryDialogData({
      approveTime: formatDateTime(new Date().toString()),
      approvalSummaries: data
    });
  };
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    spacer: {
      flex: "1 1 100%"
    },
    title: {
      flex: "0 0 auto"
    }
  }));
  const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();
    return (
      <Toolbar className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            {t("workVerificationHeading")}
          </Typography>
        </div>
        <div className={classes.spacer} />
      </Toolbar>
    );
  };

  useEffect(() => {
    reloadPageData(filterQuery);
  }, [filterQuery]);

  return (
    <>
      {routeInstanceId && (
        <div className={classes.backButton}>
          <RouteInstance routeinstanceId={routeInstanceId} />
        </div>
      )}
      <TableFilter<IQuery<IApprovalSummaryQnum>, RouteInstanceType>
        filters={FILTERS}
        onChange={handleQueryChange}
        defaultValues={{ ...DEFAULT_TABLE_QUERY }}
      />
      {!approvalSummaries.isLoading && conflictedRows.length !== 0 && (
        <TimesheetApprovalConflicts
          conflictedRows={conflictedRows}
          onEditSuccess={(routeinstance_id) => {
            reloadPageData(filterQuery, routeinstance_id);
          }}
          submitRowModified={(routeInstanceId) =>
            reloadPageData(filterQuery, routeInstanceId)
          }
        />
      )}
      <Paper className={`${classes.tableMain}`}>
        <div className={classes.paper} data-cy="workapprovals-container">
          <div className={classes.flexDiv}>
            <EnhancedTableToolbar />
            {!routeInstanceId && (
              <div className={classes.searchField}>
                <SearchIcon className={classes.searchIcon} />
                <TextField
                  label={t("searchField")}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
              </div>
            )}
          </div>
          {businessAreaNames &&
          !approvalSummaries.isLoading &&
          !processingVerification ? (
            <TimesheetApprovals
              approvalSummaries={approvalSummaries.data}
              dictionaries={dictionariesRef.current}
              SummaryViewMode={"VERIFICATION"}
              defaultExpanded={approvalSummaryMapRef.current.get(
                defaultExpanded
              )}
              submitRowDeleted={(routeInstanceId) =>
                reloadPageData(filterQuery, routeInstanceId)
              }
              submitRowModified={(routeInstanceId) =>
                reloadPageData(filterQuery, routeInstanceId)
              }
              updateApprovalSelection={updateApprovalSelection}
              clearApprovalSelection={handleClearApprovalSelection}
              businessAreaNames={businessAreaNames}
            />
          ) : (
            <Paper className={classes.loaderPaper}>
              <CircularProgress className={classes.loader} />
            </Paper>
          )}
          <TablePagination
            className={classes.pagination}
            component="div"
            page={
              typeof filterQuery.page !== "undefined" ? filterQuery.page - 1 : 0
            }
            rowsPerPage={filterQuery.page_size || 5}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePageSizeChange}
            count={approvalSummaries.count}
            rowsPerPageOptions={[5, 10, 20, { label: "All", value: -1 }]}
          />
        </div>
      </Paper>
      {selectionStats && (
        <Paper className={classes.paperVerify}>
          <div className={classes.flexBox}>
            <div className={clsx(classes.flexView, classes.alignCenter)}>
              <div>
                <div>{v("startLabel")}</div>
                <Typography className={classes.secondaryHeading}>
                  {getTimeFromDate(selectionStats.start_time)}
                  <br />
                  {formatDate(selectionStats.start_time)}
                </Typography>
              </div>
              <span className={classes.verticalDivider}></span>
              <div>
                <div>{v("endLabel")}</div>
                <Typography className={classes.secondaryHeading}>
                  {getTimeFromDate(selectionStats.end_time)}
                  <br />
                  {formatDate(selectionStats.end_time)}
                </Typography>
              </div>
              <span className={classes.verticalDivider}></span>
              <div>
                <div>{t("totalHeading")}</div>
                <Typography className={classes.secondaryHeading}>
                  {selectionStats.formattedTotalTime}
                  <br />
                  &nbsp;
                </Typography>
              </div>
            </div>
            <div className={classes.selectedApproval}>
              {`${t("selectionTitle")}: ${selectionStats.rowCount}`}
            </div>
            <Button
              variant="outlined"
              className={classes.approveBtn}
              onClick={() => controlApprovalSaveDialog()}
            >
              {t("approveButtonText")}
            </Button>
          </div>
        </Paper>
      )}
      {summaryDialogData && businessAreaNames && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <TimesheetApprovalsSummary
            data={summaryDialogData}
            selectionStats={selectionStats}
            closeDialog={() => setSummaryDialogData(undefined)}
            dictionaries={dictionariesRef.current}
            verifySummary={verifySummary}
            handleVerificationStatus={handleVerificationStatus}
            businessAreaNames={businessAreaNames}
          />
        </Dialog>
      )}
    </>
  );
};

const TimesheetApprovalsPage = (props: TimesheetApprovalProps) => (
  <TimesheetApprovalDialogServiceProvider>
    <ConfirmationDialogServiceProvider>
      <TimesheetApprovalsPageFC {...props} />
    </ConfirmationDialogServiceProvider>
  </TimesheetApprovalDialogServiceProvider>
);

export default TimesheetApprovalsPage;
