import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FeatureCollection,
  SquareMeters,
  ProspectForm,
  mainRootState
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import MapView from "../../../components/contracts/tasks/Map";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";

interface MapContainerProps {
  geo_polygons: FeatureCollection | null;
  handleSave: (
    taskId: number,
    geo_polygons: FeatureCollection | null,
    squareMeters?: SquareMeters,
    prospectForm?: ProspectForm
  ) => void;
  handleCreateImage: (taskId: number, image: string) => void;
  taskId?: number;
  editable: boolean;
  isInDialog: boolean;
  defaultLongitude?: number;
  defaultLatitude?: number;
}

const MapContainer: React.FC<MapContainerProps> = (props) => {
  return (
    <div>
      <MapView
        geo_polygons={props.geo_polygons}
        resourceId={props.taskId}
        editable={props.editable}
        handleSave={props.handleSave}
        handleCreateImage={props.handleCreateImage}
        isInDialog={props.isInDialog}
        defaultLongitude={props.defaultLongitude}
        defaultLatitude={props.defaultLatitude}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  handleSave: (
    taskId: number,
    geo_polygons: FeatureCollection | null,
    squareMeters?: SquareMeters,
    prospectForm?: ProspectForm
  ) => {
    if (squareMeters) {
      dispatch(
        RestActions.restRequestWithData(
          ownProps.tabId,
          RestStrings.PATCH,
          ReduxActionStrings.TASK,
          `contracts/tasks/${taskId}/`,
          {
            geo_polygons: geo_polygons,
            area_square_meters: squareMeters.area,
            dumpsite_square_meters: squareMeters.dumpsite,
            priority_square_meters: squareMeters.priority,
            manual_square_meters: squareMeters.manual
          }
        )
      );
    }
  },
  handleCreateImage: (taskId: number, image: string) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.TASK,
        `contracts/tasks/${taskId}/`,
        { geo_polygons_image: image }
      )
    );
  }
});

const mapStateToProps = (state: mainRootState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
