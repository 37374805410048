import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Company,
  District,
  FilterDefinition,
  mainRootState
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import {
  EnumStrings,
  ReduxActionStrings,
  RestStrings
} from "../../../redux/strings";
import _ from "lodash";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface CompaniesContainerProps {
  companies: Company[];
  districts: District[];
  count: number;
  loadingCompanies: boolean;
  resetCompanies: () => void;
  loadDistricts: (districtIds: number[]) => void;
  loadCompanies: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => void;
  showFilters?: boolean;
  customerobjectId?: number;
  showNewButton?: boolean;
  customHeader?: string;
}

const CompaniesContainer: React.FC<CompaniesContainerProps> = (props) => {
  const {
    loadCompanies,
    resetCompanies,
    companies,
    loadingCompanies,
    loadDistricts,
    districts,
    showFilters,
    customerobjectId
  } = props;
  const page = 1;
  const pageSize = 20;
  let filter = "";

  if (customerobjectId) {
    filter = `&filter__company_type=CONTRACTOR&filter__active_contractors=${customerobjectId}`;
  }

  useEffect(
    () =>
      loadCompanies(page, pageSize, undefined, undefined, filter, [
        EnumStrings.CUSTOMER,
        EnumStrings.CONTRACTOR
      ]),
    [loadCompanies, filter]
  );
  useEffect(
    () =>
      loadDistricts(_.uniq(companies.flatMap((company) => company.districts))),
    [loadDistricts, companies]
  );
  useEffect(() => () => resetCompanies(), [resetCompanies]);

  const columnDefinitions = [
    { name: "logo", type: "thumbnail" },
    { name: "name", type: "link" },
    { name: "company_type", type: "enum" },
    {
      name: "districts",
      type: "multipleLinks",
      multipleData: {
        data: districts,
        visibleColumns: ["name"],
        url: "companies/districts"
      }
    },
    { name: "status", type: "enum" }
  ];

  const showNewButton =
    (typeof props.showNewButton !== "undefined" && props.showNewButton) ||
    typeof props.showNewButton === "undefined";

  const filterDefinitions: FilterDefinition[] =
    typeof showFilters === "undefined" || showFilters
      ? [
          {
            name: "customers",
            flag: EnumStrings.CUSTOMER,
            selectedByDefualt: true
          },
          {
            name: "contractors",
            flag: EnumStrings.CONTRACTOR,
            selectedByDefualt: true
          },
          {
            name: "archived",
            flag: EnumStrings.ARCHIVED,
            selectedByDefualt: false
          }
        ]
      : [];

  return (
    <ResourcesTableContainer
      resources={companies}
      resourceName={"Company"}
      count={props.count}
      loadingResources={loadingCompanies}
      routeUrl={"companies/companies"}
      columnDefinitions={columnDefinitions}
      showNewButton={showNewButton}
      filterDefinitions={filterDefinitions}
      resetResources={resetCompanies}
      defaultPageSize={pageSize}
      loadResources={loadCompanies}
      customFilter={filter}
      customHeader={props.customHeader}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadCompanies: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => {
    const selectableTypes = [EnumStrings.CUSTOMER, EnumStrings.CONTRACTOR];

    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__name=asc";

    const typesFilter = !filter
      ? `&filter__company_type__in=[MATERIAL_SUPPLIER,OTHER,DOMAIN_OWNER${
          flags &&
          flags.filter((flag) => selectableTypes.includes(flag)).length > 0
            ? `,${flags
                .filter((flag) => selectableTypes.includes(flag))
                .toString()}`
            : ""
        }]`
      : "";

    const archivedFilter = `&filter__status__in=[ACTIVE,LOCKED,DRAFT,FLAGGED${
      flags && flags.includes(EnumStrings.ARCHIVED)
        ? `,${EnumStrings.ARCHIVED}`
        : ""
    }]`;

    const customFilter = filter || "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?include=["id", "name", "districts", "company_type", "logo", "status"]&page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${typesFilter}${archivedFilter}${customFilter}`
      )
    );
  },
  loadDistricts: (districtIds: number[]) => {
    if (districtIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.DISTRICTS,
          `companies/districts/?include=["id", "name"]&filter__id__in=[${districtIds}]`
        )
      );
    }
  },
  resetCompanies: () =>
    dispatch(LoadingBooleansActions.ResetCompanies(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  companies: state.tabStates[ownProps.tabId].companies.companies.companies,
  districts: state.tabStates[ownProps.tabId].districts.districts.results,
  count: state.tabStates[ownProps.tabId].companies.companies.count,
  loadingCompanies:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCompanies
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesContainer);
