import { CompaniesState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: CompaniesState = {
  companies: {
    companies: [],
    contractors: [],
    next: null,
    previous: null,
    count: 0,
    company: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      status: "ACTIVE",
      company_type: "CUSTOMER",
      membership_type: "STANDARD",
      organisation_number: "",
      invoice_ref: "",
      website: "",
      logo: null,
      notes: "",
      accounting_reference: 0,
      payment_account: "",
      external: false,
      passcard_type: "PASSCARD",
      after_registration: false,
      owner: 0,
      districts: [],
      location: null,
      invoicerecipients: [],
      cooperations: [],
      contact_person: null,
      invoice_location: null
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.COMPANIES):
      return {
        ...state,
        companies: {
          ...state.companies,
          companies: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTOR_COMPANIES
    ):
      return {
        ...state,
        companies: {
          ...state.companies,
          contractors: action.payload.results
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.COMPANY):
      return {
        ...state,
        companies: {
          ...state.companies,
          company: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.COMPANY):
      return {
        ...state,
        companies: {
          ...state.companies,
          companies: [...state.companies.companies, action.payload]
        }
      };
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.COMPANY):
      return {
        ...state,
        companies: {
          ...state.companies,
          companies: [
            ...state.companies.companies.map((company) =>
              company.id === action.payload.id ? action.payload : company
            )
          ],
          company: action.payload
        }
      };

    default:
      return state;
  }
}

export default reducer;
