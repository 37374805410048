import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { BaseAccessory, TabComponentProps } from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import {
  getBaseAccessoryAPI,
  patchBaseAccessoryAPI
} from "../../../services/api-declaration";
import * as Yup from "yup";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import EditableSimpleSelectField from "../../EditableSimpleSelectField";
import { EnumStrings } from "../../../redux/strings";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DeleteButtonContainer from "../../../containers/DeleteButtonContainer";
import SimpleImageUpload from "../../imageUploadComponent/SimpleImageUpload";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface BaseAccessoryProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"baseAccessoryId"> {}

const BaseAccessoryView: React.FC<BaseAccessoryProps> = (props) => {
  const {
    classes,
    tabId,
    routeParams: { baseAccessoryId }
  } = props;
  const t = useTranslate("AccessoryPage");
  const t2 = useTranslate("BaseAccessories");
  const t3 = useTranslate("ValidationErrorMessages");

  const [baseAccessory, setBaseAccessory] = useState<BaseAccessory>();
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const updateResourceState = (value: BaseAccessory) => setBaseAccessory(value);

  const patchUrl = `contracts/baseaccessories/${baseAccessoryId}/`;

  const statusOptions = [
    EnumStrings.ACTIVE,
    EnumStrings.LOCKED,
    EnumStrings.ARCHIVED,
    EnumStrings.DRAFT
  ];

  const translateFunction = (value: string) => t2(value);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (baseAccessory) store.dispatch(setTitle(baseAccessory.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAccessory]);

  useEffect(() => {
    let isAlive = true;

    (async () => {
      const baseAccessory = await getBaseAccessoryAPI(baseAccessoryId);

      if (isAlive) {
        setBaseAccessory(baseAccessory);
      }
    })();

    return () => {
      isAlive = false;
    };
  }, [baseAccessoryId]);

  const addImage = async (image: string) => {
    setImageLoading(true);
    const response = await patchBaseAccessoryAPI(baseAccessoryId, {
      image: image
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  const deleteImage = async () => {
    setImageLoading(true);
    const response = await patchBaseAccessoryAPI(baseAccessoryId, {
      image: null
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  return !baseAccessory ? (
    <LoadingSpinnerPaper />
  ) : (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/contracts/baseaccessories/"
      >
        <NavigateBeforeIcon /> {t("navigationButtonLabel")}
      </Button>
      <DeleteButtonContainer
        resourceId={baseAccessory.id}
        resource={"BASEACCESSORY"}
        url={"contracts/baseaccessories"}
        backendUrl={"contracts/baseaccessories"}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={classes.bold} colSpan={2}>
                    <SimpleImageUpload
                      handleImageSave={addImage}
                      handleImageDelete={deleteImage}
                      image={baseAccessory.image}
                      alt={baseAccessory.name}
                      isLoading={imageLoading}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      <b>{baseAccessory.name}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableTextField
                      field="name"
                      initValue={baseAccessory.name}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      validation={Yup.string()
                        .min(1, t3("minLengthError"))
                        .max(255, t3("max255LengthError"))
                        .required(t3("requiredError"))}
                    />
                  </TableCell>
                </TableRow>
                {baseAccessory.price !== null && (
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        isPrice
                        field="price"
                        initValue={baseAccessory.price}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000000, t3("max10000000NumberError"))
                          .required(t3("requiredError"))}
                      />{" "}
                      kr {baseAccessory.unit ? `/ ${baseAccessory.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableSimpleSelectField
                      field="status"
                      initValue={baseAccessory.status}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      options={statusOptions.map((option) => ({
                        label: t2(option),
                        value: option
                      }))}
                      translateFunction={translateFunction}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              data-cy="expand-details"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("isInanimateLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="is_inanimate"
                        label={t("isInanimateLabel")}
                        initValue={baseAccessory.is_inanimate}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("hasManyLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="has_many"
                        label={t("hasManyLabel")}
                        initValue={baseAccessory.has_many}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("vatPercentageLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="vat_percentage"
                        initValue={baseAccessory.vat_percentage}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(99.99, t3("max99dot99NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit"
                        initValue={baseAccessory.unit}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .max(255, t3("max255LengthError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitDividerLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit_divider"
                        initValue={baseAccessory.unit_divider}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000, t3("max10000NumberError"))
                          .required(t3("requiredError"))}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("volumeLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="volume"
                        initValue={baseAccessory.volume}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      m<sup>3</sup>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("widthLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="width"
                        initValue={baseAccessory.width}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      mm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("heightLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="height"
                        initValue={baseAccessory.height}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      mm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("weightLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="weight"
                        initValue={baseAccessory.weight}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      kg
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("rangeLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="range"
                        initValue={baseAccessory.range}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      km
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={baseAccessory.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("descriptionLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="description"
                        initValue={baseAccessory.description}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseAccessory.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseAccessory.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(BaseAccessoryView);
