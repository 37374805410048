import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import * as types from "../../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import CustomerLinkContainer from "../../../containers/companies/passcards/CustomerLinkContainer";
import ContractorLinkContainer from "../../../containers/companies/passcards/ContractorLinkContainer";
import PersonLinkContainer from "../../../containers/links/PersonLinkContainer";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../../redux/types";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface PasscardProps extends WithStyles<typeof styles>, TabComponentProps {
  passcard: types.Passcard;
}

const Passcard: React.FC<PasscardProps> = (props) => {
  const { classes, passcard, tabId } = props;
  const t = useTranslate("PasscardPage");
  const t2 = useTranslate("Passcards");

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    store.dispatch(setTitle(passcard.serial_number, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passcard]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      {passcard.serial_number}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">{t2(passcard.status)}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("passcardTypeLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {t2(passcard.passcard_type)}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("ownerCompanyLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {passcard.owner_company ? (
                      <CustomerLinkContainer
                        companyId={passcard.owner_company}
                        {...mapTabRouteProps(props)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractorCompanyLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {passcard.contractor_company ? (
                      <ContractorLinkContainer
                        companyId={passcard.contractor_company}
                        {...mapTabRouteProps(props)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={5}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractorPersonLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {passcard.contractor_person ? (
                      <PersonLinkContainer
                        personId={passcard.contractor_person}
                        {...mapTabRouteProps(props)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("codeLabel")}
                    </TableCell>
                    <TableCell align="right">{passcard.code}</TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("keyNumberLabel")}
                    </TableCell>
                    <TableCell align="right">{passcard.key_number}</TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("outDateLabel")}
                    </TableCell>
                    <TableCell align="right">{passcard.out_date}</TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className={classes.bold}>
                      {t("inDateLabel")}
                    </TableCell>
                    <TableCell align="right">{passcard.in_date}</TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">{passcard.notes}</TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(passcard.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(passcard.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Passcard);
