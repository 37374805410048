import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, TemplateImage } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";
import ResourcesTableContainer from "../../components/resourcetable/ResourcesTableContainer";

interface TemplateImagesContainerProps {
  templateImages: TemplateImage[];
  count: number;
  loadingTemplateImages: boolean;
  resetTemplateImages: () => void;
  loadTemplateImages: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string
  ) => void;
}

const TemplateImagesContainer: React.FC<TemplateImagesContainerProps> = (
  props
) => {
  const {
    loadTemplateImages,
    templateImages,
    loadingTemplateImages,
    resetTemplateImages
  } = props;
  const page = 1;
  const pageSize = 20;

  useEffect(() => loadTemplateImages(page, pageSize), [loadTemplateImages]);
  useEffect(() => () => resetTemplateImages(), [resetTemplateImages]);

  const columnDefinitions = [{ name: "name", type: "link" }];

  return (
    <ResourcesTableContainer
      resources={templateImages}
      resourceName={"TemplateImage"}
      count={props.count}
      loadingResources={loadingTemplateImages}
      routeUrl={"services/templateimages"}
      columnDefinitions={columnDefinitions}
      resetResources={resetTemplateImages}
      loadResources={loadTemplateImages}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadTemplateImages: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__name=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TEMPLATEIMAGES,
        `services/templateimages/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}`
      )
    );
  },
  resetTemplateImages: () =>
    dispatch(LoadingBooleansActions.ResetTemplateImages(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  templateImages:
    state.tabStates[ownProps.tabId].templateImages.templateImages
      .templateImages,
  count: state.tabStates[ownProps.tabId].templateImages.templateImages.count,
  loadingTemplateImages:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingTemplateImages
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateImagesContainer);
