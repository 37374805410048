import React from "react";
import { WithStyles, Paper, Typography } from "@material-ui/core";
import { useTranslate } from "../services/appLanguageService";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme: any) =>
  createStyles({
    paper: {
      padding: 20
    }
  });

interface UnderConstructionProps extends WithStyles<typeof styles> {}

const UnderConstruction: React.FC<UnderConstructionProps> = (props) => {
  const t = useTranslate("UnderConstructionPage");
  const { classes } = props;
  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">{t("underConstructionLabel")}</Typography>
    </Paper>
  );
};

export default withStyles(styles)(UnderConstruction);
