import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  District,
  TabComponentProps
} from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import DistrictView from "../../../components/companies/districts/District";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import ViewActionButtons from "../../../components/ViewActionButtons";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface DistrictContainerProps extends TabComponentProps<"districtId"> {
  district: District;
  loadingDistrict: boolean;
  resetDistrict: () => void;
  loadDistrict: (districtId: number) => void;
}

const DistrictContainer: React.FC<DistrictContainerProps> = (props) => {
  const {
    resetDistrict,
    loadingDistrict,
    loadDistrict,
    district,
    routeParams: { districtId }
  } = props;
  useEffect(() => loadDistrict(districtId), [loadDistrict, districtId]);

  useEffect(() => () => resetDistrict(), [resetDistrict]);
  return (
    <div>
      <ViewActionButtons
        resourceId={districtId}
        resource={ReduxActionStrings.DISTRICT}
        url="companies/districts"
      />
      {loadingDistrict ? (
        <LoadingSpinnerPaper />
      ) : (
        <DistrictView district={district} {...mapTabRouteProps(props)} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadDistrict: (districtId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.DISTRICT,
        `companies/districts/${districtId}/`
      )
    );
  },
  resetDistrict: () =>
    dispatch(LoadingBooleansActions.ResetDistrict(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  district: state.tabStates[ownProps.tabId].districts.district.district,
  loadingDistrict:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingDistrict
});

export default connect(mapStateToProps, mapDispatchToProps)(DistrictContainer);
