import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from "@material-ui/core";
import * as types from "../../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../../redux/types";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    }
  });

interface WeatherAreaProps
  extends WithStyles<typeof styles>,
    TabComponentProps {
  weatherArea: types.WeatherArea;
  parentWeatherArea?: types.WeatherArea;
}

const WeatherArea: React.FC<WeatherAreaProps> = (props) => {
  const { classes, weatherArea, tabId } = props;
  const t = useTranslate("WeatherAreaPage");

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    store.dispatch(setTitle(weatherArea.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherArea]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <List>
              <ListItem>
                <ListItemText
                  primary={t("nameLabel")}
                  secondary={weatherArea.name}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t("latestFetchLabel")}
                  secondary={weatherArea.latest_fetch || ""}
                />
              </ListItem>
            </List>
          </Paper>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  <ListItemText
                    primary={t("notesLabel")}
                    secondary={weatherArea.notes}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("createdAtLabel")}
                    secondary={niceDate(weatherArea.created_at)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("updatedAtLabel")}
                    secondary={niceDate(weatherArea.updated_at)}
                  />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <List></List>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(WeatherArea);
