import React, { useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import { HistoryRow, ServiceCategory } from "../../../redux/types";
import TransactionGroup from "./TransactionGroup";
import TransactionFooter from "./TransactionFooter";
import { useTranslate } from "../../../services/appLanguageService";
import Typography from "@material-ui/core/Typography";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface TransactionDialogProps
  extends WithStyles<typeof transactionDialogStyles> {
  defaultExpandedId: number;
  historyRows: HistoryRow[];
  handleClose: () => void;
  showPrices: boolean;
  serviceCategories: ServiceCategory[];
}
const transactionDialogStyles = (theme: Theme) =>
  createStyles({
    dialogTitleStyle: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    mainContentContainer: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(2)
    }
  });

const TransactionDialog = withStyles(transactionDialogStyles)(
  (props: TransactionDialogProps) => {
    const {
      classes,
      defaultExpandedId,
      historyRows,
      handleClose,
      showPrices,
      serviceCategories
    } = props;
    const [expandedIds, setExpandedIds] = useState<number[]>([
      defaultExpandedId
    ]);
    const t = useTranslate("HistoryTransactionsDialog");

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="transactions-dialog-title"
        open={true}
      >
        <MuiDialogTitle disableTypography className={classes.dialogTitleStyle}>
          <Typography variant="h6" id="tableTitle">
            {t("dialogTitle")}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.mainContentContainer}>
          {historyRows.map((r, i) => (
            <TransactionGroup
              key={i}
              expanded={expandedIds.includes(r.approval_id)}
              toggleExpanded={() =>
                setExpandedIds((ids) =>
                  ids.includes(r.approval_id)
                    ? ids.filter((id) => id !== r.approval_id)
                    : [...ids, r.approval_id]
                )
              }
              historyRow={r}
              showPrices={showPrices}
              serviceCategories={serviceCategories}
            />
          ))}
          {showPrices ? (
            <TransactionFooter historyRow={historyRows[0]} />
          ) : null}
        </MuiDialogContent>
      </Dialog>
    );
  }
);
export default TransactionDialog;
