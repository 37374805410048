import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Link
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import { CustomerObjectTaskTable } from "./CustomerObjectTaskTable";
import PasscardsContainer from "../../../containers/companies/passcards/PasscardsContainer";
import { useTranslate } from "../../../services/appLanguageService";
import ImageContainer from "../../../containers/ImageContainer";
import EditableNotes from "../../EditableNotes";
import ContactPerson from "../../ContactPerson";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  Customerobject,
  Person,
  TabComponentProps
} from "../../../redux/types";
import EditIcon from "@material-ui/icons/Edit";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import ViewActionButtons from "../../ViewActionButtons";
import { ReduxActionStrings } from "../../../redux/strings";
import store from "../../../redux/store";
import {
  getCompanyNamesAPI,
  getCustomerObjectAPI,
  getPersonAPI,
  updateCustomerobjectAPI
} from "../../../services/api-declaration";
import CustomerobjectPersons from "./CustomerobjectPersons";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  })
);

type CustomerobjectProps = {} & TabComponentProps<"customerobjectId">;

const CustomerobjectView: React.FC<CustomerobjectProps> = ({
  tabId,
  routeParams: { customerobjectId }
}) => {
  const classes = useStyles();
  const t = useTranslate("CustomerobjectPage");
  const t2 = useTranslate("Customerobjects");
  const [expanded, setExpanded] = useState(false);
  const [customerobject, setCustomerobject] = useState<Customerobject>();
  const [companyName, setCompanyName] = useState<string>();
  const [contactPerson, setContactPerson] = useState<Person>();

  useEffect(() => {
    let alive = true;
    void getCustomerObjectAPI(customerobjectId)
      .then(async (co) =>
        Promise.all([
          co,
          getCompanyNamesAPI({ id__in: [co.customer] }).then(
            ({ results }) => results[0]?.name
          ),
          co.contact_person ? getPersonAPI(co.contact_person) : null
        ])
      )
      .then(([co, companyName, person]) => {
        if (alive) {
          setCustomerobject(co);
          setCompanyName(companyName);
          person && setContactPerson(person);
          store.dispatch(setTitle(co.name, tabId));
        }
      });
    return () => {
      alive = false;
    };
  }, [customerobjectId, tabId]);

  const saveNotes = (id: number, notes: string | null) =>
    updateCustomerobjectAPI(id, { notes: notes }).then(
      (customerobjectResponse) =>
        setCustomerobject((currentCustomerobject) =>
          currentCustomerobject
            ? { ...currentCustomerobject, notes: customerobjectResponse.notes }
            : currentCustomerobject
        )
    );

  const updateCustomerobject = (newCustomerobject: Customerobject) =>
    setCustomerobject(newCustomerobject);

  return (
    <div>
      <ViewActionButtons
        resourceId={customerobjectId}
        resource={ReduxActionStrings.CUSTOMEROBJECT}
        url="contracts/customerobjects"
      />
      {!customerobject ? (
        <LoadingSpinnerPaper />
      ) : (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TableContainer component={Paper} className={classes.paper}>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <ImageContainer
                          src={customerobject.company_logo}
                          alt={customerobject.name}
                          type="logo"
                        />
                        <Typography variant="h5">
                          {customerobject.name}
                        </Typography>
                        {customerobject.location && (
                          <Typography>
                            {customerobject.location.address} <br />
                            {customerobject.location.zip_code}{" "}
                            {customerobject.location.city}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.bold}>
                        {t("contactPersonLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {contactPerson && (
                          <ContactPerson contactPerson={contactPerson} />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.bold}>
                        {t("statusLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {t2(customerobject.status)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.bold}>
                        {t("customerLabel")}
                      </TableCell>
                      <TableCell align="right">
                        {companyName ? (
                          <Link
                            component={RouterLink}
                            to={`/companies/companies/${customerobject.customer}`}
                          >
                            {companyName}
                          </Link>
                        ) : (
                          <>{"No company" /* TODO: Translation */}</>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.expandHeader}
                  onClick={() =>
                    expanded ? setExpanded(false) : setExpanded(true)
                  }
                >
                  <Typography>
                    {expanded ? t("hideLabel") : t("showMoreLabel")}
                  </Typography>
                </AccordionSummary>
                <TableContainer
                  component={AccordionDetails}
                  className={classes.tableContainer}
                >
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("invoiceAddressLabel")}
                        </TableCell>
                        <TableCell align="right">
                          <span>
                            {customerobject.invoice_location &&
                              customerobject.invoice_location.address &&
                              `${customerobject.invoice_location.address} ${customerobject.invoice_location.zip_code} ${customerobject.invoice_location.city}`}
                          </span>
                          <IconButton
                            aria-label="edit"
                            className={classes.editButton}
                            size="small"
                            component={RouterLink}
                            to={`/contracts/customerobjects/${customerobject.id}/edit-invoice-location`}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("accountingReferenceLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {customerobject.accounting_reference}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("customerobjectTypeLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {t2(customerobject.customer_object_type)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("passcardTypeLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {t2(customerobject.passcard_type)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("districtLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {t2(customerobject.district_name)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("allowPhotosLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {!customerobject.allow_photos ? t2("YES") : t2("NO")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("invoiceAddressEnabledLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {customerobject.invoice_address_enabled
                            ? t2("YES")
                            : t2("NO")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("invoiceNameLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {customerobject.invoice_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("weekdayOpenHoursLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {customerobject.weekday_opening &&
                          customerobject.weekday_closing
                            ? `${customerobject.weekday_opening.slice(
                                0,
                                5
                              )}-${customerobject.weekday_closing.slice(0, 5)}`
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("saturdayOpenHoursLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {customerobject.saturday_opening &&
                          customerobject.saturday_closing
                            ? `${customerobject.saturday_opening.slice(
                                0,
                                5
                              )}-${customerobject.saturday_closing.slice(0, 5)}`
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("sundayOpenHoursLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {customerobject.sunday_opening &&
                          customerobject.sunday_closing
                            ? `${customerobject.sunday_opening.slice(
                                0,
                                5
                              )}-${customerobject.sunday_closing.slice(0, 5)}`
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("createdAtLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {niceDate(customerobject.created_at)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" className={classes.bold}>
                          {t("updatedAtLabel")}
                        </TableCell>
                        <TableCell align="right">
                          {niceDate(customerobject.updated_at)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Accordion>
              <EditableNotes
                customLabel={t("internalNotesLabel")}
                resourceId={customerobject.id}
                notes={customerobject.notes}
                saveNotes={saveNotes}
              />
              <PasscardsContainer
                showNewButton={false}
                passcardIds={customerobject.passcards}
                companyId={customerobject.customer}
                customerobjectId={customerobject.id}
                editable={true}
                tabId={tabId}
              />
              <CustomerobjectPersons
                updateCustomerobject={updateCustomerobject}
                customerobject={customerobject}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomerObjectTaskTable
                customerObjectId={customerobject.id}
                companyId={customerobject.customer}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default CustomerobjectView;
