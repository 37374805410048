import React, { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  Tooltip,
  TableBody,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  PaginationFilterQuery,
  BulkInvoiceStatusUpdatePayload,
  BulkInvoiceStatusReturn,
  Invoice,
  InvoiceFilterQuery,
  OrderFilterType
} from "../../../redux/types";
import { niceAmount, niceDate } from "../../FormatHelpers";
import BottomInvoiceButtonsView from "../bottomInvoiceButtonsView/BottomInvoiceButtonsView";
import { useTranslate } from "../../../services/appLanguageService";
import {
  getInvoiceEmailStatusIconWithColor,
  getInvoiceStatusIconWithColor
} from "../../../helpers/invoiceHelper";
import { useHistory } from "react-router-dom";
import { getCustomerobjectByIdsAPI } from "../../../services/api-declaration";
import { ArrowUpward, ArrowDownward, SwapVert } from "@material-ui/icons";
import NoItemsText from "../../NoItemText";

const useStyles = makeStyles((theme: any) => ({
  headerCell: {
    fontWeight: "bold",
    cursor: "pointer",
    padding: 6,
    "&:last-child": {
      paddingRight: 10
    },
    "&:first-child": {
      paddingLeft: 15
    },
    userSelect: "none",
    "-webkit-user-select": "none",
    overflowX: "visible"
  },
  boldFont: {
    fontWeight: "bold"
  },
  root: {
    width: "100%"
  },
  pagination: {
    padding: theme.spacing(1.25),
    width: "100%"
  },
  iconPadding: {
    paddingTop: "6px !important"
  },
  paper: {
    width: "100%"
  },
  tableContainerPaddingBottom: {
    paddingBottom: theme.spacing(2)
  },
  tableRow: {
    "& > *": {
      padding: `0 ${theme.spacing(1)}px`
    },
    textDecoration: "none",
    cursor: "pointer"
  },
  smallIcon: {
    fontSize: "1rem"
  }
}));

interface InvoiceDetailTableProps {
  count: number;
  invoiceEntityLabel: string;
  invoices: Invoice[];
  bulkInvoiceStatusUpdate: (
    payload: BulkInvoiceStatusUpdatePayload
  ) => Promise<BulkInvoiceStatusReturn>;
  bulkSendInvoiceEmail: (payload: { invoice_ids: number[] }) => Promise<void>;
  handlePaginateQuery: (pagination: PaginationFilterQuery) => void;
  query: InvoiceFilterQuery;
  invoiceType: string;
  changeSorting: (orderByColumn: OrderFilterType) => void;
}

function InvoiceDetailTable(props: InvoiceDetailTableProps) {
  const {
    bulkInvoiceStatusUpdate,
    bulkSendInvoiceEmail,
    count,
    handlePaginateQuery,
    invoices,
    invoiceEntityLabel,
    invoiceType,
    query,
    changeSorting
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [selectedInvoices, setSelectedInvoices] = useState<Set<number>>(
    new Set()
  );

  const [customerobjectNames, setCustomerobjectNames] =
    useState<Map<number, string>>();

  const t = useTranslate("InvoicesView");
  const t2 = useTranslate("Invoices");
  const u = useTranslate("Utility");

  const handlePageChange = (_: unknown, page: number) => {
    handlePaginateQuery({
      page: page + 1
    });
    setSelectedInvoices(new Set());
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handlePaginateQuery({
      page: 1,
      page_size: parseInt(e.target.value)
    });
  };

  const toggleInvoice = (invoiceId: number) => {
    setSelectedInvoices((selected) =>
      selected.has(invoiceId)
        ? new Set([...selected].filter((id) => id !== invoiceId))
        : new Set([...selected, invoiceId])
    );
  };

  useEffect(() => {
    let isAlive = true;
    (async () => {
      const customerobjectIds = invoices
        .map((invoice) => invoice.customerobject)
        .filter(
          (customerobjectId): customerobjectId is number =>
            customerobjectId !== null
        );
      if (customerobjectIds.length > 0) {
        const customerobjectsResponse = await getCustomerobjectByIdsAPI(
          customerobjectIds
        );

        if (isAlive) {
          setCustomerobjectNames(
            new Map(
              customerobjectsResponse.results.map((customerobject) => [
                customerobject.id,
                customerobject.name
              ])
            )
          );
        }
      }
    })();

    return () => {
      isAlive = false;
    };
  }, [invoices]);

  const generateSortIcon = (orderByColumn: OrderFilterType) => {
    if (query[orderByColumn] === "asc") {
      return <ArrowUpward className={classes.smallIcon} />;
    } else if (query[orderByColumn] === "desc") {
      return <ArrowDownward className={classes.smallIcon} />;
    }
    return (
      <Tooltip title="Sort">
        <SwapVert className={classes.smallIcon} />
      </Tooltip>
    );
  };

  return invoices.length ? (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell
                  className={classes.headerCell}
                  onClick={() => changeSorting("order__status")}
                  align="center"
                >
                  {t("statusLabel")}
                  {generateSortIcon("order__status")}
                </TableCell>
                <TableCell className={classes.boldFont}>
                  {invoiceEntityLabel}
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.headerCell}
                  onClick={() => changeSorting("order__number")}
                >
                  {t("InvoiceNumberLabel")}
                  {generateSortIcon("order__number")}
                </TableCell>
                <TableCell align="right" className={classes.boldFont}>
                  {t("extOrderLabel")}
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.headerCell}
                  onClick={() => changeSorting("order__invoice_date")}
                >
                  {t("invoiceDateLabel")}{" "}
                  {generateSortIcon("order__invoice_date")}
                </TableCell>
                <TableCell align="right" className={classes.boldFont}>
                  {t("amountLabel")}
                </TableCell>
                <TableCell align="right" className={classes.boldFont}>
                  {t("emailSentLabel")}
                </TableCell>
                <TableCell align="right" className={classes.boldFont}>
                  {t("optionsLabel")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-cy="invoices-table-body">
              {invoices.map((invoice, idx) => (
                <TableRow
                  key={idx}
                  hover
                  data-cy={`invoice-row-${invoice.id}`}
                  onClick={() =>
                    history.push(`/invoices/${invoiceType}/${invoice.id}`)
                  }
                  className={classes.tableRow}
                >
                  <TableCell padding="checkbox">
                    {!!invoice.allowed_transitions.length && (
                      <Checkbox
                        checked={selectedInvoices.has(invoice.id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleInvoice(invoice.id);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center" className={classes.iconPadding}>
                    <Tooltip title={t2(invoice.status)} placement="top">
                      {getInvoiceStatusIconWithColor(invoice.status)}
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {invoice.customerobject
                      ? customerobjectNames?.get(invoice.customerobject)
                      : invoice.company_name}
                  </TableCell>
                  <TableCell align="right">{invoice.number ?? "-"}</TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: "gray"
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title={
                        t2(
                          invoice.details.recipient_info
                            .invoice_external_order_number
                        ) || u("notAvailableAbbr")
                      }
                    >
                      {invoice.details.recipient_info
                        .invoice_external_order_number ? (
                        <Typography>
                          {
                            invoice.details.recipient_info
                              .invoice_external_order_number
                          }
                        </Typography>
                      ) : (
                        <Typography>{u("notAvailableAbbr")}</Typography>
                      )}
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    {niceDate(invoice.invoice_date, false)}
                  </TableCell>
                  <TableCell align="right">
                    {invoice.summary ? niceAmount(invoice.summary.total) : 0}
                  </TableCell>
                  <TableCell align="right" className={classes.iconPadding}>
                    {invoice.email_status &&
                      getInvoiceEmailStatusIconWithColor(invoice.email_status)}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          component="div"
          page={(query.page ?? 0) - 1}
          rowsPerPage={query.page_size ?? 25}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
          count={count}
          rowsPerPageOptions={[25, 50]}
        />
      </Paper>
      {selectedInvoices.size > 0 && (
        <BottomInvoiceButtonsView
          bulkInvoiceStatusUpdate={bulkInvoiceStatusUpdate}
          bulkSendInvoiceEmail={bulkSendInvoiceEmail}
          invoices={invoices.filter((i) => i.allowed_transitions.length)}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
        />
      )}
    </>
  ) : (
    <NoItemsText />
  );
}

export default InvoiceDetailTable;
