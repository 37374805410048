import { GroupsState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { RestStrings, ReduxActionStrings } from "../../strings";

const initialState: GroupsState = {
  groups: {
    results: [],
    next: null,
    previous: null,
    count: 0
  },
  group: {
    group: {
      id: -1,
      created_at: "",
      updated_at: "",
      editable: false,
      name: "",
      notes: "",
      avatar: null,
      permissions: [],
      sub_groups: []
    },
    permissions: []
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.GROUPS):
      return {
        ...state,
        groups: {
          ...state.groups,
          results: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.GROUP):
      return {
        ...state,
        group: {
          ...state.group,
          group: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.GROUP):
      return {
        ...state,
        groups: {
          ...state.groups,
          groups: [...state.groups.results, action.payload]
        }
      };
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.GROUP):
      return {
        ...state,
        groups: {
          ...state.groups,
          groups: [
            ...state.groups.results.map((group) =>
              group.id === action.payload.id ? action.payload : group
            )
          ]
        },
        group: {
          ...state.group,
          group: {
            ...state.group.group,
            permissions: action.payload.permissions,
            sub_groups: action.payload.sub_groups
          }
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PERMISSIONS
    ):
      return {
        ...state,
        group: {
          ...state.group,
          permissions: action.payload
        }
      };

    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.PERMISSION
    ):
      return {
        ...state,
        group: {
          ...state.group,
          permissions: [
            ...state.group.permissions.map((permission) =>
              permission.id === action.payload.id ? action.payload : permission
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
