import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  BaseMaterial,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import {
  getBaseMaterialAPI,
  getBusinessareasAPI,
  getMaterialSuppliersAPI,
  patchBaseMaterialAPI
} from "../../../services/api-declaration";
import * as Yup from "yup";
import SimpleImageUpload from "../../imageUploadComponent/SimpleImageUpload";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import EditableSimpleSelectField from "../../EditableSimpleSelectField";
import { EnumStrings } from "../../../redux/strings";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import EditableAutoCompleteSelect from "../../EditableAutoCompleteSelect";
import DeleteButtonContainer from "../../../containers/DeleteButtonContainer";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface BaseMaterialProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"baseMaterialId"> {}

const BaseMaterialView: React.FC<BaseMaterialProps> = (props) => {
  const {
    classes,
    tabId,
    routeParams: { baseMaterialId }
  } = props;
  const t = useTranslate("MaterialPage");
  const t2 = useTranslate("BaseMaterials");
  const t3 = useTranslate("ValidationErrorMessages");

  const [baseMaterial, setBaseMaterial] = useState<BaseMaterial>();
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [supplierOptions, setSupplierOptions] = useState<SelectFieldOption[]>(
    []
  );
  const [businessareaOptions, setBusinessareaOptions] = useState<
    SelectFieldOption[]
  >([]);
  const updateResourceState = (value: BaseMaterial) => setBaseMaterial(value);

  const patchUrl = `contracts/basematerials/${baseMaterialId}/`;

  const statusOptions = [
    EnumStrings.ACTIVE,
    EnumStrings.LOCKED,
    EnumStrings.ARCHIVED,
    EnumStrings.DRAFT
  ];

  const translateFunction = (value: string) => t2(value);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (baseMaterial) store.dispatch(setTitle(baseMaterial.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseMaterial]);

  useEffect(() => {
    let isAlive = true;

    (async () => {
      const baseMaterial = await getBaseMaterialAPI(baseMaterialId);

      if (isAlive) {
        setBaseMaterial(baseMaterial);
      }
    })();

    return () => {
      isAlive = false;
    };
  }, [baseMaterialId]);

  useEffect(() => {
    let isAlive = true;
    (async () => {
      const [suppliersResponse, businessareasResponse] = await Promise.all([
        getMaterialSuppliersAPI(),
        getBusinessareasAPI()
      ]);

      if (isAlive) {
        setSupplierOptions(
          suppliersResponse.results.map((supplier) => ({
            label: supplier.name,
            value: supplier.id
          }))
        );
        setBusinessareaOptions(
          businessareasResponse.results.map((businessarea) => ({
            label: businessarea.name,
            value: businessarea.id
          }))
        );
      }
    })();

    return () => {
      isAlive = false;
    };
  }, []);

  const addImage = async (image: string) => {
    setImageLoading(true);
    const response = await patchBaseMaterialAPI(baseMaterialId, {
      image: image
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  const deleteImage = async () => {
    setImageLoading(true);
    const response = await patchBaseMaterialAPI(baseMaterialId, {
      image: null
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  return !baseMaterial ? (
    <LoadingSpinnerPaper />
  ) : (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/contracts/basematerials/"
      >
        <NavigateBeforeIcon /> {t("navigationButtonLabel")}
      </Button>
      <DeleteButtonContainer
        resourceId={baseMaterial.id}
        resource={"BASEMATERIAL"}
        url={"contracts/basematerials"}
        backendUrl={"contracts/basematerials"}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={classes.bold} colSpan={2}>
                    <SimpleImageUpload
                      handleImageSave={addImage}
                      handleImageDelete={deleteImage}
                      image={baseMaterial.image}
                      alt={baseMaterial.name}
                      isLoading={imageLoading}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      <b>{baseMaterial.name}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableTextField
                      field="name"
                      initValue={baseMaterial.name}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      validation={Yup.string()
                        .min(1, t3("minLengthError"))
                        .max(255, t3("max255LengthError"))
                        .required(t3("requiredError"))}
                    />
                  </TableCell>
                </TableRow>
                {baseMaterial.price !== null && (
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        isPrice
                        field="price"
                        initValue={baseMaterial.price}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000000, t3("max10000000NumberError"))
                          .required(t3("requiredError"))}
                      />{" "}
                      kr {baseMaterial.unit ? `/ ${baseMaterial.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableSimpleSelectField
                      field="status"
                      initValue={baseMaterial.status}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      options={statusOptions.map((option) => ({
                        label: t2(option),
                        value: option
                      }))}
                      translateFunction={translateFunction}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              data-cy="expand-details"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("supplierLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableAutoCompleteSelect
                        resourceUrl="companies/companies"
                        url={patchUrl}
                        field="supplier"
                        options={supplierOptions}
                        label={t("supplierLabel")}
                        updateResourceState={updateResourceState}
                        initValue={baseMaterial.supplier}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("businessareaLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableAutoCompleteSelect
                        resourceUrl="contracts/businessareas"
                        url={patchUrl}
                        field="businessarea"
                        options={businessareaOptions}
                        label={t("businessareaLabel")}
                        updateResourceState={updateResourceState}
                        initValue={baseMaterial.businessarea}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("debitsDepotLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="debits_depot"
                        label={t("debitsDepotLabel")}
                        initValue={baseMaterial.debits_depot}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("isInanimateLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="is_inanimate"
                        label={t("isInanimateLabel")}
                        initValue={baseMaterial.is_inanimate}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("hasManyLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="has_many"
                        label={t("hasManyLabel")}
                        initValue={baseMaterial.has_many}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("vatPercentageLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="vat_percentage"
                        initValue={baseMaterial.vat_percentage}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(99.99, t3("max99dot99NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit"
                        initValue={baseMaterial.unit}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .min(1, t3("minLengthError"))
                          .max(255, t3("max255LengthError"))
                          .required(t3("requiredError"))}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitDividerLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit_divider"
                        initValue={baseMaterial.unit_divider}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000, t3("max10000NumberError"))
                          .required(t3("requiredError"))}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("coefficientLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="coefficient"
                        initValue={baseMaterial.coefficient}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000, t3("max10000NumberError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={baseMaterial.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("descriptionLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="description"
                        initValue={baseMaterial.description}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseMaterial.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseMaterial.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(BaseMaterialView);
