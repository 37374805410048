import {
  createFailureActionType,
  createSuccessActionType
} from "../../actionTypes";
import { RouteInstancesState, Action } from "../../../types";
import { RestStrings, ReduxActionStrings } from "../../../strings";

const initialState: RouteInstancesState = {
  routeinstances: [],
  count: 0,
  next: null,
  previous: null,
  routeinstance: {
    title: "",
    id: -1,
    rows: [],
    oca: -1,
    time: "",
    deadline: "",
    servicecategories: [],
    participants: [],
    orders: [],
    dispatched: false,
    status: "DRAFT",
    created_by: -1,
    is_active: false,
    plan: -1,
    plan_name: "",
    is_accepted: false,
    created_at: ""
  },
  errorMessages: {}
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTEINSTANCES
    ):
      return {
        ...state,
        routeinstances: action.payload.results,
        count: action.payload.count
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTEINSTANCE
    ):
      return {
        ...state,
        routeinstance: action.payload
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.ROUTEINSTANCE
    ):
      return {
        ...state,
        routeinstance: action.payload
      };
    case createFailureActionType(
      RestStrings.POST,
      ReduxActionStrings.ROUTEINSTANCE
    ):
    case createFailureActionType(
      RestStrings.PATCH,
      ReduxActionStrings.ROUTEINSTANCE
    ):
      return {
        ...state,
        errorMessages: action.payload.body || {}
      };
    default:
      return state;
  }
}

export default reducer;
