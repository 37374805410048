import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import {
  languagesObject,
  resetLocale,
  useTranslate
} from "../../services/appLanguageService";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import { getPersonAPI, getUserAPI } from "../../services/api-declaration";
import LoadingSpinnerPaper from "../LoadingSpinnerPaper";
import { Person, User } from "../../redux/types";
import { getFromSessionStore } from "../../services/storageService";
import EditableSimpleSelectField from "../EditableSimpleSelectField";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

const languageOptions = ["sv", "en"];

interface MyProfileProps extends WithStyles<typeof styles> {
  tabId: number;
}

const MyProfile: React.FC<MyProfileProps> = (props) => {
  const { classes, tabId } = props;
  const t = useTranslate("MyProfilePage");
  const t2 = useTranslate("Users");
  const t3 = useTranslate("Persons");

  const [person, setPerson] = useState<Person>();
  const [user, setUser] = useState<User>();

  const updateLanguageState = async (value: User) => {
    localStorage.setItem("master_language", languagesObject[value.language]);
    window.dispatchEvent(new Event("storage"));
    resetLocale();
    setUser(value);
    window.location.reload();
  };

  const patchUrl = person ? `auth/users/${person.user}/` : "";

  useEffect(() => {
    if (person)
      store.dispatch(
        setTitle(`${person.first_name} ${person.last_name}`, tabId)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  const personId = getFromSessionStore("person_id");

  useEffect(() => {
    if (personId) {
      let alive = true;

      (async () => {
        const personResult = await getPersonAPI(+personId);

        if (alive) {
          setPerson(personResult);
        }

        if (personResult?.user) {
          const userResult = await getUserAPI(personResult.user);

          if (alive) {
            setUser(userResult);
          }
        }
      })();

      return () => {
        alive = false;
      };
    }
  }, [personId]);

  const translateFunction = (value: string) => t2(value);

  if (!person) return <LoadingSpinnerPaper />;
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      {`${person?.first_name} ${person?.last_name}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("titleLabel")}
                  </TableCell>
                  <TableCell align="right">{t3(person?.title)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("languageLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {user && (
                      <EditableSimpleSelectField
                        field="language"
                        initValue={user.language}
                        url={patchUrl}
                        updateResourceState={updateLanguageState}
                        options={languageOptions.map((option) => ({
                          label: t2(option),
                          value: option
                        }))}
                        translateFunction={translateFunction}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("emailLabel")}
                  </TableCell>
                  <TableCell align="right">{person?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("phoneNumberLabel")}
                  </TableCell>
                  <TableCell align="right">{person?.phone1}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(MyProfile);
