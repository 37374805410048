import { createSuccessActionType } from "../actionTypes";
import { ContractsState, Action } from "../../types";
import { RestStrings, ReduxActionStrings, EnumStrings } from "../../strings";

const initialState: ContractsState = {
  contracts: {
    contracts: [],
    next: null,
    previous: null,
    count: 0
  },
  contract: {
    contract: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      display_name: "",
      status: "",
      accept_status: "",
      signature_type: "",
      accepted_from_ip: "",
      display_as: "",
      invoice_ref: "",
      company_type: "",
      contract_text: "",
      comment: "",
      description: "",
      footnote: "",
      final_conditions: "",
      valid: false,
      terms: undefined,
      show_validity: false,
      valid_from: "",
      valid_to: "",
      accepted_by_user: "",
      services: [],
      materials: [],
      accessories: [],
      addons: [],
      supplements: [],
      servicepackages: [],
      tasks: [],
      cooperations: [],
      modifying_tasks: [],
      project: undefined,
      company: -1,
      oca: -1,
      businessarea: -1,
      signer_name: "",
      counterpart_signer: null,
      countersigner_name: "",
      signer: null,
      duedays: 30,
      locked_items: {},
      invoicingperiod: "MONTHLY"
    },
    contractItems: [],
    services: [],
    materials: [],
    accessories: [],
    addons: [],
    supplements: []
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.CONTRACTS):
      return {
        ...state,
        contracts: {
          ...state.contracts,
          contracts: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACT_ITEMS
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          contractItems: action.payload
        }
      };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.CONTRACT):
      return {
        ...state,
        contract: {
          ...state.contract,
          contract: action.payload
        }
      };

    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.CONTRACT):
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.CONTRACT
    ):
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.CLONE_CONTRACT
    ):
    case EnumStrings.ADD_CONTRACT_ITEM:
      return {
        ...state,
        contracts: {
          ...state.contracts,
          contracts: [...state.contracts.contracts, action.payload]
        },
        contract: {
          ...state.contract,
          contract: action.payload
        }
      };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.SERVICES):
      return {
        ...state,
        contract: {
          ...state.contract,
          services: action.payload.results
        }
      };

    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.SERVICE):
      return {
        ...state,
        contract: {
          ...state.contract,
          services: [
            ...state.contract.services.map((service) =>
              service.id === action.payload.id ? action.payload : service
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.SERVICE
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          services: [
            ...state.contract.services.filter(
              (service) => service.id !== parseInt(action.payload.id)
            )
          ]
        }
      };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.MATERIALS):
      return {
        ...state,
        contract: {
          ...state.contract,
          materials: action.payload.results
        }
      };

    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.MATERIAL
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          materials: [
            ...state.contract.materials.map((material) =>
              material.id === action.payload.id ? action.payload : material
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.MATERIAL
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          materials: [
            ...state.contract.materials.filter(
              (material) => material.id !== parseInt(action.payload.id)
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ACCESSORIES
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          accessories: action.payload.results
        }
      };

    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.ACCESSORY
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          accessories: [
            ...state.contract.accessories.map((accessory) =>
              accessory.id === action.payload.id ? action.payload : accessory
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.ACCESSORY
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          accessories: [
            ...state.contract.accessories.filter(
              (accessory) => accessory.id !== parseInt(action.payload.id)
            )
          ]
        }
      };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.ADDONS):
      return {
        ...state,
        contract: {
          ...state.contract,
          addons: action.payload.results
        }
      };

    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.ADDON):
      return {
        ...state,
        contract: {
          ...state.contract,
          addons: [
            ...state.contract.addons.map((addon) =>
              addon.id === action.payload.id ? action.payload : addon
            )
          ]
        }
      };

    case createSuccessActionType(RestStrings.DELETE, ReduxActionStrings.ADDON):
      return {
        ...state,
        contract: {
          ...state.contract,
          addons: [
            ...state.contract.addons.filter(
              (addon) => addon.id !== parseInt(action.payload.id)
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.SUPPLEMENTS
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          supplements: action.payload.results
        }
      };

    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.SUPPLEMENT
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          supplements: [
            ...state.contract.supplements.map((supplement) =>
              supplement.id === action.payload.id ? action.payload : supplement
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.SUPPLEMENT
    ):
      return {
        ...state,
        contract: {
          ...state.contract,
          supplements: [
            ...state.contract.supplements.filter(
              (supplement) => supplement.id !== parseInt(action.payload.id)
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
