import { Action, FetchWrapperState } from "../../types";
import { ReAuthCallbackAction } from "./actions";
import { REAUTH_CALLBACK } from "./actionTypes";

const initialState: FetchWrapperState = {
  socketReAuthCallback: () => {}
};

function reducer(
  state = initialState,
  action: Action<ReAuthCallbackAction>
): any {
  // TODO: Find right type for socketReAuthCallback
  switch (action.type) {
    case REAUTH_CALLBACK:
      return {
        ...state,
        socketReAuthCallback: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
