import React, { useEffect, useState, useRef } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  WithStyles
} from "@material-ui/core";
import { useTranslate } from "../../../../services/appLanguageService";
import {
  AddModificationRequestForm,
  Company,
  SelectFieldOption,
  Service,
  TabComponentProps,
  TimeSheet,
  TimeSheetModificationRequest,
  TimeSheetModificationRequestResponse
} from "../../../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  createTimeSheetModificationRequestAPI,
  getCompanyByIdAPI,
  getContractorContractTasksAPI,
  getRouteInstancesAPI,
  getServiceAPI,
  getServiceCategoryNamesAPI,
  getTimeSheetModificationRequestsAPI,
  getTimesheetByIdAPI,
  getTimesheetsAPI
} from "../../../../services/api-declaration";
import { BlurTextField } from "../BlurTextField";
import { Field, Formik, FormikProps } from "formik";
import { AutoCompleteSelect } from "../../../AutoCompleteSelect";
import {
  Edit as EditIcon,
  Done as DoneIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Map as MapIcon
} from "@material-ui/icons";
import {
  ConfirmationDialogServiceProvider,
  useConfirmationDialog
} from "../../../confirmationDialog/ConfirmationDialogServiceProvider";
import { Alert, AlertTitle } from "@material-ui/lab";
import { max } from "date-fns";
import { niceDate } from "../../../FormatHelpers";
import ModifyOngoingMapDialog from "./ModifyOngoingMapDialog";
import { format } from "date-fns";

const styles = () =>
  createStyles({
    paper: {
      marginTop: 20,
      padding: 20
    },
    optionButton: {
      marginRight: 20,
      minWidth: 100
    },
    participantTable: {
      marginTop: 20
    },
    assignmentsTable: {
      marginTop: 40
    },
    headerCell: {
      fontWeight: "bold"
    },
    addAssigmentButton: {
      textAlign: "right"
    },
    removedRow: {
      color: "#aaa"
    },
    buttons: {
      marginTop: 20
    },
    right: {
      textAlign: "right"
    }
  });

interface ModifyOngoingWorkProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"participantId"> {}

const ModifyOngoingWork: React.FC<ModifyOngoingWorkProps> = (props) => {
  const {
    classes,
    routeParams: { participantId }
  } = props;

  const t = useTranslate("ModifyOngoingWork");
  const [timesheet, setTimesheet] = useState<TimeSheet>();
  const [contractor, setContractor] = useState<Company>();
  const [taskOptions, setTaskOptions] = useState<SelectFieldOption[]>([]);
  const [serviceCategoryOptions, setServiceCategoryOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [service, setService] = useState<Service>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isAddingRow, setIsAddingRow] = useState<boolean>(false);
  const [editRowIndex, setEditRowIndex] = useState<number>(-1);
  const [modificationRequest, setModificationRequest] =
    useState<TimeSheetModificationRequest>({
      status: "NEW",
      payload: {
        update: [],
        add: [],
        remove: [],
        abort: false
      },
      timesheet: -1
    });
  const [createdModificationRequests, setCreatedModificationRequests] =
    useState<TimeSheetModificationRequestResponse[]>([]);
  const acknowledgedIntervalId = useRef<number>();
  const timesheetTimeoutId = useRef<number>();

  const [mapDialogData, setMapDialogData] = useState<{
    open: boolean;
    editable: boolean;
  }>({
    open: false,
    editable: false
  });

  const resetEditing = () => {
    setIsEditing(false);
    setIsAddingRow(false);
    setEditRowIndex(-1);
    setModificationRequest((mr) => ({
      ...mr,
      payload: {
        update: [],
        add: [],
        remove: [],
        abort: false
      }
    }));
  };

  const confirmationDialog = useConfirmationDialog();

  useEffect(() => {
    let alive = true;
    (async () => {
      if (participantId) {
        const routeInstanceResponse = await getRouteInstancesAPI({
          filter__participants: participantId
        });

        const _routeInstance = routeInstanceResponse.results[0];

        const participant = _routeInstance.participants.find(
          (participant) => participant.id === participantId
        );

        if (participant) {
          const [
            timesheetResponse,
            contractorResponse,
            taskOptionsResponse,
            serviceCategoriesResponse,
            serviceResponse
          ] = await Promise.all([
            getTimesheetsAPI({
              filter__routeinstance_id: _routeInstance.id
            }),
            getCompanyByIdAPI(participant.contractor),
            getContractorContractTasksAPI(participant.contractor),
            getServiceCategoryNamesAPI(),
            getServiceAPI(participant.service)
          ]);

          if (alive) {
            setContractor(contractorResponse);
            setTaskOptions(
              taskOptionsResponse.results
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((task) => ({
                  label: task.name,
                  value: task.id
                }))
            );
            setServiceCategoryOptions(
              serviceCategoriesResponse.results
                .filter((serviceCategory) =>
                  serviceResponse.categories.includes(serviceCategory.id)
                )
                .map((task) => ({
                  label: task.name,
                  value: task.id
                }))
            );
            setService(serviceResponse);

            const _timesheet = timesheetResponse.results.find(
              (_timesheet) => _timesheet.route_participant === participantId
            );

            if (_timesheet) {
              setTimesheet(_timesheet);
              setModificationRequest((mr) => ({
                ...mr,
                timesheet: _timesheet.id
              }));
            }
          }
        }
      }
    })();

    return () => {
      alive = false;
    };
  }, [participantId]);

  useEffect(() => {
    let alive = true;
    (async () => {
      if (timesheet?.id) {
        const response = await getTimeSheetModificationRequestsAPI(
          timesheet.id
        );

        if (alive) {
          setCreatedModificationRequests(response);
        }
      }
    })();

    return () => {
      alive = false;
    };
  }, [timesheet]);

  const handleMapDialog = () => {
    setMapDialogData({
      open: true,
      editable: true
    });
  };

  const handleClose = () =>
    setMapDialogData((_mapDialogData) => ({
      ..._mapDialogData,
      open: false
    }));

  useEffect(() => {
    let isAlive = true;
    const _notAcknowledgedRequests = createdModificationRequests.filter(
      (_modificationRequest) => _modificationRequest.status === "NEW"
    ).length;

    if (_notAcknowledgedRequests > 0 && !acknowledgedIntervalId.current) {
      acknowledgedIntervalId.current = window.setInterval(() => {
        if (timesheet?.id) {
          getTimeSheetModificationRequestsAPI(timesheet.id).then(
            (modsResponse) => {
              if (modsResponse.length === 0 && isAlive) {
                getTimesheetByIdAPI(timesheet.id).then((timsheetResponse) => {
                  if (isAlive) {
                    setTimesheet(timsheetResponse);
                    setCreatedModificationRequests(modsResponse);
                  }
                });
              }
            }
          );
        }
      }, 10000);
    }
    return () => {
      isAlive = false;
      if (acknowledgedIntervalId.current) {
        window.clearInterval(acknowledgedIntervalId.current);
        acknowledgedIntervalId.current = undefined;
      }
    };
  }, [createdModificationRequests, timesheet?.id]);

  useEffect(() => {
    if (timesheet?.id && !timesheetTimeoutId.current) {
      timesheetTimeoutId.current = window.setTimeout(() => {
        getTimesheetByIdAPI(timesheet.id).then((timsheetResponse) => {
          setTimesheet(timsheetResponse);
        });
      }, 30000);
    }
    return () => {
      if (timesheetTimeoutId.current) {
        window.clearTimeout(timesheetTimeoutId.current);
        timesheetTimeoutId.current = undefined;
      }
    };
  }, [timesheet]);

  let participantStatus: "ACCEPTED" | "NOT_ACCEPTED" | "ACTIVE" | "FINISHED";

  if (timesheet?.submitted) {
    participantStatus = "FINISHED";
  } else if (timesheet?.active) {
    participantStatus = "ACTIVE";
  } else if (timesheet?.accepted) {
    participantStatus = "ACCEPTED";
  } else {
    participantStatus = "NOT_ACCEPTED";
  }

  const abortRequest = async (timesheetId: number) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("abortRequestDescription")
    });
    const response = await createTimeSheetModificationRequestAPI({
      status: "NEW",
      payload: {
        update: [],
        add: [],
        remove: [],
        abort: true
      },
      timesheet: timesheetId
    });
    setCreatedModificationRequests((_modificationRequests) => [
      ..._modificationRequests,
      response
    ]);
    resetEditing();
  };

  const dispatchRequest = async () => {
    if (modificationRequest.timesheet !== -1) {
      await confirmationDialog({
        title: t("confirmationTitle"),
        description: t("dispatchRequestDescription")
      });
      const response = await createTimeSheetModificationRequestAPI(
        modificationRequest
      );

      const timesheetResponse = await getTimesheetByIdAPI(
        modificationRequest.timesheet
      );

      setCreatedModificationRequests((_modificationRequests) => [
        ..._modificationRequests,
        response
      ]);
      setTimesheet(timesheetResponse);
      resetEditing();
    }
  };

  const addInitialValues: AddModificationRequestForm = {
    task: -1,
    category: -1,
    instructions: null,
    geo_polygons: null
  };

  const startAddingRow = (
    formikProps: FormikProps<AddModificationRequestForm>
  ) => {
    setEditRowIndex(-1);
    setIsAddingRow(true);
    formikProps.resetForm();

    if (modificationRequest.payload.add.length > 0) {
      const servicecategoryId =
        modificationRequest.payload.add[
          modificationRequest.payload.add.length - 1
        ]?.category;

      if (servicecategoryId) {
        formikProps.setFieldValue("category", servicecategoryId);
      }
    } else if (timesheet && timesheet.rows.length > 0) {
      const servicecategoryId = service?.categories[0];

      if (servicecategoryId) {
        formikProps.setFieldValue("category", servicecategoryId);
      }
    }
  };

  const addRow = (formikProps: FormikProps<AddModificationRequestForm>) =>
    setModificationRequest((_modificationRequest) => ({
      ..._modificationRequest,
      payload: {
        ..._modificationRequest.payload,
        add: [
          ..._modificationRequest.payload.add,
          {
            task: formikProps.values.task,
            category: formikProps.values.category,
            instructions: formikProps.values.instructions || "",
            geo_polygons: formikProps.values.geo_polygons || null
          }
        ]
      }
    }));

  const updateRow = (
    formikProps: FormikProps<AddModificationRequestForm>,
    row_id: number
  ) =>
    setModificationRequest((_modificationRequest) => ({
      ..._modificationRequest,
      payload: {
        ..._modificationRequest.payload,
        update: [
          ..._modificationRequest.payload.update.filter(
            (updatedRow) => updatedRow.row_id !== row_id
          ),
          {
            instructions: formikProps.values.instructions,
            category: formikProps.values.category,
            row_id: row_id
          }
        ]
      }
    }));

  const removeRow = (row_id: number, isRemoved: boolean) =>
    setModificationRequest((_modificationRequest) => ({
      ..._modificationRequest,
      payload: {
        ..._modificationRequest.payload,
        remove: isRemoved
          ? _modificationRequest.payload.remove.filter(
              (removedRow) => removedRow !== row_id
            )
          : [..._modificationRequest.payload.remove, row_id]
      }
    }));

  const removeAddedRow = (removedIndex: number) =>
    setModificationRequest((_modificationRequest) => ({
      ..._modificationRequest,
      payload: {
        ..._modificationRequest.payload,
        add: _modificationRequest.payload.add.filter(
          (addedRow, index) => index !== removedIndex
        )
      }
    }));

  const cancelEdit = async () => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("confirmationDescription")
    });
    resetEditing();
  };

  const notAcknowledgedRequests = createdModificationRequests.filter(
    (_modificationRequest) => _modificationRequest.status === "NEW"
  );

  const isNotAcknowledged = notAcknowledgedRequests.length > 0;

  const latestCreated = max(
    notAcknowledgedRequests.map((data) => new Date(data.updated_at))
  );
  const abortedRequest =
    createdModificationRequests.filter(
      (_modificationRequest) => _modificationRequest.payload.abort
    ).length > 0;

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <Button
              variant="contained"
              color="primary"
              component={React.forwardRef<
                HTMLAnchorElement,
                Partial<LinkProps>
              >((props, ref) => (
                <Link
                  to="/orders/routeinstances/"
                  {...props}
                  ref={ref as any}
                />
              ))}
            >
              <NavigateBeforeIcon /> {t("backLabel")}
            </Button>
          </Grid>
          {!isEditing && (
            <Grid item xs={6} sm={9} className={classes.right}>
              <Button
                className={classes.optionButton}
                variant="contained"
                color="primary"
                disabled
              >
                {t("copyLabel")}
              </Button>
              <Button
                className={classes.optionButton}
                disabled={
                  !timesheet ||
                  isNotAcknowledged ||
                  abortedRequest ||
                  participantStatus === "FINISHED"
                }
                variant="contained"
                color="primary"
                onClick={() => setIsEditing(true)}
              >
                {t("editLabel")}
              </Button>
              <Button
                className={classes.optionButton}
                disabled={
                  !timesheet ||
                  isNotAcknowledged ||
                  abortedRequest ||
                  participantStatus === "FINISHED"
                }
                variant="contained"
                color="primary"
                onClick={() => {
                  if (timesheet?.id) {
                    abortRequest(timesheet?.id);
                  }
                }}
              >
                {t("abortLabel")}
              </Button>
            </Grid>
          )}
        </Grid>
        {isNotAcknowledged && !abortedRequest && (
          <Alert severity="warning" className={classes.paper}>
            <AlertTitle>{t("notAcknowledgedTitle")}</AlertTitle>
            <Typography>{`${t("editDisabledText")} ${niceDate(
              latestCreated.toISOString()
            )}`}</Typography>
          </Alert>
        )}
        {abortedRequest && (
          <Alert severity="error" className={classes.paper}>
            <AlertTitle>{t("abortedRequestTitle")}</AlertTitle>
            <Typography>{t("abortedRequestText")}</Typography>
          </Alert>
        )}
        <Table className={classes.participantTable}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>
                {t("participantLabel")}
              </TableCell>
              <TableCell className={classes.headerCell}>
                {t("driverLabel")}
              </TableCell>
              <TableCell className={classes.headerCell}>
                {t("serviceLabel")}
              </TableCell>
              <TableCell className={classes.headerCell}>
                {t("statusLabel")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{contractor?.name}</TableCell>
              <TableCell>{timesheet?.person_name}</TableCell>
              <TableCell>{service?.name}</TableCell>
              <TableCell>{participantStatus}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Formik
          initialValues={addInitialValues}
          onSubmit={(values, actions) => {}}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} autoComplete="off">
              <Table className={classes.assignmentsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className={classes.headerCell}>
                      {t("taskLabel")}
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                      {t("startTimeLabel")}
                    </TableCell>{" "}
                    <TableCell className={classes.headerCell}>
                      {t("endTimeLabel")}
                    </TableCell>
                    <TableCell className={classes.headerCell}>Status</TableCell>
                    <TableCell className={classes.headerCell}>
                      {t("instructionsLabel")}
                    </TableCell>
                    {isEditing && (
                      <>
                        <TableCell>{t("actionsLabel")}</TableCell>
                        <TableCell>{t("removeLabel")}</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {timesheet?.rows.map((row, index) => {
                    const task = taskOptions.find(
                      (task) => task.value === row.task
                    );

                    let rowStatus: "STARTED" | "FINISHED" | "NOT_STARTED" =
                      "NOT_STARTED";

                    if (row.end_time) {
                      rowStatus = "FINISHED";
                    } else if (row.start_time) {
                      rowStatus = "STARTED";
                    }

                    const locked =
                      rowStatus !== "NOT_STARTED" || isNotAcknowledged;

                    const editedRow = [
                      ...modificationRequest.payload.update,
                      ...notAcknowledgedRequests.flatMap(
                        (
                          notAcknowledgedRequest: TimeSheetModificationRequest
                        ) => notAcknowledgedRequest.payload.update
                      )
                    ].find((updatedRow) => updatedRow.row_id === row.row_id);

                    const serviceCategoryName =
                      serviceCategoryOptions.find((serviceCategoryOption) =>
                        editedRow &&
                        editedRow?.category !==
                          row.expected_service_categories[0]
                          ? editedRow?.category === serviceCategoryOption.value
                          : row.expected_service_categories[0] ===
                            serviceCategoryOption.value
                      )?.label || "";

                    const isPermantlyRemoved =
                      typeof row.row_id !== "undefined" &&
                      notAcknowledgedRequests
                        .flatMap(
                          (
                            notAcknowledgedRequest: TimeSheetModificationRequest
                          ) => notAcknowledgedRequest.payload.remove
                        )
                        .includes(row.row_id);

                    const isRemoved =
                      typeof row.row_id !== "undefined" &&
                      modificationRequest.payload.remove.includes(row.row_id);

                    return editRowIndex === index ? (
                      <TableRow key={`${timesheet.id}_${row.row_id}`}>
                        <TableCell></TableCell>
                        <TableCell>
                          {task?.label} <br />
                          <Typography variant="caption">
                            <Field
                              type="text"
                              name={`category`}
                              label={t("serviceCategoryLabel")}
                              placeholder={t("serviceCategoryLabel")}
                              options={serviceCategoryOptions}
                              component={AutoCompleteSelect}
                              fullWidth
                            />
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {row.start_time
                            ? format(
                                new Date(row.start_time),
                                "dd/MM, HH:mm"
                              )
                            : "..."}
                        </TableCell>
                        <TableCell>
                          {row.end_time
                            ? format(new Date(row.end_time), "dd/MM, HH:mm")
                            : "..."}
                        </TableCell>
                        <TableCell>{rowStatus}</TableCell>
                        <TableCell>
                          <BlurTextField
                            id="instructions"
                            name="instructions"
                            label={t("instructionsLabel")}
                            placeholder={t("instructionsLabel")}
                            fullWidth
                          />
                        </TableCell>
                        {isEditing && (
                          <>
                            <TableCell colSpan={2}>
                              <IconButton
                                size="small"
                                disabled={!row.row_id}
                                aria-label="edit"
                                onClick={() => {
                                  if (row.row_id) {
                                    setEditRowIndex(-1);
                                    updateRow(formikProps, row.row_id);
                                  }
                                }}
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                aria-label="close"
                                onClick={() => setEditRowIndex(-1)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ) : (
                      // A row that is locked or editable (if in edit mode and not locked)
                      <TableRow key={`${timesheet.id}_${row.row_id}`}>
                        <TableCell>
                          {isPermantlyRemoved && (
                            <Tooltip title={t("removedTooltip")}>
                              <RemoveIcon className={classes.removedRow} />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          className={
                            isPermantlyRemoved ? classes.removedRow : ""
                          }
                        >
                          {task?.label}
                          <br />
                          <Typography variant="caption">
                            {serviceCategoryName ? serviceCategoryName : "---"}{" "}
                          </Typography>
                        </TableCell>

                        <TableCell
                          className={
                            isPermantlyRemoved ? classes.removedRow : ""
                          }
                        >
                          {row.start_time
                            ? format(
                                new Date(row.start_time),
                                "dd/MM, HH:mm"
                              )
                            : "..."}
                        </TableCell>

                        <TableCell
                          className={
                            isPermantlyRemoved ? classes.removedRow : ""
                          }
                        >
                          {row.end_time
                            ? format(new Date(row.end_time), "dd/MM, HH:mm")
                            : "..."}
                        </TableCell>
                        <TableCell
                          className={
                            isPermantlyRemoved ? classes.removedRow : ""
                          }
                        >
                          {rowStatus}
                        </TableCell>
                        <TableCell
                          className={
                            isPermantlyRemoved ? classes.removedRow : ""
                          }
                        >
                          {row.row_id &&
                          editedRow?.instructions &&
                          editedRow.instructions !== row.instructions ? (
                            <>
                              <Tooltip title={t("editedTooltip")}>
                                <EditIcon style={{ fontSize: 14 }} />
                              </Tooltip>{" "}
                              {editedRow.instructions}
                            </>
                          ) : (
                            row.instructions
                          )}
                        </TableCell>
                        {!locked && isEditing ? (
                          <>
                            <TableCell>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setIsAddingRow(false);
                                  setEditRowIndex(index);
                                  formikProps.setFieldValue(
                                    "instructions",
                                    editedRow
                                      ? editedRow.instructions
                                      : row.instructions
                                  );
                                  formikProps.setFieldValue(
                                    "category",
                                    editedRow
                                      ? editedRow.category
                                      : row.expected_service_categories[0]
                                  );
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                size="small"
                                checked={isRemoved}
                                onChange={(event: any) =>
                                  row.row_id && removeRow(row.row_id, isRemoved)
                                }
                              />
                            </TableCell>
                          </>
                        ) : (
                          <TableCell colSpan={2}></TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                  {notAcknowledgedRequests.map((acknowledgedRequest) =>
                    acknowledgedRequest.payload.add.map((addedRow) => {
                      // A row that is locked and added but not acknowledged
                      const task = taskOptions.find(
                        (task) => task.value === addedRow.task
                      );

                      const serviceCategoryName =
                        serviceCategoryOptions.find(
                          (serviceCategoryOption) =>
                            addedRow.category === serviceCategoryOption.value
                        )?.label || "";

                      return (
                        <TableRow>
                          <TableCell>
                            <Tooltip title={t("addedTooltip")}>
                              <AddIcon />
                            </Tooltip>
                          </TableCell>
                          <TableCell>{task?.label}</TableCell>
                          <TableCell>{serviceCategoryName}</TableCell>
                          <TableCell>---</TableCell>
                          <TableCell>{addedRow.instructions}</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      );
                    })
                  )}
                  {modificationRequest.payload.add.map((addedRow, index) => {
                    const task = taskOptions.find(
                      (task) => task.value === addedRow.task
                    );

                    const serviceCategoryName =
                      serviceCategoryOptions.find(
                        (serviceCategoryOption) =>
                          addedRow.category === serviceCategoryOption.value
                      )?.label || "";

                    return (
                      // A row that is added and removable
                      <TableRow>
                        <TableCell>
                          <Tooltip title={t("addedTooltip")}>
                            <AddIcon />
                          </Tooltip>
                        </TableCell>
                        <TableCell>{task?.label}</TableCell>
                        <TableCell>{serviceCategoryName}</TableCell>
                        <TableCell>---</TableCell>
                        <TableCell>{addedRow.instructions}</TableCell>
                        <TableCell>
                          {isEditing && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => removeAddedRow(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    );
                  })}
                  {isAddingRow && (
                    // Adding a new row
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <Field
                          type="text"
                          name={`task`}
                          label={t("taskLabel")}
                          placeholder={t("taskLabel")}
                          options={taskOptions}
                          component={AutoCompleteSelect}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          type="text"
                          name={`category`}
                          label={t("serviceCategoryLabel")}
                          placeholder={t("serviceCategoryLabel")}
                          options={serviceCategoryOptions}
                          component={AutoCompleteSelect}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>---</TableCell>
                      <TableCell>
                        <BlurTextField
                          id="instructions"
                          name="instructions"
                          label={t("instructionsLabel")}
                          placeholder={t("instructionsLabel")}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell colSpan={2}>
                        <IconButton onClick={() => handleMapDialog()}>
                          <MapIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          aria-label="edit"
                          onClick={() => {
                            addRow(formikProps);
                            setIsAddingRow(false);
                          }}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          aria-label="close"
                          onClick={() => setIsAddingRow(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                  {isEditing && !isAddingRow && (
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        className={classes.addAssigmentButton}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => startAddingRow(formikProps)}
                        >
                          {t("addAssignmentLabel")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ModifyOngoingMapDialog
                handleClose={handleClose}
                mapDialogData={mapDialogData}
                formikProps={formikProps}
              />
            </form>
          )}
        </Formik>
      </Paper>
      <Grid container spacing={3} className={classes.buttons}>
        {isEditing && (
          <>
            <Grid item xs={4} sm={4}>
              <Button
                fullWidth
                className={classes.optionButton}
                variant="contained"
                color="primary"
                onClick={cancelEdit}
              >
                {t("cancelLabel")}
              </Button>
            </Grid>
            <Grid item xs={8} sm={8}>
              <Button
                fullWidth
                className={classes.optionButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatchRequest();
                }}
              >
                {t("dispatchLabel")}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles)((props: ModifyOngoingWorkProps) => (
  <ConfirmationDialogServiceProvider>
    <ModifyOngoingWork {...props} />
  </ConfirmationDialogServiceProvider>
));
