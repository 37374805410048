import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { useTranslate } from "../../../services/appLanguageService";
import {
  IContractSummaryItem,
  IContractSummaryType
} from "../../../redux/types";
import { Link as RouterLink } from "react-router-dom";
import { contractItemTypeToApi } from "./contractItemTypeToAPI";
import TaskPricesIndicator from "./TaskPricesIndicator";
import {
  ArrowDownward,
  ArrowUpward,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "20px"
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    },
    table: {
      "& tr": {
        fontWeight: "bold"
      }
    },
    taskPriceInidicatorContainer: {
      textAlign: "right"
    },
    gray: {
      color: "#ddd"
    },
    headerCell: {
      fontWeight: "bold",
      cursor: "pointer",
      padding: 6,
      "&:last-child": {
        paddingRight: 10
      },
      "&:first-child": {
        paddingLeft: 15
      },
      userSelect: "none",
      "-webkit-user-select": "none"
    },
    firstCell: {
      paddingLeft: 15
    },
    smallIcon: {
      fontSize: "1rem"
    }
  })
);

type ContractItemsProps = {
  contractId: number;
  header: React.ReactNode;
  contractItems?: IContractSummaryItem[];
  serviceCategoryNames: Map<number, string>;
  taskSpecificPrices?: {
    [itemId: number]: {
      businessareaName: string;
      taskName: string;
      price: number;
      unit: string;
      serviceCategoryName?: string;
    }[];
  };
  onDelete: (
    contractItemId: number,
    type: IContractSummaryType
  ) => Promise<void>;
  updateVisibility: (id: number, type: IContractSummaryType) => Promise<void>;
};

type COLUMNTYPE =
  | "name"
  | "service_category_id"
  | "type"
  | "base_name"
  | "price"
  | "display";

const ContractItemsTable: React.FC<ContractItemsProps> = ({
  contractId,
  header,
  contractItems,
  serviceCategoryNames,
  taskSpecificPrices,
  onDelete,
  updateVisibility
}) => {
  const [loadingItem, setLoadingItem] = useState<{
    action: "delete" | "display";
    id: number;
    type: IContractSummaryType;
  }>();
  const [columnSorted, setColumnSorted] = useState<COLUMNTYPE>("name");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "default">(
    "default"
  );
  const classes = useStyles();

  const t = useTranslate("ContractPage");

  const handleDelete = (id: number, type: IContractSummaryType) => {
    setLoadingItem({ action: "delete", id, type });
    onDelete(id, type).finally(() => setLoadingItem(undefined));
  };

  const handleUpdateVisibility = (id: number, type: IContractSummaryType) => {
    setLoadingItem({ action: "display", id, type });
    updateVisibility(id, type).finally(() => setLoadingItem(undefined));
  };

  const columnHeaders: { field: COLUMNTYPE; label: string }[] = [
    {
      field: "name",
      label: t("nameLabel")
    },
    {
      field: "service_category_id",
      label: t("servicecategoriesLabel")
    },
    {
      field: "type",
      label: t("typeLabel")
    },
    {
      field: "base_name",
      label: t("baseLabel")
    },
    {
      field: "price",
      label: t("priceLabel")
    },
    {
      field: "display",
      label: t("visibleLabel")
    }
  ];

  const sortTable = (a: IContractSummaryItem, b: IContractSummaryItem) => {
    if (sortOrder !== "default") {
      let x;
      let y;

      if (typeof a[columnSorted] === "string") {
        x = (a[columnSorted] as string)?.toLowerCase();
        y = (b[columnSorted] as string)?.toLowerCase();
      } else if (typeof a[columnSorted] === "number") {
        x = a[columnSorted] as number;
        y = b[columnSorted] as number;
      } else if (typeof a[columnSorted] === "boolean") {
        x = !a[columnSorted] as boolean;
        y = !b[columnSorted] as boolean;
      }
      if (typeof x === "undefined" || typeof y === "undefined") {
        return 0;
      }

      if (x < y) {
        return sortOrder === "asc" ? -1 : 1;
      } else if (x > y) {
        return sortOrder === "asc" ? 1 : -1;
      }
    }
    return 0;
  };

  const changeSorting = (field: COLUMNTYPE) => {
    if (field === columnSorted) {
      if (sortOrder === "default") {
        setSortOrder("asc");
      } else if (sortOrder === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("default");
      }
    } else {
      setSortOrder("asc");
      setColumnSorted(field);
    }
  };

  const generateSortIcon = () => {
    if (sortOrder === "asc") {
      return <ArrowUpward className={classes.smallIcon} />;
    } else if (sortOrder === "desc") {
      return <ArrowDownward className={classes.smallIcon} />;
    }
    return <></>;
  };

  const contractItemsCopy = contractItems
    ? [...contractItems].sort(sortTable)
    : [];

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>{header}</div>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {columnHeaders.map((column) => (
              <TableCell
                key={column.label}
                className={classes.headerCell}
                onClick={() => changeSorting(column.field)}
              >
                {column.label}{" "}
                {column.field === columnSorted && generateSortIcon()}
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contractItemsCopy?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5}>{t("emptyTableText")}</TableCell>
            </TableRow>
          ) : undefined}
          {contractItemsCopy.map((item) => (
            <TableRow key={`${item.type}_${item.id}`}>
              <TableCell className={classes.firstCell}>
                <Link
                  component={RouterLink}
                  to={`/contracts/${contractId}/${
                    contractItemTypeToApi[item.type]
                  }/${item.id}`}
                >
                  {item.name}
                </Link>
              </TableCell>
              <TableCell>
                {item.service_category_id === null
                  ? t("allLabel")
                  : serviceCategoryNames.get(item.service_category_id)}
              </TableCell>

              <TableCell>{item.type}</TableCell>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/contracts/base${contractItemTypeToApi[item.type]}/${
                    item.base_id
                  }`}
                >
                  {item.base_name}
                </Link>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={9} sm={9}>
                    {item.price} kr/
                    {item.unit}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    className={classes.taskPriceInidicatorContainer}
                  >
                    {taskSpecificPrices && taskSpecificPrices[item.id] && (
                      <TaskPricesIndicator
                        taskPrices={taskSpecificPrices[item.id]}
                      />
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <IconButton
                  size="small"
                  disabled={
                    loadingItem?.action === "delete" &&
                    item.id === loadingItem?.id &&
                    item.type === loadingItem?.type
                  }
                  onClick={() => handleUpdateVisibility(item.id, item.type)}
                >
                  {loadingItem?.action === "display" &&
                  item.id === loadingItem?.id &&
                  item.type === loadingItem?.type ? (
                    <CircularProgress size="1em" />
                  ) : item.display ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon className={classes.gray} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  size="small"
                  disabled={
                    loadingItem?.action === "delete" &&
                    item.id === loadingItem?.id &&
                    item.type === loadingItem?.type
                  }
                  onClick={() => void handleDelete(item.id, item.type)}
                >
                  {loadingItem?.action === "delete" &&
                  item.id === loadingItem?.id &&
                  item.type === loadingItem?.type ? (
                    <CircularProgress size="1em" />
                  ) : (
                    <Delete />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ContractItemsTable;
