import { useState, useEffect, FC, Fragment } from "react";
import clsx from "clsx";
import {
  Table,
  Collapse,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  IApprovalDictionaries,
  IApprovalSubmittedRow,
  IApprovalSummary,
  IApprovalSummaryDetails,
  IOrderPopupData,
  RouteInstanceStatus,
  SummaryViewMode
} from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import TimesheetApprovalDetails from "./TimesheetApprovalDetails";
import {
  constructApprovalSummaryDetails,
  sumTotalTime
} from "../../helpers/timesheetApprovalHelper";
import { formatDate, getTimeFromDate } from "../FormatHelpers";
import OrderPopup from "../routes/orderPopup/OrderPopup";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    flexDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    boldRow: {
      "& > *": {
        color: "#000",
        fontWeight: "bold"
      }
    },
    expandedRow: {
      "& > *": {
        color: "#000",
        fontWeight: "bold",
        background: "#e3e3e3"
      }
    },
    cellPadRow: {
      "& > *": { padding: "6px" }
    },
    red: {
      color: "red"
    },
    root: {
      "& > *": {
        position: "relative",
        borderBottom: "unset"
      }
    },
    imageIcon: {
      width: "30px",
      height: "30px",
      marginTop: theme.spacing(1)
    },
    flexBox: {
      display: "flex",
      alignItems: "center"
    },
    bubbleCount: {
      color: "#fff",
      padding: `0 5px`,
      fontWeight: "bold",
      borderRadius: "50px",
      background: "#f50057",
      fontSize: theme.spacing(1.5),
      marginLeft: theme.spacing(0.5)
    },
    hasStatusIcon: {
      marginRight: theme.spacing(0.5)
    },
    table: { minWidth: 750 },
    expandButton: { width: theme.spacing(2) },
    pointer: { cursor: "pointer" },
    scrollHelperDummy: {
      position: "relative",
      top: -100,
      left: -theme.spacing(2)
    }
  })
);
interface TimesheetApprovalProps {
  approvalSummaries: IApprovalSummary[];
  dictionaries: IApprovalDictionaries;
  defaultExpanded: IApprovalSummary | undefined;
  submitRowDeleted: (routeInstance_id: number) => void;
  submitRowModified: (routeInstance_id: number, submit_id: number) => void;
  updateApprovalSelection: () => void;
  clearApprovalSelection: (routeInstance_id: number) => void;
  SummaryViewMode: SummaryViewMode;
  toggleSummaryRowDetails?: (row: IApprovalSubmittedRow) => void;
  businessAreaNames: Map<number, string>;
}
interface IExpandedSummaryDetails {
  routeinstance_id: number;
  summaryDetails: IApprovalSummaryDetails;
}
const TimesheetApprovals: FC<TimesheetApprovalProps> = (props) => {
  const {
    approvalSummaries,
    dictionaries,
    defaultExpanded,
    submitRowDeleted,
    SummaryViewMode,
    submitRowModified,
    updateApprovalSelection,
    clearApprovalSelection,
    toggleSummaryRowDetails,
    businessAreaNames
  } = props;
  const classes = useStyles();
  const instanceStatusColors: { [key in RouteInstanceStatus]: string } = {
    CANCELED: "#9c9c9c",
    DRAFT: "#9c9c9c",
    FULLY_ACCEPTED: "#e69500",
    IN_PROGRESS: "#c5c527",
    PARTLY_ACCEPTED: "#e6c000",
    PARTLY_DECLINED: "#fc3503",
    PARTLY_STARTED: "#c5c527",
    RETROSPECTIVE: "#9c9c9c",
    SUBMITTED: "#008000",
    WAITING_FOR_ACCEPT: "#d60000",
    COMPLETED: "#0f0980"
  };
  const t = useTranslate("TimesheetApprovalPage");
  const t2 = useTranslate("RouteInstanceEnums");
  const [expandedRoute, setExpandedRoute] = useState<IExpandedSummaryDetails>({
    routeinstance_id: -1
  } as IExpandedSummaryDetails);

  const [popupMeta, setPopupMeta] = useState<IOrderPopupData[]>();
  const [highlightedRowIndex, setHighlightedRowIndex] = useState(-1);

  const handleRowExpansion = (
    shouldOpen: boolean,
    approvalSummary: IApprovalSummary
  ) => {
    setPopupMeta(undefined);
    setHighlightedRowIndex(-1);
    if (shouldOpen) {
      const summaryDetails = constructApprovalSummaryDetails(
        true,
        approvalSummary
      );

      if (expandedRoute) {
        clearApprovalSelection(expandedRoute.routeinstance_id);
      }
      setExpandedRoute({
        routeinstance_id: summaryDetails.routeinstance_id,
        summaryDetails
      });
    } else {
      clearApprovalSelection(approvalSummary.routeinstance_id);
      setExpandedRoute(({ summaryDetails }) => ({
        routeinstance_id: -1,
        summaryDetails
      }));
    }
  };
  const handleDocumentationPopup = (
    routeinstance_id: number,
    submit_id: number
  ) => {
    const [, taskDictionary] = dictionaries;
    const allRows =
      approvalSummaries
        .find((s) => s.routeinstance_id === routeinstance_id)
        ?.routeInstanceSummary.timesheets.flatMap((t) =>
          t.submitted.concat(t.work_in_progress).map((s) => [t, s] as const)
        ) ?? [];
    setPopupMeta(
      allRows.map(([sheet, row]) => {
        const task = taskDictionary.get(row.task);
        const meta: IOrderPopupData = {
          title: `${t("documentationPopupTitle")} ${task?.name ?? "n/a"}`,
          contractor_name: `${sheet.contractor_name}, ${row.service_name}`,
          driver_name: sheet.person_name ?? "n/a",
          subTitle: row.notes,
          geo_polygons: row.geo_polygons || task?.geo_polygons,
          images: row.images,
          imageIndex: 0,
          contentText: row.notes ?? "",
          timesheet_id: sheet.timesheet_id,
          accessories: row.accessories,
          contentType: "IMAGE",
          submit_id: row.submit_id
        };

        return meta;
      })
    );
    setHighlightedRowIndex(
      allRows.findIndex(([, row]) => row.submit_id === submit_id)
    );
  };

  useEffect(() => {
    if (defaultExpanded) {
      handleRowExpansion(true, defaultExpanded);
    } else if (SummaryViewMode === "DIALOG" && approvalSummaries.length === 1) {
      handleRowExpansion(true, approvalSummaries[0]); // expand summary dialog by default
    }
    // since need to use expandedRoute and set expanded route
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExpanded]);

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={clsx(classes.boldRow, classes.cellPadRow)}>
            <TableCell></TableCell>
            <TableCell>{t("titleHeading")}</TableCell>
            <TableCell>{t("classesObject")}</TableCell>
            <TableCell>{t("operators")}</TableCell>
            <TableCell>{t("startDateTimeHeading")}</TableCell>
            <TableCell>{t("totalTimeHeading")}</TableCell>
            <TableCell>{t("servCat")}</TableCell>
            {SummaryViewMode !== "DIALOG" && (
              <>
                <TableCell>{t("statusHeading")}</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {approvalSummaries.length === 0 ? (
            <TableRow>
              <TableCell colSpan={10}>{t("noItemsText")}</TableCell>
            </TableRow>
          ) : (
            approvalSummaries.map((approvalSummary) => {
              const isSummaryExpanded =
                expandedRoute.routeinstance_id ===
                approvalSummary.routeinstance_id;
              const totalTime =
                SummaryViewMode === "DIALOG"
                  ? sumTotalTime(
                      approvalSummary.summaryRows
                        .flatMap(({ rows }) => rows)
                        .map(({ readables }) => readables)
                    )
                  : approvalSummary.total_time;
              return (
                <Fragment key={approvalSummary.routeinstance_id}>
                  <TableRow
                    className={clsx(
                      classes.root,
                      classes.cellPadRow,
                      isSummaryExpanded && classes.expandedRow,
                      classes.pointer
                    )}
                    onClick={() =>
                      handleRowExpansion(!isSummaryExpanded, approvalSummary)
                    }
                  >
                    <TableCell className={clsx(classes.expandButton)}>
                      <IconButton aria-label="expand row" size="small">
                        {isSummaryExpanded ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">
                      <div>
                        <div className={classes.flexBox}>
                          <div
                            style={{ flex: 1 }}
                            className={clsx(
                              SummaryViewMode === "VERIFICATION" &&
                                approvalSummary.hasConflict &&
                                classes.red
                            )}
                          >
                            {approvalSummary.routeinstance_title}
                          </div>
                          {SummaryViewMode === "VERIFICATION" && (
                            <>
                              {approvalSummary.hasConflict && (
                                <MergeTypeIcon
                                  className={clsx(
                                    classes.hasStatusIcon,
                                    classes.red
                                  )}
                                />
                              )}
                              {approvalSummary.submitted_unapproved_count >
                                0 && (
                                <div className={classes.bubbleCount}>
                                  {approvalSummary.submitted_unapproved_count}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className={classes.flexDiv}></div>
                      </div>
                    </TableCell>
                    <TableCell>{approvalSummary.tasksCount}</TableCell>
                    <TableCell>{approvalSummary.participants.size}</TableCell>
                    <TableCell>
                      {approvalSummary.start_time ? (
                        <>
                          {getTimeFromDate(approvalSummary.start_time)}
                          <br />
                          {formatDate(approvalSummary.start_time)}
                        </>
                      ) : (
                        "---"
                      )}
                    </TableCell>
                    <TableCell>{totalTime}</TableCell>
                    <TableCell>
                      {approvalSummary.serviceCategories.length !== 0
                        ? approvalSummary.serviceCategories.map(
                            ({ id, name }) => <div key={id}>{name}</div>
                          )
                        : "---"}
                    </TableCell>
                    {SummaryViewMode !== "DIALOG" && (
                      <>
                        {/* <TableCell>
                        {`${approvalSummary.unSubmittedCount}/${approvalSummary.rowsCount}`}
                      </TableCell> */}
                        <TableCell
                          style={{
                            color:
                              instanceStatusColors[
                                approvalSummary.routeinstance_status
                              ]
                          }}
                        >
                          {t2(approvalSummary.routeinstance_status)}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow className={classes.root}>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={10}
                    >
                      <Collapse
                        in={isSummaryExpanded}
                        timeout="auto"
                        unmountOnExit
                      >
                        <TimesheetApprovalDetails
                          summaryDetails={expandedRoute.summaryDetails}
                          dictionaries={dictionaries}
                          submitRowDeleted={submitRowDeleted}
                          submitRowModified={submitRowModified}
                          updateApprovalSelection={updateApprovalSelection}
                          SummaryViewMode={SummaryViewMode}
                          toggleSummaryRowDetails={toggleSummaryRowDetails}
                          approvalSummary={approvalSummary}
                          openDocumentationPopup={handleDocumentationPopup}
                          businessAreaNames={businessAreaNames}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })
          )}
        </TableBody>
      </Table>
      {popupMeta && (
        <OrderPopup
          metaIndex={highlightedRowIndex}
          meta={popupMeta}
          dictionaries={dictionaries}
          handleClose={() => {
            setPopupMeta(undefined);
            setHighlightedRowIndex(-1);
          }}
          handleRowNavigation={(rowIndex) => setHighlightedRowIndex(rowIndex)}
        />
      )}
    </>
  );
};

export default TimesheetApprovals;
