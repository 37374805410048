import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Link,
  Table,
  Paper,
  Avatar,
  Collapse,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  CircularProgress,
  Typography,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core/styles";
import {
  Task,
  ICompany,
  IRowImage,
  BaseService,
  RouteInstance,
  IReportedImage,
  IReportedCommentsAndImages
} from "../../../redux/types";
import {
  getTaskByIdsAPI,
  getBaseServicesByIdsAPI,
  getContractorCompaniesAPI,
  getReportedCommentsAndImagesAPI
} from "../../../services/api-declaration";
import RowImageDialog from "./RowImageDialog";
import GeoPolygonBuilderDialog from "./GeoPolygonBuilderDialog";
import { getImageByURL } from "../../../services/imageHelperService";
import { translate, useTranslate } from "../../../services/appLanguageService";
import clsx from "clsx";
import Image from "@material-ui/icons/Image";
import MapIcon from "@material-ui/icons/Map";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { applySequence } from "../../../helpers/globalHelper";
import { ReportedImageSequence } from "../../../strings/ReportedImageStrings";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    notes: {
      position: "relative",
      borderLeft: "1px solid",
      padding: theme.spacing(1),
      margin: `0 0 0 ${theme.spacing(1.25)}px`
    },
    nestedCell: {
      padding: 0,
      border: "none",
      display: "flex",
      textAlign: "center"
    },
    pl0: {
      textDecoration: "none",
      paddingLeft: "0 !important"
    },
    centeredDiv: {
      textAlign: "center",
      padding: `${theme.spacing(1)}px 0`
    },
    nestedcells: {
      border: "none",
      padding: `${theme.spacing(1)}px 0 0 0`
    },
    headers: {
      border: "none",
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      borderBottom: "1px solid #80808047"
    },
    suppliers: {
      position: "relative",
      borderLeft: "1px solid",
      marginLeft: theme.spacing(1.25),
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(2.5)}px ${theme.spacing(
        3
      )}px`
    },
    unActiveRow: {
      textDecoration: "none",
      backgroundColor: "transparent"
    },
    horizontalDivider: {
      left: 0,
      height: "1px",
      position: "absolute",
      backgroundColor: "#424242"
    },
    bold: {
      fontWeight: "bold",
      padding: `${theme.spacing(1)}px 0`
    },
    paper: {
      overflow: "scroll",
      maxHeight: "250px",
      padding: theme.spacing(2),
      margin: `0 0 ${theme.spacing(2)}px 0`
    },
    section: {
      paddingBottom: theme.spacing(1),
      borderBottom: "1px solid #757575"
    },
    avatar: {
      width: "30px",
      height: "30px",
      marginLeft: theme.spacing(1)
    },
    nestRow: {
      height: "30px",
      display: "flex",
      position: "relative",
      borderLeft: "1px solid"
    },
    nestedBorderedCells: {
      border: "none",
      borderTop: "1px solid"
    },
    first: {
      width: "18px",
      bottom: "-18px"
    },
    second: {
      width: "65px",
      bottom: "0px"
    },
    imageIcon: {
      width: "30px",
      height: "30px"
    },
    taskTitle: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      justifyContent: "space-between"
    },
    mapIcon: { fontSize: 20 },
    flexDisplay: { display: "flex" },
    activeRow: { fontWeight: "bold" },
    pointerCursor: { cursor: "pointer" },
    sectionNext: { paddingTop: theme.spacing(1) },
    leftSpacing: { marginLeft: theme.spacing(2) },
    taskName: { marginRight: theme.spacing(0.8) },
    marginNotes: { marginRight: theme.spacing(1) },
    dataCell: { padding: `${theme.spacing(1)}px 0` },
    tableUnActive: { textDecoration: "none !important" },
    tasks: { margin: `0 0 ${theme.spacing(2)}px ${theme.spacing(7)}px` },
    collapsedPaper: { margin: `${theme.spacing(2)}px 0 0 0 !important` }
  })
);
interface RowDetailsProps {
  expandedId: number;
  toggledMap: boolean;
  routeinstance: RouteInstance;
  selectedTask: Task | undefined;
  onMouseEnter: (id: number) => void;
  onMouseLeave: (id: number) => void;
  toggleOrderAndTask: (
    rInstance?: RouteInstance | undefined,
    task?: Task | undefined
  ) => void;
}
const RowDetailsComponent: React.FC<RowDetailsProps> = (props) => {
  const {
    expandedId,
    toggledMap,
    onMouseEnter,
    onMouseLeave,
    selectedTask,
    routeinstance,
    toggleOrderAndTask
  } = props;
  const classes = useStyles();
  const t = useTranslate("RowDetailsPage");
  const contractorIds =
    routeinstance && routeinstance.participants.map((p) => p.contractor);
  const baseServiceIds =
    routeinstance && routeinstance.participants.map((p) => p.baseservice);
  const [tasksDict, setTasksDict] = useState<Record<number, Task>>();
  const [showImageMeta, setShowImageMeta] =
    useState<[number, number, IRowImage[]]>();
  const [suppliers, setSuppliers] = useState<ICompany[] | undefined>([]);
  const [openMapDialog, setOpenMapDialog] =
    useState<[number, Task["geo_polygons"]]>();
  const [baseServices, setBaseServices] = useState<BaseService[] | undefined>(
    []
  );
  const [reportedImages, setReportedImages] = useState<
    Record<string, IRowImage>
  >({});
  const [geoPolygonImages, setGeoPolygonImages] = useState<
    Record<string, IRowImage>
  >({});
  const [imagesMetaDict, setImagesMetasDict] =
    useState<Record<number, IReportedCommentsAndImages>>();

  const getDataFromApis = async () => {
    try {
      if (contractorIds.length) {
        const response = await getContractorCompaniesAPI(contractorIds);
        setSuppliers(response.results);
      }
      if (baseServiceIds.length) {
        const response = await getBaseServicesByIdsAPI(baseServiceIds);
        setBaseServices(response.results);
      }
      const taskIds = routeinstance.rows.map((r) => r.task);
      if (taskIds.length) {
        const response = await getTaskByIdsAPI(taskIds);
        response.results.forEach(renderGeoPolygonsAvatar);
        setTasksDict(
          Object.fromEntries(response.results.map((t) => [t.id, t]))
        );
      } else {
        setTasksDict({});
      }

      if (!toggledMap) {
        const response = await getReportedCommentsAndImagesAPI(
          routeinstance.id
        );
        const dict: Record<number, IReportedCommentsAndImages> = {};
        response.forEach((obj) => {
          obj.images = applySequence(
            ReportedImageSequence,
            obj.images.map((imgMeta) => [
              imgMeta.classification as string,
              imgMeta
            ])
          );
          obj.images.forEach(renderReportedImages);

          dict[obj.segment_row_id] = obj;
        });
        setImagesMetasDict(dict);
      }
    } catch (e) {
      console.warn(e);
    }
  };
  const handleReportedImagesClicked =
    (imageMeta: IReportedCommentsAndImages, imgIndex: number) => () => {
      const loadedImages = imageMeta.images.map(
        (imgMeta) => reportedImages[imgMeta.image]
      );
      if (
        loadedImages.filter((img) => img).length === imageMeta.images.length
      ) {
        setShowImageMeta([imageMeta.segment_row_id, imgIndex, loadedImages]);
      }
    };
  const renderReportedImages = async (img: IReportedImage) => {
    const imageStr = await getImageByURL(img.image);
    const data: IRowImage = {
      imageStr,
      notes: img.notes,
      url: img.image,
      title: img.classification
        ? translate("ImageClassificationLabel")(img.classification)
        : "",
      classification: img.classification || null
    };
    setReportedImages((imgs) => ({
      ...imgs,
      [img.image]: data
    }));
  };
  const renderGeoPolygonsAvatar = async (task: Task) => {
    if (!task.geo_polygons_image) {
      return;
    }
    try {
      const imageStr = await getImageByURL(task.geo_polygons_image);
      const data = {
        classification: null,
        imageStr,
        url: task.geo_polygons_image,
        notes: null,
        title: ""
      };
      setGeoPolygonImages((imgs) => ({
        ...imgs,
        [task.geo_polygons_image!]: data
      }));
    } catch (e) {
      console.warn(e);
    }
  };
  const [show, setShow] = useState(true);
  useEffect(() => {
    const count = window.setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => {
      clearInterval(count);
    };
  }, []);
  const renderLoader = () => {
    return (
      <CircularProgress
        color="primary"
        size={"25px"}
        className={classes.leftSpacing}
      />
    );
  };
  const isTaskSelected = (task: Task) => {
    if (task.geo_polygons && !(selectedTask && selectedTask.id === task.id)) {
      return true;
    } else {
      return false;
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apisCallback = useCallback(getDataFromApis, [routeinstance]);
  useEffect(() => {
    // if (expandedId === routeinstance.id) {
    setTasksDict(undefined);
    setSuppliers(undefined);
    setBaseServices(undefined);
    setImagesMetasDict(undefined);
    setReportedImages({});
    setGeoPolygonImages({});
    // }
    apisCallback();
  }, [apisCallback, expandedId, routeinstance]);

  return (
    <>
      {expandedId === routeinstance.id && !toggledMap && (
        <div>
          <div className={classes.suppliers}>
            <Grid container spacing={0}>
              {suppliers && suppliers.length ? (
                <Grid item xs={4}>
                  <div className={classes.headers}>{t("suppliersHeading")}</div>
                  {suppliers.map((supplier) => (
                    <div key={supplier.id}>{supplier.name}</div>
                  ))}
                </Grid>
              ) : (
                <Typography>
                  {(show && renderLoader()) || t("notAvailableText")}
                </Typography>
              )}
              {baseServices && baseServices.length ? (
                <Grid item xs={4}>
                  <div className={classes.headers}>{t("servicesHeading")}</div>
                  {baseServices.map((service, serviceIdx) => (
                    <div key={serviceIdx}>{service.name}</div>
                  ))}
                </Grid>
              ) : (
                <Typography>
                  {(show && renderLoader()) || t("notAvailableText")}
                </Typography>
              )}
            </Grid>
            <div
              className={clsx(classes.horizontalDivider, classes.second)}
            ></div>
          </div>
          <div className={classes.tasks}>
            <Table>
              {tasksDict ? (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell className={classes.bold}>
                        {t("titleHeading")}
                      </TableCell>
                      <TableCell className={classes.bold}>
                        {t("instructionHeading")}
                      </TableCell>
                      <TableCell className={classes.bold}>
                        {t("skissHeading")}
                      </TableCell>
                      <TableCell className={classes.bold}>
                        {t("percentageHeading")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {routeinstance.rows
                      .filter((row) => tasksDict[row.task])
                      .map((row, taskIdx) => {
                        const task = tasksDict[row.task];
                        const imageMeta =
                          imagesMetaDict && imagesMetaDict[row.row_id];
                        return (
                          <React.Fragment key={row.row_id}>
                            <TableRow>
                              <TableCell className={classes.nestedcells}>
                                <b>{taskIdx + 1}</b>
                              </TableCell>
                              <TableCell className={classes.nestedcells}>
                                {task.name}
                              </TableCell>
                              <TableCell className={classes.nestedcells}>
                                {row.instructions || t("notAvailableText")}
                              </TableCell>
                              <TableCell
                                className={classes.nestedcells}
                                onClick={() =>
                                  setOpenMapDialog([
                                    row.row_id,
                                    task.geo_polygons
                                  ])
                                }
                              >
                                {task.geo_polygons_image ? (
                                  geoPolygonImages[task.geo_polygons_image] ? (
                                    <Avatar
                                      className={clsx(
                                        classes.avatar,
                                        classes.pointerCursor
                                      )}
                                      variant="rounded"
                                      alt="I"
                                      src={
                                        geoPolygonImages[
                                          task.geo_polygons_image
                                        ].imageStr
                                      }
                                    />
                                  ) : (
                                    <div
                                      className={clsx(
                                        classes.avatar,
                                        classes.pointerCursor
                                      )}
                                    >
                                      <Image className={classes.imageIcon} />
                                    </div>
                                  )
                                ) : (
                                  task.geo_polygons && (
                                    <IconButton aria-label="assignment">
                                      <MapIcon />
                                    </IconButton>
                                  )
                                )}
                              </TableCell>
                              <TableCell className={classes.nestedcells}>
                                20%
                              </TableCell>
                            </TableRow>
                            {!imagesMetaDict ? (
                              <CircularProgress color="primary" size={"25px"} />
                            ) : (
                              imageMeta && (
                                <>
                                  <TableRow
                                    className={classes.nestRow}
                                  ></TableRow>
                                  <TableRow>
                                    <TableCell
                                      className={classes.nestedBorderedCells}
                                    ></TableCell>
                                    <TableCell
                                      className={classes.nestedBorderedCells}
                                    ></TableCell>
                                    <TableCell className={classes.bold}>
                                      {t("commentsHeading")}
                                    </TableCell>
                                    <TableCell
                                      className={classes.bold}
                                      colSpan={2}
                                    >
                                      {t("imagesHeading")}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      className={classes.nestedcells}
                                    ></TableCell>
                                    <TableCell
                                      className={classes.nestedcells}
                                    ></TableCell>
                                    <TableCell className={classes.dataCell}>
                                      {imageMeta.notes}
                                    </TableCell>
                                    <TableCell
                                      className={classes.dataCell}
                                      colSpan={2}
                                    >
                                      {imageMeta.images.length !== 0 && (
                                        <div className={classes.flexDisplay}>
                                          {imageMeta.images.map(
                                            (imgMeta, imgIndex) => (
                                              <TableCell
                                                className={classes.nestedCell}
                                                key={imgMeta.id}
                                              >
                                                {reportedImages[
                                                  imgMeta.image
                                                ] ? (
                                                  <Avatar
                                                    className={clsx(
                                                      classes.avatar,
                                                      classes.pointerCursor
                                                    )}
                                                    variant="rounded"
                                                    alt={`${imgIndex}`}
                                                    src={
                                                      reportedImages[
                                                        imgMeta.image
                                                      ].imageStr
                                                    }
                                                    onClick={handleReportedImagesClicked(
                                                      imageMeta,
                                                      imgIndex
                                                    )}
                                                  />
                                                ) : (
                                                  <div
                                                    className={clsx(
                                                      classes.avatar,
                                                      classes.pointerCursor
                                                    )}
                                                  >
                                                    <Image
                                                      className={
                                                        classes.imageIcon
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </TableCell>
                                            )
                                          )}
                                          {showImageMeta &&
                                            showImageMeta[0] ===
                                              imageMeta.segment_row_id && (
                                              <RowImageDialog
                                                selectedIndex={showImageMeta[1]}
                                                showDialog={true}
                                                allImages={showImageMeta[2]}
                                                onImageChange={() => {}}
                                                populateImageDetails={(img) =>
                                                  Promise.resolve(img)
                                                }
                                                onDialogClosed={() =>
                                                  setShowImageMeta(undefined)
                                                }
                                              />
                                            )}
                                        </div>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )
                            )}
                            {openMapDialog &&
                              openMapDialog[0] === row.row_id && (
                                <GeoPolygonBuilderDialog
                                  geoPolygons={openMapDialog[1]}
                                  open={true}
                                  closeDialog={() =>
                                    setOpenMapDialog(undefined)
                                  }
                                />
                              )}
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </>
              ) : (
                <TableBody>
                  <TableRow>
                    <Typography>
                      {(show && renderLoader()) || t("notAvailableText")}
                    </Typography>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </div>
        </div>
      )}
      <Collapse in={expandedId === routeinstance.id && toggledMap}>
        {routeinstance ? (
          <Paper className={clsx(classes.paper, classes.collapsedPaper)}>
            <div className={classes.section}>
              <div className={classes.bold}>{routeinstance.title}</div>
            </div>
            <div className={clsx(classes.section, classes.sectionNext)}>
              <div>
                {suppliers &&
                  suppliers.map((supplier, sIdx) => (
                    <div className={classes.bold} key={sIdx}>
                      {supplier.name}
                    </div>
                  ))}
              </div>
              {baseServices &&
                baseServices.map((service, serIdx) => (
                  <div key={serIdx}>{service.name}</div>
                ))}
            </div>
            <div className={clsx(classes.section, classes.sectionNext)}>
              <div className={classes.bold}>{t("tasksHeading")}</div>
              {tasksDict ? (
                routeinstance.rows
                  .filter((row) => tasksDict[row.task])
                  .map((row, taskIdx) => {
                    const task = tasksDict[row.task];
                    return (
                      <div
                        key={taskIdx}
                        onMouseEnter={() => onMouseEnter(task.id)}
                        onMouseLeave={() => onMouseLeave(task.id)}
                        className={`${
                          selectedTask && selectedTask.id === task.id
                            ? classes.activeRow
                            : classes.unActiveRow
                        }`}
                      >
                        <div className={clsx(classes.unActiveRow, classes.pl0)}>
                          <Link
                            onClick={
                              task.geo_polygons && isTaskSelected(task)
                                ? () => toggleOrderAndTask(undefined, task)
                                : () => toggleOrderAndTask()
                            }
                            className={
                              isTaskSelected(task)
                                ? classes.pointerCursor
                                : clsx(
                                    classes.tableUnActive,
                                    classes.pointerCursor
                                  )
                            }
                          >
                            <div className={classes.taskTitle}>
                              <div>
                                <span className={classes.taskName}>
                                  {taskIdx + 1})
                                </span>
                                <span>{task.name}</span>
                              </div>
                              {task.geo_polygons && (
                                <div>
                                  {isTaskSelected(task) ? (
                                    <MapIcon className={classes.mapIcon} />
                                  ) : (
                                    <VisibilityIcon
                                      className={classes.mapIcon}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className={classes.centeredDiv}>{renderLoader()}</div>
              )}
            </div>
          </Paper>
        ) : (
          renderLoader()
        )}
      </Collapse>
    </>
  );
};
export default RowDetailsComponent;
