import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../redux/types";
import TemplateImageContainer from "./TemplateImageContainer";
import TemplateImageEditFormContainer from "./TemplateImageEditFormContainer";
import TemplateImageNewFormContainer from "./TemplateImageNewFormContainer";
import TemplateImagesContainer from "./TemplateImagesContainer";
import ImageIcon from "@material-ui/icons/Image";

const TemplateImagesRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["services/templateimages"],
    requireSuperuser: true,
    routes: [
      {
        path: "/services/templateimages",
        label: t("navbarTemplateImagesLabel"),
        component: TemplateImagesContainer,
        icon: ImageIcon,
        highlightClass: NavItemClassEnum.TEMPLATEIMAGES,
        navId: NavItemClassEnum.TEMPLATEIMAGES,
      },
      {
        path: "/services/templateimages/new",
        parent: "/services/templateimages",
        label: t("newTemplateImage"),
        component: TemplateImageNewFormContainer,
        highlightClass: NavItemClassEnum.TEMPLATEIMAGES,
      },
      {
        path: "/services/templateimages/:templateImageId",
        parent: "/services/templateimages",
        label: t("templateimageLabel"),
        component: TemplateImageContainer,
        highlightClass: NavItemClassEnum.TEMPLATEIMAGES,
      },
      {
        path: "/services/templateimages/:templateImageId/edit",
        parent: "/services/templateimages/:templateImageId",
        label: t("editTemplateImage"),
        component: TemplateImageEditFormContainer,
        highlightClass: NavItemClassEnum.TEMPLATEIMAGES,
      },
    ],
  },
];

export default TemplateImagesRouter;
