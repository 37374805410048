import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import * as types from "../../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { niceDate } from "../../FormatHelpers";
import { useTranslate } from "../../../services/appLanguageService";
import CompanyLinkContainer from "../../../containers/links/CompanyLinkContainer";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { TabComponentProps } from "../../../redux/types";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    contractText: {
      padding: 20,
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    section: {
      marginBottom: 20
    },
    line: {
      marginBottom: 5
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface TermProps extends WithStyles<typeof styles>, TabComponentProps {
  term: types.Term;
}

const Term: React.FC<TermProps> = (props) => {
  const { classes, term, tabId } = props;
  const t = useTranslate("TermPage");
  const t2 = useTranslate("Terms");

  function multiline(str: string) {
    if (typeof str === "undefined" || str === null) {
      return "";
    }

    return (
      <div>
        {str.split("\n\n").map((section) => (
          <div className={classes.section}>
            {section.split("\n").map((line) => (
              <div className={classes.line}>{line}</div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    store.dispatch(setTitle(term.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">{term.name}</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">{t2(term.status)}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("descriptionLabel")}
                  </TableCell>
                  <TableCell align="right">{term.description}</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("ownerLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {term.owner ? (
                      <CompanyLinkContainer
                        companyId={term.owner}
                        {...mapTabRouteProps(props)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">{term.notes}</TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(term.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(term.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          {term.terms_text && (
            <Paper className={classes.contractText}>
              <List>
                <ListItem>
                  <ListItemText
                    primary={t("termsTextLabel")}
                    secondary={multiline(term.terms_text)}
                  />
                </ListItem>
              </List>
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Term);
