import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Dialog } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import clsx from "clsx";
import {
  FeatureCollection,
  IApprovalDictionaries,
  IOrderPopupData,
  IRowImage
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { fetchBlobImg } from "../../../helpers/globalHelper";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import ImagesView from "./ImagesView";
import { ExifParserFactory } from "ts-exif-parser";
import {
  ensureMapScript,
  fitToBounds,
  loadGeoJson
} from "../../contracts/tasks/maps/MapHelpers";

const orderPopupStyles = (theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center"
    },
    headerTitle: {
      paddingLeft: theme.spacing(2.5),
      flex: 1
    },
    closeButton: {
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(0.5)
    },
    horizontalPadding: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5)
    },
    contentArea: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(3),
      flex: "0 0 100%",
      width: "calc(100% - 40px)"
    },
    contentAreaLeft: {
      flexGrow: 1,
      maxWidth: "50%",
      display: "flex",
      justifyContent: "center"
    },
    contentAreaRight: {
      flexGrow: 1
    },
    mapView: {
      margin: "0 !important",
      padding: "0 !important",
      backgroundColor: "#aeaeae",
      height: "calc(100VH - 384px)",
      border: "1px solid #aeaeae"
    },
    imageView: {
      height: "calc(100VH - 384px)"
    },
    noMapContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#aeaeae",
      height: "calc(100VH - 384px)"
    },
    noMapIcon: {
      width: 100,
      height: 100
    },
    labeledText: {
      display: "flex",
      gap: theme.spacing(1.5),
      "& > :first-child": {
        fontWeight: 700
      },
      ...theme.typography.body1
    },
    contentBody: {
      maxHeight: 80,
      overflow: "auto",
      marginBottom: theme.spacing(2)
    }
  });
interface OrderPopupProps extends WithStyles<typeof orderPopupStyles> {
  metaIndex: number;
  meta: IOrderPopupData[];
  dictionaries: IApprovalDictionaries;
  handleClose: () => void;
  handleRowNavigation: (rowIndex: number) => void;
}

const OrderPopup: React.FC<OrderPopupProps> = (props) => {
  const { classes, meta, dictionaries, metaIndex, handleClose } = props;
  const [mapData, setMapData] = useState<FeatureCollection | null>();
  const [accName, setAccName] = useState("");
  const t = useTranslate("TimesheetApprovalPage");
  const data = meta[metaIndex];
  const mapRef = useRef<google.maps.Map>();
  const markerRef = useRef<google.maps.Marker>();

  const fetchExifData = useCallback(async (selectedImage: IRowImage) => {
    if (!selectedImage || !selectedImage.url) {
      console.log("Image URL not available");
      return null;
    }

    const img = await fetchBlobImg({
      url: selectedImage.url,
      imageStr: "",
      notes: "",
      title: "",
      classification: null
    });

    const response = await fetch(img.imageStr);
    const arrayBuffer = await response.arrayBuffer();

    const parser = ExifParserFactory.create(arrayBuffer);
    const result = parser.parse();

    if (result && result.tags) {
      if (
        result.tags.GPSLatitude &&
        result.tags.GPSLongitude &&
        result.tags.GPSLatitudeRef &&
        result.tags.GPSLongitudeRef
      ) {
        const latitude =
          result.tags.GPSLatitudeRef === "S"
            ? -result.tags.GPSLatitude
            : result.tags.GPSLatitude;
        const longitude =
          result.tags.GPSLongitudeRef === "W"
            ? -result.tags.GPSLongitude
            : result.tags.GPSLongitude;

        console.log(
          "GPS Coordinates of current photo:",
          latitude,
          result.tags.GPSLatitudeRef,
          longitude,
          result.tags.GPSLongitudeRef
        );

        if (markerRef.current) {
          markerRef.current.setPosition(
            new window.google.maps.LatLng(latitude, longitude)
          );
        }

        return { latitude, longitude };
      } else {
        console.log("GPS data not available");
        return null;
      }
    } else {
      console.log("Exif data not available");
      return null;
    }
  }, []);
  const syncAccessoryNames = useCallback(
    async (
      [materialDictionary, , timesheetOptionDict]: IApprovalDictionaries,
      data: IOrderPopupData
    ) => {
      let timesheetOptions = timesheetOptionDict.get(data.timesheet_id);
      if (!timesheetOptions) {
        timesheetOptionDict.collectId(data.timesheet_id);
        timesheetOptions = await timesheetOptionDict.fetchData(
          materialDictionary
        );
      }
      let texts: string[] = [];
      if (timesheetOptions?.contracted) {
        if (timesheetOptions.contracted.accessories) {
          const accessoriesDict = timesheetOptions.contracted.accessories;
          const accessoryNames = Object.keys(data.accessories)
            .map((id) => accessoriesDict[id]?.name)
            .filter((name) => name);
          texts = texts.concat(accessoryNames);
        }
      }
      setAccName(texts.join(","));
    },
    []
  );

  useEffect(() => {
    const data = meta[metaIndex];
    setMapData(data.geo_polygons);
  }, [meta, metaIndex]);

  useEffect(() => {
    const data = meta[metaIndex];
    if (data.accessories && data.accessories.length !== 0) {
      syncAccessoryNames(dictionaries, data);
    }
  }, [meta, metaIndex, dictionaries, syncAccessoryNames]);

  const DialogContent = useMemo(
    () =>
      withStyles((theme: Theme) => ({
        root: {
          minWidth: "1000px",
          padding: "0",
          "&:first-child": {
            padding: theme.spacing(2)
          }
        }
      }))(MuiDialogContent),
    []
  );

  const onRef = useCallback(
    (r: HTMLDivElement | null): void => {
      if (r && !mapRef.current) {
        ensureMapScript().then(() => {
          const map = new window.google.maps.Map(r, {
            zoom: 17,
            center: new window.google.maps.LatLng(57.6916305668, 11.974412769),
            mapTypeId: "hybrid"
          });

          if (mapData && mapData.features) {
            loadGeoJson(map, mapData);
            fitToBounds(map);

            if (data.images && data.images.length > 0) {
              const selectedImage = data.images[0];
              fetchExifData(selectedImage).then((coordinates) => {
                if (
                  coordinates &&
                  coordinates.latitude &&
                  coordinates.longitude
                ) {
                  markerRef.current = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(
                      coordinates.latitude,
                      coordinates.longitude
                    ),
                    map: map,
                    title: "Image Marker"
                  });

                  const bounds = map
                    .getBounds()
                    ?.extend(
                      new window.google.maps.LatLng(
                        coordinates.latitude,
                        coordinates.longitude
                      )
                    );
                  if (bounds) {
                    map.fitBounds(bounds);
                  }
                }
              });
            }
          }

          mapRef.current = map;
        });
      }
    },
    [mapData, data, fetchExifData]
  );

  return (
    <Dialog maxWidth="lg" fullWidth open>
      <DialogContent>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.headerTitle}>
            {data.title}
          </Typography>

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className={clsx(classes.contentArea, classes.horizontalPadding)}>
          {!!data.images.length && (
            <div className={classes.contentAreaLeft}>
              <ImagesView
                defaultIndex={data.imageIndex}
                allImages={data.images}
                onImageChange={(selectedImage) => fetchExifData(selectedImage)}
                populateImageDetails={fetchBlobImg}
              />
            </div>
          )}

          <div className={classes.contentAreaRight}>
            {mapData ? (
              <div ref={onRef} className={classes.mapView}></div>
            ) : (
              <div className={classes.noMapContainer}>
                <LocationOffIcon className={classes.noMapIcon} />
                <Typography variant="h6">{t("noPolygon")}</Typography>
              </div>
            )}
          </div>
        </div>

        <div className={clsx(classes.horizontalPadding, classes.labeledText)}>
          <div>{t("tableEntrepHeading")}:</div>
          <div>
            {data.contractor_name} {accName && `, ${accName}`}
          </div>
        </div>
        <div className={clsx(classes.horizontalPadding, classes.labeledText)}>
          <div>{t("tableDriverHeading")}:</div>
          <div>{data.driver_name}</div>
        </div>
        <div className={clsx(classes.horizontalPadding, classes.labeledText)}>
          <div>{t("tableCommentHeading")}:</div>
          <div>{data.contentText}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(orderPopupStyles)(OrderPopup);
