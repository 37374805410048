import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Holiday } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";
import * as Yup from "yup";
import { useTranslate } from "../../services/appLanguageService";
import ResourcesTableContainer from "../../components/resourcetable/ResourcesTableContainer";

interface HolidaysContainerProps {
  holidays: Holiday[];
  count: number;
  loadingHolidays: boolean;
  resetHolidays: () => void;
  loadHolidays: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string,
    flags?: string[]
  ) => void;
  addHoliday: (newData: any, owningId?: number) => void;
  updateHoliday: (holidayId: number, newData: any) => void;
  deleteHoliday: (holidayId: number) => void;
}

const HolidaysContainer: React.FC<HolidaysContainerProps> = (props) => {
  const { loadHolidays, holidays, loadingHolidays, resetHolidays } = props;
  const page = 1;
  const pageSize = 20;

  let filter = "";

  const t3 = useTranslate("ValidationErrorMessages");

  useEffect(
    () => loadHolidays(page, pageSize, undefined, undefined, filter),
    [loadHolidays, filter]
  );
  useEffect(() => () => resetHolidays(), [resetHolidays]);

  const columnDefinitions = [
    {
      name: "name",
      type: "string",
      editableForm: "text",
      validation: Yup.string()
        .min(1, t3("minLengthError"))
        .max(255, t3("max255LengthError"))
        .required(t3("requiredError"))
    },
    {
      name: "date",
      type: "date",
      editableForm: "date",
      validation: Yup.date().required(t3("requiredError")).nullable()
    }
  ];

  return (
    <ResourcesTableContainer
      resources={holidays}
      resourceName={"Holiday"}
      count={props.count}
      loadingResources={loadingHolidays}
      routeUrl={"holidays"}
      columnDefinitions={columnDefinitions}
      filterDefinitions={[]}
      resetResources={resetHolidays}
      defaultPageSize={pageSize}
      loadResources={loadHolidays}
      customFilter={filter}
      addResource={props.addHoliday}
      updateResource={props.updateHoliday}
      deleteResource={props.deleteHoliday}
      showNewButton={false}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadHolidays: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "&order__date=asc";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "HOLIDAYS",
        `orders/holidays/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  addHoliday: (newData: { name: string; date: Date }) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.HOLIDAY,
        "orders/holidays/",
        {
          name: newData.name,
          date: newData.date
        }
      )
    );
  },
  updateHoliday: (holidayId: number, newData: { name: string; date: Date }) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.HOLIDAY,
        `orders/holidays/${holidayId}/`,
        {
          name: newData.name,
          date: newData.date
        }
      )
    );
  },
  deleteHoliday: (holidayId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.HOLIDAY,
        `orders/holidays/${holidayId}/`,
        undefined,
        undefined,
        holidayId
      )
    );
  },
  resetHolidays: () =>
    dispatch(LoadingBooleansActions.ResetHolidays(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  holidays: state.tabStates[ownProps.tabId].holidays.holidays.holidays,
  count: state.tabStates[ownProps.tabId].holidays.holidays.count,
  loadingHolidays:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingHolidays
});

export default connect(mapStateToProps, mapDispatchToProps)(HolidaysContainer);
