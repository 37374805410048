const RestStrings = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
};
const ReduxActionStrings = {
  CUSTOMER: "CUSTOMER",
  CONTRACTOR: "CONTRACTOR",
  TASK: "TASK",
  TASKS: "TASKS",
  TASK_CONTRACTOR_COOPERATIONS: "TASK_CONTRACTOR_COOPERATIONS",
  TASK_SERVICES: "TASK_SERVICES",
  ROUTEPLAN: "ROUTEPLAN",
  ROUTEPLANS: "ROUTEPLANS",
  ROUTEPLAN_OPTIONS: "ROUTEPLAN_OPTIONS",
  ROUTE_PARTICIPANT_OPTIONS: "ROUTE_PARTICIPANT_OPTIONS",
  ROUTEINSTANCE: "ROUTEINSTANCE",
  ROUTEINSTANCES: "ROUTEINSTANCES",
  ROUTEPARTICIPANT: "ROUTEPARTICIPANT",
  ROUTEPARTICIPANTS: "ROUTEPARTICIPANTS",
  ROUTESEGMENT: "ROUTESEGMENT",
  ROUTESEGMENTS: "ROUTESEGMENTS",
  ROUTESEGMENTS_TASKS: "ROUTESEGMENTS_TASKS",
  ROUTETIMESHEETS_TASKS: "ROUTETIMESHEETS_TASKS",
  CONTRACT: "CONTRACT",
  CONTRACTS: "CONTRACTS",
  CONTRACT_ITEMS: "CONTRACT_ITEMS",
  CLONE_CONTRACT: "CLONE_CONTRACT",
  CONTRACTINGPROFILE: "CONTRACTINGPROFILE",
  CONTRACTINGPROFILES: "CONTRACTINGPROFILES",
  TASK_CATEGORY: "CATEGORY",
  TASK_CATEGORIES: "CATEGORIES",
  TASK_CUSTOMEROBJECT: "TASK_CUSTOMEROBJECT",
  TASK_BUSINESSAREA: "TASK_BUSINESSAREA",
  ADD_SIGNED_COOPERATION: "ADD_SIGNED_COOPERATION",
  CONTRACTOR_COMPANIES: "CONTRACTOR_COMPANIES",
  CONTRACTOR_TASKS: "CONTRACTOR_TASKS",
  CUSTOMEROBJECT: "CUSTOMEROBJECT",
  CUSTOMEROBJECTS: "CUSTOMEROBJECTS",
  CUSTOMEROBJECT_COOPERATIONS: "CUSTOMEROBJECT_COOPERATIONS",
  ALL_CUSTOMEROBJECTS: "ALL_CUSTOMEROBJECTS",
  CUSTOMEROBJECT_LOCATION: "CUSTOMEROBJECT_LOCATION",
  BASESERVICE: "BASESERVICE",
  BASESERVICES: "BASESERVICES",
  BASEMATERIAL: "BASEMATERIAL",
  BASEMATERIALS: "BASEMATERIALS",
  BASEACCESSORY: "BASEACCESSORY",
  BASEACCESSORIES: "BASEACCESSORIES",
  BASEADDON: "BASEADDON",
  BASEADDONS: "BASEADDONS",
  BASESERVICEPACKAGE: "BASESERVICEPACKAGE",
  BASESERVICEPACKAGES: "BASESERVICEPACKAGES",
  BASESUPPLEMENT: "BASESUPPLEMENT",
  BASESUPPLEMENTS: "BASESUPPLEMENTS",
  SERVICECATEGORY: "SERVICECATEGORY",
  SERVICECATEGORIES: "SERVICECATEGORIES",
  TASKOPTION: "TASKOPTION",
  TASKOPTIONS: "TASKOPTIONS",
  CUSTOMER_CONTRACT: "CUSTOMER_CONTRACT",
  CUSTOMER_CONTRACTS: "CUSTOMER_CONTRACTS",
  CONTRACTOR_CONTRACT: "CONTRACTOR_CONTRACT",
  CONTRACTOR_CONTRACTS: "CONTRACTOR_CONTRACTS",
  LOCATION: "LOCATION",
  SECOND_LOCATION: "SECOND_LOCATION",
  LOCATIONS: "LOCATIONS",
  DISTRICT: "DISTRICT",
  DISTRICTS: "DISTRICTS",
  PARENT_DISTRICT: "PARENT_DISTRICT",
  COOPERATION: "COOPERATION",
  COOPERATIONS: "COOPERATIONS",
  COOPERATION_TASK: "COOPERATION_TASK",
  COOPERATION_CONTRACT: "COOPERATION_CONTRACT",
  COOPERATION_PERSON: "COOPERATION_PERSON",
  COOPERATION_USER: "COOPERATION_USER",
  SERVICE: "SERVICE",
  SERVICEPACKAGE: "SERVICEPACKAGE",
  SERVICEPACKAGES: "SERVICEPACKAGES",
  SERVICES: "SERVICES",
  MATERIAL: "MATERIAL",
  MATERIALS: "MATERIALS",
  ACCESSORY: "ACCESSORY",
  ACCESSORIES: "ACCESSORIES",
  ADDON: "ADDON",
  ADDONS: "ADDONS",
  SUPPLEMENT: "SUPPLEMENT",
  SUPPLEMENTS: "SUPPLEMENTS",
  COMPANY: "COMPANY",
  COMPANIES: "COMPANIES",
  CONTRACTOR_COOPERATIONS: "CONTRACTOR_COOPERATIONS",
  COMPANY_LOCATION: "COMPANY_LOCATION",
  INVOICE_COMPANY_LOCATION: "INVOICE_COMPANY_LOCATION",
  PASSCARD: "PASSCARD",
  PASSCARDS: "PASSCARDS",
  PASSCARD_CUSTOMER: "PASSCARD_CUSTOMER",
  PASSCARD_CONTRACTOR: "PASSCARD_CONTRACTOR",
  PASSCARD_CONTRACTORS: "PASSCARD_CONTRACTORS",
  PASSCARD_CUSTOMERS: "PASSCARD_CUSTOMERS",
  HISTORY: "HISTORY",
  HISTORY_CONTRACTORS: "HISTORY_CONTRACTORS",
  HISTORY_CUSTOMERS: "HISTORY_CUSTOMERS",
  HISTORY_CUSTOMEROBJECTS: "HISTORY_CUSTOMEROBJECTS",
  HISTORY_BUSINESSAREAS: "HISTORY_BUSINESSAREAS",
  HOLIDAY: "HOLIDAY",
  HOLIDAYS: "HOLIDAYS",
  PARAMS_USER: "PARAMS_USER",
  USER: "USER",
  USERS: "USERS",
  NOTIFICATIONSPROFILE: "NOTIFICATIONSPROFILE",
  NOTIFICATIONSPROFILES: "NOTIFICATIONSPROFILES",
  PERSON: "PERSON",
  PERSONS: "PERSONS",
  PROSPECT: "PROSPECT",
  CUSTOMER_PROSPECT_LOCATION: "CUSTOMER_PROSPECT_LOCATION",
  CUSTOMEROBJECT_PROSPECT_LOCATION: "CUSTOMEROBJECT_PROSPECT_LOCATION",
  PROSPECT_FINALIZE: "PROSPECT_FINALIZE",
  PROSPECTS: "PROSPECTS",
  PERSON_COMPANIES: "PERSON_COMPANIES",
  GROUP: "GROUP",
  GROUPS: "GROUPS",
  PERMISSION: "PERMISSION",
  PERMISSIONS: "PERMISSIONS",
  INVOICINGPROFILE: "INVOICINGPROFILE",
  INVOICINGPROFILES: "INVOICINGPROFILES",
  INVOICERECIPIENT: "INVOICERECIPIENT",
  INVOICERECIPIENTS: "INVOICERECIPIENTS",
  INVOICERECIPIENTS_PERSONS: "INVOICERECIPIENTS_PERSONS",
  CUSTOMER_INVOICE: "CUSTOMER_INVOICE",
  CUSTOMER_INVOICES: "CUSTOMER_INVOICES",
  CONTRACTOR_INVOICE: "CONTRACTOR_INVOICE",
  CONTRACTOR_INVOICES: "CONTRACTOR_INVOICES",
  BUSINESSAREA: "BUSINESSAREA",
  BUSINESSAREAS: "BUSINESSAREAS",
  BUSINESSAREA_OPTIONS: "BUSINESSAREA_OPTIONS",
  ACTIVE_CONTRACTOR_BUSINESSAREAS: "ACTIVE_CONTRACTOR_BUSINESSAREAS",
  PROJECT: "PROJECT",
  PROJECTS: "PROJECTS",
  TEMPLATEIMAGE: "TEMPLATEIMAGE",
  TEMPLATEIMAGES: "TEMPLATEIMAGES",
  TERM: "TERM",
  TERMS: "TERMS",
  WEATHERAREA: "WEATHERAREA",
  WEATHERAREAS: "WEATHERAREAS",
  EMAILS: "EMAILS"
};
const EnumStrings = {
  CUSTOMER: "CUSTOMER",
  CONTRACTOR: "CONTRACTOR",
  MATERIAL_SUPPLIER: "MATERIAL_SUPPLIER",
  OTHER: "OTHER",
  DOMAIN_OWNER: "DOMAIN_OWNER",
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  LOCKED: "LOCKED",
  UNCONFIRMED: "UNCONFIRMED",
  LOCKOUT: "LOCKOUT",
  STANDARD: "STANDARD",
  PROSPECT: "PROSPECT",
  DIGITAL: "DIGITAL",
  ANALOG: "ANALOG",
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  DRAFT: "DRAFT",
  FINALIZED: "FINALIZED",
  DECLINED: "DECLINED",
  CONTRACT: "CONTRACT",
  OFFER: "OFFER",
  YES: "YES",
  NO: "NO",
  VIEW: "VIEW",
  EDIT: "EDIT",
  CONTACT: "CONTACT",
  DRIVER: "DRIVER",
  CONFIRMED_BUYER: "CONFIRMED_BUYER",
  TEAM_LEADER: "TEAM_LEADER",
  ADMINISTRATIVE_CONTACT: "ADMINISTRATIVE_CONTACT",
  DRIVER_AND_TEAM_LEADER: "DRIVER_AND_TEAM_LEADER",
  ADMINISTRATIVE_CONTACT_AND_CONFIRMED_BUYER:
    "ADMINISTRATIVE_CONTACT_AND_CONFIRMED_BUYER",
  PROPERTY_USER: "PROPERTY_USER",
  PROPERTY_OWNER: "PROPERTY_OWNER",
  TRUSTEE: "TRUSTEE",
  KEY: "KEY",
  CODE: "CODE",
  PASSCARD: "PASSCARD",
  TAG: "TAG",
  SEK: "SEK",
  NOK: "NOK",
  DKK: "DKK",
  EUR: "EUR",
  USD: "USD",
  SV: "SV",
  EN: "EN",
  MAIN: "MAIN",
  CC: "CC",
  BCC: "BCC",
  PER_HOUR: "PER_HOUR",
  PER_EVENT: "PER_EVENT",
  PER_UNIT: "PER_UNIT",
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
  MONTHLY: "MONTHLY",
  WEEKLY: "WEEKLY",
  YEARLY: "YEARLY",
  WEEKDAY: "WEEKDAY",
  WEEKEND: "WEEKEND",
  HOLIDAY: "HOLIDAY",
  ADD_CONTRACT_ITEM: "ADD_CONTRACT_ITEM",
  HAPPY: "HAPPY",
  UNHAPPY: "UNHAPPY"
};

export { RestStrings, ReduxActionStrings, EnumStrings };
