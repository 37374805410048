import { NotificationsProfilesState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: NotificationsProfilesState = {
  notificationsProfiles: {
    results: [],
    next: null,
    previous: null,
    count: 0
  },
  notificationsProfile: {
    notificationsProfile: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      notes: ""
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.NOTIFICATIONSPROFILES
    ):
      return {
        ...state,
        notificationsProfiles: {
          results: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };

    default:
      return state;
  }
}

export default reducer;
