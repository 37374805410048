import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  WithStyles
} from "@material-ui/core";
import { useTranslate } from "../../../../services/appLanguageService";
import MapView from "../../../contracts/tasks/Map";
import { FormikProps } from "formik";
import { AddModificationRequestForm } from "../../../../redux/types";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const styles = (theme: any) => createStyles({});

interface RouteSegmentMapDialogProps extends WithStyles<typeof styles> {
  handleClose: () => void;
  formikProps: FormikProps<AddModificationRequestForm>;
  mapDialogData: {
    open: boolean;
    editable: boolean;
  };
}

const RouteSegmentMapDialog: React.FC<RouteSegmentMapDialogProps> = (props) => {
  const { handleClose, mapDialogData } = props;

  const t = useTranslate("RouteSegmentMapDialog");

  return (
    <Dialog
      open={mapDialogData.open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">{t("mapDialogTitle")}</DialogTitle>
      <DialogContent>
        <MapView
          geo_polygons={props.formikProps.values.geo_polygons}
          editable={mapDialogData.editable}
          showSaveButton={false}
          formikProps={props.formikProps}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          color="primary"
        >
          <CheckCircleOutlineOutlinedIcon />
          {t("okButtonLabel")}
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          <CancelOutlinedIcon />
          {t("closeButtonLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(RouteSegmentMapDialog);
