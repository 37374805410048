import { Dispatch } from "redux";
import { connect } from "react-redux";
import { HistoryFilterQuery, mainRootState } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";
import HistoryView from "../../components/history/History";
import { filterQueryParams } from "../../helpers/queryParams";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadHistory: (filter: HistoryFilterQuery = {}) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.HISTORY,
        `timesheets/workapprovals/history/${filterQueryParams(filter)}`
      )
    );
  },
  loadContractorOptions: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.HISTORY_CONTRACTORS,
        `companies/companies/?filter__status=ACTIVE&filter__company_type=CONTRACTOR&include=["id","name"]&page=1&page_size=10&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerOptions: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.HISTORY_CUSTOMERS,
        `companies/companies/?filter__status=ACTIVE&filter__company_type=CUSTOMER&include=["id","name"]&page=1&page_size=10&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerobjectOptions: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.HISTORY_CUSTOMEROBJECTS,
        `contracts/customerobjects/?filter__status=ACTIVE&include=["id","name"]&page=1&page_size=10&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadBusinessareaOptions: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.HISTORY_BUSINESSAREAS,
        `contracts/businessareas/?include=["id","name"]&page=1&page_size=10&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  resetHistory: () =>
    dispatch(LoadingBooleansActions.ResetLoadingHistory(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  historyData: state.tabStates[ownProps.tabId].historyView.history.history,
  count: state.tabStates[ownProps.tabId].historyView.history.count,
  contractorOptions: state.tabStates[
    ownProps.tabId
  ].historyView.contractors.map((contractor) => ({
    label: contractor.name,
    value: contractor.id
  })),
  customerOptions: state.tabStates[ownProps.tabId].historyView.customers.map(
    (customer) => ({
      label: customer.name,
      value: customer.id
    })
  ),
  customerobjectOptions: state.tabStates[
    ownProps.tabId
  ].historyView.customerobjects.map((customerobjekt) => ({
    label: customerobjekt.name,
    value: customerobjekt.id
  })),
  businessareaOptions: state.tabStates[
    ownProps.tabId
  ].historyView.businessareas.map((businessarea) => ({
    label: businessarea.name,
    value: businessarea.id
  })),
  previous: state.tabStates[ownProps.tabId].historyView.history.previous,
  next: state.tabStates[ownProps.tabId].historyView.history.next,
  loadingHistory: state.tabStates[ownProps.tabId].loadingBooleans.loadingHistory
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryView);
