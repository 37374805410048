export const RESET_STATE = "RESET_STATE";

interface ResetStateAction {
  type: typeof RESET_STATE;
}

export type RootActionTypes = ResetStateAction;

export function createSuccessActionType(restType: string, actionType: string) {
  return `${restType}_${actionType}_SUCCESS`;
}

export function createFailureActionType(restType: string, actionType: string) {
  return `${restType}_${actionType}_FAILURE`;
}

export function createResetResourceType(actionType: string) {
  return `RESET_${actionType}_RESOURCE`;
}
