import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../../redux/types";
import ProspectNewEditForm from "../../../components/contracts/prospects/ProspectNewEditForm";
import ProspectsContainer from "./ProspectsContainer";

const ProspectsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/prospects"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/prospects",
        label: t("prospectsNavbarLabel"),
        component: ProspectsContainer,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS
      },
      {
        parent: "/contracts/prospects",
        path: "/contracts/prospects/:prospectId",
        label: t("editProspectLabel"),
        component: ProspectNewEditForm,
        highlightClass: NavItemClassEnum.CUSTOMEROBJECTS
      }
    ]
  }
];

export default ProspectsRouter;
