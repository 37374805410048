import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { FormikSubmitDispatchProps, NewPasswordForm } from "../../redux/types";
import UserEditPasswordForm from "../../components/users/UserEditPasswordForm";
import * as RestActions from "../../redux/sagas/restActions";
import { ReduxActionStrings, RestStrings } from "../../redux/strings";

interface UserEditPasswordFormContainerProps
  extends FormikSubmitDispatchProps<NewPasswordForm> {
  userId: number;
  personId: number;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<NewPasswordForm> {}

const UserEditPasswordFormContainer: React.FC<
  UserEditPasswordFormContainerProps
> = (props) => {
  const { userId, personId } = props;
  return (
    <div>
      <UserEditPasswordForm
        handleSubmit={props.handleSubmit}
        userId={userId}
        personId={personId}
      />
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.USER,
        `auth/users/${(values as NewPasswordForm).id}/`,
        values,
        `/auth/persons/${(values as NewPasswordForm).person}?same_tab=true`
      )
    );
  }
});

export default connect(null, mapDispatchToProps)(UserEditPasswordFormContainer);
