import { LayoutRouteUsage } from "../../../redux/types";
import BaseItems from "../../../components/contracts/BaseItems";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { LocalShipping } from "@material-ui/icons";
import ContractItemContainer from "../services/ContractItemContainer";
import Basematerial from "../../../components/contracts/materials/Basematerial";

const MaterialsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/basematerials"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/basematerials",
        label: t("contractNavbarBaseMaterialsLabel"),
        component: BaseItems,
        subTabIndex: 1,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS,
        icon: LocalShipping
      },
      {
        path: "/contracts/basematerials/:baseMaterialId",
        parent: "/contracts/basematerials",
        label: t("baseMaterialLabel"),
        component: Basematerial,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/:contractId/materials/:contractItemId",
        parent: "/contracts/materials",
        label: t("materialLabel"),
        component: ContractItemContainer,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/baseitems",
        label: t("baseItemsLabel"),
        component: BaseItems,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      }
    ]
  }
];

export default MaterialsRouter;
