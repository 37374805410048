import React, { useEffect, useState } from "react";
import { Invoice, TabComponentProps } from "../../redux/types";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  getContractorInvoiceAPI,
  getContractorInvoicePdfAPI,
  getCustomerInvoiceAPI,
  getCustomerInvoicePdfAPI
} from "../../services/api-declaration";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import PDFViewer from "../PDFViewer";
import { InvoicesSubIndexesEnum } from "../../strings/TabRouterStrings";
import LoadingSpinnerPaper from "../LoadingSpinnerPaper";

interface InvoicePDFViewerProps extends TabComponentProps<"invoiceId"> {}

const InvoicePDFViewer: React.FC<InvoicePDFViewerProps> = (props) => {
  const {
    tabId,
    subTabIndex,
    routeParams: { invoiceId }
  } = props;

  const [invoice, setInvoice] = useState<Invoice>();
  const [invoicePdf, setInvoicePdf] = useState<Blob>();

  useEffect(() => {
    let isAlive = true;

    const invoicePromise =
      subTabIndex === InvoicesSubIndexesEnum.CONTRACTOR
        ? getContractorInvoiceAPI(invoiceId)
        : getCustomerInvoiceAPI(invoiceId);
    const invoicePdfPromise =
      subTabIndex === InvoicesSubIndexesEnum.CONTRACTOR
        ? getContractorInvoicePdfAPI(invoiceId)
        : getCustomerInvoicePdfAPI(invoiceId);
    void Promise.all([invoicePromise, invoicePdfPromise]).then(
      ([invoice, invoicePdf]) => {
        if (isAlive) {
          setInvoice(invoice);
          setInvoicePdf(invoicePdf);
          store.dispatch(setTitle(invoice.company_name, tabId));
        }
      }
    );

    return () => {
      isAlive = false;
    };
  }, [invoiceId, subTabIndex, tabId]);

  const invoiceName = invoice
    ? `${invoice.company_name} ${invoice.invoice_date}`
    : "Invoice";

  return invoice && invoicePdf ? (
    <PDFViewer pdfBlob={invoicePdf} pdfName={invoiceName} />
  ) : (
    <LoadingSpinnerPaper />
  );
};

export default InvoicePDFViewer;
