import React, { useState, useCallback, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { TextField, Select, CheckboxWithLabel } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  UserForm,
  User,
  NewUserForm,
  ImageRow,
  TabComponentProps
} from "../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { EnumStrings } from "../../redux/strings";
import UserEditPasswordFormContainer from "../../containers/users/UserEditPasswordFormContainer";
import { AutoCompleteSelectMultiNEW } from "../AutoCompleteSelect";
import { useTranslate } from "../../services/appLanguageService";
import { getImageByURL } from "../../services/imageHelperService";
import ImageUploadComponent from "../imageUploadComponent/ImageUploadComponent";
import {
  getSearchDistrictOptionsAPI,
  getSearchGroupOptionsAPI,
  getSearchNotificationsProfileOptionsAPI
} from "../../services/api-declaration";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import { mapTabRouteProps } from "../../helpers/routesHelper";
import { getFromSessionStore } from "../../services/storageService";
import LoadingSpinner from "../LoadingSpinner";

const statusOptions = [
  EnumStrings.ACTIVE,
  EnumStrings.UNCONFIRMED,
  EnumStrings.LOCKOUT
];

const languageOptions = ["sv", "en"];

let ValidationSchemaNew = (t: any) =>
  Yup.object().shape({
    username: Yup.string()
      .min(1, t("minLengthError"))
      .max(150, t("max150LengthError"))
      .matches(/^[\w.@+-]+$/, t("matchesCharactersError"))
      .required(t("requiredError")),
    status: Yup.string()
      .oneOf(statusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    language: Yup.string()
      .oneOf(languageOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    is_staff: Yup.boolean().required(),
    is_superuser: Yup.boolean().notRequired(),
    groups: Yup.array().of(Yup.number().min(1)).notRequired(),
    email: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .email(t("emailInvalidError"))
      .nullable()
      .notRequired(),
    password: Yup.string()
      .min(1, t("minLengthError"))
      .max(128, t("max128LengthError"))
      .required(t("requiredError")),
    passwordConfirmation: Yup.string()
      .min(1, t("minLengthError"))
      .max(128, t("max128LengthError"))
      .required(t("requiredError"))
  });

let ValidationSchemaEdit = (t: any) =>
  Yup.object().shape({
    username: Yup.string()
      .min(1, t("minLengthError"))
      .max(150, t("max150LengthError"))
      .matches(/^[\w.@+-]+$/, t("matchesCharactersError"))
      .required(t("requiredError")),
    status: Yup.string()
      .oneOf(statusOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    language: Yup.string()
      .oneOf(languageOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    is_staff: Yup.boolean().required(),
    is_superuser: Yup.boolean().required(),
    groups: Yup.array().of(Yup.number().min(1)).notRequired(),
    email: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .email(t("emailInvalidError"))
      .nullable()
      .notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    field: {
      marginTop: 12
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    },
    imageLabel: {
      marginTop: theme.spacing(3)
    },
    groups: {
      marginTop: theme.spacing(3)
    }
  });

interface NewUserProps
  extends FormikSubmitDispatchProps<UserForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  user?: User;
}

const NewEditUser: React.FC<NewUserProps> = (props) => {
  const { classes, user, tabId } = props;
  const t = useTranslate("PersonPage");
  const t2 = useTranslate("Users");
  const t3 = useTranslate("ValidationErrorMessages");
  const [images, setImages] = useState<ImageRow[]>([]);
  const [sendingForm, setSendingForm] = useState<boolean>(false);
  let initialValues: User | UserForm | NewUserForm;
  if (user && (user as User).id) {
    initialValues = {
      ...user
    };
  } else {
    initialValues = {
      username: "",
      status: EnumStrings.ACTIVE,
      language: "sv",
      password: "",
      passwordConfirmation: "",
      is_staff: false,
      is_superuser: false,
      avatar: null,
      permissions: [],
      groups: [],
      notifications_profiles: [],
      districts: [],
      person: -1
    };
  }

  const isSuperuser = getFromSessionStore("is_superuser") === "true";

  const handleFormSubmit = (
    values: UserForm,
    actions: FormikHelpers<UserForm>
  ) => {
    let checkedValues: any;
    const imageStr = images.length ? images[0].imageStr : null;

    setSendingForm(true);

    if (!imageStr || (imageStr && imageStr.includes("base64"))) {
      checkedValues = { ...values, avatar: imageStr };
    } else {
      const { avatar, ...other } = values;
      checkedValues = other;
    }
    props.handleSubmit(checkedValues, actions);
  };

  const getAvatar = async () => {
    if (user && user.avatar) {
      const imageStr = await getImageByURL(user.avatar);
      setImages([
        {
          imageStr
        }
      ]);
    }
  };
  const getAvatarCb = useCallback(getAvatar, [user]);
  useEffect(() => {
    getAvatarCb();
  }, [user, getAvatarCb]);

  const [values, setValues] = useState({
    showPassword: false
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  useEffect(() => {
    if (user) {
      store.dispatch(setTitle(user.username, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Button
        id="new-user-to-users"
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/auth/persons${user ? `/${user.person}` : ""}?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {user ? t("editUserHeading") : t("newUserHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={
            user ? ValidationSchemaEdit(t3) : ValidationSchemaNew(t3)
          }
          onSubmit={handleFormSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                data-cy="username-field"
                type="text"
                name="username"
                label={t("usernameLabel")}
                placeholder={t("usernameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />
              {!user ? (
                <>
                  <Field
                    data-cy="password-field"
                    type={values.showPassword ? "text" : "password"}
                    name="password"
                    label={t("passwordLabel")}
                    placeholder={t("passwordLabel")}
                    component={TextField}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Field
                    data-cy="password-confirm-field"
                    type={values.showPassword ? "text" : "password"}
                    name="passwordConfirmation"
                    label={t("passwordConfirmationLabel")}
                    placeholder={t("passwordConfirmationLabel")}
                    component={TextField}
                    margin="normal"
                    fullWidth
                  />
                </>
              ) : (
                ""
              )}

              {/* Status type */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="user-form-status"
              >
                {t("statusLabel")}
              </InputLabel>
              <Field
                id="user-form-status"
                name="status"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              {isSuperuser && (
                <Field
                  data-cy="is-superuser-checkbox"
                  name="is_superuser"
                  type="checkbox"
                  Label={{ label: t("isSuperUserLabel") }}
                  component={CheckboxWithLabel}
                />
              )}
              <Field
                data-cy="is-staff-checkbox"
                name="is_staff"
                type="checkbox"
                Label={{ label: t("isStaffLabel") }}
                component={CheckboxWithLabel}
              />

              <InputLabel className={classes.imageLabel}>
                {t("avatarLabel")}
              </InputLabel>
              <ImageUploadComponent
                images={images}
                onImageUpdate={setImages}
                singleImageMode
              />

              <Field
                id="form-districts-field"
                data-cy="form-districts-field"
                type="text"
                name="districts"
                placeholder={t("districtsLabel")}
                component={AutoCompleteSelectMultiNEW}
                className={classes.field}
                margin="normal"
                fullWidth
                loadOptionsAPI={getSearchDistrictOptionsAPI}
                selectedIds={user ? user.districts : []}
              />

              <Field
                id="form-groups-field"
                data-cy="form-groups-field"
                type="text"
                name="groups"
                placeholder={t("groupsLabel")}
                component={AutoCompleteSelectMultiNEW}
                className={classes.field}
                margin="normal"
                fullWidth
                loadOptionsAPI={getSearchGroupOptionsAPI}
                selectedIds={user ? user.groups : []}
              />

              <Field
                id="form-notifications_profiles-field"
                data-cy="form-notifications_profiles-field"
                type="text"
                name="notifications_profiles"
                placeholder={t("notificationsProfilesLabel")}
                component={AutoCompleteSelectMultiNEW}
                className={classes.field}
                margin="normal"
                fullWidth
                loadOptionsAPI={getSearchNotificationsProfileOptionsAPI}
                selectedIds={user ? user.notifications_profiles : []}
              />

              {/* Language */}
              <InputLabel
                className={classes.selectLabel}
                htmlFor="form-language-user"
              >
                {t("languageLabel")}
              </InputLabel>
              <Field
                id="form-language-user"
                name="language"
                component={Select}
                className={classes.select}
                fullWidth
              >
                {languageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t2(option)}
                  </MenuItem>
                ))}
              </Field>

              {sendingForm ? (
                <LoadingSpinner />
              ) : (
                <Button
                  data-cy="form-submit-btn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {user ? t("saveButtonLabel") : t("createButtonLabel")}
                </Button>
              )}
            </form>
          )}
        </Formik>
      </Paper>
      {user ? (
        <UserEditPasswordFormContainer
          userId={user.id}
          personId={user.person}
          {...mapTabRouteProps(props)}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withStyles(styles)(NewEditUser);
