import { NavItemClassEnum } from "../strings/TabRouterStrings";
import WorkIcon from "@material-ui/icons/Work";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SettingsIcon from "@material-ui/icons/Settings";
import { NavRoute } from "../redux/types";

export const getNavbarDrawerItems: (t: any) => NavRoute<string>[] = (
  t: any
) => [
  NavItemClassEnum.DASHBOARD as any,
  {
    navId: "navbar-work",
    label: t("workNavbarPrimaryLabel"),
    icon: WorkIcon,
    path: "",
    children: [
      NavItemClassEnum.ORDERS,
      NavItemClassEnum.LIVEVIEW,
      NavItemClassEnum.WORKLOG,
      NavItemClassEnum.AFTERREGISTRATEWORK,
      NavItemClassEnum.WORKVERIFICATION,
      NavItemClassEnum.HISTORY
    ],
    highlightClasses: ""
  },
  {
    navId: "navbar-administration",
    label: t("administrationNavbarPrimaryLabel"),
    icon: ListAltIcon,
    path: "",
    children: [
      NavItemClassEnum.REPORTS,
      NavItemClassEnum.CONTRACTORREPORTS,
      NavItemClassEnum.INVOICES,
      NavItemClassEnum.EMAILS,
      NavItemClassEnum.EVENTS
    ],
    highlightClasses: ""
  },
  {
    navId: "navbar-files",
    label: t("filesNavbarPrimaryLabel"),
    icon: FileCopyIcon,
    path: "",
    children: [
      NavItemClassEnum.COMPANIES,
      NavItemClassEnum.CUSTOMEROBJECTS,
      NavItemClassEnum.CONTRACTS,
      NavItemClassEnum.SERVICESANDPRODUCTS,
      NavItemClassEnum.PERSONS,
      NavItemClassEnum.PASSCARDS,
      NavItemClassEnum.MATERIALDEPOTS,
      NavItemClassEnum.DISTRICTS,
      NavItemClassEnum.WEATHERAREAS
    ],
    highlightClasses: ""
  },
  {
    navId: "navbar-options",
    label: t("optionsNavbarLabel"),
    icon: SettingsIcon,
    path: "",
    children: [
      NavItemClassEnum.HOLIDAYS,
      NavItemClassEnum.SYSTEMHEALTH,
      NavItemClassEnum.USERHISTORY,
      NavItemClassEnum.IMAGEDATABASE,
      NavItemClassEnum.INVOICINGPROFILES,
      NavItemClassEnum.CONTRACTINGPROFILES,
      NavItemClassEnum.TEMPLATEIMAGES,
      NavItemClassEnum.NOTIFICATIONPROFILES
    ],
    highlightClasses: ""
  }
];
