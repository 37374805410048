import { useEffect, useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Paper, Typography, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format, isValid, parse, sub } from "date-fns";
import {
  CompanyType,
  InvoiceReport,
  SelectFieldOption
} from "../../redux/types";
import { ENDPOINT_DATE_FORMAT, slugify } from "../../helpers/globalHelper";
import { useTranslate } from "../../services/appLanguageService";
import {
  getCompanyOptionsByTypeAPI,
  getContractorInvoiceReportPDFAPI,
  getCustomerInvoiceReportPDFAPI,
  getSearchCustomerobjectOptionsAPI
} from "../../services/api-declaration";
import PDFViewer from "../PDFViewer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LoadingSpinnerPaper from "../LoadingSpinnerPaper";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    margin: `${theme.spacing(3)}px 0`
  },
  paperHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseLine"
  },
  pageHeader: {
    paddingBottom: theme.spacing(1)
  },
  conditional: {
    textAlign: "center",
    alignSelf: "center"
  },
  loadingContainer: {
    marginTop: 45
  }
}));

interface BaseInvoiceReportProps {
  companyTypes: CompanyType[];
}

const NOW = new Date();
const START_DATE = format(sub(NOW, { weeks: 1 }), ENDPOINT_DATE_FORMAT);
const END_DATE = format(NOW, ENDPOINT_DATE_FORMAT);

function BaseInvoiceReport({ companyTypes }: BaseInvoiceReportProps) {
  const classes = useStyles();

  const t = useTranslate("ReportsPage");
  const [companyOptions, setCompanyOptions] = useState<SelectFieldOption[]>();
  const [customerobjectOptions, setCustomerobjectOptions] =
    useState<SelectFieldOption[]>();
  const [report, setReport] = useState<InvoiceReport>({
    start: START_DATE,
    end: END_DATE,
    company: null,
    customer_objects: [],
    person: null
  });
  const [reportPdf, setReportPdf] = useState<Blob>();
  const [loadingReportPdf, setLoadingReportPdf] = useState<boolean>();

  useEffect(() => {
    let isAlive = true;
    (async () => {
      const [customerobjectsResponse, companiesResponse] = await Promise.all([
        getSearchCustomerobjectOptionsAPI(),
        getCompanyOptionsByTypeAPI(companyTypes)
      ]);

      if (isAlive) {
        setCustomerobjectOptions(
          customerobjectsResponse.results.map((customerobject) => ({
            label: customerobject.name,
            value: customerobject.id
          }))
        );
        setCompanyOptions(
          companiesResponse.results.map((company) => ({
            label: company.name,
            value: company.id
          }))
        );
      }
    })();

    return () => {
      isAlive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValidReport =
    (report.company || report.customer_objects?.length === 1) &&
    report.start &&
    isValid(parse(report.start, ENDPOINT_DATE_FORMAT, new Date())) &&
    report.end &&
    isValid(parse(report.end, ENDPOINT_DATE_FORMAT, new Date()));

  useEffect(() => {
    let alive = true;
    setLoadingReportPdf(true);

    if (isValidReport) {
      void (
        companyTypes.includes("CONTRACTOR")
          ? getContractorInvoiceReportPDFAPI(report)
          : getCustomerInvoiceReportPDFAPI(report)
      ).then((blob) => {
        if (alive) {
          setReportPdf(blob);
          setLoadingReportPdf(false);
        }
      });
    } else {
      setLoadingReportPdf(false);
      setReportPdf(undefined);
    }

    return () => {
      alive = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, isValidReport]);

  const companyName = report?.company
    ? companyOptions?.find((c) => c.value === report?.company)?.label
    : undefined;
  const customerObjectName =
    report?.customer_objects?.length === 1
      ? customerobjectOptions?.find(
          (co) => co.value === report?.customer_objects?.[0]
        )?.label
      : undefined;
  const reportName = `${t("filenamePrefix")}_${slugify(
    customerObjectName ?? companyName ?? ""
  )}_${report?.start ?? ""}-${report?.end ?? ""}`;

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <Typography
            component="h1"
            variant="h6"
            className={classes.pageHeader}
          >
            {companyTypes.includes("CUSTOMER")
              ? t("customerPageTitle")
              : t("contractorPageTitle")}
          </Typography>
        </div>
        <Grid container spacing={2} direction="row">
          <Grid item xs={2}>
            <Autocomplete
              value={
                companyOptions?.find((o) => o.value === report?.company) ?? null
              }
              options={companyOptions ?? []}
              loading={!companyOptions}
              onChange={(_, value) => {
                setReport((s) =>
                  s
                    ? {
                        ...s,
                        customer_objects: [],
                        company: value?.value ?? null
                      }
                    : s
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    companyTypes.includes("CUSTOMER")
                      ? t("customerFieldLabel")
                      : t("contractorFieldLabel")
                  }
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={1} className={classes.conditional}>
            <Typography>{t("orLabel")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              value={
                customerobjectOptions?.find(
                  (o) => o.value === report?.customer_objects?.[0]
                ) ?? null
              }
              options={customerobjectOptions ?? []}
              loading={!customerobjectOptions}
              onChange={(_, value) => {
                setReport((s) =>
                  s
                    ? {
                        ...s,
                        company: null,
                        customer_objects: value ? [value?.value] : []
                      }
                    : s
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("customerObjectFieldLabel")}
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.label}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          direction="row"
          style={{ marginTop: "20px" }}
        >
          <Grid item xs={2}>
            <KeyboardDatePicker
              label={t("startFieldLabel")}
              value={report?.start ?? START_DATE}
              format="yyyy-MM-dd"
              onChange={(_, value) => {
                setReport((s) => (s ? { ...s, start: value ?? "" } : s));
              }}
              disableFuture
            />
          </Grid>
          <Grid item xs={1} className={classes.conditional}>
            <Typography>-</Typography>
          </Grid>
          <Grid item xs={2}>
            <KeyboardDatePicker
              label={t("endFieldLabel")}
              value={report?.end ?? END_DATE}
              format="yyyy-MM-dd"
              onChange={(_, value) => {
                setReport((s) => (s ? { ...s, end: value ?? "" } : s));
              }}
              disableFuture
            />
          </Grid>
        </Grid>
      </Paper>
      {loadingReportPdf ? (
        <div className={classes.loadingContainer}>
          <LoadingSpinnerPaper />
        </div>
      ) : (
        reportPdf && <PDFViewer pdfBlob={reportPdf} pdfName={reportName} />
      )}
    </>
  );
}

export default BaseInvoiceReport;
