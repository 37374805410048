import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import AuthorizationDialog from "../components/AuthorizationDialog";
import {
  FormikSubmitDispatchProps,
  LoginSubmitValues,
  mainRootState
} from "../redux/types";
import * as AuthenticationActions from "../redux/reducers/authentication/actions";

interface AuthorizationDialogContainerProps
  extends FormikSubmitDispatchProps<LoginSubmitValues> {
  authorizationDialog: boolean;
  username: string;
  domain: string;
  isLoginError: boolean;
  authenticating: boolean;
}

const AuthorizationDialogContainer: React.FC<
  AuthorizationDialogContainerProps
> = (props) => {
  return (
    <AuthorizationDialog
      handleSubmit={props.handleSubmit}
      open={props.authorizationDialog}
      username={props.username}
      domain={props.domain}
      isLoginError={props.isLoginError}
      authenticating={props.authenticating}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch
): FormikSubmitDispatchProps<LoginSubmitValues> => ({
  handleSubmit: (values, actions) => {
    actions.setSubmitting(false);
    dispatch(
      AuthenticationActions.authenticate(
        values.domain,
        values.username,
        values.password,
        values.rememberMe
      )
    );
  }
});

const mapStateToProps = (state: mainRootState) => ({
  authorizationDialog: state.authentication.authorizationDialog,
  username: state.authentication.username,
  domain: state.authentication.domain,
  isLoginError: state.authentication.isLoginError,
  authenticating: state.authentication.authenticating
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizationDialogContainer);
