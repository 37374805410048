import { useEffect, useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Paper, Typography, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format, isValid, parse, sub } from "date-fns";
import { ENDPOINT_DATE_FORMAT, slugify } from "../../helpers/globalHelper";
import { useTranslate } from "../../services/appLanguageService";
import {
  getContractorInvoiceReportPDFAPI,
  getPersonsAPI,
} from "../../services/api-declaration";
import PDFViewer from "../PDFViewer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TabComponentProps } from "../../redux/types";
import LoadingSpinner from "../LoadingSpinner";
import { validateApiUsage } from "../../helpers/routesHelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    margin: `${theme.spacing(3)}px 0`
  },
  paperHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseLine"
  },
  pageHeader: {
    paddingBottom: theme.spacing(2)
  },
  pageDescription: {
    whiteSpace: "pre",
    paddingBottom: theme.spacing(2)
  },
  conditional: {
    textAlign: "center",
    alignSelf: "center"
  }
}));

const NOW = new Date();
const START_DATE = format(sub(NOW, { weeks: 1 }), ENDPOINT_DATE_FORMAT);
const END_DATE = format(NOW, ENDPOINT_DATE_FORMAT);

type ReportsProps = TabComponentProps;

export const ContractorReports: React.FC<ReportsProps> = () => {
  const classes = useStyles();

  const t = useTranslate("ReportsPage");
  const [personOptions, setPersonOptions] =
    useState<{ label: string; value: number }[]>();
  const [report, setReport] = useState<{
    start: string;
    end: string;
    person: number;
    customer_objects: number[];
  }>({
    start: START_DATE,
    end: END_DATE,
    person: 0,
    customer_objects: []
  });
  const [reportPdf, setReportPdf] = useState<Blob>();

  const hideForAllCompanyAccess = validateApiUsage({ apiUsage: ["features/allcompanies"] })

  useEffect(() => {
    let alive = true;
    void getPersonsAPI()
      .then(({ results: persons }) => {
      if (alive) {
        setPersonOptions(
          persons.map((p) => ({
            label: `${p.first_name} ${p.last_name}`,
            value: p.id
          }))
        );
      }
    });
    return () => {
      alive = false;
    };
  }, []);

  const isValidReport =
    report.start &&
    isValid(parse(report.start, ENDPOINT_DATE_FORMAT, new Date())) &&
    report.end &&
    isValid(parse(report.end, ENDPOINT_DATE_FORMAT, new Date()));

  useEffect(() => {
    setReportPdf(undefined);
    if (!hideForAllCompanyAccess && isValidReport) {
      let alive = true;
      const request = {
        start: report.start,
        end: report.end,
        company: null,
        person: report.person || null,
        customer_objects: [],
      };
      void getContractorInvoiceReportPDFAPI(request).then(
        (reportPdf) => alive && setReportPdf(reportPdf)
      );
      return () => {
        alive = true;
      };
    }
  }, [hideForAllCompanyAccess, isValidReport, report]);

  const personName = personOptions?.find((o) => o.value === report.person)?.label
  const reportName = `${t("filenamePrefix")}_${slugify(personName ?? "all")}_${report?.start ?? ""}-${report?.end ?? ""}`;

  return hideForAllCompanyAccess ? (
    <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <Typography
            component="h1"
            variant="h6"
            className={classes.pageHeader}
          >
            {t("contractorReportsHeadingText")}
          </Typography>
        </div>
        <div style={{ margin: "20px" }}>
          <Typography>
            {t("hiddenForAdmin")}
          </Typography>
        </div>
    </Paper>
   ) : (
    <>
      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <Typography
            variant="h6"
          >
            {t("contractorReportsHeadingText")}
          </Typography>
        </div>
        <div>
          <Typography
            variant="subtitle2"
            className={classes.pageDescription}
          >
            {t("contractorReportsDescription")}
          </Typography>
        </div>
        <Grid container spacing={2} direction="row">
          <Grid item xs={3}>
            <Autocomplete
              value={
                personOptions?.find((o) => o.value === report.person) ?? null
              }
              options={personOptions ?? []}
              loading={!personOptions}
              onChange={(_, value) =>
                setReport((s) => ({ ...s, person: value?.value ?? 0 }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("personFieldLabel")}
                  fullWidth
                />
              )}
              getOptionLabel={(o) => o.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={1} className={classes.conditional}></Grid>
          <Grid item xs={3} />
        </Grid>

        <Grid
          container
          spacing={2}
          direction="row"
          style={{ marginTop: "20px" }}
        >
          <Grid item xs={3}>
            <KeyboardDatePicker
              label={t("startFieldLabel")}
              value={report?.start ?? START_DATE}
              format="yyyy-MM-dd"
              onChange={(_, value) => {
                setReport((s) => (s ? { ...s, start: value ?? "" } : s));
              }}
              disableFuture
              fullWidth
            />
          </Grid>
          <Grid item xs={1} className={classes.conditional}>
            <Typography>-</Typography>
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              label={t("endFieldLabel")}
              value={report?.end ?? END_DATE}
              format="yyyy-MM-dd"
              onChange={(_, value) => {
                setReport((s) => (s ? { ...s, end: value ?? "" } : s));
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Paper>
      {isValidReport && !reportPdf && <LoadingSpinner />}
      {reportPdf && <PDFViewer pdfBlob={reportPdf} pdfName={reportName} />}
    </>
  );
};
