import React, { FC } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  TableHead,
  TableSortLabel,
  TablePagination,
  Toolbar,
  Typography,
  Button
} from "@material-ui/core";
import clsx from "clsx";
import { INotificationProfile } from "../../redux/types";
import { history } from "../../redux/store";
import { useTranslate } from "../../services/appLanguageService";
import { getNotificationProfilesAPI } from "../../services/api-declaration";
import LoadingSpinnerPaper from "../LoadingSpinnerPaper";
import NoItemsText from "../NoItemText";

function desc(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array: INotificationProfile[], cmp: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function getSorting(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy);
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: "1 1 100%"
  },
  title: {
    flex: "0 0 auto"
  }
}));

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();
  const t = useTranslate("NotificationProfile");
  return (
    <Toolbar className={clsx(classes.root)}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {t("pageHeading")}
        </Typography>
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};

interface EnhancedTableHeadProps {
  order: "asc" | "desc" | false;
  orderBy: string;
  onRequestSort: (_: any, property: any) => void;
  rowCount: number;
}

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const t = useTranslate("NotificationProfile");
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  // Columns
  const headRows = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("nameLabel")
    },
    {
      id: "notes",
      numeric: false,
      disablePadding: false,
      label: t("notesLabel")
    }
  ];

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? "right" : "left"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={typeof order === "string" ? order : "asc"}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  mb20: {
    marginBottom: theme.spacing(2.5)
  },
  fab: {
    height: "30px",
    width: "30px"
  },
  editIcon: {
    fontSize: 18
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    maxHeight: "calc(100VH - 226px)",
    overflowX: "auto"
  }
}));

interface NotificationProfilesProps {
  className?: string;
}

const NotificationProfiles: FC<NotificationProfilesProps> = (props) => {
  const classes = useStyles();
  const t = useTranslate("NotificationProfile");
  const [order, setOrder] = React.useState<"asc" | "desc" | false>("asc");
  const [orderBy, setOrderBy] = React.useState("title");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [profiles, setProfiles] = React.useState<INotificationProfile[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const initGetProfiles = async () => {
    try {
      setIsLoading(true);
      const response = await getNotificationProfilesAPI();
      setProfiles(response.results);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
    }
  };
  const cb = React.useCallback(initGetProfiles, []);
  React.useEffect(() => {
    cb();
  }, [cb]);

  const handleRequestSort = (event: any, property: any) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigate = (_url: string) => (event: any) => {
    event.stopPropagation();
    history.replace(_url);
  };

  return (
    <div>
      <Button
        data-cy="profile-new"
        variant="contained"
        color="primary"
        className={classes.mb20}
        onClick={navigate("/notification-profiles/add-new")}
      >
        {t("addNewBtn")}
      </Button>
      <Paper className={props.className}>
        <EnhancedTableToolbar />
        {isLoading ? (
          <LoadingSpinnerPaper />
        ) : profiles.length ? (
          <>
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={profiles.length || 0}
                />
                <TableBody>
                  {stableSort(profiles, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((profile: INotificationProfile) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={profile.id}
                        onClick={navigate(
                          `/notification-profiles/${profile.id}`
                        )}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell align="left">{profile.name}</TableCell>
                        <TableCell align="left">
                          {profile.notes || "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={profiles.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <NoItemsText />
        )}
      </Paper>
    </div>
  );
};

export default NotificationProfiles;
