import { LayoutRouteUsage } from "../../../redux/types";
import WeatherAreaContainer from "../../../containers/companies/weatherAreas/WeatherAreaContainer";
import WeatherAreaEditFormContainer from "../../../containers/companies/weatherAreas/WeatherAreaEditFormContainer";
import WeatherAreaNewFormContainer from "../../../containers/companies/weatherAreas/WeatherAreaNewFormContainer";
import WeatherAreasContainer from "../../../containers/companies/weatherAreas/WeatherAreasContainer";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import CloudIcon from "@material-ui/icons/Cloud";

const WeatherAreasRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["companies/weatherareas"],
    requireSuperuser: true,
    routes: [
      {
        path: "/companies/weatherareas",
        label: t("companyNavbarWeatherAreasLabel"),
        component: WeatherAreasContainer,
        icon: CloudIcon,
        highlightClass: NavItemClassEnum.WEATHERAREAS,
        navId: NavItemClassEnum.WEATHERAREAS,
      },
      {
        path: "/companies/weatherareas/new",
        parent: "/companies/weatherareas",
        label: t("newWeatherAreaLabel"),
        component: WeatherAreaNewFormContainer,
        highlightClass: NavItemClassEnum.WEATHERAREAS,
      },
      {
        path: "/companies/weatherareas/:weatherAreaId",
        parent: "/companies/weatherAreas",
        label: t("weatherAreaLabel"),
        component: WeatherAreaContainer,
        highlightClass: NavItemClassEnum.WEATHERAREAS,
      },
      {
        path: "/companies/weatherareas/:weatherAreaId/edit",
        parent: "/companies/weatherareas/:weatherAreaId",
        label: t("editWeatherAreaLabel"),
        component: WeatherAreaEditFormContainer,
        highlightClass: NavItemClassEnum.WEATHERAREAS,
      },
    ],
  },
];

export default WeatherAreasRouter;
