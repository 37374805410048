import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, CircularProgress, WithStyles } from "@material-ui/core";
import { fetchPatch } from "../services/fetchWrapper";
import { restUrl } from "../services/api-declaration";
import { useTranslate } from "../services/appLanguageService";
import {
  ConfirmationDialogServiceProvider,
  useConfirmationDialog
} from "./confirmationDialog/ConfirmationDialogServiceProvider";

const styles = (theme: any) =>
  createStyles({
    checkbox: {
      padding: 0
    }
  });

interface EditableCheckBoxFieldProps extends WithStyles<typeof styles> {
  field: string;
  initValue: boolean;
  url: string;
  updateResourceState: (newValue: any) => void;
  label: string;
  readonly?: boolean;
}

const EditableCheckBoxField: React.FC<EditableCheckBoxFieldProps> = (props) => {
  const { classes } = props;

  const t = useTranslate("EditableFieldComponent");

  const confirmationDialog = useConfirmationDialog();
  const [loading, setLoading] = useState(false);

  const updateResource = async (newValue: boolean) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: `${t("confirmationDescription")} ${
        newValue ? t("turnOnLabel") : t("turnOffLabel")
      } ${props.label.toLowerCase()}?`
    });

    setLoading(true);
    const response = await fetchPatch(restUrl(props.url), {
      [props.field]: newValue
    });
    setLoading(false);
    props.updateResourceState(response);
  };

  return loading ? (
    <CircularProgress size={18} />
  ) : (
    <Checkbox
      data-cy={`${props.field}-field`}
      disabled={props.readonly}
      className={classes.checkbox}
      size="small"
      checked={props.initValue}
      onChange={(event: any) => updateResource(event.target.checked)}
    />
  );
};

export default withStyles(styles)((props: EditableCheckBoxFieldProps) => (
  <ConfirmationDialogServiceProvider>
    <EditableCheckBoxField {...props} />
  </ConfirmationDialogServiceProvider>
));
