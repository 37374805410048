import { LayoutRouteUsage } from "../../redux/types";
import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import DirectionsIcon from "@material-ui/icons/Directions";
import RouteInstancesPage from "../../components/routes/routeinstances/RouteInstancesPage";
import RouteInstanceForm from "../../components/routes/routeinstances/RouteInstanceForm";

const RouteInstancesRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["orders/routeinstances"],
    routes: [
      {
        path: "/orders/routeinstances",
        label: t("ordersNavbarRouteInstancesLabel"),
        component: RouteInstancesPage,
        icon: DirectionsIcon,
        highlightClass: NavItemClassEnum.ORDERS,
        navId: NavItemClassEnum.ORDERS
      },
      {
        path: "/orders/routeinstances/new",
        parent: "/orders/routeinstances",
        label: t("routeInstanceNewLabel"),
        component: RouteInstanceForm,
        highlightClass: NavItemClassEnum.ORDERS
      }
    ]
  }
];

export default RouteInstancesRouter;
