import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, InvoiceRecipient, Person } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import {
  RestStrings,
  ReduxActionStrings,
  EnumStrings
} from "../../../redux/strings";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";
import * as Yup from "yup";
import { useTranslate } from "../../../services/appLanguageService";

interface InvoiceRecipientsContainerProps {
  invoiceRecipients: InvoiceRecipient[];
  count: number;
  loadingInvoiceRecipients: boolean;
  invoiceRecipientIds?: number[];
  persons: Person[];
  resetInvoiceRecipients: () => void;
  loadInvoiceRecipients: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  loadPersons: (companyId: number) => void;
  addInvoiceRecipient: (companyId: number, newData: any) => void;
  updateInvoiceRecipient: (invoiceRecipientId: number, newData: any) => void;
  deleteInvoiceRecipient: (invoiceRecipientId: number) => void;
  companyId: number;
  showNewButton?: boolean;
  customHeader?: string;
}

const InvoiceRecipientsContainer: React.FC<InvoiceRecipientsContainerProps> = (
  props
) => {
  const {
    invoiceRecipientIds,
    loadInvoiceRecipients,
    resetInvoiceRecipients,
    loadingInvoiceRecipients,
    invoiceRecipients,
    companyId,
    persons,
    loadPersons
  } = props;

  const page = 1;
  const pageSize = 20;
  let filter = "";

  const t2 = useTranslate("InvoiceRecipients");
  const t3 = useTranslate("ValidationErrorMessages");

  if (invoiceRecipientIds) {
    const filterStr =
      invoiceRecipientIds.length !== 1 ? "filter__id__in" : "filter__id";
    const invoiceRecipientIdsStr = invoiceRecipientIds.length
      ? invoiceRecipientIds.toString()
      : "";

    filter = `&${filterStr}=${invoiceRecipientIdsStr}`;
  }

  useEffect(
    () => loadInvoiceRecipients(page, pageSize, undefined, undefined, filter),
    [filter, loadInvoiceRecipients]
  );
  useEffect(() => () => resetInvoiceRecipients(), [resetInvoiceRecipients]);
  useEffect(() => loadPersons(companyId), [loadPersons, companyId]);

  const invoiceRecipientTypes = [
    EnumStrings.MAIN,
    EnumStrings.CC,
    EnumStrings.BCC
  ];

  const columnDefinitions = [
    {
      name: "email",
      type: "text",
      editableForm: "text",
      validation: Yup.string()
        .min(1, t3("minLengthError"))
        .max(150, t3("max150LengthError"))
        .email(t3("emailInvalidError"))
        .required(t3("requiredError"))
    },
    {
      name: "type",
      type: "enum",
      editableForm: "select",
      options: invoiceRecipientTypes.map((type) => ({
        label: t2(type),
        value: type
      })),
      validation: Yup.string()
        .oneOf(invoiceRecipientTypes, t3("notValidEnumValueError"))
        .required(t3("requiredError"))
    },
    {
      name: "person",
      type: "foreignLink",
      foreignData: {
        data: persons,
        visibleColumns: ["first_name", "last_name"],
        url: "auth/persons"
      },
      editableForm: "select",
      options: persons.map((person) => ({
        label: `${person.first_name} ${person.last_name || ""}`,
        value: person.id
      })),
      validation: Yup.number().nullable().notRequired()
    }
  ];

  return (
    <ResourcesTableContainer
      resources={invoiceRecipients}
      resourceName={"InvoiceRecipient"}
      count={props.count}
      loadingResources={loadingInvoiceRecipients}
      routeUrl={"companies/invoicerecipients"}
      columnDefinitions={columnDefinitions}
      showNewButton={false}
      filterDefinitions={[]}
      customFilter={filter}
      resetResources={resetInvoiceRecipients}
      defaultPageSize={pageSize}
      loadResources={loadInvoiceRecipients}
      customHeader={props.customHeader}
      owningId={companyId}
      addResource={props.addInvoiceRecipient}
      updateResource={props.updateInvoiceRecipient}
      deleteResource={props.deleteInvoiceRecipient}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadInvoiceRecipients: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__email__contains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__email=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.INVOICERECIPIENTS,
        `companies/invoicerecipients/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  resetInvoiceRecipients: () =>
    dispatch(LoadingBooleansActions.ResetInvoiceRecipients(ownProps.tabId)),
  loadPersons: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.INVOICERECIPIENTS_PERSONS,
        `auth/persons/?filter__company=${companyId}&filter__status=ACTIVE`
      )
    );
  },
  addInvoiceRecipient: (newData: any, companyId: number) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.INVOICERECIPIENTS,
        "companies/invoicerecipients/",
        {
          company: companyId,
          email: newData.email,
          type: newData.type || null,
          person: newData.person || null
        }
      )
    );
  },
  updateInvoiceRecipient: (invoiceRecipientId: number, newData: any) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.INVOICERECIPIENT,
        `companies/invoicerecipients/${invoiceRecipientId}/`,
        {
          email: newData.email,
          type: newData.type || null,
          person: newData.person || null
        }
      )
    );
  },
  deleteInvoiceRecipient: (invoiceRecipientId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.INVOICERECIPIENT,
        `companies/invoicerecipients/${invoiceRecipientId}/`,
        undefined,
        undefined,
        invoiceRecipientId
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  invoiceRecipients:
    state.tabStates[ownProps.tabId].invoiceRecipients.invoiceRecipients.results,
  count:
    state.tabStates[ownProps.tabId].invoiceRecipients.invoiceRecipients.count,
  loadingInvoiceRecipients:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingInvoiceRecipients,
  persons: state.tabStates[ownProps.tabId].invoiceRecipients.persons
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceRecipientsContainer);
