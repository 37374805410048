import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  mainRootState,
  Person,
  User,
  FormikSubmitDispatchProps,
  CreateUserForm,
  TabComponentProps
} from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import PersonView from "../../components/persons/Person";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import LoadingSpinnerPaper from "../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface PersonContainerProps
  extends TabComponentProps<"personId">,
    FormikSubmitDispatchProps<CreateUserForm> {
  person: Person;
  loadingPerson: boolean;
  user: User;
  loadUser: (userId: number | null) => void;
  deleteUser: (userId: number) => void;
  deletePerson: (personId: number) => void;
  resetPerson: () => void;
  loadPerson: (personId: number) => void;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<CreateUserForm> {
  loadPerson: (personId: number) => void;
  loadUser: (userId: number | null) => void;
  deleteUser: (userId: number) => void;
  deletePerson: (personId: number) => void;
  resetPerson: () => void;
}

const PersonContainer: React.FC<PersonContainerProps> = (props) => {
  const {
    resetPerson,
    loadingPerson,
    loadPerson,
    person,
    loadUser,
    user,
    handleSubmit,
    deletePerson,
    deleteUser,
    routeParams: { personId }
  } = props;
  useEffect(() => loadPerson(personId), [loadPerson, personId]);
  useEffect(() => loadUser(person.user), [loadUser, person.user]);
  useEffect(() => () => resetPerson(), [resetPerson]);

  return (
    <div>
      {loadingPerson ? (
        <LoadingSpinnerPaper />
      ) : (
        <PersonView
          person={person}
          user={user}
          handleSubmit={handleSubmit}
          deleteUser={deleteUser}
          loadPerson={loadPerson}
          deletePerson={deletePerson}
          {...mapTabRouteProps(props)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  loadPerson: (personId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSON,
        `auth/persons/${personId}/`
      )
    );
  },
  loadUser: (userId: number | null) => {
    if (userId && userId !== -1) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.USER,
          `auth/users/${userId}/`
        )
      );
    }
  },
  handleSubmit: (values, actions) => {
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<CreateUserForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.USER,
        "auth/users/",
        values
      )
    );
  },
  deleteUser: (userId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.USER,
        `auth/users/${userId}/`,
        undefined,
        undefined,
        userId
      )
    );
  },
  deletePerson: (personId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.PERSON,
        `auth/persons/${personId}/`,
        "/auth/persons",
        undefined,
        personId
      )
    );
  },
  resetPerson: () =>
    dispatch(LoadingBooleansActions.ResetPerson(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  person: state.tabStates[ownProps.tabId].persons.person.person,
  user: state.tabStates[ownProps.tabId].users.user.user,
  loadingPerson: state.tabStates[ownProps.tabId].loadingBooleans.loadingPerson
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonContainer);
