import { useTranslate } from "../services/appLanguageService";
import { LayoutRouteUsage, LayoutRoute } from "../redux/types";
import {
  InvoicesSubIndexesEnum,
  NavItemClassEnum
} from "../strings/TabRouterStrings";
import { makeRoutePathStatic } from "../helpers/routesHelper";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import FilterIcon from "@material-ui/icons/Filter";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import TerrainIcon from "@material-ui/icons/Terrain";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import EventIcon from "@material-ui/icons/PriorityHigh";


import PersonsRouter from "../containers/persons/PersonsRouter";
import GroupsRouter from "../containers/groups/GroupsRouter";
import RouteInstancesRouter from "../containers/routeinstances/RouteInstancesRouter";
import ContractsRouter from "../containers/contracts/contracts/ContractsRouter";
import CustomerObjectRouter from "../containers/contracts/customerobjects/CustomerObjectsRouter";
import TasksRouter from "../containers/contracts/tasks/TasksRouter";
import BusinessAreaRouter from "../containers/contracts/businessAreas/BusinessAreasRouter";
import ProjectsRouter from "../containers/contracts/projects/ProjectsRouter";
import ServicesRouter from "../containers/contracts/services/ServicesRouter";
import ServicePackagesRouter from "../containers/contracts/servicepackages/ServicePackagesRouter";
import MaterialsRouter from "../containers/contracts/materials/MaterialsRouter";
import AccessoriesRouter from "../containers/contracts/accessories/AccessoriesRouter";
import AddonsRouter from "../containers/contracts/addons/AddonsRouter";
import SupplimentsRouter from "../containers/contracts/supplements/SupplementsRouter";
import AfterRegistrateRouter from "./timesheet/TimesheetsRouter";
import InvoicingProfilesRouter from "../containers/invoices/invoicingProfiles/InvoicingProfilesRouter";
import MyTasksRouter from "./my-tasks/MyTasksRouter";
import ProspectsRouter from "../containers/contracts/prospects/ProspectsRouter";
import TermsRouter from "../containers/contracts/terms/termsRouter";
import CooperationRouter from "../containers/contracts/cooperations/CooperationRouter";
import NotificationsRouter from "./notifications/NotificationsRouter";
import ContraProfilesRouter from "../containers/contracts/contractingProfiles/ContractingProfilesRouter";
import TemplateImagesRouter from "../containers/templateimages/TemplateImagesRouter";
import UsersRouter from "../containers/users/UsersRouter";
import WeatherAreasRouter from "./companies/weatherAreas/WeatherAreasRouter";
import CompaniesRouter from "./companies/CompaniesRouter";
import PasscardsRouter from "./companies/passcards/PasscardsRouter";
import DistrictsRouter from "./companies/districts/DistrictsRouter";
import EmailsRouter from "./emails/EmailsRouter";
import WorkLogRouter from "./WorkLogRouter";

import Home from "./home/Home";
import SearchSuggestions from "./searchSuggestions/SearchSuggestions";
import HolidaysContainer from "../containers/holidays/HolidaysContainer";
import UnapprovedWorkComponent from "./unapprovedWork/UnapprovedWork";
// import TimeSheetSettings from "./timesheet/TimeSheetSettings";
import Invoices from "./invoices/Invoices";
import Reports from "./invoices/Reports";
import ContractingProfilesContainer from "../containers/contracts/contractingProfiles/ContractingProfilesContainer";
import UnderConstruction from "./UnderConstruction";
import ResetPassword from "./resetPassword/ResetPassword";
import ForgottenPassword from "./forgottenPassword/ForgottenPassword";
import { Beenhere } from "@material-ui/icons";
import TimesheetApprovalRouter from "../containers/verification/TimesheetApprovalRouter";
import ErrorPage from "./errorPage/ErrorPage";
import LiveViewRouter from "./LiveViewRouter";
import ModifyOngoingWork from "./routes/routeinstances/modify_ongoing/ModifyOngoingWork";
import Events from "./events/Events";
import InvoicePDFViewer from "./invoices/InvoicePDFViewer";
import InvoiceView from "./invoices/invoice/InvoiceView";
import ResetPasswordSuccess from "./resetPassword/ResetPasswordSuccess";
import MyProfile from "../components/persons/MyProfile";
import { ContractorReports } from "./invoices/ContractorReports";

export const AppRouterDec: LayoutRouteUsage = (t) => [
  {
    apiUsage: [],
    routes: [
      {
        path: "/",
        label: t("dashboardLabel"),
        component: Home,
        icon: HomeIcon,
        highlightClass: NavItemClassEnum.DASHBOARD,
        navId: NavItemClassEnum.DASHBOARD,
        suspendable: true
      },
      {
        path: "/search-suggestions",
        label: t("searchLabel"),
        component: SearchSuggestions
      },
      {
        path: "/auth/my-profile",
        parent: "/auth/persons",
        label: t("personLabel"),
        component: MyProfile
      },
      {
        path: "/error",
        label: t("errorPage"),
        component: ErrorPage,
        suspendable: true
      }
    ]
  },
  ...UsersRouter(t),
  ...PersonsRouter(t),
  ...GroupsRouter(t),
  ...RouteInstancesRouter(t),
  ...ContractsRouter(t),
  ...CustomerObjectRouter(t),
  ...TasksRouter(t),
  ...BusinessAreaRouter(t),
  ...ProjectsRouter(t),
  ...ServicesRouter(t),
  ...ServicePackagesRouter(t),
  ...MaterialsRouter(t),
  ...AccessoriesRouter(t),
  ...AddonsRouter(t),
  ...SupplimentsRouter(t),
  ...AfterRegistrateRouter(t),
  ...MyTasksRouter(t),
  ...InvoicingProfilesRouter(t),
  ...ProspectsRouter(t),
  ...CompaniesRouter(t),
  ...PasscardsRouter(t),
  ...DistrictsRouter(t),
  ...EmailsRouter(t),
  ...TermsRouter(t),
  ...CooperationRouter(t),
  ...WeatherAreasRouter(t),
  ...NotificationsRouter(t),
  ...ContraProfilesRouter(t),
  ...TemplateImagesRouter(t),
  ...TimesheetApprovalRouter(t),
  ...LiveViewRouter(t),
  ...WorkLogRouter(t),
  {
    apiUsage: ["events/events"],
    requireSuperuser: true,
    routes: [
      {
        path: "/events",
        label: t("eventsNavbarLabel"),
        component: Events,
        icon: EventIcon,
        highlightClass: NavItemClassEnum.EVENTS,
        navId: NavItemClassEnum.EVENTS
      }
    ]
  },
  {
    apiUsage: ["orders/holidays"],
    requireSuperuser: true,
    routes: [
      {
        path: "/holidays/:id",
        label: t("holidaysNavbarLabel"),
        component: HolidaysContainer,
        icon: CalendarTodayIcon,
        highlightClass: NavItemClassEnum.HOLIDAYS
      },
      {
        path: "/holidays",
        label: t("holidaysNavbarLabel"),
        component: HolidaysContainer,
        icon: CalendarTodayIcon,
        highlightClass: NavItemClassEnum.HOLIDAYS,
        navId: NavItemClassEnum.HOLIDAYS
      }
    ]
  },
  {
    apiUsage: ["timesheets/timesheets"],
    routes: [
      {
        path: "/unapproved-work",
        label: t("UnplannedWorkNavbarLabel"),
        component: UnapprovedWorkComponent
      }
      //   {
      //     path: "/timesheets/addNew/:contractorId",
      //     parent: "/timesheets",
      //     label: "Add New",
      //     component: TimeSheetSettings
      //   }
    ]
  },
  {
    apiUsage: ["invoices/customerinvoices"],
    requireSuperuser: true,
    routes: [
      {
        path: "/invoices/customerinvoices",
        label: t("customerInvoicesLabel"),
        component: Invoices,
        subTabIndex: InvoicesSubIndexesEnum.CUSTOMER,
        icon: AttachMoneyIcon,
        highlightClass: NavItemClassEnum.INVOICES,
        navId: NavItemClassEnum.INVOICES,
        navLabel: t("invoicesLabel")
      },
      {
        path: "/invoices/customerinvoices/:invoiceId",
        parent: "/invoices/customerinvoices",
        label: t("customerInvoicesLabel"),
        component: InvoiceView,
        subTabIndex: InvoicesSubIndexesEnum.CUSTOMER,
        highlightClass: NavItemClassEnum.INVOICES,
        suspendable: true
      },
      {
        path: "/invoices/customerinvoices/:invoiceId/preview-pdf",
        parent: "/invoices/customerinvoices",
        label: t("customerInvoicesLabel"),
        component: InvoicePDFViewer,
        subTabIndex: InvoicesSubIndexesEnum.CUSTOMER,
        highlightClass: NavItemClassEnum.INVOICES
      }
    ]
  },
  {
    apiUsage: ["invoices/contractorinvoices", "invoices/contractorreports"],
    requireSuperuser: true,
    routes: [
      {
        path: "/invoices/contractorinvoices",
        label: t("contractorInvoicesLabel"),
        component: Invoices,
        subTabIndex: InvoicesSubIndexesEnum.CONTRACTOR,
        highlightClass: NavItemClassEnum.INVOICES
      },
      {
        path: "/invoices/contractorinvoices/:invoiceId",
        label: t("contractorInvoicesLabel"),
        component: InvoiceView,
        subTabIndex: InvoicesSubIndexesEnum.CONTRACTOR,
        highlightClass: NavItemClassEnum.INVOICES,
        suspendable: true
      },
      {
        path: "/invoices/contractorinvoices/:invoiceId/preview-pdf",
        parent: "/invoices/contractorinvoices",
        label: t("contractorInvoicesLabel"),
        component: InvoicePDFViewer,
        subTabIndex: InvoicesSubIndexesEnum.CONTRACTOR,
        highlightClass: NavItemClassEnum.INVOICES
      }
    ]
  },
  {
    apiUsage: ["invoices/contractorreports"],
    requireSuperuser: true,
    routes: [
      {
        path: "/invoices/contractorreports",
        label: t("contractorReportsLabel"),
        component: Reports,
        subTabIndex: 1,
        highlightClass: NavItemClassEnum.REPORTS
      }
    ]
  },
  {
    apiUsage: ["invoices/customerreports"],
    requireSuperuser: true,
    routes: [
      {
        path: "/invoices/customerreports",
        label: t("customerReportsLabel"),
        component: Reports,
        subTabIndex: 0,
        icon: InsertDriveFileIcon,
        highlightClass: NavItemClassEnum.REPORTS,
        navId: NavItemClassEnum.REPORTS,
        navLabel: t("reportsLabel")
      }
    ]
  },
  {
    apiUsage: ["features/contractorreport"],
    routes: [
      {
        path: "/contractor/reports",
        label: t("contractorReportsLabel"),
        component: ContractorReports,
        icon: InsertDriveFileIcon,
        highlightClass: NavItemClassEnum.CONTRACTORREPORTS,
        navId: NavItemClassEnum.CONTRACTORREPORTS,
        navLabel: t("contractorReportsLabel")
      }
    ]
  },
  {
    apiUsage: ["timesheets/timesheets"],
    requireSuperuser: true,
    routes: [
      {
        path: "/routeinstances/modify-ongoing/:participantId",
        label: t("modifyOngoingLabel"),
        component: ModifyOngoingWork,
        subTabIndex: 0,
        icon: InsertDriveFileIcon,
        highlightClass: NavItemClassEnum.ORDERS
      }
    ]
  },
  {
    apiUsage: ["contracts/contractingprofiles"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/contractingprofiles",
        label: t("contractingProfilesNavbarContractsLabel"),
        component: ContractingProfilesContainer,
        icon: Beenhere,
        highlightClass: NavItemClassEnum.CONTRACTINGPROFILES
      },
      {
        path: "/under-construction", //TODO: Set correct apiUsage when implementing view
        label: t("underConstructionLabel"),
        component: UnderConstruction
      },
      {
        path: "/material-depots", //TODO: Set correct apiUsage when implementing view
        label: t("materialDepotsNavbarLabel"),
        component: UnderConstruction,
        icon: TerrainIcon,
        highlightClass: NavItemClassEnum.MATERIALDEPOTS,
        navId: NavItemClassEnum.MATERIALDEPOTS
      },
      {
        path: "/system-health", //TODO: Set correct apiUsage when implementing view
        label: t("systemHealthNavbarLabel"),
        component: UnderConstruction,
        icon: InsertChartIcon,
        highlightClass: NavItemClassEnum.SYSTEMHEALTH,
        navId: NavItemClassEnum.SYSTEMHEALTH
      },
      {
        path: "/user-history", //TODO: Set correct apiUsage when implementing view
        label: t("userHistoryNavbarLabel"),
        component: UnderConstruction,
        icon: PersonIcon,
        highlightClass: NavItemClassEnum.USERHISTORY,
        navId: NavItemClassEnum.USERHISTORY
      },
      {
        path: "/image-database", //TODO: Set correct apiUsage when implementing view
        label: t("imageDbNavbarLabel"),
        component: UnderConstruction,
        icon: FilterIcon,
        highlightClass: NavItemClassEnum.IMAGEDATABASE,
        navId: NavItemClassEnum.USERHISTORY
      }
    ]
  },
  {
    apiUsage: ["auth/users/set_password"],
    requireSuperuser: true,
    routes: [
      {
        path: "/reset-password",
        label: t("resetPasswordLabel"),
        component: ResetPassword
      },
      {
        path: "/reset-password-success",
        label: t("resetPasswordLabel"),
        component: ResetPasswordSuccess
      },
      {
        path: "/forget-password",
        label: "",
        component: ForgottenPassword
      }
    ]
  }
];

// eslint-disable-next-line react-hooks/rules-of-hooks
const t = useTranslate("RouteLabels");
const appRoutes: Array<{
  apiUsage: string[];
  requireSuperuser?: true;
  routes: LayoutRoute[];
}> = AppRouterDec(t) as any[];
export const AppRoutesStatic: LayoutRoute[] = [];
export const AppStaticRoutesMap = new Map<string, LayoutRoute>();
export const AppSideNavRoutesMap = new Map<string, LayoutRoute>();

appRoutes.forEach(({ apiUsage, requireSuperuser, routes }) => {
  routes.forEach((r) => {
    r.apiUsage = apiUsage;
    r.requireSuperuser = requireSuperuser;
    r.staticPath = makeRoutePathStatic(r.path);
    if (r.parent) {
      r.staticParent = makeRoutePathStatic(r.parent);
    }
    AppRoutesStatic.push(r);
    AppStaticRoutesMap.set(r.staticPath, r);
    if (r.navId) {
      AppSideNavRoutesMap.set(r.navId, r);
    }
    return r;
  });
});
