import { FC, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "../../services/appLanguageService";
import { searchAPI } from "../../services/api-declaration";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Link,
  Typography,
  CircularProgress
} from "@material-ui/core";
import store from "../../redux/store";
import { SearchResponseDictionary, TabComponentProps } from "../../redux/types";
import { getSearchInfo, transformSearchData } from "../../helpers/routesHelper";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import { AppStaticRoutesMap } from "../AppRouter";
import { useHistory } from "react-router-dom";

interface SearchSuggestionsComponentProps extends TabComponentProps {}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    childPaper: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2)
    },
    listItem: {
      background: "#f8f8f8",
      borderRadius: theme.spacing(0.5),
      margin: `${theme.spacing(0.5)}px 0`,
      "&:hover": {
        background: "#e8e8e8"
      }
    },
    link: {
      display: "flex",
      alignItems: "center",
      "&:hover": {
        color: "#1976d2",
        cursor: "pointer",
        textDecoration: "none"
      }
    },
    iconSpan: {
      display: "flex",
      minWidth: "30px",
      justifyContent: "center",
      marginRight: theme.spacing(1.5)
    },
    noResultsMessage: {
      padding: theme.spacing(1.5)
    },
    urlParamsText: {
      fontWeight: "bold",
      marginLeft: theme.spacing(0.5)
    },
    boldText: { fontWeight: "bold" },
    loaderPaper: {
      textAlign: "center",
      padding: `${theme.spacing(3)}px 0`,
      margin: `${theme.spacing(3)}px 0 0 0`
    },
    loader: {
      width: "50px !important",
      height: "50px !important",
      padding: theme.spacing(0.5)
    },
    nestedConnection: {
      marginTop: -16,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      width: 40,
      height: 32,
      "& > *": {
        borderColor: theme.palette.primary.main,
        borderLeft: "2px solid",
        borderBottom: "2px solid",
        width: "calc(50% + 8px)",
        height: "calc(50% + 8px)"
      }
    }
  })
);

const SearchSuggestions: FC<SearchSuggestionsComponentProps> = (props) => {
  const t = useTranslate("RouteLabels");
  const h = useTranslate("AppHeader");
  const classes = useStyles();
  const {
    tabId,
    urlQueryParams: { searchText = "" }
  } = props;
  const [suggestions, setSuggestions] = useState<{
    data: SearchResponseDictionary[] | undefined;
    isLoading: boolean;
  }>({ data: [], isLoading: false });
  const history = useHistory();

  const loadSearchResult = (searchText: string) => {
    setSuggestions({ data: [], isLoading: true });
    searchAPI({ query: `${searchText}*` })
      .then((response) =>
        setSuggestions({
          data:
            response.items.length !== 0
              ? transformSearchData(response.items, AppStaticRoutesMap)
              : undefined,
          isLoading: false
        })
      )
      .catch((e) => {
        console.warn(e.message);
        setSuggestions({ data: undefined, isLoading: false });
      });
  };
  const navigate = (item: SearchResponseDictionary) => () => {
    const searchInfo = getSearchInfo(AppStaticRoutesMap, item);
    if (searchInfo) {
      history.push(searchInfo.path);
    } else {
      showGlobalSnackbar(h("noPathAvailableLabel"), "default");
    }
  };

  useEffect(() => {
    if (searchText.length > 1) {
      loadSearchResult(searchText);
    }
  }, [searchText]);
  useEffect(() => {
    store.dispatch(setTitle(searchText, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Paper className={classes.paper}>
      <Typography component="h1" variant="h6">
        {t("searchSuggestionsLabel")}{" "}
        <span className={classes.boldText}>"{searchText}"</span>
      </Typography>
      <Paper>
        <List className={classes.childPaper}>
          {suggestions.isLoading ? (
            <Paper className={classes.loaderPaper}>
              <CircularProgress className={classes.loader} />
            </Paper>
          ) : suggestions.data ? (
            suggestions.data.map((item, index) => (
              <ListItem key={index} className={classes.listItem}>
                {item.related_to_path && (
                  <div className={classes.nestedConnection}>
                    <div></div>
                  </div>
                )}
                <ListItemText>
                  <Link onClick={navigate(item)} className={classes.link}>
                    <span className={classes.iconSpan}>
                      {item.icon ? <item.icon /> : ""}
                    </span>
                    <span>{item.name || "N/A"}</span>
                  </Link>
                </ListItemText>
              </ListItem>
            ))
          ) : (
            <div className={classes.noResultsMessage}>
              {h("noResultsFoundMessage")}
              <span className={classes.urlParamsText}>{searchText}</span>
            </div>
          )}
        </List>
      </Paper>
    </Paper>
  );
};

export default SearchSuggestions;
