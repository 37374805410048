import { TimeSheetOptions } from "../../../redux/types";
import { getTimesheetOptionsForApproval } from "../../../services/timesheetService";
import Dictionary from "./Dictionary";
import MaterialDictionary from "./MaterialDictionary";

class TimesheetOptionDict extends Dictionary<TimeSheetOptions> {
  public collectFromEnteries() {}

  public async fetchData(materialDictionary: MaterialDictionary) {
    const [timesheetId] = this.getIdsToSync();

    if (timesheetId) {
      const timesheetOptions = await getTimesheetOptionsForApproval(
        timesheetId
      );
      materialDictionary.addTimesheetOptions(
        timesheetOptions.contracted.materials
      );
      this.set(timesheetId, timesheetOptions);
      this.idsToSync.delete(timesheetId);
      return timesheetOptions;
    }

    return undefined;
  }
}

export default TimesheetOptionDict;
