import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, TaskOption } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { useTranslate } from "../../../services/appLanguageService";
import * as Yup from "yup";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface TaskOptionsContainerProps {
  taskOptions: TaskOption[];
  count: number;
  loadingTaskOptions: boolean;
  resetTaskOptions: () => void;
  loadTaskOptions: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => void;
  addTaskOption: (newData: any, businessAreaId?: number) => void;
  updateTaskOption: (taskOptionId: number, newData: any) => void;
  deleteTaskOption: (taskOptionId: number) => void;
  businessAreaId: number;
  showNewButton?: boolean;
  customHeader?: string;
}

const TaskOptionsContainer: React.FC<TaskOptionsContainerProps> = (props) => {
  const {
    loadTaskOptions,
    resetTaskOptions,
    loadingTaskOptions,
    taskOptions,
    businessAreaId
  } = props;

  const page = 1;
  const pageSize = 20;
  const filter = `&filter__businessarea=${businessAreaId}`;
  const t3 = useTranslate("ValidationErrorMessages");

  useEffect(
    () => loadTaskOptions(page, pageSize, undefined, undefined, filter),
    [filter, loadTaskOptions]
  );
  useEffect(() => () => resetTaskOptions(), [resetTaskOptions]);

  const columnDefinitions = [
    {
      name: "name",
      type: "text",
      editableForm: "text",
      validation: Yup.string()
        .min(1, t3("minLengthError"))
        .max(255, t3("max255LengthError"))
        .required(t3("requiredError"))
    },
    {
      name: "notes",
      type: "text",
      editableForm: "text",
      validation: Yup.string().nullable().notRequired()
    },
    {
      name: "present_by_default",
      type: "boolean",
      editableForm: "checkbox"
    }
  ];

  return (
    <ResourcesTableContainer
      resources={taskOptions}
      resourceName={"TaskOption"}
      count={props.count}
      loadingResources={loadingTaskOptions}
      routeUrl={"contracts/taskoptions"}
      columnDefinitions={columnDefinitions}
      showNewButton={false}
      filterDefinitions={[]}
      customFilter={filter}
      resetResources={resetTaskOptions}
      defaultPageSize={pageSize}
      loadResources={loadTaskOptions}
      customHeader={props.customHeader}
      owningId={businessAreaId}
      addResource={props.addTaskOption}
      updateResource={props.updateTaskOption}
      deleteResource={props.deleteTaskOption}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadTaskOptions: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string,
    filter?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        "TASKOPTIONS",
        `contracts/taskoptions?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}${filter}`
      )
    );
  },
  resetTaskOptions: () =>
    dispatch(LoadingBooleansActions.ResetTaskOptions(ownProps.tabId)),
  addTaskOption: (newData: any, businessAreaId?: number) => {
    if (businessAreaId) {
      dispatch(
        RestActions.restRequestWithData(
          ownProps.tabId,
          RestStrings.POST,
          ReduxActionStrings.TASKOPTIONS,
          "contracts/taskoptions/",
          {
            businessarea: businessAreaId,
            name: newData.name,
            notes: newData.notes || null,
            present_by_default: newData.present_by_default || false
          }
        )
      );
    }
  },
  updateTaskOption: (taskOptionId: number, newData: any) => {
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.TASKOPTION,
        `contracts/taskoptions/${taskOptionId}/`,
        {
          name: newData.name,
          notes: newData.notes || null,
          present_by_default: newData.present_by_default
        }
      )
    );
  },
  deleteTaskOption: (taskOptionId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.DELETE,
        ReduxActionStrings.TASKOPTION,
        `contracts/taskoptions/${taskOptionId}/`,
        undefined,
        undefined,
        taskOptionId
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  taskOptions: state.tabStates[ownProps.tabId].taskOptions.taskOptions.results,
  count: state.tabStates[ownProps.tabId].taskOptions.taskOptions.count,
  loadingTaskOptions:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingTaskOptions
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskOptionsContainer);
