import { createSuccessActionType } from "../actionTypes";
import { ServiceCategoriesState, Action, ServiceCategory } from "../../types";
import { RestStrings, ReduxActionStrings } from "../../strings";

const initialState: ServiceCategoriesState = {
  serviceCategories: {
    serviceCategories: [],
    next: null,
    previous: null,
    count: 0
  },
  serviceCategory: {
    id: -1,
    created_at: "",
    updated_at: "",
    name: "",
    notes: "",
    businessarea: -1
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.SERVICECATEGORIES
    ):
      return {
        ...state,
        serviceCategories: {
          serviceCategories: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.SERVICECATEGORIES
    ):
      return {
        ...state,
        serviceCategories: {
          ...state.serviceCategories,
          serviceCategories: [
            ...state.serviceCategories.serviceCategories,
            action.payload
          ]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.SERVICECATEGORY
    ):
      return {
        ...state,
        serviceCategories: {
          ...state.serviceCategories,
          serviceCategories: [
            ...state.serviceCategories.serviceCategories.map(
              (serviceCategory) =>
                serviceCategory.id === action.payload.id
                  ? action.payload
                  : serviceCategory
            )
          ]
        }
      };

    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.SERVICECATEGORY
    ):
      return {
        ...state,
        serviceCategories: {
          ...state.serviceCategories,
          serviceCategories: state.serviceCategories.serviceCategories.filter(
            (serviceCategory: ServiceCategory) =>
              serviceCategory.id !== parseInt(action.payload.id)
          )
        }
      };

    default:
      return state;
  }
}

export default reducer;
