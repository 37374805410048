import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  BusinessAreaForm,
  BusinessArea,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import BusinessAreaNewEditForm from "../../../components/contracts/businessAreas/BusinessAreaNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditBusinessAreaContainerProps
  extends FormikSubmitDispatchProps<BusinessAreaForm>,
    TabComponentProps<"businessareaId"> {
  businessArea: BusinessArea;
  invoicingProfileOptions: SelectFieldOption[];
  contractingProfileOptions: SelectFieldOption[];
  loadBusinessArea: (businessAreaId: number) => void;
  loadContractingProfiles: (searchTerm?: string) => void;
  loadInvoicingProfiles: (searchTerm?: string) => void;
  loadingBusinessArea: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<BusinessArea | BusinessAreaForm> {
  loadBusinessArea: (businessAreaId: number) => void;
  loadContractingProfiles: (searchTerm?: string) => void;
  loadInvoicingProfiles: (searchTerm?: string) => void;
}

const EditBusinessAreaContainer: React.FC<EditBusinessAreaContainerProps> = (
  props
) => {
  const {
    loadBusinessArea,
    businessArea,
    routeParams: { businessareaId },
    loadInvoicingProfiles,
    loadContractingProfiles,
    loadingBusinessArea
  } = props;
  useEffect(
    () => loadBusinessArea(businessareaId),
    [loadBusinessArea, businessareaId]
  );
  useEffect(() => loadInvoicingProfiles(), [loadInvoicingProfiles]);
  useEffect(() => loadContractingProfiles(), [loadContractingProfiles]);

  return loadingBusinessArea ? (
    <LoadingSpinnerPaper />
  ) : (
    <BusinessAreaNewEditForm
      businessArea={businessArea}
      invoicingProfileOptions={props.invoicingProfileOptions}
      contractingProfileOptions={props.contractingProfileOptions}
      handleSubmit={props.handleSubmit}
      loadContractingProfiles={props.loadContractingProfiles}
      loadInvoicingProfiles={props.loadInvoicingProfiles}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.BUSINESSAREA,
        `contracts/businessareas/${(checkedNullValues as BusinessArea).id}/`,
        checkedNullValues,
        `/contracts/businessareas/${
          (checkedNullValues as BusinessArea).id
        }?same_tab=true`
      )
    );
  },
  loadBusinessArea: (businessAreaId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.BUSINESSAREA,
        `contracts/businessareas/${businessAreaId}/`
      )
    );
  },
  loadInvoicingProfiles: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.INVOICINGPROFILES,
        `invoices/invoicingprofiles/?page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadContractingProfiles: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CONTRACTINGPROFILES,
        `contracts/contractingprofiles/?page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  businessArea:
    state.tabStates[ownProps.tabId].businessAreas.businessArea.businessArea,
  invoicingProfileOptions: state.tabStates[
    ownProps.tabId
  ].invoicingProfiles.invoicingProfiles.invoicingProfiles.map(
    (invoicingProfile) => ({
      label: invoicingProfile.name,
      value: invoicingProfile.id
    })
  ),
  contractingProfileOptions: state.tabStates[
    ownProps.tabId
  ].contractingProfiles.contractingProfiles.contractingProfiles.map(
    (contractingProfile) => ({
      label: contractingProfile.name,
      value: contractingProfile.id
    })
  ),
  loadingBusinessArea:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingBusinessArea
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBusinessAreaContainer);
