import React, { useEffect, useState, useRef } from "react";
import { Feature, LocationMark, FeatureCollection } from "../../redux/types";
import {
  ensureMapScript,
  loadGeoJson,
  getFeaturesBounds,
} from "../contracts/tasks/maps/MapHelpers";

export interface GoogleMapsProps {
  id: string;
  className?: string;
  zoom?: number;
  options?: google.maps.MapOptions;
  geoPolygons?: FeatureCollection;
  drawCustomMarker?: (
    position: google.maps.LatLng,
    map: google.maps.Map,
    markerData?: LocationMark
  ) => google.maps.OverlayView;
  customMarkerIcon?: () =>
    | string
    | google.maps.ReadonlyIcon
    | google.maps.ReadonlySymbol;
  markers?: LocationMark[];
  onMarkerClick?: (mark: LocationMark, marker: google.maps.Marker) => void;
  onMarkerMouseover?: (mark: LocationMark, marker: google.maps.Marker) => void;
  onMarkerMouseout?: (mark: LocationMark, marker: google.maps.Marker) => void;
  toggleBounds?: boolean;
  markerRefs?: (markers: MarkersRef[]) => void;
}

export type MarkersRef<T = any> = {
  marker: google.maps.Marker;
  data: T;
};

interface LatLngBounds extends google.maps.LatLngBounds {
  new (
    sw?: google.maps.LatLng | google.maps.LatLngLiteral,
    ne?: google.maps.LatLng | google.maps.LatLngLiteral
  ): google.maps.LatLngBounds;
}
interface Polygon extends google.maps.Polygon {
  new (opts?: google.maps.PolygonOptions): google.maps.Polygon;
}
interface Polyline extends google.maps.Polyline {
  new (opts?: google.maps.PolylineOptions): google.maps.Polyline;
}
interface Marker extends google.maps.Marker {
  new (opts?: google.maps.ReadonlyMarkerOptions): google.maps.Marker;
}
interface Map extends google.maps.Map {
  new (mapDiv: Element | null, opts?: google.maps.MapOptions): google.maps.Map;
}
interface OverlayView extends google.maps.OverlayView {
  new (): google.maps.OverlayView;
}
declare global {
  interface Window {
    google: {
      maps: {
        Geocoder: any;
        LatLng: any;
        LatLngBounds: LatLngBounds;
        Polygon: Polygon;
        Polyline: Polyline;
        Marker: Marker;
        Map: Map;
        OverlayView: OverlayView;
        SymbolPath: any;
        drawing: any;
        Data: any;
        geometry: any;
        event: any;
      };
    };
  }
}

type DrawingObject =
  | google.maps.Polygon
  | google.maps.Polyline
  | google.maps.Marker
  | google.maps.OverlayView;
// type GeometryType =
//   | "Point"
//   | "MultiPoint"
//   | "LineString"
//   | "MultiLineString"
//   | "LinearRing"
//   | "Polygon"
//   | "MultiPolygon"
//   | "GeometryCollection";

function convertCoordinatesWithBound(
  coordinates: Array<number[]>,
  bounds: google.maps.LatLngBounds
): google.maps.LatLngLiteral[] {
  return coordinates.map(([lng, lat]: number[]) => {
    bounds && bounds.extend({ lat, lng });
    return { lat, lng };
  });
}

function traverseGeometry(
  geometry: Feature["geometry"],
  callback: (coordinates: Array<number[]>) => void
): void {
  switch (geometry.type) {
    case "Polygon":
    case "MultiLineString":
      geometry.coordinates.forEach((coordinates: Array<number[]>) =>
        callback(coordinates)
      );
      break;
    case "MultiPolygon":
      geometry.coordinates.forEach((coordinates: Array<number[][]>) => {
        coordinates.forEach((cood) => callback(cood));
      });
      break;
    case "LineString":
      callback(geometry.coordinates);
  }
}
export function convertFeatureToLocation(
  features: Feature[]
): google.maps.LatLngLiteral {
  const bounds: google.maps.LatLngBounds =
    window && window.google && new window.google.maps.LatLngBounds();
  features.forEach((feature) => {
    traverseGeometry(feature.geometry, (coordinates) => {
      convertCoordinatesWithBound(coordinates, bounds);
    });
  });
  return bounds && bounds.getCenter().toJSON();
}

function GoogleMaps(props: GoogleMapsProps) {
  const mapRef = useRef<google.maps.Map>();
  const [allMarkers, setAllMarkers] = useState<Array<DrawingObject>>([]);

  const drawMarkers = (
    mapInstance: google.maps.Map,
    markers: LocationMark[],
    screenBounds: google.maps.LatLngBounds
  ): void => {
    const markerRefs: MarkersRef[] = [];
    if (props.markerRefs) {
      props.markerRefs([]);
    }
    markers.forEach((markLocation) => {
      const marker = new window.google.maps.Marker({
        ...markLocation.markerOptions,
        position: markLocation.position,
        icon: props.customMarkerIcon && props.customMarkerIcon(),
      });
      if (mapRef.current && props.drawCustomMarker) {
        allMarkers.push(
          props.drawCustomMarker(
            new google.maps.LatLng(markLocation.position),
            mapRef.current,
            markLocation
          )
        );
      }
      const { onMarkerClick, onMarkerMouseover, onMarkerMouseout } = props;
      if (onMarkerClick) {
        marker.addListener("click", function () {
          if (onMarkerClick) {
            onMarkerClick(markLocation, marker);
          }
        });
      }
      if (onMarkerMouseover) {
        marker.addListener("mouseover", function () {
          if (onMarkerMouseover) {
            onMarkerMouseover(markLocation, marker);
          }
        });
      }
      if (onMarkerMouseout) {
        marker.addListener("mouseout", function () {
          if (onMarkerMouseout) {
            onMarkerMouseout(markLocation, marker);
          }
        });
      }
      screenBounds.extend(markLocation.position);
      markerRefs.push({
        marker: marker,
        data: markLocation.data,
      });
      marker.setMap(mapInstance);
      allMarkers.push(marker);
    });
    setAllMarkers(allMarkers);

    if (props.markerRefs) {
      props.markerRefs(markerRefs);
    }
  };

  const updateMap = () => {
    ensureMapScript()
      .then(() => {
        const mapDiv = document.getElementById(props.id);
        if (!mapDiv || !window.google) return;
        if (!mapRef.current) {
          mapRef.current = new window.google.maps.Map(mapDiv, props.options);
        }
        const mapInstance = mapRef.current;
        setAllMarkers((markers) => {
          markers.forEach((drawn) => drawn.setMap(null));
          return [];
        });
        mapInstance.data.forEach(function (feature) {
          mapInstance.data.remove(feature);
        });
        let screenBounds: google.maps.LatLngBounds =
          new window.google.maps.LatLngBounds();

        if (props.geoPolygons) {
          loadGeoJson(mapInstance, props.geoPolygons);
          getFeaturesBounds(mapInstance, screenBounds);
        }
        if (props.markers) {
          drawMarkers(mapInstance, props.markers, screenBounds);
          mapInstance.setOptions({
            center: screenBounds.getCenter(),
          });
        }
        if (props.geoPolygons || (props.markers && props.markers.length)) {
          mapInstance.fitBounds(screenBounds);
        }
      })
      .catch((error) => console.log("Error in update map: ", error));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateMap, [props.geoPolygons, props.markers]);

  return (
    <div
      key={props.id}
      id={props.id}
      className={props.className}
      style={{ height: "100%", width: "100%" }}
    ></div>
  );
}

export default GoogleMaps;

// const drawFeature = (
//   type: GeometryType,
//   path: google.maps.LatLngLiteral[],
//   properties: FeatureProperties
// ) => {
//   switch (type) {
//     case "Polygon":
//     case "MultiPolygon":
//       const polygonOptions: google.maps.PolygonOptions = {
//         paths: path,
//         strokeColor: properties.strokeColor || properties.color, //#00328e',
//         strokeOpacity: properties.strokeOpacity || 0.8,
//         strokeWeight: properties.strokeWeight || 2,
//         fillColor: properties.fillColor || properties.color, //'#004ad2',
//         fillOpacity: properties.fillOpacity || 0.35
//       };
//       const polygon = new window.google.maps.Polygon(polygonOptions);
//       addToMap(polygon, map);
//       break;
//     case "LineString":
//     case "MultiLineString":
//       const polylineOptions: google.maps.PolylineOptions = {
//         path: path,
//         strokeColor: properties.strokeColor || properties.color,
//         strokeOpacity: properties.strokeOpacity || 0.8,
//         strokeWeight: properties.strokeWeight || 2
//       };
//       const polyline = new window.google.maps.Polyline(polylineOptions);
//       // if (properties.decorator === "arrowHead") {
//       //   polyline.setOptions({
//       //     icons: [
//       //       {
//       //         icon: {
//       //           path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
//       //         }
//       //       }
//       //     ]
//       //   });
//       // }
//       addToMap(polyline, map);
//       break;
//   }
// };

// const screenBounds: google.maps.LatLngBounds = new window.google.maps.LatLngBounds();
// features.forEach(feature => {
//   traverseGeometry(feature.geometry, coordinates => {
//     const path = convertCoordinatesWithBound(coordinates, screenBounds);
//     drawFeature(feature.geometry.type, path, feature.properties);
//   });
// });
// map.setOptions({
//   center: screenBounds.getCenter()
// });
// map.fitBounds(screenBounds);
// setCurrentBounds(screenBounds);
