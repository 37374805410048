import BaseItems from "../../../components/contracts/BaseItems";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../../redux/types";
import { LocalShipping } from "@material-ui/icons";
import ContractItemContainer from "../services/ContractItemContainer";
import Baseaddon from "../../../components/contracts/addons/Baseaddon";

const AddonsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/baseaddons"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/baseaddons",
        label: t("contractNavbarBaseAddonsLabel"),
        component: BaseItems,
        subTabIndex: 4,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS,
        icon: LocalShipping
      },
      {
        path: "/contracts/baseaddons/:baseAddonId",
        parent: "/contracts/baseaddons",
        label: t("baseAddonLabel"),
        component: Baseaddon,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/:contractId/addons/:contractItemId",
        parent: "/contracts/addons",
        label: t("addonLabel"),
        component: ContractItemContainer,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/baseitems",
        label: t("baseItemsLabel"),
        component: BaseItems,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      }
    ]
  }
];

export default AddonsRouter;
