import { LayoutRouteUsage } from "../../../redux/types";
import PasscardsContainer from "../../../containers/companies/passcards/PasscardsContainer";
import PasscardNewFormContainer from "../../../containers/companies/passcards/PasscardNewFormContainer";
import PasscardContainer from "../../../containers/companies/passcards/PasscardContainer";
import PasscardEditFormContainer from "../../../containers/companies/passcards/PasscardEditFormContainer";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

const PasscardsRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["companies/passcards"],
    requireSuperuser: true,
    routes: [
      {
        path: "/companies/passcards",
        label: t("companyNavbarPasscardsLabel"),
        component: PasscardsContainer,
        icon: VpnKeyIcon,
        highlightClass: NavItemClassEnum.PASSCARDS,
        navId: NavItemClassEnum.PASSCARDS,
      },
      {
        path: "/companies/passcards/new",
        parent: "/companies/passcards",
        label: t("newPasscardLabel"),
        component: PasscardNewFormContainer,
        highlightClass: NavItemClassEnum.PASSCARDS,
      },
      {
        path: "/companies/passcards/:passcardId",
        parent: "/companies/passcards",
        label: t("passcardLabel"),
        component: PasscardContainer,
        highlightClass: NavItemClassEnum.PASSCARDS,
      },
      {
        path: "/companies/passcards/:passcardId/edit",
        parent: "/companies/passcards/:passcardId",
        label: t("editPasscardLabel"),
        component: PasscardEditFormContainer,
        highlightClass: NavItemClassEnum.PASSCARDS,
      },
    ],
  },
];

export default PasscardsRouter;
