import React, { useState, useEffect, ChangeEvent } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Yup from "yup";
import {
  Button,
  Dialog,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  CircularProgress,
  makeStyles,
  createStyles,
  Typography
} from "@material-ui/core";
import {
  IContractSummaryType,
  SelectFieldOption,
  Task
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import {
  createContractItemAPI,
  getBaseContractItemsAPI
} from "../../../services/api-declaration";
import { typeOptions } from "../../../services/contractsService";
import { ValidationError } from "yup";
import { Autocomplete } from "@material-ui/lab";

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    }
  })
);

interface AddContractItemDialogProps {
  contractId: number;
  selectedTaskId: number | null;
  serviceCategoryNames: Map<number, string>;
  businessAreaNames: Map<number, string>,
  tasks: Task[];
  createContractItem: (
    payload: Parameters<typeof createContractItemAPI>[1]
  ) => Promise<void>;
  onClose: () => void;
}

const AddContractItemDialog: React.FC<AddContractItemDialogProps> = ({
  contractId,
  selectedTaskId,
  serviceCategoryNames,
  businessAreaNames,
  tasks,
  createContractItem,
  onClose
}) => {
  const [baseItems, setBaseItems] = useState<
    Awaited<ReturnType<typeof getBaseContractItemsAPI>>
  >([]);
  const [state, setState] = useState<{
    name: string;
    price: string;
    type: IContractSummaryType;
    task: number;
    base: number;
    serviceCategoryId: number;
  }>({
    name: "",
    price: "",
    type: "SERVICE",
    task: selectedTaskId ?? 0,
    base: 0,
    serviceCategoryId: 0
  });

  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {}
  );
  const [loading, setLoading] = useState(false);

  const classes = useModalStyles();
  const t = useTranslate("ContractPage");

  const categoryOptions =
    baseItems
      .find((base) => base.id === state.base && base.type === state.type)
      ?.categories.map((id) => ({
        value: id,
        label: serviceCategoryNames.get(id) ?? "n/a"
      })) ?? [];

  useEffect(() => {
    let alive = true;
    void getBaseContractItemsAPI(contractId).then((baseItems) => {
      if (alive) {
        setBaseItems(baseItems);
      }
    });
    return () => {
      alive = false;
    };
  }, [contractId]);

  const schema = Yup.object().shape({
    type: Yup.string(),
    base: Yup.number().moreThan(0, t("fieldRequired")),
    serviceCategoryId: Yup.number(),
    task: Yup.number(),
    name: Yup.string()
      .required(t("fieldRequired"))
      .max(255, t("fieldLengthExceeded")),
    price: Yup.string()
      .required(t("fieldRequired"))
      .matches(/^\d+$/, t("fieldDigitOnly"))
  });

  const validateFields = (...fields: (keyof typeof state)[]): void => {
    let next = { ...errors };
    void Promise.all(
      fields.map((field) =>
        schema
          .validateAt(field, state)
          .then(() => field in next && delete next[field])
          .catch(
            (err: ValidationError) => (next[field] = err.errors.join(", "))
          )
      )
    ).then(() => setErrors(next));
  };

  const disabled =
    !state.base || !state.name || !state.price || !!Object.keys(errors).length;

  const handleCreate = () => {
    if (!disabled) {
      setLoading(true);

      const taskId = !!tasks.find((task) => task.id === state.task)
        ? state.task
        : null;
      void createContractItem({
        type: state.type,
        service_category_id: state.serviceCategoryId || null,
        base_id: state.base!,
        task_id: taskId || null,
        name: state.name,
        price: parseInt(state.price)
      }).finally(() => setLoading(false));
    } else {
      validateFields(...(Object.keys(state) as (keyof typeof state)[]));
    }
  };

  const selectedBaseItem = baseItems.find(
    (baseitem) => baseitem.type === state.type && baseitem.id === state.base
  );

  const selectedBaseItemOption: SelectFieldOption = {
    label: selectedBaseItem?.name || "",
    value: selectedBaseItem?.id || 0
  };

  return (
    <Dialog open={true} onClose={() => onClose()} fullWidth>
      <DialogTitle>{t("addContractItem")}</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <InputLabel>{t("typeLabel")}</InputLabel>
          <Select
            value={state.type}
            onChange={(e) =>
              setState({
                ...state,
                type: e.target.value as IContractSummaryType
              })
            }
            onBlur={() => validateFields("type")}
            error={!!errors["type"]}
            fullWidth
          >
            {typeOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {!!errors["type"] && (
            <FormHelperText error>{errors["type"]}</FormHelperText>
          )}
        </div>

        <Autocomplete
          value={selectedBaseItemOption}
          onChange={(
            _event: ChangeEvent<{}>,
            newBaseitem: SelectFieldOption | null
          ) => {
            const baseItem = baseItems.find(
              (b) => b.type === state.type && b.id === newBaseitem?.value
            );
            setState({
              ...state,
              base: baseItem?.id || 0,
              name: baseItem?.name || "",
              price: baseItem?.price || ""
            });
          }}
          options={baseItems
            .filter(({ type }) => type === state.type)
            .sort((a, b) =>
              a.name.toLowerCase() === b.name.toLowerCase()
                ? 0
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : -1
            )
            .map((baseitem) => ({
              label: baseitem.name,
              value: baseitem.id
            }))}
          getOptionLabel={(option) => option.label}
          renderInput={(params: any) => (
            <TextField {...params} label={t("baseLabel")} />
          )}
        />

        <div>
          <InputLabel shrink>{t("serviceCategoriesLabel")}</InputLabel>
          <Select
            value={state.serviceCategoryId}
            onChange={(e) =>
              setState({
                ...state,
                serviceCategoryId: e.target.value as number
              })
            }
            onBlur={() => validateFields("serviceCategoryId")}
            error={!!errors["serviceCategoryId"]}
            disabled={categoryOptions.length <= 1}
            fullWidth
          >
            <MenuItem value={0}>{t("allServiceCategoriesChoice")}</MenuItem>
            {categoryOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {!!errors["serviceCategoryId"] && (
            <FormHelperText error>{errors["serviceCategoryId"]}</FormHelperText>
          )}
        </div>

        <div>
          <InputLabel>{t("tasksLabel")}</InputLabel>
          <Select
            value={state.task}
            onChange={(e) =>
              setState({ ...state, task: e.target.value as number })
            }
            onBlur={() => validateFields("task")}
            error={!!errors["task"]}
            disabled={tasks.length <= 1}
            fullWidth
          >
            <MenuItem value={0}>{t("allLabel")}</MenuItem>
            {tasks.map(({ id, name, businessarea }) => (
              <MenuItem key={id} value={id}>
                <div>
                  <Typography component="div" variant="body1">
                    {name}
                  </Typography>
                  <Typography component="div" variant="subtitle2">
                    {businessAreaNames.get(businessarea) ?? ""}
                  </Typography>
                </div>
              </MenuItem>
            ))}
          </Select>
          {!!errors["task"] && (
            <FormHelperText error>{errors["task"]}</FormHelperText>
          )}
        </div>

        <div>
          <InputLabel htmlFor="name">{t("nameLabel")}</InputLabel>
          <TextField
            name="name"
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
            onBlur={() => validateFields("name")}
            error={!!errors["name"]}
            helperText={errors["name"]}
            fullWidth
          />
        </div>

        <div>
          <InputLabel htmlFor="price">{t("priceLabel")}</InputLabel>
          <TextField
            name="price"
            value={state.price}
            onChange={(e) => setState({ ...state, price: e.target.value })}
            onBlur={() => validateFields("price")}
            error={!!errors["price"]}
            helperText={errors["price"]}
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          aria-label="cancel"
          onClick={() => onClose()}
          color="primary"
        >
          {t("cancelLabel")}
        </Button>
        <Button
          variant="contained"
          aria-label="add"
          startIcon={loading ? <CircularProgress size={20} /> : undefined}
          onClick={() => handleCreate()}
          disabled={loading}
          color="primary"
        >
          {t("addLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddContractItemDialog;
