import { showGlobalSnackbar } from "../helpers/globalHelper";
import store from "../redux/store";
import {
  UNAUTHORIZED,
  LOGEDOUT
} from "../redux/reducers/authentication/actionTypes";
import { useTranslate } from "./appLanguageService";
import {
  getFromSessionStore,
  removeFromSessionStore,
  setSessionStore
} from "./storageService";

export const fetchPost = <T>(url: string, body = {}): Promise<T> =>
  authenticateWrapper(url, {
    method: "POST",
    headers: getHeaders("application/json"),
    body: JSON.stringify(body)
  });

export const fetchGet = <T>(url: string): Promise<T> =>
  authenticateWrapper(url, {
    method: "GET",
    headers: getHeaders()
  });

export const fetchPut = <T>(url: string, body = {}): Promise<T> =>
  authenticateWrapper(url, {
    method: "PUT",
    headers: getHeaders("application/json"),
    body: JSON.stringify(body)
  });

export const fetchPatch = <T>(url: string, body = {}): Promise<T> =>
  authenticateWrapper(url, {
    method: "PATCH",
    headers: getHeaders("application/json"),
    body: JSON.stringify(body)
  });

export const fetchDelete = <T>(url: string): Promise<T> =>
  authenticateWrapper(url, {
    method: "DELETE",
    headers: getHeaders()
  });

export function fetchSansBody<T>(
  restType: string,
  url: string,
  cache: RequestInit["cache"] = undefined
): Promise<T> {
  const response: Promise<T> = authenticateWrapper(
    localStorage.getItem("baseURL") + url,
    {
      method: restType,
      headers: getHeaders(),
      cache
    }
  );
  return response;
}

export const fetchWithBody = <T>(
  restType: string,
  url: string,
  body = {}
): Promise<T> =>
  authenticateWrapper(localStorage.getItem("baseURL") + url, {
    method: restType,
    headers: getHeaders("application/json"),
    body: JSON.stringify(body)
  });

export const fetchBlob = async (url: string): Promise<Blob> =>
  authenticateWrapper(url, {
    method: "GET",
    headers: getHeaders("application/json")
  });

/*
 *
 * Non-exported functions
 *
 */

interface CustomRequestInit extends RequestInit {
  headers: Headers;
}
function authenticateWrapper<T>(url: string, options: CustomRequestInit) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const t = useTranslate("APIErrorMessages");
  return new Promise<T>(async (resolve, reject) => {
    if (url.match(/^null/)) {
      return;
    }
    try {
      const responseOn1stTry: T = await customFetch(url, options);
      resolve(responseOn1stTry);
    } catch (error: any) {
      if (error.message === "401") {
        try {
          await handleTokenRenewalRequests();

          const responseOn2ndTry: T = await customFetch(url, {
            method: options.method,
            headers: getHeaders(options.headers.get("Content-Type")),
            body: options.body
          });
          resolve(responseOn2ndTry);
        } catch (error2) {
          reject(error2 || error);
        }
      } else {
        showErrorMessage(error, t, url);
        reject(error);
      }
    }
  });
}
let isRenewingToken = false;
let tokenRenewalRequests: Array<[() => void, () => void]> = [];

export async function handleTokenRenewalRequests() {
  return new Promise<void>(async (resolve, reject) => {
    tokenRenewalRequests.push([resolve, reject]);
    if (!isRenewingToken) {
      isRenewingToken = true;
      try {
        await callRenewToken();
        resolveTokenRenewalRequests();
      } catch (error) {
        tokenRenewalRequests.forEach(([_, error]) => error());
        tokenRenewalRequests = [];
        isRenewingToken = false;
      }
    }
  });
}
export function resolveTokenRenewalRequests() {
  if (tokenRenewalRequests.length !== 0) {
    store.getState().fetchWrapper.socketReAuthCallback();
    tokenRenewalRequests.forEach(([success]) => success());
    tokenRenewalRequests = [];
  }
  isRenewingToken = false;
}

async function callRenewToken() {
  return new Promise<void>(async (resolve, reject) => {
    const refreshToken = getFromSessionStore("refresh_token");
    const t = useTranslate("APIErrorMessages");
    const url = `${localStorage.getItem("baseURL")}token/refresh/`;
    if (refreshToken) {
      try {
        const { access: accessToken } = await customFetch<{ access: string }>(
          url,
          {
            method: "POST",
            headers: getHeaders("application/json"),
            body: JSON.stringify({ refresh: refreshToken })
          }
        );
        setSessionStore("access_token", accessToken);
        resolve();
      } catch (error: any) {
        showErrorMessage(error, t, url);
        if (error.message === "401") {
          removeFromSessionStore("access_token");
          removeFromSessionStore("refresh_token");
          store.dispatch({
            type: UNAUTHORIZED
          });
          // don't reject, it will be renewed by dialog
        } else {
          reject(error);
        }
      }
    } else if (
      getFromSessionStore("access_token") &&
      getFromSessionStore("username") &&
      localStorage.getItem("domain_id")
    ) {
      store.dispatch({
        type: UNAUTHORIZED
      });
    } else {
      store.dispatch({
        type: LOGEDOUT
      });
      // showGlobalSnackbar(t("accessInfoErrorMessage"), "error");
      reject(t("accessInfoErrorMessage"));
    }
  });
}

function showErrorMessage(
  error: CustomError | Error,
  t: (key: string) => string,
  url: string
) {
  const path = new URL(url).pathname;
  if (error instanceof TypeError) {
    showGlobalSnackbar(t("genericErrorMessage"), "error");
  }
  switch (error.message) {
    case "400":
      if ((error as CustomError).response_data) {
        const errorMessage = (error as CustomError).response_data;
        showGlobalSnackbar(
          `${t("400ErrorMessage")}: ${display400ErrorMessage(errorMessage)}`,
          "error"
        );
      } else {
        showGlobalSnackbar(t("400ErrorMessage"), "error");
      }
      break;
    case "401":
      showGlobalSnackbar(t("401ErrorMessage"), "error");
      break;
    case "404":
      showGlobalSnackbar(`${t("404ErrorMessage")}: ${path}`, "error");
      break;
    case "405":
      showGlobalSnackbar(`${t("405ErrorMessage")}: ${path}`, "error");
      break;
    case "409":
      showGlobalSnackbar(`${t("409ErrorMessage")}: ${path}`, "error");
      break;
    case "500":
      showGlobalSnackbar(t("500ErrorMessage"), "error");
      break;
    case "503":
      showGlobalSnackbar(t("503ErrorMessage"), "error");
      break;
  }
}

type RecursiveErrorMessage =
  | { [key: string]: RecursiveErrorMessage }
  | RecursiveErrorMessage[]
  | string;

function display400ErrorMessage(errorMessage: {
  [key: string]: RecursiveErrorMessage;
}) {
  let messageString = "";
  let stack: string[] = [];

  const extractErrors = (m: RecursiveErrorMessage): void => {
    if (typeof m === "string") {
      const path = stack.join(".");
      messageString += `[${path}]: ${m}\n`;
    } else {
      Object.entries(m).forEach(([key, n]) => {
        stack.push(key);
        extractErrors(n);
        stack.pop();
      });
    }
  };
  extractErrors(errorMessage);

  return messageString;
}

function startStreaming(
  reader: ReadableStreamDefaultReader<Uint8Array>,
  controller: ReadableStreamDefaultController
): any {
  return reader
    .read()
    .then(({ done, value }) => {
      if (done) {
        controller.close();
        return;
      }
      controller.enqueue(value);
      startStreaming(reader, controller);
    })
    .catch((error) => console.log(error));
}

function processStream(reader: ReadableStreamDefaultReader<Uint8Array>) {
  return new ReadableStream({
    start: (controller) => startStreaming(reader, controller)
  });
}

function customFetch<T>(url: string, options: RequestInit): Promise<T> {
  return new Promise(async (resolve, reject) => {
    try {
      const response: Response = await fetch(url, options);
      await checkResponseStatus(response);
      const contentType = response.headers.get("content-type");

      if (contentType) {
        if (contentType.indexOf("application/json") !== -1) {
          resolve(response.json());
        } else if (
          (contentType.indexOf("octet-stream") !== -1 ||
            contentType.indexOf("pdf") !== -1 ||
            contentType.indexOf("image") !== -1) &&
          response.body !== null
        ) {
          const reader = response.body.getReader();
          resolve(
            new Blob([await new Response(processStream(reader)).blob()], {
              type: contentType
            }) as unknown as T
          );
        }
      }
      resolve(undefined as unknown as T);
    } catch (error) {
      reject(error);
    }
  });
}

function getHeaders(contentType?: string | null) {
  const headers: Headers = new Headers();
  const accessToken = getFromSessionStore("access_token");

  if (contentType) {
    headers.set("Content-Type", contentType);
  }

  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  }

  let tenant_id = localStorage.getItem("tenant_id");
  if (tenant_id) {
    headers.set("X-Vinna-Tenant-ID", tenant_id);
  }

  return headers;
}

function checkResponseStatus(response: Response) {
  return new Promise(async (resolve, reject) => {
    if (response && !response.ok) {
      let response_data;
      const contentType = response.headers.get("content-type");
      if (contentType) {
        if (contentType.indexOf("application/json") !== -1) {
          response_data = await response.json();
        }
      }
      if (response.status === 500) {
        reject({
          message: "500"
        });
      } else {
        let error = new Error(response.status.toString());
        if (response.status && response_data) {
          error = new CustomError(response.status.toString(), response_data);
        }
        reject(error);
      }
    }
    resolve(true);
  });
}

class CustomError extends Error {
  response_data: any;
  constructor(message: string, response_data: any) {
    super(message);
    this.response_data = response_data;
  }
}
