import { WeatherAreasState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: WeatherAreasState = {
  weatherAreas: {
    weatherAreas: [],
    next: null,
    previous: null,
    count: 0
  },
  weatherArea: {
    weatherArea: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      notes: "",
      latitude: 57.7,
      longitude: 11.96,
      latest_fetch: null
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.WEATHERAREAS
    ):
      return {
        ...state,
        weatherAreas: {
          ...state.weatherAreas,
          weatherAreas: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.WEATHERAREA
    ):
      return {
        ...state,
        weatherArea: {
          ...state.weatherArea,
          weatherArea: action.payload
        }
      };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.WEATHERAREA
    ):
      return {
        ...state,
        weatherAreas: {
          ...state.weatherAreas,
          weatherAreas: [...state.weatherAreas.weatherAreas, action.payload]
        }
      };
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.WEATHERAREA
    ):
      return {
        ...state,
        weatherAreas: {
          ...state.weatherAreas,
          weatherAreas: [
            ...state.weatherAreas.weatherAreas.map((weatherArea) =>
              weatherArea.id === action.payload.id
                ? action.payload
                : weatherArea
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
