import html2canvas from "html2canvas";
import { getFeaturesBounds } from "./MapHelpers";

interface OffsetType {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

// Creates an image from the map with features
export const createImage = (
  map: google.maps.Map,
  taskId: number,
  handleCreateImage: (taskId: number, image: string) => void,
  node: HTMLDivElement,
  hideMarker?: () => void,
  showMarker?: () => void
) => {
  // Hiding google maps controls and the location marker, so that they aren't included in the image
  map.setOptions({
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false,
    styles: [
      {
        featureType: "all",
        stylers: [{ visibility: "off" }]
      }
    ]
  });
  if (hideMarker) {
    hideMarker();
  }

  let currentTransform = "";

  // Small delay (100 ms), in order to have time to hide google maps controls when creating image
  setTimeout(() => {
    /**
     * Some preparations before capturing the image, in order to work correctly with Google maps and its layers
     *
     * Source of the solution:
     * https://stackoverflow.com/questions/24046778/html2canvas-does-not-work-with-google-maps-pan
     * https://github.com/niklasvh/html2canvas/issues/1568#issuecomment-413760848
     */

    const transformCases = [
      ".gm-style > div:first-child > div:first-child > div:last-child > div",
      ".gm-style > div:first-child > div:first-child > div:first-child > div:first-child > div",
      ".gm-style > div:first-child > div:first-child > div:nth-child(2) > div:first-child > div",
      ".gm-style > div:nth-child(2) > div:first-child > div:first-child > div:first-child > div"
    ];

    transformCases.forEach((transformCase) => {
      if (document.querySelectorAll(transformCase).length) {
        currentTransform = transformDiv(transformCase, currentTransform);
      }
    });

    //Creating the image with htm2canvas library (http://html2canvas.hertzen.com)
    html2canvas(node, {
      useCORS: true
      // When image is created, some after-processing
    })
      .then(async (canvas: HTMLCanvasElement) => {
        transformCases.forEach((transformCase) => {
          if (document.querySelectorAll(transformCase).length) {
            transformDivBack(transformCase, currentTransform);
          }
        });

        // Show the google maps controls again
        map.setOptions({
          mapTypeControl: true,
          fullscreenControl: true,
          streetViewControl: true,
          zoomControl: true,
          styles: [
            {
              featureType: "all",
              stylers: [{ visibility: "on" }]
            }
          ]
        });

        if (showMarker) {
          showMarker();
        }

        const offsets = calcCroppedOffsets(canvas, 150, map);
        const croppedCanvas = document.createElement("canvas");

        const croppedCanvasWidth = canvas.width - offsets.x1 - offsets.x2;
        const croppedCanvasHeight = canvas.height - offsets.y1 - offsets.y2;

        const context = croppedCanvas.getContext("2d");

        croppedCanvas.setAttribute("width", croppedCanvasWidth.toString());
        croppedCanvas.setAttribute("height", croppedCanvasHeight.toString());

        if (context) {
          context.drawImage(
            canvas,
            offsets.x1,
            offsets.y1,
            croppedCanvasWidth,
            croppedCanvasHeight,
            0,
            0,
            croppedCanvasWidth,
            croppedCanvasHeight
          );
        }

        //Generating watermark
        if (context) {
          const watermarkResult = await fetch("SS_logo_watermark.png");
          const watermarkBlob = await watermarkResult.blob();
          const watermarkImage = await createImageBitmap(watermarkBlob);
          const pattern = context.createPattern(watermarkImage, "no-repeat");

          if (pattern) {
            const defaultWatermarkScale = 0.8;
            const watermarkScale =
              Math.min(croppedCanvasWidth / watermarkImage.width, 1.0) *
              defaultWatermarkScale;
            const watermarkWidth = watermarkImage.width * watermarkScale;
            const watermarkHeight = watermarkImage.height * watermarkScale;
            const watermarkX = croppedCanvasWidth / 2 - watermarkWidth / 2;
            const watermarkY = croppedCanvasHeight / 2 - watermarkHeight / 2;

            context.fillStyle = pattern;
            context.drawImage(
              watermarkImage,
              watermarkX,
              watermarkY,
              watermarkWidth,
              watermarkHeight
            );
          }
        }

        const image = croppedCanvas.toDataURL("image/jpeg", 1);

        if (image) {
          handleCreateImage(taskId, image);
        }
      })
      .catch((error) => console.log(error));
  }, 200);
};

// Idea from https://github.com/niklasvh/html2canvas/issues/1568#issuecomment-413760848
const transformDiv = (query: string, currentTransform: string) => {
  const element = document.querySelectorAll(query)[0] as HTMLElement;
  const transform = getComputedStyle(element)["transform"]; //get transform value

  if (transform) {
    const comp = transform.split(","); //split up the transform matrix
    const mapleft = parseFloat(comp[4]); //get left value
    const maptop = parseFloat(comp[5]); //get top value

    element.style.transform = "none";
    element.style.left = mapleft.toString() + "px";
    element.style.top = maptop.toString() + "px";
  }

  return currentTransform === "" && transform ? transform : currentTransform;
};

// Idea from https://github.com/niklasvh/html2canvas/issues/1568#issuecomment-413760848
const transformDivBack = (query: string, currentTransform: string) => {
  const element = document.querySelectorAll(query)[0] as HTMLElement;
  const transform = getComputedStyle(element)["transform"];

  element.style.transform = currentTransform ? currentTransform : transform;
  element.style.left = "0px";
  element.style.top = "0px";
};

// Get offsets, in order to crop an image so that only the features are captured (and some extra space around)
const calcCroppedOffsets = (
  canvas: HTMLCanvasElement,
  extraSpace: number,
  mapInstance: google.maps.Map
): OffsetType => {
  const innerBounds = getFeaturesBounds(
    mapInstance,
    new window.google.maps.LatLngBounds()
  );
  const outerBounds = mapInstance.getBounds();

  if (outerBounds) {
    const outer = {
      west: outerBounds.getSouthWest().lng(),
      east: outerBounds.getNorthEast().lng(),
      north: outerBounds.getNorthEast().lat(),
      south: outerBounds.getSouthWest().lat()
    };

    const inner = {
      west: innerBounds.getSouthWest().lng(),
      east: innerBounds.getNorthEast().lng(),
      north: innerBounds.getNorthEast().lat(),
      south: innerBounds.getSouthWest().lat()
    };

    const offsets = {
      x1:
        Math.round(
          ((outer.west - inner.west) / (outer.west - outer.east)) * canvas.width
        ) - extraSpace,
      x2:
        Math.round(
          ((outer.east - inner.east) / (outer.east - outer.west)) * canvas.width
        ) - extraSpace,
      y1:
        Math.round(
          ((outer.north - inner.north) / (outer.north - outer.south)) *
            canvas.height
        ) -
        extraSpace / 2,
      y2:
        Math.round(
          ((outer.south - inner.south) / (outer.south - outer.north)) *
            canvas.height
        ) -
        extraSpace / 2
    };

    // Removing extra white space when image is more than 2:1
    const extraWide =
      (canvas.width - offsets.x1 - offsets.x2) /
        (canvas.height - offsets.y1 - offsets.y2) >
      2.0;

    offsets.x1 = extraWide ? offsets.x1 + extraSpace / 2 : offsets.x1;
    offsets.x2 = extraWide ? offsets.x2 + extraSpace / 2 : offsets.x2;

    return offsets;
  }
  return { x1: 0, x2: 0, y1: 0, y2: 0 };
};

export default createImage;
