import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../redux/types";
import UserContainer from "./UserContainer";
import UserEditFormContainer from "./UserEditFormContainer";

const UsersRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["auth/users"],
    requireSuperuser: true,
    routes: [
      // {
      //   path: "/auth/users",
      //   label: t("usersNavbarLabel"),
      //   component: undefined,
      // },
      // {
      //   path: "/auth/users/new",
      //   parent: "/auth/users",
      //   label: t("newNavbarLabel"),
      //   component: undefined,
      // },
      {
        path: "/auth/users/:userId",
        parent: "/auth/users",
        label: t("userLabel"),
        component: UserContainer,
        highlightClass: NavItemClassEnum.PERSONS,
      },
      {
        path: "/auth/users/:userId/edit",
        parent: "/auth/users/:userId",
        label: t("editUserLabel"),
        component: UserEditFormContainer,
        highlightClass: NavItemClassEnum.PERSONS,
      },
    ],
  },
];

export default UsersRouter;
