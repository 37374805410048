import React from "react";
import { Grow } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { TransitionProps } from "@material-ui/core/transitions";
import { IRowImage } from "../../../redux/types";
import ImageSlider from "./ImageSlider";
import { DrawerProvider } from "./DrawerContext";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paperScrollPaper: {
      "& .MuiDialog-paperScrollPaper": {
        height: "calc(100vh - 50px)",
        minWidth: "80vw",
        width: "100%"
      }
    }
  })
);
interface RowImageDialogProps {
  showDialog: boolean;
  selectedIndex: number;
  allImages: IRowImage[];
  onImageChange: (img: IRowImage) => void;
  populateImageDetails: (img: IRowImage) => Promise<IRowImage>;
  onDialogClosed: () => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});
const RowImageDialog: React.FC<RowImageDialogProps> = (props) => {
  const {
    allImages,
    showDialog,
    onImageChange,
    onDialogClosed,
    selectedIndex,
    populateImageDetails
  } = props;
  const classes = useStyles();

  const handleClose = () => {
    onDialogClosed();
  };
  return (
    <DrawerProvider>
      <Dialog
        scroll="paper"
        open={showDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.paperScrollPaper}
      >
        <ImageSlider
          defaultIndex={selectedIndex}
          allImages={allImages}
          onImageChange={onImageChange}
          populateImageDetails={populateImageDetails}
        />
      </Dialog>
    </DrawerProvider>
  );
};
export default RowImageDialog;
