import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, TabComponentProps, User } from "../../redux/types";
import * as RestActions from "../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import LoadingSpinnerPaper from "../../components/LoadingSpinnerPaper";
import { Redirect } from "react-router-dom";

interface UserContainerProps extends TabComponentProps<"userId"> {
  user: User;
  loadingUser: boolean;
  resetUser: () => void;
  loadUser: (userId: number) => void;
}

const UserContainer: React.FC<UserContainerProps> = (props) => {
  const {
    resetUser,
    loadingUser,
    loadUser,
    user,
    routeParams: { userId }
  } = props;
  useEffect(() => loadUser(userId), [loadUser, userId]);

  useEffect(() => () => resetUser(), [resetUser]);
  return (
    <>
      {loadingUser ? (
        <LoadingSpinnerPaper />
      ) : (
        <Redirect to={`/auth/persons/${user.person}`} />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadUser: (userId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PARAMS_USER,
        `auth/users/${userId}/`
      )
    );
  },
  resetUser: () => dispatch(LoadingBooleansActions.ResetUser(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  user: state.tabStates[ownProps.tabId].users.user.paramsUser,
  loadingUser: state.tabStates[ownProps.tabId].loadingBooleans.loadingUser
});

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
