import { LayoutRouteUsage } from "../redux/types";
import { NavItemClassEnum } from "../strings/TabRouterStrings";
import ViewListIcon from '@material-ui/icons/ViewList';
import WorkLog from "./WorkLog";

const WorkLogRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["timesheets/timesheets"],
    routes: [
      {
        path: "/work_log",
        label: t("workLogNavbarLabel"),
        component: WorkLog,
        icon: ViewListIcon,
        highlightClass: NavItemClassEnum.WORKLOG,
        navId: NavItemClassEnum.WORKLOG,
        suspendable: true
      }
    ]
  }
];

export default WorkLogRouter;
