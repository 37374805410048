import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { BaseAddon, TabComponentProps } from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import { getBaseAddonAPI } from "../../../services/api-declaration";
import * as Yup from "yup";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import EditableSimpleSelectField from "../../EditableSimpleSelectField";
import { EnumStrings } from "../../../redux/strings";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DeleteButtonContainer from "../../../containers/DeleteButtonContainer";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface BaseAddonProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"baseAddonId"> {}

const BaseAddonView: React.FC<BaseAddonProps> = (props) => {
  const {
    classes,
    tabId,
    routeParams: { baseAddonId }
  } = props;
  const t = useTranslate("AddonPage");
  const t2 = useTranslate("BaseAddons");
  const t3 = useTranslate("ValidationErrorMessages");

  const [baseAddon, setBaseAddon] = useState<BaseAddon>();
  const updateResourceState = (value: BaseAddon) => setBaseAddon(value);

  const patchUrl = `contracts/baseaddons/${baseAddonId}/`;

  const statusOptions = [
    EnumStrings.ACTIVE,
    EnumStrings.LOCKED,
    EnumStrings.ARCHIVED,
    EnumStrings.DRAFT
  ];

  const periodicityOptions = [
    EnumStrings.WEEKLY,
    EnumStrings.MONTHLY,
    EnumStrings.YEARLY
  ];

  const translateFunction = (value: string) => t2(value);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (baseAddon) store.dispatch(setTitle(baseAddon.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAddon]);

  useEffect(() => {
    let isAlive = true;

    (async () => {
      const baseAddon = await getBaseAddonAPI(baseAddonId);

      if (isAlive) {
        setBaseAddon(baseAddon);
      }
    })();

    return () => {
      isAlive = false;
    };
  }, [baseAddonId]);

  return !baseAddon ? (
    <LoadingSpinnerPaper />
  ) : (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/contracts/baseaddons/"
      >
        <NavigateBeforeIcon /> {t("navigationButtonLabel")}
      </Button>
      <DeleteButtonContainer
        resourceId={baseAddon.id}
        resource={"BASEADDON"}
        url={"contracts/baseaddons"}
        backendUrl={"contracts/baseaddons"}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      <b>{baseAddon.name}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableTextField
                      field="name"
                      initValue={baseAddon.name}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      validation={Yup.string()
                        .min(1, t3("minLengthError"))
                        .max(255, t3("max255LengthError"))
                        .required(t3("requiredError"))}
                    />
                  </TableCell>
                </TableRow>
                {baseAddon.price !== null && (
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        isPrice
                        field="price"
                        initValue={baseAddon.price}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000000, t3("max10000000NumberError"))
                          .required(t3("requiredError"))}
                      />{" "}
                      kr {baseAddon.unit ? `/ ${baseAddon.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableSimpleSelectField
                      field="status"
                      initValue={baseAddon.status}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      options={statusOptions.map((option) => ({
                        label: t2(option),
                        value: option
                      }))}
                      translateFunction={translateFunction}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              data-cy="expand-details"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("periodicityLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableSimpleSelectField
                        field="periodicity"
                        initValue={baseAddon.periodicity}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        options={periodicityOptions.map((option) => ({
                          label: t2(option),
                          value: option
                        }))}
                        translateFunction={translateFunction}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("isInanimateLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="is_inanimate"
                        label={t("isInanimateLabel")}
                        initValue={baseAddon.is_inanimate}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("hasManyLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="has_many"
                        label={t("hasManyLabel")}
                        initValue={baseAddon.has_many}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("vatPercentageLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="vat_percentage"
                        initValue={baseAddon.vat_percentage}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(99.99, t3("max99dot99NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit"
                        initValue={baseAddon.unit}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .max(255, t3("max255LengthError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitDividerLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit_divider"
                        initValue={baseAddon.unit_divider}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000, t3("max10000NumberError"))
                          .required(t3("requiredError"))}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={baseAddon.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("descriptionLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="description"
                        initValue={baseAddon.description}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseAddon.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseAddon.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(BaseAddonView);
