import React, { useState } from "react";
import {
  Select,
  Tooltip,
  MenuItem,
  InputLabel,
  IconButton
} from "@material-ui/core";
import { IPackageType } from "../../../redux/types";
import { Add } from "@material-ui/icons";
import { showGlobalSnackbar } from "../../../helpers/globalHelper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "../../../services/appLanguageService";
import AddServicePackageEventDialog from "../../timesheetApprovalDialog/addServicePackageEventDialog";
import PackageCarry from "../../../helpers/classes/verification/PackageCarry";

interface ApprovalTypeSelectProps {
  label: string;
  packageType: IPackageType;
  packageCarry: PackageCarry;
  isDisabled: boolean;
  onPackageCreated: (packageCarry: PackageCarry) => void;
  openByDefault?: boolean;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    addItemContainer: {
      textAlign: "right",
      margin: `0 ${theme.spacing(1.5)}px`
    },
    iconBtn: { border: "1px solid" }
  })
);

const ApprovalTypeSelect: React.FC<ApprovalTypeSelectProps> = (props) => {
  const {
    label,
    packageType,
    packageCarry,
    isDisabled,
    onPackageCreated,
    openByDefault
  } = props;
  const classes = useStyles();
  const t = useTranslate("TimeSheetRowsAndApprovals");
  const { packageOptions } = packageCarry;

  const [isOpen, setIsOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(openByDefault);
  const handleAddClick = () => {
    if (packageOptions.available[packageType].length) {
      setIsOpen(true);
    } else {
      const label =
        packageType === "contractor"
          ? t("noContractorOptionsLabel")
          : t("noCustomerOptionsLabel");
      showGlobalSnackbar(label, "error");
    }
  };

  const handlePackageChanged = ({
    target: { value }
  }: React.ChangeEvent<{
    value: any;
  }>) => {
    const selectedPackage = packageOptions.applicable?.[packageType].find(
      (p) => value === p.event_id
    );
    packageCarry.setCurrentPackage(packageType, selectedPackage);
  };

  return (
    <>
      <InputLabel htmlFor={packageType} id={packageType} shrink>
        {label}
      </InputLabel>
      <Select
        name={packageType}
        labelId={packageType}
        value={packageCarry.current[packageType]?.event_id || -1}
        onChange={handlePackageChanged}
        type="number"
        disabled={isDisabled}
        fullWidth
        onClose={() => setIsSelectOpen(false)}
        onOpen={() => {
          setIsSelectOpen(true);
        }}
        open={isSelectOpen}
      >
        <MenuItem key={-1} value={-1}>
          {t("byTheHourOption")}
        </MenuItem>
        {packageOptions.applicable[packageType].map((option, index) => (
          <MenuItem key={index} value={option.event_id}>
            {option.title}
          </MenuItem>
        ))}
        <div className={classes.addItemContainer}>
          <Tooltip arrow placement="right" title={t("addNewTooltip")}>
            <IconButton
              size="small"
              className={classes.iconBtn}
              onClick={handleAddClick}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </div>
      </Select>
      {packageOptions.available && (
        <AddServicePackageEventDialog
          isOpen={isOpen}
          packageType={packageType}
          packageCarry={packageCarry}
          onPackageCreated={onPackageCreated}
          closeDialog={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default ApprovalTypeSelect;
