import { createStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import RouteInstancesContainer from "../../../containers/routeinstances/RouteInstancesContainer";
import { mapTabRouteProps } from "../../../helpers/routesHelper";
import { FC } from "react";
import RoutePlansContainer from "../../../containers/routeplans/RoutePlansContainer";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    routePlanContainer: { marginTop: theme.spacing(2) },
    mainContainter: {
      width: "100%",
      [theme.breakpoints.down(600)]: {
        padding: "1px"
      }
    }
  })
);

interface RouteInstancesPageProps {}

const RouteInstancesPage: FC<RouteInstancesPageProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainter}>
      <RouteInstancesContainer {...mapTabRouteProps(props)} />
      <div className={classes.routePlanContainer}>
        <RoutePlansContainer {...mapTabRouteProps(props)} />
      </div>
    </div>
  );
};

export default RouteInstancesPage;
