import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  BaseService,
  BaseServicepackage,
  BaseSupplement,
  SelectFieldOption,
  ServiceCategory,
  TabComponentProps
} from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import AssociatedField from "../../AssociatedField";
import {
  getAllServiceCategories,
  getBaseServicepackageAPI,
  patchBaseServicepackageAPI,
  getBaseSupplementOptionsAPI,
  getBaseServiceOptionsAPI
} from "../../../services/api-declaration";
import * as Yup from "yup";
import LoadingSpinnerPaper from "../../LoadingSpinnerPaper";
import EditableSimpleSelectField from "../../EditableSimpleSelectField";
import { EnumStrings } from "../../../redux/strings";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DeleteButtonContainer from "../../../containers/DeleteButtonContainer";
import {
  ConfirmationDialogServiceProvider,
  useConfirmationDialog
} from "../../confirmationDialog/ConfirmationDialogServiceProvider";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface BaseServicepackageProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"baseServicepackageId"> {}

const BaseServicepackageView: React.FC<BaseServicepackageProps> = (props) => {
  const {
    classes,
    tabId,
    routeParams: { baseServicepackageId }
  } = props;
  const t = useTranslate("ServicepackagePage");
  const t2 = useTranslate("BaseServicepackages");
  const t3 = useTranslate("ValidationErrorMessages");

  const [baseSupplementOptions, setBaseSupplementOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [baseServiceOptions, setBaseServiceOptions] = useState<
    SelectFieldOption[]
  >([]);
  const [servicecategories, setServicecategories] = useState<ServiceCategory[]>(
    []
  );
  const [servicecategoriesLoading, setServicecategoriesLoading] =
    useState<boolean>(false);
  const [baseServicepackage, setBaseServicepackage] =
    useState<BaseServicepackage>();
  const [baseSupplementsLoading, setBaseSupplementsLoading] =
    useState<boolean>(false);
  const [baseServicesLoading, setBaseServicesLoading] =
    useState<boolean>(false);
  const updateResourceState = (value: BaseServicepackage) =>
    setBaseServicepackage(value);

  const patchUrl = `contracts/baseservicepackages/${baseServicepackageId}/`;

  const confirmationDialog = useConfirmationDialog();

  const statusOptions = [
    EnumStrings.ACTIVE,
    EnumStrings.LOCKED,
    EnumStrings.ARCHIVED,
    EnumStrings.DRAFT
  ];

  const translateFunction = (value: string) => t2(value);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (baseServicepackage)
      store.dispatch(setTitle(baseServicepackage.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseServicepackage]);

  useEffect(() => {
    let isAlive = true;

    (async () => {
      const baseServicepackage = await getBaseServicepackageAPI(
        baseServicepackageId
      );

      const [
        baseSupplementsResponse,
        baseServicesResponse,
        servicecategoriesResponse
      ] = await Promise.all([
        getBaseSupplementOptionsAPI(),
        getBaseServiceOptionsAPI(),
        getAllServiceCategories()
      ]);

      if (isAlive) {
        setBaseServicepackage(baseServicepackage);
        setBaseSupplementOptions(
          baseSupplementsResponse.results.map(
            (baseSupplement: Pick<BaseSupplement, "name" | "id">) => ({
              value: baseSupplement.id,
              label: baseSupplement.name
            })
          )
        );
        setBaseServiceOptions(
          baseServicesResponse.results.map(
            (baseService: Pick<BaseService, "name" | "id">) => ({
              value: baseService.id,
              label: baseService.name
            })
          )
        );
        setServicecategories(servicecategoriesResponse.results);
      }
    })();

    return () => {
      isAlive = false;
    };
  }, [baseServicepackageId]);

  const addBaseService = async (
    baseServicepackageId: number,
    currentServiceIds: number[]
  ) => {
    setBaseServicesLoading(true);

    const response = await patchBaseServicepackageAPI(baseServicepackageId, {
      base_services: currentServiceIds
    });

    updateResourceState(response);
    setBaseServicesLoading(false);
  };

  const removeBaseService = async (
    baseServicepackageId: number,
    currentServiceIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateBaseServiceConfirmation")
    });
    setBaseServicesLoading(true);
    const response = await patchBaseServicepackageAPI(baseServicepackageId, {
      base_services: currentServiceIds
    });

    updateResourceState(response);
    setBaseServicesLoading(false);
  };

  const addBaseSupplement = async (
    baseServicepackageId: number,
    currentSupplementIds: number[]
  ) => {
    setBaseSupplementsLoading(true);

    const response = await patchBaseServicepackageAPI(baseServicepackageId, {
      base_supplements: currentSupplementIds
    });

    updateResourceState(response);
    setBaseSupplementsLoading(false);
  };

  const removeBaseSupplement = async (
    baseServicepackageId: number,
    currentSupplementIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateBaseSupplementConfirmation")
    });
    setBaseSupplementsLoading(true);
    const response = await patchBaseServicepackageAPI(baseServicepackageId, {
      base_supplements: currentSupplementIds
    });

    updateResourceState(response);
    setBaseSupplementsLoading(false);
  };

  const addServicecategory = async (
    baseServicepackageId: number,
    currentCategoryIds: number[]
  ) => {
    setServicecategoriesLoading(true);

    const response = await patchBaseServicepackageAPI(baseServicepackageId, {
      categories: currentCategoryIds
    });

    updateResourceState(response);
    setServicecategoriesLoading(false);
  };

  const removeServicecategory = async (
    baseServicepackageId: number,
    currentCategoryIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateCategoryConfirmation")
    });
    setServicecategoriesLoading(true);
    const response = await patchBaseServicepackageAPI(baseServicepackageId, {
      categories: currentCategoryIds
    });

    updateResourceState(response);
    setServicecategoriesLoading(false);
  };

  return !baseServicepackage ? (
    <LoadingSpinnerPaper />
  ) : (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/contracts/baseservicepackages/"
      >
        <NavigateBeforeIcon /> {t("navigationButtonLabel")}
      </Button>
      <DeleteButtonContainer
        resourceId={baseServicepackage.id}
        resource={"BASESERVICEPACKAGE"}
        url={"contracts/baseservicepackages"}
        backendUrl={"contracts/baseservicepackages"}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      <b>{baseServicepackage.name}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableTextField
                      field="name"
                      initValue={baseServicepackage.name}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      validation={Yup.string()
                        .min(1, t3("minLengthError"))
                        .max(255, t3("max255LengthError"))
                        .required(t3("requiredError"))}
                    />
                  </TableCell>
                </TableRow>
                {baseServicepackage.price !== null && (
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        isPrice
                        field="price"
                        initValue={baseServicepackage.price}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000000, t3("max10000000NumberError"))
                          .required(t3("requiredError"))}
                      />{" "}
                      kr{" "}
                      {baseServicepackage.unit
                        ? `/ ${baseServicepackage.unit}`
                        : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("statusLabel")}
                  </TableCell>
                  <TableCell align="right">
                    <EditableSimpleSelectField
                      field="status"
                      initValue={baseServicepackage.status}
                      url={patchUrl}
                      updateResourceState={updateResourceState}
                      options={statusOptions.map((option) => ({
                        label: t2(option),
                        value: option
                      }))}
                      translateFunction={translateFunction}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              data-cy="expand-details"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("isInanimateLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="is_inanimate"
                        label={t("isInanimateLabel")}
                        initValue={baseServicepackage.is_inanimate}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("hasManyLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="has_many"
                        label={t("hasManyLabel")}
                        initValue={baseServicepackage.has_many}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("vatPercentageLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="vat_percentage"
                        initValue={baseServicepackage.vat_percentage}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(99.99, t3("max99dot99NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit"
                        initValue={baseServicepackage.unit}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .min(1, t3("minLengthError"))
                          .max(255, t3("max255LengthError"))
                          .required(t3("requiredError"))}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("unitDividerLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="unit_divider"
                        initValue={baseServicepackage.unit_divider}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000, t3("max10000NumberError"))
                          .required(t3("requiredError"))}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={baseServicepackage.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("descriptionLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="description"
                        initValue={baseServicepackage.description}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseServicepackage.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(baseServicepackage.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
          {servicecategories && baseServicepackage && (
            <AssociatedField
              field="categories"
              resourceIds={baseServicepackage.categories}
              options={servicecategories.map((servicecategory) => ({
                value: servicecategory.id,
                label: servicecategory.name
              }))}
              addResource={addServicecategory}
              owningId={baseServicepackage.id}
              label={t("servicecategoriesLabel")}
              removeResource={removeServicecategory}
              isLoading={servicecategoriesLoading}
              title={t("servicecategoriesLabel")}
              atleastOneElement
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {baseServiceOptions && baseServicepackage && (
            <AssociatedField
              field="services"
              resourceIds={baseServicepackage.base_services}
              options={baseServiceOptions}
              addResource={addBaseService}
              owningId={baseServicepackage.id}
              label={t("baseServiceLabel")}
              removeResource={removeBaseService}
              isLoading={baseServicesLoading}
              title={t("baseServicesLabel")}
            />
          )}
          {baseSupplementOptions && baseServicepackage && (
            <AssociatedField
              field="supplements"
              resourceIds={baseServicepackage.base_supplements}
              options={baseSupplementOptions}
              addResource={addBaseSupplement}
              owningId={baseServicepackage.id}
              label={t("baseSupplementLabel")}
              removeResource={removeBaseSupplement}
              isLoading={baseSupplementsLoading}
              title={t("baseSupplementsLabel")}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)((props: BaseServicepackageProps) => (
  <ConfirmationDialogServiceProvider>
    <BaseServicepackageView {...props} />
  </ConfirmationDialogServiceProvider>
));
