/* eslint-disable import/no-anonymous-default-export */
import { put, takeLatest } from "redux-saga/effects";
import { Action } from "../../types";
import { RESET_STATE } from "../actionTypes";

import authenticationService from "../../../services/authenticationService";
import {
  AUTHENTICATION_REQUEST,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  AUTHENTICATING
} from "./actionTypes";
import {
  removeFromSessionStore,
  setSessionStore
} from "../../../services/storageService";

function* authenticate(action: Action<any>) {
  try {
    yield put({
      type: AUTHENTICATING,
      payload: {
        authenticating: true
      }
    });
    yield authenticationService.getDomainInfo(action.payload.domain);
    if (action.payload.rememberMe) {
      setSessionStore(
        "rememberUser",
        JSON.stringify({
          domain: action.payload.domain,
          username: action.payload.username,
          rememberMe: action.payload.rememberMe
        })
      );
    } else {
      removeFromSessionStore("rememberUser");
    }
    yield authenticationService.authenticate(
      action.payload.username,
      action.payload.password,
      action.payload.domain
    );

    yield put({
      type: AUTHENTICATION_SUCCESS,
      payload: {
        domain: action.payload.domain,
        username: action.payload.username,
        rememberMe: action.payload.rememberMe
      }
    });
  } catch (e: any) {
    removeFromSessionStore("rememberUser");
    console.log("AUTHENTICATION FAIL: ", e);
    yield put({ type: AUTHENTICATION_FAILURE, message: e?.message });
  }
  yield put({
    type: AUTHENTICATING,
    payload: {
      authenticating: false
    }
  });
}

export function* authenticateSaga() {
  yield takeLatest(AUTHENTICATION_REQUEST, authenticate);
}

function* logout(action: Action<any>) {
  try {
    yield authenticationService.logout();
    yield put({
      type: RESET_STATE
    });
  } catch (e: any) {
    yield put({ type: LOGOUT_FAILURE, message: e.message });
  }
}

export function* logoutSaga() {
  yield takeLatest(LOGOUT_REQUEST, logout);
}

export default [authenticateSaga(), logoutSaga()];
