import { TermsState, Action } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: TermsState = {
  terms: {
    terms: [],
    next: null,
    previous: null,
    count: 0
  },
  term: {
    term: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      status: "",
      description: "",
      terms_text: "",
      notes: "",
      owner: -1
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.TERMS):
      return {
        ...state,
        terms: {
          terms: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.TERM):
      return {
        ...state,
        term: {
          term: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.TERM):
      return {
        ...state,
        terms: {
          ...state.terms,
          terms: [...state.terms.terms, action.payload]
        }
      };
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.TERM):
      return {
        ...state,
        terms: {
          ...state.terms,
          terms: [
            ...state.terms.terms.map((term) =>
              term.id === action.payload.id ? action.payload : term
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
