import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import { BaseSupplement, Contract, Supplement } from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import ResourceLink from "../../links/ResourceLink";
import {
  getBaseSupplementAPI,
  getSupplementAPI
} from "../../../services/api-declaration";
import * as Yup from "yup";
import LockIcon from "@material-ui/icons/Lock";
import EditableSimpleSelectField from "../../EditableSimpleSelectField";
import { EnumStrings } from "../../../redux/strings";
import EditableTimePicker from "../../EditableTimePicker";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { format } from "date-fns";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface SupplementProps extends WithStyles<typeof styles> {
  tabId: number;
  supplementId: number;
  contract: Contract | undefined;
}

const SupplementView: React.FC<SupplementProps> = (props) => {
  const { classes, tabId, contract, supplementId } = props;
  const t = useTranslate("SupplementPage");
  const t2 = useTranslate("Basesupplements");
  const t3 = useTranslate("ValidationErrorMessages");

  const [basesupplement, setBasesupplement] = useState<BaseSupplement>();
  const [expanded, setExpanded] = useState(false);
  const [supplement, setSupplement] = useState<Supplement>();
  const updateResourceState = (value: Supplement) => setSupplement(value);

  const patchUrl = `contracts/supplements/${supplementId}/`;

  useEffect(() => {
    if (supplement) store.dispatch(setTitle(supplement.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplement]);

  useEffect(() => {
    let alive = true;

    if (contract?.id) {
      (async () => {
        const supplement = await getSupplementAPI(supplementId);
        const basesupplementResponse = await getBaseSupplementAPI(
          supplement.base
        );

        if (alive) {
          setSupplement(supplement);
          setBasesupplement(basesupplementResponse);
        }
      })();
    }
    return () => {
      alive = false;
    };
  }, [contract?.id, supplementId]);

  const supplementTypeOptions = [
    EnumStrings.WEEKDAY,
    EnumStrings.WEEKEND,
    EnumStrings.HOLIDAY
  ];

  const timeToDate = (time: string) => {
    if (
      typeof time === "string" &&
      time !== "" &&
      time.search(/\d\d:\d\d/) !== -1
    ) {
      const hour = parseInt(time.slice(0, 2));
      const minute = parseInt(time.slice(3, 5));
      let date = new Date();
      return date.setHours(hour, minute);
    }
    return new Date();
  };

  const translateFunction = (value: string) => t2(value);

  const supplementIsLocked =
    contract?.locked_items["supplements"].includes(supplementId) || false;

  if (!supplement) return <LoadingSpinnerPaper />;
  return supplement.contract !== contract?.id ? (
    <Paper className={classes.pageNotFound}>
      <Typography variant="h6">{t("pageNotFoundTitle")}</Typography>
    </Paper>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      {contract?.name}: <b>{supplement.name}</b>{" "}
                      {supplementIsLocked && (
                        <Tooltip title={t("supplementLockedLabel")}>
                          <LockIcon />
                        </Tooltip>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {supplementIsLocked ? (
                      supplement.name
                    ) : (
                      <EditableTextField
                        field="name"
                        initValue={supplement.name}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .min(1, t3("minLengthError"))
                          .max(255, t3("max255LengthError"))
                          .required(t3("requiredError"))}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contract && (
                      <ResourceLink
                        label={contract.name}
                        id={contract.id}
                        url="contracts/contracts"
                      />
                    )}
                  </TableCell>
                </TableRow>
                {supplement.price !== null && (
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {supplementIsLocked ? (
                        supplement.price
                      ) : (
                        <EditableTextField
                          isPrice
                          field="price"
                          initValue={supplement.price}
                          url={patchUrl}
                          updateResourceState={updateResourceState}
                          number={true}
                          validation={Yup.number()
                            .typeError(t3("numberRequiredError"))
                            .min(0, t3("minZeroError"))
                            .max(10000000, t3("max10000000NumberError"))
                            .required(t3("requiredError"))}
                        />
                      )}{" "}
                      kr {supplement.unit ? `/ ${supplement.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key={5}>
                  <TableCell align="left" className={classes.bold}>
                    {t("baseSupplementLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {basesupplement && (
                      <ResourceLink
                        url="contracts/basesupplements"
                        label={basesupplement.name}
                        id={basesupplement.id}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={6}>
                  <TableCell align="left" className={classes.bold}>
                    {t("supplementTypeLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {supplementIsLocked ? (
                      t2(supplement.supplement_type)
                    ) : (
                      <EditableSimpleSelectField
                        field="supplement_type"
                        initValue={supplement.supplement_type}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        options={supplementTypeOptions.map((option) => ({
                          label: t2(option),
                          value: option
                        }))}
                        translateFunction={translateFunction}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={supplement.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("displayLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="display"
                        label={t("displayLabel")}
                        initValue={supplement.display}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("orderByLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="order_by"
                        initValue={supplement.order_by}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell align="left" className={classes.bold}>
                      {t("startTimeLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {supplementIsLocked ? (
                        supplement.start_time &&
                        format(timeToDate(supplement.start_time), "HH:mm")
                      ) : (
                        <EditableTimePicker
                          field="start_time"
                          initValue={supplement.start_time}
                          url={patchUrl}
                          updateResourceState={updateResourceState}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className={classes.bold}>
                      {t("endTimeLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {supplementIsLocked ? (
                        supplement.end_time &&
                        format(timeToDate(supplement.end_time), "HH:mm")
                      ) : (
                        <EditableTimePicker
                          field="end_time"
                          initValue={supplement.end_time}
                          url={patchUrl}
                          updateResourceState={updateResourceState}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow key={7}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(supplement.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={8}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(supplement.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SupplementView);
