import BaseItems from "../../../components/contracts/BaseItems";
import { NavItemClassEnum } from "../../../strings/TabRouterStrings";
import { LayoutRouteUsage } from "../../../redux/types";
import { LocalShipping } from "@material-ui/icons";
import ContractItemContainer from "../services/ContractItemContainer";
import Baseaccessory from "../../../components/contracts/accessories/Baseaccessory";

const AccessoriesRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["contracts/baseaccessories"],
    requireSuperuser: true,
    routes: [
      {
        path: "/contracts/baseaccessories",
        label: t("contractNavbarBaseAccessoriesLabel"),
        component: BaseItems,
        subTabIndex: 3,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS,
        icon: LocalShipping
      },
      {
        path: "/contracts/baseaccessories/:baseAccessoryId",
        parent: "/contracts/baseaccessories",
        label: t("baseAccessoryLabel"),
        component: Baseaccessory,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/:contractId/accessories/:contractItemId",
        parent: "/contracts/accessories",
        label: t("accessoryLabel"),
        component: ContractItemContainer,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      },
      {
        path: "/contracts/baseitems",
        label: t("baseItemsLabel"),
        component: BaseItems,
        highlightClass: NavItemClassEnum.SERVICESANDPRODUCTS
      }
    ]
  }
];

export default AccessoriesRouter;
