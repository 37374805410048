import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Project, BusinessArea } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { ReduxActionStrings, RestStrings } from "../../../redux/strings";
import _ from "lodash";
import ResourcesTableContainer from "../../../components/resourcetable/ResourcesTableContainer";

interface ProjectsContainerProps {
  projects: Project[];
  count: number;
  loadingProjects: boolean;
  resetProjects: () => void;
  loadProjects: (
    page: number,
    page_size: number,
    searchStr?: string,
    orderingStr?: string
  ) => void;
  loadBusinessareas: (businessareaIds: number[]) => void;
  businessareas: BusinessArea[];
}

const ProjectsContainer: React.FC<ProjectsContainerProps> = (props) => {
  const {
    loadProjects,
    projects,
    loadingProjects,
    resetProjects,
    businessareas,
    loadBusinessareas
  } = props;
  const page = 1;
  const pageSize = 20;

  useEffect(() => loadProjects(page, pageSize), [loadProjects]);
  useEffect(
    () =>
      loadBusinessareas(
        _.uniq(projects.map((project) => project.businessarea))
      ),
    [loadBusinessareas, projects]
  );
  useEffect(() => () => resetProjects(), [resetProjects]);

  const columnDefinitions = [
    { name: "name", type: "link" },
    {
      name: "businessarea",
      type: "foreignLink",
      foreignData: {
        data: businessareas,
        visibleColumns: ["name"],
        url: "contracts/businessareas"
      }
    }
  ];

  return (
    <ResourcesTableContainer
      resources={projects}
      resourceName={"Project"}
      count={props.count}
      loadingResources={loadingProjects}
      routeUrl={"contracts/projects"}
      columnDefinitions={columnDefinitions}
      resetResources={resetProjects}
      loadResources={loadProjects}
      defaultPageSize={pageSize}
      filterDefinitions={[]}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadProjects: (
    page: number,
    pageSize: number,
    searchStr?: string,
    orderingStr?: string
  ) => {
    const searchFilter = searchStr
      ? `&filter__name__icontains=${searchStr}`
      : "";

    const orderingFilter =
      orderingStr && orderingStr !== "none"
        ? `&order__name=${orderingStr}`
        : "";

    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PROJECTS,
        `contracts/projects/?page=${page}&page_size=${pageSize}${searchFilter}${orderingFilter}`
      )
    );
  },
  loadBusinessareas: (businessareaIds: number[]) => {
    if (businessareaIds.length > 0) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.BUSINESSAREAS,
          `contracts/businessareas/?include=["id","name"]&filter__id__in=[${businessareaIds}]`
        )
      );
    }
  },
  resetProjects: () =>
    dispatch(LoadingBooleansActions.ResetProjects(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  projects: state.tabStates[ownProps.tabId].projects.projects.projects,
  businessareas:
    state.tabStates[ownProps.tabId].businessAreas.businessAreas.results,
  count: state.tabStates[ownProps.tabId].projects.projects.count,
  loadingProjects:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingProjects
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer);
