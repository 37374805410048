import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  PersonForm,
  Person,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../redux/types";
import PersonNewEditForm from "../../components/persons/PersonNewEditForm";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import LoadingSpinnerPaper from "../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../helpers/routesHelper";

interface EditPersonContainerProps
  extends FormikSubmitDispatchProps<PersonForm>,
    TabComponentProps<"personId"> {
  person: Person;
  userOptions: SelectFieldOption[];
  companyOptions: SelectFieldOption[];
  loadPerson: (personId: number) => void;
  loadCompanies: (searchTerm?: string) => void;
  loadingPerson: boolean;
  loadCustomerobjects: (companyId: number | null) => void;
  customerobjectOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Person | PersonForm> {
  loadPerson: (personId: number) => void;
  loadCompanies: (searchTerm?: string) => void;
  loadCustomerobjects: (companyId: number | null) => void;
}

const EditPersonContainer: React.FC<EditPersonContainerProps> = (props) => {
  const {
    loadPerson,
    person,
    routeParams: { personId },
    loadCompanies,
    loadingPerson,
    loadCustomerobjects
  } = props;
  useEffect(() => loadPerson(personId), [loadPerson, personId]);
  useEffect(() => loadCompanies(), [loadCompanies]);
  useEffect(
    () => loadCustomerobjects(person.company),
    [loadCustomerobjects, person.company]
  );

  return loadingPerson ? (
    <LoadingSpinnerPaper />
  ) : (
    <PersonNewEditForm
      companyOptions={props.companyOptions}
      person={person}
      handleSubmit={props.handleSubmit}
      customerobjectOptions={props.customerobjectOptions}
      loadCustomerobjects={props.loadCustomerobjects}
      loadCompanies={props.loadCompanies}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.PERSON,
        `auth/persons/${(values as Person).id}/`,
        values,
        `/auth/persons/${(values as Person).id}?same_tab=true`
      )
    );
  },
  loadPerson: (personId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSON,
        `auth/persons/${personId}/`
      )
    );
  },
  loadCompanies: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.COMPANIES,
        `companies/companies/?order__name=asc&filter__status=ACTIVE&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadCustomerobjects: (companyId: number | null) => {
    if (companyId) {
      dispatch(
        RestActions.restRequest(
          ownProps.tabId,
          RestStrings.GET,
          ReduxActionStrings.CUSTOMEROBJECTS,
          `contracts/customerobjects/?order__name=asc&filter__customer=${companyId}`
        )
      );
    }
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  person: state.tabStates[ownProps.tabId].persons.person.person,
  companyOptions: state.tabStates[
    ownProps.tabId
  ].companies.companies.companies.map((company) => ({
    label: company.name,
    value: company.id
  })),
  customerobjectOptions: state.tabStates[
    ownProps.tabId
  ].customerObjects.customerobjects.customerobjects.map((customerObject) => ({
    label: customerObject.name,
    value: customerObject.id
  })),
  loadingPerson: state.tabStates[ownProps.tabId].loadingBooleans.loadingPerson
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPersonContainer);
