import { LayoutRouteUsage } from "../../redux/types";
import CompaniesContainer from "../../containers/companies/companies/CompaniesContainer";
import CompanyContainer from "../../containers/companies/companies/CompanyContainer";
import CompanyEditFormContainer from "../../containers/companies/companies/CompanyEditFormContainer";
import CompanyNewFormContainer from "../../containers/companies/companies/CompanyNewFormContainer";
import { NavItemClassEnum } from "../../strings/TabRouterStrings";
import BusinessIcon from "@material-ui/icons/Business";
import InvoiceLocationForm from "./companies/CompanyInvoiceLocationForm";

const CompaniesRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["companies/companies"],
    requireSuperuser: true,
    routes: [
      {
        path: "?inspect_socket=true",
        label: "",
        component: undefined
      },
      {
        path: "/companies/companies",
        label: t("companyNavbarCompaniesLabel"),
        component: CompaniesContainer,
        icon: BusinessIcon,
        highlightClass: NavItemClassEnum.COMPANIES,
        navId: NavItemClassEnum.COMPANIES
      },
      {
        path: "/companies/companies/new",
        parent: "/companies/companies",
        label: t("newCompanyLabel"),
        component: CompanyNewFormContainer,
        highlightClass: NavItemClassEnum.COMPANIES
      },
      {
        path: "/companies/companies/:companyId",
        parent: "/companies/companies",
        label: t("companyLabel"),
        component: CompanyContainer,
        highlightClass: NavItemClassEnum.COMPANIES
      },
      {
        path: "/companies/companies/:companyId/edit",
        parent: "/companies/companies/:companyId",
        label: t("editCompanyLabel"),
        component: CompanyEditFormContainer,
        highlightClass: NavItemClassEnum.COMPANIES
      },
      {
        path: "/companies/companies/:companyId/edit-invoice-location",
        parent: "/companies/companies/:companyId",
        label: t("editInvoiceLocation"),
        component: InvoiceLocationForm,
        highlightClass: NavItemClassEnum.COMPANIES
      }
    ]
  }
];

export default CompaniesRouter;
