/* eslint-disable react-hooks/rules-of-hooks */
import { useTranslate } from "../services/appLanguageService";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";

const dateTimeFormat = useTranslate("dateTimeFormat")();
export const dashDateFormat = useTranslate("dashDateFormat")();
const dateFormat = useTranslate("dateFormat")();
export const timeFormat = useTranslate("timeFormat")();

export const niceDate = (date: string | null, time = true, seconds = false) => {
  if (!date || date === "") return date;
  return format(
    parseISO(date),
    "dd MMM yyyy" + (time ? ` HH:mm${seconds ? ":ss" : ""}` : "")
  );
};

export const getDate = (date: string | Date) => {
  const parsed = typeof date === "string" ? parseISO(date) : date;
  return isValid(parsed) ? format(parsed, "yyyy-MM-dd") : "";
};

export const shortDate = (date: string) => {
  if (date === "") return date;
  return format(parseISO(date), "dd MMM");
};

export const formatDateTime = (date: string | number, timeOnly = false) =>
  date ? format(new Date(date), timeOnly ? timeFormat : dateTimeFormat) : "";

export const formatDashDate = (date: string | number) =>
  date ? format(new Date(date), dashDateFormat) : "";

export const formatDate = (date: string | number) => {
  return date ? format(new Date(date), dateFormat) : "";
};

export const getFilterDateFromISO = (date: string) => {
  return format(new Date(date), "yyyy-MM-dd");
};

export const getTimeFromDate = (date: string | number) => {
  return date ? format(new Date(date), timeFormat) : "";
};

export const timeToDate = (time: string) => {
  if (typeof time !== "string") {
    return time;
  }

  if (
    typeof time === "string" &&
    time !== "" &&
    time.search(/\d\d:\d\d/) !== -1
  ) {
    const hour = parseInt(time.slice(0, 2));
    const minute = parseInt(time.slice(3, 5));
    let date = new Date();
    return date.setHours(hour, minute);
  }
  return new Date();
};

export const niceAmount = (amount: any) => {
  const parsedAmount = Number(amount);
  if (isNaN(parsedAmount)) {
    return amount;
  }
  return parsedAmount
    .toFixed(2)
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(/^-\s*/, "- ")
    .concat(" kr");
};

export const snakeToCamel = (str: string) =>
  str.replace(/([_][a-z])/g, (group) => group.toUpperCase().replace("_", ""));

export const niceDecimal = (decimal: number) => {
  var sign = decimal < 0 ? "-" : "";
  var min = Math.floor(Math.abs(decimal));
  var sec = Math.floor((Math.abs(decimal) * 60) % 60);
  return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
};

export const convertToPlural = (value: string) =>
  value.endsWith("y") && !value.endsWith("ay")
    ? value.slice(0, value.length - 1) + "ies"
    : value + "s";
