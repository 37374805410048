import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  BusinessAreaForm,
  BusinessArea,
  mainRootState,
  SelectFieldOption
} from "../../../redux/types";
import BusinessAreaNewForm from "../../../components/contracts/businessAreas/BusinessAreaNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface NewBusinessAreaContainerProps
  extends FormikSubmitDispatchProps<BusinessAreaForm> {
  loadContractingProfiles: (searchTerm?: string) => void;
  loadInvoicingProfiles: (searchTerm?: string) => void;
  invoicingProfileOptions: SelectFieldOption[];
  contractingProfileOptions: SelectFieldOption[];
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<BusinessArea | BusinessAreaForm> {
  loadContractingProfiles: (searchTerm?: string) => void;
  loadInvoicingProfiles: (searchTerm?: string) => void;
}

const NewBusinessAreaContainer: React.FC<NewBusinessAreaContainerProps> = (
  props
) => {
  const { loadInvoicingProfiles, loadContractingProfiles } = props;

  useEffect(() => loadInvoicingProfiles(), [loadInvoicingProfiles]);
  useEffect(() => loadContractingProfiles(), [loadContractingProfiles]);
  return (
    <BusinessAreaNewForm
      invoicingProfileOptions={props.invoicingProfileOptions}
      contractingProfileOptions={props.contractingProfileOptions}
      handleSubmit={props.handleSubmit}
      loadContractingProfiles={props.loadContractingProfiles}
      loadInvoicingProfiles={props.loadInvoicingProfiles}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData<BusinessAreaForm>(
        ownProps.tabId,
        RestStrings.POST,
        ReduxActionStrings.BUSINESSAREA,
        "contracts/businessareas/",
        checkedNullValues as BusinessArea,
        "/contracts/businessareas?same_tab=true"
      )
    );
  },
  loadInvoicingProfiles: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.INVOICINGPROFILES,
        `invoices/invoicingprofiles/?page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  },
  loadContractingProfiles: (searchTerm?: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CONTRACTINGPROFILES,
        `contracts/contractingprofiles/?page=1&page_size=10&include=["id","name"]&filter__name__icontains=${
          searchTerm || ""
        }`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  invoicingProfileOptions: state.tabStates[
    ownProps.tabId
  ].invoicingProfiles.invoicingProfiles.invoicingProfiles.map(
    (invoicingProfile) => ({
      label: invoicingProfile.name,
      value: invoicingProfile.id
    })
  ),
  contractingProfileOptions: state.tabStates[
    ownProps.tabId
  ].contractingProfiles.contractingProfiles.contractingProfiles.map(
    (contractingProfile) => ({
      label: contractingProfile.name,
      value: contractingProfile.id
    })
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBusinessAreaContainer);
