import React, { ChangeEvent, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  MenuItem,
  DialogContent,
  InputLabel,
  TextField,
  TablePagination
} from "@material-ui/core";
import { Add, DeleteOutline } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { SelectFieldOption, Task } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import {
  createContractCooperationAPI,
  createContractTaskAPI,
  deleteContractCooperationAPI,
  deleteContractTaskAPI,
  getActiveContractIdsByCompanyIdAPI,
  getActiveContractsAPI,
  getCompanyNamesAPI,
  getContractBusinessAreasAPI,
  getContractCooperationsAPI,
  getContractTasksAPI,
  updateContractTaskAPI
} from "../../../services/api-declaration";
import ConfirmationDialog from "../../confirmationDialog/ConfirmationDialog";
import MapView from "../tasks/Map";
import ImageContainer from "../../../containers/ImageContainer";
import EditableNotes from "../../EditableNotes";
import { Autocomplete } from "@material-ui/lab";

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    }
  })
);

type CustomerObjectCreateTaskModalProps = {
  customerObjectId: number;
  businessAreaNames: Map<number, { name: string }>;
  customerContractIds: number[];
  onCreate: (
    businessAreaId: number,
    customerObjectId: number,
    customerContractIds: number[]
  ) => Promise<void>;
  onClose: () => void;
};

const CustomerObjectCreateTaskModal: React.FC<
  CustomerObjectCreateTaskModalProps
> = ({
  customerObjectId,
  businessAreaNames,
  customerContractIds,
  onCreate,
  onClose
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [businessAreaId, setBusinessAreaId] = useState<number>(0);
  const t = useTranslate("TaskTable");
  const classes = useModalStyles();

  const handleCreate = () => {
    if (businessAreaId !== 0) {
      setLoading(true);
      void onCreate(businessAreaId, customerObjectId, customerContractIds);
    }
  };

  const disabled = !businessAreaId || loading;

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>{t("addTaskLabel")}</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <InputLabel>{t("businessareaLabel")}</InputLabel>
          <Select
            value={businessAreaId}
            fullWidth
            onChange={(e) => setBusinessAreaId(e.target.value as number)}
          >
            <MenuItem disabled value={0}>
              None
            </MenuItem>
            {[...businessAreaNames].map(([id, { name }]) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t("cancelButtonText")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={loading ? <CircularProgress /> : undefined}
          disabled={disabled}
          onClick={handleCreate}
        >
          {t("createButtonText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type CustomerObjectCreateCooperationModalProps = {
  ignoreCompanies: number[];
  onCreate: (companyId: number) => Promise<void>;
  onClose: () => void;
};

const CustomerObjectCreateCooperationModal: React.FC<
  CustomerObjectCreateCooperationModalProps
> = ({ ignoreCompanies, onCreate, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] =
    useState<{ id: number; name: string; company: string }[]>();
  const [companyId, setCompanyId] = useState<number>(0);
  const t = useTranslate("TaskTable");
  const classes = useModalStyles();

  // Since array are usually recreated.
  const ignoreCompaniesKey = ignoreCompanies.join(",");
  useEffect(() => {
    let alive = true;

    const ignoreCompaniesSet = new Set(ignoreCompanies);
    void getActiveContractsAPI("CONTRACTOR").then(async ({ results }) => {
      const contracts = results.filter(
        (c) => !ignoreCompaniesSet.has(c.company)
      );
      if (!contracts.length) {
        alive && setContracts([]);
      } else {
        const companyNames = await getCompanyNamesAPI({
          id__in: contracts.map((c) => c.company)
        }).then(
          ({ results }) => new Map(results.map(({ id, name }) => [id, name]))
        );
        alive &&
          setContracts(
            contracts.map((c) => ({
              id: c.id,
              name: c.name,
              company: companyNames.get(c.company) ?? ""
            }))
          );
      }
    });

    return () => {
      alive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreCompaniesKey]);

  const handleCreate = () => {
    if (companyId !== 0) {
      setLoading(true);
      void onCreate(companyId);
    }
  };

  const disabled = !companyId || loading;

  const selectedCooperation = contracts?.find(
    (contract) => contract.id === companyId
  );

  const selectedCooperationOption: SelectFieldOption = {
    label: selectedCooperation
      ? `${selectedCooperation.company} (${selectedCooperation.name})`
      : "",
    value: selectedCooperation?.id || 0
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>{t("addCooperationLabel")}</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <Autocomplete
            value={selectedCooperationOption}
            onChange={(
              event: ChangeEvent<{}>,
              cooperationOption: SelectFieldOption | null
            ) => {
              setCompanyId(cooperationOption?.value || -1);

              return cooperationOption;
            }}
            options={
              contracts
                ?.sort((a, b) =>
                  a.company.toLowerCase() === b.company.toLowerCase()
                    ? 0
                    : a.company.toLowerCase() > b.company.toLowerCase()
                    ? 1
                    : -1
                )
                .map(({ id, name, company }) => ({
                  label: `${company} (${name})`,
                  value: id
                })) || []
            }
            getOptionLabel={(option: SelectFieldOption) => option.label}
            renderInput={(params: any) => (
              <TextField {...params} label={t("companyLabel")} />
            )}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t("cancelButtonText")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={loading ? <CircularProgress /> : undefined}
          disabled={disabled}
          onClick={handleCreate}
        >
          {t("createButtonText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1)
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2)
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h6": {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
      }
    },
    noMap: {
      padding: theme.spacing(2)
    },
    bold: {
      fontWeight: "bold"
    },
    addButton: {
      marginRight: theme.spacing(2)
    },
    imageContainer: {
      paddingRight: theme.spacing(2.5),
      paddingLeft: theme.spacing(2.5)
    }
  })
);

const CustomerObjectCooperationTable: React.FC<{
  task: Task;
}> = ({ task }) => {
  const t = useTranslate("TaskTable");
  const [cooperations, setCooperations] =
    useState<
      { id: number; contractorCompanyId: number; contractorCompany: string }[]
    >();
  const [modal, setModal] = useState<
    { m: "create" } | { m: "delete"; cooperationId: number }
  >();
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const classes = useStyles();
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    let alive = true;

    void getContractCooperationsAPI({
      task_id: task.id
    }).then(async ({ results }) => {
      if (!results.length) {
        if (alive) {
          setCooperations([]);
          setLoading(false);
        }
      } else {
        const companyNames = await getCompanyNamesAPI({
          id__in: results.map((c) => c.contractor_company)
        }).then(
          ({ results }) => new Map(results.map(({ id, name }) => [id, name]))
        );
        if (alive) {
          setCooperations(
            results.map((c) => ({
              id: c.id,
              contractorCompanyId: c.contractor_company,
              contractorCompany: companyNames.get(c.contractor_company) ?? ""
            }))
          );
          setLoading(false);
        }
      }
    });

    return () => {
      alive = false;
    };
  }, [task.customer_contracts, task.id, refresh]);

  const handleCreate = async (contractId: number): Promise<void> => {
    if (modal?.m === "create") {
      setModal(undefined);
      setLoading(true);
      await createContractCooperationAPI({
        taskId: task.id,
        contractId
      })
        .then(() => setRefresh((r) => !r))
        .catch(() => setLoading(false));
    }
  };

  const handleDelete = async (): Promise<void> => {
    if (modal?.m === "delete") {
      const { cooperationId } = modal;
      setModal(undefined);
      setLoading(true);
      await deleteContractCooperationAPI(cooperationId)
        .then(() => setRefresh((r) => !r))
        .catch(() => setLoading(false));
    }
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h6">{t("cooperationHeadingText")}</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          className={classes.addButton}
          onClick={() => setModal({ m: "create" })}
        >
          Add
        </Button>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.bold} colSpan={2}>
              {t("companyLabel")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!cooperations?.length && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                {t("noCooperations")}
              </TableCell>
            </TableRow>
          )}
          {cooperations
            ?.slice(pageIndex * 10, pageIndex * 10 + 10)
            .map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/companies/companies/${row.contractorCompanyId}`}
                  >
                    {row.contractorCompany}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() =>
                      setModal({ m: "delete", cooperationId: row.id })
                    }
                    disabled={
                      modal?.m === "delete" && modal.cooperationId === row.id
                    }
                    size="small"
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[10]}
          count={cooperations?.length ?? 0}
          rowsPerPage={10}
          page={pageIndex}
          onPageChange={(_, page) => setPageIndex(page)}
        />
      </Table>
      {(loading || !cooperations) && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {cooperations !== undefined && modal?.m === "create" && (
        <CustomerObjectCreateCooperationModal
          ignoreCompanies={cooperations.map((c) => c.contractorCompanyId)}
          onCreate={handleCreate}
          onClose={() => {
            setModal(undefined);
            setLoading(false);
          }}
        />
      )}
      {modal?.m === "delete" && modal.cooperationId && (
        <ConfirmationDialog
          title={t("confirmDeleteTitle")}
          description={t("confirmDeleteText")}
          onSuccess={handleDelete}
          onClose={() => {
            setModal(undefined);
            setLoading(false);
          }}
          open
        />
      )}
    </Paper>
  );
};

type CustomerObjectTaskTableProps = {
  customerObjectId: number;
  companyId: number;
};

export const CustomerObjectTaskTable: React.FC<
  CustomerObjectTaskTableProps
> = ({ customerObjectId, companyId }) => {
  const [modal, setModal] = useState<
    { m: "create" } | { m: "delete"; taskId: number }
  >();
  const [tasks, setTasks] = useState<Task[]>();
  const [selectedId, setSelectedId] = useState<number>();
  const [businessAreaNames, setBusinessAreaNames] =
    useState<Map<number, { name: string }>>();
  const [customerContractIds, setCustomerContractIds] = useState<number[]>();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const t = useTranslate("TaskTable");
  const classes = useStyles();

  useEffect(() => {
    let alive = true;
    void Promise.all([
      getContractTasksAPI({ customerobject: customerObjectId }),
      getContractBusinessAreasAPI({}),
      getActiveContractIdsByCompanyIdAPI(companyId)
    ]).then(
      ([
        { results: tasks },
        { results: businessareas },
        { results: contractIds }
      ]) => {
        if (alive) {
          tasks.sort((a, b) => a.name.localeCompare(b.name));
          setTasks(tasks);
          setSelectedId(tasks[0]?.id);
          setBusinessAreaNames(new Map(businessareas.map((a) => [a.id, a])));
          setCustomerContractIds(contractIds.map((c) => c.id));
        }
      }
    );
    return () => {
      alive = false;
    };
  }, [customerObjectId, companyId, refresh]);

  const handleCreate = async (
    businessAreaId: number,
    customerObjectId: number,
    customerContractIds: number[]
  ): Promise<void> => {
    if (modal?.m === "create") {
      setModal(undefined);
      setLoading(true);
      await createContractTaskAPI(
        businessAreaId,
        customerObjectId,
        customerContractIds
      )
        .then(() => setRefresh((r) => !r))
        .finally(() => setLoading(false));
    }
  };

  const handleDelete = async (): Promise<void> => {
    if (tasks && modal?.m === "delete") {
      const { taskId } = modal;
      setModal(undefined);
      setLoading(true);
      await deleteContractTaskAPI(taskId)
        .then(() => setRefresh((r) => !r))
        .finally(() => setLoading(false));
    }
  };

  const patchTask = async (
    taskId: number,
    patch: Partial<Pick<Task, "work_description">>
  ): Promise<void> => {
    if (taskId) {
      await updateContractTaskAPI(taskId, patch);
      setRefresh((r) => !r);
    }
  };

  const selectedTask = tasks?.find((t) => t.id === selectedId);

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h6">{t("taskHeadingText")}</Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            className={classes.addButton}
            onClick={() => setModal({ m: "create" })}
          >
            Add
          </Button>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.bold}></TableCell>
              <TableCell className={classes.bold}>{t("nameLabel")}</TableCell>
              <TableCell className={classes.bold} colSpan={2}>
                {t("businessareaLabel")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!tasks?.length && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {t("noTasks")}
                </TableCell>
              </TableRow>
            )}
            {tasks?.map((row) => (
              <TableRow key={row.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={row.id === selectedId}
                    onClick={() => setSelectedId(row.id)}
                  />
                </TableCell>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/contracts/tasks/${row.id}`}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {businessAreaNames?.get(row.businessarea)?.name}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => setModal({ m: "delete", taskId: row.id })}
                    disabled={modal?.m === "delete" && modal.taskId === row.id}
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(loading || !tasks) && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </Paper>
      {selectedTask && (
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h6">{selectedTask.name}</Typography>
          </div>

          <EditableNotes
            customLabel={t("workDescriptionLabel")}
            resourceId={selectedTask.id}
            notes={selectedTask.work_description}
            saveNotes={(_, work_description) =>
              patchTask(selectedTask.id, {
                work_description: work_description ?? ""
              })
            }
          />

          {selectedTask.geo_polygons_image && (
            <div className={classes.imageContainer}>
              <ImageContainer
                src={selectedTask.geo_polygons_image}
                alt={selectedTask.name}
                type="image"
              />
            </div>
          )}
          {selectedTask.geo_polygons && (
            <MapView
              geo_polygons={selectedTask.geo_polygons}
              editable={false}
              resourceId={selectedTask.id}
            />
          )}
          {!selectedTask.geo_polygons && !selectedTask.geo_polygons_image && (
            <div className={classes.noMap}>{t("noMapAvailable")}</div>
          )}
          <CustomerObjectCooperationTable task={selectedTask} />
        </Paper>
      )}
      {modal?.m === "create" && businessAreaNames && customerContractIds && (
        <CustomerObjectCreateTaskModal
          customerObjectId={customerObjectId}
          businessAreaNames={businessAreaNames}
          customerContractIds={customerContractIds}
          onCreate={handleCreate}
          onClose={() => {
            setModal(undefined);
            setLoading(false);
          }}
        />
      )}
      {modal?.m === "delete" && modal?.taskId && (
        <ConfirmationDialog
          title={t("confirmDeleteTitle")}
          description={t("confirmDeleteText")}
          onSuccess={handleDelete}
          onClose={() => {
            setModal(undefined);
            setLoading(false);
          }}
          open
        />
      )}
    </>
  );
};
