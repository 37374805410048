import { LayoutRouteUsage } from "../redux/types";
import { NavItemClassEnum } from "../strings/TabRouterStrings";
import MapIcon from "@material-ui/icons/Map";
import LiveView from "./LiveView";

const LiveViewRouter: LayoutRouteUsage = (t) => [
  {
    apiUsage: ["timesheets/timesheets"],
    routes: [
      {
        path: "/live-view",
        label: t("liveViewNavbarLabel"),
        component: LiveView,
        icon: MapIcon,
        highlightClass: NavItemClassEnum.LIVEVIEW,
        navId: NavItemClassEnum.LIVEVIEW,
        suspendable: true
      }
    ]
  }
];

export default LiveViewRouter;
