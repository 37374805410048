import React from "react";
import { Dispatch } from "redux";
import LoginContainer from "../containers/LoginContainer";
import Layout from "./Layout";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import { mainRootState, SnackbarState } from "../redux/types";
import { CssBaseline } from "@material-ui/core";
import { connect } from "react-redux";
import { clearSnackbar } from "../redux/reducers/snackbar/actions";
import { useGlobalSnackbar } from "./snackbar/Snackbar";
import ForgottenPassword from "./forgottenPassword/ForgottenPassword";
import ResetPasswordSuccess from "./resetPassword/ResetPasswordSuccess";

interface AppProps {
  snackbar: SnackbarState;
  clearSnackbar: () => void;
}

const App: React.FC<AppProps> = (props) => {
  const { snackbar, clearSnackbar } = props;
  useGlobalSnackbar(snackbar, clearSnackbar);
  return (
    <>
      <CssBaseline />
      <Switch>
        <Route exact path="/login" component={LoginContainer} />
        <Route exact path="/forget-password" component={ForgottenPassword} />
        <Route
          exact
          path="/reset-password-success"
          component={ResetPasswordSuccess}
        />
        <PrivateRoute path="/" component={Layout} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state: mainRootState) => ({
  snackbar: state.snackbar
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearSnackbar: () => dispatch(clearSnackbar())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
