import { FC, useState } from "react";
import clsx from "clsx";
import {
  Paper,
  Table,
  Tooltip,
  Collapse,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import LaunchIcon from "@material-ui/icons/Launch";
import {
  ConflictData,
  ConflictGroup,
  IApprovalCorrections,
  IApprovalCorrectionsKey,
  IApprovalRowEditContext,
  IDataCarryKey,
  IPackageType
} from "../../redux/types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../services/appLanguageService";
import { useTimeSheetApprovalDialog } from "../timesheetApprovalDialog/DialogServiceProvider";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import { getTimesheetOptionsForApproval } from "../../services/timesheetService";
import { CONFLICTED_EDIT_FIELDS } from "../../strings/WorkApprovalStrings";
import CorrectionsCarry from "../../helpers/classes/verification/CorrectionsCarry";
import { getTimeFromDate, formatDate } from "../FormatHelpers";
import DateTimeFragment from "../timesheetApprovalDialog/dateTimeFragment";
import ApprovalInlinePopup from "./ApprovalInlinePopup";
import { EditModeContainer } from "../timesheetApprovalDialog/editModeContainer";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    loaderPaperConflicts: {
      textAlign: "center",
      padding: `${theme.spacing(3)}px 0`
    },
    loader: {
      width: "50px !important",
      height: "50px !important",
      padding: theme.spacing(0.5)
    },
    expansionSummary: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    expansionDetailPanel: {
      flexWrap: "wrap",
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(1.5)}px`
    },
    timesheetRowActions: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(0)}`
    },
    flex: {
      display: "flex",
      justifyContent: "center"
    },
    flexDiv: {
      display: "flex",
      alignItems: "center"
      // justifyContent: "space-between"
    },
    boldText: {
      color: "#000",
      fontWeight: "bold"
    },
    red: {
      color: "red"
    },
    root: {
      "& > *": {
        position: "relative",
        borderBottom: "unset"
      }
    },
    expandableDiv: {
      padding: theme.spacing(1),
      backgroundColor: "#FAFAFA",
      border: "1px solid #a9a9a9"
    },
    dateTimeField: { marginBottom: theme.spacing(1) },
    table: { minWidth: 750 },
    cellPd: { padding: "6px" },
    paper: { marginTop: theme.spacing(2) },
    expandButton: { width: theme.spacing(2) },
    whiteSapce: { paddingRight: theme.spacing(0.5) },
    pointer: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    nowrap: { whiteSpace: "nowrap" },
    leftIcon: {
      marginRight: theme.spacing(1)
    }
  })
);
interface TimesheetApprovalConflictsProps {
  conflictedRows: ConflictGroup[];
  onEditSuccess: (routeInstanceId: number) => void;
  submitRowModified: (routeInstance_id: number, submit_id: number) => void;
}
interface IApprovalConflictRowEditContext {
  popupField: IApprovalCorrectionsKey | IPackageType | "";
  editId: number;
  correctionsCarry: CorrectionsCarry;
  readables: Pick<IApprovalCorrections, "start_time" | "end_time">;
  isSaving: boolean;
}
const TimesheetApprovalConflicts: FC<TimesheetApprovalConflictsProps> = (
  props
) => {
  const { conflictedRows, onEditSuccess, submitRowModified } = props;
  const t = useTranslate("TimesheetApprovalPage");
  const m = useTranslate("WorkApprovalTooltipMessages");
  const v = useTranslate("TimeSheetRowsAndApprovals");
  const classes = useStyles();
  const openApprovalDialog = useTimeSheetApprovalDialog();
  const [conflictExpanded, setConflictExpanded] = useState(0);
  const [editRowContext, setEditRowContext] =
    useState<IApprovalConflictRowEditContext>({
      popupField: "",
      editId: -1,
      correctionsCarry: {} as CorrectionsCarry,
      readables: {} as unknown as Pick<
        IApprovalCorrections,
        "start_time" | "end_time"
      >,
      isSaving: false
    });

  const handleConflictRowExpansion = ({ id }: ConflictGroup) => {
    correctionPopupClosed();
    setConflictExpanded((oldId) => (id !== oldId ? id : 0));
  };

  const openEditDialog = async ({
    routeinstance_id,
    timesheet_id,
    workApproval
  }: ConflictData) => {
    try {
      const timesheetOptions =
        await getTimesheetOptionsForApproval(timesheet_id);
      await openApprovalDialog(
        {
          open: true,
          maxWidth: "lg",
          children: <></>
        },
        {
          options: timesheetOptions,
          editableFields: CONFLICTED_EDIT_FIELDS,
          defaultCorrectionsCarry: new CorrectionsCarry(workApproval),
          workApproval,
          packageCarry: undefined,
          onClose: () => {},
          onSuccess: () => {}
        }
      );
      showGlobalSnackbar(v("updatedSnackbarLabel"), "success");
      onEditSuccess(routeinstance_id);
    } catch (e) {
      console.warn(e);
    }
  };

  const editModeChanged = (editMode: IDataCarryKey) => {
    setEditRowContext(({ correctionsCarry, ...rest }) => ({
      ...rest,
      correctionsCarry: correctionsCarry.editModeChanged(editMode)
    }));
  };
  const resetField = (fieldName: IApprovalCorrectionsKey) => {
    setEditRowContext(({ correctionsCarry, ...rest }) => ({
      ...rest,
      correctionsCarry: correctionsCarry.correctionChanged(
        fieldName,
        undefined
      ),
      readables: correctionsCarry.getSubmittedReadables()
    }));
  };

  const correctionChanged = (fieldName: IApprovalCorrectionsKey, value: any) =>
    setEditRowContext(({ correctionsCarry, ...rest }) => ({
      ...rest,
      correctionsCarry: correctionsCarry.correctionChanged(fieldName, value),
      readables: correctionsCarry.getSubmittedReadables()
    }));

  const correctionPopupClosed = () =>
    setEditRowContext({
      popupField: "",
      editId: -1,
      correctionsCarry: {} as CorrectionsCarry,
      readables: {} as unknown as Pick<
        IApprovalCorrections,
        "start_time" | "end_time"
      >,
      isSaving: false
    });

  const openInlineEdit = async (
    field: IApprovalRowEditContext["popupField"],
    row: ConflictData,
    idx: number
  ) => {
    if (editRowContext?.editId === idx) {
      setEditRowContext((editContext) => ({
        ...editContext,
        popupField: field
      }));
      return;
    }
    setEditRowContext({
      popupField: field,
      editId: idx,
      correctionsCarry: new CorrectionsCarry(row.workApproval),
      readables: { ...row.readables },
      isSaving: false
    });
  };

  return (
    <Paper className={classes.paper} data-cy="conflicts-container">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-cy="conflict-expand"
          className={classes.expansionSummary}
        >
          <Typography
            className={`${classes.flex} ${classes.red}`}
            id="tableTitle"
          >
            <MergeTypeIcon className={classes.leftIcon} />
            {`${t("conflictsHeading")} (${conflictedRows.reduce(
              (sum, { conflicts }) => sum + conflicts.length,
              0
            )})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={classes.expansionDetailPanel}
          data-cy="expanded-filters"
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellPd}></TableCell>
                <TableCell className={clsx(classes.boldText, classes.cellPd)}>
                  {t("titleHeading")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conflictedRows.map((conflictedRow, index) => [
                <TableRow key={`row-${index}`} className={classes.root}>
                  <TableCell
                    className={clsx(classes.cellPd, classes.expandButton)}
                  >
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleConflictRowExpansion(conflictedRow)}
                    >
                      {conflictExpanded === conflictedRow.id ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => handleConflictRowExpansion(conflictedRow)}
                    className={clsx(
                      classes.cellPd,
                      conflictExpanded === conflictedRow.id
                        ? classes.boldText
                        : ""
                    )}
                  >
                    <div className={classes.flexDiv}>
                      <div>{conflictedRow.routeinstanceTitles.join(", ")}</div>
                      <div style={{ marginLeft: 20 }}>
                        {`(${conflictedRow.conflicts.length})`}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>,
                <TableRow key={`row-${index + 1}`} className={classes.root}>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={10}
                  >
                    <Collapse
                      in={conflictExpanded === conflictedRow.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      {" "}
                      <div className={classes.expandableDiv}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.cellPd}></TableCell>
                              <TableCell
                                className={clsx(
                                  classes.boldText,
                                  classes.cellPd
                                )}
                              >
                                {t("titleHeading")}
                              </TableCell>
                              <TableCell
                                className={clsx(
                                  classes.boldText,
                                  classes.cellPd
                                )}
                              >
                                {t("routeInstanceFilterLabel")}
                              </TableCell>
                              <TableCell
                                className={clsx(
                                  classes.boldText,
                                  classes.cellPd
                                )}
                              >
                                {t("startTimeHeading")}
                              </TableCell>
                              <TableCell
                                className={clsx(
                                  classes.boldText,
                                  classes.cellPd
                                )}
                              >
                                {t("endTimeHeading")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {conflictedRow.conflicts.map(
                              (conflict, rowIndex) => {
                                const isEditing =
                                  rowIndex === editRowContext.editId;
                                return (
                                  <TableRow
                                    key={`row-${rowIndex}`}
                                    className={classes.root}
                                  >
                                    <TableCell
                                      className={clsx(classes.cellPd)}
                                    ></TableCell>
                                    <TableCell className={clsx(classes.cellPd)}>
                                      {conflict.title}
                                    </TableCell>
                                    <TableCell className={clsx(classes.cellPd)}>
                                      {conflict.routeinstance_title}
                                    </TableCell>
                                    {isEditing &&
                                    editRowContext.popupField ===
                                      "start_time" ? (
                                      <TableCell>
                                        <ApprovalInlinePopup
                                          fieldName="start_time"
                                          correctionsCarry={
                                            editRowContext.correctionsCarry
                                          }
                                          editModeChanged={editModeChanged}
                                          resetField={resetField}
                                          correctionPopupClosed={
                                            correctionPopupClosed
                                          }
                                          open={false}
                                        >
                                          <Grid
                                            className={classes.dateTimeField}
                                          >
                                            <DateTimeFragment
                                              fieldName="start_time"
                                              fieldInitials="start"
                                              fieldValue={
                                                editRowContext.correctionsCarry
                                                  .corrections.start_time
                                              }
                                              correctionChanged={
                                                correctionChanged
                                              }
                                            />
                                          </Grid>
                                        </ApprovalInlinePopup>
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        className={clsx(
                                          classes.cellPd,
                                          classes.pointer,
                                          classes.red
                                        )}
                                        onClick={() =>
                                          openInlineEdit(
                                            "start_time",
                                            conflict,
                                            rowIndex
                                          )
                                        }
                                      >
                                        {getTimeFromDate(
                                          conflict.readables.start_time
                                        )}
                                        <br />
                                        {formatDate(
                                          conflict.readables.start_time
                                        )}
                                        <EditModeContainer
                                          dataCarry={
                                            isEditing
                                              ? editRowContext.correctionsCarry
                                                  .dataCarry
                                              : conflict.workApproval
                                          }
                                          fieldName={"start_time"}
                                        />
                                      </TableCell>
                                    )}
                                    {isEditing &&
                                    editRowContext.popupField === "end_time" ? (
                                      <TableCell>
                                        <ApprovalInlinePopup
                                          fieldName="end_time"
                                          correctionsCarry={
                                            editRowContext.correctionsCarry
                                          }
                                          editModeChanged={editModeChanged}
                                          resetField={resetField}
                                          correctionPopupClosed={
                                            correctionPopupClosed
                                          }
                                          open={false}
                                        >
                                          <Grid
                                            className={classes.dateTimeField}
                                          >
                                            <DateTimeFragment
                                              fieldName="end_time"
                                              fieldInitials="end"
                                              fieldValue={
                                                editRowContext.correctionsCarry
                                                  .corrections.end_time
                                              }
                                              correctionChanged={
                                                correctionChanged
                                              }
                                            />
                                          </Grid>
                                        </ApprovalInlinePopup>
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        className={clsx(
                                          classes.cellPd,
                                          classes.pointer,
                                          classes.red
                                        )}
                                        onClick={() =>
                                          openInlineEdit(
                                            "end_time",
                                            conflict,
                                            rowIndex
                                          )
                                        }
                                      >
                                        {getTimeFromDate(
                                          conflict.readables.end_time
                                        )}
                                        <br />
                                        {formatDate(
                                          conflict.readables.end_time
                                        )}
                                        <EditModeContainer
                                          dataCarry={
                                            isEditing
                                              ? editRowContext.correctionsCarry
                                                  .dataCarry
                                              : conflict.workApproval
                                          }
                                          fieldName={"end_time"}
                                        />
                                      </TableCell>
                                    )}
                                    <TableCell
                                      className={clsx(
                                        classes.flex,
                                        classes.timesheetRowActions
                                      )}
                                    >
                                      <Tooltip title={m("editInDialogMessage")}>
                                        <IconButton
                                          onClick={() =>
                                            openEditDialog(conflict)
                                          }
                                        >
                                          <LaunchIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </Collapse>
                  </TableCell>
                </TableRow>
              ])}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default TimesheetApprovalConflicts;
