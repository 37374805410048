import { createContext, useState } from "react";
import { IRowImage } from "../../../redux/types";

export const DrawerContext = createContext<any>(undefined);

export const DrawerProvider = (props: any) => {
  const [imgDetails, setImgDetails] = useState<IRowImage | undefined>(
    undefined
  );
  return (
    <DrawerContext.Provider value={{ imgDetails, setImgDetails }}>
      {props.children}
    </DrawerContext.Provider>
  );
};
