import {
  Paper,
  Typography,
  Button,
  FormControl,
  Select,
  IconButton,
  InputLabel,
  MenuItem,
  FormGroup
} from "@material-ui/core";
import { Field, FieldHookConfig, FormikProps, useField } from "formik";
import { PersonForm, Service, TimeSheetForm } from "../../../redux/types";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "../../../services/appLanguageService";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import DatePicker from "../../DatePicker";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { isEqual } from "lodash";
import { BlurTextField } from "../../routes/routeinstances/BlurTextField";

const useAccompanyingPeopleEditorStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      width: "100%"
    },
    entryContainer: {
      display: "flex",
      marginBottom: theme.spacing(1)
    },
    addContainer: {},
    selectService: {
      width: "250px",
      marginRight: theme.spacing(1)
    },
    inputService: {
      width: "150px"
    }
  })
);

type AccompanyingPeopleOption = {
  serviceId: number;
  name: string;
};

type AccompanyingPeopleServiceMapping = {
  [personId: string]: { service: number };
};

type AccompanyingPeoplesFieldProps = {
  options: AccompanyingPeopleOption[];
  personOptions: { personId: number; name: string }[];
  disabled?: boolean;
} & FieldHookConfig<AccompanyingPeopleServiceMapping>;

const AccompanyingPeopleEditor = ({
  options,
  personOptions,
  disabled,
  ...props
}: AccompanyingPeoplesFieldProps): JSX.Element | null => {
  const t = useTranslate("AfterregistrationPage");
  const classes = useAccompanyingPeopleEditorStyles();
  const [, { value = {} }, { setValue }] = useField(props);
  const [state, setState] = useState<
    { serviceId: number; personId: number | null }[]
  >(() =>
    Object.entries(value).map(([personId, { service: serviceId }]) => ({
      serviceId,
      personId: parseInt(personId)
    }))
  );

  const onStateChange = (newState: typeof state) => {
    const newValue = Object.fromEntries(
      newState
        .filter(
          (o): o is { serviceId: number; personId: number } =>
            o.personId !== null
        )
        .map((o) => [o.personId, { service: o.serviceId }] as const)
    );
    if (!isEqual(value, newValue)) {
      setValue(newValue);
    }
    setState(newState);
  };

  const validState = state.filter(
    (o) =>
      o.personId === null ||
      personOptions.some((p) => p.personId === o.personId)
  );
  if (state.length !== validState.length) {
    onStateChange(validState);
  }

  const onAdd = () => {
    const [option] = options;
    if (option) {
      onStateChange([
        ...state,
        { serviceId: option.serviceId, personId: null }
      ]);
    }
  };
  const onRemove = (idx: number) => {
    const newState = [...state];
    newState.splice(idx, 1);
    onStateChange(newState);
  };
  const onServiceChanged = (idx: number, serviceId: number) => {
    if (Number.isInteger(serviceId)) {
      onStateChange(state.map((o, i) => (i === idx ? { ...o, serviceId } : o)));
    }
  };
  const onPersonChanged = (idx: number, newPersionId: number) => {
    if (Number.isInteger(newPersionId)) {
      onStateChange(
        state.map((o, i) => (i === idx ? { ...o, personId: newPersionId } : o))
      );
    }
  };

  const canAdd =
    !!options.length &&
    personOptions.some((p) => !value[p.personId]) &&
    state.length < personOptions.length;

  return (
    <div className={classes.container}>
      {!!state.length && (
        <Typography variant="overline">
          {t("selectAccompanyingInputLabel")}
        </Typography>
      )}
      {state.map(({ serviceId, personId }, index) => {
        return (
          <FormGroup key={index} className={classes.entryContainer} row>
            <FormControl className={classes.selectService}>
              <InputLabel>{t("selectServiceInputLabel")}</InputLabel>
              <Select
                data-cy="afterregistration-accompanying_person-select_service"
                value={serviceId}
                onChange={(e) =>
                  onServiceChanged(index, e.target.value as number)
                }
                disabled={disabled}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.serviceId}
                    value={option.serviceId}
                    disabled={!!value[option.serviceId]}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.inputService}>
              <InputLabel>{t("selectPersonLabel")}</InputLabel>
              <Select
                data-cy="afterregistration-accompanying_person-select_person"
                value={personId ?? ""}
                onChange={(e) =>
                  onPersonChanged(index, e.target.value as number)
                }
                disabled={disabled}
              >
                {personOptions.map((option) => (
                  <MenuItem
                    key={option.personId}
                    value={option.personId}
                    disabled={!!value[option.personId]}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton disabled={disabled} onClick={() => onRemove(index)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </FormGroup>
        );
      })}
      <div className={classes.addContainer}>
        <Button
          color="primary"
          data-cy="timesheet-row-add-accompanying"
          variant="contained"
          size="small"
          onClick={onAdd}
          disabled={disabled || !canAdd}
        >
          <AddIcon fontSize="small" /> {t("addAccompanyingPersonButtonLabel")}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper: {
      padding: theme.spacing(2.5),
      marginTop: theme.spacing(2.5)
    },
    form: {
      width: "100%"
    },
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5)
    },
    btnSpacing: { marginLeft: theme.spacing(2) },
    errMessage: {
      color: "#f44336",
      fontSize: "0.75rem",
      marginTop: theme.spacing(0.5)
    },
    tableContainer: {
      padding: 0,
      marginTop: 20
    },
    bold: {
      fontWeight: "bold"
    },
    title: {
      marginBottom: 20
    },
    title2: {
      margin: 20,
      fontSize: 16
    }
  })
);

interface AfterregistrationFormProps {
  formikProps: FormikProps<TimeSheetForm>;
  services: Service[];
  drivers: PersonForm[];
  contractorName: string;
  isLoadingTimesheetOptions: boolean;
}

const AfterregistrationForm: React.FC<AfterregistrationFormProps> = ({
  formikProps,
  services,
  drivers,
  contractorName,
  isLoadingTimesheetOptions
}) => {
  const classes = useStyles();
  const t = useTranslate("AfterregistrationPage");

  const accompanyingPersonOptions = services
    .filter((s) => s.is_accompanying_person_allowed)
    .map((s) => ({ serviceId: s.id, name: s.name }));
  const personOptions = drivers
    .filter((d) => d.id !== formikProps.values.person)
    .map((d) => ({ personId: d.id, name: `${d.first_name} ${d.last_name}` }));
  const driverOptions = drivers.map((driver) => ({
    label: `${driver.first_name} ${driver.last_name}`,
    value: driver.id
  }));

  const serviceOptions = services.map((service) => ({
    label: service.name,
    value: service.id
  }));

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.title}>
        {`${t("afterregistrationForTitle")} ${contractorName}`}
      </Typography>
      <BlurTextField
        name="title"
        label={t("titleLabel")}
        placeholder={t("titleLabel")}
        margin="normal"
        fullWidth
      />
      <Field
        isLoading={isLoadingTimesheetOptions}
        data-cy="afterregistration-current_service"
        type="text"
        name="current_service"
        label={t("serviceLabel")}
        placeholder={t("serviceLabel")}
        value={
          serviceOptions.find(
            (service) => formikProps.values.current_service === service.value
          )
            ? serviceOptions.find(
                (service) =>
                  formikProps.values.current_service === service.value
              )
            : null
        }
        options={serviceOptions}
        component={AutoCompleteSelect}
        margin="normal"
        customHandleChange={(value: number) => {
          const service = services.find((_service) => _service.id === value);
          if (service && service.base) {
            formikProps.setFieldValue("base_service", service.base);
          }
        }}
      />
      <Field
        isLoading={isLoadingTimesheetOptions}
        data-cy="afterregistration-driver"
        type="text"
        name="person"
        label={t("driverLabel")}
        placeholder={t("driverLabel")}
        value={
          driverOptions.find(
            (driver) => formikProps.values.person === driver.value
          )
            ? driverOptions.find(
                (driver) => formikProps.values.person === driver.value
              )
            : null
        }
        options={driverOptions}
        component={AutoCompleteSelect}
        margin="normal"
      />
      <AccompanyingPeopleEditor
        name="accompanying_people"
        options={accompanyingPersonOptions}
        personOptions={personOptions}
        disabled={formikProps.values.person === null}
      />
      <Field
        name="start_date"
        label={t("startDateLabel")}
        placeholder={t("startDateLabel")}
        component={DatePicker}
        format="yyyy-MM-dd"
        margin="normal"
        fullWidth
      />
    </Paper>
  );
};

export default AfterregistrationForm;
