import { SnackbarTypes, IRowImage } from "../redux/types";
import store from "../redux/store";
import { showSnackbar } from "../redux/reducers/snackbar/actions";
import { getImageByURL } from "../services/imageHelperService";

export const ENDPOINT_DATE_FORMAT = "yyyy-MM-dd";

export const ENDPOINT_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const showGlobalSnackbar = (message: string, variant: SnackbarTypes) => {
  store.dispatch(showSnackbar(message, variant));
};

export function decomposeUrlHash() {
  const pathMatch = window.location.hash.match(/^([^?]+)(.*)$/);
  const [, hashPath, queryStr] = pathMatch as RegExpMatchArray;
  return {
    hashPath: hashPath.replace("#/", "/"),
    urlSearchParams: new URLSearchParams(queryStr)
  };
}

// Removes spaces from price and replaces commas with dots
export const sanitizePrice = (price: string) =>
  price.replace(/(?!\d) +(?=\d)/g, "").replace(/,/g, ".");

export const fetchBlobImg = async (img: IRowImage) => {
  try {
    img.imageStr = await getImageByURL(img.url);
  } catch (e) {
    console.warn(e);
    throw e;
  }
  return img;
};

export const applySequence = <T>(
  sequence: string[],
  entries: Array<[string, T]>
) => {
  const mapping = new Map(entries);
  return sequence.reduce((acc, m) => {
    const img = mapping.get(m);
    if (img) {
      acc.push(img);
    }
    return acc;
  }, [] as T[]);
};

export const slugify = (name: string): string =>
  name
    ?.trim()
    .replace(/ +/g, "-")
    .replace(/[^a-z0-9-]/gi, "");

export const parseTimeAsFloat = (time: string | null): number => {
  if (time) {
    const [hh, mm] = time.split(":")
    return parseFloat(hh) + parseFloat(mm) / 60
  } else {
    return NaN
  }
}
