import { makeStyles, Paper } from "@material-ui/core";
import { useTranslate } from "../services/appLanguageService";
const useStyles = makeStyles((theme: any) => ({
  paperContainer: {
    padding: theme.spacing(3),
    fontSize: theme.spacing(2)
  }
}));
export default function NoItemsText() {
  const classes = useStyles();
  const t = useTranslate("NoItemsText");
  return (
    <Paper className={classes.paperContainer} data-cy="no-items">
      {t("noItemsText")}
    </Paper>
  );
}
