import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { mainRootState, Contract } from "../../../redux/types";
import * as RestActions from "../../../redux/sagas/restActions";
import * as LoadingBooleansActions from "../../../redux/reducers/loadingBooleans/actions";
import { RestStrings, ReduxActionStrings } from "../../../redux/strings";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import TaskContractorCooperations from "../../../components/contracts/tasks/TaskContractorCooperations";

interface TaskContractorCooperationsContainerProps {
  contractor_cooperations: number[];
  contractorContracts: Contract[];
  loadingTaskContractorCooperations: boolean;
  resetLoadingBooleans: () => void;
  loadContractorContracts: (cooperationsIds: string) => void;
}

const TaskContractorCooperationsContainer: React.FC<
  TaskContractorCooperationsContainerProps
> = (props) => {
  const {
    loadContractorContracts,
    contractor_cooperations,
    contractorContracts,
    resetLoadingBooleans
  } = props;
  const cooperationsIds = contractor_cooperations.toString();
  useEffect(
    () => loadContractorContracts(cooperationsIds),
    [loadContractorContracts, cooperationsIds]
  );
  useEffect(() => () => resetLoadingBooleans(), [resetLoadingBooleans]);

  const contractorContractIds = contractorContracts.map(
    (contractorContract) => contractorContract.id
  );

  return props.loadingTaskContractorCooperations ? (
    <LoadingSpinnerPaper />
  ) : (
    <TaskContractorCooperations
      contractor_cooperations={contractor_cooperations.filter(
        (contractor_cooperation) =>
          contractorContractIds.includes(contractor_cooperation)
      )}
      contractorContracts={contractorContracts}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  loadContractorContracts: (cooperationsIds: string) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.TASK_CONTRACTOR_COOPERATIONS,
        `contracts/contracts/?filter__valid=true&filter__id__in=[${cooperationsIds}]`
      )
    );
  },
  resetLoadingBooleans: () =>
    dispatch(
      LoadingBooleansActions.ResetLoadingContractorContracts(ownProps.tabId)
    )
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  contractorContracts:
    state.tabStates[ownProps.tabId].tasks.task.taskContractorCooperations,
  loadingTaskContractorCooperations:
    state.tabStates[ownProps.tabId].loadingBooleans
      .loadingTaskContractorCooperations
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskContractorCooperationsContainer);
