import { getBackendVersionAPI } from "./api-declaration";
import { VersionResponse } from "../redux/types";


export const getFrontendVersion = () => {
    return process.env.REACT_APP_VERSION
}


export const getBackendVersion = async () => {
    try {
        const response: VersionResponse = await getBackendVersionAPI();
        return response
    } catch (error: any) {
        console.error(error.response_data)
    }
};


export const displayAppVersionInConsole = async () => {
    const frontendVersion = getFrontendVersion()
    const response = await getBackendVersion()
    const backendVersion = response?.version

    const backendVersionText = backendVersion == null ? `font-size: ${config.fontSize}; color: ${config.errorColor};` : `font-size: ${config.fontSize}; color: ${config.successColor};`;
    const frontendVersionText = frontendVersion == null ? `font-size: ${config.fontSize}; color: ${config.errorColor};` : `font-size: ${config.fontSize}; color: ${config.successColor};`;
    const normalText = `font-size: ${config.fontSize}; color: ${config.primaryColor};`;

    /* eslint-disable-next-line */
    console.log(`%c\r\n\r\n __      ___                               \r\n \\ \\    \/ (_)                              \r\n  \\ \\  \/ \/ _ _ __  _ __   __ _             \r\n   \\ \\\/ \/ | | \'_ \\| \'_ \\ \/ _\` |            \r\n    \\  \/  | | | | | | | | (_| |  _   _   _ \r\n     \\\/   |_|_| |_|_| |_|\\__,_| (_) (_) (_)\r\n                                           \r\n                                           \r\n\r\n`, `color: ${config.primaryColor}; font-size: ${config.vinnaLogoFontSize};`);

    console.group(
        "%cApplication Version",
        `background-color: ${config.primaryColor}; color: ${config.secondaryColor}; font-weight: ${config.headerFontWeight}; padding: ${config.headerPadding}; font-size:${config.headerFontSize}; font-family: ${config.fontFamily};`
    );
    console.log(`%cFrontend version: %c${frontendVersion == null ? "Could not find frontend version!": frontendVersion}`, normalText, frontendVersionText)
    console.log(`%cBackend version: %c${backendVersion == null ? "Could not find backend version!" : backendVersion}`, normalText, backendVersionText)
    console.groupEnd();
}

const config = {
    "primaryColor": "#277BC0",
    "secondaryColor": "#dea01e",
    "errorColor": "#FF7BA9",
    "successColor": "#65C18C",
    "fontFamily": "monospace",
    "headerFontSize": "36px",
    "headerPadding": "6px",
    "headerFontWeight": "600",
    "vinnaLogoFontSize": "24px",
    "fontSize": "20px",
}



