import { Action, ConnectivityState } from "../../types";
import { CONNECTION_ONLINE, CONNECTION_OFFLINE } from "./actionTypes";

const initialState: ConnectivityState = {
  isOnline: true
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case CONNECTION_ONLINE:
      return {
        ...state,
        isOnline: true
      };
    case CONNECTION_OFFLINE:
      return {
        ...state,
        isOnline: false
      };
    default:
      return state;
  }
}

export default reducer;
