import React, { useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, Tabs, Tab, Paper, Box } from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import * as Router from "react-router-dom";
import { TabComponentProps } from "../../redux/types";
import Baseservices from "./services/Baseservices";
import Basematerials from "./materials/Basematerials";
import Baseaddons from "./addons/Baseaddons";
import Baseservicepackages from "./servicepackages/Baseservicepackages";
import Basesupplements from "./supplements/Basesupplements";
import Baseaccessories from "./accessories/Baseaccessories";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "inline-block",
      marginBottom: theme.spacing(2.5),
      width: "100%",
      "& div.MuiTabs-scroller": {
        [theme.breakpoints.down("sm")]: {
          overflowX: "scroll !important"
        }
      }
    },
    paper: {
      padding: 20,
      marginTop: 20
    }
  });

interface BaseItemsProps extends WithStyles<typeof styles>, TabComponentProps {}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const BaseItems: React.FC<BaseItemsProps> = (props) => {
  const { classes, subTabIndex } = props;
  const t = useTranslate("BaseItemsPage");

  const [selectedTab, setSelectedTab] = useState(subTabIndex || 0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const linkDefinitions = [
    {
      label: t("baseservicesHeadingText"),
      route: "/contracts/baseservices"
    },
    {
      label: t("basematerialsHeadingText"),
      route: "/contracts/basematerials"
    },
    {
      label: t("basservicepackagesHeadingText"),
      route: "/contracts/baseservicepackages"
    },
    {
      label: t("baseaccessoriesHeadingText"),
      route: "/contracts/baseaccessories"
    },
    {
      label: t("baseaddonsHeadingText"),
      route: "/contracts/baseaddons"
    },
    {
      label: t("basesupplementsHeadingText"),
      route: "/contracts/basesupplements"
    }
  ];

  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          {linkDefinitions.map((linkDefinition, index) => (
            <Tab
              key={index}
              label={linkDefinition.label}
              component={React.forwardRef<
                HTMLAnchorElement,
                Partial<Router.LinkProps>
              >((props, ref) => (
                <Router.Link
                  to={`${linkDefinition.route}?same_tab=true`}
                  {...props}
                  ref={ref as any}
                />
              ))}
            />
          ))}
        </Tabs>
      </Paper>
      <TabPanel value={selectedTab} index={0}>
        <Baseservices />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Basematerials />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <Baseservicepackages />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <Baseaccessories />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <Baseaddons />
      </TabPanel>
      <TabPanel value={selectedTab} index={5}>
        <Basesupplements />
      </TabPanel>
    </>
  );
};

export default withStyles(styles)(BaseItems);
