import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  Theme,
  Paper,
  Typography,
  TextField,
  IconButton,
  Link,
  Button
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslate } from "../services/appLanguageService";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  getCustomerobjectByIdsAPI,
  getSearchCustomerobjectOptionsAPI,
  getTimesheetsAPI,
  getContractorNamesAPI
} from "../services/api-declaration";
import { format, startOfWeek, add, differenceInCalendarDays, isValid, compareAsc, isEqual as isDateEqual } from "date-fns";
import { sv } from "date-fns/locale";
import LoadingSpinner from "./LoadingSpinner";
import { SubmitStatus, TabComponentProps } from "../redux/types";
import { isEqual } from "lodash";

// STYLES //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: 20,
      minHeight: "calc(100vh - 150px)",
    },
    pageHeader: { paddingBottom: theme.spacing(3), display: "flex" },
    rowHeader: {
      display: "flex"
    },
    leftColumn: {
      width: "18rem",
      display: "flex",
      whiteSpace: "nowrap",
      overflow: "hidden",
      flexShrink: 0
    },
    rightColumn: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      flex: "1"
    },
    searchBox: {
      width: "100%",
      margin: theme.spacing(1)
    },
    weekContainer: {
      display: "flex",
      maxWidth: "100%"
    },
    weekSelectContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *": {
        marginRight: theme.spacing(2),
      }
    },
    weekField: {
      width: "20rem",
      fontSize: "1.2rem",
      fontWeight: "bold",
      textAlign: "center"
    },
    MuiOutlinedInputInput: {
      padding: "18.5px 14px",
      width: "20rem",
      textAlign: "center"
    },
    arrowButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },

    week1: {
      display: "flex",
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: "solid 1px"
    },
    week2: {
      display: "flex",
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    dayBoxInv: {
      width: "2.5rem",
      height: "2.5rem",
      margin: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    smallText: { fontSize: "0.8rem", marginTop: "1.5rem" },
    dayName: { fontSize: "1.2rem", marginTop: "-1rem" },
    customerObjectRow: {
      display: "flex"
    },
    customerBox: {
      width: "100%",
      padding: theme.spacing(1.5),
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      height: "3.5rem"
    },
    customerBoxTitle: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginRight: "-20px"
    },
    searchIcon: { position: "relative", top: "-0.2rem" },
    searchText: { position: "relative", top: "-1.7rem", left: "1.5rem" },
    deleteButton: {
      marginLeft: "auto",
      position: "relative",
      right: "-0.7rem"
    },
    taskCount: {
      fontSize: "1.2rem",
      fontWeight: "bold"
    },
    dayBox: {
      border: "1px solid",
      borderRadius: "5px",
      width: "2.5rem",
      height: "2.5rem",
      margin: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "&.has-rows": {
        cursor: "pointer"
      }
    },
    selectedDay: {
      border: "4px solid",
      backgroundColor: "#eee",
      position: "relative",

      "&::after": {
        content: "''",
        position: "absolute",
        bottom: "-10px",
        left: "50%",
        borderStyle: "solid",
        borderWidth: "0 8px 8px 8px",
        borderColor: "transparent transparent #000 transparent",
        transform: "translateX(-50%) rotate(180deg)",
        display: "block"
      }
    },
    WorkLogAccordionFullContainer: {
      display: "flex"
    },
    WorkLogAccordionBox: {
      display: "block",
      flexDirection: "row",
      width: "100%",
      border: "1px solid ",
      backgroundColor: "#eee",
      borderRadius: "8px",
      overflow: "hidden",
      marginBottom: theme.spacing(1)
    },
    WorkLogAccordionHeader: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(0)
    },
    WorkLogAccordionHeader2: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    WorkLogAccordionHeader3: {
      display: "flex",
      justifyContent: "space-between",
      color: "#808080",
      paddingBottom: theme.spacing(2)
    },
    objectName: {
      fontSize: "1.2rem",
      fontWeight: "bold"
    },
    closeButton: {
      cursor: "pointer",
      marginTop: theme.spacing(-2),
      marginRight: theme.spacing(-2)
    },
    WorkLogAccordionContent: {
      padding: theme.spacing(3),
      overflowY: "auto"
    },
    fieldTitle: { color: "#808080" },
    WorkLogAccordionContentRowGrid: {},
    WorkLogAccordionHeader3Grid: {
      display: "grid",
      gridTemplateColumns: "0.5fr 2fr 1.25fr 1.25fr 0.5fr 0.25fr 0.5fr",
      color: "#808080",
      paddingBottom: theme.spacing(1),
      gap: "1rem",
      alignItems: "center"
    },
    workLogItemGrid: {
      display: "grid",
      gridTemplateColumns: "0.5fr 2fr 1.25fr 1.25fr 0.5fr 0.25fr 0.5fr",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      gap: "1rem",
      borderTop: "1px solid #808080",
      alignItems: "center"
    },
    rightAlignedCell: {
      justifySelf: "end"
    },
    openInNewIcon: {
      color: "#808080",
      fontSize: "1.3rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    loadingSpinner: {
      position: "absolute",
      top: "-1rem",
      left: "-40rem",
      right: 0,
      bottom: 0,
      zIndex: 1
    }
  })
);

// INTERFACES //

interface WorkLogRowObject {
  customerObjectId: number;
  date: Date;
  task: number;
  title: string;
  person_name: string | null;
  contractor: number;
  contractor_name: string;
  status: "DISPATCHED" | "ACCEPTED" | "SUBMITTED" | "CANCELLED" | "VERIFIED" | "INVOICED" | "UNKNOWN";
  route?: string;
}

// MAIN COMPONENT //

type WorkLogRowMap = Map<string, WorkLogRowObject[]>;

type WorkLogProps = TabComponentProps<never>

const WorkLog: React.FC<WorkLogProps> = ({ urlQueryParams }) => {
  const classes = useStyles();
  const history = useHistory();

  // STATES
  const [contractorNames, setContractorNames] = useState<Map<number, string>>();
  const [customerObjectNames, setCustomerObjectNames] = useState<Map<number, string>>()
  const [workLogRows, setWorkLogRows] = useState<WorkLogRowObject[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const startDate = useMemo(() => {
    const startDateParsed = urlQueryParams.date ? new Date(urlQueryParams.date) : undefined
    return startOfWeek(startDateParsed && isValid(startDateParsed) ? startDateParsed : new Date(), { weekStartsOn: 1 })
  }, [urlQueryParams.date])

  const selectedObjectIds = useMemo(() => {
    const customerObjectIdsParsed = urlQueryParams.co_ids?.split(",")
      ?.map(v => parseInt(v))
      .filter(v => isFinite(v) && v > 0)
    return [...new Set(customerObjectIdsParsed)]
  }, [urlQueryParams.co_ids])

  const selected = useMemo(() => {
    const selectedParsed = urlQueryParams.selected?.split(",")
      ?.map(v => parseInt(v))
      .filter(v => isFinite(v))
    const objectId = selectedParsed?.[0]
    const dateIndex = selectedParsed?.[1]
    return objectId !== undefined && dateIndex !== undefined ? [objectId, dateIndex] as const : null
  }, [urlQueryParams.selected])

  const onAddObjectid = (customerObjectId: number) => {
    const nextIds = [...selectedObjectIds, customerObjectId]
    history.replace({ search: `?${getSearchParams(startDate, nextIds, selected)}` })
  }

  const onChangeSelected = (newSelected: readonly [number, number] | null) => {
    if (!isEqual(selected, newSelected)) {
      history.replace({ search: `?${getSearchParams(startDate, selectedObjectIds, newSelected)}` })
    }
  }

  const onChangeStartDate = (newDate: Date) => {
    const weekStart = startOfWeek(newDate, { weekStartsOn: 1 })
    if (!isDateEqual(startDate, weekStart)) {
      const diff = differenceInCalendarDays(startDate, weekStart)
      const newSelected = selected ? [selected[0], selected[1] + diff] as const : null
      history.replace({ search: `?${getSearchParams(weekStart, selectedObjectIds, newSelected)}` })
    }
  }

  const onRemoveObjectId = (customerObjectId: number) => {
    const nextIds = selectedObjectIds.filter(id => id !== customerObjectId)
    history.replace({
      search: `?${new URLSearchParams({ date: format(startDate, "yyyy-MM-dd"), co_ids: nextIds.join(",") })}`
    })
  };

  const workLogRowMap: WorkLogRowMap = new Map()
  workLogRows.forEach(wr => {
    const key = `${wr.customerObjectId}_${differenceInCalendarDays(wr.date, startDate)}`
    const rows = workLogRowMap.get(key) ?? []
    rows.push(wr)
    workLogRowMap.set(key, rows)
  })

  useEffect(() => {
    let alive = true;
    void Promise.all([getContractorNamesAPI(), getSearchCustomerobjectOptionsAPI()]).then(
      ([{ results: contractorNames }, { results: customerObjects }]) => {
        if (alive) {
          setContractorNames(new Map(contractorNames.map((c) => [c.id, c.name])))
          setCustomerObjectNames(new Map(customerObjects.map(co => [co.id, co.name])))
        }
    });
    return () => {
      alive = false;
    };
  }, []);

  useEffect(() => {
    if (contractorNames && selectedObjectIds.length) {
      let alive = true;

      setIsLoading(true);

      void getCustomerobjectByIdsAPI(selectedObjectIds)
        .then(async ({ results: customerobjects }) => {
          const idMap = new Map(
            customerobjects.flatMap((cu) =>
              cu.tasks.map((taskId) => [taskId, cu.id])
            )
          );
          const taskIds = Array.from(idMap.keys());
          const { results: timesheets = [] } = taskIds.length
            ? await getTimesheetsAPI({
                /* TODO: Filter dates */
                filter__rows__task__in: taskIds,
                filter__time__gt: startDate.toISOString(),
                filter__time__lt: add(startDate, { days: 14 }).toISOString(),
              })
            : {};

          return [idMap, timesheets] as const;
        })
        .then(([idMap, timesheets]) => {
          const workLogRows = timesheets.flatMap(ts => {
            const deadlineDate = ts.deadline_time
              ? new Date(ts.deadline_time)
              : null;
            return ts.rows
              .map(r => {
                const customerObjectId = idMap.get(r.task ?? 0);
                const date = r.start_time ? new Date(r.start_time) : deadlineDate;
                const status = getWorklogStatus(ts.accepted, ts.submitted, r.submit_status)
                return customerObjectId && date ? {
                  customerObjectId,
                  date,
                  task: r.task!,
                  title: ts.title,
                  person_name: ts.person_name,
                  contractor: ts.contractor,
                  contractor_name: contractorNames.get(ts.contractor) ?? "",
                  route: ts.route,
                  status,
                } : undefined;
              })
          }).filter(<T,>(r: T): r is Exclude<T, undefined> => r !== undefined);
          workLogRows.sort((a, b) => compareAsc(a.date, b.date))
          if (alive) {
            setWorkLogRows(workLogRows)
            setIsLoading(false);
          }
        });

      return () => {
        alive = false;
      };
    }
  }, [contractorNames, selectedObjectIds, startDate]);

  return (
    <Paper className={classes.paper}>
      <PageHeader isLoading={isLoading}>
        <WeekSelector
          startDate={startDate}
          onChangeStartDate={onChangeStartDate}
        />
      </PageHeader>
      <div className={classes.rowHeader}>
        <Search
          options={customerObjectNames && Array.from(customerObjectNames.entries(), ([id, name]) => ({ id, name })).filter(co => !selectedObjectIds.includes(co.id))}
          onSelect={id => onAddObjectid(id)}
        />
        <DatesHeader startDate={startDate} />
      </div>
      {selectedObjectIds.map((coId) => (
        <React.Fragment key={coId}>
          <CustomerObjectRow
            customerObjectId={coId}
            customerObjectName={customerObjectNames?.get(coId) ?? "Unknown"}
            workLogRowMap={workLogRowMap}
            selected={selected}
            onSelect={onChangeSelected}
            onDelete={() => onRemoveObjectId(coId)}
          />
          {selected?.[0] === coId && (
            <WorkLogAccordion
              customerObjectName={customerObjectNames?.get(coId) ?? "Unknown"}
              selectedWorkLogObjects={workLogRowMap.get(`${coId}_${selected[1]}`) || []}
              onClose={() => onChangeSelected(null)}
            />
          )}
        </React.Fragment>
      ))}
    </Paper>
  );
};

const getSearchParams = (date: Date, objectIds: number[], selected: readonly [number, number] | null ) =>
  new URLSearchParams({ date: format(date, "yyyy-MM-dd"), co_ids: objectIds.join(","), selected: selected?.join(",") ?? "" })

const getWorklogStatus = (tsAccepted: boolean, tsSubmitted: boolean, status: SubmitStatus): WorkLogRowObject["status"] => {
  if (status === "DRAFT") {
    if (tsSubmitted) {
      return "CANCELLED"
    } else if (tsAccepted) {
      return "ACCEPTED"
    } else {
      return "DISPATCHED"
    }
  } else if (status === "APPROVED") {
    return "VERIFIED"
  } else if (status === "INVOICED") {
    return "INVOICED"
  } else if (status === "SUBMITTED") {
    return "SUBMITTED"
  }
  return "UNKNOWN"
}


// PAGE HEADER //
interface PageHeaderProps {
  isLoading: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({ isLoading, children }) => {
  const classes = useStyles();
  const t = useTranslate("WorkLog");

  return (
    <div className={classes.pageHeader}>
      <div className={classes.leftColumn}>
        <Typography variant="h6">{t("workLogHeaderLabel")}</Typography>
      </div>
      <div className={classes.rightColumn}>
        {" "}
        {isLoading && (
          <div className={classes.loadingSpinner}>
            <LoadingSpinner />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

// WEEK SELECTOR //
interface WeekSelectorProps {
  startDate: Date;
  onChangeStartDate: (date: Date) => void;
}

const WeekSelector: React.FC<WeekSelectorProps> = ({
  startDate,
  onChangeStartDate
}) => {
  const classes = useStyles();
  const t = useTranslate("WorkLog");

  const weekEnd = add(startDate, { days: 13 });

  return (
    <div className={classes.weekSelectContainer}>
      <IconButton onClick={() => onChangeStartDate(add(startDate, { weeks: -1 }))}>
        <ArrowBackIcon />
      </IconButton>
      {/*"TODO: Use typography"*/}
      <TextField
        fullWidth
        label={t("selectWeeksLabel")}
        variant="outlined"
        className={classes.weekField}
        value={`${format(startDate, "dd/MM")} - ${format(weekEnd, "dd/MM")}`}
        InputProps={{
          readOnly: true,
          inputProps: {
            style: { textAlign: "center", fontSize: "1.2rem" }
          }
        }}
      />
      <Button variant="outlined" size="large" onClick={() => onChangeStartDate(new Date())}>
        {t("todayLabel")}
      </Button>
      <IconButton onClick={() => onChangeStartDate(add(startDate, { weeks: 1 }))}>
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};

// SEARCH //
interface SearchProps {
  options: { id: number, name: string }[] | undefined,
  onSelect: (customerObjectId: number) => void;
}

const Search: React.FC<SearchProps> = ({ options, onSelect }) => {
  const classes = useStyles();
  const t = useTranslate("WorkLog");

  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(`.${classes.searchBox}`)) {
        setInputValue("");
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.leftColumn}>
      <Autocomplete
        className={classes.searchBox}
        options={options ?? []}
        loading={!options}
        value={null}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        inputValue={inputValue}
        onInputChange={(_event, value) => setInputValue(value)}
        onChange={(_event, newValue) => {
          if (newValue?.id) {
            onSelect(newValue.id);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <React.Fragment>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <div className={classes.searchText}>
                  &nbsp;{t("searchTextLabel")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </React.Fragment>
            }
            variant="outlined"
            fullWidth
          />
        )}
      />
    </div>
  );
};

// DATES HEADER //

const DatesHeader: React.FC<{ startDate: Date }> = ({ startDate }) => {
  const t = useTranslate("WorkLog");
  const classes = useStyles();

  const dayLabels = [
    t("Mon"),
    t("Tue"),
    t("Wed"),
    t("Thu"),
    t("Fri"),
    t("Sat"),
    t("Sun")
  ];
  const datesBetweenWeeks = Array.from({ length: 14 }, (_, i) =>
    format(add(startDate, { days: i }), "dd/MM")
  );

  return (
    <div className={classes.rightColumn}>
      <div className={classes.weekContainer}>
        <div className={classes.week1}>
          {dayLabels.map((day, index) => (
            <div key={`week1-${day}`} className={classes.dayBoxInv}>
              <p className={classes.smallText}>
                {datesBetweenWeeks[index]?.split("/")[0]}/
                {datesBetweenWeeks[index]?.split("/")[1]}
              </p>
              <p className={classes.dayName}>{day}</p>
            </div>
          ))}
        </div>

        <div className={classes.week2}>
          {dayLabels.map((day, index) => (
            <div key={`week2-${day}`} className={classes.dayBoxInv}>
              <p className={classes.smallText}>
                {datesBetweenWeeks[index + 7]?.split("/")[0]}/
                {datesBetweenWeeks[index + 7]?.split("/")[1]}
              </p>
              <p className={classes.dayName}>{day}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// CUSTOMER OBJECT ROW //
interface CustomerObjectRowProps {
  customerObjectName: string;
  customerObjectId: number;
  workLogRowMap: WorkLogRowMap;
  selected: readonly [number, number] | null;
  onSelect: (key: readonly [number, number] | null) => void;
  onDelete: () => void;
}

const CustomerObjectRow: React.FC<CustomerObjectRowProps> = ({
  customerObjectName,
  customerObjectId,
  workLogRowMap,
  selected,
  onSelect,
  onDelete
}) => {
  const classes = useStyles();

  const DateBox: React.FC<{ dayIndex: number }> = ({ dayIndex }) => {
    const key = `${customerObjectId}_${dayIndex}`;
    const isSelected =
      selected && selected[0] === customerObjectId && selected[1] === dayIndex;
    const workLogRows = workLogRowMap.get(key) ?? [];

    return (
      <div
        key={dayIndex}
        className={`${classes.dayBox} ${workLogRows.length ? "has-rows" : ""} ${isSelected ? classes.selectedDay : ""}`}
        onClick={workLogRows.length ? () => onSelect(!isSelected ? [customerObjectId, dayIndex] : null) : undefined}
      >
        {!!workLogRows.length && (
          <p className={classes.taskCount}>{workLogRows.length}</p>
        )}
      </div>
    );
  };

  return (
    <div className={classes.customerObjectRow}>
      <div className={classes.leftColumn}>
        <div className={classes.customerBox}>
          <Typography variant="body2" className={classes.customerBoxTitle} title={customerObjectName}>
            {customerObjectName}
          </Typography>
          <IconButton onClick={onDelete} className={classes.deleteButton}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.rightColumn}>
        <div className={classes.weekContainer}>
          {/* Week 1 */}
          <div className={classes.week1}>
            {Array.from({ length: 7 }).map((_, i) => (
              <DateBox key={i} dayIndex={i} />
            ))}
          </div>

          {/* Week 2 */}
          <div className={classes.week2}>
            {Array.from({ length: 7 }).map((_, i) => (
              <DateBox key={i} dayIndex={i + 7} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// WorkLogAccordion //
interface WorkLogAccordionProps {
  customerObjectName: string;
  selectedWorkLogObjects: WorkLogRowObject[];
  onClose: () => void;
}

const WorkLogAccordion: React.FC<WorkLogAccordionProps> = ({
  customerObjectName,
  selectedWorkLogObjects,
  onClose,
}) => {
  const classes = useStyles();
  const locale = useTranslate("locale")();
  const t = useTranslate("WorkLog");

  if (!selectedWorkLogObjects.length) {
    return null;
  }

  return (
    <div className={classes.WorkLogAccordionFullContainer}>
        <div className={classes.WorkLogAccordionBox}>
          <div className={classes.WorkLogAccordionHeader}>
            <span className={classes.fieldTitle}>
              {t("customerObjectLabel")}
            </span>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.WorkLogAccordionHeader2}>
            <span className={classes.objectName}>
              {customerObjectName}
            </span>

            <span className={classes.objectName}>
              {format(selectedWorkLogObjects[0].date, "EEEE", {
                locale: locale === "sv-SE" ? sv : undefined
              })}{" "}
              {format(selectedWorkLogObjects[0].date, "dd/MM")}
            </span>
          </div>

          <div className={classes.WorkLogAccordionContent}>
            <div className={classes.WorkLogAccordionHeader3Grid}>
              <div> {t("timeLabel")}</div>
              <div> {t("assignmentLabel")}</div>
              <div> {t("contractorLabel")}</div>
              <div> {t("workerLabel")}</div>
              <div> {t("statusLabel")}</div>
              <div className={classes.rightAlignedCell}> {t("linkLabel")}</div>
            </div>
            {selectedWorkLogObjects.map((log, index) => (
              <div key={index} className={classes.workLogItemGrid}>
                <div>{format(log.date, "HH:mm")}</div>
                <div>{log.title}</div>
                <div>{log.contractor_name}</div>
                <div>{log.person_name}</div>
                <div>{t(`${log.status}Status`)}</div>
                <div className={classes.rightAlignedCell}>
                  <Link
                    component={RouterLink}
                    to={`/contracts/tasks/${log.task}`}
                  >
                    <OpenInNewIcon className={classes.openInNewIcon} />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
    </div>
  );
};

export default WorkLog;
