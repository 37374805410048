import { HolidaysState, Action, Holiday } from "../../types";
import { createSuccessActionType } from "../actionTypes";
import { ReduxActionStrings, RestStrings } from "../../strings";

const initialState: HolidaysState = {
  holidays: {
    holidays: [],
    next: null,
    previous: null,
    count: 0
  },
  holiday: {
    holiday: {
      id: -1,
      created_at: "",
      updated_at: "",
      name: "",
      date: ""
    }
  }
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.HOLIDAYS):
      return {
        ...state,
        holidays: {
          holidays: action.payload.results,
          next: action.payload.next,
          previous: action.payload.previous,
          count: action.payload.count
        }
      };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.HOLIDAY):
      return {
        ...state,
        holiday: {
          holiday: action.payload
        }
      };
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.HOLIDAY):
      return {
        ...state,
        holidays: {
          ...state.holidays,
          holidays: [...state.holidays.holidays, action.payload]
        }
      };
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.HOLIDAY):
      return {
        ...state,
        holidays: {
          ...state.holidays,
          holidays: [
            ...state.holidays.holidays.map((holiday: Holiday) =>
              holiday.id === action.payload.id ? action.payload : holiday
            )
          ]
        }
      };
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.HOLIDAY
    ):
      return {
        ...state,
        holidays: {
          ...state.holidays,
          holidays: [
            ...state.holidays.holidays.filter(
              (holiday: Holiday) => holiday.id !== parseInt(action.payload.id)
            )
          ]
        }
      };

    default:
      return state;
  }
}

export default reducer;
