import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { useTranslate } from "../services/appLanguageService";
import clsx from "clsx";

interface NavbarLinkProps {
  label: string;
  route: string;
  icon: React.ComponentType<any>;
  id: string;
  approvalsCount?: number;
  partlyDeclinedCount?: number;
  className?: string;
  closeMenu?: () => void;
  isSelected?: boolean;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {
      textDecoration: "none",
      color: "inherit"
    },
    countBubble: {
      color: "#fff",
      display: "flex",
      fontWeight: "bold",
      alignItems: "center",
      borderRadius: "50px",
      justifyContent: "center",
      width: theme.spacing(3.5),
      height: theme.spacing(3.5)
    },
    verificationsCounter: { backgroundColor: "#f50057" },
    partlyDeclinedCounter: { backgroundColor: "#f5d800" },
    boldText: { fontWeight: "bold" },
    active: { background: "#e3e3e3" }
  })
);

const NavbarLink: React.FC<NavbarLinkProps> = (props) => {
  const classes = useStyles();
  const t = useTranslate("RouteLabels");
  const {
    icon,
    label,
    approvalsCount,
    partlyDeclinedCount,
    route,
    closeMenu,
    id,
    className,
    isSelected
  } = props;
  let Icon = icon;
  const renderCountBubble = () => {
    if (label === t("WorkVerificationNavbarLabel") && approvalsCount) {
      return (
        <div
          className={`${classes.countBubble} ${classes.verificationsCounter}`}
        >
          {approvalsCount}
        </div>
      );
    } else if (
      label === t("ordersNavbarRouteInstancesLabel") &&
      partlyDeclinedCount
    ) {
      return (
        <div
          className={`${classes.countBubble} ${classes.partlyDeclinedCounter}`}
        >
          {partlyDeclinedCount}
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <NavLink to={route} className={classes.text}>
      <ListItem
        onClick={closeMenu}
        id={id}
        button
        key={1}
        className={clsx(className, isSelected ? classes.active : "")}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText
          primary={label}
          classes={{ primary: isSelected ? classes.boldText : "" }}
        />
        {renderCountBubble()}
      </ListItem>
    </NavLink>
  );
};

export default NavbarLink;
