import {
  IQuery,
  IApprovalSummaryQnum,
  TimeSheetSummaryByRouteInstanceRows,
  IApprovalDictionaries,
  IAvailablePackageEventDescription,
  IPackageOptions,
  IPackageEventDescription,
  RouteInstance
} from "../redux/types";
import {
  getAllServiceCategories,
  getAvailableServicePackageEventsAPI,
  getApplicableServicePackageEventsAPI,
  getVerificationSummariesAPI
} from "./api-declaration";
import { tableFilterQueryParams } from "../helpers/queryParams";
import {
  processVerificationSummaries,
  collectConflictDetails,
  applySCOnApprovalSummary
} from "../helpers/timesheetApprovalHelper";

export async function fetchVerificationSummaries(
  filterQuery: IQuery<IApprovalSummaryQnum>,
  dictionaries: IApprovalDictionaries
) {
  const response = await getVerificationSummariesAPI({
    ...tableFilterQueryParams(filterQuery),
    filter__is_approvable: "True"
  });
  const { conflictGroups, conflictMap, conflictRowsMap, moreSummaryIds } =
    collectConflictDetails(response.results);

  // ::: fetch conflicted summaries :::
  let moreSummariesForConflict: TimeSheetSummaryByRouteInstanceRows[] = [];
  if (moreSummaryIds.length !== 0) {
    const moreSummariesResponse = await getVerificationSummariesAPI({
      filter__id__in: moreSummaryIds
    });
    moreSummariesForConflict = moreSummariesResponse.results;
  }
  const { approvalSummaries, serviceCategoriesPresent } =
    processVerificationSummaries(
      true,
      response.results,
      dictionaries,
      moreSummariesForConflict,
      conflictMap,
      conflictRowsMap
    );

  // ::: fetch connected serviceCategory :::
  if (serviceCategoriesPresent.length !== 0) {
    const { results: serviceCategories } = await getAllServiceCategories();
    applySCOnApprovalSummary(approvalSummaries, serviceCategories);
  }

  // ::: fetch connected dependencies :::
  const [materialDictionary, taskDictionary] = dictionaries;
  await materialDictionary.fetchData();
  await taskDictionary.fetchData();

  return {
    response,
    approvalSummaries,
    approvalSummaryMap: new Map(
      approvalSummaries.map((a) => [a.routeinstance_id, a])
    ),
    conflictGroups
  };
}
export async function fetchOrdersSummaries(
  routeinstances: RouteInstance[],
  dictionaries: IApprovalDictionaries
) {
  const response = await getVerificationSummariesAPI({
    filter__id__in: routeinstances.map(({ id }) => id)
  });
  const { approvalSummaries, serviceCategoriesPresent } =
    processVerificationSummaries(
      false,
      response.results,
      dictionaries,
      [],
      new Map()
    );

  // ::: fetch connected serviceCategory :::
  if (serviceCategoriesPresent.length !== 0) {
    const { results: serviceCategories } = await getAllServiceCategories({
      filter__id__in: serviceCategoriesPresent
    });
    applySCOnApprovalSummary(approvalSummaries, serviceCategories);
  }

  // ::: fetch connected dependencies :::
  const [materialDictionary, taskDictionary] = dictionaries;
  await materialDictionary.fetchData();
  await taskDictionary.fetchData();

  return {
    response,
    approvalSummaries,
    approvalSummaryMap: new Map(
      approvalSummaries.map((a) => [a.routeinstance_id, a])
    )
  };
}

export async function fetchPackageOptionsData(submitId: number) {
  try {
    const applicableResp = await getApplicableServicePackageEventsAPI(submitId);
    const availableResp = await getAvailableServicePackageEventsAPI(submitId);

    const mapAvailablePackages = (p: IAvailablePackageEventDescription) => ({
      event_id: p.id,
      title: p.name
    });
    const mapApplicablePackages = (p: IPackageEventDescription) => ({
      event_id: p.id,
      title: p.title
    });
    const packageOptions: IPackageOptions = {
      applicable: {
        "": [],
        contractor: applicableResp.contractor_service_package_events.map(
          mapApplicablePackages
        ),
        customer: applicableResp.customer_service_package_events.map(
          mapApplicablePackages
        )
      },
      available: {
        "": [],
        contractor: availableResp.contractor_packages.map(mapAvailablePackages),
        customer: availableResp.customer_packages.map(mapAvailablePackages)
      }
    };
    return packageOptions;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
