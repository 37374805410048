import React, { useState, useEffect } from "react";
import {
  INotificationSettingForm,
  NotificationEventType,
  NotificationType,
  TabComponentProps
} from "../../redux/types";
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography
} from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";
import {
  getNotificationSettingAPI,
  updateNotificationSettingAPI,
  createNotificationSettingAPI
} from "../../services/api-declaration";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import { NavigateBefore as NavigateBeforeIcon } from "@material-ui/icons";
import { useTranslate } from "../../services/appLanguageService";
import { Formik, Form, FormikHelpers, Field } from "formik";
import * as Yup from "yup";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";
import { useHistory } from "react-router-dom";

const styles = (theme: any) =>
  createStyles({
    buttonsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1.5)
    },
    formPaper: {
      flex: "auto",
      minWidth: 400,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      padding: theme.spacing(2)
    },
    formWrapper: {
      display: "flex",
      flexFlow: "column"
    },
    formControl: {
      width: "100%",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      display: "flex",
      flexDirection: "row"
    },
    addButtons: {
      margin: theme.spacing(1.5),
      alignSelf: "start"
    },
    mapPaper: {
      flex: "0.6 0 auto",
      minWidth: 220,
      marginTop: theme.spacing(1),
      height: "calc(100VH - 172px)",
      display: "flex",
      flexFlow: "column",
      padding: theme.spacing(2.5)
    },
    mapFlexBasis: {
      flex: "auto"
    },
    m20: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5)
    },
    gridViewFields: {
      display: "flex"
    },
    mr15: {
      marginRight: 15
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: 2
    },
    paper: {
      padding: theme.spacing(2.5)
    },
    saveBtn: { marginTop: theme.spacing(2) },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    }
  });

const eventTypeOptions: NotificationEventType[] = [
  "LOGIN",
  "LOGOUT",
  "ACCEPT_TIMESHEET",
  "SUBMIT_TIMESHEET",
  "NEW_TIMESHEET_AVAILABLE",
  "PING",
  "NODE_START",
  "INTERNAL_ERROR"
];

const notificationTypeOptions: NotificationType[] = [
  "EMAIL",
  "WEB_SOCKET",
  "MOBILE_PUSH",
  "MOBILE_PUSH_URGENT"
];

let ValidationSchema = (t: any) => {
  return Yup.object().shape({
    title: Yup.string()
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    eventtype: Yup.string()
      .oneOf(eventTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    notificationtype: Yup.string()
      .oneOf(notificationTypeOptions, t("notValidEnumValueError"))
      .required(t("requiredError")),
    body: Yup.string().nullable().notRequired(),
    default_specificity: Yup.boolean().required(),
    user_specific: Yup.boolean().required(),
    company_specific: Yup.boolean().required(),
    search_term: Yup.string()
      .min(1, t("minLengthError"))
      .max(100, t("max100LengthError"))
      .nullable()
      .notRequired()
  });
};

const defaultNotificationSetting: INotificationSettingForm = {
  title: null,
  eventtype: "PING",
  notificationtype: "EMAIL",
  body: null,
  default_specificity: false,
  user_specific: false,
  company_specific: false,
  search_term: null
};

interface NotificationSettingSaveProps
  extends WithStyles<typeof styles>,
    TabComponentProps<"nSettingId" | "id"> {}

const NotificationSettingsSave: React.FC<NotificationSettingSaveProps> = (
  props
) => {
  const {
    routeParams: { nSettingId, id },
    classes,
    tabId
  } = props;
  const [notificationSetting, setNotificationSetting] =
    useState<INotificationSettingForm>(defaultNotificationSetting);
  const t = useTranslate("NotificationSettingsSavePage");
  const t2 = useTranslate("EventTypes");
  const t3 = useTranslate("NotificationTypes");
  const v = useTranslate("ValidationErrorMessages");
  const history = useHistory();

  const fetchNotificationSetting = async () => {
    if (nSettingId) {
      try {
        const response = await getNotificationSettingAPI(Number(nSettingId));
        if (response) {
          setNotificationSetting({ ...response });
        }
      } catch (e: any) {
        showGlobalSnackbar(e.message, "error");
      }
    }
  };

  const goBack = () => {
    const paramId = id;
    history.replace(`/notification-profiles/${paramId}?same_tab=true`);
  };

  // Form Handler
  const handleSubmit = async (
    formValues: INotificationSettingForm,
    { setSubmitting }: FormikHelpers<INotificationSettingForm>
  ) => {
    setSubmitting(true);
    try {
      formValues.profile = Number(id);
      if (nSettingId) {
        await updateNotificationSettingAPI(Number(nSettingId), formValues);
      } else {
        await createNotificationSettingAPI(formValues);
      }
      goBack();
    } catch (e: any) {
      showGlobalSnackbar(e.message, "error");
    } finally {
      setSubmitting(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetch = React.useCallback(fetchNotificationSetting, []);

  useEffect(() => {
    fetch();
  }, [fetch]);
  useEffect(() => {
    if (notificationSetting.title) {
      store.dispatch(setTitle(notificationSetting.title, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationSetting]);

  return (
    <div>
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          centerRipple
          onClick={goBack}
        >
          <NavigateBeforeIcon /> {t("navigateBeforeButtonText")}
        </Button>
      </div>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {nSettingId ? t("editHeading") : t("addHeading")}
        </Typography>
        <Formik
          initialValues={notificationSetting}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema(v)}
        >
          {(_props) => (
            <Form className={classes.formWrapper}>
              <Field
                autoFocus
                component={TextField}
                data-cy="notification-setting-title"
                error={_props.errors.title && _props.touched.title}
                fullWidth
                id="title"
                name="title"
                label={t("titleFieldLabel")}
                margin="normal"
                onChange={_props.handleChange}
                placeholder={t("titleFieldPlaceholder")}
                type="text"
                value={_props.values.title || ""}
              />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="eventtype">
                  {t("selectEventTypeInputLabel")}
                </InputLabel>
                <Select
                  data-cy="notification-setting-event-type"
                  fullWidth
                  name="eventtype"
                  onChange={_props.handleChange}
                  value={_props.values.eventtype || ""}
                >
                  {eventTypeOptions.map((origin, idx) => (
                    <MenuItem key={idx} value={origin}>
                      {t2(origin)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="notificationtype">
                  {t("selectNotificationTypeInputLabel")}
                </InputLabel>
                <Select
                  data-cy="notification-setting-notification-type"
                  fullWidth
                  name="notificationtype"
                  onChange={_props.handleChange}
                  value={_props.values.notificationtype || ""}
                >
                  {notificationTypeOptions.map((origin, idx) => (
                    <MenuItem key={idx} value={origin}>
                      {t3(origin)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Field
                component={TextField}
                data-cy="notification-setting-body"
                error={_props.errors.body && _props.touched.body}
                fullWidth
                id="body"
                name="body"
                label={t("bodyFieldLabel")}
                margin="normal"
                onChange={_props.handleChange}
                placeholder={t("bodyFieldPlaceholder")}
                type="text"
                value={_props.values.body || ""}
              />
              <Field
                id="default_specificity"
                data-cy="default_specificity"
                name="default_specificity"
                type="checkbox"
                Label={{ label: t("defaultSpecificityFieldLabel") }}
                component={CheckboxWithLabel}
              />
              <Field
                id="user_specific"
                data-cy="user_specific"
                name="user_specific"
                type="checkbox"
                Label={{ label: t("userSpecificFieldLabel") }}
                component={CheckboxWithLabel}
              />
              <Field
                id="company_specific"
                data-cy="company_specific"
                name="company_specific"
                type="checkbox"
                Label={{ label: t("companySpecificFieldLabel") }}
                component={CheckboxWithLabel}
              />
              <Field
                component={TextField}
                data-cy="notification-setting-search_term"
                error={_props.errors.search_term && _props.touched.search_term}
                fullWidth
                id="search_term"
                name="search_term"
                label={t("searchTermFieldLabel")}
                margin="normal"
                onChange={_props.handleChange}
                placeholder={t("searchTermFieldPlaceholder")}
                type="text"
                value={_props.values.search_term || ""}
              />
              <Button
                id="new-setting-submit"
                data-cy="setting-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.saveBtn}
              >
                {t("saveButtonLabel")}
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NotificationSettingsSave);
