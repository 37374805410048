import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Link,
  TableHead,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useTranslate } from "../../../services/appLanguageService";
import {
  Customerobject,
  Person,
  SelectFieldOption
} from "../../../redux/types";
import {
  getPersonsAPI,
  updateCustomerobjectAPI
} from "../../../services/api-declaration";
import { Add, DeleteOutline } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import ConfirmationDialog from "../../confirmationDialog/ConfirmationDialog";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper: {
      marginTop: 20,
      paddingTop: theme.spacing(1)
    },
    editButton: {
      marginLeft: 20
    },
    bold: {
      fontWeight: "bold"
    },
    title: {
      padding: 10
    },
    addButton: {
      marginRight: theme.spacing(2)
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h6": {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
      }
    },
    loadingIndicator: {
      fontSize: 40
    },
    loadingContainer: {
      textAlign: "center"
    }
  })
);

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    }
  })
);

interface CustomerObjectPersonModalProps {
  personOptions: SelectFieldOption[];
  onClose: () => void;
  onSuccess: (personId: number) => void;
  handleLoading: (loading: boolean) => void;
}

const CustomerObjectPersonModal: React.FC<CustomerObjectPersonModalProps> = (
  props
) => {
  const [selectedPersonOption, setSelectedPersonOption] =
    useState<SelectFieldOption>({
      label: "",
      value: 0
    });
  const { personOptions, onClose, onSuccess } = props;
  const t = useTranslate("CustomerobjectPage");
  const classes = useModalStyles();

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>{t("connectPersonLabel")}</DialogTitle>
      <DialogContent className={classes.content}>
        <Autocomplete
          value={selectedPersonOption}
          onChange={(event: any, newPerson: SelectFieldOption | null) => {
            setSelectedPersonOption(newPerson || { label: "", value: 0 });
          }}
          renderInput={(params: any) => (
            <TextField {...params} label={t("personLabel")} />
          )}
          getOptionLabel={(o) => o.label}
          options={personOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t("cancelLabel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSuccess(selectedPersonOption.value)}
        >
          {t("connectLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface CustomerobjectProps {
  customerobject: Customerobject;
  updateCustomerobject: (customerobject: Customerobject) => void;
}

const CustomerobjectPersons: React.FC<CustomerobjectProps> = (props) => {
  const { customerobject, updateCustomerobject } = props;
  const classes = useStyles();
  const t = useTranslate("CustomerobjectPage");
  const t2 = useTranslate("Persons");
  const [persons, setPersons] = useState<Person[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<
    { m: "create" } | { m: "delete"; personId: number }
  >();

  useEffect(() => {
    let isAlive = true;

    getPersonsAPI({ filter__company: customerobject.customer }).then(
      (personsResponse) => isAlive && setPersons(personsResponse.results)
    );

    return () => {
      isAlive = false;
    };
  }, [customerobject.customer]);

  const handleClose = () => setModal(undefined);

  const handleLoading = (_loading: boolean) => setLoading(_loading);

  const handleConnectPerson = (personId: number) => {
    setLoading(true);
    updateCustomerobjectAPI(customerobject.id, {
      ...customerobject,
      persons: [...customerobject.persons, personId]
    }).then((customerobjectResponse) => {
      updateCustomerobject(customerobjectResponse);
      setModal(undefined);
      setLoading(false);
    });
  };

  const handleDisconnectPerson = (personId: number) => {
    setLoading(true);
    updateCustomerobjectAPI(customerobject.id, {
      ...customerobject,
      persons: customerobject.persons.filter((_person) => _person !== personId)
    }).then((customerobjectResponse) => {
      updateCustomerobject(customerobjectResponse);
      setModal(undefined);
      setLoading(false);
    });
  };

  const personOptions = persons
    .filter((_person) => !customerobject.persons.includes(_person.id))
    .map((person) => ({
      label: `${person.first_name} ${person.last_name}`,
      value: person.id
    }));

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("staffFieldsLabel")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            className={classes.addButton}
            onClick={() => setModal({ m: "create" })}
          >
            {t("addLabel")}
          </Button>
        </div>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell className={classes.bold}>{t("nameLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("titleLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("emailLabel")}</TableCell>
              <TableCell className={classes.bold}>{t("phone1Label")}</TableCell>
              <TableCell></TableCell>
            </TableHead>
            <TableBody>
              {!loading ? (
                customerobject.persons.length > 0 ? (
                  persons
                    .filter((person) =>
                      customerobject.persons.includes(person.id)
                    )
                    .map((person) => (
                      <TableRow>
                        <TableCell>
                          <Link
                            component={RouterLink}
                            to={`/auth/persons/${person.id}`}
                          >
                            {person.first_name} {person.last_name}
                          </Link>
                        </TableCell>
                        <TableCell>{t2(person.title)}</TableCell>
                        <TableCell>{person.email}</TableCell>
                        <TableCell>{person.phone1}</TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setModal({ m: "delete", personId: person.id })
                            }
                          >
                            <DeleteOutline />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>{t("noPersonsLabel")}</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className={classes.loadingContainer}>
                    <CircularProgress className={classes.loadingIndicator} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {modal?.m === "create" && customerobject.persons && persons && (
        <CustomerObjectPersonModal
          personOptions={personOptions}
          onClose={handleClose}
          onSuccess={handleConnectPerson}
          handleLoading={handleLoading}
        />
      )}
      {modal?.m === "delete" && modal?.personId && (
        <ConfirmationDialog
          title={t("confirmDeleteTitle")}
          description={t("confirmDeleteText")}
          onSuccess={() => handleDisconnectPerson(modal.personId)}
          onClose={() => {
            setModal(undefined);
          }}
          open
        />
      )}
    </>
  );
};

export default CustomerobjectPersons;
