import * as Yup from "yup";
import { ColumnDefinition } from "../../../redux/types";

const validate = (
  values: any,
  columnDefinition: ColumnDefinition,
  handleFormValidation: (
    field: string,
    validation: { error: boolean; errorText: string }
  ) => void
) => {
  if (columnDefinition.validation) {
    const schema = Yup.object().shape({
      [columnDefinition.name]: columnDefinition.validation
    });
    schema
      .validate(values)
      .then((valid) => {
        handleFormValidation(columnDefinition.name, {
          error: false,
          errorText: ""
        });
      })
      .catch((e: any) => {
        handleFormValidation(columnDefinition.name, {
          error: true,
          errorText: e.errors.join()
        });
      });
  }
};

export default validate;
