import React from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { useTranslate } from "../services/appLanguageService";
import { FieldProps, FormikProps, FormikValues } from "formik";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

interface ExtendedFieldProps extends FieldProps {
  name: string;
}

export interface FDateTimePickerProps {
  label?: string;
  field: ExtendedFieldProps;
  form: FormikProps<FormikValues>;
  fullWidth?: boolean;
  margin?: "none" | "dense" | "normal";
  autoOk?: boolean;
  inputVariant?: "filled" | "outlined" | "standard";
}

const FTimePicker: React.FC<FDateTimePickerProps> = (props) => {
  const {
    label,
    field,
    form: { touched, errors, values, setFieldValue },
    ...other
  } = props;

  const t = useTranslate("TimePicker");
  const errorText = errors[field.name];
  const hasError = touched[field.name] && errorText !== undefined;
  return (
    <KeyboardDateTimePicker
      cancelLabel={t("cancelLabel")}
      label={label}
      ampm={false}
      error={hasError}
      helperText={hasError ? errorText : ""}
      onChange={(value) => setFieldValue(field.name, value)}
      value={values[field.name]}
      keyboardIcon={<QueryBuilderIcon />}
      {...other}
    />
  );
};

export default FTimePicker;
