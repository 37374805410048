import { ColumnDefinition } from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { snakeToCamel } from "../../FormatHelpers";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from "@material-ui/icons";

interface ColumnHeaderProps {
  columnDefinition: ColumnDefinition;
  pluralName: string;
  handleSorting: (columnDefinition: ColumnDefinition) => void;
  classes: any;
  currentSorting?: string;
}

const ColumnHeader: React.FC<ColumnHeaderProps> = (props) => {
  const {
    columnDefinition,
    pluralName,
    handleSorting,
    classes,
    currentSorting
  } = props;

  const t = useTranslate(`${pluralName}Page`);

  return columnDefinition.sortable ? (
    <div
      className={`${classes.leftAlign} ${classes.hover}`}
      onClick={() => handleSorting(columnDefinition)}
    >
      {t(
        `${snakeToCamel(columnDefinition.name)}${
          columnDefinition.type === "joined" ? "Joined" : ""
        }Label`
      )}{" "}
      {currentSorting && currentSorting === "asc" && (
        <ArrowUpwardIcon className={classes.sortIcon} fontSize="small" />
      )}
      {currentSorting && currentSorting === "desc" && (
        <ArrowDownwardIcon className={classes.sortIcon} fontSize="small" />
      )}
    </div>
  ) : (
    <div className={`${classes.leftAlign}`}>
      {t(
        `${snakeToCamel(columnDefinition.name)}${
          columnDefinition.type === "joined" ? "Joined" : ""
        }Label`
      )}
    </div>
  );
};

export default ColumnHeader;
